<h2 *ngIf="!siteForm && !addComponent && !componentView && !isUpdateComponent" mat-dialog-title>
  {{ 'Supplier Creation' | translate }}
</h2>

<div class="header" *ngIf="siteForm || addComponent || componentView || isUpdateComponent">
  <div class="custom-breadcramp d-flex align-items-center">
    <div class="supp-creation d-flex align-items-center">
      <app-custom-icon [icon_category]="'arrows'" [icon_name]="'arrow-circle-left-short'"></app-custom-icon>
      <h4 class="">{{ 'Supplier Creation' | translate }}</h4>
    </div>
    <div class="supp-site cursor d-flex align-items-center cursor" (click)="supplierView()">
      <app-custom-icon [icon_category]="'arrows'" [icon_name]="'arrow-right-short'"></app-custom-icon>
      <h4 class="">{{ supplierName }}</h4>
    </div>
    <div *ngIf="siteForm || isUpdateSite" class="supp-site d-flex align-items-center">
      <app-custom-icon [icon_category]="'arrows'" [icon_name]="'arrow-right-short'"></app-custom-icon>
      <h4 class="">{{ isUpdateSite ? ('Edit Supplier Site' | translate) : ('Add Supplier Site' | translate) }}</h4>
    </div>
    <div *ngIf="addComponent" class="supp-site d-flex align-items-center">
      <app-custom-icon [icon_category]="'arrows'" [icon_name]="'arrow-right-short'"></app-custom-icon>
      <h4 class="">{{ 'Add Component' | translate }}</h4>
    </div>
    <div *ngIf="isUpdateComponent" class="supp-site d-flex align-items-center">
      <app-custom-icon [icon_category]="'arrows'" [icon_name]="'arrow-right-short'"></app-custom-icon>
      <h4 class="">{{ 'Edit Component' | translate }}</h4>
    </div>
    <div *ngIf="componentView && !isUpdateComponent" class="supp-site d-flex align-items-center">
      <app-custom-icon [icon_category]="'arrows'" [icon_name]="'arrow-right-short'"></app-custom-icon>
      <h4 class="">{{ 'View Components' | translate }}</h4>
    </div>
  </div>
</div>
<div class="divider-holder" *ngIf="siteForm || addComponent || componentView || isUpdateComponent">
  <mat-divider></mat-divider>
</div>
<mat-dialog-content #dialogContent class="mat-typography">
  <div class="create-supplier-wrapper">
    <div
      *ngIf="supplierUuid && action === 'edit' && isShowNote && supplierDetails.form.pristine"
      class="created-supplier-note d-flex align-items-center"
    >
      <img src="assets/images/symbol-blue.svg" />
      <h6 class="m-0 flex-grow-1">
        <strong>{{ 'The supplier was successfully updated.' | translate }}</strong>
      </h6>
      <app-custom-icon
        id="close-note"
        class="cursor-pointer"
        icon_category="essential"
        icon_name="close-circle"
        (click)="isShowNote = false"
      ></app-custom-icon>
    </div>

    <app-supplier-details
      [ngClass]="{ 'd-none': hideSupplierComponent }"
      #supplierDetails
      [productId]="productId"
      (supplier)="supplierCreated($event)"
      [show_buttons]="show_buttons"
    ></app-supplier-details>

    <div class="divider" *ngIf="!hideSupplierComponent && supplierUuid"></div>
    <div *ngIf="componentCreated && isShowNote" class="created-supplier-note d-flex align-items-center">
      <img src="assets/images/symbol-blue.svg" />
      <h6 class="m-0 flex-grow-1">
        <strong>{{ 'The component was successfully added' | translate }}</strong>
      </h6>
      <app-custom-icon
        id="close-note"
        class="cursor-pointer"
        icon_category="essential"
        icon_name="close-circle"
        (click)="componentCreated = false"
      ></app-custom-icon>
    </div>

    <app-supplier-sites
      #supplierSites
      *ngIf="supplierUuid && !hideSupplierSIte"
      [supplierUuid]="supplierUuid"
      [show_buttons]="show_buttons"
      (ingredients)="getComponents($event)"
      (showSiteForm)="siteFormView($event)"
      (addComponent)="createComponent($event)"
      (site)="getSite($event)"
      (componentsView)="viewComponent($event)"
      (siteCreated)="siteCreated($event)"
      [componentCreated]="componentCreated"
      (isUpdate)="isSitesUpdate($event)"
      [componentDeleted]="componentDeleted"
    ></app-supplier-sites>

    <app-supplier-components
      #supplierComponents
      *ngIf="addComponent || componentView"
      [supplierUuid]="supplierUuid"
      [supplierName]="supplierName"
      [show_buttons]="component_btn"
      [componentView]="componentView"
      [addComponent]="addComponent"
      [site]="site"
      (componentCreated)="componentCretaed($event)"
      (close)="closeComponent($event)"
      [supplierComponents]="ingredients"
      (isUpdate)="updateComponent($event)"
      (componentingredients)="getComponents($event)"
      (componentDeleted)="deletedComponent($event)"
    ></app-supplier-components>
  </div>
</mat-dialog-content>

<mat-dialog-actions class="justify-content-end">
  <app-rounded-button
    [disabled]="!createdSupplier || siteForm || addComponent"
    color="primary"
    [size]="'md'"
    [button_text]="'Close Supplier Creation'"
    (click)="close()"
  >
  </app-rounded-button>
</mat-dialog-actions>
