<div class="container-fluid">
  <div class="row container-inside">
    <div class="col-md-6 left p0">
      <app-illustration-design color="primary"></app-illustration-design>
    </div>
    <div class="col-md-6 right">
      <div class="col-md-12 content row align-content-center">
        <div class="col-md-10 text-inputs-button mb-0 p-0 row flex-nowrap">
          <!-- <div class="text-box-inputs mb-0"> -->
          <div class="title-box">
            <h2 class="mb-0">{{ 'account-setup.New Password' | translate }}</h2>
            <h5 class="mb-0">{{ 'account-setup.Now you can add your new password' | translate }}</h5>
          </div>
          <!-- </div> -->
          <form [formGroup]="form" action="" class="row">
            <section class="password-section d-flex">
              <mat-form-field class="input-primary" appearance="outline">
                <mat-label>{{ 'account-setup.New Password' | translate }}</mat-label>
                <input matInput formControlName="new_password1" [type]="hide_pass1 ? 'password' : 'text'" />
                <app-custom-icon
                  class="show-hide-pass"
                  [icon_category]="'security'"
                  [icon_name]="hide_pass1 ? 'eye-slash' : 'eye'"
                  (click)="hide_pass1 = !hide_pass1"
                ></app-custom-icon>
                <mat-error *ngIf="form.controls['new_password1'].hasError('required')">
                  {{ 'account-setup.Password is' | translate }} {{ 'required.' | translate }}</mat-error
                >
                <mat-error
                  *ngIf="
                    form.controls['new_password1'].hasError('minlength') &&
                    form.controls['new_password1'].getError('minlength').actualLength <
                      form.controls['new_password1'].getError('minlength').requiredLength
                  "
                >
                  {{ 'Use at least 8 characters.' | translate }}</mat-error
                >
                <div
                  *ngIf="
                    form.controls['new_password1'].value?.length > 7 &&
                    form.controls['new_password1'].value?.length < 12
                  "
                  class="pass-field-message"
                >
                  <span class="fair-pass-circle"></span>
                  {{ 'account-setup.Fair password, use 12 characters to make it stronger.' | translate }}
                </div>
                <div *ngIf="form.controls['new_password1'].value?.length > 11" class="pass-field-message">
                  <span class="strong-pass-circle"></span>
                  {{ 'account-setup.Strong password, you are good to go.' | translate }}
                </div>
              </mat-form-field>
              <app-custom-icon
                class="indicator"
                [icon_category]="'security'"
                [icon_name]="'lock-2'"
                [matTooltip]="'account-setup.Your password is securely encrypted.' | translate"
                matTooltipClass="custom-tooltip-center"
              ></app-custom-icon>
            </section>

            <section class="password-section d-flex">
              <mat-form-field class="input-primary" appearance="outline">
                <mat-label>{{ 'account-setup.Confirm new password' | translate }}</mat-label>
                <input matInput formControlName="new_password2" [type]="hide_pass2 ? 'password' : 'text'" />
                <app-custom-icon
                  class="show-hide-pass"
                  [icon_category]="'security'"
                  [icon_name]="hide_pass2 ? 'eye-slash' : 'eye'"
                  (click)="hide_pass2 = !hide_pass2"
                ></app-custom-icon>
                <mat-error *ngIf="form.controls['new_password2'].hasError('required')">
                  {{ 'account-setup.Please confirm your password.' | translate }}
                </mat-error>
                <mat-error *ngIf="form.controls['new_password2'].hasError('mustMatch')">
                  {{ 'Passwords do not match.' | translate }}</mat-error
                >
              </mat-form-field>
              <app-custom-icon
                class="indicator"
                [icon_category]="'security'"
                [icon_name]="'lock-2'"
                [matTooltip]="'account-setup.Your password is securely encrypted.' | translate"
                matTooltipClass="custom-tooltip-center"
              ></app-custom-icon>
            </section>
          </form>

          <section class="p-0">
            <mat-checkbox class="checkbox-primary" [formControl]="$any(form.controls['is_consent_apporved'])">
              <p>
                {{ 'by checking the box you agree with' | translate }}
                <span class="policy-link" [routerLink]="'/terms-and-conditions'">{{
                  'account-setup.PDNAs Terms and Conditions' | translate
                }}</span>
              </p>
            </mat-checkbox>
          </section>

          <div class="bottom-area">
            <div class="resset-pass">
              <app-rounded-button
                [disabled]="form.invalid"
                (click)="resetPassword()"
                color="primary"
                [button_text]="'account-setup.Reset password' | translate"
                [icon_category]="'security'"
                [icon_name]="'lock-circle'"
                [icon_right]="true"
              ></app-rounded-button>
            </div>

            <div class="back-btn">
              <app-rounded-button
                [color]="small_screen ? 'tetriary-link' : 'secondary'"
                [button_text]="'account-setup.Back to log in' | translate"
                [icon_category]="'arrows'"
                [icon_name]="'arrow-left'"
                [icon_left]="true"
                [routerLink]="'/login'"
              ></app-rounded-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
