<div class="background">
  <div class="certificates-grid-view">
    <mat-card class="certificates-list-wraper">
      <div class="header-wraper">
        <mat-card class="options-header">
          <div class="certificate-heading">
            <p>
              {{ 'All Certificates' | translate }}
            </p>
            <!-- Input filter -->
            <div class="filter">
              <mat-form-field
                class="input-primary mat-form-field-10-px-brd-rd custom-filter-header"
                appearance="outline"
              >
                <mat-label class="label-text">{{ 'Quick search...' | translate }}</mat-label>
                <input matInput (keyup)="onQuickFilterChanged($event)" placeholder="Ex. Certificate" #input />
                <app-custom-icon
                  class="search-filter"
                  [icon_category]="'search'"
                  [icon_name]="'search-normal-2'"
                  matIconSuffix
                ></app-custom-icon>
              </mat-form-field>
            </div>
          </div>

          <div class="toggle-button-wraper">
            <div class="toggle-group">
              <mat-button-toggle-group #group="matButtonToggleGroup" (change)="viewChange($event.value)">
                <div class="table-view toggle custom-icon-20-px">
                  <mat-button-toggle
                    value="tableView"
                    aria-label="Table View"
                    [checked]="true"
                    matTooltip="{{ 'List View' | translate }}"
                    matTooltipClass="custom-tooltip-center"
                    matTooltipPosition="above"
                  >
                    <app-custom-icon [icon_category]="'essential'" [icon_name]="'menu-3lines'"></app-custom-icon>
                  </mat-button-toggle>
                </div>
                <div class="card-view toggle custom-icon-20-px">
                  <mat-button-toggle
                    value="cardView"
                    aria-label="Card View"
                    matTooltip="{{ 'Grid View' | translate }}"
                    matTooltipClass="custom-tooltip-center"
                    matTooltipPosition="above"
                  >
                    <app-custom-icon [icon_category]="'grid'" [icon_name]="'element-3-large'"></app-custom-icon>
                  </mat-button-toggle>
                </div>
              </mat-button-toggle-group>
            </div>
            <div class="create-certificate-btn">
              <app-rounded-button
                color="primary"
                [button_text]="'Create Certificate'"
                [icon_category]="'learning'"
                [icon_name]="'certificate'"
                [icon_right]="true"
                [size]="'md'"
                (click)="onCreateEditCertificate(null)"
              ></app-rounded-button>
            </div>
          </div>
        </mat-card>
      </div>

      <div class="main-holder" *ngIf="certificates.length">
        <!-- Card view -->
        <div class="cards-wraper row" *ngIf="group.value === 'cardView'">
          <mat-card *ngFor="let element of certificatesObservable$ | async" class="custom-card col-12 col-md-6">
            <div class="wrapper">
              <div
                (click)="
                  viewCertificate(
                    element.certificate_subject,
                    element,
                    element.document,
                    element.document.split('/').at(-1)
                  )
                "
              >
                <div class="certificate-header">
                  <div class="icon">
                    <app-custom-icon
                      id="learning"
                      [icon_category]="'learning'"
                      [icon_name]="'certificate'"
                    ></app-custom-icon>
                  </div>
                  <div class="type">
                    <p class="certificate-type-text">{{ 'Certificate type' | translate }}</p>
                    <p class="certificate-type">{{ element.certificate.name }}</p>
                  </div>
                </div>

                <div class="certificate-title">
                  <p class="subtitle">{{ 'area:' | translate }}</p>
                  <p class="domain">{{ element.certificate.domain?.name }}</p>
                  <p class="subtitle">{{ 'certificator:' | translate }}</p>
                  <p class="company-name">
                    {{
                      element.certificator !== null && element.certificator !== 'null' && element.certificator !== ''
                        ? element.certificator
                        : 'Not set'
                    }}
                  </p>

                  <div
                    class="product-certificate"
                    [ngClass]="element.certificate_subject?.manufactories?.length ? 'd-none' : 'bg-blue'"
                  >
                    <p class="subtitle">{{ 'certificate for' | translate }} {{ element.certificate_subject.type }}:</p>
                    <p class="product-name">{{ element.certificate_subject.name }}</p>
                  </div>

                  <div class="product-certificate" *ngIf="element.certificate_subject?.manufactories?.length">
                    <p class="subtitle">{{ element.certificate_subject.type }}:</p>
                    <p class="company-name">{{ element.certificate_subject.name }}</p>
                  </div>

                  <!-- *ngIf certificate applied to supplier sites -->

                  <div
                    class="sites"
                    *ngIf="element.certificate_subject?.manufactories"
                    [ngClass]="element.certificate_subject?.manufactories?.length ? 'bg-blue' : 'bg-none'"
                  >
                    <p class="subtitle">{{ 'certificate applied to supplier sites:' | translate }}</p>
                    <p class="sup-sites">
                      {{ element.certificate_subject.manufactories[0].name }}
                      <span
                        >{{
                          element.certificate_subject.manufactories.length > 1
                            ? ' (+' + (element.certificate_subject.manufactories.length - 1) + ' others)'
                            : ''
                        }}
                      </span>
                    </p>
                  </div>
                </div>
              </div>

              <div class="certificate-footer">
                <div class="start-date">
                  <div class="icon">
                    <app-custom-icon id="calendar" [icon_category]="'time'" [icon_name]="'calendar'"></app-custom-icon>
                  </div>
                  <div class="start">
                    <p class="date">{{ 'Start Date' | translate }} {{ element.validity_start | date: 'dd.MM.YY' }}</p>
                  </div>
                </div>

                <div class="end-date-wrapper">
                  <div class="end-date" *ngIf="!element.is_expired">
                    <div class="icon">
                      <app-custom-icon [icon_category]="'essential'" [icon_name]="'danger-yellow'"></app-custom-icon>
                    </div>
                    <div class="end">
                      <p class="date">
                        {{ 'Expires ' | translate }}
                        <span>{{
                          (element.validity_end | date: 'dd.MM.YY') !== 'null'
                            ? (element.validity_end | date: 'dd.MM.YY')
                            : ''
                        }}</span>
                      </p>
                    </div>
                  </div>
                  <div class="expires-date-holder" *ngIf="element.is_expired">
                    <app-custom-icon id="danger" [icon_category]="'essential'" [icon_name]="'danger'">
                    </app-custom-icon>
                    <p class="expired">{{ 'EXPIRED' | translate }}</p>
                  </div>
                  <div class="actions">
                    <app-custom-icon
                      matTooltip="{{ 'Edit Certificate' | translate }}"
                      matTooltipClass="custom-tooltip-center"
                      matTooltipPosition="above"
                      id="edit"
                      [icon_category]="'files'"
                      [icon_name]="'edit'"
                      (click)="onCreateEditCertificate(element)"
                    >
                    </app-custom-icon>

                    <app-custom-icon
                      matTooltip="{{ 'Delete Certificate' | translate }}"
                      matTooltipClass="custom-tooltip-end"
                      matTooltipPosition="above"
                      id="trash"
                      [icon_category]="'essential'"
                      [icon_name]="'trash'"
                      (click)="deleteCertificate(element.uuid, element.certificate.name)"
                    >
                    </app-custom-icon>
                  </div>
                </div>
              </div>
            </div>
          </mat-card>
        </div>

        <!-- Table view -->
        <div class="table-wraper" *ngIf="group.value === 'tableView'">
          <table class="custom-table" mat-table matSort [dataSource]="dataSource">
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Certificate Type' | translate }}</th>
              <td mat-cell *matCellDef="let element">{{ element.certificate.name }}</td>
            </ng-container>

            <ng-container matColumnDef="certificated_subject">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Certificated subject' | translate }}</th>
              <td mat-cell *matCellDef="let element">
                <div class="certificated-subject">
                  <div class="logo">
                    <img
                      class="avatar"
                      src="{{
                        element.certificate_subject?.image
                          ? element.certificate_subject?.image
                          : '../../../../../../assets/images/upload-placeholder-blue.svg'
                      }}"
                    />
                  </div>
                  <div class="name">
                    <p>{{ element.certificate_subject?.name }}</p>
                  </div>
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="area">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Area' | translate }}</th>
              <td mat-cell *matCellDef="let element">{{ element?.certificate.domain?.name | slice: 0 : 30 }}</td>
            </ng-container>

            <ng-container matColumnDef="validity_start">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Start Date' | translate }}</th>
              <td mat-cell *matCellDef="let element">{{ element.validity_start | date: 'dd.MM.YY' }}</td>
            </ng-container>

            <ng-container matColumnDef="validity_end">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'End Date' | translate }}</th>
              <td mat-cell *matCellDef="let element">{{ element.validity_end | date: 'dd.MM.YY' }}</td>
            </ng-container>

            <ng-container matColumnDef="is_expired">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Status' | translate }}</th>
              <td mat-cell *matCellDef="let element">
                <div class="status-holder d-flex" [ngClass]="element.is_expired ? 'expired' : 'up-to-date'">
                  <app-custom-icon
                    [icon_category]="'essential'"
                    [icon_name]="element.is_expired ? 'danger-red' : 'tick-circle-green'"
                    matTooltip="{{ element.is_expired ? 'Expired Date' : ('Up to date' | translate) }}"
                    matTooltipClass="custom-tooltip-center"
                    matTooltipPosition="above"
                  >
                  </app-custom-icon>
                  <p class="m-0">{{ element.is_expired ? 'Expired' : ('Up to date' | translate) }}</p>
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="actions">
              <th mat-header-cell *matHeaderCellDef class="actions-header">
                {{ 'product.Actions' | translate }}
              </th>
              <td mat-cell *matCellDef="let element" class="actions-body">
                <div class="actions-holder d-flex">
                  <app-custom-icon
                    matTooltip="{{ 'View Certificate' | translate }}"
                    id="view"
                    matTooltipClass="custom-tooltip-center"
                    matTooltipPosition="above"
                    [icon_category]="'security'"
                    [icon_name]="'eye'"
                    (click)="
                      viewCertificate(
                        element.certificate_subject,
                        element,
                        element.document,
                        element?.document?.split('/').at(-1)
                      )
                    "
                  >
                  </app-custom-icon>
                  <app-custom-icon
                    matTooltip="{{ 'Edit Certificate' | translate }}"
                    matTooltipClass="custom-tooltip-center"
                    matTooltipPosition="above"
                    id="edit"
                    [icon_category]="'files'"
                    [icon_name]="'edit'"
                    (click)="onCreateEditCertificate(element)"
                  >
                  </app-custom-icon>

                  <app-custom-icon
                    matTooltip="{{ 'Remove Certificate' | translate }}"
                    matTooltipClass="custom-tooltip-end"
                    matTooltipPosition="above"
                    id="close-square"
                    [icon_category]="'essential'"
                    [icon_name]="'trash'"
                    (click)="deleteCertificate(element.uuid, element.certificate?.name)"
                  >
                  </app-custom-icon>
                </div>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          </table>
        </div>
      </div>

      <div class="card-footer">
        <mat-paginator class="custom-paginator" showFirstLastButtons aria-label="Select page of certificates">
        </mat-paginator>
      </div>
    </mat-card>
  </div>
</div>
