<div class="background">
  <div class="product-grid-view">
    <mat-card class="product-list-wraper">
      <div class="header-wraper">
        <mat-card class="options-header">
          <div class="product-heading">
            <p>
              {{ 'Your products' | translate }}
            </p>
            <div class="filter">
              <mat-form-field
                class="input-primary mat-form-field-10-px-brd-rd custom-filter-header"
                appearance="outline"
              >
                <mat-label class="label-text">{{ 'Quick search...' | translate }}</mat-label>
                <input matInput (keyup)="applyFilter($event)" placeholder="Ex. Product" #input />
                <app-custom-icon
                  class="search-filter"
                  [icon_category]="'search'"
                  [icon_name]="'search-normal-2'"
                ></app-custom-icon>
              </mat-form-field>
            </div>
          </div>

          <div class="toggle-button-wraper">
            <div class="toggle-group">
              <mat-button-toggle-group #group="matButtonToggleGroup" (change)="viewChange($event.value)">
                <div class="table-view toggle">
                  <mat-button-toggle
                    matTooltip="{{ 'List View' | translate }}"
                    matTooltipClass="custom-tooltip-center"
                    matTooltipPosition="above"
                    value="tableView"
                    aria-label="Table View"
                    [checked]="true"
                  >
                    <app-custom-icon [icon_category]="'essential'" [icon_name]="'menu-3lines'"></app-custom-icon>
                  </mat-button-toggle>
                </div>
                <div class="card-view toggle">
                  <mat-button-toggle
                    matTooltip="{{ 'Grid View' | translate }}"
                    matTooltipClass="custom-tooltip-center"
                    matTooltipPosition="above"
                    value="cardView"
                    aria-label="Card View"
                  >
                    <app-custom-icon [icon_category]="'grid'" [icon_name]="'element-3-large'"></app-custom-icon>
                  </mat-button-toggle>
                </div>
              </mat-button-toggle-group>
            </div>
            <div class="create-product-btn">
              <app-rounded-button
                color="primary"
                (click)="openCreateDialog()"
                [button_text]="'Create Product'"
                [icon_category]="'product'"
                [icon_name]="'3d-cube-scan'"
                [icon_right]="true"
                [size]="'md'"
              >
              </app-rounded-button>
            </div>
          </div>
        </mat-card>
        <div class="check-box d-flex justify-content-end">
          <mat-checkbox
            (change)="showDeactivatedProducts($event)"
            [checked]="show_deactivated"
            class="checkbox-primary"
          >
            {{ 'show deactivated products' | translate }}
          </mat-checkbox>
        </div>
      </div>

      <div class="main-holder" *ngIf="productsList.length">
        <!-- Card view -->
        <div class="cards-wraper row" *ngIf="group.value === 'cardView'">
          <mat-card
            *ngFor="let product of productsObservable$ | async; let i = index"
            [ngClass]="archived ? 'archived' : product.is_active ? 'active' : 'inactive'"
            class="custom-card col-12 col-md-6 flex-row"
          >
            <div class="product-img-card">
              <img
                class="preview product-pic"
                src="{{ product.image ? product.image : assetPath + 'images/image-placeholder.jpg' }}"
                alt="Product Image"
              />
            </div>

            <div class="data-actions-holder">
              <mat-card-title-group class="cursor-pointer">
                <div class="title">
                  <mat-card-title class="product-title" routerLink="../../dashboard/product/{{ product.uuid }}"
                    >{{ product.name }}
                  </mat-card-title>
                  <!-- <div class="icon-div">
                    <app-custom-icon (click)="openCreateDialog(product)" [icon_category]="'grid'" [icon_name]="'copy'">
                    </app-custom-icon>
                  </div> -->
                </div>

                <div class="description">
                  <mat-card-subtitle class="product-description">
                    <app-view-rich-text [content]="product.description"></app-view-rich-text>
                  </mat-card-subtitle>
                </div>

                <div class="chains-view-btn align-items-center">
                  <div
                    class="product-chains"
                    [ngClass]="
                      countActiveChains(product.product_chains) === 0 ? 'product-chains-gray' : 'product-chains-green'
                    "
                  >
                    <span class="dot-green">
                      <div class="div-dot"></div>
                    </span>
                    <span class="number">{{ countActiveChains(product.product_chains) }}</span>
                    <span class="text">chains created</span>
                  </div>
                  <div *ngIf="!archived" class="button-div d-flex justify-content-end align-items-center">
                    <app-custom-icon
                      *ngIf="product.is_active"
                      id="eye"
                      [icon_category]="'security'"
                      [icon_name]="'eye'"
                      matTooltip="{{ 'View Product' | translate }}"
                      matTooltipClass="custom-tooltip-center centering"
                      matTooltipPosition="above"
                      routerLink="../../dashboard/product/{{ product.uuid }}"
                    >
                    </app-custom-icon>
                    <app-custom-icon
                      *ngIf="product.is_active"
                      id="copy"
                      [icon_category]="'grid'"
                      [icon_name]="'copy'"
                      matTooltip="{{ 'Duplicate Product' | translate }}"
                      matTooltipClass="custom-tooltip-center"
                      matTooltipPosition="above"
                      (click)="openCreateDialog(product)"
                    >
                    </app-custom-icon>
                    <app-custom-icon
                      *ngIf="product.is_active"
                      id="deactivate"
                      [icon_category]="'essential'"
                      [icon_name]="'deactivate-red'"
                      matTooltip="{{ 'Deactivate Product' | translate }}"
                      matTooltipClass="custom-tooltip-end"
                      matTooltipPosition="above"
                      (click)="deactivateProduct(product)"
                    >
                    </app-custom-icon>
                    <app-custom-icon
                      *ngIf="!product.is_active && !archived"
                      id="activate"
                      [icon_category]="'essential'"
                      [icon_name]="'activate'"
                      matTooltip="{{ 'Reactivate Product' | translate }}"
                      matTooltipClass="custom-tooltip-end"
                      matTooltipPosition="above"
                      (click)="reactivateProduct(product)"
                    ></app-custom-icon>
                  </div>
                  <div
                    *ngIf="archived"
                    class="archived-icon d-flex align-items-center justify-content-end"
                    (mouseover)="mouseEvHandler(true, i)"
                    (mouseout)="mouseEvHandler(false, i)"
                  >
                    <app-custom-icon
                      id="archived"
                      [icon_category]="'essential'"
                      [icon_name]="'archive'"
                    ></app-custom-icon>
                    <p class="m-0">{{ 'archived' | translate }}</p>
                    <div class="tooltip" *ngIf="archived_tooltip && selectedIndex === i">
                      <p>
                        {{ 'This product is archived' | translate }}
                      </p>
                      <p class="example-text">
                        {{ 'Contact your brand administrator to restore the product' | translate }}
                      </p>
                    </div>
                  </div>
                </div>
              </mat-card-title-group>
            </div>
          </mat-card>
        </div>

        <!-- Table view -->
        <div class="table-wraper" *ngIf="group.value === 'tableView'">
          <table class="custom-table" mat-table matSort [dataSource]="tableDataSource">
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Product name' | translate }}</th>
              <td mat-cell *matCellDef="let element">
                <div
                  class="prod-desc"
                  [ngClass]="archived ? 'archived' : element.is_active ? 'description' : 'inactive'"
                >
                  {{ element.name }}
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="description">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Product description' | translate }}</th>
              <td class="table-desc" mat-cell *matCellDef="let element">
                <div
                  class="prod-desc"
                  [ngClass]="archived ? 'archived' : element.is_active ? 'description' : 'inactive'"
                >
                  <app-view-rich-text [content]="element.description" [inheritStyles]="true"></app-view-rich-text>
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="product_chains">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Chains created' | translate }}</th>
              <td mat-cell *matCellDef="let element">
                <div
                  class="product-chains"
                  [ngClass]="
                    archived
                      ? 'archived'
                      : element.is_active
                        ? countActiveChains(element.product_chains) === 0
                          ? 'product-chains-gray'
                          : 'product-chains-green'
                        : 'inactive'
                  "
                >
                  <span class="dot-green">
                    <div class="div-dot"></div>
                  </span>
                  <span class="number">{{ countActiveChains(element.product_chains) }}</span>
                  <span class="text">chains created</span>
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="actions">
              <th mat-header-cell *matHeaderCellDef class="actions-header text-center">
                <div class="d-flex justify-content-end">{{ 'Actions' | translate }}</div>
              </th>
              <td mat-cell *matCellDef="let element; let i = index" class="actions-body text-center">
                <div class="actions-holder" *ngIf="!archived">
                  <app-custom-icon
                    *ngIf="element.is_active"
                    id="eye"
                    [icon_category]="'security'"
                    [icon_name]="'eye'"
                    matTooltip="{{ 'View Product' | translate }}"
                    matTooltipClass="custom-tooltip-center centering"
                    matTooltipPosition="above"
                    routerLink="../../dashboard/product/{{ element.uuid }}"
                  >
                  </app-custom-icon>
                  <app-custom-icon
                    *ngIf="element.is_active"
                    id="copy"
                    (click)="openCreateDialog(element)"
                    [icon_category]="'grid'"
                    [icon_name]="'copy'"
                    matTooltip="{{ 'Duplicate Product' | translate }}"
                    matTooltipClass="custom-tooltip-center"
                    matTooltipPosition="above"
                  >
                  </app-custom-icon>
                  <app-custom-icon
                    *ngIf="element.is_active"
                    id="deactivate"
                    [icon_category]="'essential'"
                    matTooltip="{{ 'Deactivate Product' | translate }}"
                    matTooltipClass="custom-tooltip-end"
                    matTooltipPosition="above"
                    [icon_name]="'deactivate-red'"
                    (click)="deactivateProduct(element)"
                  >
                  </app-custom-icon>
                  <app-custom-icon
                    *ngIf="!element.is_active"
                    id="activate"
                    [icon_category]="'essential'"
                    matTooltip="{{ 'Reactivate Product' | translate }}"
                    matTooltipClass="custom-tooltip-end"
                    matTooltipPosition="above"
                    [icon_name]="'activate'"
                    (click)="reactivateProduct(element)"
                  ></app-custom-icon>
                </div>
                <div
                  class="archived d-flex justify-content-end"
                  *ngIf="archived"
                  (mouseover)="mouseEvHandler(true, i)"
                  (mouseout)="mouseEvHandler(false, i)"
                >
                  <app-custom-icon id="archive" [icon_category]="'essential'" [icon_name]="'archive'"></app-custom-icon>
                  <p class="m-0">{{ 'archived' | translate }}</p>
                  <div class="tooltip" *ngIf="archived_tooltip && selectedIndex === i">
                    <p>
                      {{ 'This product is archived' | translate }}
                    </p>
                    <p class="example-text">
                      {{ 'Contact your brand administrator to restore the product' | translate }}
                    </p>
                  </div>
                </div>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          </table>
        </div>
      </div>

      <div class="card-footer">
        <mat-paginator class="custom-paginator" showFirstLastButtons aria-label="Select page of products">
        </mat-paginator>
      </div>
    </mat-card>
  </div>
</div>
