<div class="dialog-wrapper d-flex flex-column">
  <!-- Breadcrumb -->
  <div class="breadcrump d-flex align-items-center">
    <div class="brand-management-crumb d-flex align-items-center cursor-pointer" mat-dialog-close>
      <app-custom-icon
        class="icon-arrow-l-circle"
        icon_category="arrows"
        icon_name="arrow-circle-left-short"
      ></app-custom-icon>
      <h6>
        <strong>{{ 'Brand Management' | translate }}</strong>
      </h6>
    </div>
    <div class="update-brand-crumb d-flex align-items-center">
      <app-custom-icon icon_category="arrows" icon_name="arrow-right-2"></app-custom-icon>
      <h4>
        <strong>{{ 'Invite Brand User' | translate }}</strong>
      </h4>
    </div>
  </div>
  <div class="horizontal-line"></div>
  <!-- Content -->
  <mat-dialog-content class="m-0 flex-grow-1">
    <div *ngIf="!isInvited; else inviteSuccessful" class="invitation-card d-flex justify-content-between">
      <form [formGroup]="form" class="col-6 d-flex flex-column">
        <div class="input-wraper">
          <mat-form-field class="input-primary" appearance="outline">
            <mat-label>{{ 'User E-mail' | translate }}</mat-label>
            <input matInput formControlName="email" placeholder="{{ 'User E-mail' | translate }}" />
          </mat-form-field>
          <app-custom-icon
            class="indicator"
            [icon_category]="'essential'"
            [icon_name]="'info-circle'"
            [matTooltip]="
              'account-setup.We collect your email address to send you important updates and notifications related to your account. Your email will only be used for these purposes and will not be shared with third parties.'
                | translate
            "
            matTooltipClass="custom-tooltip-center"
          ></app-custom-icon>
        </div>
        <mat-form-field class="input-primary" appearance="outline">
          <mat-label>{{ 'User Role' | translate }}</mat-label>
          <input matInput formControlName="role" placeholder="{{ 'User Role' | translate }}" />
        </mat-form-field>
        <mat-checkbox class="checkbox-primary" formControlName="is_brand_admin">{{
          'Will this User be brand admin?' | translate
        }}</mat-checkbox>
      </form>
      <div class="invitation-note col-6">
        <h5>
          <strong>{{ 'We will send an invitation to the email you provided.' | translate }}</strong>
        </h5>
        <p>
          {{
            'Please choose the correct role for this User. Note that a brand admin can access custom Brand settings.'
              | translate
          }}
        </p>
        <img src="assets/images/invite-brand-user-letter-img.svg" alt="" />
      </div>
    </div>

    <ng-template #inviteSuccessful>
      <div class="invite-successful d-flex flex-column align-items-center justify-content-center full-height">
        <img src="assets/images/invite-brand-user-success.svg" alt="" />
        <div class="text d-flex align-items-center">
          <img src="assets/logos/symbol-logo-dark.svg" alt="" />
          <h4>{{ 'Your invitation was successfully sent!' | translate }}</h4>
        </div>
      </div>
    </ng-template>
  </mat-dialog-content>
  <!-- Action buttons -->
  <mat-dialog-actions align="end">
    <ng-container *ngIf="!isInvited; else okGotIt">
      <app-rounded-button
        class="btn-w-180px"
        color="tetriary-link underlined"
        button_text="{{ 'Cancel' | translate }}"
        size="md"
        mat-dialog-close
      >
      </app-rounded-button>
      <app-rounded-button
        class="btn-w-180px"
        color="turquoise"
        button_text="{{ 'Send Invite' | translate }}"
        size="md"
        [disabled]="form.invalid"
        (click)="inviteBrandUser()"
      >
      </app-rounded-button>
    </ng-container>
    <ng-template #okGotIt>
      <app-rounded-button
        class="btn-w-180px"
        color="turquoise"
        button_text="{{ 'Ok, Got it' | translate }}"
        size="md"
        (click)="dialogRef.close(invitedUser)"
      >
      </app-rounded-button>
    </ng-template>
  </mat-dialog-actions>
</div>
