import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { ObservableInput } from 'rxjs/internal/types';
import { catchError } from 'rxjs/internal/operators/catchError';
import { take } from 'rxjs/internal/operators/take';
import { throwError } from 'rxjs/internal/observable/throwError';
import { TasksService } from '../service/tasks.service';
import {
  ITask,
  ITaskResponse,
  ITasksObjectResponse,
  ITasksResponse,
  ITasksTypesResponse,
  ITasksUsersResponse,
} from '../interface/tasks.interface';
import { generateFormData } from '../utils/generate-formData';
import { forkJoin } from 'rxjs';

@Injectable()
export class TasksFacade {
  constructor(private _tasksService: TasksService) {}

  getTasks$(): Observable<ITasksResponse> {
    return this._tasksService.getTasks$().pipe(
      take(1),
      catchError(({ error }: ITasksResponse): ObservableInput<ITasksResponse> => throwError(() => error))
    );
  }

  deleteTask$(uuid: string): Observable<ITasksResponse> {
    return this._tasksService.deleteTask$(uuid).pipe(
      take(1),
      catchError(({ error }: ITasksResponse): ObservableInput<ITasksResponse> => throwError(() => error))
    );
  }

  getTasksObjects$(): Observable<ITasksObjectResponse> {
    return this._tasksService.getTasksObjects$().pipe(
      take(1),
      catchError(({ error }: ITasksObjectResponse): ObservableInput<ITasksObjectResponse> => throwError(() => error))
    );
  }
  getTasksTypes$(): Observable<ITasksTypesResponse> {
    return this._tasksService.getTasksTypes$().pipe(
      take(1),
      catchError(({ error }: ITasksTypesResponse): ObservableInput<ITasksTypesResponse> => throwError(() => error))
    );
  }
  getTasksUsers$(): Observable<ITasksUsersResponse> {
    return this._tasksService.getTasksUsers$().pipe(
      take(1),
      catchError(({ error }: ITasksUsersResponse): ObservableInput<ITasksUsersResponse> => throwError(() => error))
    );
  }

  createTask$(data: Partial<ITask>): Observable<ITaskResponse> {
    const formData = generateFormData(data);
    return this._tasksService.createTask$(formData).pipe(
      take(1),
      catchError(({ error }: ITaskResponse): ObservableInput<ITaskResponse> => throwError(() => error))
    );
  }
  mergeTasksdata(): Observable<[ITasksObjectResponse, ITasksUsersResponse, ITasksTypesResponse]> {
    return forkJoin([this.getTasksObjects$(), this.getTasksUsers$(), this.getTasksTypes$()]);
  }
}
