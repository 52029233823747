import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { infoDialogHeight, infoDialogWidth } from '@app/core/constants';

import { IErrorResponse } from '@app/core/interface/manufactories.interface';
import { ISuppManufactory } from '@app/core/interface/supp.interface';
import { InfoDialogComponent } from '@app/shared/components/info-dialog/info-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { SiteDialogComponent } from '../site-dialog/site-dialog.component';
import { SuppSuppliersFacade } from '@app/core/facade/supp-suppliers.facade';

@Component({
  selector: 'app-supplier-sites-table',
  templateUrl: './supplier-sites-table.component.html',
  styleUrls: ['./supplier-sites-table.component.scss'],
  providers: [SuppSuppliersFacade],
})
export class SupplierSitesTableComponent implements OnChanges, OnInit {
  @Input() sites!: ISuppManufactory[];
  @Input() isEdit = true;
  @Output() refresh: EventEmitter<boolean> = new EventEmitter<boolean>();

  deleteUuid = '';
  displayedColumns: string[] = ['name', 'country', 'region', 'city', 'address', 'actions'];
  tableDataSource = new MatTableDataSource<ISuppManufactory>();
  constructor(
    private _dialog: MatDialog,
    private _facade: SuppSuppliersFacade
  ) {}

  ngOnInit(): void {
    // Remove actions column if not in edit mode
    if (!this.isEdit) {
      this.displayedColumns.pop();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['sites']) this.tableDataSource.data = changes['sites'].currentValue;
  }

  editSiteDialog(_site: ISuppManufactory) {
    const dialogRef = this._dialog.open(SiteDialogComponent, {
      width: '1160px',
      height: '768px',
      panelClass: 'top-padding-0',
      data: { supplierUuid: 'edit', site: _site },
    });

    dialogRef.afterClosed().subscribe((result: ISuppManufactory) => {
      result && this.refresh.emit(true);
    });
  }
  deleteSiteDialog(_site: ISuppManufactory): void {
    this.deleteUuid = _site.uuid;
    const dialogRef = this._dialog.open(InfoDialogComponent, {
      width: infoDialogWidth,
      height: infoDialogHeight,
      data: {
        infoText:
          'You are about to delete this site from the Supplier. Please note that if you proceed with deleting this supplier site, the changes cannot be undone.',
        confirmationText: 'Do you wish to continue?',
        btnText: 'Yes, Delete',
        type: 'warning',
        text: 'warning-text',
      },
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this._facade.deleteSupplierSite$(this.deleteUuid).subscribe({
          next: this._removeDeletedItem.bind(this),
          error: this._error.bind(this),
        });
      }
    });
  }

  private _removeDeletedItem(): void {
    this.tableDataSource.data = this.tableDataSource.data.filter(row => row.uuid !== this.deleteUuid);
  }

  private _error(error: IErrorResponse): void {
    this._dialog.open(InfoDialogComponent, {
      width: infoDialogWidth,
      height: infoDialogHeight,
      data: {
        infoText: error.detail,
        btnText: 'Ok, Got It',
        type: 'forbidden',
        text: 'forbidden-text',
      },
    });
  }
}
