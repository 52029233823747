import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntypedFormGroup } from '@angular/forms';
import { IPublicationGroup } from '@interface/publication.interface';
import { ChainOverviewGroup } from '@module/publication/chain-overview/chain-overview.group';
import { PublicationFacade } from '@facade/publication.facade';
import { SnackbarService } from '@service/snackbar.service';
import { NotificationType } from '@app/core/constants';
import { CustomPublicationsFacade } from '@module/publication/custom-publications/custom-publications.facade';

@Component({
  selector: 'app-amway-create-group',
  templateUrl: './amway-create-group.component.html',
  styleUrl: './amway-create-group.component.scss',
})
export class AmwayCreateGroupComponent implements OnInit, OnDestroy {
  form: UntypedFormGroup;
  selectedSteps: string[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      product_chain_uid: string;
      group?: IPublicationGroup;
      type_step: string;
    },
    private _group: ChainOverviewGroup,
    private _facade: PublicationFacade,
    private _customPublication: CustomPublicationsFacade,
    private dialogRef: MatDialogRef<AmwayCreateGroupComponent>,
    private _snackbarService: SnackbarService
  ) {
    this.form = this._group.createGroupForm;
    this.form.removeControl('productchain_uuid');
    this.form.patchValue({
      type_step: this.data.type_step,
    });
  }

  ngOnInit(): void {
    if (this.data.group) {
      this.form.patchValue({
        name: this.data.group.name,
      });
    }
  }

  ngOnDestroy(): void {
    this.data.group = undefined;
    this.form.reset();
  }

  saveChanges(): void {
    this._customPublication.createPublicationGroup$({ ...this.form.value }).subscribe({
      next: this.createGroupSuccess.bind(this),
      error: this._error.bind(this),
    });
  }

  createGroupSuccess(data: IPublicationGroup) {
    this.dialogRef.close(data);
  }

  updateGroup(): void {
    if (this.data.group) {
      this._customPublication.updatePublicationGroup$({ ...this.form.value }, this.data.group.uuid).subscribe(() => {
        this.dialogRef.close({ ...this.form.value });
      });
    }
  }

  deleteGroup(uuid: string): void {
    this._facade.deletePublicationGroup$(uuid).subscribe({
      next: () => this.dialogRef.close(true),
      error: this._error.bind(this),
    });
  }

  private _error(error: Record<string, string[]>): void {
    Object.values(error).map(err => this._snackbarService.openTypeSnackbar(err[0], NotificationType.error));
  }
}
