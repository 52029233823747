import { ChangeDetectorRef, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { ProductTypeComponent } from './product-type/product-type.component';
import { TracebilityStepsComponent } from './tracebility-steps/tracebility-steps.component';
import { RecycledMaterialsComponent } from './recycled-materials/recycled-materials.component';
import { PresencePlasticMicrofiberComponent } from './presence-plastic-microfiber/presence-plastic-microfiber.component';
import { PackagingComponent } from './packaging/packaging.component';
import { RegulationsFacade } from '@app/core/facade/regulations.facade';
import {
  IAgecRegulation,
  IAgecRegulationResponse,
  IAgecTracebility,
  SheetCategory,
  TracebilityStepOne,
  TracebilityStepThree,
  TracebilityStepTwo,
} from '@app/core/interface/regulations.interface';
import { IProduct } from '@app/core/interface/products.interface';
import { NotificationType } from '@app/core/constants';
import { SnackbarService } from '@app/core/service/snackbar.service';

@Component({
  selector: 'app-create-digital-product-sheet-dialog',
  templateUrl: './create-digital-product-sheet-dialog.component.html',
  styleUrls: ['./create-digital-product-sheet-dialog.component.scss'],
  providers: [RegulationsFacade],
})
export class CreateDigitalProductSheetDialogComponent implements OnInit {
  @ViewChild('sheetSteper') stepper!: MatStepper;
  @ViewChild(ProductTypeComponent) productTypeComponent!: ProductTypeComponent;
  @ViewChild(TracebilityStepsComponent) tracebilityStepsComponent!: TracebilityStepsComponent;
  @ViewChild(RecycledMaterialsComponent) recycledMaterialsComponent!: RecycledMaterialsComponent;
  @ViewChild(PresencePlasticMicrofiberComponent)
  presencePlasticMicrofiberComponent!: PresencePlasticMicrofiberComponent;
  @ViewChild(PackagingComponent) packagingComponent!: PackagingComponent;
  sheet?: IAgecRegulation;
  selectedStepIndex = 0;
  refreshData = false;
  tab = '';
  uuid = '';
  initial = true;
  product?: IProduct;
  showTooltip = false;
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { regulation: string; uuid: string; brand_regulation?: string; product?: IProduct },
    public dialogRef: MatDialogRef<CreateDigitalProductSheetDialogComponent>,
    private cdr: ChangeDetectorRef,
    private _facade: RegulationsFacade,
    private _snackbarService: SnackbarService
  ) {
    this.tab = this.data.regulation;
    this.product = this.data?.product;
  }

  ngOnInit(): void {
    this.data.uuid && this.getDigitalSheet();
  }

  getDigitalSheet(): void {
    this._facade.getDigitalSheet$(this.data.regulation, this.data.uuid).subscribe({
      next: this._success.bind(this),
      error: this._error.bind(this),
    });
  }

  close(): void {
    this.dialogRef.close(this.refreshData);
  }

  nextStep(): void {
    if (this.selectedStepIndex < this.stepper._steps.length - 1) {
      this.selectedStepIndex++;
      this.stepper.selectedIndex = this.selectedStepIndex;
      this.cdr.detectChanges();
    }
  }
  prevStep(): void {
    if (this.selectedStepIndex > 0) {
      this.selectedStepIndex--;
      this.stepper.selectedIndex = this.selectedStepIndex;
      this.cdr.detectChanges();
    }
  }
  updateStep(): void {
    /**
     * Fifth Step
     */
    if (this.selectedStepIndex === 4) {
      this.dialogRef.close(this.refreshData);
    }
    /**
     * Forth Step
     */
    if (this.selectedStepIndex === 3) {
      if (this.presencePlasticMicrofiberComponent.form.dirty) {
        const dataRecyled = this.getDataRecyled();
        const steps_completed = (this.sheet?.steps_completed ?? 0) > 4 ? this.sheet?.steps_completed : 4;

        const formValue = this.presencePlasticMicrofiberComponent.form.value;
        const data = formValue['hazardous_list']
          ? {
              is_hazardous_substance: formValue['hazardous_substances'],
              is_plastic_microfibers: formValue['plastic_presetce'],
              hazardous_substance: formValue['hazardous_list'],
              ...dataRecyled,
              steps_completed: steps_completed,
            }
          : {
              is_hazardous_substance: formValue['hazardous_substances'],
              is_plastic_microfibers: formValue['plastic_presetce'],
              ...dataRecyled,
              steps_completed: steps_completed,
            };
        this.updateDigitalSheet(data);
      } else {
        this.nextStep();
      }
    }
    /**
     * Third Step
     */
    if (this.selectedStepIndex === 2) {
      if (this.recycledMaterialsComponent.form.dirty) {
        const val: boolean | undefined = this.recycledMaterialsComponent?.selectedType?.value;
        const formValue = this.recycledMaterialsComponent.form.value;
        const steps_completed = (this.sheet?.steps_completed ?? 0) > 3 ? this.sheet?.steps_completed : 3;
        const data = val
          ? {
              is_recycled_material: val,
              recycled_material_percentage: formValue['percentage'],
              steps_completed: steps_completed,
            }
          : {
              is_recycled_material: val,
              is_recycled_materials_display: this.recycledMaterialsComponent.showInfo,
              steps_completed: steps_completed,
            };
        this.updateDigitalSheet(data);
      } else {
        this.nextStep();
      }
    }
    /**
     * Second Step
     */
    if (this.selectedStepIndex === 1) {
      if (this.tracebilityStepsComponent?.dirty) {
        this.tracebilityStepsComponent.setData();
        const stepOneType = this._facade.isKeyOfFirstStep(this.tracebilityStepsComponent.data[0].choice)
          ? TracebilityStepOne[this.tracebilityStepsComponent.data[0].choice]
          : undefined;
        const stepTwoType = this._facade.isKeyOfSecondStep(this.tracebilityStepsComponent.data[1].choice)
          ? TracebilityStepTwo[this.tracebilityStepsComponent.data[1].choice]
          : undefined;
        const stepThreeType = this._facade.isKeyOfThirdStep(this.tracebilityStepsComponent.data[2].choice)
          ? TracebilityStepThree[this.tracebilityStepsComponent.data[2].choice]
          : undefined;
        const data: Partial<IAgecTracebility> = {
          agec: this.sheet?.uuid,
          step_one_country: this.tracebilityStepsComponent.data[0].country.code,
          step_two_country: this.tracebilityStepsComponent.data[1].country.code,
          step_three_country: this.tracebilityStepsComponent.data[2].country.code,
          step_one_type: stepOneType,
          step_two_type: stepTwoType,
          step_three_type: stepThreeType,
          steps_completed: (this.sheet?.steps_completed ?? 0) > 2 ? this.sheet?.steps_completed : 2,
        };
        if (this.sheet?.traceability_steps?.length) {
          this._facade
            .updateTracebilityStep$(data, this.sheet?.traceability_steps[0].uuid ?? '', this.data.regulation)
            .subscribe({
              next: this.nextStepAfterUpdate.bind(this),
              error: this._error.bind(this),
            });
        } else {
          this._facade.createTracebilityStep$(data, this.data.regulation).subscribe({
            next: this.nextStepAfterTracebilityStepCreated.bind(this),
            error: this._error.bind(this),
          });
        }
      } else {
        this.nextStep();
      }
    }
    /**
     * First Step
     */
    if (this.selectedStepIndex === 0) {
      if (this.productTypeComponent?.dirty) {
        const val: string = this.productTypeComponent?.selectedType?.name ?? '';
        if (this._facade.isKeyOfSheetCategory(val)) {
          let data = {
            product_type: SheetCategory[val],
            steps_completed: (this.sheet?.steps_completed ?? 0) > 1 ? this.sheet?.steps_completed : 1,
          };
          if ((data.steps_completed ?? 0) > 1) {
            const dataRecyled = this.getDataRecyled();
            data = { ...data, ...dataRecyled };
          }
          this.uuid ? this.updateDigitalSheetFromStepOne(data) : this.createDigitalSheet(data);
        }
      } else {
        this.nextStep();
      }
    }
  }
  getDataRecyled() {
    const val: boolean | undefined = this.recycledMaterialsComponent?.selectedType?.value;
    const formValueRecyled = this.recycledMaterialsComponent.form.value;
    const dataRecyled = val
      ? { is_recycled_material: val, recycled_material_percentage: formValueRecyled['percentage'] }
      : { is_recycled_material: val, is_recycled_materials_display: this.recycledMaterialsComponent.showInfo };
    return dataRecyled;
  }
  private updateDigitalSheetFromStepOne(data: Partial<IAgecRegulation>) {
    this.tracebilityStepsComponent.dirty = true;
    this.updateDigitalSheet(data);
  }
  private _success(response: IAgecRegulation): void | undefined {
    if (!this.initial) return;
    let selInd = 1;
    this.sheet = response;
    if (response) {
      this.uuid = response.uuid;
      const steps_completed = response.steps_completed ?? 0;
      /**
       * First Step
       */
      if (response.product_type && steps_completed >= 1) {
        this.productTypeComponent.selectedType = this.productTypeComponent.types.find(
          type => type.name === SheetCategory[response.product_type]
        );
        /**
         * Second Step setup is in the timeout at the end of this function
         */
        if (response.traceability_steps.length) {
          this.tracebilityStepsComponent.setDataFromResponse(response.traceability_steps);
          selInd += 1;
        }
      }
      /**
       * Third Step
       */
      if (response.is_recycled_material !== null && steps_completed >= 3) {
        /**
         * Set type if available
         */
        this.recycledMaterialsComponent.onSelection(
          this.recycledMaterialsComponent.types.find(type => type.value === response.is_recycled_material)
        );
        this.recycledMaterialsComponent.selectedType &&
          this.recycledMaterialsComponent.selectedType.uuid > -1 &&
          this.recycledMaterialsComponent.form.controls['recycled_materials'].setValue(
            this.recycledMaterialsComponent.selectedType
          );
        /**
         * Set percentage if is recycled material
         */
        if (response.is_recycled_material) {
          this.recycledMaterialsComponent.form.controls['percentage'].setValue(response.recycled_material_percentage);
        }
        /**
         * Set is_recycled_materials_display if available
         */
        if (response.is_recycled_materials_display !== null && response.is_recycled_materials_display !== undefined)
          this.recycledMaterialsComponent.showInfo = response.is_recycled_materials_display;
        selInd += 1;
      }

      /**
       * Forth Step
       */
      if (
        (response.is_hazardous_substance !== null || response.is_plastic_microfibers !== null) &&
        steps_completed >= 4
      ) {
        this.presencePlasticMicrofiberComponent.form.controls['hazardous_substances'].setValue(
          response.is_hazardous_substance
        );
        this.presencePlasticMicrofiberComponent.form.controls['plastic_presetce'].setValue(
          response.is_plastic_microfibers
        );
        if (response.hazardous_substances.length) {
          response.hazardous_substances.forEach(subsctance => {
            this.presencePlasticMicrofiberComponent.addInput(subsctance.name);
          });
          /**
           * Remove controls added on hazardous_substancesvalueChanges in presencePlasticMicrofiberComponent
           */
          this.presencePlasticMicrofiberComponent.removeInput(0);
        }
        selInd += 1;
      }

      /**
       * Fifth Step
       */
      if (response.packaging && response.packaging.length && steps_completed >= 5) {
        this.packagingComponent.packaging = response.packaging;
      }
      setTimeout(() => {
        this.selectedStepIndex = selInd;
        this.stepper.selectedIndex = this.selectedStepIndex;
        this.cdr.detectChanges();
        this.initial = false;
      }, 800);
    }
  }

  createDigitalSheet(data: Partial<IAgecRegulation>) {
    const payload = { ...data, regulation: this.data.brand_regulation, product: this.data.product?.uuid };
    this._facade.createDigitalSheet$(payload, this.data.regulation).subscribe({
      next: this.nextStepAfterCreate.bind(this),
      error: this._error.bind(this),
    });
  }

  updateDigitalSheet(data: Partial<IAgecRegulation>) {
    const uuid = this.uuid ? this.uuid : this.sheet?.uuid ?? '';
    this._facade.updateDigitalSheet$(data, uuid, this.data.regulation).subscribe({
      next: this.nextStepAfterUpdate.bind(this),
      error: this._error.bind(this),
    });
  }
  nextStepAfterUpdate() {
    this.refreshData = true;
    this.nextStep();
  }
  nextStepAfterCreate(res: IAgecRegulation) {
    this.sheet = res;
    this.refreshData = true;
    this.nextStep();
  }
  nextStepAfterTracebilityStepCreated(res: IAgecTracebility) {
    this.sheet = { ...this.sheet, traceability_steps: [res] } as IAgecRegulation;
    this.refreshData = true;
    this.nextStep();
  }
  onHover(visible: boolean): void {
    this.showTooltip = visible;
  }
  onHoverOut(): void {
    this.showTooltip = false;
  }
  private _error(error: IAgecRegulationResponse): void {
    Object.values(error).map(err => this._snackbarService.openTypeSnackbar(err[0], NotificationType.error));
  }
}
