import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { Injectable } from '@angular/core';

@Injectable()
export class BrandManagementGroup {
  updateBrandForm: UntypedFormGroup;
  inviteBrandUser: UntypedFormGroup;

  constructor(private _fb: UntypedFormBuilder) {
    this.updateBrandForm = this._fb.group({
      name: [null, [Validators.required]],
      logo: [null],
      banner: [null],
      description: [null],
      domain_activity: [null, [Validators.required]],
      traceability_commitment: [null, [Validators.required]],
      external_url: [null],
      rc_section_title: [null],
    });

    this.inviteBrandUser = this._fb.group({
      email: [
        null,
        [
          Validators.required,
          Validators.email,
          Validators.pattern('[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,3}$'),
        ],
      ],
      is_brand_admin: [false],
      role: [null],
    });
  }
}
