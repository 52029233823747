<mat-card class="col-11 col-lg-8 offset-lg-2 select-bar-wraper">
  <div class="row">
    <div class="col-9">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'Select Role' | translate }}</mat-label>
        <mat-select [value]="rolesList[0]?.uuid">
          <mat-option *ngFor="let role of rolesList" [value]="role.uuid" placeholder="Selected role">
            {{ role.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-3">
      <button class="add-role-btn full-width" mat-raised-button color="primary">ADD ROLE</button>
    </div>
  </div>

  <div class="row">
    <div class="col-9">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'Select User' | translate }}</mat-label>
        <mat-select [formControl]="brand_user" [value]="usersList[0]">
          <mat-option *ngFor="let user of usersList" [value]="user" placeholder="Selected User">
            {{ user.first_name }} {{ user.last_name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
</mat-card>

<mat-accordion class="col-11 col-lg-8 offset-lg-2">
  <mat-expansion-panel #expansionPanel expanded>
    <mat-expansion-panel-header>
      <mat-panel-title> Page: Products Dashboard </mat-panel-title>
      <mat-panel-description>
        Click to {{ expansionPanel.expanded ? 'hide' : 'show' }} permissions
      </mat-panel-description>
    </mat-expansion-panel-header>
    <div class="row field-access-row" *ngFor="let permission of permissionsListForListing">
      <div class="col">{{ permission.name }}</div>
      <div class="col-auto">
        <mat-slide-toggle
          color="primary"
          [checked]="permission.is_active"
          [id]="permission.uuid"
          (change)="changePermissionStatus($event)"
        ></mat-slide-toggle>
      </div>
    </div>
  </mat-expansion-panel>
</mat-accordion>
