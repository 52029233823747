import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CertificateManagementComponent } from './certificate-management.component';
import { CertificateManagementRoutingModule } from './certificate-management-routing.module';
import { SharedModule } from '@app/shared/shared.module';
import { CertificatesFacade } from '@app/core/facade/certificates.facade';

@NgModule({
  declarations: [CertificateManagementComponent],
  imports: [CommonModule, CertificateManagementRoutingModule, SharedModule],
  providers: [CertificatesFacade],
})
export class CertificateManagementModule {}
