import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FeaturesManagementComponent } from './features-management.component';
import { FeaturesManagementRoutingModule } from './features-management-routing.module';
import { SharedModule } from '@app/shared/shared.module';
import { FormsModule } from '@angular/forms';
import { RegulationsComponent } from './regulations/regulations.component';

@NgModule({
  declarations: [FeaturesManagementComponent, RegulationsComponent],
  imports: [CommonModule, FeaturesManagementRoutingModule, SharedModule, FormsModule],
})
export class FeaturesManagementModule {}
