<div class="dialog-header">
  <h2>{{ 'Product Details' | translate }}</h2>
  <app-custom-icon mat-dialog-close [icon_category]="'essential'" [icon_name]="'close-circle'" class="cursor-pointer">
  </app-custom-icon>
</div>
<div class="holder">
  <!-- If no custom fields show just standard fields, else show tabs -->
  <div *ngIf="!product.custom_fields.length; else tabs" [ngTemplateOutlet]="standardFields"></div>

  <!------------------------------ Tabs ------------------------------>
  <ng-template #tabs>
    <mat-tab-group (selectedTabChange)="tabChanged($event)">
      <!-- Standard fields -->
      <mat-tab [label]="'Details' | translate">
        <ng-template matTabContent>
          <ng-container [ngTemplateOutlet]="standardFields"></ng-container>
        </ng-template>
      </mat-tab>
      <!-- Custom Fields tab -->
      <mat-tab>
        <ng-template mat-tab-label>
          <div class="custom-fields-label">
            <p class="m-0">{{ 'Custom Fields' | translate }}</p>
            <div [ngClass]="showRedCircle ? 'red-circle' : 'd-none'"></div>
          </div>
        </ng-template>
        <ng-template matTabContent>
          <div class="info-holder flex-row justify-content-between pt-0">
            <div class="info-header">
              <div class="name">
                <h6>
                  <strong>{{ product.name }}</strong>
                </h6>
              </div>
            </div>
            <div
              class="chains"
              [ngClass]="{
                'chains-green': product.product_chains && product.product_chains.length > 0,
                'chains-gray': product.product_chains && product.product_chains.length === 0,
              }"
            >
              <div class="dot"></div>
              <p>{{ chains_number }} {{ 'chains created' | translate }}</p>
            </div>
          </div>
          <div class="pt-24">
            <app-view-custom-fields-details [customFields]="product.custom_fields"></app-view-custom-fields-details>
          </div>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </ng-template>

  <!-- Reusable html block for standard fields (because it is used inside the two tabs) -->
  <ng-template #standardFields>
    <div class="details-holder d-flex flex-column">
      <div class="supplier-info">
        <div class="suplier-logo">
          <app-file-upload
            [placeholder]="product.image ? product.image : ('Logo' | translate)"
            [iconType]="'add_photo_alternate'"
            [imageUrl]="product.image"
            [readonly]="true"
          ></app-file-upload>
        </div>
        <div class="info-holder flex-column">
          <div class="info-header">
            <div class="name">
              <h5>
                {{ product.name }}
              </h5>
            </div>
          </div>
          <div
            class="chains"
            [ngClass]="{
              'chains-green': product.product_chains && product.product_chains.length > 0,
              'chains-gray': product.product_chains && product.product_chains.length === 0,
            }"
          >
            <div class="dot"></div>
            <p>{{ chains_number }} {{ 'chains created' | translate }}</p>
          </div>
        </div>
      </div>
      <div class="divider"></div>
      <div class="product-description">
        <h6>{{ 'Product Description' | translate }}</h6>
        <p class="product-description-txt" *ngIf="product.description; else noneblockDesc">
          <app-view-rich-text [content]="product.description"></app-view-rich-text>
        </p>
      </div>
      <div class="product-reference-number">
        <h6>{{ 'Product Reference Number' | translate }}</h6>
        <p *ngIf="product.reference; else noneblockRef">
          {{ product.reference }}
        </p>
      </div>
      <ng-template #noneblockDesc>
        <span class="none-p">
          {{ 'product description not entered' | translate }}
        </span>
      </ng-template>
      <ng-template #noneblockRef>
        <span class="none-p">
          {{ 'product reference not entered' | translate }}
        </span>
      </ng-template>
    </div>
  </ng-template>
</div>
