import { Component } from '@angular/core';
import { ProductChainFacade } from '@app/core/facade/productchain.facade';

@Component({
  selector: 'app-product-chain',
  templateUrl: './product-chain.component.html',
  styleUrls: ['./product-chain.component.scss'],
  providers: [ProductChainFacade],
})
export class ProductChainComponent {}
