<div class="background">
  <div class="holder">
    <mat-card class="wrapper test">
      <div class="header-wrapper d-flex justify-content-between flex-column">
        <h6 class="title m-0">{{ 'Manage Archived Objects' | translate }}</h6>
      </div>
      <mat-tab-group class="archived-mat-tab">
        <mat-tab [label]="'Products'">
          <ng-template matTabContent>
            <app-manage-products></app-manage-products>
          </ng-template>
        </mat-tab>
        <mat-tab [label]="'Supply chains' | translate">
          <ng-template matTabContent> </ng-template>
        </mat-tab>
        <mat-tab [label]="'Suppliers' | translate">
          <ng-template matTabContent> </ng-template>
        </mat-tab>
        <mat-tab [label]="'Certificates' | translate">
          <ng-template matTabContent> </ng-template>
        </mat-tab>
      </mat-tab-group>
    </mat-card>
  </div>
</div>
