<div class="wraper">
  <div class="row">
    <div class="col-12 confidential-info" *ngIf="isSupplierConfidential">
      <p>This supplier is confidential. Only the Country will be visible when Published</p>
    </div>
  </div>
  <div class="row" [ngClass]="{ 'confidential-supplier': isSupplierConfidential }">
    <div class="col-6">
      <div class="supplier" [formGroup]="supplierForm">
        <h3>{{ 'Supplier Details' | translate }}</h3>
        <h5>{{ 'Supplier Logo and Name' | translate }}</h5>
        <div class="name-logo">
          <app-file-upload
            [iconType]="'add_photo_alternate'"
            (fileData)="saveImageData($event)"
            [status]="supplierForm.controls['logo'].status"
            [imageUrl]="step?.supplier?.logo ? step?.supplier?.logo : supplier?.logo"
          >
          </app-file-upload>
          <mat-form-field appearance="outline" class="input-primary">
            <mat-label>{{ 'Supplier Name' | translate }}</mat-label>
            <input type="text" matInput formControlName="name" [matAutocomplete]="auto" />
            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onSupplierChange($event)">
              <mat-option *ngFor="let supplier of filteredSuppliers | async" [value]="supplier">{{
                supplier.name
              }}</mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>

        <h5>{{ 'Supplier Description' | translate }}</h5>
        <div class="input-primary text-area textarea" appearance="outline">
          <mat-label>{{ 'Supplier Description' | translate }}</mat-label>
          <!-- <textarea
            matInput
            formControlName="description"
            placeholder="Enter description"
            rows="6"
            appTextareaAutoResize
          ></textarea> -->
          <div class="wrp">
            <app-rs-rte
              formControlName="description"
              [c]="$any(supplierForm.controls['description'])"
              [placeholder]="'Supplier Description' | translate"
            ></app-rs-rte>
          </div>
        </div>

        <div class="supplier-media" *ngIf="supplier">
          <h5>{{ 'Supplier Documents' | translate }}</h5>
          <div class="note">
            <app-custom-icon [icon_category]="'essential'" [icon_name]="'danger'"></app-custom-icon>
            <span>{{
              'Please note that the media of the supplier will be displayed in every step he is in.' | translate
            }}</span>
          </div>
          <app-step-media-accordion
            [canUpload]="true"
            [canDelete]="true"
            [photos]="photos"
            [videos]="videos"
            [documents]="supplier?.documents"
            [typeUuid]="supplier?.uuid"
            [certificates]="supplier?.certifications"
            [type]="'publication/suppliers'"
            (refreshStep)="refreshSupplier.emit(true)"
            [hideUploadCertificates]="false"
          ></app-step-media-accordion>
        </div>
      </div>
    </div>
    <div class="col-6 map-wraper">
      <div class="manufactory mb-4" [formGroup]="manufactoryForm">
        <h5>{{ 'Supplier Site Location' | translate }}</h5>
        <div class="note">
          <app-custom-icon [icon_category]="'essential'" [icon_name]="'info-circle'"></app-custom-icon>
          <span>{{ 'Add at least 1 of the 3: (Address, City or Country) to create the step' | translate }}</span>
        </div>
        <!-- <mat-form-field class="input-primary" appearance="outline">
          <mat-label>{{ 'Enter Address' | translate }}</mat-label>
          <input matInput formControlName="address" placeholder="{{ 'Enter Address' | translate }}" />
        </mat-form-field> -->
        <mat-form-field appearance="outline" class="input-primary">
          <mat-label>{{ 'Enter Address' | translate }}</mat-label>
          <input type="text" matInput formControlName="address" [matAutocomplete]="auto1" />
          <mat-autocomplete #auto1="matAutocomplete" (optionSelected)="onAddressChange($event)">
            <mat-option *ngFor="let manufactory of filteredManufactories | async" [value]="manufactory">{{
              manufactory.name
            }}</mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <div class="row">
          <div class="col-6 ps-0">
            <mat-form-field class="input-primary" appearance="outline">
              <mat-label>{{ 'Enter City' | translate }}</mat-label>
              <input matInput formControlName="city" placeholder="{{ 'Enter City' | translate }}" />
            </mat-form-field>
          </div>
          <div class="col-6 pe-0">
            <mat-form-field class="input-primary" appearance="outline">
              <mat-label>{{ 'Enter Country' | translate }}</mat-label>
              <input matInput formControlName="country" placeholder="{{ 'Enter Country' | translate }}" />
            </mat-form-field>
          </div>
        </div>
      </div>

      <div class="note">
        <app-custom-icon [icon_category]="'essential'" [icon_name]="'info-circle'"></app-custom-icon>
        <span>{{
          step
            ? 'Please note that if you update the supplier address, in order to get updated pin on the map press the “Update Pin” button.'
            : ('Please add supplier address and press the “Generate Pin” button, to get a preview pin on the map.'
              | translate)
        }}</span>
      </div>
      <app-rounded-button
        color="success"
        [button_text]="step ? 'Update Pin' : 'Generate Pin'"
        [size]="'md'"
        [disabled]="!validateMapFields()"
        (click)="generatePin()"
      >
      </app-rounded-button>
      <mgl-map [style]="'mapbox://styles/mapbox/light-v11'" [zoom]="[6]" [center]="mapCenter">
        <mgl-marker [lngLat]="mapCenter">
          <app-custom-icon [style]="'fill: red'" [icon_category]="'location'" [icon_name]="'exclude'"></app-custom-icon>
        </mgl-marker>
      </mgl-map>
    </div>
  </div>
</div>
