import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { IEmailTemp, IEmailTemplateResponse } from '@app/core/interface/email.interface';

import { ActivatedRoute } from '@angular/router';
import { CreateEmailContentComponent } from './create-email-content/create-email-content.component';
import { EmailDialogComponent } from '@app/shared/components/email-dialog/email-dialog.component';
import { EmailFacade } from '@app/core/facade/email.facade';
import { MatDialog } from '@angular/material/dialog';
import { NotificationType } from '@app/core/constants';
import { SnackbarService } from '@app/core/service/snackbar.service';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/internal/operators/take';

@Component({
  selector: 'app-modify-template',
  templateUrl: './modify-template.component.html',
  styleUrls: ['./modify-template.component.scss'],
})
export class ModifyTemplateComponent implements OnInit, OnDestroy {
  @Input() public data: IEmailTemp = {
    brand: '',
    content: '',
    email_template_type: {
      language: '',
      type: '',
      uuid: '',
    },
    name: '',
  };
  subscription?: Subscription;
  constructor(
    private _route: ActivatedRoute,
    private _dialog: MatDialog,
    private _facade: EmailFacade,
    private _snackbarService: SnackbarService
  ) {}
  disabled = true;
  name = '';
  @ViewChild(CreateEmailContentComponent) createEmailContentComponent!: CreateEmailContentComponent;
  ngOnInit(): void {
    this._route.params.pipe(take(1)).subscribe(params => {
      if (params['id']) {
        this.data.uuid = params['id'];
        this.getEmailTemplate();
      }
    });
  }
  ngOnDestroy(): void {
    if (this.subscription) this.subscription.unsubscribe();
  }
  ngAfterViewInit(): void {
    if (this.data) this.setForm();
    this.subscription = this.createEmailContentComponent.form.valueChanges.subscribe(() => {
      this.disabled = this.createEmailContentComponent.form.invalid || this.createEmailContentComponent.form.pristine;
    });
  }
  setForm(): void {
    // setTimeout(() => {
    this.name = this.data.name;
    this.createEmailContentComponent.form.controls['name'].setValue(this.data.name);
    this.createEmailContentComponent.form.controls['content'].setValue(this.data.content);
    this.createEmailContentComponent.form.markAsPristine();
    // }, 0);
  }

  getEmailTemplate(): void {
    this._facade.getEmailTemlate$(this.data.uuid ?? '').subscribe({
      next: this._success.bind(this),
      error: this._error.bind(this),
    });
  }

  modifyTemplate(): void {
    const payload = this.createEmailContentComponent.form.value;
    if (!payload.logo || this.createEmailContentComponent.form.controls['logo'].pristine) delete payload.logo;
    this._facade.updateEmailTemplate$(payload, this.data.uuid ?? '').subscribe({
      next: this.getEmailTemplate.bind(this),
      error: this._error.bind(this),
    });
  }

  setName(value: string): void {
    this.createEmailContentComponent.form.controls['name'].setValue(value);
  }

  previewTemplate(): void {
    const ref = this._dialog.open(EmailDialogComponent, {
      maxWidth: '900px',
      height: 'calc(100% - 100px)',
      autoFocus: false,
      panelClass: ['email-view-dialog-container', 'padding-0'],
      data: {
        template: { ...this.data, ...this.createEmailContentComponent.form.value },
      },
    });

    ref
      .afterClosed()
      .pipe(take(1))
      .subscribe((res: string) => {
        if (res) this._snackbarService.openTypeSnackbar(`Invitation has been sent to ${res}`, NotificationType.success);
      });
  }

  private _success(response: IEmailTemplateResponse): void {
    this.createEmailContentComponent.form.markAsPristine();
    this.data = response;
    this.setForm();
  }

  private _error(error: Record<string, string[]>): void {
    Object.values(error).map(err => this._snackbarService.openTypeSnackbar(err[0], NotificationType.error));
  }
}
