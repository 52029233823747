import { Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

@Injectable()
export class TranslationGroup {
  translateForm: UntypedFormGroup;

  constructor(private _fb: UntypedFormBuilder) {
    this.translateForm = this._fb.group({
      select_page: ['', Validators.required],
      select_language: ['', Validators.required],
      translation_data: ['', Validators.required],
    });
  }
}
