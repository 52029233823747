import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import {
  ICountry,
  ISupplierIngredient,
  ISupplierResponse,
  ISuppliersErrorResponse,
} from '@app/core/interface/suppliers.interface';
import { NotificationType, infoDialogHeight, infoDialogWidth } from '@app/core/constants';
import { Observable, Subject, map, startWith, takeUntil } from 'rxjs';

import { AddFactoryGroup } from '@app/module/supplier-management/add-factory/add-factory.group';
import { CountryList } from '@app/core/utils/country-list';
import { GetDirtyValues } from '@app/core/utils/form-dirty-values';
import { IManufactory } from '@app/core/interface/manufactories.interface';
import { InfoDialogComponent } from '@app/shared/components/info-dialog/info-dialog.component';
import { ManufactoriesFacade } from '@app/core/facade/manufactories.facade';
import { ManufactoriesService } from '@app/core/service/manufactories.service';
import { MatDialog } from '@angular/material/dialog';
import { SnackbarService } from '@app/core/service/snackbar.service';
import { SuppliersService } from '@app/core/service/suppliers.service';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-supplier-sites',
  templateUrl: './supplier-sites.component.html',
  styleUrls: ['./supplier-sites.component.scss'],
  providers: [AddFactoryGroup, ManufactoriesFacade],
})
export class SupplierSitesComponent implements OnInit {
  @Input() supplierUuid!: string;
  form: UntypedFormGroup;
  countryList = CountryList.isoCountries;
  filteredCountries!: Observable<ICountry[]>;
  @Input() supplierSites: IManufactory[] = [];
  @Input() hideAddSite!: boolean;
  supplierSite!: IManufactory;
  showAddSiteForm = false;
  isUpdateSite = false;
  supplierSiteUuid? = '';
  @Output() isUpdate = new EventEmitter<boolean>(false);
  @Output() isFormValid = new EventEmitter<boolean>(false);
  @Output() addComponent = new EventEmitter<boolean>(false);
  @Output() componentsView = new EventEmitter<boolean>(false);
  @Output() site = new EventEmitter<IManufactory>();
  @Output() siteCreated = new EventEmitter<boolean>(false);
  @Output() showSiteForm = new EventEmitter<boolean>(false);
  @Input() show_buttons!: boolean;
  @Input() componentCreated!: boolean;
  @Input() componentDeleted!: boolean;
  supplier!: ISupplierResponse;
  isSiteCreatedNote = false;
  is_main_location = false;
  @Output() ingredients = new EventEmitter<ISupplierIngredient[]>();
  private _unsubscribe$: Subject<void> = new Subject();

  constructor(
    private _group: AddFactoryGroup,
    private _facade: ManufactoriesFacade,
    private _snackbarService: SnackbarService,
    private _dialog: MatDialog,
    private _manufactoriesService: ManufactoriesService,
    private _changeDetectorRef: ChangeDetectorRef,
    private supplierService: SuppliersService
  ) {
    this.form = this._group.addFactory;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['supplierUuid']) {
      this.form.get('supplier')?.setValue(this.supplierUuid);
      this.form.get('supplier')?.markAsDirty();
    }
    if (changes['componentCreated'] || changes['componentDeleted']) {
      this.getSupplier();
    }
    if (changes['hideAddSite']) {
      this.showAddSiteForm = false;
    }
  }

  ngOnInit(): void {
    this.filteredCountries = this.form.controls['country'].valueChanges.pipe(
      startWith(''),
      map(value => this._filter((typeof value === 'string' && value) || ''))
    );

    this.form.valueChanges.subscribe(() => {
      this.isFormValid.emit(this.form.valid);
    });

    this._manufactoriesService.supplierSiteActions.pipe(takeUntil(this._unsubscribe$)).subscribe(data => {
      switch (data) {
        case 'create': {
          this.createSupplierSite();
          break;
        }
        case 'update': {
          this.updateSupplierSite();
          break;
        }
        case '!showAddSiteForm': {
          this.showAddSiteForm = false;
          this._changeDetectorRef.detectChanges();
          break;
        }
      }
    });
  }

  displayFn(subject: ICountry | null): string {
    return subject?.name ?? '';
  }

  createSupplierSite(): void {
    this.is_main_location = false;
    const changedFromValues = GetDirtyValues(this.form);
    if (this.supplierSites.length) {
      this.is_main_location = false;
    }
    if (changedFromValues['country']) {
      changedFromValues['country'] = this.form.controls['country'].value.code;
    }

    if (
      this.form.valid &&
      (changedFromValues['country'] || changedFromValues['region'] || changedFromValues['address'])
    ) {
      this._facade.createSupplierSite$(changedFromValues).subscribe({
        next: this._createSupplierSiteSuccess.bind(this),
        error: this._error.bind(this),
      });
    }
    this.isSiteCreatedNote = true;
    this.siteCreated.emit(true);
  }

  closeSiteForm() {
    this.showSiteForm.emit(false);
  }

  deleteSupplierSite(site: IManufactory): void {
    if (site.ingredients?.length) {
      this._dialog.open(InfoDialogComponent, {
        width: infoDialogWidth,
        height: infoDialogHeight,
        data: {
          infoText: 'You cannot delete this site because it has minimum one component linked to it.',
          type: `forbidden`,
          btnText: 'Ok, got it',
          text: `warning-text`,
        },
      });
    } else {
      const dialogRef = this._dialog.open(InfoDialogComponent, {
        width: infoDialogWidth,
        height: infoDialogHeight,
        data: {
          infoText: `Are you sure you want to delete Supplier Site: ${site.name}?`,
          type: 'warning',
          text: 'warning-text',
          btnText: 'Yes, Remove',
        },
      });
      dialogRef.afterClosed().subscribe((result: boolean) => {
        if (result && site.uuid) {
          this._facade.deleteSupplierSite$(site.uuid).subscribe(() => {
            this.supplierSites = this.supplierSites.filter(supplierSite => supplierSite.uuid !== site.uuid);
          });
        }
      });
    }
  }

  updateSupplierSite(): void {
    const changedFromValues = GetDirtyValues(this.form);
    if (changedFromValues['country']) {
      changedFromValues['country'] = this.form.controls['country'].value.code;
    }

    if (changedFromValues && this.supplierSiteUuid) {
      this._facade.updateSupplierSite$(this.supplierSiteUuid, changedFromValues).subscribe({
        next: this._updateSupplierSiteSuccess.bind(this),
        error: this._error.bind(this),
      });
      this.isSiteCreatedNote = false;
    }
  }

  showAddForm(): void {
    this.form.reset();
    this.form.get('supplier')?.setValue(this.supplierUuid);
    this.form.get('supplier')?.markAsDirty();
    if (!this.supplierSites.length) {
      this.form.get('is_main_location')?.setValue(true);
      this.form.get('is_main_location')?.markAsDirty();
      this.is_main_location = true;
    }
    this.showAddSiteForm = true;
    this.isUpdateSite = false;
    this.isUpdate.emit(false);
    this.showSiteForm.emit(true);
  }

  showUpdateForm(site: IManufactory): void {
    this.showSiteForm.emit(true);
    this.showAddSiteForm = true;
    this.supplierSiteUuid = site.uuid;
    const findCountry = this.countryList.find(
      country => country.code === site.country || country.name === site.country
    );
    this.form.patchValue({
      name: site.name,
      country: findCountry,
      city: site.city,
      address: site.address,
      supplier: this.supplierUuid,
      is_main_location: site.is_main_location,
    });
    site.is_main_location ? (this.is_main_location = true) : (this.is_main_location = false);

    this.form.markAsPristine();
    this.isUpdateSite = true;
    this.isUpdate.emit(true);
  }

  setMainLocation(): void {
    this.form.get('is_main_location')?.setValue(true);
    this.form.get('is_main_location')?.markAsDirty();
  }

  changeMainSite(uuid?: string): void {
    this.form.get('is_main_location')?.setValue(true);
    this.form.get('is_main_location')?.markAsDirty();
    this.supplierSiteUuid = uuid;
    this.updateSupplierSite();
  }

  private _createSupplierSiteSuccess(data: IManufactory): void {
    this._snackbarService.openTypeSnackbar(`Site ${data.name} Added`, NotificationType.success);
    this.supplierSites.push(data);
    this.showAddSiteForm = false;
    this.form.reset();
    this.form.get('supplier')?.setValue(this.supplierUuid);
    this.form.get('supplier')?.markAsDirty();
    this.isSiteCreatedNote = true;
    this.getSupplier();
  }

  private _updateSupplierSiteSuccess(data: IManufactory): void {
    this._snackbarService.openTypeSnackbar(`Site ${data.name} Updated`, NotificationType.success);
    this.supplierSites = this.supplierSites.filter(supplierSite => supplierSite.uuid !== data.uuid);
    this.supplierSites.push(data);
    if (data.is_main_location) {
      this.supplierSites = this.supplierSites.map(site => {
        if (site.uuid !== data.uuid && site.is_main_location) {
          site.is_main_location = false;
        }
        return site;
      });
    }
    this.form.reset();
    this.form.get('supplier')?.setValue(this.supplierUuid);
    this.form.get('supplier')?.markAsDirty();
    this.showAddSiteForm = false;
    this.isUpdateSite = false;
    this.siteCreated.emit(true);
    this.isUpdate.emit(false);
    this.getSupplier();
  }

  private _error(error: IManufactory | ISuppliersErrorResponse): void {
    Object.values(error).map(err => this._snackbarService.openTypeSnackbar(err[0], NotificationType.error));
  }

  private _filter(value: string): ICountry[] {
    const filterValue = value && value.toLowerCase();

    return this.countryList.filter(country => country.name.toLowerCase().includes(filterValue));
  }

  ngOnDestroy(): void {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }

  createComponent(site: IManufactory) {
    this.site.emit(site);
    this.ingredients.emit(site.ingredients);
    this.addComponent.emit(true);
    this.supplierSiteUuid = site.uuid;
  }

  viewComponents(site: IManufactory): void {
    this.site.emit(site);
    this.ingredients.emit(site.ingredients);
    this.addComponent.emit(false);
    this.componentsView.emit(true);
  }

  getSupplier(): void {
    this.supplierService.getSupplier$(this.supplierUuid).subscribe({
      next: this._setSupplierResponse.bind(this),
      error: this._error.bind(this),
    });
  }

  private _setSupplierResponse(response: ISupplierResponse) {
    this.supplierSites = this.mapIngredientsToManufactories(response);
  }

  mapIngredientsToManufactories(response: ISupplierResponse): IManufactory[] {
    const mappedManufactories = response.manufactories.map((manufactory: Partial<IManufactory>) => {
      const ingredients = response.ingredients.filter(
        (ingredient: ISupplierIngredient) => ingredient.manufactory?.uuid === manufactory.uuid
      );
      // Return a new manufactory object with the ingredients property
      return { ...manufactory, ingredients: ingredients };
    });
    return mappedManufactories as IManufactory[];
  }
}
