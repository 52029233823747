import { Component, Inject, ViewChild, ChangeDetectorRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { IProjectDialogData, IProjectResponse, ISubprojectResponse } from '@app/core/interface/projects.interface';
import { ProjectFormComponent } from '../project-form/project-form.component';
import { TranslateService } from '@ngx-translate/core';
import { DatePipe } from '@angular/common';
import { IProduct } from '@app/core/interface/products.interface';
import { ProjectsFacade } from '@app/core/facade/projects.facade';
import { CustomFieldsFormComponent } from '../../../shared/components/custom-fields-form/custom-fields-form.component';
import { AddFieldsFacade } from '@app/core/facade/add-fields.facade';
import { ICustomField, ICustomFieldsListResponse, IUserCustomField } from '@app/core/interface/add-fields.interface';
import { ProjectsGroup } from '../projects.group';
import { CountryList } from '@app/core/utils/country-list';
import { GetDirtyValues } from '@app/core/utils/form-dirty-values';
import { UntypedFormGroup } from '@angular/forms';
import { AlertBoxComponent } from '@app/shared/components/alert-box/alert-box.component';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { NotificationType } from '@app/core/constants';
import { SnackbarService } from '@app/core/service/snackbar.service';

@Component({
  selector: 'app-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.scss'],
  providers: [DatePipe, AddFieldsFacade, ProjectsGroup],
})
export class ProjectComponent {
  @ViewChild(ProjectFormComponent) projectFormComponent!: ProjectFormComponent;
  @ViewChild(CustomFieldsFormComponent) projectCustomFieldsFormComponent!: CustomFieldsFormComponent;
  editTitle!: string;
  createTitle!: string;
  editButton!: string;
  createButton!: string;
  photo!: string;
  projectId!: string;
  id!: string;
  savedData = '';
  projectUuid!: string;
  subProjectUuid!: string;
  is_project!: boolean;
  is_editing!: boolean;
  subprojectId = '';
  products: Partial<IProduct>[] = [];
  customFields: ICustomField[] = [];
  customFieldsData: Array<IUserCustomField> = [];
  form!: UntypedFormGroup;
  countryList = CountryList.isoCountries;
  selectedIndex = 0;
  showRedCircle = true;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IProjectDialogData,
    public dialogRef: MatDialogRef<ProjectComponent>,
    private translate: TranslateService,
    public dialog: MatDialog,
    private changeDetection: ChangeDetectorRef,
    private _facade: ProjectsFacade,
    private _addFieldsFacade: AddFieldsFacade,
    private _snackbarService: SnackbarService,
    private _group: ProjectsGroup,
    public datepipe: DatePipe
  ) {
    dialogRef.disableClose = true;

    this.form = this._group.projectForm;
  }

  ngOnInit(): void {
    this.setProjectData();

    this.getProjectCustomFields();

    if ((!this.is_project && this.subprojectId) || (this.is_project && this.projectId)) {
      // this.create = false;
      // this.setViewMode();
      this.getProject();
    }
  }

  tabChanged(event: MatTabChangeEvent) {
    this.selectedIndex = event.index;

    // Hide red-circle when custom fields tab is selected
    if (this.selectedIndex === 1) {
      this.showRedCircle = false;
    }
  }

  setProjectData() {
    if (this.data) {
      if (this.data?.is_project) this.is_project = this.data.is_project;
      if (this.data?.id) this.id = this.data.id;
      if (this.data?.projectId) this.projectId = this.data.projectId;
      if (this.data?.subprojectId) this.subprojectId = this.data.subprojectId;
      if (this.data.is_editing) this.is_editing = true;
    }

    this.setTranslationData();
    this.changeDetection.detectChanges();
  }

  setTranslationData(): void {
    if (this.is_project) {
      this.editTitle = this.translate.instant('Edit Project');
      this.createTitle = this.translate.instant('Create Project');
      this.editButton = this.translate.instant('Update Project');
      this.createButton = this.translate.instant('Create Project');
    } else {
      this.editTitle = this.translate.instant('Edit Sub-project');
      this.createTitle = this.translate.instant('Create Sub-project');
      this.editButton = this.translate.instant('Update Sub-project');
      this.createButton = this.translate.instant('Create Sub-project');
    }
  }

  // Get available project custom fields from api;
  // If there are custom fields available, show tabs group
  // else show just the standard fields
  getProjectCustomFields(): void {
    this._addFieldsFacade.getCustomFields$('project').subscribe({
      next: this._getFieldsSuccess.bind(this),
      error: this._error.bind(this),
    });
  }

  /**
   * Gets the project using UUID
   */
  getProject() {
    if (!this.is_project && !this.subprojectId) return;
    !this.is_project
      ? this._facade.getSubproject$(this.subprojectId).subscribe({
          next: this._setProject.bind(this),
          error: this._error.bind(this),
        })
      : this._facade.getProject$(this.projectId).subscribe({
          next: this._setProject.bind(this),
          error: this._error.bind(this),
        });
  }

  /**
   * Triger cancel method on form component or closes dialog if it's create dialog
   */
  cancel(): void {
    this.dialogRef.close(this.savedData);
  }

  /**
   *
   * @param isDelete if it's isDelete we send string 'delete' as param form closing the modeal
   */
  close(isDelete?: string): void {
    this.dialogRef.close(isDelete ? isDelete : this.savedData);
  }

  /**
   * Sends post API to create project
   */
  save() {
    if (this.form.dirty) {
      this.form.controls['estimated_start_date'].markAsDirty();
      this.form.controls['estimated_end_date'].markAsDirty();
      this.form.controls['name'].markAsDirty();
      this.form.controls['status'].markAsDirty();
      this.form.controls['project'].markAsDirty();
      this.form.controls['is_location_specific'].markAsDirty();

      const submitForm = GetDirtyValues(this.form);
      if (!this.is_project) {
        // Set empty string for date fields if not provided
        if (!submitForm['estimated_start_date']) {
          submitForm['estimated_start_date'] = '';
        }

        if (!submitForm['estimated_end_date']) {
          submitForm['estimated_end_date'] = '';
        }
      }

      if (!this.form.controls['is_location_specific'].value) {
        submitForm['address'] = '';
        submitForm['country'] = '';
        submitForm['city'] = '';
        submitForm['statezipcode'] = '';
      }
      if (submitForm['country'])
        if (typeof submitForm['country'] === 'string') {
          this.countryList.filter(country => {
            if (country.name.toLowerCase() === submitForm['country'].toLowerCase()) {
              submitForm['country'] = country.code;
              this.form.controls['country'].patchValue(country);
            }
          });
        } else {
          submitForm['country'] = this.form.controls['country'].value.code;
        }
      delete submitForm['location'];
      if (submitForm['country'])
        if (typeof submitForm['country'] === 'string') {
          this.countryList.filter(country => {
            if (country.name.toLowerCase() === submitForm['country'].toLowerCase()) {
              submitForm['country'] = country.code;
              this.form.controls['country'].patchValue(country);
            }
          });
        } else {
          submitForm['country'] = this.form.controls['country'].value.code;
        }
      if (submitForm['estimated_start_date']) {
        submitForm['estimated_start_date'] =
          this.datepipe.transform(new Date(submitForm['estimated_start_date']), 'yyyy-MM-dd') ?? '';
      }
      if (submitForm['estimated_end_date']) {
        submitForm['estimated_end_date'] =
          this.datepipe.transform(new Date(submitForm['estimated_end_date']), 'yyyy-MM-dd') ?? '';
      }

      if (this.is_editing) {
        !this.is_project
          ? this._facade.updateSubproject$(submitForm, this.subprojectId).subscribe({
              next: this.saveSuccess.bind(this),
              error: this._error.bind(this),
            })
          : this._facade.updateProject$(submitForm, this.projectId).subscribe({
              next: this.saveSuccess.bind(this),
              error: this._error.bind(this),
            });
      } else {
        !this.is_project
          ? this._facade.createSubproject$({ ...submitForm, project: this.projectId }).subscribe({
              next: this.saveSuccess.bind(this),
              error: this._error.bind(this),
            })
          : this._facade.createProject$(submitForm).subscribe({
              next: this.saveSuccess.bind(this),
              error: this._error.bind(this),
            });
      }
    }
  }

  saveSuccess(event: IProjectResponse | ISubprojectResponse) {
    if (this.is_project) {
      this.projectUuid = event.uuid;
      this.dialogRef.close({ uuid: this.projectUuid, is_project: this.is_project });
    } else {
      this.subProjectUuid = event.uuid;
      this.dialogRef.close({ uuid: this.subProjectUuid, is_project: this.is_project });
    }

    if (this.is_editing) {
      this.closeDialogProject();
    }
  }
  addProducts(product: IProduct): void {
    this.projectFormComponent.products.push(product);
    this.projectFormComponent.form.controls['project_products'].setValue(this.projectFormComponent.products);
    this.projectFormComponent.form.controls['project_products'].markAsDirty();
    this.projectFormComponent.setProducts.emit(this.projectFormComponent.products);
  }
  remove(product: Partial<IProduct>): void {
    const index = this.projectFormComponent.products.indexOf(product);
    if (index > -1) {
      this.projectFormComponent.products.splice(index, 1);
    }
    this.projectFormComponent.form.controls['project_products'].setValue(this.projectFormComponent.products);
    this.projectFormComponent.form.controls['project_products'].markAsDirty();
    this.projectFormComponent.setProducts.emit(this.projectFormComponent.products);
  }

  closeDialogProject(): void {
    this.dialog.open(AlertBoxComponent, {
      width: '496px',
      height: '194px',
      panelClass: ['padding-0', 'alert-box'],
      data: {
        text: `The ${this.is_project ? 'project' : 'sub-project'} was successfully updated.`,
      },
    });
  }

  /**
   * Sets project on successfull getting the project
   * @param response from get project
   */
  private _setProject(response: IProjectResponse | ISubprojectResponse) {
    this.projectFormComponent.setProject(response);
    this.customFieldsData = response.custom_fields;
  }

  private _getFieldsSuccess(data: ICustomFieldsListResponse): void {
    this.customFields = data.results;
  }

  /**
   * Hadles HTTP error desplaying message from backend
   * @param error
   */
  private _error(error: Record<string, string>): void {
    Object.values(error).map(err => {
      this._snackbarService.openTypeSnackbar(err, NotificationType.error);
    });
  }
}
