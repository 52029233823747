import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/internal/Subject';

@Injectable()
export class LoaderService {
  // Is loading flag as behavior subject (Observable with a default value)
  static isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  // Loader content suffix as subject (Observable with a default value)
  static loaderSuffix$: Subject<string> = new Subject();
}
