import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BillingManagementComponent } from './billing-management.component';
import { BillingManagementRoutingModule } from './billing-management-routing.module';
import { AngularMaterialModule } from '@app/material.module';
import { TranslateModule } from '@ngx-translate/core';
import { PaidUsersComponent } from './paid-users/paid-users.component';
import { UnpaidInvoicesComponent } from './unpaid-invoices/unpaid-invoices.component';
import { PaidInvoicesComponent } from './paid-invoices/paid-invoices.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IMaskModule } from 'angular-imask';
import { ManageDetailsComponent } from './manage-details/manage-details.component';
import { ManageCustomerDetailsComponent } from './manage-details/manage-customer-details/manage-customer-details.component';
import { ManageBillingAddressComponent } from './manage-details/manage-billing-address/manage-billing-address.component';
import { ManageCreditCardComponent } from './manage-details/manage-credit-card/manage-credit-card.component';
import { SharedModule } from '@app/shared/shared.module';

@NgModule({
  declarations: [
    BillingManagementComponent,
    PaidUsersComponent,
    UnpaidInvoicesComponent,
    PaidInvoicesComponent,
    ManageDetailsComponent,
    ManageCustomerDetailsComponent,
    ManageBillingAddressComponent,
    ManageCreditCardComponent,
  ],
  imports: [
    CommonModule,
    BillingManagementRoutingModule,
    AngularMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forChild({}),
    IMaskModule,
    SharedModule,
  ],
})
export class BillingManagementModule {}
