<div class="dialog-header">
  <h3>{{ 'Supplier Identification' | translate }}</h3>
  <app-custom-icon mat-dialog-close [icon_category]="'essential'" [icon_name]="'close-circle'" class="cursor-pointer">
  </app-custom-icon>
</div>
<mat-dialog-content>
  <div class="sel-info" #selInfo>
    <div class="detail-txt d-flex">
      <img
        src="../../../../assets/images/symbol-blue.svg"
        alt="Symbol"
        height="45"
        width="45"
        class="col-1 align-self-center"
      />
      <span class="more-details">
        <p class="p1-strong">
          {{
            "Based on the company unique identifier number, the supplier as identified by previous supplier matches this existing supplier. If you validate this suppliers matching, the current step’s supplier will be updated with the matching supplier. Besides, all the contacts of the current step’s supplier will be moved to the matching supplier\’s contacts. Once all previous actions are done, the current step's supplier will be deactivated if it isn't linked to any other data element."
              | translate
          }}
        </p>
      </span>
    </div>
    <app-custom-icon
      class="close-icon cursor-pointer cls-more-details"
      icon_category="essential"
      icon_name="close-circle"
      (click)="selInfo.style.display = 'none'"
    ></app-custom-icon>
  </div>
  <div class="mb-3 d-flex align-items-center">
    <div
      class="step-status"
      [ngClass]="{
        'in-progress': step.sip_supplier_identified_approved === 0,
        disapproved: step.sip_supplier_identified_approved === 1,
        approved: step.sip_supplier_identified_approved === 2,
      }"
    ></div>

    <div>
      <p *ngIf="step.sip_supplier_identified_approved === 0" class="font-10 mb-0 px-2 align-self-center">
        {{ 'Matching supplier not verified yet' | translate }}
      </p>
      <p *ngIf="step.sip_supplier_identified_approved === 1" class="font-10 mb-0 px-2 align-self-center">
        {{ 'Matching supplier disapproved' | translate }}
      </p>
      <p *ngIf="step.sip_supplier_identified_approved === 2" class="font-10 mb-0 px-2 align-self-center">
        {{ 'Matching Supplier approved' | translate }}
      </p>
    </div>
  </div>

  <mat-card class="holder align-items-center">
    <div class="d-flex justify-content-between">
      <div class="d-flex flex-column wrapper-row">
        <p class="p1-strong">
          {{ 'Current supplier' | translate }}
        </p>
        <app-input-info [label]="'Identified supplier name' | translate" [value]="step.supplier?.name"></app-input-info>
        <app-input-info
          *ngIf="mainSiteAddress"
          [label]="'Main site address'"
          [value]="mainSiteAddress"
        ></app-input-info>
        <app-input-info
          [label]="'Identified company unique identifier'"
          [number]="step.supplier?.supplier_unique_identifier"
          [type]="step.supplier?.unique_identifier_type"
        ></app-input-info>
      </div>
      <div class="holder-compare d-flex justify-content-between align-items-center align-self-center">
        <div class="blue-circle">
          <div class="icon-wraper">
            <app-custom-icon icon_category="arrows" icon_name="arrow-1"> </app-custom-icon>
          </div>
        </div>
        <p class="font-14">{{ 'Please compare the suppliers to validate that they match' | translate }}</p>
      </div>
      <div class="d-flex flex-column wrapper-row">
        <p class="p1-strong">
          {{ 'Matching supplier' | translate }}
        </p>
        <app-input-info
          [label]="'Matching supplier name'"
          [value]="step.sip_supplier_identified?.name"
        ></app-input-info>
        <app-input-info
          *ngIf="mainSiteAddressIdentified"
          [label]="'Main site address'"
          [value]="mainSiteAddressIdentified"
        ></app-input-info>
        <app-input-info
          [label]="'Identified company unique identifier'"
          [number]="step.sip_supplier_identified?.supplier_unique_identifier"
          [type]="step.sip_supplier_identified?.unique_identifier_type"
        ></app-input-info>
      </div>
    </div>
  </mat-card>

  <div class="action-buttons d-flex justify-content-end mt-4">
    <app-rounded-button
      class="text-decoration-underline"
      color="tetriary-link"
      [button_text]="'Suppliers Don’t Match'"
      [size]="'md'"
      (click)="close()"
    >
    </app-rounded-button>
    <app-rounded-button
      color="success"
      [button_text]="'Validate Suppliers Matching'"
      [size]="'md'"
      (click)="onValidateSupplier()"
    >
    </app-rounded-button>
  </div>
</mat-dialog-content>

<ng-template> </ng-template>
