<div class="sub_plan text-center" [class]="color">
  <div class="card-header">
    <div class="icon-holder">
      <div class="icon-bg text-center">
        <app-custom-icon [icon_category]="icon_category" [icon_name]="icon_name"></app-custom-icon>
      </div>
    </div>
    <div class="title-holder">
      <h6 class="plan_title text-center">{{ card_title }}</h6>
    </div>
  </div>

  <div class="price">
    <h2>{{ price }} CHF</h2>
    <span class="">per month</span>
  </div>
  <ul class="list-unstyled p_list">
    <li *ngFor="let list of card_list" class="d-flex justify-content-center align-items-center my-1">
      <app-custom-icon [icon_category]="'essential'" [icon_name]="'tick-circle'"></app-custom-icon>
      <span>{{ list | translate }}</span>
    </li>
  </ul>

  <a
    *ngIf="data_plan === 'essential-licenses-monthly-chf'"
    href="javascript:void(0)"
    data-cb-type="checkout"
    data-cb-plan-id="essential-licenses-monthly-chf"
    id="essential-licenses-monthly-chf"
  >
    <app-rounded-button
      color="tetriary-link"
      [button_text]="'Get Started'"
      [icon_category]="'arrows'"
      [icon_name]="'arrow-right'"
      [icon_right]="true"
      (click)="initSubscriptionDialog()"
    ></app-rounded-button>
  </a>
  <a
    *ngIf="data_plan === 'business-licenses-monthly-chf'"
    href="javascript:void(0)"
    data-cb-type="checkout"
    data-cb-plan-id="business-licenses-monthly-chf"
    id="business-licenses-monthly-chf"
  >
    <app-rounded-button
      color="tetriary-link"
      [button_text]="'Get Started'"
      [icon_category]="'arrows'"
      [icon_name]="'arrow-right'"
      [icon_right]="true"
      (click)="initSubscriptionDialog()"
    ></app-rounded-button>
  </a>
  <a
    *ngIf="data_plan === 'premium-licences-monthly-chf'"
    href="javascript:void(0)"
    data-cb-type="checkout"
    data-cb-plan-id="premium-licences-monthly-chf"
    id="premium-licences-monthly-chf"
  >
    <app-rounded-button
      color="tetriary-link"
      [button_text]="'Get Started'"
      [icon_category]="'arrows'"
      [icon_name]="'arrow-right'"
      [icon_right]="true"
      (click)="initSubscriptionDialog()"
    ></app-rounded-button>
  </a>
</div>
