<div class="wrapper">
  <div class="holder">
    <div class="card-holder">
      <div class="card">
        <h6>{{ 'Created products' | translate }}</h6>
        <div
          class="{{
            dashboardInfo && dashboardInfo.products ? 'added-icon-holder-blue' : 'added-icon-holder-light-blue'
          }}"
        >
          <div class="blue-circle-wrraper">
            <div class="blue-circle">
              <app-custom-icon [icon_category]="'product'" [icon_name]="'box-tick-white'"></app-custom-icon>
            </div>
            <div class="line" [class]="getLineClass(dashboardInfo?.products)">
              <div class="circle"></div>
              <div class="dashed-line"></div>
              <div class="circle"></div>
            </div>

            <div class="number">
              <h1>{{ dashboardInfo?.products }}</h1>
            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <h6>{{ 'Added suppliers' | translate }}</h6>
        <div
          class="{{
            dashboardInfo && dashboardInfo.suppliers ? 'added-icon-holder-blue' : 'added-icon-holder-light-blue'
          }}"
        >
          <div class="blue-circle-wrraper">
            <div class="blue-circle">
              <app-custom-icon [icon_category]="'product'" [icon_name]="'truck'"></app-custom-icon>
            </div>
            <div class="line" [class]="getLineClass(dashboardInfo?.suppliers)">
              <div class="circle"></div>
              <div class="dashed-line"></div>
              <div class="circle"></div>
            </div>

            <div class="number">
              <h1>
                {{ dashboardInfo?.suppliers }}
              </h1>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-holder">
      <div class="card">
        <h6>{{ 'Created supply chains' | translate }}</h6>
        <div
          class="{{
            dashboardInfo && dashboardInfo.supplychains ? 'added-icon-holder-blue' : 'added-icon-holder-light-blue'
          }}"
        >
          <div class="blue-circle-wrraper">
            <div class="blue-circle">
              <app-custom-icon [icon_category]="'location'" [icon_name]="'routing-2'"></app-custom-icon>
            </div>
            <div class="line" [class]="getLineClass(dashboardInfo?.supplychains)">
              <div class="circle"></div>
              <div class="dashed-line"></div>
              <div class="circle"></div>
            </div>

            <div class="number">
              <h1>{{ dashboardInfo?.supplychains }}</h1>
            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <h6>{{ 'Added certificates' | translate }}</h6>
        <div
          class="{{
            dashboardInfo && dashboardInfo.certificates ? 'added-icon-holder-blue' : 'added-icon-holder-light-blue'
          }}"
        >
          <div class="blue-circle-wrraper">
            <div class="blue-circle">
              <app-custom-icon [icon_category]="'learning'" [icon_name]="'certificate'"></app-custom-icon>
            </div>
            <div class="line" [class]="getLineClass(dashboardInfo?.certificates)">
              <div class="circle"></div>
              <div class="dashed-line"></div>
              <div class="circle"></div>
            </div>

            <div class="number">
              <h1>{{ dashboardInfo?.certificates }}</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
