import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import {
  IBrandUserListResponse,
  IPermissionListResponse,
  IPutDeleteBrandUserPermissionsResult,
} from '../interface/users-access.interface';

@Injectable({
  providedIn: 'root',
})
export class UsersAccessService {
  private apiUrl = environment.api;

  constructor(private _http: HttpClient) {}

  getBrandUsers$(): Observable<IBrandUserListResponse> {
    return this._http.get<IBrandUserListResponse>(`${this.apiUrl}/brands/users/`);
  }

  getPermissionsListLookup$(): Observable<IPermissionListResponse> {
    return this._http.get<IPermissionListResponse>(`${this.apiUrl}/usercustompermission/`);
  }

  putBrandUserPermissions$(data: FormData): Observable<IPutDeleteBrandUserPermissionsResult> {
    return this._http.put<IPutDeleteBrandUserPermissionsResult>(`${this.apiUrl}/brands/users/custompermissions/`, data);
  }

  deleteBrandUserPermissions$(data: FormData): Observable<IPutDeleteBrandUserPermissionsResult> {
    return this._http.delete<IPutDeleteBrandUserPermissionsResult>(`${this.apiUrl}/brands/users/custompermissions/`, {
      body: data,
    });
  }
}
