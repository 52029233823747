import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-recyclability',
  templateUrl: './recyclability.component.html',
  styleUrls: ['./recyclability.component.scss'],
})
export class RecyclabilityComponent implements OnChanges {
  @Output() isSelected = new EventEmitter<boolean>(false);
  @Input() recyclability = '';
  @Input() is_recyclable_display!: boolean;
  @Output() checkValidation = new EventEmitter<boolean>(false);

  selectedValue = '';
  selected = false;
  visibility = true;
  criteriaList = [
    'The capacity to be efficiently collected on a territorial scale',
    'The capacity to be sorted (for instance, the existence of recycling channels)',
    'The absence of elements or substances affecting sorting and recycling',
    'The capacity for the recycled material produced by the recycling processes to represent more than 50% of the collected waste',
    'The capacity to be recycled on an industrial scale and in practice, with a guarantee that the quality of the recycled material obtained is sufficient to reuse it in another process',
  ];

  // @ViewChild('infoWrap') infoWrapElement!: ElementRef;
  // @ViewChild('info') infoElement!: ElementRef;

  // @HostListener('document:click', ['$event'])
  // handleClickOutside(event: Event): void {
  //   if (
  //     !this.infoWrapElement?.nativeElement.contains(event.target) &&
  //     this.infoElement?.nativeElement.style.display !== 'none'
  //   ) {
  //     this.hideInfo();
  //   }
  // }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['recyclability']?.currentValue) this.selectedValue = changes['recyclability'].currentValue;
    if (changes['is_recyclable_display']) this.visibility = changes['is_recyclable_display'].currentValue;
  }

  onSelection(): void {
    if (!this.selected) this.isSelected.emit(true);
    this.selected = true;
  }

  // hideInfo(): void {
  //   this.infoElement.nativeElement.style.display = 'none';
  // }
  onVisibilityChange() {
    this.checkValidation.emit(this.visibility);
  }
}
