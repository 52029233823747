<div class="background">
  <div class="holder">
    <mat-card class="unit-wrapper">
      <div class="header-wrapper d-flex justify-content-between flex-column">
        <div class="title-holder d-flex justify-content-between align-items-center">
          <h6 class="title m-0">{{ 'Manage Units' | translate }}</h6>
          <div class="button d-flex align-items-center justify-content-center">
            <div
              (mouseover)="mouseEvHandler(true)"
              (mouseout)="mouseEvHandler(false)"
              class="help-holder d-flex align-items-center justify-content-center cursor-pointer"
            >
              <div class="logo"></div>
              <p class="m-0">{{ 'need help?' }}</p>
              <div class="tooltip" *ngIf="needHelpTooltip">
                <p>
                  {{ 'Here you can manage all the units you may use in Respect SaaS. ' | translate }}
                </p>
                <p class="example-text">
                  {{
                    'Examples: A unit can be specified when filling a batch quantity or a component quantity received from a supplier in a step of a batch…'
                      | translate
                  }}
                </p>
              </div>
            </div>
            <app-rounded-button
              (click)="createUnit(false, '')"
              color="primary"
              [button_text]="'Create Unit' | translate"
              [size]="'md'"
              [icon_category]="'essential'"
              [icon_name]="'unit'"
              [icon_right]="true"
            >
            </app-rounded-button>
          </div>
        </div>
      </div>
      <!-- Empty unit list view -->
      <div *ngIf="!tableView" class="empty-unit d-flex align-items-center justify-content-center">
        <div class="logo-bg d-flex align-items-center justify-content-center">
          <div class="info-holder">
            <div class="icon-holder d-flex align-items-center justify-content-center">
              <app-custom-icon [icon_category]="'essential'" [icon_name]="'unit-white'"> </app-custom-icon>
            </div>
            <h4>{{ 'There are no units created yet.' | translate }}</h4>
          </div>
        </div>
      </div>
      <!--Units Table view -->
      <div class="wrapper" *ngIf="tableView">
        <div class="main-holder d-flex flex-column">
          <div class="checkbox-holder d-flex justify-content-end">
            <mat-checkbox (change)="showDeactivatedUnit($event)" [checked]="show_deactivated" class="checkbox-primary">
              {{ 'show deactivated units' | translate }}
            </mat-checkbox>
          </div>
          <!-- Table View -->
          <div class="table-wraper">
            <table class="custom-table" mat-table matSort [dataSource]="tableDataSource">
              <ng-container matColumnDef="unit_symbol">
                <th class="width-40" mat-header-cell *matHeaderCellDef mat-sort-header>
                  {{ 'Unit Symbol' | translate }}
                </th>
                <td
                  class="width-40 bold unit-symbol"
                  [ngClass]="!element.is_active ? 'text-light' : ''"
                  mat-cell
                  *matCellDef="let element"
                >
                  {{ element.symbol }}
                </td>
              </ng-container>

              <ng-container matColumnDef="unit_name">
                <th class="width-40" mat-header-cell *matHeaderCellDef mat-sort-header>
                  {{ 'Unit Name' | translate }}
                </th>
                <td
                  class="width-40"
                  [ngClass]="!element.is_active ? 'text-light' : ''"
                  mat-cell
                  *matCellDef="let element"
                >
                  {{ element.name }}
                </td>
              </ng-container>

              <ng-container matColumnDef="actions">
                <th class="width-20" mat-header-cell *matHeaderCellDef class="actions-header text-end">
                  {{ 'Actions' | translate }}
                </th>
                <td class="width-20" mat-cell *matCellDef="let element" class="actions-body text-end">
                  <div class="icon-holder d-flex justify-content-end">
                    <app-custom-icon
                      *ngIf="element.is_active"
                      class="cursor"
                      (click)="createUnit(true, element)"
                      [icon_category]="'files'"
                      [icon_name]="'edit'"
                      matTooltip="{{ 'Edit unit' | translate }}"
                      matTooltipClass="custom-tooltip-center"
                      matTooltipPosition="above"
                    >
                    </app-custom-icon>
                    <app-custom-icon
                      *ngIf="element.is_active"
                      class="cursor"
                      (click)="deactivateUnit(element)"
                      id="edit"
                      [icon_category]="'essential'"
                      [icon_name]="'deactivate-red'"
                      matTooltip="{{ 'Deactivate unit' | translate }}"
                      matTooltipClass="custom-tooltip-end"
                      matTooltipPosition="above"
                    ></app-custom-icon>

                    <app-custom-icon
                      class="cursor"
                      (click)="reactivateUnit(element)"
                      *ngIf="!element.is_active"
                      id="trash"
                      [icon_category]="'essential'"
                      [icon_name]="'activate-green'"
                      matTooltip="{{ 'Reactivate unit' | translate }}"
                      matTooltipClass="custom-tooltip-end"
                      matTooltipPosition="above"
                    >
                    </app-custom-icon>
                  </div>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>
          </div>
        </div>
        <div class="card-footer">
          <mat-paginator class="custom-paginator" showFirstLastButtons aria-label="Select page of suppliers">
          </mat-paginator>
        </div>
      </div>
    </mat-card>
  </div>
</div>
