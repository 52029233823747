import { ActivatedRoute, Router } from '@angular/router';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { IBrandFeatures, IBrandFeaturesResponse } from '@app/core/interface/brands.interface';
import {
  ICertificationProduct,
  IProduct,
  IProductChain,
  IProductResponse,
} from '@app/core/interface/products.interface';
import { INameID, IProject, IProjectsListResponse } from '@app/core/interface/projects.interface';
import { ISupplier, ISuppliersResponse } from '@app/core/interface/suppliers.interface';
import { NotificationType, PROJECT_MANAGEMENT, infoDialogHeight, infoDialogWidth } from '@app/core/constants';

import { BrandsFacade } from '@app/core/facade/brands.facade';
import { CertificationDashboardComponent } from '../certificates/certification-dashboard/certification-dashboard.component';
import { ChangeDetectorRef } from '@angular/core';
import { CreateProductDialogComponent } from '@app/shared/components/create-product-dialog/create-product-dialog.component';
import { CreateSupplierComponent } from '../supplier/create-supplier/create-supplier.component';
import { FormGroup } from '@angular/forms';
import { ICreateProductResponse } from '@app/core/interface/register.interface';
import { InfoDialogComponent } from '@app/shared/components/info-dialog/info-dialog.component';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { ProductCertificatesComponent } from './product-certificates/product-certificates.component';
import { ProductCertificatesGridComponent } from './product-certificates-grid/product-certificates-grid.component';
import { ProductDashboardGroup } from './product-dashboard.group';
import { ProductDetailsDialogComponent } from './product-details-dialog/product-details-dialog.component';
import { ProductSuppliersComponent } from './product-suppliers/product-suppliers.component';
import { ProductsFacade } from '@app/core/facade/products.facade';
import { ProductsService } from '@app/core/service/products.service';
import { ProjectsFacade } from '@app/core/facade/projects.facade';
import { SnackbarService } from '@app/core/service/snackbar.service';
import { SuppliersFacade } from '@app/core/facade/suppliers.facade';
import { map, Observable, take } from 'rxjs';
import { MatTabChangeEvent } from '@angular/material/tabs';

@Component({
  selector: 'app-product-dashboard',
  templateUrl: './product-dashboard.component.html',
  styleUrls: ['./product-dashboard.component.scss'],
  providers: [SuppliersFacade, ProductDashboardGroup],
})
export class ProductDashboardComponent implements OnInit {
  @ViewChild('menuTrigger') menuTrigger!: MatMenuTrigger;
  @ViewChild(ProductSuppliersComponent)
  productSuppliersComponent!: ProductSuppliersComponent;
  @ViewChild(ProductCertificatesComponent)
  productCertificatesComponent!: ProductCertificatesComponent;
  @ViewChild(ProductCertificatesGridComponent)
  productCertificatesGridComponent!: ProductCertificatesGridComponent;
  productId = '';
  need_help = false;
  empty_projects_list!: boolean;
  empty_subprojects_list!: boolean;
  displayRegulations = false;
  product: IProduct | IProductResponse = {
    certification_product: [],
    composition: '',
    co2: 0,
    h2o: 0,
    description: '',
    external_url: '',
    image: null,
    is_clone: false,
    gtin: '',
    product_group: null,
    medias: [],
    name: '',
    product_model: null,
    product_chains: [],
    reference: '',
    summary: '',
    tags: '',
    uuid: '',
    visible_end: null,
    visible_start: null,
    youtube_url: '',
    suppliers: [],
    batch_chains: [],
    custom_fields: [],
  };
  chains: Array<IProductChain> = [];
  allSuppliers: Array<ISupplier> = [];
  isEditDetails = false;
  isAddSupplier = false;
  isEditMedias = false;
  editBtn: 'Edit Section' | 'View Section' = 'Edit Section';
  isAddIngredient = false;
  selectedIndex = 0;
  existingSupplierList: Array<ISupplier> = [];
  existingSupplierListOrg: Array<ISupplier> = [];
  items: Array<ISupplier> = [];
  projectsList: Array<IProject> = [];
  projectsListOrg: Array<IProject> = [];
  activeProjectsList: Array<IProject> = [];
  activeProjectsListOrg: Array<IProject> = [];
  subprojectsList: Array<INameID> = [];
  subprojectsListOrg: Array<INameID> = [];
  @ViewChild('inputFilter', { static: false }) inputFilter!: ElementRef;
  suppliersIDs: Array<string> = [];
  viewCreateProject!: boolean;
  viewCreateSubProject!: boolean;
  form!: FormGroup;
  projectUuid!: string;
  has_project!: boolean;
  has_subproject!: boolean;
  is_project: boolean | undefined;
  show_project!: boolean;
  brandFeatures!: IBrandFeatures[];
  projectManagement!: IBrandFeatures | undefined;
  has_chains!: boolean;
  has_batches!: boolean;
  isChains = true;
  focus = true;
  certificatesList: Array<ICertificationProduct> = [];
  selectedView = 'tableView';
  publicationIndex: number = 0; // Index of the publication tab
  constructor(
    private _activatedRoute: ActivatedRoute,
    private _productsFacade: ProductsFacade,
    private _suppliersFacade: SuppliersFacade,
    private _snackbarService: SnackbarService,
    private _dialog: MatDialog,
    private _router: Router,
    private _projectsFacade: ProjectsFacade,
    private _productDashboardGroup: ProductDashboardGroup,
    private _productService: ProductsService,
    private cdRef: ChangeDetectorRef,
    private _brandsFacade: BrandsFacade,
    public router: Router
  ) {
    this.form = this._productDashboardGroup.productDetails;
  }

  private $changesDetect = this._productService.changesBehaviour.subscribe(res => (this.hasUnsavedChanges = res));

  hasUnsavedChanges = false;

  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (this.hasUnsavedChanges) {
      const dialogRef = this._dialog.open(InfoDialogComponent, {
        width: infoDialogWidth,
        height: infoDialogHeight,
        data: {
          infoText:
            'Please note that you have unsaved changes. If you leave this page without saving first, all of the progress you have made will be lost.',
          confirmationText: 'Do you want to leave the page?',
          btnText: 'Yes, leave',
          type: 'warning',
          text: 'warning-text',
        },
      });
      return dialogRef.afterClosed().pipe(
        map(result => {
          return result === true; // true if the user confirms, false otherwise
        })
      );
    }
    return true;
  }
  /**
   * In OnInit lifecycle we are taking id from params and getting product data using that id
   */
  ngOnInit(): void {
    this._activatedRoute.params.pipe(take(1)).subscribe(res => {
      this.productId = res['id'];
      this.getProduct();
      this.getSuppliers();
      this.getProjects();
      this.getBrandFeatures();
    });
  }

  ngAfterViewInit() {
    this._productService.refetchProductDetails$.subscribe(data => {
      if (data) {
        this.getProduct();
        this.getSuppliers();
      }
    });
  }

  /**
   * Sends GET API in order to get the product data
   */
  getProduct(): void {
    this._productsFacade.getProduct$(this.productId).subscribe({
      next: this._setProduct.bind(this),
      error: this._error.bind(this),
    });
  }

  getSuppliers() {
    this._suppliersFacade.getSupplierList$().subscribe({
      next: this._getSupplierListSuccess.bind(this),
      error: this._error.bind(this),
    });
  }

  /**
   *
   * @param event if throuthy boolean is emited we are trigering NgOnInit in ordder to refresh the data
   */
  refresh(event: boolean): void {
    event && this.ngOnInit();
  }

  /**
   * On Creating Certification we are opening Certification Dialog passing Project ID
   */
  createCertificationDialog(): void {
    const dialogRef = this._dialog.open(CertificationDashboardComponent, {
      width: '1160px',
      height: '768px',
      panelClass: 'top-padding-0',
      data: {
        content_type: 'Product',
        uuid: this.productId,
      },
      autoFocus: false,
    });
    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.productCertificatesComponent?.getProductCertificates();
        this.productCertificatesGridComponent?.getProductCertificates();
      }
    });
  }

  /**
   * On deactivating product btn clicked we are checking if this product can be deactivated and opening confirmation dialog
   * if there is confirmation we are fireing Deactivating API
   */

  deactivateProduct(product: IProduct): void {
    const incompleteBatches = product.batch_chains.filter(el => el.status === 'IN PROGRESS');
    this.has_chains = !!product.product_chains.length;
    this.has_batches = !!incompleteBatches.length;
    const dialogRef = this._dialog.open(InfoDialogComponent, {
      width: infoDialogWidth,
      height: infoDialogHeight,
      data: {
        infoText: this.has_chains
          ? 'The selected product has published chains, visible on respect-code platform.'
          : this.has_batches
            ? 'The selected product has incomplete batches.'
            : 'Note that once you deactivate this product all of the related projects, certificates & labels will also be deactivated. The product can be reactivated at anytime.',
        confirmationText:
          this.has_batches || this.has_chains
            ? 'The product cannot be deactivated!'
            : 'Are you sure you want to deactivate this product?',
        btnText: this.has_batches || this.has_chains ? 'Ok, Got It' : 'Deactivate Product',
        type: this.has_batches || this.has_chains ? 'forbidden' : 'warning',
        text: this.has_batches || this.has_chains ? 'forbidden-text' : 'warning-text',
      },
    });
    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this._productsFacade.activateDeactivateProduct$(product.uuid, { is_active: false }).subscribe({
          next: this._seccess.bind(this),
          error: this._error.bind(this),
        });
      }
    });
  }

  private _seccess(): void {
    this.router.navigate(['products/list']);
  }

  /**
   * On delete product btn clicked we are opening confirmation dialog passing Product's name and
   * if there is confirmation we are fireing DELETE API
   */
  deleteProductDialog(): void {
    const dialogRef = this._dialog.open(InfoDialogComponent, {
      width: infoDialogWidth,
      height: infoDialogHeight,
      data: {
        infoText: `Are you sure you want to delete product: ${this.product.name}?`,
        confirmationText: 'Please Confirm',
        btnText: 'Yes, Delete',
        type: 'warning',
        text: 'warning-text',
      },
    });
    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this._productsFacade.deleteProduct$(this.product.uuid).subscribe({
          next: this._deleteProduct.bind(this),
          error: this._error.bind(this),
        });
      }
    });
  }

  /**
   *
   * @param product on edit product we are passing the whole product that needs to be edited
   */
  openEditProductDialog(): void {
    const dialogRef = this._dialog.open(CreateProductDialogComponent, {
      width: '1160px',
      height: '768px',
      panelClass: ['overflow-hidden-dialog', 'top-padding-0'],
      data: { mode: 'edit', productId: this.productId },
    });
    dialogRef.afterClosed().subscribe((result: ICreateProductResponse) => {
      if (result) {
        this.ngOnInit();
      }
    });
  }

  /**
   * On create supplier we are oppening CreateSupplier Dialog
   */
  createSupplierDialog(): void {
    this.focus = false;
    this.inputFilter.nativeElement.blur();
    const dialogRef = this._dialog.open(CreateSupplierComponent, {
      width: '1160px',
      height: '768px',
      panelClass: 'top-padding-0',
      data: {
        productId: this.productId,
        show_buttons: false,
      },
      autoFocus: true,
    });
    dialogRef.afterClosed().subscribe((result: boolean) => {
      this.focus = true;
      this.inputFilter && this.inputFilter.nativeElement.focus();
      if (result) {
        this.ngOnInit();
      }
    });
  }

  /**
   *
   * @param event Updates Product
   */
  productUpdate(event: boolean): void {
    this.isEditDetails = false;
    event && this.ngOnInit();
  }

  /**
   * On successfull deliting product we navigate to products list
   */
  private _deleteProduct(): void {
    this._router.navigate(['/products']);
  }

  /**
   *
   * @param response on successfull getting product details we are setting products and the chains
   */
  private _setProduct(response: IProductResponse): void {
    this.product = response;
    this.displayRegulations = !!this.product.regulations?.find(reg => reg.are_regulation_enabled);
    this._productService.productDetailsName.next({
      label: response.name,
      url: response.uuid,
    });
    if (response.project) {
      this.has_project = true;
      if (response.project.subprojects) {
        this.subprojectsList = response.project.subprojects.filter(sub => sub.is_active);
        this.subprojectsListOrg = response.project.subprojects.filter(sub => sub.is_active);
        this.empty_subprojects_list = this.subprojectsList.length <= 0;
      }
    }
    if (response.subproject) {
      this.has_subproject = true;
    }
    this.chains = response.batch_product ?? [];
    this.certificatesList = this.product.certification_product;
  }

  private _getSupplierListSuccess(data: ISuppliersResponse): void {
    if (data.results) this.allSuppliers = data.results;
  }

  /**
   *
   * @param error Hadles HTTP error desplaying message from backend
   */
  _error(error: Record<string, string[]>): void {
    Object.values(error).map(err => this._snackbarService.openTypeSnackbar(err[0], NotificationType.error));
  }

  error(): void {
    this._error.bind(this);
  }

  viewDetails() {
    this._dialog.open(ProductDetailsDialogComponent, {
      width: '1160px',
      // height: '80vh',
      height: '768px',
      data: this.product,
    });
  }

  getExitingSuppliersList(eventData: ISupplier[]): void {
    this.existingSupplierList = eventData;
    this.existingSupplierListOrg = eventData;
    this.cdRef.detectChanges();
  }

  /**
   * Filtering existing suppliers
   */
  filterItem(event: Event | KeyboardEvent): void {
    const value = (event.target as HTMLInputElement).value;
    if (!value) {
      this.existingSupplierList = this.existingSupplierListOrg;
    } else {
      this.existingSupplierList = this.existingSupplierListOrg.filter(el =>
        el.name.toLowerCase().includes(value.toLowerCase())
      );
    }
  }

  /**
   * Clearing filter/ Clearing input value
   */
  clearFilter(): void {
    this.existingSupplierList = this.existingSupplierListOrg;
    this.inputFilter.nativeElement.value = '';
  }

  /**
   * Focus on inputFilter in dropdown menu while typing
   */
  onBlur(): void {
    this.focus && this.inputFilter.nativeElement.focus();
  }

  addExistingSupplier(uuid: string) {
    this._productsFacade.addSupplierToProduct$(this.productId, uuid).subscribe({
      next: this._success.bind(this),
      error: this._error.bind(this),
    });
  }

  onTabIndexChange(): void {
    this.isChains = true;
  }

  onTabChange(event: MatTabChangeEvent) {
    const targetTabLabel = event.tab.textLabel; // Get the label of the tab being selected

    // Store the publication tab index before any change happens
    this.publicationIndex = targetTabLabel === 'Publication' ? event.index : this.publicationIndex;

    // Check if there are unsaved changes and if the user is trying to navigate away from the Publication tab
    if (this.hasUnsavedChanges && targetTabLabel !== 'Publication') {
      this.selectedIndex = this.publicationIndex;

      const dialogRef = this._dialog.open(InfoDialogComponent, {
        width: infoDialogWidth,
        height: infoDialogHeight,
        data: {
          infoText:
            'Please note that you have unsaved changes. If you leave this page without saving first, all of the progress you have made will be lost.',
          confirmationText: 'Do you want to leave the page?',
          btnText: 'Yes, leave',
          type: 'warning',
          text: 'warning-text',
        },
      });

      dialogRef.afterClosed().subscribe((result: boolean) => {
        if (result) {
          this.hasUnsavedChanges = false;
          this.selectedIndex = event.index;
        } else {
          this.selectedIndex = this.publicationIndex;
        }
      });
    } else {
      this.selectedIndex = event.index;
    }
  }

  /**
   * Refreshes data in parent component
   */

  /**
   * Handle the dialog result
   * @param result Whether the user confirmed or canceled
   */
  private _success() {
    this.refresh(true);
    this.menuTrigger.closeMenu();
  }

  getProjects() {
    this._projectsFacade.getProjects$('is_active,name', 999999).subscribe({
      next: this._Success.bind(this),
      error: this._Error.bind(this),
    });
  }

  private _Success(data: IProjectsListResponse) {
    this.projectsList = data.results;
    this.projectsListOrg = data.results;
    this.activeProjectsList = data.results.filter(project => project.is_active);
    this.activeProjectsListOrg = data.results.filter(project => project.is_active);
    this.empty_projects_list = this.projectsList.length <= 0;
  }

  private _Error(error: Record<string, string[]>): void {
    Object.values(error).map(err => this._snackbarService.openTypeSnackbar(err[0], NotificationType.error));
  }

  /**
   * Filtering existing projects
   */
  filterProjects(event: Event | KeyboardEvent) {
    const value = (event.target as HTMLInputElement).value;
    if (!value) {
      this.projectsList = this.projectsListOrg;
    } else {
      this.projectsList = this.projectsListOrg.filter(el => el.name.toLowerCase().includes(value.toLowerCase()));
    }
  }

  /**
   * Filtering existing subprojects
   */
  filtersubprojects(event: Event | KeyboardEvent) {
    const value = (event.target as HTMLInputElement).value;
    if (!value) {
      this.subprojectsList = this.subprojectsListOrg;
    } else {
      this.subprojectsList = this.subprojectsListOrg.filter(el => el.name.toLowerCase().includes(value.toLowerCase()));
    }
  }

  updateProduct(): void {
    this.getProduct();
  }

  getBrandFeatures(): void {
    this._brandsFacade.getBrandFeatures$().subscribe({
      next: this._successBrandFeatures.bind(this),
      error: this._Error.bind(this),
    });
  }

  private _successBrandFeatures(data: IBrandFeaturesResponse) {
    this.brandFeatures = data.results;
    if (this.brandFeatures) {
      this.projectManagement = this.brandFeatures.find(project => project.name === PROJECT_MANAGEMENT);
      if (this.projectManagement) {
        this.show_project = this.projectManagement.is_enabled;
      }
    }
  }
  ////certificates filter
  _applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value.trim().toLowerCase();
    this.product.certification_product = this.filterCertificates(filterValue);
  }

  filterCertificates(filterValue: string) {
    return this.certificatesList.filter(certificate => {
      // Filtering by name, category name, domain name, certificator...
      const nameMatch = certificate.certificate.name.toLowerCase().includes(filterValue);
      const categoryNameMatch = certificate.certificate.category?.name.toLowerCase().includes(filterValue);
      const domainNameMatch =
        certificate.certificate.domain && certificate.certificate.domain.name.toLowerCase().includes(filterValue);
      const certificatorMatch = certificate.certificator.toLowerCase().includes(filterValue);

      // Return true if any of the filter criteria match.
      return nameMatch || categoryNameMatch || domainNameMatch || certificatorMatch;
    });
  }

  onToggleChange(event: MatButtonToggleChange) {
    this.selectedView = event.value;
  }
  scrollContent() {
    const tabContentElement = document.querySelector('.mat-tab-body-active'); // Adjust the selector based on your structure
    if (tabContentElement) {
      tabContentElement.scrollIntoView({ behavior: 'smooth', block: 'start' });

      setTimeout(() => {
        this.openSupplierMenu();
      }, 200);
    }
  }

  openSupplierMenu() {
    // Check if menuTrigger is defined
    if (this.menuTrigger) {
      if (this.menuTrigger.menuOpen) {
        // If the menu is already open, close it before reopening
        this.menuTrigger.closeMenu();
      }

      // Open the MatMenu
      this.menuTrigger.openMenu();
    }
  }
}
