import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ICreateAddressResponse } from '@app/core/interface/address.interface';

@Component({
  selector: 'app-address-view-dialog',
  templateUrl: './address-view-dialog.component.html',
  styleUrls: ['./address-view-dialog.component.scss'],
})
export class AddressViewDialogComponent {
  address!: ICreateAddressResponse;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ICreateAddressResponse,
    public dialogRef: MatDialogRef<AddressViewDialogComponent>
  ) {
    this.address = this.data;
  }
}
