<div class="wrp d-flex flex-column">
  <h5 class="header m-0">{{ 'Packaging - Recycled Materials' | translate }}</h5>
  <mat-radio-group
    class="d-flex justify-content-between re-gr"
    [(ngModel)]="selectedValue"
    (ngModelChange)="onSelection()"
    aria-label="Select an option"
  >
    <div class="radio-wrapper d-flex">
      <div class="radio-section d-flex flex-column">
        <div class="radio-wrp">
          <mat-radio-button [value]="true" class="align-items-start">
            {{ 'There are recycled materials in the packaging' | translate }}
          </mat-radio-button>
        </div>
      </div>
      <div class="radio-section d-flex flex-column">
        <div class="radio-wrp d-flex flex-column align-items-start">
          <mat-radio-button [value]="false" class="align-items-start">
            {{ 'Packaging does not contain any recycled materials' | translate }}
          </mat-radio-button>
          <div class="action d-flex flex-row-reverse justify-content-start" *ngIf="selectedValue === false">
            <div class="action-text">
              {{ 'Display that the packaging does not contain any recycled materials' | translate }}
            </div>
            <mat-slide-toggle
              [checked]="visibility"
              class="custom-slide-toggle"
              [color]="'primary'"
              [(ngModel)]="visibility"
              (ngModelChange)="onVisibilityChange()"
            >
            </mat-slide-toggle>
          </div>
        </div>
      </div>
    </div>
  </mat-radio-group>
</div>
