import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FilePreview } from '@app/core/interface/preview_publicaton.interface';
import { SharedModule } from '@app/shared/shared.module';

@Component({
  standalone: true,
  imports: [SharedModule, CommonModule],
  selector: 'app-image-dialog',
  templateUrl: './image-dialog.component.html',
  styleUrls: ['./image-dialog.component.scss'],
})
export class ImageDialogComponent {
  slideConfig = { slidesToShow: 1, slidesToScroll: 1 };
  constructor(@Inject(MAT_DIALOG_DATA) public images: FilePreview[]) {}
}
