<div class="dialog-header">
  <h3>{{ 'Supplier Site Mapping' | translate }}</h3>
  <app-custom-icon mat-dialog-close [icon_category]="'essential'" [icon_name]="'close-circle'" class="cursor-pointer">
  </app-custom-icon>
</div>
<mat-dialog-content>
  <div class="sel-info" #selInfo>
    <div class="detail-txt d-flex">
      <img src="../../../../assets/images/symbol-blue.svg" alt="Symbol" height="45" width="45" class="col-1" />
      <span class="more-details">
        <p class="p1-strong">
          {{
            'You have approved that the current step supplier matches one of the existing supplier. Then we invite you below to map the current step site with a site of the matching supplier.'
              | translate
          }}
        </p>
      </span>
    </div>
    <app-custom-icon
      class="close-icon cursor-pointer cls-more-details"
      icon_category="essential"
      icon_name="close-circle"
      (click)="selInfo.style.display = 'none'"
    ></app-custom-icon>
  </div>

  <mat-card class="holder">
    <div class="d-flex justify-content-between">
      <div class="d-flex flex-column wrapper-row">
        <p class="p1-strong">
          {{ 'Current step site' | translate }}
        </p>
        <app-input-info
          *ngIf="step.manufactories?.name"
          [label]="'Supplier site name' | translate"
          [value]="step.manufactories?.name"
        ></app-input-info>
        <app-input-info
          *ngIf="mainSiteAddress"
          [label]="'Supplier site address' | translate"
          [value]="mainSiteAddress"
        ></app-input-info>
      </div>
      <div class="d-flex flex-column wrapper-row">
        <p class="p1-strong">
          {{ 'Matching supplier’s site' | translate }}
        </p>
        <div class="label">{{ 'Choose an existing site that matches the current step site' | translate }}</div>
        <form [formGroup]="form">
          <div class="row">
            <mat-form-field class="input-primary p-0" appearance="outline">
              <mat-label>{{ 'Choose Supplier Site' | translate }}</mat-label>
              <mat-select formControlName="manufactory" (selectionChange)="onValueChange()">
                <mat-select-trigger>
                  <div *ngIf="this.selectedSite">
                    <span class="site-name-blue">{{ this.selectedSite.name }}</span>
                    <span class="site-address">
                      {{
                        this._concatenateFields(
                          this.selectedSite?.address,
                          this.selectedSite?.city,
                          this.selectedSite?.region,
                          this.selectedSite?.country
                        )
                      }}</span
                    >
                  </div>
                </mat-select-trigger>
                <mat-option *ngFor="let site of sites" [value]="site.uuid" placeholder="Phase">
                  <div>
                    <span class="site-name">
                      {{ site.name }}
                    </span>

                    <span class="site-address">
                      {{ this._concatenateFields(site.address, site.city, site.region, site.country) }}</span
                    >
                  </div>
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </form>
      </div>
    </div>
  </mat-card>
  <div class="action-buttons d-flex justify-content-end mt-4">
    <app-rounded-button
      class="text-decoration-underline"
      color="tetriary-link"
      [button_text]="'No matching supplier site' | translate"
      [size]="'md'"
      (click)="onDontMatch()"
    >
    </app-rounded-button>
    <app-rounded-button
      color="success"
      [button_text]="'Validate Sites Matching' | translate"
      [size]="'md'"
      (click)="onValidateSupplier()"
    >
    </app-rounded-button>
  </div>
</mat-dialog-content>
