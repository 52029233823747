<div class="wrapper">
  <div class="holder">
    <div class="header">
      <img class="symbol" src="../../../../assets/images/symbol-blue-logo.svg" alt="" />
      <img class="logo" src="../../../../assets/logos/RespectSaas-Blue.svg" alt="" />
    </div>
    <div class="content-holder">
      <div class="content">
        <h4>{{ 'Great job you successfully finished with the publication of this product' | translate }}</h4>

        <div class="product d-flex justify-content-center">
          <div class="product-name d-flex align-items-center">
            <img [src]="product?.image ? product?.image : '../../../../assets/images/upload-placeholder-blue.svg'" />
            <h6 class="name">
              {{ product?.name }}
            </h6>
          </div>
          <!-- field description is missing from backend -->
          <p class="description">
            <app-view-rich-text [content]="product.description"></app-view-rich-text>
          </p>
        </div>
      </div>
      <div class="footer d-flex">
        <app-rounded-button
          color="secondary"
          [button_text]="'Back to the product'"
          [icon_category]="'arrows'"
          [icon_name]="'arrow-left'"
          [icon_left]="true"
          (click)="goToProduct()"
        >
        </app-rounded-button>
        <app-rounded-button
          color="primary"
          [button_text]="'To the live publication'"
          [icon_category]="'arrows'"
          [icon_name]="'arrow-right'"
          [icon_right]="true"
          (click)="goToExternalLink()"
        >
        </app-rounded-button>
      </div>
    </div>
  </div>
</div>
