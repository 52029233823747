import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import {
  IBillingAddressDetails,
  IBillingCardDetails,
  IBillingCustomerDetails,
  IBillingManagementResult,
  InvoiceDownloadResult,
} from '../interface/billing.interface';

@Injectable({
  providedIn: 'root',
})
export class BillingService {
  private apiUrl = environment.api;

  constructor(private _http: HttpClient) {}

  getCustomerDetails$(): Observable<IBillingCustomerDetails> {
    return this._http.get<IBillingCustomerDetails>(`${this.apiUrl}/chargebee/customer`);
  }

  updateCustomerDetails$(data: FormData): Observable<IBillingCustomerDetails> {
    return this._http.put<IBillingCustomerDetails>(`${this.apiUrl}/chargebee/customer`, data);
  }

  updateBillingInfo$(data: FormData): Observable<IBillingAddressDetails> {
    return this._http.put<IBillingAddressDetails>(`${this.apiUrl}/chargebee/billinginfo`, data);
  }

  getCreditCardDetails$(): Observable<Array<IBillingCardDetails>> {
    return this._http.get<Array<IBillingCardDetails>>(`${this.apiUrl}/chargebee/card`);
  }

  updateCreditCardDetails$(data: FormData): Observable<Array<IBillingCardDetails>> {
    return this._http.put<Array<IBillingCardDetails>>(`${this.apiUrl}/chargebee/card`, data);
  }

  getInvoices$(): Observable<IBillingManagementResult> {
    return this._http.get<IBillingManagementResult>(`${this.apiUrl}/chargebee/invoices`);
  }

  downloadInvoice$(invoiceId: string): Observable<InvoiceDownloadResult> {
    return this._http.get<InvoiceDownloadResult>(`${this.apiUrl}/chargebee/${invoiceId}/download`);
  }
}
