import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

export interface ConfirmComponent {
  canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}

@Injectable({
  providedIn: 'root',
})
export class ConfirmGuard {
  canDeactivate(component: ConfirmComponent): Observable<boolean> | Promise<boolean> | boolean {
    return component.canDeactivate ? component.canDeactivate() : true;
  }
}
