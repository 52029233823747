<div class="modal-container d-flex flex-column full-height">
  <div class="holder">
    <div class="header">
      <h1 *ngIf="!selectedSupplier" class="m-0">{{ 'Manage Suppliers' | translate }}</h1>
      <div class="custom-breadcrump" *ngIf="selectedSupplier">
        <div class="choose-supplier">
          <app-custom-icon
            *ngIf="!editSupplierHeader"
            [icon_category]="'arrows'"
            [icon_name]="'arrow-circle-left-short'"
            (click)="selectedSupplier = undefined; detailsFormValidation = siteForm = addComponent = false"
          ></app-custom-icon>
          <h4>{{ editSupplierHeader ? ('Edit Supplier' | translate) : ('Manage Suppliers' | translate) }}</h4>
        </div>
        <div class="supplier-info cursor" (click)="supplierView()">
          <app-custom-icon [icon_category]="'arrows'" [icon_name]="'arrow-right-short'"></app-custom-icon>
          <h4>{{ selectedSupplier?.name }}</h4>
        </div>
        <div *ngIf="siteForm || isUpdateSite" class="supplier-info">
          <app-custom-icon [icon_category]="'arrows'" [icon_name]="'arrow-right-short'"></app-custom-icon>
          <h4>{{ !isUpdateSite ? ('Add Supplier Site' | translate) : ('Edit Site' | translate) }}</h4>
        </div>
        <div *ngIf="addComponent" class="supplier-info">
          <app-custom-icon [icon_category]="'arrows'" [icon_name]="'arrow-right-short'"></app-custom-icon>
          <h4>{{ 'Add Component' | translate }}</h4>
        </div>
        <div *ngIf="componentView" class="supplier-info">
          <app-custom-icon [icon_category]="'arrows'" [icon_name]="'arrow-right-short'"></app-custom-icon>
          <h4>{{ isUpdateComponent ? ('Edit Components' | translate) : ('View Components' | translate) }}</h4>
        </div>
      </div>
    </div>
    <div class="divider-holder">
      <mat-divider></mat-divider>
    </div>

    <mat-dialog-content #dialogContent class="mat-typography" *ngIf="!selectedSupplier">
      <h2>{{ 'Choose the supplier you want to edit' }}</h2>
      <div *ngIf="!data.supplier" class="row justify-content-between suppliers-wraper">
        <div class="supplier-card" *ngFor="let supplier of data.suppliers">
          <app-custom-icon
            [icon_category]="'arrows'"
            [icon_name]="'arrow-circle-right'"
            (click)="selectedSupplier = supplier"
          ></app-custom-icon>
          <h6>{{ supplier.name }}</h6>
          <div class="manufactories-holder">
            <div class="manufactory" *ngFor="let manufactory of supplier.manufactories">
              <p
                [ngClass]="{ 'main-location': manufactory.is_main_location }"
                matTooltip="{{ manufactory.is_main_location ? 'main site' : '' }}"
                matTooltipClass="custom-tooltip-end"
                [matTooltipPosition]="'above'"
              >
                <span>{{ manufactory.name }}</span> {{ manufactory.address }} {{ manufactory.city }}
                {{ manufactory.country }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </mat-dialog-content>
  </div>

  <mat-dialog-content #dialogContent *ngIf="selectedSupplier" class="selected-supplier">
    <div class="m-4">
      <app-supplier-details
        *ngIf="!hideSupplierComponent"
        #supplierDetails
        (isFormValid)="isDetailsFormValid($event)"
        [supplierData]="selectedSupplier"
        (supplier)="supplierUpdated($event)"
        [show_buttons]="show_buttons"
      >
      </app-supplier-details>
    </div>

    <div class="divider-between" [ngClass]="{ 'd-none': componentView || addComponent || siteForm }">
      <mat-divider></mat-divider>
    </div>
    <app-supplier-sites
      *ngIf="!hideSupplierSIte"
      #supplierSites
      (isFormValid)="isSitesFormValid($event)"
      (isUpdate)="isSitesUpdate($event)"
      [supplierUuid]="selectedSupplier.uuid"
      [supplierSites]="selectedSupplier.manufactories"
      [show_buttons]="show_buttons"
      [componentCreated]="componentCreated"
      (addComponent)="createComponent($event)"
      (site)="getSite($event)"
      (componentsView)="viewComponent($event)"
      (showSiteForm)="siteFormView($event)"
      (siteCreated)="siteCreated($event)"
      (ingredients)="setIngredients($event)"
      [hideAddSite]="hideAddSite"
      [componentDeleted]="componentDeleted"
    >
    </app-supplier-sites>

    <app-supplier-components
      *ngIf="addComponent || componentView"
      #supplierComponents
      [addComponent]="addComponent"
      (isFormValid)="isComponentFormValid($event)"
      (isUpdate)="isComponentUpdate($event)"
      [supplierUuid]="selectedSupplier.uuid"
      [supplierName]="selectedSupplier.name"
      [supplierComponents]="components"
      [show_buttons]="component_btn"
      [site]="site"
      (componentCreated)="componentCretaed($event)"
      (close)="closeComponent($event)"
      (componentDeleted)="deletedComponent($event)"
      [componentView]="componentView"
    ></app-supplier-components>
  </mat-dialog-content>

  <mat-dialog-actions class="justify-content-end">
    <app-rounded-button
      [disabled]="detailsFormValidation || siteForm || addComponent"
      color="primary"
      [size]="'md'"
      [button_text]="'Close Manage Suppliers'"
      mat-dialog-close="true"
    >
    </app-rounded-button>
  </mat-dialog-actions>
</div>
