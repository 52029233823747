import { Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

@Injectable()
export class AddDirectSupplierGroup {
  addDirectSupplier: UntypedFormGroup;

  constructor(private _fb: UntypedFormBuilder) {
    this.addDirectSupplier = this._fb.group({
      name: [null, Validators.required],
      role: [null, Validators.required],
      email: [null, [Validators.required, Validators.email]],
      language_prefered: [null, Validators.required],
    });
  }
}
