<div class="holder d-flex flex-column">
  <div class="table-holder">
    <!-- Table -->
    <table mat-table matSort [dataSource]="dataSource" class="custom-table">
      <ng-container matColumnDef="name">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>
          {{ 'Name' | translate }}
        </th>
        <td mat-cell *matCellDef="let element" class="alerts-name bold-column">
          {{ element.name }}
        </td>
      </ng-container>

      <ng-container matColumnDef="username">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>
          {{ 'Username' | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.username }}
        </td>
      </ng-container>

      <ng-container matColumnDef="role">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>
          {{ 'Role' | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.role }}
        </td>
      </ng-container>

      <ng-container matColumnDef="actions" class="actions">
        <th mat-header-cell mat-sort-header *matHeaderCellDef class="jc-center">
          <div class="action">{{ 'Actions' | translate }}</div>
        </th>
        <td mat-cell *matCellDef="let element">
          <div class="preview-container">
            <app-custom-icon
              matTooltip="{{ 'Last Login' | translate }}"
              matTooltipClass="custom-tooltip-center"
              matTooltipPosition="above"
              [icon_category]="'security'"
              [icon_name]="'lock-circle'"
            ></app-custom-icon>
            <app-custom-icon
              matTooltip="{{ 'Reset Password' | translate }}"
              matTooltipClass="custom-tooltip-center"
              matTooltipPosition="above"
              [icon_category]="'security'"
              [icon_name]="'key'"
            ></app-custom-icon>
            <app-custom-icon
              matTooltip="{{ 'Block Access' | translate }}"
              matTooltipClass="custom-tooltip-center"
              matTooltipPosition="above"
              [icon_category]="'essential'"
              [icon_name]="'slash'"
            ></app-custom-icon>
            <app-custom-icon
              matTooltip="{{ 'Delete User' | translate }}"
              matTooltipClass="custom-tooltip-end"
              matTooltipPosition="above"
              [icon_category]="'user'"
              [icon_name]="'profile-delete'"
            ></app-custom-icon>
          </div>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </div>

  <div class="card-footer">
    <mat-paginator showFirstLastButtons aria-label="Select page of contacts"> </mat-paginator>
  </div>
</div>
