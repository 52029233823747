<div class="holder">
  <div class="header">
    <h3>Removing {{ name }}</h3>
  </div>

  <div class="h-line">
    <div class="horizontal-line mb-2"></div>
  </div>
  <mat-dialog-content>
    <div class="form-note">
      <div class="logo">
        <img class="pdna-logo" src="../../../../assets/logos/symbol-logo-dark.svg" />
      </div>
      <div class="note">
        <p>
          {{
            " Please note that this action will just remove the cluster, and the steps in the cluster won't be deleted."
              | translate
          }}
        </p>
      </div>
    </div>

    <h4>Are you sure you want to remove it ?</h4>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <app-rounded-button color="tetriary-link" [button_text]="'Cancel'" [size]="'md'" mat-dialog-close>
    </app-rounded-button>
    <app-rounded-button color="primary" [button_text]="'Remove Cluster'" [size]="'md'" (click)="deleteCluster()">
    </app-rounded-button>
  </mat-dialog-actions>
</div>
