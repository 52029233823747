import { Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

@Injectable()
export class IngredientDetailsGroup {
  ingredientDetails: UntypedFormGroup;

  constructor(private _fb: UntypedFormBuilder) {
    this.ingredientDetails = this._fb.group({
      reference: [null],
      quantity: [null],
      sourcing_location: [null],
    });
  }
}
