<div class="wrapper">
  <div class="row">
    <h6>{{ 'All Settings' | translate }}</h6>
  </div>
  <ng-container *ngFor="let setting of settingItems">
    <ng-container *ngIf="setting.show">
      <div class="row heading">
        <h5>{{ setting.row_name | translate }}</h5>
      </div>

      <div class="row items">
        <ng-container *ngFor="let item of setting.items; let i = index">
          <ng-container
            *ngIf="item.keyword === 'Assign Brands to CSM' ? !environment.qa && !environment.production : true"
          >
            <div *ngIf="item.show" class="col-4 nav-item" [routerLink]="item.routerLink">
              <app-custom-icon [icon_category]="item.icon_category" [icon_name]="item.icon_name"></app-custom-icon>
              <p>{{ item.keyword | translate }}</p>
              <app-custom-icon
                class="arrow"
                [icon_category]="item.arrow_icon_category"
                [icon_name]="item.arrow_icon_name"
              ></app-custom-icon>
            </div>
          </ng-container>
          <!-- condition "setting.row_name !== 'ADMINISTRATIVE SETTINGS'" needs to be removed when Billing Management will be shown again-->
          <div *ngIf="i === 2 && setting.row_name !== 'ADMINISTRATIVE SETTINGS'" class="horizontal-line"></div>
        </ng-container>
        <div class="horizontal-line mb-4"></div>
      </div>
    </ng-container>
  </ng-container>
</div>
