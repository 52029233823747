import { Injectable } from '@angular/core';
import { Observable, take, catchError, ObservableInput, throwError } from 'rxjs';
import {
  IBillingAddressDetails,
  IBillingCardDetails,
  IBillingCustomerDetails,
  IBillingManagementResult,
  IBillingUpdateCardDetails,
  InvoiceDownloadResult,
} from '../interface/billing.interface';
import { BillingService } from '../service/billing.service';
import { generateFormData } from '../utils/generate-formData';

@Injectable()
export class BillingFacade {
  constructor(private _billingService: BillingService) {}

  getCustomerDetails$(): Observable<IBillingCustomerDetails> {
    return this._billingService.getCustomerDetails$().pipe(
      take(1),
      catchError(
        ({ error }: IBillingCustomerDetails): ObservableInput<IBillingCustomerDetails> => throwError(() => error)
      )
    );
  }

  updateCustomerDetails$(data: Partial<IBillingCustomerDetails>): Observable<IBillingCustomerDetails> {
    const formData = generateFormData(data);

    return this._billingService.updateCustomerDetails$(formData).pipe(
      take(1),
      catchError(
        ({ error }: IBillingCustomerDetails): ObservableInput<IBillingCustomerDetails> => throwError(() => error)
      )
    );
  }

  updateBillingInfo$(data: Partial<IBillingAddressDetails>): Observable<IBillingAddressDetails> {
    const formData = generateFormData(data);

    return this._billingService.updateBillingInfo$(formData).pipe(
      take(1),
      catchError(
        ({ error }: IBillingAddressDetails): ObservableInput<IBillingAddressDetails> => throwError(() => error)
      )
    );
  }

  getCreditCardDetails$(): Observable<Array<IBillingCardDetails>> {
    return this._billingService.getCreditCardDetails$().pipe(
      take(1),
      catchError(
        ({ error }: IBillingCardDetails): ObservableInput<Array<IBillingCardDetails>> => throwError(() => error)
      )
    );
  }

  updateCreditCardDetails$(data: Partial<IBillingUpdateCardDetails>): Observable<Array<IBillingCardDetails>> {
    const formData = generateFormData(data);

    return this._billingService.updateCreditCardDetails$(formData).pipe(
      take(1),
      catchError(
        ({ error }: IBillingCardDetails): ObservableInput<Array<IBillingCardDetails>> => throwError(() => error)
      )
    );
  }

  getInvoices$(): Observable<IBillingManagementResult> {
    return this._billingService.getInvoices$().pipe(
      take(1),
      catchError(
        ({ error }: IBillingManagementResult): ObservableInput<IBillingManagementResult> => throwError(() => error)
      )
    );
  }

  downloadInvoice$(invoiceId: string): Observable<InvoiceDownloadResult> {
    return this._billingService.downloadInvoice$(invoiceId).pipe(
      take(1),
      catchError(({ error }: InvoiceDownloadResult): ObservableInput<InvoiceDownloadResult> => throwError(() => error))
    );
  }
}
