import { Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

@Injectable()
export class CompleteDirectSupplierGroup {
  completeDirectSupplier: UntypedFormGroup;

  constructor(private _fb: UntypedFormBuilder) {
    this.completeDirectSupplier = this._fb.group({
      quantity: [null, Validators.required],
      quantity_unit: [null, Validators.required],
      reference: [null],
      proofs: [null, Validators.required],
      email: [null, [Validators.required, Validators.email]],
      language_prefered: [null, Validators.required],
    });
  }
}
