import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Injectable } from '@angular/core';
import { IsCountry } from '@app/core/utils/country.validator';

@Injectable()
export class SupplierManagmentGroup {
  supplierForm: UntypedFormGroup;
  infoForm: UntypedFormGroup;

  constructor(private _fb: UntypedFormBuilder) {
    this.supplierForm = this._fb.group(
      {
        description: [null],
        address: [null],
        city: [null],
        country: [null, [Validators.required]],
        region: [null],
      },
      { validator: IsCountry() }
    );
    this.infoForm = this._fb.group({
      name: [null, [Validators.required]],
      logo: [null],
    });
  }
}
