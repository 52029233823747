import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { IProduct, IProductProject } from '@app/core/interface/products.interface';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-project-details-dialog',
  templateUrl: './project-details-dialog.component.html',
  styleUrls: ['./project-details-dialog.component.scss'],
})
export class ProjectDetailsDialogComponent implements OnInit {
  project!: IProductProject;
  status: string | undefined;
  is_project!: boolean;
  title!: string;
  description!: string;
  photo!: string;
  location!: string;
  duration!: string;
  location_not_set!: string;
  selectedIndex = 0;
  showRedCircle = true;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      project: IProductProject;
      is_project: boolean;
      project_view?: boolean;
      project_products: Array<IProduct>;
    },
    private translate: TranslateService
  ) {
    if (data) {
      this.is_project = this.data.is_project;
      this.project = this.data.project;
      this.title = this.is_project
        ? this.translate.instant('Project Details')
        : this.translate.instant('Sub-project Details');
      this.description = this.is_project
        ? this.translate.instant('Project Description')
        : this.translate.instant('Sub-project Description');
      this.photo = this.is_project
        ? this.translate.instant('Project Photo')
        : this.translate.instant('Sub-project Photo');
      this.location = this.is_project
        ? this.translate.instant('Project Location')
        : this.translate.instant('Sub-project Location');
      this.duration = this.is_project
        ? this.translate.instant('Project Duration')
        : this.translate.instant('Sub-project Duration');
      this.location_not_set = this.is_project
        ? this.translate.instant('project not linked to location')
        : this.translate.instant('sub-project not linked to location');
    }
  }

  ngOnInit(): void {
    this.status = this.project.status.name;
  }
  tabChanged(event: MatTabChangeEvent) {
    this.selectedIndex = event.index;

    // Hide red-circle when custom fields tab is selected
    if (this.selectedIndex === 1) {
      this.showRedCircle = false;
    }
  }
}
