<div style="padding-bottom: 8px" *ngIf="question">
  <div
    class="row"
    style="border: solid 2px black; border-radius: 10px; {{ sectionExpanded ? 'height: auto' : 'height: 48px' }} "
  >
    <div class="col-1">
      <div class="edit-icon">
        <ng-container *ngIf="!sectionExpanded">
          <app-custom-icon
            matTooltip="{{ 'Expand Section' | translate }}"
            matTooltipClass="custom-tooltip above"
            matTooltipPosition="above"
            [icon_category]="'arrows'"
            [icon_name]="'arrow-square-down'"
            (click)="expandSection()"
          >
          </app-custom-icon>
        </ng-container>
        <ng-container *ngIf="sectionExpanded">
          <app-custom-icon
            matTooltip="{{ 'Shrink Section' | translate }}"
            matTooltipClass="custom-tooltip above"
            matTooltipPosition="above"
            [icon_category]="'arrows'"
            [icon_name]="'arrow-square-up'"
            (click)="expandSection()"
          >
          </app-custom-icon>
        </ng-container>
      </div>
    </div>
    <div class="col-9">
      <ng-container>
        <p style="padding-top: 15px">
          {{ 'Question' | translate }} {{ numOfQuestion }}: <span class="questionName">{{ question.name }}</span>
        </p>
      </ng-container>
    </div>
    <div class="col-1">
      <div class="edit-icon">
        <app-custom-icon
          matTooltip="{{ 'Edit Name' | translate }}"
          matTooltipClass="custom-tooltip above"
          matTooltipPosition="above"
          [icon_category]="'files'"
          [icon_name]="'edit-2'"
          (click)="editName(question.name)"
        >
        </app-custom-icon>
      </div>
    </div>
    <div class="col-1">
      <div class="edit-icon">
        <app-custom-icon
          matTooltip="{{ 'Delete Question' | translate }}"
          matTooltipClass="custom-tooltip above"
          matTooltipPosition="above"
          [icon_category]="'essential'"
          [icon_name]="'minus-circle'"
          (click)="deleteQuestion()"
        >
        </app-custom-icon>
      </div>
    </div>
  </div>
  <div class="row" *ngIf="sectionExpanded">
    <div class="col-12">
      <div class="survey-block">
        <div class="radio-buttons-container">
          <mat-radio-group [(ngModel)]="selectedOption">
            <mat-radio-button value="yes" disabled>Yes</mat-radio-button>
          </mat-radio-group>
          <app-custom-icon
            (click)="toggleFollowUpQuestion('yes')"
            *ngIf="!showFollowUpQuestion['yes']"
            icon_category="essential"
            icon_name="add"
          ></app-custom-icon>
          <div class="follow-up-question-container" *ngIf="showFollowUpQuestion['yes']">
            <app-follow-up-question></app-follow-up-question>
          </div>
        </div>

        <div class="radio-buttons-container">
          <mat-radio-group [(ngModel)]="selectedOption">
            <mat-radio-button value="no" disabled>No</mat-radio-button>
          </mat-radio-group>
          <app-custom-icon
            (click)="toggleFollowUpQuestion('no')"
            *ngIf="!showFollowUpQuestion['no']"
            icon_category="essential"
            icon_name="add"
          ></app-custom-icon>
          <div class="follow-up-question-container" *ngIf="showFollowUpQuestion['no']">
            <app-follow-up-question></app-follow-up-question>
          </div>
        </div>

        <div class="radio-buttons-container">
          <mat-radio-group [(ngModel)]="selectedOption">
            <mat-radio-button value="maybe" disabled>Maybe</mat-radio-button>
          </mat-radio-group>
          <app-custom-icon
            (click)="toggleFollowUpQuestion('maybe')"
            *ngIf="!showFollowUpQuestion['maybe']"
            icon_category="essential"
            icon_name="add"
          ></app-custom-icon>
          <div class="follow-up-question-container" *ngIf="showFollowUpQuestion['maybe']">
            <app-follow-up-question></app-follow-up-question>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
