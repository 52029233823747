import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AssignBrandsRoutingModule } from './assign-brands-routing.module';
import { AssignBrandsComponent } from './assign-brands.component';
import { AngularMaterialModule } from '@app/material.module';
import { SharedModule } from '@app/shared/shared.module';
import { AssignBrandsTableComponent } from './assign-brands-table/assign-brands-table.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [AssignBrandsComponent, AssignBrandsTableComponent],
  imports: [
    CommonModule,
    AssignBrandsRoutingModule,
    AngularMaterialModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
  ],
})
export class AssignBrandsModule {}
