import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

import { Injectable } from '@angular/core';

@Injectable()
export class CertificatesGroup {
  certificateForm: UntypedFormGroup;
  certificationForm: UntypedFormGroup;

  constructor(private _fb: UntypedFormBuilder) {
    this.certificateForm = this._fb.group({
      name: [null, [Validators.required, Validators.maxLength(30)]],
      external_url: [null, [Validators.maxLength(200)]],
      image: [null],
      description: [null],
      summary: [null],
      domain: [null],
      category: [null, [Validators.required]],
    });

    this.certificationForm = this._fb.group({
      certificate: [null, [Validators.required]],
      certificator: [null],
      document: [null, [Validators.required]],
      validity_start: [null],
      validity_end: [{ value: null, disabled: true }],
      manufactories: [new UntypedFormControl()],
    });
  }
}
