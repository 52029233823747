<div class="d-flex justify-content-center align-items-center">
  <div class="content-holder">
    <div class="logo-holder"></div>

    <div class="text-holder">
      <h3>
        <strong>{{ 'Create a Product' | translate }}</strong>
      </h3>
      <h6>{{ 'The first step in traceability is to create the product that you want to trace.' | translate }}</h6>
    </div>

    <div class="divider-holder">
      <mat-divider></mat-divider>
    </div>

    <form class="row" [formGroup]="form">
      <div class="col-12 col-md-4">
        <app-file-upload
          [placeholder]="'Upload product image'"
          [iconType]="'add_photo_alternate'"
          (fileData)="saveImageData($event)"
          [status]="form.controls['image'].status"
        >
        </app-file-upload>
      </div>
      <div class="col-12 col-md-8 row p-0">
        <mat-form-field class="col-12 input-primary" appearance="outline">
          <mat-label>{{ 'Product Name' | translate }}</mat-label>
          <input matInput formControlName="name" placeholder="Product Name" />
        </mat-form-field>
        <div class="col-12 input-primary textarea" appearance="outline">
          <mat-label>{{ 'Product Description' | translate }}</mat-label>
          <div class="wrp">
            <app-rs-rte
              formControlName="description"
              [c]="$any(form.controls['description'])"
              [placeholder]="'Product Description' | translate"
            ></app-rs-rte>
          </div>
        </div>
        <mat-form-field class="col-12 input-primary mat-form-field-custom-spacing" appearance="outline">
          <mat-label>{{ 'Product Reference Number' | translate }}</mat-label>
          <input matInput formControlName="reference" placeholder="{{ 'Product Reference Number' | translate }}" />
        </mat-form-field>
      </div>

      <div class="buttons-row d-flex">
        <app-rounded-button
          color="primary"
          button_text="{{ 'Create product' | translate }}"
          [icon_category]="'arrows'"
          [icon_name]="'arrow-right'"
          [icon_right]="true"
          [disabled]="form.invalid || form.pristine"
          (click)="createProduct()"
        >
        </app-rounded-button>
      </div>
    </form>
  </div>
</div>
