import { Component, OnInit } from '@angular/core';
import { NotificationType } from '@app/core/constants';
import { TasksFacade } from '@app/core/facade/tasks.facade';
import { IErrorResponse } from '@app/core/interface/projects.interface';
import { ITask, ITasksInfo, ITasksResponse } from '@app/core/interface/tasks.interface';
import { SnackbarService } from '@app/core/service/snackbar.service';

@Component({
  selector: 'app-tasks-dashboard',
  templateUrl: './tasks-dashboard.component.html',
  styleUrls: ['./tasks-dashboard.component.scss'],
})
export class TasksDashboardComponent implements OnInit {
  tasks!: Array<ITask>;
  tasksInfo: ITasksInfo = {
    total_tasks: 0,
    to_do_tasks: 0,
    completed_tasks: 0,
    in_progress_tasks: 0,
  };
  constructor(
    private _facade: TasksFacade,
    private _snackbarService: SnackbarService
  ) {}
  ngOnInit(): void {
    this.getTasks();
  }
  getTasks() {
    this._facade.getTasks$().subscribe({
      next: this._getTasksSuccess.bind(this),
      error: this._error.bind(this),
    });
  }
  private _getTasksSuccess(res: ITasksResponse) {
    this.tasks = res.results.reverse();
    this._setTasksInfo(res);
  }

  private _error(error: IErrorResponse): void {
    Object.values(error).map(err => this._snackbarService.openTypeSnackbar(err[0], NotificationType.error));
  }

  private _setTasksInfo(res: ITasksResponse) {
    this.tasksInfo = {
      completed_tasks:
        res.results.length && res.results[0].completed_tasks && res.results[0].total_tasks
          ? Math.round((res.results[0].completed_tasks / res.results[0].total_tasks) * 100)
          : 0,
      in_progress_tasks:
        res.results.length && res.results[0].in_progress_tasks && res.results[0].total_tasks
          ? Math.round((res.results[0].in_progress_tasks / res.results[0].total_tasks) * 100)
          : 0,
      to_do_tasks:
        res.results.length && res.results[0].to_do_tasks && res.results[0].total_tasks
          ? Math.round((res.results[0].to_do_tasks / res.results[0].total_tasks) * 100)
          : 0,
      total_tasks: res.results.length && res.results[0].total_tasks ? res.results[0].total_tasks : 0,
    };
  }
}
