import { ICluster, IDeleteCluster } from '../interface/cluster.interface';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class ClusterService {
  private apiUrl = environment.api;

  constructor(private _http: HttpClient) {}

  getCluster$(uuid: string): Observable<ICluster> {
    return this._http.get<ICluster>(`${this.apiUrl}/groups/${uuid}/`);
  }

  createCluster$(data: FormData): Observable<ICluster> {
    return this._http.post<ICluster>(`${this.apiUrl}/groups/`, data);
  }

  updateCluster$(uuid: string, data: FormData): Observable<ICluster> {
    return this._http.put<ICluster>(`${this.apiUrl}/groups/${uuid}/`, data);
  }

  deleteCluster$(uuid: string): Observable<IDeleteCluster> {
    return this._http.delete<IDeleteCluster>(`${this.apiUrl}/groups/${uuid}/`);
  }
}
