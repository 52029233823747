import { Component, EventEmitter, Input, Output, QueryList, ViewChildren } from '@angular/core';

import { CreatePackagingDialogComponent } from './create-packaging-dialog/create-packaging-dialog.component';
import { IAgecPackaging } from '@app/core/interface/regulations.interface';
import { IProduct } from '@app/core/interface/products.interface';
import { MatDialog } from '@angular/material/dialog';
import { SetPackagingInfoComponent } from './create-packaging-dialog/set-packaging-info/set-packaging-info.component';

@Component({
  selector: 'app-packaging',
  templateUrl: './packaging.component.html',
  styleUrls: ['./packaging.component.scss'],
})
export class PackagingComponent {
  @Input() agec = '';
  @Input() product!: IProduct | undefined;
  @ViewChildren(SetPackagingInfoComponent) packs!: QueryList<SetPackagingInfoComponent>;
  packaging: Array<Partial<IAgecPackaging>> = [];
  // @Input() packaging!: Partial<IAgecPackaging>;
  @Output() refresh = new EventEmitter<boolean>(false);

  invalid = true;
  constructor(private _dialog: MatDialog) {}
  addPac() {
    const data = { edit: false, agec: this.agec, product: this.product };
    const dialog = this._dialog.open(CreatePackagingDialogComponent, {
      width: '1160px',
      height: '726px',
      panelClass: ['top-padding-0', 'overflow-hidden-dialog'],
      data,
    });
    dialog.afterClosed().subscribe((res: Partial<IAgecPackaging>) => {
      res && this.packaging.push(res) && this.refresh.emit(true);
    });
  }
  removePackaging(uuid: string) {
    this.packaging = this.packaging.filter(pac => pac.uuid !== uuid);
    this.refresh.emit(true);
  }
}
