import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { NotificationType, OFFICE_FILENAME_EXTENSIONS } from '@app/core/constants';
import {
  ICertificate,
  ICertificatesResponse,
  ICertification,
  ICertificationObjectSelected,
  ICertificatsContentType,
  IContentTypeURL,
} from '@app/core/interface/certificates.interface';
import { IManufactory } from '@app/core/interface/manufactories.interface';
import { CertificatesGroup } from '../../certificates.group';
import { CertificatesFacade } from '@app/core/facade/certificates.facade';
import { DatePipe } from '@angular/common';
import { GetDirtyValues } from '@app/core/utils/form-dirty-values';
import { takeWhile } from 'rxjs';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { SuppliersFacade } from '@app/core/facade/suppliers.facade';
import { ICertificationSupplier, ISupplierResponse } from '@app/core/interface/suppliers.interface';
import { FilesMeadiasLinksFacade } from '@app/core/facade/files-medias-links.facade';
import { uploadFileSize } from '@app/core/constants';
import { SnackbarService } from '@app/core/service/snackbar.service';
@Component({
  selector: 'app-brand-product-form',
  templateUrl: './brand-product-form.component.html',
  styleUrls: ['./brand-product-form.component.scss'],
  providers: [CertificatesGroup, CertificatesFacade, SuppliersFacade, DatePipe],
})
export class BrandProductFormComponent implements OnInit, OnDestroy, OnChanges {
  @Input() entityForCertification: ICertificatsContentType = 'Product';
  @Input() certificateObject!: ICertification;
  @Input() certificationObjectUuid!: string;
  @Input() selectedObject!: ICertificationObjectSelected;
  @Input() showTabSelector = false;
  @Input() submitClicked = false;
  @Output() onSubmitSuccess = new EventEmitter<void>();
  @Output() resetSubmit = new EventEmitter<void>();

  form!: FormGroup;
  certificates: Array<ICertificate> = [];
  certificatesFilterList: Array<ICertificate> = [];
  opened = false;
  warn = false;
  typeWarn = false;
  minEnd!: Date;
  mode = 'Create';
  docURL = '';
  docURLTransformed: SafeResourceUrl = '';
  fileName? = '';
  viewer: 'url' | 'office' = 'office';
  supplierSitesView = false;
  toggleArray: Array<boolean> = new Array<boolean>();
  alive = true;
  manufactoriesUuidArray: Array<string> = new Array<string>();
  manufactories!: IManufactory[];
  hasCertificates: number = 0;

  constructor(
    public sanitizer: DomSanitizer,
    private _group: CertificatesGroup,
    private _certificatesFacade: CertificatesFacade,
    private _suppliersFacade: SuppliersFacade,
    private _snackbarService: SnackbarService,
    private _facade: FilesMeadiasLinksFacade,
    public datepipe: DatePipe
  ) {
    this.form = this._group.certificationForm;
  }

  ngOnInit() {
    if (this.certificateObject) {
      this.mode = 'Update';
    }

    this._certificatesFacade.getFilteredCertificates$(this.entityForCertification).subscribe({
      next: this._success.bind(this),
      error: this._error.bind(this),
    });

    this.form.controls['validity_start'].valueChanges.pipe(takeWhile(() => this.alive)).subscribe(date => {
      this.minEnd = date;
      date ? this.form.controls['validity_end'].enable() : this.form.controls['validity_end'].disable();
      if (!date && this.form.controls['validity_end'].value) {
        this.form.controls['validity_end'].patchValue(null);
      }
    });
  }

  ngOnDestroy(): void {
    this.alive = false;
  }

  /**
   *
   * @param element if we have element we are calling this method to set the values in the form(edit scenario)
   */
  setForm(element: ICertification) {
    this.form.controls['certificate'].patchValue(this.getCertificateById(element.certificate.uuid));
    this.form.controls['certificator'].patchValue(
      element.certificator !== null && element.certificator !== 'null' && element.certificator !== ''
        ? element.certificator
        : 'Not set'
    );
    this.form.controls['document'].patchValue(element.document);
    if (element.certificate_subject.manufactories)
      this.form.controls['manufactories'].patchValue(element.certificate_subject.manufactories);
    if (element.document) this.fileName = element.document.split('/').at(-1);
    this.docURLTransformed = this.docURL = element.document;
    this.setView(element.document);
    if (element.validity_start) {
      this.form.controls['validity_start'].patchValue(element.validity_start);
      this.minEnd = new Date(element.validity_start);
    }
    if (element.validity_end) this.form.controls['validity_end'].patchValue(element.validity_end);
  }

  /**
   *
   * @param id certificate id
   * @returns hole certificate obj depending on it's id
   */
  getCertificateById(id?: string): ICertificate {
    let certificate!: ICertificate;
    this.certificates.find(res => {
      if (res.uuid === id) {
        certificate = res;
      }
    });
    return certificate;
  }

  filterItem(event: Event) {
    const value = (event.target as HTMLInputElement).value;
    if (!value) {
      this.certificates = this.certificatesFilterList;
    } else {
      this.certificates = this.certificatesFilterList.filter(el => el.name.toLowerCase().includes(value.toLowerCase()));
    }
  }

  displayFn(subject: ICertificate | null): string {
    return subject?.name ?? '';
  }

  onClosedEvent() {
    this.opened = false;
  }

  isOpened() {
    this.opened = true;
  }

  openOrClosePanel(evt: Event, trigger: MatAutocompleteTrigger): void {
    evt.stopPropagation();
    if (trigger.panelOpen) {
      trigger.closePanel();
      this.opened = false;
    } else {
      trigger.openPanel();
      this.opened = true;
    }
  }

  /**
   *
   * @param event on file selection from event we are setting the file to blob and
   * create SafeResourceUrl if file is selected , so user can have option to open in new tab
   */
  onFileSelected(event: Event): void {
    const target = event.target as HTMLInputElement;
    const file = target.files?.[0];
    this.fileName = this.docURL = this.docURLTransformed = '';

    if (file) {
      this.fileName = file.name;
      this.typeWarn = !this._facade.isValidUploadType(file.type, [
        'doc',
        'docx',
        'pdf',
        'xml',
        'msword',
        'vnd.openxmlformats-officedocument.wordprocessingml.document',
      ]);
      if (this.typeWarn) return;
      if (file.size > uploadFileSize) {
        this.warn = true;
        return;
      }

      const name = file.name.split('.').slice(0, -1).join('-');
      this.form.patchValue({ file: file, name: name });

      if (this.form.controls['name'] && this.form.controls['file']) {
        this.form.controls['name'].markAsDirty();
        this.form.controls['file'].markAsDirty();
      }
      this.setView(this.fileName);
      file.arrayBuffer().then(arrayBuffer => {
        const blob = new Blob([new Uint8Array(arrayBuffer)], {
          type: file.type,
        });
        this.docURL = URL.createObjectURL(blob);
        this.docURLTransformed = this.transformTrustURL(this.docURL);
      });
      this.saveDocumentData(file);
    } else {
      this.saveDocumentData(undefined);
    }
  }

  tryAgain(): void {
    this.warn = false;
    this.typeWarn = false;
    this.form.controls['document'].setValue('');
    this.form.controls['document'].markAsDirty();
  }
  /**
   *
   * @param filename depending on extension from the filename we set viewer property
   */
  setView(filename: string) {
    if (filename) {
      const extIndex = filename.split('.').length - 1;
      const ext = filename.split('.')[extIndex];
      if (OFFICE_FILENAME_EXTENSIONS.includes(ext)) {
        this.viewer = 'office';
      } else {
        this.viewer = 'url';
      }
    }
  }

  /**
   *
   * @param url document local blob url
   * @returns SafeResourceUrl
   */
  transformTrustURL(url: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  /**
   *
   * @param file Sets file as form value for document control
   */
  saveDocumentData(file?: File): void {
    this.form.get('document')?.setValue(file);
    this.form.get('document')?.markAsDirty();
  }

  /**
   * Submits the form , if element is present we fire update API if not Create ...
   */
  submit(): void {
    if (this.certificateObject) {
      this._certificatesFacade
        .updateCertification$(
          this.certificateObject.certificate_subject.uuid,
          this.certificateObject.uuid,
          this.getTypeURL(this.entityForCertification),
          this.requestPayload()
        )
        .subscribe({
          next: this._submitSuccess.bind(this),
          error: this._error.bind(this),
        });
    } else {
      this._certificatesFacade
        .createCertification$(
          this.requestPayload(),
          this.getTypeURL(this.entityForCertification),
          this.certificationObjectUuid
        )
        .subscribe({
          next: this._submitSuccess.bind(this),
          error: this._error.bind(this),
        });
    }
  }

  /**
   *
   * @param content_type
   * @returns
   */
  getTypeURL(content_type: ICertificatsContentType): IContentTypeURL {
    let url = 'products';
    if (content_type === 'Brand') url = 'brands';
    if (content_type === 'Supplier') url = 'suppliers';
    if (content_type === 'Manufactory') url = 'manufactories';
    if (content_type === 'Step') url = 'steps';
    return url as IContentTypeURL;
  }

  /**
   *
   * @returns request payload values
   */
  requestPayload(): Record<string, string> {
    this.form.controls['certificator'].markAsDirty();
    this.form.controls['validity_start'].markAsDirty();
    this.form.controls['validity_end'].markAsDirty();
    const submitForm = GetDirtyValues(this.form);
    submitForm['certificate'] = this.form.controls['certificate'].value.uuid;
    if (submitForm['validity_start']) {
      submitForm['validity_start'] =
        this.datepipe.transform(new Date(submitForm['validity_start']), 'yyyy-MM-dd') ?? '';
    } else {
      submitForm['validity_start'] = '';
    }
    if (submitForm['validity_end']) {
      submitForm['validity_end'] = this.datepipe.transform(new Date(submitForm['validity_end']), 'yyyy-MM-dd') ?? '';
    } else {
      submitForm['validity_end'] = '';
    }
    if (this.form.controls['certificate']?.value?.category?.name?.trim().toUpperCase() === 'AUDIT') {
      delete submitForm['validity_start'];
      delete submitForm['validity_end'];
    }
    return submitForm;
  }

  // On toggle site certificate apply
  onChange($event: MatSlideToggleChange, i: number, uuid: string) {
    this.manufactories[i].toggleStatus = !this.manufactories[i].toggleStatus;
    if ($event.checked) {
      this.manufactoriesUuidArray.push(uuid);
    }
    if (!$event.checked) {
      this.manufactoriesUuidArray.forEach((element: string, index: number) => {
        if (element === uuid) this.manufactoriesUuidArray.splice(index, 1);
      });
    }
    this.setManufactoryControlValue();
  }

  setManufactoryControlValue() {
    this.form.controls['manufactories'].setValue(this.manufactoriesUuidArray);
    this.form.controls['manufactories'].markAsDirty();
  }

  // On apply certificate to supplier sites checkbox click
  onCheckboxChange() {
    this.supplierSitesView = !this.supplierSitesView;

    if (!this.manufactories) {
      this._suppliersFacade.getSupplier$(this.certificationObjectUuid).subscribe({
        next: this._setSupplierResponse.bind(this),
        error: this._error.bind(this),
      });
    }
  }

  /**
   *
   * @param data this method is invoked on successfully getting the certificates ,sets the certificates and
   * if element is present(edit mode) sets the values in the form calling setForm
   */
  private _success(data: ICertificatesResponse): void {
    this.certificates = data.results;
    this.certificatesFilterList = data.results;
    this.hasCertificates = this.certificates.length;

    if (this.certificateObject) this.setForm(this.certificateObject);
  }

  /**
   *
   * @param error hadles HTTP error desplaying message from backend
   */
  private _error(error: Record<string, string[]>): void {
    Object.values(error).map(err => this._snackbarService.openTypeSnackbar(err[0], NotificationType.error));
  }

  // Set manufactories list for displaying the sites
  private _setSupplierResponse(response: ISupplierResponse) {
    this.manufactories = <IManufactory[]>response.manufactories;

    if (this.manufactories) {
      this._certificatesFacade.getSupplierCertificate$(this.certificateObject.uuid).subscribe({
        next: this._setCertificateResponse.bind(this),
        error: this._error.bind(this),
      });
    }
  }

  // Filter certified sites and turn on their toggles
  private _setCertificateResponse(response: ICertificationSupplier) {
    const certified_manufactories: IManufactory[] = <IManufactory[]>response.manufactories;

    if (certified_manufactories) {
      certified_manufactories.forEach((certManufactory: IManufactory) => {
        this.manufactories.forEach(manufactory => {
          if (certManufactory.uuid === manufactory.uuid) {
            manufactory.toggleStatus = true;
            this.manufactoriesUuidArray.push(manufactory.uuid);
          }
        });
      });

      this.form.controls['manufactories'].setValue(this.manufactoriesUuidArray);
    }
  }

  /**
   * On succesfull submit we close the modal with true in order to refresh the data in component that was invoked the dialog
   */
  private _submitSuccess(): void {
    this.onSubmitSuccess.emit();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['submitClicked']?.currentValue) {
      if (this.form && (this.form.valid || !this.form.pristine)) {
        this.submit();
      } else {
        this._snackbarService.openTypeSnackbar(`The form is not valid`, NotificationType.error);
      }
      this.resetSubmit.emit();
      this.submitClicked = false;
    }
  }
}
