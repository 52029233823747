import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FilePreview, IBrandPreview, IProductPreview } from '@app/core/interface/preview_publicaton.interface';
import { SharedModule } from '@app/shared/shared.module';

@Component({
  selector: 'app-dialog-info',
  standalone: true,
  imports: [SharedModule, CommonModule],
  templateUrl: './dialog-info.component.html',
  styleUrls: ['./dialog-info.component.scss'],
})
export class DialogInfoComponent {
  carousels?: string[] | FilePreview[];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { product: IProductPreview; reference: string; brand: IBrandPreview }
  ) {}

  ngOnInit() {
    if (this.data.product?.medias && this.data.product.medias.length !== 0) {
      this.carousels = this.data.product.medias;
    }
  }
}
