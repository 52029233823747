import { ISupplier, ISupplierIngredient } from '@app/core/interface/suppliers.interface';
import { ChangeDetectorRef, Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { InfoDialogComponent } from '@app/shared/components/info-dialog/info-dialog.component';
import { SupplierDetailsComponent } from '../create-supplier/supplier-details/supplier-details.component';
import { SupplierSitesComponent } from '../create-supplier/supplier-sites/supplier-sites.component';
import { ManufactoriesService } from '@app/core/service/manufactories.service';
import { IngredientService } from '@app/core/service/ingredient.service';
import { SuppliersService } from '@app/core/service/suppliers.service';
import { SupplierComponentsComponent } from '../create-supplier/supplier-components/supplier-components.component';
import { IManufactory } from '@app/core/interface/manufactories.interface';
import { infoDialogHeight, infoDialogWidth } from '@app/core/constants';

@Component({
  selector: 'app-manage-supplier',
  templateUrl: './manage-supplier.component.html',
  styleUrls: ['./manage-supplier.component.scss'],
})
export class ManageSupplierComponent {
  selectedSupplier?: ISupplier;
  selectedIndex = 0;
  @ViewChild('supplierDetails') supplierDetails!: SupplierDetailsComponent;
  @ViewChild('supplierSites') supplierSites!: SupplierSitesComponent;
  @ViewChild('supplierComponents') supplierComponents!: SupplierComponentsComponent;
  @ViewChild('dialogContent', { static: false }) dialogContent!: ElementRef;
  isUpdateSite = false;
  sitesFormValidation = false;
  detailsFormValidation = false;
  isUpdateComponent = false;
  componentsFormValidation = false;
  companyIngredient!: string;
  isUpdateDetails = false;
  show_buttons!: boolean;
  isShowNote = true;
  action!: string;
  component_btn!: boolean;
  componentCreated = false;
  addComponent = false;
  componentView = false;
  siteForm = false;
  hideSupplierComponent = false;
  hideSupplierSIte = false;
  site!: IManufactory;
  createdSIte!: boolean;
  components!: Array<ISupplierIngredient>;
  hideAddSite = false;
  componentDeleted = false;
  editSupplierHeader = false;
  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<ManageSupplierComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      suppliers?: ISupplier[];
      supplier?: ISupplier;
      show_buttons: boolean;
      component_btn: boolean;
      editSupplierHeader: boolean;
    },
    private cdr: ChangeDetectorRef,
    private manufactoriesService: ManufactoriesService,
    private ingredientService: IngredientService,
    private supplierService: SuppliersService
  ) {
    if (this.data?.supplier) {
      this.selectedSupplier = this.data.supplier;
    }
    if (data) {
      this.show_buttons = this.data.show_buttons;
      this.component_btn = this.data.component_btn;
    }
    if (data.editSupplierHeader) {
      this.editSupplierHeader = data.editSupplierHeader;
    }
    dialogRef.disableClose = true;
  }

  creationFinished(): void {
    !this.selectedSupplier
      ? this.dialogRef.close()
      : this.supplierDetails.form.pristine
        ? this.dialogRef.close(true)
        : this.unsavedChangesDialog();
  }

  supplierUpdated(data: ISupplier): void {
    const updatedSupplier = this.selectedSupplier;
    if (updatedSupplier) {
      updatedSupplier.action = data.action;
      updatedSupplier.name = data.name;
      updatedSupplier.logo = data.logo;
      updatedSupplier.description = data.description;
    }
    this.selectedSupplier = updatedSupplier;
    this.isShowNote = true;
  }

  unsavedChangesDialog(): void {
    const dialogRef = this.dialog.open(InfoDialogComponent, {
      width: infoDialogWidth,
      height: infoDialogHeight,
      data: {
        title: 'Manage Suppliers',
        infoText: 'Please note that you have unsaved changes, if you continue now all the changes will be lost!',
        confirmationText: 'Do you wish to continue?',
        btnText: 'Yes, continue',
        type: 'warning',
        text: 'warning-text',
      },
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.dialogRef.close(true);
      }
    });
  }
  updateOrCreateSupplier(): void {
    if (this.selectedSupplier?.uuid) {
      this.supplierDetails.updateSupplier();
    } else {
      this.supplierDetails.createSupplier();
    }
  }

  cancel(): void {
    this.dialogRef.close();
  }
  ngAfterContentChecked(): void {
    this.cdr.detectChanges();
  }

  scrollToTop() {
    if (this.dialogContent && this.dialogContent.nativeElement) {
      this.dialogContent.nativeElement.scrollTop = 0;
    }
  }

  /// Details
  isDetailsFormValid(event: boolean) {
    this.detailsFormValidation = event;
  }
  DetailsAction(action: string) {
    this.supplierService.supplierDetailsActions.next(action);
  }

  isDetailsUpdate(event: boolean) {
    this.isUpdateDetails = event;
  }

  supplierView(): void {
    this.scrollToTop();
    this.hideSupplierComponent =
      this.hideSupplierSIte =
      this.componentView =
      this.addComponent =
      this.isUpdateSite =
      this.siteForm =
      this.hideAddSite =
      this.componentDeleted =
      this.isUpdateComponent =
        false;
  }

  /// Sites
  isSitesUpdate(event: boolean) {
    this.isUpdateSite = event;
  }

  isSitesFormValid(event: boolean) {
    this.sitesFormValidation = event;
  }

  sitesAction(action: string) {
    this.manufactoriesService.supplierSiteActions.next(action);
  }

  siteFormView(data: boolean): void {
    this.scrollToTop();
    this.siteForm = this.hideSupplierComponent = data;
    this.isShowNote = this.addComponent = this.componentView = this.isUpdateSite = false;
  }

  siteCreated(data: boolean): void {
    this.createdSIte = data;
    this.hideSupplierComponent = this.siteForm = !data;
  }

  setIngredients(data: ISupplierIngredient[]): void {
    this.components = data;
  }

  /// Components
  isComponentUpdate(event: boolean) {
    this.scrollToTop();
    this.isUpdateComponent = event;
  }

  isComponentFormValid(event: boolean) {
    this.componentsFormValidation = event;
  }

  componentsAction(action: string) {
    this.ingredientService.supplierComponentActions.next(action);
  }

  componentCretaed(data: boolean): void {
    this.hideSupplierComponent = this.hideSupplierSIte = this.addComponent = !data;
    this.componentCreated = true;
  }

  createComponent(data: boolean): void {
    this.scrollToTop();
    this.addComponent = this.hideSupplierComponent = this.hideSupplierSIte = data;
    this.componentView = !data;
    this.isShowNote = false;
  }

  viewComponent(data: boolean): void {
    this.scrollToTop();
    this.componentView = this.hideSupplierSIte = this.hideSupplierComponent = data;
    this.addComponent = !data;
    this.isShowNote = false;
  }

  getSite(data: IManufactory): void {
    this.site = data;
  }

  closeComponent(data: boolean): void {
    this.addComponent = this.hideSupplierSIte = this.isShowNote = this.hideSupplierComponent = !data;
    this.isUpdateComponent = false;
  }

  deletedComponent(data: boolean): void {
    this.componentDeleted = data;
  }
}
