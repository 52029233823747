<div style="height: 100%">
  <div class="row">
    <table mat-table matSort [dataSource]="dataSource" class="col-12 mat-table">
      <ng-container matColumnDef="name">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>
          {{ 'supplier.Supplier Name' | translate }}
        </th>
        <td mat-cell *matCellDef="let element" class="alerts-name" [matTooltip]="element.name">
          {{ element.name }}
        </td>
      </ng-container>

      <ng-container matColumnDef="message">
        <th mat-header-cell *matHeaderCellDef>
          {{ 'supplier.Supplier Message' | translate }}
        </th>
        <td mat-cell *matCellDef="let element" [matTooltip]="element.message" class="supplier_messaage">
          {{ element.message }}
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>
          {{ 'supplier.Actions' | translate }}
        </th>
        <td mat-cell *matCellDef="let element" class="alerts-action">
          <mat-icon [matTooltip]="'Approve ' + element.name">done</mat-icon>
          <mat-icon [matTooltip]="'Delete ' + element.name">delete</mat-icon>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    <mat-paginator [pageSizeOptions]="[7]" showFirstLastButtons aria-label="Select page of users"> </mat-paginator>
  </div>
</div>
