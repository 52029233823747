import { CertificatesFacade } from '@app/core/facade/certificates.facade';
import { CommonModule } from '@angular/common';
import { DashboardComponent } from './dashboard.component';
import { DashboardOverviewComponent } from './dashboard-overview/dashboard-overview.component';
import { DashboardRoutingModule } from './dashboard-routing.module';
import { FirstProductInfoDialogComponent } from './first-product-info-dialog/first-product-info-dialog.component';
import { NgModule } from '@angular/core';
import { ProductChainFacade } from '@app/core/facade/productchain.facade';
import { SharedModule } from '../../shared/shared.module';
import { SuppliersFacade } from '@app/core/facade/suppliers.facade';

@NgModule({
  declarations: [DashboardComponent, FirstProductInfoDialogComponent, DashboardOverviewComponent],
  providers: [ProductChainFacade, SuppliersFacade, CertificatesFacade],
  imports: [CommonModule, DashboardRoutingModule, SharedModule],
})
export class DashboardModule {}
