import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { Injectable } from '@angular/core';
import { IsCountry } from '@app/core/utils/country.validator';

@Injectable()
export class ChainOverviewGroup {
  editClassificationForm: UntypedFormGroup;
  createStepForm: UntypedFormGroup;
  createSupplierForm: UntypedFormGroup;
  createManufactoryForm: UntypedFormGroup;
  createGroupForm: UntypedFormGroup;

  constructor(private _fb: UntypedFormBuilder) {
    this.editClassificationForm = this._fb.group({
      name: ['', Validators.required],
      color: ['', Validators.required],
      position: [],
      product_chain: ['', Validators.required],
    });

    this.createStepForm = this._fb.group({
      name: ['', Validators.required],
      supplier: [''],
      description: [''],
      product_chain: ['', Validators.required],
      position_x: [0],
      position_y: [0],
      manufactories: [''],
      is_hidden: [false],
    });

    this.createSupplierForm = this._fb.group({
      name: [null, [Validators.required]],
      description: [null],
      logo: [],
      product_chain: ['', [Validators.required]],
    });

    this.createManufactoryForm = this._fb.group(
      {
        country: [''],
        supplier: [''],
        address: [''],
        city: [''],
        name: ['', Validators.required],
      },
      { validator: IsCountry() }
    );

    this.createGroupForm = this._fb.group({
      name: [null, [Validators.required]],
      type_step: ['', [Validators.required]],
      productchain_uuid: ['', [Validators.required]],
      // publication_step_items: ['', [Validators.required]],
    });
  }
}
