<div class="container-fluid">
  <div class="row container-inside">
    <div class="col-md-6 left p0">
      <!-- Illustration-design cpomponent witch can be used multiple times only with diferent input color -->
      <app-illustration-design color="primary"></app-illustration-design>
    </div>
    <div class="col-md-6 right">
      <div class="content">
        <div class="box mb-0">
          <div class="text-box mb-0">
            <h2 class="mb-0">
              {{ 'Welcome to' | translate }} <br class="brake" />
              {{ 'Respect-SaaS' | translate }}
            </h2>
            <h5 class="mb-0" *ngIf="!environment.qa && !environment.production">
              {{ 'In order to proceed to the platform, you need to sign up for an account.' | translate }}
            </h5>
          </div>
          <div class="button-holder">
            <!-- use primary, secondary or tetriary-link like input color for diferent type of button -->
            <app-rounded-button
              *ngIf="!environment.qa && !environment.production"
              color="primary"
              [button_text]="'Sign Up'"
              [icon_category]="'shop'"
              [icon_name]="'shopping-cart'"
              [icon_right]="true"
              (click)="goToPurchase()"
            ></app-rounded-button>
          </div>
          <div class="divider"></div>
          <div class="login-btn d-flex flex-column">
            <p class="m-0">{{ 'Already have an account?' }}</p>
            <app-rounded-button
              color="secondary"
              [button_text]="'Log in'"
              [icon_category]="'arrows'"
              [icon_name]="'login-2'"
              [icon_right]="true"
              [routerLink]="'/login'"
            ></app-rounded-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
