import { AmwayCreateGroupComponent } from './amway/amway-create-group/amway-create-group.component';
import { AmwayCreateStepComponent } from './amway/amway-create-step/amway-create-step.component';
import { AmwayEditClassificationComponent } from './amway/amway-edit-classification/amway-edit-classification.component';
import { AmwayFinishedPublicationComponent } from './amway/amway-finished-publication/amway-finished-publication.component';
import { AmwayPublicationPageComponent } from './amway/amway-publication-page/amway-publication-page.component';
import { AmwayStepDetailsComponent } from './amway/amway-create-step/amway-step-details/amway-step-details.component';
import { AmwayStepItemComponent } from '@module/publication/custom-publications/amway/amway-step-item/amway-step-item.component';
import { AmwaySupplierDetailsComponent } from './amway/amway-create-step/amway-supplier-details/amway-supplier-details.component';
import { ColorPickerModule } from 'ngx-color-picker';
import { CommonModule } from '@angular/common';
import { CustomPublicationsFacade } from '@module/publication/custom-publications/custom-publications.facade';
import { NgModule } from '@angular/core';
import { NgxMapboxGLModule } from 'ngx-mapbox-gl';
import { SharedModule } from '@app/shared/shared.module';

@NgModule({
  declarations: [
    AmwayStepItemComponent,
    AmwayPublicationPageComponent,
    AmwayCreateStepComponent,
    AmwayCreateGroupComponent,
    AmwayEditClassificationComponent,
    AmwayStepDetailsComponent,
    AmwaySupplierDetailsComponent,
    AmwayFinishedPublicationComponent,
  ],
  imports: [CommonModule, SharedModule, NgxMapboxGLModule, ColorPickerModule],
  providers: [CustomPublicationsFacade],
})
export class CustomPublicationsModule {}
