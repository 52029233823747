import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CountryList } from '@app/core/utils/country-list';
import {
  ICustomPublicationStep,
  ICustomPublicationStepSupplierManufactory,
} from '@app/module/publication/custom-publications/custom-publications.models';
import { IProductChainStep } from '@app/core/interface/steps.interface';
import { IManufactory } from '@app/core/interface/manufactories.interface';

@Component({
  selector: 'app-step-item',
  templateUrl: './step-item.component.html',
  styleUrls: ['./step-item.component.scss'],
})
export class StepItemComponent {
  @Input() step!: ICustomPublicationStep | IProductChainStep;
  @Input() color!: string;
  @Input() mode = 'edit';
  @Output() editStepClick = new EventEmitter<ICustomPublicationStep | IProductChainStep>();
  @Output() hideStepClick = new EventEmitter<ICustomPublicationStep | IProductChainStep>();
  getCountryName = CountryList.getCountryName;

  editStep(step: ICustomPublicationStep | IProductChainStep): void {
    this.editStepClick.emit(step);
  }

  hideStep(step: ICustomPublicationStep | IProductChainStep): void {
    this.hideStepClick.emit(step);
  }

  isArray(
    manufactories: ICustomPublicationStepSupplierManufactory[] | IManufactory
  ): manufactories is ICustomPublicationStepSupplierManufactory[] {
    return Array.isArray(manufactories);
  }

  getCountryDisplay(manufactories: ICustomPublicationStepSupplierManufactory[] | IManufactory): string {
    if (this.isArray(manufactories)) {
      return manufactories.map(m => this.getCountryName(m.country) || 'Country not specified').join(', ');
    } else {
      return manufactories?.country
        ? this.getCountryName(manufactories.country) || 'Country not specified'
        : 'Country not specified';
    }
  }
}
