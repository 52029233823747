<div style="padding-bottom: 24px">
  <div
    style="width: 100%; {{
      sectionExpanded ? 'height: auto' : 'height : 90px'
    }}; border: solid 2px black; border-radius: 10px"
  >
    <div class="row">
      <div class="col-1">
        <div class="edit-icon">
          <ng-container *ngIf="!sectionExpanded">
            <app-custom-icon
              matTooltip="{{ 'Expand Section' | translate }}"
              matTooltipClass="custom-tooltip above"
              matTooltipPosition="above"
              [icon_category]="'arrows'"
              [icon_name]="'arrow-square-down'"
              (click)="expandSection()"
            >
            </app-custom-icon>
          </ng-container>
          <ng-container *ngIf="sectionExpanded">
            <app-custom-icon
              matTooltip="{{ 'Shrink Section' | translate }}"
              matTooltipClass="custom-tooltip above"
              matTooltipPosition="above"
              [icon_category]="'arrows'"
              [icon_name]="'arrow-square-up'"
              (click)="expandSection()"
            >
            </app-custom-icon>
          </ng-container>
        </div>
      </div>
      <div class="col-2">
        <div class="row">
          <div class="col-10">
            <p class="section-style">
              {{ section.name }}
              <br />
              <span class="section-style-question"> {{ section.questions.length }} {{ 'Questions' | translate }}</span>
            </p>
          </div>
          <div class="col-1">
            <div class="edit-name-icon">
              <app-custom-icon
                matTooltip="{{ 'Edit Name' | translate }}"
                matTooltipClass="custom-tooltip above"
                matTooltipPosition="above"
                [icon_category]="'files'"
                [icon_name]="'edit-2'"
                (click)="editName(section.name)"
              >
              </app-custom-icon>
            </div>
          </div>
        </div>
      </div>
      <div class="col-6">
        <div>
          <app-section-progress-bar
            [questionNumber]="section.questions.length"
            [submittedQuestions]="+section.submitted_answers"
          ></app-section-progress-bar>
        </div>
      </div>

      <div class="col-3">
        <div style="padding-top: 30px; text-align: end; align-items: center">
          <app-rounded-button
            color="secondary"
            [button_text]="'Assign' | translate"
            [size]="'md'"
            [icon_right]="true"
            icon_category="user"
            icon_name="user-add"
          >
          </app-rounded-button>
        </div>
      </div>
    </div>

    <div class="row" *ngIf="sectionExpanded">
      <div style="padding-left: 1%; padding-top: 15px">
        <div class="horizontal-line mb-5" style="width: 99%"></div>
      </div>
      <ng-container *ngFor="let question of section.questions; let i = index">
        <app-questionnaire-question
          [question]="question"
          [numOfQuestion]="i + 1"
          [isCreate]="isCreate"
          [questionnaireUid]="questionnaireUuid"
          [section]="section"
          (questionDeleted)="questionDeleted($event)"
        ></app-questionnaire-question>
      </ng-container>
      <p *ngIf="isCreate" (click)="createQuestion()">
        <app-custom-icon icon_category="essential" icon_name="add"></app-custom-icon>
        {{ 'Add New Question' | translate }}
      </p>
    </div>
  </div>
</div>
