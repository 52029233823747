import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-section-progress-bar',
  templateUrl: './section-progress-bar.component.html',
  styleUrls: ['./section-progress-bar.component.scss'],
})
export class SectionProgressBarComponent implements OnInit {
  @Input() questionNumber!: number;
  @Input() submittedQuestions!: number;

  numbers!: number[];
  submittedNumbers!: number[];

  ngOnInit(): void {
    this.numbers =
      this.questionNumber > 0
        ? Array(this.questionNumber - 1)
            .fill(1)
            .map((x, i) => i)
        : [];
    this.submittedNumbers =
      this.questionNumber > 0
        ? Array(this.submittedQuestions)
            .fill(1)
            .map((x, i) => i)
        : [];
  }

  shouldBeColored(index: number): boolean {
    return this.submittedNumbers.find(i => i === index) != null;
  }
}
