<div class="wraper">
  <div class="header-section">
    <div class="breadcrump">
      <app-custom-icon
        mat-dialog-close
        [icon_category]="'arrows'"
        [icon_name]="'arrow-circle-left-short'"
      ></app-custom-icon>
      <h4 mat-dialog-close>{{ 'Review ' }}{{ data.step?.name }}</h4>
      <app-custom-icon [icon_category]="'arrows'" [icon_name]="'arrow-right-2'"></app-custom-icon>
      <h3>{{ 'Certificates & Documents' }}</h3>
      <div class="close">
        <app-custom-icon [icon_category]="'essential'" [icon_name]="'close-circle'" mat-dialog-close></app-custom-icon>
      </div>
    </div>
    <div class="horizontal-line"></div>
  </div>
  <mat-tab-group #tabs>
    <mat-tab>
      <ng-template mat-tab-label>
        <app-custom-icon [icon_category]="'learning'" [icon_name]="'note-2'"></app-custom-icon>
        {{ 'Documents & Media' | translate }}
      </ng-template>
      <div class="content-section">
        <app-documents-tab
          [suppliers]="data.suppliers"
          [stepLevelView]="true"
          [selectedStep]="data.step"
        ></app-documents-tab>
      </div>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <app-custom-icon [icon_category]="'learning'" [icon_name]="'certificate'"></app-custom-icon>
        {{ 'Certificates' | translate }}
      </ng-template>
      <div class="content-section">
        <app-certificates-tab
          [suppliers]="data.suppliers"
          [product_chain]="data.product_chain"
          [selectedStep]="data.step"
        ></app-certificates-tab>
      </div>
    </mat-tab>
  </mat-tab-group>
  <!-- <div class="footer-actions">
  <mat-dialog-actions align="end" class="footer-section">
    <app-rounded-button
      mat-dialog-close
      color="tetriary-link"
      [size]="'md'"
      [button_text]="'Cancel'">
    </app-rounded-button>
    <app-rounded-button
      color="primary"
      [button_text]="'Save changes'"
      [size]="'md'" >
    </app-rounded-button>
  </mat-dialog-actions>
</div> -->
</div>
