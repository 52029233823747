<div
  class="step-wraper"
  [ngClass]="{
    'hidden-step': step.is_hidden,
  }"
>
  <div class="d-flex align-items-center">
    <app-custom-icon
      class="move-icon"
      [icon_category]="'essential'"
      [icon_name]="'move'"
      *ngIf="mode === 'edit'"
    ></app-custom-icon>
    <div class="step-info">
      <p class="step-name">{{ step.name }}</p>
      <div class="address-wraper" *ngIf="step.product_chain !== ''">
        <app-custom-icon
          [style]="'fill:' + (color ? color : 'gray')"
          [icon_category]="'location'"
          [icon_name]="'exclude'"
        ></app-custom-icon>

        <div>
          <p class="address">
            {{ step.supplier?.name }} -
            {{ getCountryDisplay(step.manufactories) }}
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="actions" *ngIf="mode === 'edit'">
    <app-custom-icon
      *ngIf="!step.is_hidden && step.product_chain !== ''"
      class="edit"
      [icon_category]="'files'"
      [icon_name]="'edit'"
      (click)="editStep(step)"
    ></app-custom-icon>
    <div class="hide" (click)="hideStep(step)">
      <app-custom-icon
        *ngIf="step.product_chain !== ''"
        [icon_category]="'security'"
        [icon_name]="step.is_hidden ? 'eye' : 'eye-slash'"
      ></app-custom-icon>
    </div>
  </div>
</div>
