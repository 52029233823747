import { environment } from '@env/environment';
import { Component, OnInit } from '@angular/core';
import { Settings } from '@interface/action-list.interface';
import { ActionListHelper } from '@app/core/utils/action-list-helper';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent implements OnInit {
  settingItems: Settings[] = ActionListHelper.settingsList;
  environment = environment;

  ngOnInit(): void {
    this.settingItems.forEach(x => {
      x.items = x.items.sort(x => x.id);
    });
    this.settingItems.sort((n1, n2) => n1.id - n2.id);
  }
}
