import { Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

@Injectable()
export class QuestionnairesManagementGroup {
  createQuestion: UntypedFormGroup;
  editName: UntypedFormGroup;

  constructor(private _fb: UntypedFormBuilder) {
    this.createQuestion = this._fb.group({
      name: ['', Validators.required],
    });

    this.editName = this._fb.group({
      name: ['', Validators.required],
    });
  }
}
