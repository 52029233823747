import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { Injectable } from '@angular/core';
import { MustMatch } from '@app/core/utils/must-match.validator';

@Injectable()
export class NewPasswordGroup {
  newPasswordForm: UntypedFormGroup;

  constructor(private _fb: UntypedFormBuilder) {
    this.newPasswordForm = this._fb.group(
      {
        new_password1: [null, [Validators.required, Validators.minLength(8)]],
        new_password2: [
          null,
          [
            Validators.required,
            // Validators.minLength(8)
          ],
        ],
        uid: [null, [Validators.required]],
        token: [null, [Validators.required]],
        is_consent_apporved: [false, [Validators.requiredTrue]],
      },
      { validator: MustMatch('new_password1', 'new_password2') }
    );
  }
}
