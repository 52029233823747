import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Chart } from 'chart.js';
import { Subject, Subscription } from 'rxjs';

@Component({
  selector: 'app-doughnut-step-overview',
  templateUrl: './doughnut-step-overview.component.html',
  styleUrls: ['./doughnut-step-overview.component.scss'],
})
export class DoughnutStepOverviewComponent implements OnInit, OnDestroy {
  public chart!: Chart<'doughnut', number[], string> | undefined;

  toUpdate = 'To Update';
  updated = 'Updated';
  insideText = 'Progress %';

  @Input() allUpdatedChainStepsPercentage = 0;
  @Input() allToUpdateChainStepsPercentage = 0;
  @Input() updateSteps$!: Subject<void>;

  private _langChangeSubscription: Subscription;

  constructor(public translate: TranslateService) {
    this._langChangeSubscription = this.translate.onLangChange.subscribe(() => {
      this._createChartWithLanguage();
    });
  }

  ngOnInit(): void {
    this.updateSteps$.subscribe(() => {
      setTimeout(() => {
        // this.chart?.update();
        this.chart?.destroy();
        this._createChart();
      }, 500);
    });
    setTimeout(() => {
      this._createChartWithLanguage();
      Chart.register({
        id: 'textInsideDoughnut',
        afterDraw: chart => {
          const ctx = chart.ctx;
          ctx.save();
          ctx.textAlign = 'center';
          ctx.textBaseline = 'middle';
          ctx.font = '14px Arial';
          ctx.fillText(
            this.insideText,
            (chart.chartArea.left + chart.chartArea.right) / 2,
            (chart.chartArea.top + chart.chartArea.bottom) / 2
          );
          ctx.restore();
        },
      });
    }, 2000);
  }

  ngOnDestroy(): void {
    this.updateSteps$.unsubscribe();
    this.chart?.destroy();
    this._langChangeSubscription.unsubscribe();
  }

  ///////////////////////////////////// PRIVATE METHODS //////////////////////////////////////////

  private _createChartWithLanguage(): void {
    this.toUpdate = this.translate.instant('supplier-dashboard.To Update');
    this.updated = this.translate.instant('supplier-dashboard.Updated');
    this.chart?.destroy();
    this._createChart();
  }

  private _createChart() {
    this.chart = new Chart('StepsChart', {
      type: 'doughnut',
      data: {
        labels: [this.toUpdate, this.updated],
        datasets: [
          {
            data: [this.allToUpdateChainStepsPercentage, this.allUpdatedChainStepsPercentage],
            backgroundColor: ['rgba(226, 6, 19, 1)', 'rgba(59, 94, 214, 1)'],
            hoverOffset: 4,
          },
        ],
      },
      options: {
        cutout: 45,
        plugins: {
          tooltip: {
            enabled: true,
            callbacks: {
              label: function (context: { label: string; parsed: number | null }) {
                let label = context.label || '';
                if (label) {
                  label += ': ';
                }
                if (context.parsed !== null) {
                  context.parsed = Math.round(context.parsed);
                  label += context.parsed + '%';
                }
                return label;
              },
            },
          },

          legend: {
            display: true,
            position: 'right',

            labels: {
              usePointStyle: true,
              padding: 20,
              textAlign: 'left',
              font: {
                size: 14,
                weight: '600',
                // lineHeight: 21,
              },
            },
          },
        },
        aspectRatio: 2.5,
        radius: '90%',
      },
    });
  }
}
