import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { NotificationType } from '@app/core/constants';
import { ProductsFacade } from '@app/core/facade/products.facade';
import { ICertificationObjectSelected } from '@app/core/interface/certificates.interface';
import { IProduct, IProductsListResponse } from '@app/core/interface/products.interface';
import { SnackbarService } from '@app/core/service/snackbar.service';
import { applyFilter } from '@app/core/utils/apply-filter';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-certification-products-list',
  templateUrl: './certification-products-list.component.html',
  styleUrls: ['./certification-products-list.component.scss'],
})
export class CertificationProductsListComponent implements OnInit {
  @Output() objectSelected = new EventEmitter<ICertificationObjectSelected>();
  productsList: IProduct[] = [];
  productsDataSource = new MatTableDataSource<IProduct>();
  productsObservable$!: Observable<IProduct[]>;
  selectedObject: ICertificationObjectSelected | null = null;

  constructor(
    private _productsFacade: ProductsFacade,
    private _snackbarService: SnackbarService
  ) {}

  ngOnInit(): void {
    this._productsFacade.getProducts$().subscribe({
      next: this._getProductsSuccess.bind(this),
      error: this._error.bind(this),
    });
  }

  // Search product filter
  applyFilter(event: Event): void {
    applyFilter(event, this.productsDataSource, this.productsDataSource);
  }

  onSelectObject(object: IProduct) {
    this.selectedObject = {
      name: object.name,
      uuid: object.uuid,
      image: object.image,
    };

    this.objectSelected.emit(this.selectedObject);
  }

  private _getProductsSuccess(data: IProductsListResponse): void {
    if (data && data.results.length) {
      this.productsList = data.results;
      this.productsDataSource.data = data.results;
      this.productsObservable$ = this.productsDataSource.connect();
    }
  }

  private _error(error: Record<string, string>): void {
    Object.values(error).map(err => {
      this._snackbarService.openTypeSnackbar(err, NotificationType.error);
    });
  }
}
