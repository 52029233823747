<mat-card class="wrap">
  <div class="row">
    <div class="col-1">
      <app-custom-icon
        id="arrow-down"
        [icon_category]="'arrows'"
        [icon_name]="expand ? 'arrow-square-up' : 'arrow-square-down'"
        (click)="expand = !expand"
      ></app-custom-icon>
    </div>
    <div class="col-3 menu-wrap">
      <div class="head-key">
        {{ 'Packaging name:' | translate }}
      </div>
      <div class="head-val">
        {{ displayPac.name }}
      </div>
    </div>
    <div class="col-3">
      <!-- <mat-select formControlName="type" panelClass="dropdown-primary dropdown-select-type"
            placeholder="{{ 'Choose Type' | translate }}" (selectionChange)="onFieldTypeChange($event)"> -->

      <div class="head-key">
        {{ 'Packaging Type:' | translate }}
      </div>
      <div class="head-val">
        {{ displayPac.packageType }}
      </div>
    </div>
    <div class="col-4 progress-bar">
      <p class="m-0 sec-com">{{ '3 sections required / 3 completed' | translate }}</p>
      <mat-progress-bar
        mode="determinate"
        [value]="100"
        class="custom-progress-bar light-progress-bar"
      ></mat-progress-bar>
      <img class="indicator-1-progress-bar" [src]="indicatorImg" alt="" />
      <img class="indicator-2-progress-bar" [src]="indicatorImg" alt="" />
    </div>
    <div class="col-1">
      <div class="action">
        <app-custom-icon
          id="edit"
          [icon_category]="'files'"
          [icon_name]="'edit'"
          matTooltip="{{ 'Edit Packaging' | translate }}"
          matTooltipClass="custom-tooltip-center"
          matTooltipPosition="above"
          (click)="editPac()"
        >
        </app-custom-icon>
        <app-custom-icon
          matTooltip="{{ 'Delete Packaging' | translate }}"
          matTooltipClass="custom-tooltip-end"
          matTooltipPosition="above"
          class="delete-icon cursor-pointer"
          icon_category="essential"
          icon_name="trash"
          (click)="onDeletePackaging()"
        ></app-custom-icon>
      </div>
    </div>
    <div class="cont" [class.hide]="!expand" [class.show]="expand">
      <div class="cont"></div>
      <div class="step-cont row">
        <div class="recyclability row" *ngIf="displayPac.is_recyclable || displayPac.is_recyclable_display">
          <div class="col-1 pack-ic">
            <app-custom-icon [icon_category]="'essential'" [icon_name]="'recycle'"></app-custom-icon>
          </div>
          <div class="pack-step-tit col-11">
            {{ 'Recyclability of the packaging' | translate }}
          </div>
          <!-- <div class="pack-step-cont"> -->
          <div class="col-12 ic-val-wrap">
            <app-custom-icon [icon_category]="'essential'" [icon_name]="'tick-circle'"></app-custom-icon>
            <div class="val">
              {{ displayPac.packageRecyclability | translate }}
            </div>
          </div>
          <!-- </div> -->
        </div>
        <div class="recycled row" *ngIf="displayPac.is_recycled_materials || displayPac.is_recycled_materials_display">
          <div class="col-1 pack-ic">
            <app-custom-icon [icon_category]="'essential'" [icon_name]="'recycled'"></app-custom-icon>
          </div>
          <div class="pack-step-tit col-11">
            {{ 'Packaging - Recycled Materials' | translate }}
          </div>
          <div class="col-12 ic-val-wrap">
            <app-custom-icon [icon_category]="'essential'" [icon_name]="'tick-circle'"></app-custom-icon>
            <div class="val">
              {{
                (displayPac.is_recycled_materials
                  ? 'There are recycled materials in the packaging'
                  : ' Packaging does not contain any recycled materials '
                ) | translate
              }}
            </div>
          </div>
        </div>
        <div class="hazardous row">
          <div class="col-1 pack-ic">
            <app-custom-icon [icon_category]="'essential'" [icon_name]="'poison'"></app-custom-icon>
          </div>
          <div class="pack-step-tit col-11">
            {{ 'Packaging - Hazardous Substances' | translate }}
          </div>
          <div class="col-12 ic-val-wrap">
            <app-custom-icon [icon_category]="'essential'" [icon_name]="'tick-circle'"></app-custom-icon>
            <div class="val">
              {{
                (displayPac.hazardous_substances?.length
                  ? ' The packaging contains substance(s) of very high concern '
                  : '
              The packaging does not contain any substance(s) of very high concern'
                ) | translate
              }}
            </div>
          </div>
          <ul *ngIf="displayPac.hazardous_substances?.length">
            <li class="substance" *ngFor="let substance of displayPac.hazardous_substances">
              {{ substance }}
            </li>
          </ul>
          <!--    <hr class="shortt" *ngIf="packaging?.packaging?.length && displaySheet.displayPac.length - 1 !== i" /> -->
        </div>
      </div>
    </div>
  </div>
</mat-card>
