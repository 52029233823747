<div class="holder d-flex flex-column">
  <div class="main-holder">
    <div class="card" *ngFor="let certificate of certificatesObservable$ | async">
      <div class="thumbnail" (click)="openViewFile(certificate)">
        <div class="icon">
          <app-custom-icon [icon_category]="'learning'" [icon_name]="'certificate-white'"> </app-custom-icon>
        </div>
        <div class="title-holder">
          <p class="type">{{ 'Certificate type' | translate }}</p>
          <p class="cert-name">
            {{ certificate.certificate.name !== 'null' ? certificate.certificate.name : 'Not set' }}
          </p>
        </div>
      </div>

      <div class="card-body">
        <div class="info-holder">
          <div class="category">
            <p class="category-label">{{ 'area:' | translate }}</p>
            <p class="category-name">{{ certificate.certificate.domain?.name }}</p>
          </div>
          <div class="certificator">
            <p class="certificator-label">{{ 'certificator:' | translate }}</p>
            <p class="certificator-name">
              {{ certificate.certificator !== 'null' ? certificate.certificator : 'Not set' }}
            </p>
          </div>
        </div>
        <div class="date-holder">
          <div class="start-date-holder">
            <app-custom-icon [icon_category]="'time'" [icon_name]="'calendar-blue'"> </app-custom-icon>
            <p class="start-date">
              {{ 'Start Date' }}
              <span>{{
                (certificate.validity_start | date: 'dd.MM.YY') !== 'null'
                  ? (certificate.validity_start | date: 'dd.MM.YY')
                  : ''
              }}</span>
            </p>
          </div>

          <div class="expires">
            <div class="expires-date-holder" *ngIf="!certificate.expired">
              <app-custom-icon [icon_category]="'essential'" [icon_name]="'danger-yellow'"> </app-custom-icon>
              <p class="expires-date">
                {{ 'Expires ' }}
                <span>{{
                  (certificate.validity_end | date: 'dd.MM.YY') !== 'null'
                    ? (certificate.validity_end | date: 'dd.MM.YY')
                    : ''
                }}</span>
              </p>
            </div>
            <div class="expires-date-holder" *ngIf="certificate.expired">
              <app-custom-icon id="danger" [icon_category]="'essential'" [icon_name]="'danger'"> </app-custom-icon>
              <p class="expired">{{ 'EXPIRED' | translate }}</p>
            </div>

            <div class="actions">
              <app-custom-icon
                class="cursor-pointer"
                matTooltip="{{ 'Edit Certificate' | translate }}"
                matTooltipClass="custom-tooltip-center centering"
                [matTooltipPosition]="'above'"
                [icon_category]="'files'"
                [icon_name]="'edit'"
                (click)="editCertification(certificate)"
              >
              </app-custom-icon>
              <app-custom-icon
                id="trash"
                class="cursor-pointer"
                matTooltip="{{ 'Delete Certificate' | translate }}"
                matTooltipClass="custom-tooltip-end"
                [matTooltipPosition]="'above'"
                [icon_category]="'essential'"
                [icon_name]="'trash'"
                (click)="deleteCertificate(certificate.uuid, certificate.certificate.name)"
              >
              </app-custom-icon>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="card-footer">
    <mat-paginator #paginator showFirstLastButtons aria-label="Select page of components"> </mat-paginator>
  </div>
</div>
