import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subject, filter, takeUntil } from 'rxjs';
import { Breadcrumb } from '@app/core/interface/header.interface';
import { BreadcrumbHeaderService } from '@app/core/service/breadcrumb-header.service';
import { ProductsService } from '@app/core/service/products.service';
import { SuppliersService } from '@app/core/service/suppliers.service';
import { TranslateService } from '@ngx-translate/core';
import { TranslationService } from '@app/core/service/translation.service';

@Component({
  selector: 'app-breadcrumb-header',
  templateUrl: './breadcrumb-header.component.html',
  styleUrls: ['./breadcrumb-header.component.scss'],
})
export class BreadcrumbHeaderComponent implements OnInit, OnDestroy {
  // Unsubscribe cleaner
  private _unsubscribe$: Subject<void> = new Subject();

  bindBreadCrumbs: Breadcrumb[] = [];

  constructor(
    private router: Router,
    private _productService: ProductsService,
    private _supplierService: SuppliersService,
    private _BreadcrumbHeaderService: BreadcrumbHeaderService,
    public translate: TranslateService,
    public translationService: TranslationService
  ) {
    this.onLangChange();
  }

  ngOnInit() {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        takeUntil(this._unsubscribe$)
      )
      .subscribe(() => {
        this.bindBreadCrumbs = this._BreadcrumbHeaderService.setBreadCrumbs();
      });
    this._BreadcrumbHeaderService.setHeaders$.pipe(takeUntil(this._unsubscribe$)).subscribe((list: Breadcrumb[]) => {
      this.bindBreadCrumbs = list;
    });

    this.bindBreadCrumbs = this._BreadcrumbHeaderService.setBreadCrumbs();

    this._productService.productDetailsName.pipe(takeUntil(this._unsubscribe$)).subscribe((data: Breadcrumb) => {
      if (!this.bindBreadCrumbs[2]) {
        this.bindBreadCrumbs.push({ label: '', url: '' });
      }

      if (data.url) {
        this.bindBreadCrumbs[2].label = data.label;
        this.bindBreadCrumbs[2].url += data.url;
      }
    });

    this._supplierService.supplierDetailsName.pipe(takeUntil(this._unsubscribe$)).subscribe((data: Breadcrumb) => {
      if (!this.bindBreadCrumbs[2]) {
        this.bindBreadCrumbs.push({ label: '', url: '' });
      }

      if (data.url) {
        this.bindBreadCrumbs[2].label = data.label;
        this.bindBreadCrumbs[2].url += data.url;
      }
    });
  }
  setBreadCrumbs() {
    this.bindBreadCrumbs = this._BreadcrumbHeaderService.setBreadCrumbs();
  }
  goBack() {
    const route = this._BreadcrumbHeaderService.goBack();
    if (route) {
      this.router.navigate([this._BreadcrumbHeaderService.goBack()]);
    }
  }

  ngOnDestroy(): void {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }

  onLangChange(): void {
    this.translate.onLangChange.subscribe(() => {
      this.setBreadCrumbs();
    });
  }
}
