import { Pipe, PipeTransform } from '@angular/core';
import { Router } from '@angular/router';

@Pipe({
  name: 'activeRoute',
  pure: false,
})
export class ActiveRoutePipe implements PipeTransform {
  constructor(private router: Router) {}

  transform(url: string): boolean {
    if (url === 'dashboard') {
      return this.router.url === '/dashboard';
    }
    if (url === '/overview' || url === '/company-info') {
      return this.router.url === url;
    }
    if (url === 'products') {
      return this.router.url.includes(url) || this.router.url.includes('product/');
    }
    if (url === '/dashboard/supplier/supplier-list')
      return this.router.url.includes('supplier-list') || this.router.url.includes('supplier');

    if (url === '/dashboard/product-chains') return this.router.url.includes('product-chains');

    if (url === '/dashboard/tasks') return this.router.url.includes('tasks') || this.router.url.includes('tasks/all');

    if (url === '/dashboard/certificates' || url === '/chain-steps-info') return this.router.url.includes(url);
    return false;
  }
}
