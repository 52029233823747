import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  IBillingAddressDetails,
  IBillingCardDetails,
  IBillingCustomerDetails,
  UpdateCustomerDetails,
} from '@app/core/interface/billing.interface';

@Component({
  selector: 'app-manage-details',
  templateUrl: './manage-details.component.html',
  styleUrls: ['./manage-details.component.scss'],
})
export class ManageDetailsComponent {
  customerDetails!: IBillingCustomerDetails;
  billingDetails!: IBillingAddressDetails;
  creditCardDetails!: Array<IBillingCardDetails>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: UpdateCustomerDetails,
    public dialogRef: MatDialogRef<ManageDetailsComponent>
  ) {
    this.customerDetails = data.customerDetails;
    this.billingDetails = data.customerDetails?.billing_address;
    this.creditCardDetails = data.creditCardDetails;
  }

  /**
   * Function to close dialog
   */
  closeDialog(close: boolean) {
    if (close) {
      this.dialogRef.close(true);
    }
  }
}
