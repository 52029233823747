import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ArchivedObjectsManagementComponent } from './archived-objects-management.component';
import { SharedModule } from '@app/shared/shared.module';
import { AchivedObjectsManagementRoutingModule } from './archived-objects-management-routing.module';
import { ManageProductsComponent } from './manage-products/manage-products.component';

@NgModule({
  declarations: [ArchivedObjectsManagementComponent, ManageProductsComponent],
  imports: [CommonModule, AchivedObjectsManagementRoutingModule, SharedModule],
})
export class ArchivedObjectsManagementModule {}
