<div class="holder">
  <div
    class="supplier-sites d-flex justify-content-between flex-column"
    *ngIf="supplierSites.length && !showAddSiteForm"
  >
    <div class="header-site d-flex justify-content-between align-items-center">
      <h6 class="m-0">{{ 'Supplier Sites' | translate }}</h6>
      <app-rounded-button
        color="secondary"
        [size]="'md'"
        [icon_right]="true"
        [icon_category]="'buildings'"
        [icon_name]="'factory'"
        [button_text]="'Add Supplier Site'"
        *ngIf="!showAddSiteForm"
        (click)="showAddForm()"
      >
      </app-rounded-button>
    </div>

    <div class="card-holder d-flex justify-content-between">
      <div class="site-card" [ngClass]="site.is_main_location ? 'is' : ''" *ngFor="let site of supplierSites">
        <div class="actions">
          <app-custom-icon
            [icon_category]="'files'"
            [icon_name]="'edit-2'"
            (click)="showUpdateForm(site)"
          ></app-custom-icon>
          <app-custom-icon
            [icon_category]="'essential'"
            [icon_name]="'trash'"
            (click)="deleteSupplierSite(site)"
          ></app-custom-icon>
        </div>
        <div class="icon">
          <app-custom-icon [icon_category]="'buildings'" [icon_name]="'factory'"></app-custom-icon>
        </div>
        <div class="site-info">
          <h5>{{ site.name }}</h5>
          <h6>
            {{ site.country }}
          </h6>
          <div class="is-main-location">
            <p *ngIf="site.is_main_location">{{ 'main supplier site' | translate }}</p>
            <mat-radio-group
              *ngIf="!site.is_main_location"
              [(ngModel)]="site.is_main_location"
              (change)="changeMainSite(site.uuid)"
              class="small-radio-button"
            >
              <mat-radio-button value="1">{{ 'make this site main' | translate }}</mat-radio-button>
            </mat-radio-group>
          </div>
          <div class="btn-holder d-flex justify-content-between align-items-center">
            <p class="m-0" *ngIf="!site.ingredients?.length">{{ 'No components added yet.' | translate }}</p>
            <app-rounded-button
              *ngIf="site.ingredients && site.ingredients.length"
              [size]="'md'"
              [icon_right]="true"
              [icon_category]="'security'"
              [icon_name]="'eye'"
              [color]="'tetriary-link'"
              [button_text]="'View Components'"
              (click)="viewComponents(site)"
            ></app-rounded-button>
            <app-rounded-button
              [size]="'md'"
              [icon_right]="true"
              [icon_category]="'essential'"
              [icon_name]="'box-2'"
              [color]="'tetriary-link'"
              [button_text]="'Add Components'"
              (click)="createComponent(site)"
            ></app-rounded-button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="no-sites d-flex flex-column" *ngIf="!supplierSites.length && !showAddSiteForm">
    <div class="header-sites d-flex justify-content-between align-items-center">
      <h6 class="m-0">{{ 'Supplier Sites' | translate }}</h6>
      <app-rounded-button
        color="secondary"
        [size]="'md'"
        [icon_right]="true"
        [icon_category]="'buildings'"
        [icon_name]="'factory'"
        [button_text]="'Add Supplier Site'"
        *ngIf="!showAddSiteForm"
        (click)="showAddForm()"
      >
      </app-rounded-button>
    </div>
    <div class="info d-flex flex-column">
      <h4 *ngIf="!supplierSites.length && !showAddSiteForm">{{ 'No supplier sites added yet' | translate }}</h4>
      <p *ngIf="!supplierSites.length && !showAddSiteForm">
        {{ 'You can add them now or add them later.' | translate }}
      </p>
    </div>
  </div>

  <div class="add-site" *ngIf="showAddSiteForm">
    <div class="add-site d-flex justify-content-between align-items-center" *ngIf="showAddSiteForm">
      <h6 class="m-0">{{ !isUpdateSite ? 'Adding Supplier Site' : 'Editing Supplier Site' }}</h6>
      <div class="btn-holder d-flex">
        <app-rounded-button
          [color]="'turquoise'"
          [button_text]="!isUpdateSite ? 'Add Site' : 'Update Site'"
          [size]="'md'"
          [icon_right]="true"
          [icon_category]="'buildings'"
          [icon_name]="'factory'"
          [disabled]="!form.valid || form.pristine"
          (click)="!isUpdateSite ? createSupplierSite() : updateSupplierSite()"
        >
        </app-rounded-button>
        <app-rounded-button
          (click)="showAddSiteForm = !showAddSiteForm; closeSiteForm()"
          [color]="'tetriary-link'"
          [size]="'sm'"
          [button_text]="'Cancel'"
          class="cancel"
        >
        </app-rounded-button>
      </div>
    </div>

    <form [formGroup]="form">
      <div class="row">
        <div class="col-6">
          <mat-form-field class="input-primary" appearance="outline">
            <mat-label>{{ 'Site Name' | translate }}</mat-label>
            <input matInput formControlName="name" placeholder="Site Name" required />
          </mat-form-field>
        </div>
        <div class="col-6 paragraph">
          <p>
            {{ 'Site name could be any facility of the supplier: headquarters, factory, farm, field etc.' | translate }}
          </p>
        </div>
      </div>

      <div class="row">
        <div class="col-6">
          <mat-form-field class="input-primary" appearance="outline">
            <mat-label>{{ 'Country' | translate }}</mat-label>
            <input type="text" matInput formControlName="country" [matAutocomplete]="auto" />
            <mat-autocomplete class="autocomplete-primary" #auto="matAutocomplete" [displayWith]="displayFn">
              <mat-option *ngFor="let country of filteredCountries | async" [value]="country">
                {{ country.name }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
        <div class="col-6">
          <mat-form-field class="input-primary" appearance="outline">
            <mat-label>{{ 'Region' | translate }}</mat-label>
            <input matInput formControlName="region" placeholder="Region" />
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-6">
          <mat-form-field class="input-primary" appearance="outline">
            <mat-label>{{ 'City' | translate }}</mat-label>
            <input matInput formControlName="city" placeholder="City" />
          </mat-form-field>
        </div>
        <div class="col-6">
          <mat-form-field class="input-primary" appearance="outline">
            <mat-label>{{ 'Address' | translate }}</mat-label>
            <input matInput formControlName="address" placeholder="Address" />
          </mat-form-field>
        </div>
        <div class="col-6">
          <div class="is-main-location">
            <mat-radio-group formControlName="is_main_location">
              <mat-radio-button
                (click)="!is_main_location ? setMainLocation() : ''"
                [value]="true"
                [checked]="is_main_location"
                color="primary"
                >{{ 'make this site main' | translate }}</mat-radio-button
              >
            </mat-radio-group>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
