<div class="create-certificate-wraper d-flex flex-column">
  <div class="content {{ showTabSelector ? 'content-padding2' : 'content-padding1' }}">
    <div *ngIf="selectedObject" class="object-of-certification">
      <h6><strong>Object of certification</strong></h6>
      <div class="object-thumb d-flex align-items-center">
        <img [src]="selectedObject.image" [alt]="selectedObject.name" />
        <p class="m-0">
          <strong>{{ selectedObject.name }}</strong>
        </p>
        <app-custom-icon class="icon-tick-circle" icon_category="essential" icon_name="tick-circle"></app-custom-icon>
      </div>
    </div>
    <form [formGroup]="form" [ngClass]="{ 'd-flex flex-wrap': entityForCertification === 'Supplier' }">
      <!-- Certificate inputs row -->
      <div
        class="certification"
        [ngStyle]="{ width: entityForCertification === 'Supplier' ? 'calc(50% - 22px)' : '100%' }"
      >
        <h6 class="section-title">
          <strong>{{ 'Certification Details' | translate }}</strong>
        </h6>
        <div class="inputs-holder">
          <div class="certificate-name-row d-flex">
            <mat-form-field class="dropdown-primary col mat-form-field-10-px-brd-rd-dd" appearance="outline">
              <!-- *ngIf="!(entityForCertification === 'Brand' || entityForCertification === 'Step')" -->
              <mat-label>{{ 'Search certificate type' | translate }}</mat-label>
              <input
                id="certificate"
                formControlName="certificate"
                [value]="form.controls['certificate'].value?.valid ? form.controls['certificate'].value?.name : ''"
                placeholder="{{
                  opened ? ('Choose Certificate Type' | translate) : ('Search certificate type' | translate)
                }}"
                panelClass="dropdown-primary"
                matInput
                [matAutocomplete]="auto"
                (input)="filterItem($event)"
                #inputFilter
                #trigger="matAutocompleteTrigger"
                matAutocompletePosition="below"
                required
              />
              <mat-autocomplete
                [displayWith]="displayFn"
                class="autocomplete-primary cert-list-autocomplete"
                #auto="matAutocomplete"
                (closed)="onClosedEvent()"
                (opened)="isOpened()"
              >
                <ng-container *ngIf="!certificates.length">
                  <mat-option disabled>
                    <p class="certifications-message">There are no certifications created yet</p>
                    <P class="certifications-instruction"
                      >In order to create a certificate, please visit certificate settings to manage & create
                      certificate types. <br />
                      Once you create new types, they will appear here.</P
                    >
                  </mat-option>
                </ng-container>
                <mat-option *ngFor="let certificate of certificates" [value]="certificate">
                  <div class="option-holder">
                    <p class="cert-name m-0">
                      <strong>{{ certificate.name }}</strong>
                    </p>
                    <div class="details d-flex">
                      <p class="category-name p2 m-0">{{ certificate.category.name }}</p>
                      <!-- <p class="category-name m-0">&nbsp;|&nbsp;</p> -->
                      <p class="domain-name p2 m-0">{{ certificate.domain?.name }}</p>
                    </div>
                  </div>
                </mat-option>
              </mat-autocomplete>

              <app-custom-icon
                class="cursor-pointer"
                (click)="openOrClosePanel($event, trigger)"
                matSuffix
                [icon_category]="'arrows'"
                [icon_name]="opened ? 'arrow-up-2' : 'arrow-down-2'"
              ></app-custom-icon>
            </mat-form-field>
            <mat-form-field class="input-primary col" appearance="outline">
              <mat-label>{{ 'Certificator' | translate }}</mat-label>
              <input matInput placeholder="{{ 'Certificator' | translate }}" formControlName="certificator" />
            </mat-form-field>
          </div>

          <div
            *ngIf="form.controls['certificate']?.value?.category?.name?.trim().toUpperCase() !== 'AUDIT'"
            class="no-audit-row d-flex"
          >
            <mat-form-field class="input-primary col" appearance="outline">
              <mat-label>{{ 'Start Date' | translate }}</mat-label>
              <input matInput [matDatepicker]="picker_start" formControlName="validity_start" />
              <mat-datepicker-toggle matSuffix [for]="picker_start">
                <app-custom-icon
                  class="date-icon"
                  icon_category="time"
                  icon_name="calendar-search"
                  matDatepickerToggleIcon
                ></app-custom-icon>
              </mat-datepicker-toggle>
              <mat-datepicker #picker_start></mat-datepicker>
            </mat-form-field>
            <mat-form-field class="input-primary col" appearance="outline">
              <mat-label>{{ 'End Date' | translate }}</mat-label>
              <input matInput [matDatepicker]="picker_end" formControlName="validity_end" [min]="minEnd" />
              <mat-datepicker-toggle matSuffix [for]="picker_end">
                <app-custom-icon
                  class="date-icon"
                  icon_category="time"
                  icon_name="calendar-search"
                  matDatepickerToggleIcon
                ></app-custom-icon>
              </mat-datepicker-toggle>
              <mat-datepicker #picker_end></mat-datepicker>
            </mat-form-field>
          </div>
        </div>
        <mat-checkbox
          class="checkbox-primary"
          *ngIf="entityForCertification === 'Supplier'"
          (change)="onCheckboxChange()"
        >
          {{ 'apply certificate to supplier sites' | translate }}
        </mat-checkbox>
      </div>

      <!-- Certificate file row -->
      <div
        class="uploader-holder"
        [ngStyle]="{ width: entityForCertification === 'Supplier' ? 'calc(50% - 22px)' : '100%' }"
      >
        <h6 class="section-title" *ngIf="mode === 'Create'">
          <strong>{{ 'Upload your certificate' | translate }}</strong>
        </h6>
        <h6 class="section-title" *ngIf="mode === 'Update'">
          <strong>{{ 'Certificate file' | translate }}</strong>
        </h6>

        <div
          class="upload-file-row d-flex flex-wrap align-items-center"
          *ngIf="!docURLTransformed"
          [ngStyle]="{ gap: entityForCertification === 'Supplier' ? '28px' : '16px' }"
        >
          <p class="info-text" [ngClass]="entityForCertification === 'Supplier' ? 'col-12' : 'col'">
            <strong>{{
              'No files
              uploaded yet.Please upload your document in the required format, and no more than 10MB.' | translate
            }}</strong>
          </p>
          <div
            class="d-flex align-items-center justify-content-between"
            [ngClass]="{
              'upload-file': !warn && !typeWarn && entityForCertification !== 'Supplier',
              'upload-file-red': (warn || typeWarn) && entityForCertification !== 'Supplier',
              'col-12': entityForCertification === 'Supplier',
              col: entityForCertification !== 'Supplier',
            }"
          >
            <div class="d-flex align-items-center">
              <div class="icon-circle d-flex justify-content-center align-items-center">
                <app-custom-icon
                  [ngClass]="warn || typeWarn ? 'file-icon-red' : 'file-icon'"
                  icon_category="files"
                  icon_name="document-text"
                ></app-custom-icon>
              </div>
              <p class="file-upload-note" *ngIf="!warn && !typeWarn">
                {{ 'pdf.doc.xml' | translate }} <br />
                {{ '/ maximum 10MB' | translate }}
              </p>
              <div *ngIf="warn || typeWarn" class="warn-upload-holder">
                <app-custom-icon [icon_category]="'essential'" [icon_name]="'danger-red'"></app-custom-icon>
                <div class="danger-icon" *ngIf="warn">
                  <p class="file-upload-note-red nr-h">{{ 'Your file is larger than 10mb!' | translate }}</p>
                  <p class="file-upload-note-red nr-p">{{ 'Please upload the correct size' | translate }}</p>
                </div>
                <div class="danger-icon" *ngIf="typeWarn">
                  <p class="file-upload-note-red nr-h">{{ 'Your document format is not supported!' | translate }}</p>
                  <p class="file-upload-note-red nr-p">
                    {{ 'Please choose document with one of the formats bellow.' | translate }}
                  </p>
                </div>
                <div class="d-flex">
                  <app-rounded-button
                    *ngIf="warn || typeWarn"
                    color="turquoise"
                    [button_text]="'Try again' | translate"
                    [icon_category]="'arrows'"
                    [icon_name]="'refresh-square'"
                    [size]="'sm'"
                    [icon_right]="true"
                    (click)="tryAgain()"
                    class="v-center"
                  >
                  </app-rounded-button>
                </div>
              </div>
            </div>

            <ng-container *ngIf="!warn && !typeWarn">
              <input
                type="file"
                accept=".doc,.docx,.pdf,.xml"
                class="file-upload d-none"
                #fileUpload
                (change)="onFileSelected($event)"
              />
              <app-rounded-button
                color="primary"
                [size]="'md'"
                [icon_right]="true"
                [icon_category]="'essential'"
                [icon_name]="'upload'"
                [button_text]="'Upload from PC'"
                #fileUpload
                (change)="onFileSelected($event)"
                (click)="fileUpload.click(); form.controls['document'].markAsDirty()"
              ></app-rounded-button>
            </ng-container>
          </div>

          <div class="formats-allowed offset-6" *ngIf="typeWarn">
            <div class="row">
              <div class="col-1">
                <app-custom-icon [icon_category]="'essential'" [icon_name]="'info-circle'"></app-custom-icon>
              </div>
              <div class="col-11">
                {{ 'Please choose a document in some of the following formats: ' | translate }}
                <div class="formats">pdf / doc / xml</div>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="docURLTransformed" class="certificate-uploaded d-flex flex-wrap align-items-center">
          <div class="box-text-holder" [ngClass]="entityForCertification === 'Supplier' ? 'col-12' : 'col'">
            <div class="box-icon-uploaded d-flex align-items-center" *ngIf="mode === 'Create'">
              <div class="icon-uploaded d-flex justify-content-center align-items-center">
                <app-custom-icon
                  class="tick-circle-icon"
                  [icon_category]="'essential'"
                  [icon_name]="'tick-circle'"
                ></app-custom-icon>
              </div>
              <p class="col">
                <strong>{{ 'Your Certificate was uploaded successfully!' | translate }}</strong>
              </p>
            </div>
            <div class="box-text" *ngIf="mode === 'Update'">
              <p>
                <strong
                  >This is your uploaded file. If this is not the correct file, you can remove the file by clicking on
                  the x button, and then upload a new one.</strong
                >
              </p>
            </div>
          </div>

          <div
            class="box-file-uploaded d-flex align-items-center"
            [ngClass]="entityForCertification === 'Supplier' ? 'col-12' : 'col'"
          >
            <div class="icon-circle d-flex justify-content-center align-items-center">
              <app-custom-icon
                class="file-icon-white"
                [icon_category]="'files'"
                [icon_name]="'document-text-white'"
              ></app-custom-icon>
            </div>
            <a [href]="docURLTransformed" target="_blank" bypassSecurityTrustUrl>
              <h6>
                <strong>{{ fileName }}</strong>
              </h6>
            </a>
            <app-custom-icon
              class="close-icon"
              (click)="docURLTransformed = !docURLTransformed"
              [icon_category]="'essential'"
              [icon_name]="'close-circle'"
            ></app-custom-icon>
          </div>
        </div>
      </div>

      <!-- Choose manufactories section for Supplier mode -->
      <div *ngIf="supplierSitesView" class="supplier-sites-holder col-12">
        <div class="horizontal-line"></div>
        <h6>{{ 'Choose on which supplier sites you want to apply this certificate' | translate }}</h6>
        <div class="card-holder">
          <div *ngFor="let manufactory of manufactories; let i = index" class="site-card">
            <div class="site-details">
              <div class="icon-holder">
                <app-custom-icon [icon_category]="'buildings'" [icon_name]="'factory-white'"></app-custom-icon>
              </div>
              <div class="details">
                <p class="name">{{ manufactory.name }}</p>
                <p class="address">
                  {{ manufactory.city ? manufactory.city + ',' : '' }}
                  {{ manufactory.address ? manufactory.address + ',' : '' }} {{ manufactory.country }}
                </p>
                <div *ngIf="manufactory.is_main_location" class="main-site">
                  <div class="dot"></div>
                  <p class="main-site-text">{{ 'main supplier site' | translate }}</p>
                </div>
              </div>
            </div>
            <div class="action">
              <div class="action-text">
                {{ manufactory.toggleStatus ? 'applied on this site' : ('switch on to apply' | translate) }}
              </div>
              <mat-slide-toggle
                [checked]="manufactory.toggleStatus"
                class="custom-slide-toggle"
                [color]="'primary'"
                (change)="onChange($event, i, manufactory.uuid)"
              >
              </mat-slide-toggle>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>

  <ng-container *ngIf="!showTabSelector || mode === 'Update'">
    <div class="form-buttons d-flex justify-content-end">
      <app-rounded-button class="discard-button" color="secondary" button_text="Discard" size="md" mat-dialog-close>
      </app-rounded-button>
      <app-rounded-button
        color="primary"
        [button_text]="mode + ' Certificate' | translate"
        size="md"
        [disabled]="form && (!form.valid || form.pristine)"
        (click)="submit()"
      >
      </app-rounded-button>
    </div>
  </ng-container>
</div>
