<div class="dialog-wrapper">
  <mat-dialog-content>
    <div class="sel-info" #selInfo>
      <div class="detail-txt">
        <img
          src="../../../../assets/images/symbol-blue.svg"
          alt="Symbol"
          height="60"
          width="60"
          class="align-self-start"
        />
        <span class="more-details">
          <div class="header-txt">
            {{
              '"Current step site" is copied to a new site in the matching supplier and step is updated accordingly.'
                | translate
            }}
          </div>
        </span>
      </div>
    </div>
  </mat-dialog-content>
  <div class="button">
    <app-rounded-button mat-dialog-close color="primary" [button_text]="'Ok, Got it'" size="sm"> </app-rounded-button>
  </div>
</div>
