import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IProductChainStep } from '@app/core/interface/steps.interface';

@Component({
  selector: 'app-step-details-dialog',
  templateUrl: './step-details-dialog.component.html',
  styleUrls: ['./step-details-dialog.component.scss'],
})
export class StepDetailsDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<StepDetailsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public step: IProductChainStep
  ) {}
}
