import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { ObservableInput } from 'rxjs/internal/types';
import { catchError } from 'rxjs/internal/operators/catchError';

import { take } from 'rxjs/internal/operators/take';
import { throwError } from 'rxjs/internal/observable/throwError';
import { CertificatesService } from '../service/certificates.service';
import { generateFormData } from '../utils/generate-formData';
import {
  ICertificate,
  ICertificateCategoriesResponse,
  ICertificateDomainResponse,
  ICertificateResponse,
  ICertificatesResponse,
  ICertificationResponse,
  ICertificatsContentType,
  IContentTypeURL,
  IDeleteCertificate,
  IDeleteCertification,
} from '../interface/certificates.interface';
import { ICertificationSupplier } from '../interface/suppliers.interface';

@Injectable()
export class CertificatesFacade {
  constructor(private _certificatesService: CertificatesService) {}

  getCertificates$(ordering?: string, limit?: number): Observable<ICertificatesResponse> {
    return this._certificatesService.getCertificates$(ordering, limit).pipe(
      take(1),
      catchError(({ error }: ICertificatesResponse): ObservableInput<ICertificatesResponse> => throwError(() => error))
    );
  }

  getCertifications$(ordering?: string, limit?: number): Observable<ICertificationResponse> {
    return this._certificatesService.getCertifications$(ordering, limit).pipe(
      take(1),
      catchError(
        ({ error }: ICertificationResponse): ObservableInput<ICertificationResponse> => throwError(() => error)
      )
    );
  }

  getFilteredCertificates$(content_type: ICertificatsContentType): Observable<ICertificatesResponse> {
    return this._certificatesService.getFilteredCertificates$(content_type).pipe(
      take(1),
      catchError(({ error }: ICertificatesResponse): ObservableInput<ICertificatesResponse> => throwError(() => error))
    );
  }

  getCertificate$(uuid: string): Observable<ICertificateResponse> {
    return this._certificatesService.getCertificate$(uuid).pipe(
      take(1),
      catchError(({ error }: ICertificateResponse): ObservableInput<ICertificateResponse> => throwError(() => error))
    );
  }

  createCertificate$(data: Partial<ICertificate>): Observable<ICertificateResponse> {
    const formData = generateFormData(data);

    return this._certificatesService.createCertificate$(formData).pipe(
      take(1),
      catchError(({ error }: ICertificateResponse): ObservableInput<ICertificateResponse> => throwError(() => error))
    );
  }

  getCertificatesDomains$(): Observable<ICertificateDomainResponse> {
    return this._certificatesService.getCertificatesDomains$().pipe(
      take(1),
      catchError(
        ({ error }: ICertificateDomainResponse): ObservableInput<ICertificateDomainResponse> => throwError(() => error)
      )
    );
  }

  getCertificatesCategories$(): Observable<ICertificateCategoriesResponse> {
    return this._certificatesService.getCertificatesCategories$().pipe(
      take(1),
      catchError(
        ({ error }: ICertificateCategoriesResponse): ObservableInput<ICertificateCategoriesResponse> =>
          throwError(() => error)
      )
    );
  }

  updateCertificate$(uuid: string, data: Partial<ICertificate>): Observable<ICertificateResponse> {
    const formData = generateFormData(data);

    return this._certificatesService.updateCertificate$(uuid, formData).pipe(
      take(1),
      catchError(({ error }: ICertificateResponse): ObservableInput<ICertificateResponse> => throwError(() => error))
    );
  }

  ////delete any certificate from  certificates in settings
  deleteCertificate$(uuid: string): Observable<IDeleteCertificate> {
    return this._certificatesService.deleteCertificate$(uuid).pipe(
      take(1),
      catchError(({ error }: IDeleteCertificate): ObservableInput<IDeleteCertificate> => throwError(() => error))
    );
  }

  ////delete any certificate from  certificates in dashboard
  deleteCertifications$(uuid: string): Observable<IDeleteCertificate> {
    return this._certificatesService.deleteCertifications$(uuid).pipe(
      take(1),
      catchError(({ error }: IDeleteCertificate): ObservableInput<IDeleteCertificate> => throwError(() => error))
    );
  }

  createCertification$(
    data: Partial<ICertificate>,
    content_type: IContentTypeURL,
    uuid: string
  ): Observable<ICertificationResponse> {
    const formData = generateFormData(data);

    // For creating certificate of content_type 'brands'
    if (content_type === 'brands') {
      return this._certificatesService.createBrandCertification$(formData).pipe(
        take(1),
        catchError(
          ({ error }: ICertificationResponse): ObservableInput<ICertificationResponse> => throwError(() => error)
        )
      );
    }
    return this._certificatesService.createCertification$(formData, content_type, uuid).pipe(
      take(1),
      catchError(
        ({ error }: ICertificationResponse): ObservableInput<ICertificationResponse> => throwError(() => error)
      )
    );
  }
  updateCertification$(
    uuid: string,
    certificationUuid: string,
    content_type: IContentTypeURL,
    data: Partial<ICertificate>
  ): Observable<ICertificationResponse> {
    const formData = generateFormData(data);

    // For updating certificate of content_type 'brands'
    if (content_type === 'brands') {
      return this._certificatesService.updateBrandCertification$(formData, certificationUuid).pipe(
        take(1),
        catchError(
          ({ error }: ICertificationResponse): ObservableInput<ICertificationResponse> => throwError(() => error)
        )
      );
    }
    return this._certificatesService.updateCertification$(formData, content_type, uuid, certificationUuid).pipe(
      take(1),
      catchError(
        ({ error }: ICertificationResponse): ObservableInput<ICertificationResponse> => throwError(() => error)
      )
    );
  }

  deleteCertification$(supplier_uuid: string, uuid: string): Observable<IDeleteCertification> {
    return this._certificatesService.deleteCertification$(supplier_uuid, uuid).pipe(
      take(1),
      catchError(({ error }: IDeleteCertification): ObservableInput<IDeleteCertification> => throwError(() => error))
    );
  }

  getSupplierCertificate$(certificate_uuid: string): Observable<ICertificationSupplier> {
    return this._certificatesService.getSupplierCertificate$(certificate_uuid).pipe(
      take(1),
      catchError(
        ({ error }: ICertificationSupplier): ObservableInput<ICertificationSupplier> => throwError(() => error)
      )
    );
  }
}
