import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { OFFICE_FILENAME_EXTENSIONS } from '@app/core/constants';
import { IMedia } from '@app/core/interface/medias.interface';
import { ISuppDirectSupplier, ISuppStepBatchDirectSupplier } from '@app/core/interface/supp.interface';
import { FileViewComponent } from '@app/shared/components/file-view/file-view.component';

@Component({
  selector: 'app-direct-supplier-modal',
  templateUrl: './direct-supplier-modal.component.html',
  styleUrls: ['./direct-supplier-modal.component.scss'],
})
export class DirectSupplierModalComponent {
  constructor(
    private _dialog: MatDialog,
    public dialogRef: MatDialogRef<DirectSupplierModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      directSupplier?: ISuppDirectSupplier;
      directSupplierBatch?: ISuppStepBatchDirectSupplier;
    }
  ) {}

  openDocument(file: IMedia) {
    let mimetype;
    const extIndex = file.name.split('.').length - 1;
    const ext = file.name.split('.')[extIndex];
    if (OFFICE_FILENAME_EXTENSIONS.includes(ext)) {
      mimetype = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
    } else {
      mimetype = 'application/pdf';
    }

    this._dialog.open(FileViewComponent, {
      width: '1185px',
      height: '780px',
      data: {
        url: file.file,
        name: file.name,
        mimetype: mimetype,
        created_at: file.created_at,
      },
    });
  }
}
