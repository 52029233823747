<div class="dialog-wrapper">
  <div class="holder">
    <div class="breadcrump">
      <app-custom-icon
        mat-dialog-close
        [icon_category]="'arrows'"
        [icon_name]="'arrow-circle-left-short'"
      ></app-custom-icon>
      <h4 mat-dialog-close>{{ 'Address Management' | translate }}</h4>
      <app-custom-icon [icon_category]="'arrows'" [icon_name]="'arrow-right-2'"></app-custom-icon>
      <h3>{{ data?.uuid ? 'Edit Address' : ('Add New Address' | translate) }}</h3>
      <div class="close">
        <app-custom-icon [icon_category]="'essential'" [icon_name]="'close-circle'" mat-dialog-close></app-custom-icon>
      </div>
    </div>

    <div class="h-line">
      <div class="horizontal-line mb-2"></div>
    </div>

    <div class="header">
      <h4>{{ 'Add Brand Address' | translate }}</h4>
    </div>

    <form [formGroup]="form">
      <div class="input-holder">
        <mat-form-field class="input-primary" appearance="outline">
          <mat-label>{{ 'Street Line' | translate }}</mat-label>
          <input matInput formControlName="street_line_1" placeholder="Street Line" />
        </mat-form-field>

        <mat-form-field class="input-primary" appearance="outline">
          <mat-label>{{ 'Street Line 2' | translate }}</mat-label>
          <input matInput formControlName="street_line_2" placeholder="Street Line 2" />
        </mat-form-field>
      </div>

      <div class="input-holder">
        <mat-form-field class="input-primary" appearance="outline">
          <mat-label>{{ 'City' | translate }}</mat-label>
          <input matInput formControlName="city" placeholder="City" />
        </mat-form-field>
        <mat-form-field class="input-primary" appearance="outline">
          <mat-label>{{ 'State/Province' | translate }}</mat-label>
          <input matInput formControlName="state" placeholder="State/Province" />
        </mat-form-field>
      </div>

      <div class="input-holder">
        <mat-form-field class="input-primary" appearance="outline">
          <mat-label>{{ 'Country' | translate }}</mat-label>
          <input type="text" matInput formControlName="country" [matAutocomplete]="auto" required />
          <mat-autocomplete class="autocomplete-primary" #auto="matAutocomplete" [displayWith]="displayFn">
            <mat-option *ngFor="let country of filteredCountries | async" [value]="country">
              {{ country.name }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>

        <mat-form-field class="input-primary" appearance="outline">
          <mat-label>{{ 'Zip Code' | translate }}</mat-label>
          <input matInput formControlName="postcode" placeholder="Zip Code" />
        </mat-form-field>
      </div>

      <div class="input-holder fd-col">
        <div class="subtitle">
          <p>{{ 'How would you like to save this Address' | translate }}</p>
        </div>
        <mat-form-field class="input-primary" appearance="outline">
          <mat-label>{{ 'Name this Brand Address' | translate }}</mat-label>
          <input matInput formControlName="name" placeholder="Name this Brand Address" />
        </mat-form-field>
      </div>
    </form>
  </div>

  <mat-dialog-actions align="end">
    <app-rounded-button size="md" mat-dialog-close color="tetriary-link" button_text="{{ 'Cancel' | translate }}">
    </app-rounded-button>

    <app-rounded-button
      color="turquoise"
      mat-button
      mat-raised-button
      size="md"
      [button_text]="data?.uuid ? 'Save Changes' : ('Add Address' | translate)"
      [disabled]="form && !form.valid"
      (click)="data?.uuid ? updateAddress() : createAddress()"
    >
    </app-rounded-button>
  </mat-dialog-actions>
</div>
