<div class="wrapper">
  <h6 class="strong">{{ 'supplier-dashboard.Company information overview' | translate }}</h6>
  <mat-divider></mat-divider>

  <div class="info-content">
    <div class="holder">
      <div class="holder">
        <div class="info" *ngIf="company.info_validated">
          <div class="dot-green"></div>
          <p class="text">
            {{ 'supplier-dashboard.Information validated on' | translate }}
            {{ company.info_validated_on | date: 'dd.MM.yyyy' }}
          </p>
        </div>
        <div class="info" *ngIf="!company.info_validated">
          <div class="dot-red"></div>
          <p class="text">{{ 'supplier-dashboard.Information is not validated yet' | translate }}</p>
        </div>

        <div class="logo">
          <img
            class="avatar"
            [src]="company.logo ? company.logo : 'assets/images/upload-placeholder-lightblue.png'"
            alt=""
          />
          <div class="company-name">
            <p class="bold">{{ 'supplier-dashboard.Company Name' | translate }}</p>
            <h6 class="name">{{ company.name | slice: 0 : 40 }}</h6>
          </div>
        </div>

        <p class="gray">{{ 'supplier-dashboard.Information completition' | translate }}</p>
        <div class="bar-container progress-bar">
          <p class="text">{{ infoProgress }}% {{ 'supplier-dashboard.completed' | translate }}</p>
          <mat-progress-bar
            class="progress-bar bold-progress-bar"
            mode="determinate"
            [value]="infoProgress"
          ></mat-progress-bar>
        </div>

        <div class="information" *ngIf="isCompleted">
          <app-custom-icon [icon_category]="'essential'" [icon_name]="'tick-circle-green'"></app-custom-icon>
          <p class="info-text">{{ 'supplier-dashboard.All company information entered' | translate }}</p>
        </div>
        <div class="information" *ngIf="!isCompleted">
          <app-custom-icon [icon_category]="'essential'" [icon_name]="'danger-yellow'"></app-custom-icon>
          <p class="info-text">{{ 'supplier-dashboard.Missing information' | translate }}</p>
        </div>
      </div>
    </div>

    <div class="action">
      <app-rounded-button
        class="update-btn"
        size="sm"
        color="turquoise"
        button_text="{{ 'Update Information' | translate }}"
        [icon_category]="'arrows'"
        [icon_name]="'arrow-right-long'"
        [icon_right]="true"
        [size]="'md'"
        [routerLink]="['../company-info']"
      >
      </app-rounded-button>
    </div>
  </div>
</div>
