import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AngularMaterialModule } from '@app/material.module';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ProductsComponent } from './products.component';
import { ProductsRoutingModule } from './products-routing.module';
import { RegisterGroup } from '../register/register.group';
import { SharedModule } from '@app/shared/shared.module';
import { ProductsListComponent } from './products-list/products-list.component';
import { ProjectsModule } from '../projects/projects.module';

@NgModule({
  imports: [
    CommonModule,
    ProductsRoutingModule,
    AngularMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    ProjectsModule,
  ],
  declarations: [ProductsComponent, ProductsListComponent],
  exports: [ProductsComponent],
  providers: [RegisterGroup],
})
export class ProductsModule {}
