import { BehaviorSubject, Observable } from 'rxjs';

import { computed, Injectable, Signal, signal } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SidenavService {
  private enableDisableSuppLinks: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  sidenavUrls: string[] = [
    '/register',
    '/landing',
    '/login',
    '/chain-setup',
    '/purchase',
    '/publish',
    '/switch-brand',
    '/select-brand',
    '/create-password',
  ];

  sidenavUrlChainManagement = '/chain-setup/chain-management';

  private isSidenavOpen = signal<boolean>(false);
  private isSnackbarOpen = signal<boolean>(false);

  get enableDisableSuppLinks$(): Observable<boolean> {
    return this.enableDisableSuppLinks.asObservable();
  }

  toggleSuppSidnavAvalability(validated: boolean): void {
    this.enableDisableSuppLinks.next(validated);
  }

  hasSidenav(url: string): boolean {
    return !this.sidenavUrls.some(sidenavUrl => url.includes(sidenavUrl));
  }

  getIsSidenavOpen(): Signal<boolean> {
    return computed(this.isSidenavOpen);
  }

  getIsSnackbarOpen(): Signal<boolean> {
    return computed(this.isSnackbarOpen);
  }

  setSidenavOpen(isOpen: boolean): void {
    this.isSidenavOpen.set(isOpen);
  }

  setSnackbarOpen(isOpen: boolean): void {
    this.isSnackbarOpen.set(isOpen);
  }
}
