import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl, UntypedFormGroup } from '@angular/forms';
import { ISupplier, ISupplierResponse } from '@interface/suppliers.interface';
import { ICreatePublicationStep } from '@interface/publication.interface';
import { ChainOverviewGroup } from '@module/publication/chain-overview/chain-overview.group';
import { SnackbarService } from '@service/snackbar.service';
import { NotificationType } from '@app/core/constants';
import { GetDirtyValues } from '@app/core/utils/form-dirty-values';
import { IManufactory } from '@interface/manufactories.interface';
import { forkJoin, switchMap } from 'rxjs';
import { CustomPublicationsFacade } from '@module/publication/custom-publications/custom-publications.facade';
import { ICustomPublicationStep } from '@module/publication/custom-publications/custom-publications.models';
import { IProductChainStep } from '@interface/steps.interface';

@Component({
  selector: 'app-amway-create-step',
  templateUrl: './amway-create-step.component.html',
  styleUrl: './amway-create-step.component.scss',
})
export class AmwayCreateStepComponent {
  createStepForm: UntypedFormGroup;
  createSupplierForm: UntypedFormGroup;
  createManufactoryForm: UntypedFormGroup;
  newSupplierUuid!: string;
  supplier!: ISupplier;
  step!: IProductChainStep;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { productChainUid: string; step: ICustomPublicationStep },
    private _group: ChainOverviewGroup,
    private _snackbarService: SnackbarService,
    public dialogRef: MatDialogRef<AmwayCreateStepComponent>,
    private _customPublicationFacade: CustomPublicationsFacade
  ) {
    this.createStepForm = this._group.createStepForm;
    this.createSupplierForm = this._group.createSupplierForm;
    this.createManufactoryForm = this._group.createManufactoryForm;
    this.resetForms();
    this.createManufactoryForm.addControl('uuid', new FormControl());
    this.createSupplierForm.addControl('uuid', new FormControl());
    this.createManufactoryForm.addControl('region', new FormControl());
    this.createManufactoryForm.addControl('latitude', new FormControl());
    this.createManufactoryForm.addControl('longitude', new FormControl());
    this.createStepForm.patchValue({
      product_chain: this.data.productChainUid,
    });
    this.getStepDetails();
  }

  getSupplierDetails(uuid: string): void {
    this._customPublicationFacade.getSupplier$(uuid).subscribe({
      next: this.test.bind(this),
      error: this._error.bind(this),
    });
  }

  getStepDetails() {
    if (this.data.step && this.data.step.uuid) {
      this._customPublicationFacade.getStep$(this.data.step.uuid).subscribe(data => {
        this.step = data;
        this.setupStepForm();
        this.setupSupplierForm();
        this.setupManufactoryForm();
        this.updateSupplierConfidential();
      });
    }
  }

  test(data: ISupplierResponse) {
    this.supplier = this.convertToSupplier(data);
  }

  updateSupplierConfidential() {
    if (this.step.supplier && this.step.supplier.uuid) {
      this.getSupplierDetails(this.step.supplier.uuid);
      this._customPublicationFacade
        .updatePublicationSupplier(
          {
            is_address_private: true,
            is_description_private: true,
            is_tags_private: true,
            is_country_private: true,
            is_name_private: true,
            is_logo_private: true,
          },
          this.step.supplier.uuid
        )
        .subscribe({
          error: this._error.bind(this),
        });
    }
  }

  checkBeforeCreate(): void {
    if (this.createStepForm.valid && this.createSupplierForm.valid && this.validateMapFields()) {
      this.createStepSupplierManufactory();
    } else if (this.createStepForm.valid && this.createSupplierForm.valid && !this.validateMapFields()) {
      this.createStepAndSupplier();
    } else if (this.createStepForm.valid) {
      this.createStep();
    }
  }

  createStepSupplierManufactory(): void {
    const changedStepFormValues: Partial<ICreatePublicationStep> = GetDirtyValues(this.createStepForm);
    const changedSupplierFormValues: Partial<ISupplier> = GetDirtyValues(this.createSupplierForm);
    const changedManufactoryFormValues: Partial<IManufactory> = GetDirtyValues(this.createManufactoryForm);
    this._customPublicationFacade
      .createStepSupplierManufactory$(
        this.data.productChainUid,
        changedStepFormValues,
        changedSupplierFormValues,
        changedManufactoryFormValues
      )
      .subscribe({
        next: this._updateStepSuccess.bind(this, {
          changedStepFormValues,
          changedSupplierFormValues,
          changedManufactoryFormValues,
        }),
        error: this._error.bind(this),
      });
  }

  createStepAndSupplier(): void {
    const changedStepFormValues: Partial<ICreatePublicationStep> = GetDirtyValues(this.createStepForm);
    const changedSupplierFormValues: Partial<ISupplier> = GetDirtyValues(this.createSupplierForm);
    this._customPublicationFacade
      .createStepAndSupplier$(this.data.productChainUid, changedStepFormValues, changedSupplierFormValues)
      .subscribe({
        next: this._updateStepSuccess.bind(this, { changedStepFormValues, changedSupplierFormValues }),
        error: this._error.bind(this),
      });
  }

  createStep(): void {
    const changedStepFormValues: Partial<ICreatePublicationStep> = GetDirtyValues(this.createStepForm);
    this._customPublicationFacade.createStep$(changedStepFormValues, this.data.productChainUid).subscribe({
      next: this._updateStepSuccess.bind(this),
      error: this._error.bind(this),
    });
  }

  setupStepForm(): void {
    this.createStepForm.reset();
    if (this.data?.step || this.step) {
      this.createStepForm.patchValue({
        name: this.step?.name ? this.step.name : this.data.step.name,
        description: this.step?.description ? this.step.description : this.data.step.description,
      });
    }

    this.createStepForm.patchValue({
      product_chain: this.data.productChainUid,
      position_x: 0,
      position_y: 0,
    });
    this.createStepForm.get('product_chain')?.markAsDirty();
    this.createStepForm.get('position_x')?.markAsDirty();
    this.createStepForm.get('position_y')?.markAsDirty();
  }

  setupSupplierForm(): void {
    this.createSupplierForm.reset();
    if (this.step && this.step.supplier) {
      this.createSupplierForm.patchValue({
        name: this.step.supplier.name,
        logo: this.data.step.supplier.logo,
        description: this.data.step.supplier.description,
        uuid: this.data.step.supplier.uuid,
      });
      this.createSupplierForm.addControl('is_address_private', new FormControl(true));
      this.createSupplierForm.addControl('is_description_private', new FormControl(true));
      this.createSupplierForm.addControl('is_tags_private', new FormControl(true));
      this.createSupplierForm.addControl('is_country_private', new FormControl(true));
      this.createSupplierForm.addControl('is_name_private', new FormControl(true));
      this.createSupplierForm.addControl('is_logo_private', new FormControl(true));
    }
    this.createSupplierForm.get('product_chain')?.setValue(this.data.productChainUid);
    this.createSupplierForm.get('product_chain')?.markAsDirty();
  }

  setupManufactoryForm(): void {
    this.createManufactoryForm.reset();

    if (this.data.step && this.data.step.supplier) {
      this.createManufactoryForm.patchValue({
        supplier: this.data.step.supplier.uuid,
      });
    }
  }

  checkBeforeUpdate(): void {
    if (this.createStepForm.valid && this.createSupplierForm.valid && this.validateMapFields()) {
      this.checkUpdateType();
    } else if (this.createStepForm.valid && this.createSupplierForm.valid && !this.validateMapFields()) {
      this.updateStepAndSupplier();
    } else if (this.createStepForm.valid) {
      this.updateStep();
    }
  }

  checkUpdateType(): void {
    this.updateStepSupplierManufactory();
  }

  updateStepSupplierManufactory(): void {
    const changedStepFormValues: Partial<ICreatePublicationStep> = GetDirtyValues(this.createStepForm);
    const changedSupplierFormValues: Partial<ISupplier> = GetDirtyValues(this.createSupplierForm);
    const changedManufactoryFormValues: Partial<IManufactory> = GetDirtyValues(this.createManufactoryForm);
    let supplierUpdate;
    if (this.createSupplierForm.controls['uuid'].value) {
      supplierUpdate = this._customPublicationFacade.updatePublicationSupplier(
        { ...changedSupplierFormValues },
        this.createSupplierForm.controls['uuid'].value
      );
    } else {
      supplierUpdate = this._customPublicationFacade.createPublicationSupplier$({ ...this.createSupplierForm.value });
    }

    const stepUpdate = this._customPublicationFacade.updateStep$(
      { ...changedStepFormValues, supplier: this.createSupplierForm.controls['uuid'].value },
      this.data.step.uuid
    );
    let manufactoryUpdate;
    if (this.createManufactoryForm.controls['uuid'].value) {
      manufactoryUpdate = this._customPublicationFacade.updatePublicationManufactory$(
        { ...changedManufactoryFormValues },
        this.createManufactoryForm.controls['uuid'].value
      );
    } else {
      manufactoryUpdate = this._customPublicationFacade.createPublicationManufactory$({
        ...this.createManufactoryForm.value,
      });
    }

    forkJoin({
      stepUpdate,
      supplierUpdate,
      manufactoryUpdate,
    }).subscribe({
      next: this._updateStepSuccess.bind(this, {
        changedStepFormValues,
        changedManufactoryFormValues,
        changedSupplierFormValues,
      }),
      error: this._error.bind(this),
    });
  }

  updateStepNewManufactory(): void {
    const changedStepFormValues: Partial<ICreatePublicationStep> = GetDirtyValues(this.createStepForm);
    const changedSupplierFormValues: Partial<ISupplier> = GetDirtyValues(this.createSupplierForm);
    const changedManufactoryFormValues: Partial<IManufactory> = GetDirtyValues(this.createManufactoryForm);
    let manufactoryUuid: string;
    this._customPublicationFacade
      .createPublicationManufactory$({ ...changedManufactoryFormValues })
      .pipe(
        switchMap(res => {
          manufactoryUuid = res.uuid;

          return this._customPublicationFacade.updatePublicationSupplier(
            { ...changedSupplierFormValues },
            this.data.step.supplier.uuid
          );
        }),
        switchMap(res => {
          return this._customPublicationFacade.updateStep$(
            { ...changedStepFormValues, manufactories: manufactoryUuid, supplier: res.uuid },
            this.data.step.uuid
          );
        })
      )
      .subscribe({
        next: this._updateStepSuccess.bind(this, {
          changedStepFormValues,
          changedSupplierFormValues,
          changedManufactoryFormValues,
        }),
        error: this._error.bind(this),
      });
  }

  updateStepNewSupplierAndManufactory(): void {
    const changedStepFormValues: Partial<ICreatePublicationStep> = GetDirtyValues(this.createStepForm);
    const changedSupplierFormValues: Partial<ISupplier> = GetDirtyValues(this.createSupplierForm);
    const changedManufactoryFormValues: Partial<IManufactory> = GetDirtyValues(this.createManufactoryForm);
    this._customPublicationFacade
      .createPublicationSupplier$({ ...changedSupplierFormValues })
      .pipe(
        switchMap(res => {
          this.newSupplierUuid = res.uuid;
          return this._customPublicationFacade.createPublicationManufactory$({
            ...changedManufactoryFormValues,
            supplier: res.uuid,
          });
        }),
        switchMap(res => {
          return this._customPublicationFacade.updateStep$(
            { ...changedStepFormValues, manufactories: res.uuid, supplier: this.newSupplierUuid },
            this.data.step.uuid
          );
        })
      )
      .subscribe({
        next: this._updateStepSuccess.bind(this, {
          changedStepFormValues,
          changedSupplierFormValues,
          changedManufactoryFormValues,
        }),
        error: this._error.bind(this),
      });
  }

  updateStepAndSupplier(): void {
    const changedStepFormValues: Partial<ICreatePublicationStep> = GetDirtyValues(this.createStepForm);
    const changedSupplierFormValues: Partial<ISupplier> = GetDirtyValues(this.createSupplierForm);
    const supplierUpdate = this._customPublicationFacade.updatePublicationSupplier(
      { ...changedSupplierFormValues },
      this.data.step.supplier.uuid
    );
    const stepUpdate = this._customPublicationFacade.updateStep$({ ...changedStepFormValues }, this.data.step.uuid);

    forkJoin({
      stepUpdate,
      supplierUpdate,
    }).subscribe({
      next: this._updateStepSuccess.bind(this, { changedSupplierFormValues, changedStepFormValues }),
      error: this._error.bind(this),
    });
  }

  updateStepNewSupplier(): void {
    const changedStepFormValues: Partial<ICreatePublicationStep> = GetDirtyValues(this.createStepForm);
    const changedSupplierFormValues: Partial<ISupplier> = GetDirtyValues(this.createSupplierForm);
    this._customPublicationFacade
      .createPublicationSupplier$({ ...changedSupplierFormValues })
      .pipe(
        switchMap(res => {
          return this._customPublicationFacade.updateStep$(
            { ...changedStepFormValues, supplier: res.uuid },
            this.data.step.uuid
          );
        })
      )
      .subscribe({
        next: this._updateStepSuccess.bind(this, { changedStepFormValues, changedSupplierFormValues }),
        error: this._error.bind(this),
      });
  }

  updateStep(): void {
    const changedStepFormValues: Partial<ICreatePublicationStep> = GetDirtyValues(this.createStepForm);
    this._customPublicationFacade.updateStep$(changedStepFormValues, this.data.step.uuid).subscribe({
      next: this._updateStepSuccess.bind(this, { changedStepFormValues }),
      error: this._error.bind(this),
    });
  }

  validateMapFields(): boolean {
    const address = this.createManufactoryForm.get('address')?.value;
    const city = this.createManufactoryForm.get('city')?.value;
    const country = this.createManufactoryForm.get('country')?.value;
    const region = this.createManufactoryForm.get('region')?.value;

    return !(!address && !city && !country && !region);
  }

  private _updateStepSuccess(updates: unknown): void {
    this.dialogRef.close({ updates });
  }

  private _error(error: Record<string, string[]>): void {
    Object.values(error).map(err => this._snackbarService.openTypeSnackbar(err[0], NotificationType.error));
  }

  groupChanged($event: string) {
    if ($event && $event === 'supplier' && this.supplier?.uuid) {
      this.getSupplierDetails(this.supplier.uuid);
    }
  }

  convertToSupplier(response: ISupplierResponse): ISupplier {
    return {
      name: response.name,
      description: response.description,
      uuid: response.uuid,
      count_unique_product_chains: response.product_chain ? response.product_chain.length : null,
      address: response.address,
      certification_supplier: response.certification_supplier,
      certifications: response.certifications,
      country: response.country,
      city: response.city,
      is_address_private: response.is_address_private,
      is_country_private: response.is_country_private,
      is_description_private: response.is_description_private,
      is_hidden: response.is_hidden,
      is_logo_private: response.is_logo_private,
      is_name_private: response.is_name_private,
      is_clone: response.is_clone,
      is_tags_private: response.is_tags_private,
      handshake_email: response.handshake_email,
      handshake_optout: response.handshake_optout,
      latitude: response.latitude,
      logo: response.logo,
      longitude: response.longitude,
      tags: response.tags,
      ingredients: response.ingredients,
      info_validated_on: response.info_validated_on,
      info_validated: response.info_validated,
      updated_at: response.updated_at instanceof Date ? response.updated_at.toISOString() : response.updated_at,
      youtube_url: Array.isArray(response.youtube_url) ? response.youtube_url.join(',') : response.youtube_url,
      medias: response.medias,
      documents: response.documents,
      links: response.links,
      error: response.error,
      action: 'default_action', // Replace with the correct action or map if available
      sip_is_created_by_sip: false, // Replace with correct value if available,
      is_active: false, // Default to true
      created_at: '', // Default to empty string
      supplier_unique_identifier: '', // Default to empty string
      unique_identifier_type: '', // Default to empty string
      product_chain: [],
      manufactories: [],
    };
  }

  refreshStep($event: boolean) {
    if ($event) {
      this.getStepDetails();
    }
  }

  refreshSupplier($event: boolean) {
    if ($event) {
      this.getSupplierDetails(this.createSupplierForm.value.uuid);
    }
  }

  resetForms(): void {
    this.setupStepForm();
    this.setupSupplierForm();
    this.setupManufactoryForm();
  }
}
