<!-- Assign Brands Header -->
<mat-card class="customer-account-header row">
  <h2>{{ 'Assign Brands to CSM' | translate }}</h2>
</mat-card>

<!-- Assign Brands Content -->
<div class="row" id="container">
  <div class="pl-0 pr-0 h-100">
    <mat-card class="customer-info-1-card">
      <h4>My Brands</h4>
      <app-assign-brands-table></app-assign-brands-table>
    </mat-card>
  </div>
</div>
