import { Component, Input, OnInit } from '@angular/core';
import {
  ICreateBrandErrorResponse,
  ICreateBrandForm,
  IDomain,
  IDomainsResponse,
} from '@app/core/interface/register.interface';
import { ILanguage, ILanguagesResponse } from '@app/core/interface/languages.interface';

import { UntypedFormGroup } from '@angular/forms';
import { GetDirtyValues } from '@app/core/utils/form-dirty-values';
import { MatStepper } from '@angular/material/stepper';
import { RegisterFacade } from '@app/core/facade/register.facade';
import { RegisterGroup } from '../../register.group';
import { Router } from '@angular/router';
import { NotificationType } from '@app/core/constants';
import { SnackbarService } from '@app/core/service/snackbar.service';

@Component({
  selector: 'app-create-brand-new',
  templateUrl: './create-brand-new.component.html',
  styleUrls: ['./create-brand-new.component.scss'],
  providers: [RegisterFacade, RegisterGroup],
})
export class CreateBrandNewComponent implements OnInit {
  @Input() stepper!: MatStepper;
  form: UntypedFormGroup;
  domains: Array<IDomain> = [];
  languages: Array<ILanguage> = [];
  isRegisterPage!: boolean;

  constructor(
    private _regFacade: RegisterFacade,
    private _regGrup: RegisterGroup,
    private _snackbarService: SnackbarService,
    private _router: Router
  ) {
    this.form = this._regGrup.createBrandForm;
    this.isRegisterPage = this._router.url === '/register/complete-register';
  }

  ngOnInit(): void {
    // make get API calls for Domains and Languages in order to populate domain_activity and languages fields
    this._regFacade.getDomainsAndLangs$().subscribe({
      next: this._setLangsAndDomains.bind(this),
      error: this._error.bind(this),
    });
  }

  ngOnDestroy() {
    sessionStorage.removeItem('customer');
    sessionStorage.removeItem('paymentId');
  }

  saveImageData(file: File): void {
    this.form.get('logo')?.setValue(file);
    this.form.get('logo')?.markAsDirty();
  }

  createBrand(): void {
    const changedFormValues: Partial<ICreateBrandForm> = GetDirtyValues(this.form);
    if (this.form.valid) {
      this._regFacade
        .createBrand$({
          ...changedFormValues,
        })
        .subscribe({
          next: this._success.bind(this),
          error: this._error.bind(this),
        });
    }
  }

  private _success = () => {
    this._snackbarService.openTypeSnackbar('Brand created successfully', NotificationType.success);

    if (this.isRegisterPage) {
      this.stepper.next();
    } else {
      this._router.navigate(['/']);
    }
  };

  private _setLangsAndDomains(data: [IDomainsResponse, ILanguagesResponse]): void {
    // first obj of the array isdomains second is languages
    this.domains = data[0].results;
    this.languages = data[1].results;
  }

  private _error(error: ICreateBrandErrorResponse): void {
    Object.values(error).map(err => this._snackbarService.openTypeSnackbar(err, NotificationType.error));
  }
}
