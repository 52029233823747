<h2 mat-dialog-title>
  {{
    data?.supplierUuid ? ('company-information.Edit site' | translate) : ('company-information.Create Site' | translate)
  }}
</h2>
<app-create-site-form
  [supplierUuid]="data?.supplierUuid ?? ''"
  (siteCreated)="onSiteCreated($event)"
  [isSupplier]="true"
  [site]="data?.site"
  [suppSupplier]="data?.suppSupplier"
></app-create-site-form>
