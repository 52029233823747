import { Component, EventEmitter, Input, Output } from '@angular/core';

import { CreateTaskDialogComponent } from '../create-task-dialog/create-task-dialog.component';
import { ITasksInfo } from '@app/core/interface/tasks.interface';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-tasks-overview',
  templateUrl: './tasks-overview.component.html',
  styleUrls: ['./tasks-overview.component.scss'],
})
export class TasksOverviewComponent {
  @Input() tasksInfo!: ITasksInfo;
  @Output() refreshData = new EventEmitter<boolean>();
  constructor(private _dialog: MatDialog) {}
  createTaskDialog() {
    const dialogRef = this._dialog.open(CreateTaskDialogComponent, {
      panelClass: 'top-padding-0',
      width: '1160px',
      height: '768px',
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.refreshData.emit(true);
      }
    });
  }
}
