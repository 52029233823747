import { ChainStatus, IProductChainResult } from '@app/core/interface/productchain.interface';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NotificationType, infoDialogHeight, infoDialogWidth } from '@app/core/constants';

import { AddStepsPhaseComponent } from './../add-steps-phase/add-steps-phase.component';
import { ChartData } from 'chart.js';
import { CreateBatchComponent } from '@app/module/dashboard/product/create-batch/create-batch.component';
import { CreateSupplierComponent } from '@app/module/dashboard/supplier/create-supplier/create-supplier.component';
import { IPhase } from '@app/core/interface/phase-management.interface';
import { ISupplier } from '@app/core/interface/suppliers.interface';
import { InfoDialogComponent } from '@app/shared/components/info-dialog/info-dialog.component';
import { ManageSupplierComponent } from '@app/module/dashboard/supplier/manage-supplier/manage-supplier.component';
import { MatDialog } from '@angular/material/dialog';
import { NodeService } from '@app/core/service/node.service';
import { ProductChainFacade } from '@app/core/facade/productchain.facade';
import { ProductSupplyChainFormComponent } from '@app/module/dashboard/product/product-supply-chains/product-supply-chain-form/product-supply-chain-form.component';
import { Router } from '@angular/router';
import { SnackbarService } from '@app/core/service/snackbar.service';
import { Status } from '@app/core/interface/steps.interface';

@Component({
  selector: 'app-chain-sidenav',
  templateUrl: './chain-sidenav.component.html',
  styleUrls: ['./chain-sidenav.component.scss'],
})
export class ChainSidenavComponent {
  @Input() chainData?: IProductChainResult;
  @Input() phases?: [ChartData<'doughnut'>, IPhase[]];
  @Input() supplierList?: ISupplier[];
  @Input() batchSetup?: boolean;
  @Input() isChainComplete?: boolean;
  @Input() product_chain_uuid?: string | null;
  @Output() getSupplierList = new EventEmitter<boolean>();
  @Output() fetchProductchain = new EventEmitter<boolean>();
  @Output() saveChain = new EventEmitter<boolean>();
  @Output() onUpdateBatchStatus = new EventEmitter<boolean>();
  panelOpenState = true;
  step = 0;
  showViewAllPhasesBtn = false;
  selectedStepsList: string[] = [];
  selectedPhase = '';
  productChain!: IProductChainResult;

  constructor(
    private dialog: MatDialog,
    private nodeService: NodeService,
    private _router: Router,
    private _snackbarService: SnackbarService,
    private _productChainFacade: ProductChainFacade
  ) {}

  ngAfterViewInit() {
    this.nodeService.selectedPhase$.subscribe((data: string | null) => {
      if (data) {
        this.showViewAllPhasesBtn = true;
        this.selectedPhase = data;
      } else {
        this.showViewAllPhasesBtn = false;
      }
    });
  }

  viewAllPhases(): void {
    this.nodeService.selectedPhase.next(null);
    this.selectedPhase = 'all';
  }

  setStep(index: number) {
    this.step = index;
  }

  setStatus(status: string): void {
    if (this.chainData && this.chainData.steps) {
      // Check if there are any steps without suppliers
      const stepsWithoutSuppliers = this.chainData.steps.some(step => !step.supplier);
      // Check if there are any steps without manufactories
      const stepsWithoutManufactories = this.chainData.steps.some(step => !step.manufactories);

      if (status === Status.complete && (stepsWithoutManufactories || stepsWithoutSuppliers)) {
        const dialogRef = this.dialog.open(InfoDialogComponent, {
          width: infoDialogWidth,
          height: infoDialogHeight,
          data: {
            infoText:
              'You are about to update the chain`s status.\n Before proceeding, please note that there are one or more steps of the chain which supplier and/or site have not yet been specified.',
            confirmationText: 'Do you wish to continue?',
            btnText: 'Yes, Continue',
            type: 'warning',
            text: 'warning-text',
            align_items_flex_start: true,
          },
        });

        dialogRef.afterClosed().subscribe((result: boolean) => {
          if (result && this.chainData && this.chainData.uuid) {
            this.updateChainStatus(status);
          }
        });
      } else {
        this.updateChainStatus(status);
      }
    }
  }

  private updateChainStatus(status: string): void {
    if (this.chainData && this.chainData.uuid) {
      const productChainStatus = status;

      this._productChainFacade.changeProductChainStatus$(this.chainData.uuid, productChainStatus).subscribe({
        next: this._success.bind(this),
        error: this._error.bind(this),
      });
    }
  }
  private _success(): void {
    this._router
      .navigate([`/chain-setup/chain-management/${this.chainData?.uuid}`])
      .then(() => window.location.reload());
    this._snackbarService.openTypeSnackbar(`Chain data was updated successfully`, NotificationType.success);
  }

  private _error(error: Record<string, string[]>): void {
    Object.values(error).map(err => this._snackbarService.openTypeSnackbar(err[0], NotificationType.error));
  }

  createSupplierDialog(): void {
    const dialogRef = this.dialog.open(CreateSupplierComponent, {
      width: '1160px',
      height: '768px',
      panelClass: 'top-padding-0',
      data: {
        product_chain_uuid: this.product_chain_uuid,
        show_buttons: false,
        component_btn: false,
        productId: this.chainData?.product.uuid,
      },
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.getSupplierList.emit(true);
        dialogRef.close(true);
      }
    });
  }

  manageSupplierDialog(): void {
    const dialogRef = this.dialog.open(ManageSupplierComponent, {
      width: '1160px',
      height: '768px',
      panelClass: 'top-padding-0',
      data: { suppliers: this.supplierList, show_buttons: false, component_btn: false },
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.getSupplierList.emit(true);
        this.fetchProductchain.emit(true);
      }
    });
  }

  addStepsToPhaseDialog(): void {
    this.saveChain.emit(true);
    const dialogRef = this.dialog.open(AddStepsPhaseComponent, {
      width: '1160px',
      height: '768px',
      panelClass: 'top-padding-0',
      data: {
        product_chain: this.chainData,
        selectedSteps: this.selectedStepsList,
      },
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.fetchProductchain.emit(true);
      }
    });
  }

  openBatchModal(): void {
    const isChainCompleted = this.chainData?.status === ChainStatus.complete;
    this.dialog.open(CreateBatchComponent, {
      width: '1160px',
      height: '768px',
      panelClass: `${isChainCompleted ? 'padding-0' : 'top-padding-0'}`,
      data: {
        product_chain: this.chainData,
        batch: this.chainData?.batch?.[0],
        name: this.chainData?.product.name,
      },
    });
  }

  editSupplyChain(): void {
    const isChainCompleted = this.chainData?.status === ChainStatus.complete;
    this.dialog.open(ProductSupplyChainFormComponent, {
      data: {
        productChain: this.chainData,
        product: this.chainData?.product.uuid,
      },
      panelClass: ['custom-dialog-container', `${isChainCompleted ? 'padding-0' : 'top-padding-0'}`],
      width: '1160px',
      height: '768px',
    });
  }
}
