<div class="complete-register-steps container-fluid row">
  <div class="stepper-holder col-5 d-flex align-items-center justify-content-center">
    <!-- <div class="stepper-logo-holder">respect-code.<span>org</span></div> -->

    <mat-stepper
      [orientation]="(stepperOrientation | async)!"
      [disableRipple]="true"
      labelPosition="bottom"
      [linear]="false"
      (selectionChange)="stepChange($event)"
      [selectedIndex]="selectedStepIndex"
      #registerStepper
    >
      <mat-step [editable]="false" [completed]="selectedStepIndex > 0">
        <ng-template matStepLabel>{{ 'Complete your profile' | translate }}</ng-template>
      </mat-step>
      <mat-step [editable]="false" [completed]="selectedStepIndex > 1">
        <ng-template matStepLabel>{{ 'Create Brand' | translate }}</ng-template>
      </mat-step>
      <mat-step [editable]="false" [completed]="selectedStepIndex > 2">
        <ng-template matStepLabel>{{ 'Product Creation' | translate }}</ng-template>
      </mat-step>
      <!-- <mat-step>
        <ng-template matStepLabel>{{'Video Tutorial' | translate}}</ng-template>
      </mat-step> -->
    </mat-stepper>
  </div>
  <div class="right-half col-7 d-flex align-items-center" [ngSwitch]="selectedStepIndex">
    <ng-template [ngSwitchCase]="0">
      <app-complete-profile-new [stepper]="registerStepper"></app-complete-profile-new>
    </ng-template>
    <ng-template [ngSwitchCase]="1">
      <app-create-brand-new [stepper]="registerStepper"></app-create-brand-new>
    </ng-template>
    <ng-template [ngSwitchCase]="2">
      <app-create-product-new [stepper]="registerStepper"></app-create-product-new>
    </ng-template>
    <!-- <ng-template [ngSwitchCase]="3">
      <app-video-tutorial></app-video-tutorial>
    </ng-template> -->
  </div>
</div>
