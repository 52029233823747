import { RouterModule, Routes } from '@angular/router';

import { BatchPublishingModule } from './batch-publishing/batch-publishing.module';
import { CustomerAccountModule } from './customer-account/customer-account.module';
import { CustomerSuccessManagerComponent } from './customer-success-manager.component';
import { NgModule } from '@angular/core';
import { TranslationtModule } from './../translation/translation.module';
import { AssignBrandsModule } from './assign-brands/assign-brands.module';

const routes: Routes = [
  {
    path: '',
    component: CustomerSuccessManagerComponent,
    children: [
      {
        path: '',
        redirectTo: 'batch-publishing',
        pathMatch: 'full',
      },
      {
        path: 'batch-publishing',
        loadChildren: () => BatchPublishingModule,
      },
      {
        path: 'customer-account',
        loadChildren: () => CustomerAccountModule,
      },
      {
        path: 'assign-brands',
        loadChildren: () => AssignBrandsModule,
      },
      {
        path: 'translation',
        loadChildren: () => TranslationtModule,
      },
      {
        path: '**',
        redirectTo: 'batch-publishing',
        pathMatch: 'full',
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CustomerSuccessManagerRoutingModule {}
