import { IPhase, IPhaseManagementResponse } from '../interface/phase-management.interface';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class PhasesService {
  private apiUrl = environment.api;

  constructor(private _http: HttpClient) {}

  getRespectcycle$(): Observable<IPhaseManagementResponse> {
    return this._http.get<IPhaseManagementResponse>(`${this.apiUrl}/respectcycles/`);
  }

  getRespectcyclePhases$(uuid: string): Observable<IPhase[]> {
    return this._http.get<IPhase[]>(`${this.apiUrl}/productchain/${uuid}/phases`);
  }

  updatePhase$(uuid: string, chainUuid: string, data: Partial<IPhase>): Observable<IPhase> {
    return this._http.patch<IPhase>(`${this.apiUrl}/productchain/${chainUuid}/type-steps/${uuid}/`, data);
  }
}
