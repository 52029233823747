import { AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { IInvitedUsers } from '@app/core/interface/suppliers.interface';

@Component({
  selector: 'app-arena-account',
  templateUrl: './arena-account.component.html',
  styleUrls: ['./arena-account.component.scss'],
})
export class ArenaAccountComponent implements OnInit, AfterViewInit {
  @ViewChild(MatSort, { static: false }) sort!: MatSort;
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  dataSource: MatTableDataSource<IInvitedUsers> = new MatTableDataSource<IInvitedUsers>();
  displayedColumns: string[] = ['name', 'username', 'role', 'actions'];

  constructor(private _changeDetectorRef: ChangeDetectorRef) {}
  ngOnInit(): void {
    this.dataSource.data = [];
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.paginator.pageSize = 6;
    this._changeDetectorRef.detectChanges();
  }

  // private _getcertificatesSuccess(params: IProductCertificatesRespone) {
  //   this.certificates = params.results ? params.results : [];
  //   this.dataSource.data = this.certificates;
  // }

  // private _error(error: Record<string, string[]>): void {
  //   Object.values(error).map((err) => this._snackBar.open(err[0], 'OK'));
  // }
}
