<div class="container-fluid">
  <div class="row container-inside">
    <div class="col-md-6 left p-0">
      <!-- Illustration-design cpomponent witch can be used multiple times only with diferent input color -->
      <app-illustration-design [color]="'primary'"></app-illustration-design>
    </div>
    <div class="col-md-6 right row justify-content-center">
      <div class="col-md-10 content row">
        <div class="col-md-8 box mb-0 p-0 row flex-nowrap">
          <h2 class="d-none d-md-block">{{ 'Success!' | translate }}</h2>

          <div class="success-text-icon d-flex flex-md-wrap justify-content-center align-items-center">
            <h5 class="col-auto col-md-12">
              {{ 'You have successfully ' | translate }}<br class="subhead-break" />{{
                'created your account.' | translate
              }}
            </h5>

            <span class="icon-circle">
              <app-custom-icon [icon_category]="'user'" [icon_name]="'profile-tick'"></app-custom-icon>
            </span>
          </div>

          <div class="col-12 divider-holder">
            <mat-divider></mat-divider>
          </div>

          <h4>
            {{ 'Continue with the setup and start' | translate }}<br />{{ 'managing your chains now.' | translate }}
          </h4>
          <p class="update-the-info">{{ 'You can update the info anytime after the registration.' | translate }}</p>

          <app-rounded-button
            color="turquoise"
            button_text="{{ 'Continue' | translate }}"
            [icon_category]="'arrows'"
            [icon_name]="'arrow-right-long'"
            [icon_right]="true"
            (click)="completeRegisterSteps()"
          >
          </app-rounded-button>
        </div>
      </div>
    </div>
  </div>
</div>
