import { AfterViewInit, Component, Input, OnChanges, QueryList, SimpleChanges, ViewChildren } from '@angular/core';
import { SetStepTypeCardComponent } from './set-step-type-card/set-step-type-card.component';
import { ICountry } from '@app/core/interface/suppliers.interface';
import { CountryList } from '@app/core/utils/country-list';
import {
  IAgecTracebility,
  TracebilityStepOne,
  TracebilityStepThree,
  TracebilityStepTwo,
} from '@app/core/interface/regulations.interface';

@Component({
  selector: 'app-tracebility-steps',
  templateUrl: './tracebility-steps.component.html',
  styleUrls: ['./tracebility-steps.component.scss'],
})
export class TracebilityStepsComponent implements AfterViewInit, OnChanges {
  dirty = false;
  @ViewChildren(SetStepTypeCardComponent) stepTypeCards!: QueryList<SetStepTypeCardComponent>;
  @Input() shoes = false;
  @Input() stepDetails?: Array<IAgecTracebility | undefined> = [
    {
      agec: '',
      is_submitted: false,
      step_one_country: '',
      step_two_country: '',
      step_three_country: '',
      uuid: '',
      step_one_type: -1,
      step_two_type: -1,
      step_three_type: -1,
    },
  ];
  invalid = true;
  steps: Array<{ title: string; country: string; options: Array<string> }> = [];
  data: Array<{ choice: string; country: ICountry }> = [];
  defaultSteps = [
    {
      header: 'Fabric Construction',
      title: 'Choose one of the step types',
      options: ['Weaving', 'Knitting'],
      country: '',
    },
    {
      header: 'Fabric Colouring',
      title: 'Choose one of the step types',
      options: ['Dyeing', 'Printing', 'Dyeing & Printing'],
      country: '',
    },
    { header: 'Garment Making', title: 'Step Type', options: ['Assembly'], country: '' },
  ];
  shoesSteps = [
    { title: 'Step type', options: ['Stitching'], country: '' },
    { title: 'Step type', options: ['Assembly'], country: '' },
    { title: 'Step type', options: ['Finishing'], country: '' },
  ];
  stepDetail: Array<IAgecTracebility | undefined> = [];
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['shoes']) {
      this.steps = changes['shoes'].currentValue ? this.shoesSteps : this.defaultSteps;
      this.checkValidation();
    }
  }
  ngAfterViewInit(): void {
    this.checkValidation();
  }
  checkValidation(): void {
    this.setData();
    this.invalid = !!this.stepTypeCards?.find((step: SetStepTypeCardComponent) => step.form.invalid);
    if (!this.dirty) this.dirty = !!this.stepTypeCards?.find((step: SetStepTypeCardComponent) => step.form.dirty);
  }
  setDataFromResponse(stepDetails: Array<IAgecTracebility | undefined>) {
    this.stepDetail = stepDetails;
    this.data = [];
    this.stepTypeCards?.forEach((step: SetStepTypeCardComponent, i: number) => {
      if (stepDetails && stepDetails.length) {
        if (i === 0) {
          step.form.controls['country'].setValue(
            CountryList.getCountryByCode(stepDetails[0]?.step_one_country ? stepDetails[0]?.step_one_country : '')
          );
          step.form.controls['choice'].setValue(TracebilityStepOne[stepDetails[0]?.step_one_type ?? -1]);
        }
        if (i === 1) {
          step.form.controls['country'].setValue(
            CountryList.getCountryByCode(stepDetails[0]?.step_two_country ? stepDetails[0]?.step_two_country : '')
          );
          step.form.controls['choice'].setValue(TracebilityStepTwo[stepDetails[0]?.step_two_type ?? -1]);
        }
        if (i === 2) {
          step.form.controls['country'].setValue(
            CountryList.getCountryByCode(stepDetails[0]?.step_three_country ? stepDetails[0]?.step_three_country : '')
          );
          step.form.controls['choice'].setValue(TracebilityStepThree[stepDetails[0]?.step_three_type ?? -1]);
        }
      }

      this.data.push(step.form.value);
    });
    this.checkValidation();
  }
  setData() {
    this.data = [];
    this.stepTypeCards?.forEach((step: SetStepTypeCardComponent) => {
      this.data.push(step.form.value);
    });
  }
}
