<div class="col-12">
  <h2 class="dialog-title">
    {{ is_editing ? editTitle : createTitle }}
  </h2>
  <span class="col-1 dlt-btn"> </span>
</div>
<mat-dialog-content>
  <!------------------------- No custom fields ------------------------->
  <div *ngIf="customFields.length === 0 || !is_project; else tabs">
    <app-project-form
      [is_editing]="is_editing"
      [isSubproject]="!is_project"
      [subprojectId]="is_project ? id : subprojectId"
      [projectId]="projectId"
      (saveSuccess)="saveSuccess($event)"
      (setProducts)="products = $event"
      [from_project]="data.project_view && is_project"
      [form]="form"
    ></app-project-form>
  </div>

  <!---------------------- if custom fields present --------------------->
  <ng-template #tabs>
    <mat-tab-group preserveContent (selectedTabChange)="tabChanged($event)">
      <!-- Standard fields tab -->
      <mat-tab>
        <ng-template mat-tab-label>
          <div class="custom-tab-label">
            <app-custom-icon icon_category="grid" icon_name="grid-6"></app-custom-icon>
            <p class="p1-strong m-0">{{ 'Standard Fields' | translate }}</p>
          </div>
        </ng-template>
        <ng-template matTabContent>
          <app-project-form
            [is_editing]="is_editing"
            [isSubproject]="!is_project"
            [subprojectId]="is_project ? id : subprojectId"
            [projectId]="projectId"
            (saveSuccess)="saveSuccess($event)"
            (setProducts)="products = $event"
            [from_project]="data.project_view && is_project"
            [form]="form"
          ></app-project-form>
        </ng-template>
      </mat-tab>
      <!-- Custom fields tab -->
      <mat-tab [label]="'Custom Fields' | translate" [disabled]="projectFormComponent.form.invalid">
        <ng-template mat-tab-label>
          <div class="custom-tab-label" (click)="projectFormComponent.form.markAllAsTouched()">
            <app-custom-icon icon_category="grid" icon_name="element-plus"></app-custom-icon>
            <p class="p1-strong m-0">{{ 'Custom Fields' | translate }}</p>
            <div [ngClass]="showRedCircle ? 'red-circle' : 'd-none'"></div>
          </div>
        </ng-template>
        <ng-template matTabContent>
          <app-custom-fields-form
            [customFields]="customFields"
            [customFieldsData]="customFieldsData"
            [is_editing]="is_editing"
            [form]="form"
          ></app-custom-fields-form>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </ng-template>
</mat-dialog-content>
<!---------------------------- Buttons ---------------------------->
<mat-dialog-actions class="d-flex justify-content-end">
  <div class="d-flex justify-content-end">
    <app-rounded-button (click)="cancel()" color="tetriary-link md" [button_text]="'Cancel' | translate">
    </app-rounded-button>
    <app-rounded-button
      color="primary md"
      [icon_category]="'essential'"
      [icon_name]="is_project ? 'project' : 'sub-project'"
      [icon_right]="true"
      (click)="save()"
      [button_text]="is_editing ? editButton : createButton"
      [disabled]="projectFormComponent?.form?.invalid || (projectFormComponent?.form?.pristine && form?.pristine)"
    >
    </app-rounded-button>
  </div>
</mat-dialog-actions>
