import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { environment } from '@env/environment';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-first-product-info-dialog',
  templateUrl: './first-product-info-dialog.component.html',
  styleUrls: ['./first-product-info-dialog.component.scss'],
})
export class FirstProductInfoDialogComponent {
  description_type!: string;
  description!: string;
  isProductionEnvironment: boolean = environment.production;
  isQaEnvironment: boolean = environment.qa;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: string,
    private dialogRef: MatDialogRef<FirstProductInfoDialogComponent>,
    public dialog: MatDialog,
    private translate: TranslateService
  ) {
    this.description_type = data;
    if (data === 'first product') {
      this.description = this.translate.instant(
        'You have created your first product, you can find it in the Product Section here.'
      );
    } else if (data === 'manage suppliers') {
      this.description = this.translate.instant('You can also manage your suppliers, in the Suppliers Section here.');
    } else {
      this.description = this.translate.instant(
        'To get started, let’s go to the supply chain and create a chain for your product.'
      );
    }
  }

  manageSupplierDialog() {
    this.dialog.open(FirstProductInfoDialogComponent, {
      panelClass: 'manage-suppliers-dialog',
      data: 'manage suppliers',
    });
  }

  crateChainDialog() {
    this.dialogRef.close(true);
    this.dialog.open(FirstProductInfoDialogComponent, {
      panelClass:
        this.isProductionEnvironment || this.isQaEnvironment ? 'create-chain-dialog-qa' : 'create-chain-dialog',
    });
  }
}
