<h2 mat-dialog-title>
  {{ 'Create task' | translate }}
</h2>
<mat-dialog-content class="col-12 right-side">
  <form [formGroup]="form">
    <div class="row">
      <div class="col-12 header-wrapper row">
        <mat-label class="headers">{{ 'Task general info' | translate }}</mat-label>
      </div>
      <div class="col-6">
        <div class="row inside-row">
          <div class="col-12 fields-section">
            <mat-form-field class="input-primary" appearance="outline" floatLabel="auto">
              <mat-label>{{ 'Name' | translate }}</mat-label>
              <input matInput formControlName="name" placeholder="Task Name" />
            </mat-form-field>
          </div>
          <div class="col-6">
            <mat-form-field class="input-primary" appearance="outline" floatLabel="auto">
              <mat-label>{{ 'Start date' | translate }}</mat-label>
              <input matInput [matDatepicker]="picker_start" [min]="minStart" formControlName="start_at" />
              <mat-hint>MM/DD/YYYY</mat-hint>
              <mat-datepicker-toggle matSuffix [for]="picker_start"> </mat-datepicker-toggle>
              <mat-datepicker #picker_start></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="col-6">
            <mat-form-field class="input-primary" appearance="outline" floatLabel="auto">
              <mat-label>{{ 'End date' | translate }}</mat-label>
              <input matInput [matDatepicker]="picker_end" [min]="minEnd" formControlName="end_date" />
              <mat-hint>MM/DD/YYYY</mat-hint>
              <mat-datepicker-toggle matSuffix [for]="picker_end"> </mat-datepicker-toggle>
              <mat-datepicker #picker_end></mat-datepicker>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="row">
          <mat-form-field class="input-primary textarea" appearance="outline">
            <mat-label>{{ 'Description' | translate }}</mat-label>
            <textarea
              matInput
              formControlName="description"
              placeholder="{{ 'Description' | translate }}"
              appTextareaAutoResize
              rows="6"
            ></textarea>
          </mat-form-field>
        </div>
      </div>
      <div class="row setup-t">
        <div class="col-12 header-wrapper-2">
          <mat-label class="headers">{{ 'Task Setup' | translate }}</mat-label>
        </div>
        <div class="col-6">
          <mat-label class="label-text-task">{{
            'Choose with what object is this Task associated' | translate
          }}</mat-label>
        </div>
        <div class="col-3 filters">
          <mat-form-field
            class="input-primary filters col-6"
            appearance="outline"
            [ngClass]="selectedType ? 'selType' : ''"
            floatLabel="auto"
          >
            <mat-select
              [(value)]="selectedType"
              [placeholder]="'Filter Types' | translate"
              (valueChange)="filterByType()"
              class="sel-fil"
            >
              <mat-option *ngFor="let type of types" [value]="type" placeholder="type">
                {{ type.name }}
              </mat-option>
            </mat-select>
            <app-custom-icon
              *ngIf="!selectedType"
              class="search-filter"
              [icon_category]="'search'"
              [icon_name]="'search-filter'"
            ></app-custom-icon>
            <app-custom-icon
              *ngIf="selectedType"
              class="search-filter"
              [icon_category]="'essential'"
              (click)="removeSelectedObjAndType()"
              [icon_name]="'close-circle-white'"
            ></app-custom-icon>
          </mat-form-field>
        </div>
        <div class="col-3 filters">
          <mat-form-field
            class="input-primary filters col-6"
            appearance="outline"
            [ngClass]="form.controls['relatedObj'].value ? 'selObj' : ''"
            floatLabel="auto"
          >
            <input
              id="relatedObj"
              formControlName="relatedObj"
              [placeholder]="'Filter Objects' | translate"
              [value]="form.controls['relatedObj'].value?.valid ? form.controls['relatedObj'].value?.username : ''"
              matTooltip="Select object"
              matTooltipShowDelay="1000"
              placeholder="{{ 'Search object' | translate }}"
              panelClass="dropdown-primary"
              matInput
              [matAutocomplete]="autoObj"
              (input)="filterObjects($event)"
              #inputFilter
              #trigger="matAutocompleteTrigger"
              required
            />
            <mat-autocomplete [displayWith]="displayObjFn" class="user-list" #autoObj="matAutocomplete">
              <mat-option *ngFor="let object of filteredObjects" [value]="object" placeholder="objectname">
                {{ object.name }}
              </mat-option>
            </mat-autocomplete>
            <app-custom-icon
              *ngIf="!form.controls['relatedObj'].value"
              class="search-filter"
              [icon_category]="'search'"
              [icon_name]="'search-normal-2'"
            ></app-custom-icon>

            <app-custom-icon
              *ngIf="form.controls['relatedObj'].value"
              class="search-filter"
              [icon_category]="'essential'"
              (click)="removeSelectedObj()"
              [icon_name]="'close-circle-white'"
            ></app-custom-icon>
            <mat-hint *ngIf="form.controls['relatedObj'].dirty && !selectedObject">
              {{ 'Select existing object' | translate }}
            </mat-hint>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <mat-form-field class="input-primary" appearance="outline">
            <mat-label>{{ 'Assign to' | translate }}</mat-label>
            <input
              id="assigned_to"
              formControlName="assigned_to"
              [value]="
                form.controls['assigned_to'].value?.username
                  ? form.controls['assigned_to'].value?.username
                  : form.controls['assigned_to'].value
              "
              matTooltip="Select assigne"
              matTooltipShowDelay="1000"
              placeholder="{{ 'Search assigne' | translate }}"
              panelClass="dropdown-primary"
              matInput
              [matAutocomplete]="auto"
              (input)="filterItem($event)"
              #inputFilter
              #trigger="matAutocompleteTrigger"
            />
            <mat-autocomplete [displayWith]="displayFn" class="user-list" #auto="matAutocomplete">
              <mat-option *ngFor="let user of filteredUsers" [value]="user" placeholder="username">
                {{ user.username }}
              </mat-option>
            </mat-autocomplete>
            <app-custom-icon
              class="search-filter"
              [icon_category]="'search'"
              [icon_name]="'search-normal-2'"
            ></app-custom-icon>
            <mat-hint *ngIf="form.controls['assigned_to'].dirty && !assign_to">
              {{ 'Select existing object' | translate }}
            </mat-hint>
          </mat-form-field>
        </div>
        <div class="col-6">
          <mat-form-field class="input-primary status" appearance="outline">
            <mat-label>{{ 'Status' | translate }}</mat-label>
            <mat-select formControlName="status" [value]="form.controls['status']">
              <mat-option *ngFor="let status of statuses" [value]="status" placeholder="Status">
                {{ status }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-6 row">
          <div class="col-12 assigned row" *ngIf="assign_to">
            <div class="avatar col-1">
              <img
                [src]="assign_to.avatar ?? '../../../../../assets/images/image-placeholder.jpg'"
                alt="Author avatar"
                class="avatar"
              />
            </div>
            <div class="creator-info offset-1 col-7">
              <p class="creator-name">{{ assign_to.username ? assign_to.username : ('Name' | translate) }}</p>
              <p class="creator-role">{{ assign_to.role ? assign_to.role : ('Role' | translate) }}</p>
            </div>
            <div class="offset-1 col-1">
              <app-custom-icon
                [icon_category]="'essential'"
                (click)="form.controls['assigned_to'].setValue('')"
                [icon_name]="'close-circle-white'"
              ></app-custom-icon>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <app-rounded-button mat-dialog-close color="tetriary-link" [size]="'md'" [button_text]="'Close'">
  </app-rounded-button>
  <app-rounded-button
    color="primary"
    [button_text]="'Create task' | translate"
    [icon_category]="'essential'"
    [icon_name]="'task-square'"
    [icon_right]="true"
    [size]="'md'"
    [disabled]="form.invalid || form.pristine || !selectedObject || !assign_to"
    (click)="createTask()"
  >
  </app-rounded-button>
</mat-dialog-actions>
