<p class="step-header">
  {{ 'Recycled Materials Used' | translate }}
</p>

<div class="row step-cont cont-1">
  <div class="col-12">
    <!-- <div class="head-info">
      {{ 'Please select the type of your product' | translate }}
    </div> -->
    <form [formGroup]="form">
      <div class="sel row">
        <mat-card>
          <mat-radio-group formControlName="recycled_materials" class="row">
            <mat-radio-button
              (click)="onSelection(type)"
              *ngFor="let type of types"
              [value]="type"
              class="col-6 align-items-start"
            >
              <p class="radio-lab">
                {{ type.name }}
              </p>
            </mat-radio-button>
          </mat-radio-group>
        </mat-card>
        <div *ngIf="selectedType?.uuid === 0" class="row per">
          <mat-form-field appearance="outline" class="w-100 input-primary px-1 col-3" *ngIf="selectedType?.uuid === 0">
            <mat-label>{{ 'Enter number' | translate }}</mat-label>
            <input
              matInput
              formControlName="percentage"
              placeholder="{{ 'Please enter number as percentage' | translate }}"
              type="number"
              min="0"
              max="100"
            />
            <mat-hint>{{ 'Please enter number as percentage' | translate }} </mat-hint>
          </mat-form-field>
          <span class="col-6 per-txt"
            ><span class="per-sym">%</span> {{ 'of recycled material in this product' | translate }}</span
          >
        </div>

        <div class="action row" *ngIf="selectedType?.uuid === 1">
          <div class="action-text col-12">
            {{ 'Display that the product does not contain any recycled materials.' | translate }}
            <mat-slide-toggle
              [checked]="showInfo"
              class="custom-slide-toggle col-1"
              [color]="'primary'"
              (change)="showInfo = !showInfo; form.markAsDirty()"
            >
            </mat-slide-toggle>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
<!-- </div> -->
