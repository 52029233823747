<h2 mat-dialog-title>
  {{ title | translate }}
</h2>
<mat-dialog-content class="col-12 right-side">
  <form [formGroup]="form">
    <mat-form-field class="input-primary" appearance="outline">
      <mat-label>{{ 'Name' | translate }}</mat-label>
      <input matInput formControlName="name" placeholder="Name" />
    </mat-form-field>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <app-rounded-button mat-dialog-close color="tetriary-link" [size]="'md'" [button_text]="'Cancel'">
  </app-rounded-button>
  <app-rounded-button
    color="primary"
    [button_text]="btn"
    [icon_category]="'arrows'"
    [icon_name]="'arrow-right-long'"
    [icon_right]="true"
    [size]="'md'"
    [disabled]="!form.valid"
    (click)="closeDialog()"
  >
  </app-rounded-button>
</mat-dialog-actions>
