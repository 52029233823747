import { AngularMaterialModule } from '@app/material.module';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { LoaderComponent } from './loader.component';
import { LoaderFacade } from '@app/core/facade/loader.facade';
import { LoaderInterceptor } from '@app/core/interceptor/loader.interceptor';
import { LoaderService } from '@service/loader.service';
import { NgModule } from '@angular/core';

@NgModule({
  imports: [CommonModule, AngularMaterialModule],
  declarations: [LoaderComponent],
  exports: [LoaderComponent],
  providers: [
    LoaderService,
    LoaderFacade,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true,
    },
  ],
})
export class LoaderModule {}
