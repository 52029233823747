import { Component, Input } from '@angular/core';
import { ITask } from '@app/core/interface/tasks.interface';

@Component({
  selector: 'app-recent-tasks',
  templateUrl: './recent-tasks.component.html',
  styleUrls: ['./recent-tasks.component.scss'],
})
export class RecentTasksComponent {
  @Input() tasks!: Array<ITask>;
}
