import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NotificationType } from '@app/core/constants';
import { CustomerSuccessManagementFacade } from '@app/core/facade/customer-success-manager.facade';
import {
  IBatchPublishRequest,
  IBatchPublishRequestsResponse,
} from '@app/core/interface/customer-success-management.interface';
import { SnackbarService } from '@app/core/service/snackbar.service';

@Component({
  selector: 'app-batch-publication-requests-table',
  templateUrl: './batch-publication-requests-table.component.html',
  styleUrls: ['./batch-publication-requests-table.component.scss'],
  providers: [CustomerSuccessManagementFacade],
})
export class BatchPublicationRequestsTableComponent implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort!: MatSort;
  dataSource: MatTableDataSource<IBatchPublishRequest> = new MatTableDataSource<IBatchPublishRequest>();
  displayedColumns: string[] = ['brand', 'request_date', 'assigned_to', 'batch_number', 'status'];

  constructor(
    private _customerSuccessManagerFacade: CustomerSuccessManagementFacade,
    private _snackbarService: SnackbarService
  ) {}

  ngOnInit(): void {
    this._customerSuccessManagerFacade.getBatchPublishingRequests$().subscribe({
      next: this._getBatchPublishingRequestsSuccess.bind(this),
      error: this._error.bind(this),
    });
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.dataSource.sortingDataAccessor = (row: IBatchPublishRequest, columnName: string): string => {
      let columnValue: string;

      switch (columnName) {
        case 'brand':
          columnValue = row.brand.name;
          break;

        case 'request_date':
          columnValue = row.created_at as string;
          break;

        case 'assigned_to':
          columnValue = row.csm_assign.username;
          break;

        case 'batch_number':
          columnValue = row.batch.reference;
          break;

        default:
          columnValue = row[columnName as keyof IBatchPublishRequest] as string;
      }

      return columnValue;
    };
  }

  private _getBatchPublishingRequestsSuccess(data: IBatchPublishRequestsResponse): void {
    if (data && data.results.length) {
      this.dataSource.data = data.results;
    }
  }

  private _error(error: Record<string, string[]>): void {
    Object.values(error).map(err => this._snackbarService.openTypeSnackbar(err[0], NotificationType.error));
  }
}
