import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmailTemplatesComponent } from './email-templates.component';
import { EmailTemplatesRoutingModule } from './email-templates-routing.module';
import { SharedModule } from '@app/shared/shared.module';
import { NodeService } from '@app/core/service/node.service';
import { EmailFacade } from '@app/core/facade/email.facade';
import { ModifyTemplateComponent } from './modify-template/modify-template.component';
import { CreateEmailContentComponent } from './modify-template/create-email-content/create-email-content.component';
import { ModifyTemplateDialogComponent } from './modify-template-dialog/modify-template-dialog.component';

@NgModule({
  declarations: [
    EmailTemplatesComponent,
    ModifyTemplateComponent,
    CreateEmailContentComponent,
    ModifyTemplateDialogComponent,
  ],
  imports: [CommonModule, EmailTemplatesRoutingModule, SharedModule],
  providers: [NodeService, EmailFacade],
})
export class EmailTemplatesModule {}
