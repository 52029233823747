import { Injectable } from '@angular/core';
import { FormGroup, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

@Injectable()
export class AddFieldsGroup {
  createCustomFieldForm: UntypedFormGroup;
  optionForm: UntypedFormGroup;

  constructor(private _fb: UntypedFormBuilder) {
    this.createCustomFieldForm = this._fb.group({
      name: [null, Validators.required],
      field_type: [null, Validators.required],
      field_options: this._fb.array([]),
    });

    this.optionForm = this._fb.group({
      option: [null, Validators.required],
      uuid: [null],
    });
  }

  createOptionForm(): FormGroup {
    return this._fb.group({
      option: [null, Validators.required],
    });
  }

  editOptionForm(): FormGroup {
    return this._fb.group({
      option: [null, Validators.required],
      uuid: [null],
    });
  }
}
