<div class="title-search-row d-flex justify-content-between align-items-end">
  <h6 class="title-note m-0"><strong>Select for which Product you want to create this certificate</strong></h6>
  <mat-form-field class="input-primary mat-form-field-10-px-brd-rd" appearance="outline">
    <input matInput (keyup)="applyFilter($event)" placeholder="Search for product" />
    <app-custom-icon matSuffix icon_category="search" icon_name="search-normal-2"></app-custom-icon>
  </mat-form-field>
</div>

<div class="products-list d-flex flex-wrap">
  <div
    *ngFor="let product of productsObservable$ | async"
    class="product-thumb d-flex align-items-center cursor-pointer"
    [ngClass]="selectedObject?.uuid === product.uuid ? 'product-thumb-selected' : ''"
    (click)="onSelectObject(product)"
  >
    <div class="product-image">
      <img [src]="product.image || 'assets/images/upload-placeholder-blue.svg'" [alt]="product.name" />
    </div>
    <div class="product-text">
      <p class="name">{{ product.name }}</p>
      <p class="description m-0">
        <app-view-rich-text [content]="product.description"></app-view-rich-text>
      </p>
    </div>
    <app-custom-icon
      *ngIf="selectedObject?.uuid === product.uuid"
      class="icon-tick-circle"
      icon_category="essential"
      icon_name="tick-circle"
    ></app-custom-icon>
  </div>
</div>
