import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ForgotPasswordComponent } from './forgot-password.component';
import { LinkSentComponent } from './link-sent/link-sent.component';
import { NewPasswordComponent } from './new-password/new-password.component';
import { PasswordResetComponent } from './password-reset/password-reset.component';

const routes: Routes = [
  {
    path: '',
    children: [
      { path: '', component: ForgotPasswordComponent },
      { path: 'link-sent', component: LinkSentComponent },
      { path: 'new-password/:uid/:token', component: NewPasswordComponent },
      { path: 'password-changed', component: PasswordResetComponent },
      {
        path: '**',
        redirectTo: '',
        pathMatch: 'full',
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ForgotPasswordRoutingModule {}
