<div class="background">
  <div class="header-wraper">
    <mat-card class="options-header">
      <div class="template-heading">
        <h6 class="scr-header">
          {{ 'Manage Email Templates' | translate }}
        </h6>

        <!-- Input filter -->
        <div class="filter">
          <mat-form-field class="input-primary mat-form-field-10-px-brd-rd custom-filter-header" appearance="outline">
            <mat-label class="label-text">{{ 'Quick search...' | translate }}</mat-label>
            <input matInput (keyup)="onQuickFilterChanged($event)" placeholder="Quck search..." #input />
            <app-custom-icon
              class="search-filter"
              [icon_category]="'search'"
              [icon_name]="'search-normal-2'"
            ></app-custom-icon>
          </mat-form-field>
        </div>
      </div>
      <!-- Table view -->
      <div class="table-wraper">
        <table class="custom-table" mat-table matSort [dataSource]="tableDataSource">
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="name">{{ 'Template name' | translate }}</th>
            <td mat-cell *matCellDef="let element" class="name">{{ element.name }}</td>
          </ng-container>

          <ng-container matColumnDef="type">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="type">
              {{ 'Template type' | translate }}
            </th>
            <td mat-cell *matCellDef="let element" class="type">{{ element.email_template_type?.type }}</td>
          </ng-container>

          <ng-container matColumnDef="language">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="language">
              {{ 'Template language' | translate }}
            </th>
            <td mat-cell *matCellDef="let element" class="language">
              {{ element.email_template_type?.language | shortToFullLanguage }}
            </td>
          </ng-container>

          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef class="actions-header text-center actions">
              {{ 'Actions' | translate }}
            </th>
            <td mat-cell *matCellDef="let element" class="actions-body text-center actions">
              <div class="icon-wrap">
                <div>
                  <app-custom-icon
                    id="eye"
                    [icon_category]="'security'"
                    [icon_name]="'eye'"
                    (click)="openViewEmailDialog(element)"
                  >
                  </app-custom-icon>
                </div>
                <div>
                  <app-custom-icon
                    id="edit"
                    [icon_category]="'files'"
                    [icon_name]="'edit'"
                    [routerLink]="'./modify/' + element.uuid"
                  >
                  </app-custom-icon>
                </div>
              </div>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </div>
    </mat-card>
  </div>
</div>
