import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { IEmailTemp, IEmailTemplatesResponse } from '@app/core/interface/email.interface';

import { EmailDialogComponent } from '@app/shared/components/email-dialog/email-dialog.component';
import { EmailFacade } from '@app/core/facade/email.facade';
import { ISupplier } from '@app/core/interface/suppliers.interface';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NotificationType } from '@app/core/constants';
import { SnackbarService } from '@app/core/service/snackbar.service';
import { applyFilter } from '@app/core/utils/apply-filter';

@Component({
  selector: 'app-email-templates',
  templateUrl: './email-templates.component.html',
  styleUrls: ['./email-templates.component.scss'],
})
export class EmailTemplatesComponent implements OnInit, AfterViewInit {
  @ViewChild(MatSort, { static: false }) sort!: MatSort;
  displayedColumns: string[] = ['name', 'type', 'language', 'actions'];
  supplier: ISupplier = {
    name: '',
    description: '',
    uuid: '',
    count_unique_product_chains: null,
    manufactories: [],
    ingredients: [],
    product_chain: [],
    medias: [],
    action: '',
  };
  uuid = '';
  emailTemlates: IEmailTemp[] = [];
  tableDataSource = new MatTableDataSource<IEmailTemp>();
  constructor(
    private _dialog: MatDialog,
    private _snackbarService: SnackbarService,
    private _facade: EmailFacade
  ) {}

  ngOnInit(): void {
    this.getEmailTemplates();
  }

  ngAfterViewInit(): void {
    this.tableDataSource.sort = this.sort;
    this.tableDataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'type':
          return item.email_template_type?.type;
        case 'language':
          return item.email_template_type?.language;
        default:
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          return (item as any)[property];
      }
    };
  }

  getEmailTemplates(): void {
    this._facade.getEmailTemlates$().subscribe({
      next: this._success.bind(this),
      error: this._error.bind(this),
    });
  }

  openViewEmailDialog(tmp: IEmailTemp) {
    const ref = this._dialog.open(EmailDialogComponent, {
      maxWidth: '900px',
      height: 'calc(100% - 100px)',
      autoFocus: false,
      panelClass: ['email-view-dialog-container', 'padding-0'],
      data: {
        template: tmp,
      },
    });

    ref.afterClosed().subscribe((res: string) => {
      if (res) this._snackbarService.openTypeSnackbar(`Invitation has been sent to ${res}`, NotificationType.success);
    });
  }
  private _success(response: IEmailTemplatesResponse): void {
    this.emailTemlates = response.results;
    this.tableDataSource.data = this.emailTemlates;
  }

  private _error(error: Record<string, string[]>): void {
    Object.values(error).map(err => this._snackbarService.openTypeSnackbar(err[0], NotificationType.error));
  }

  /**
   * Filtering data when user types
   * @param value entered by user
   */
  onQuickFilterChanged(event: Event): void {
    applyFilter(event, this.tableDataSource);
  }
}
