<div class="container-fluid">
  <div class="row container-inside">
    <div class="col-md-6 left p0">
      <app-illustration-design color="primary"></app-illustration-design>
    </div>
    <div class="col-md-6 right">
      <div class="content">
        <div class="box">
          <div class="text-box">
            <h2 class="mb-0">{{ 'account-setup.Reset your Password' | translate }}</h2>
            <h5>
              {{ 'account-setup.Please enter your email' | translate }}
            </h5>
            <p>
              {{
                'account-setup.Enter your email address below and we will send you a link to reset your password. Please make sure you enter the same email as the one you used when creating your account.'
                  | translate
              }}
            </p>

            <mat-form-field class="input-primary" appearance="outline">
              <mat-label>{{ 'account-setup.E-mail' | translate }}</mat-label>
              <input matInput placeholder="example@gmail.com" [formControl]="emailFormControl" />
            </mat-form-field>
          </div>
          <div class="button-holder">
            <app-rounded-button
              color="primary"
              class="link"
              [button_text]="'account-setup.Send me link' | translate"
              [icon_category]="'messages'"
              [icon_name]="'directbox-send'"
              [icon_right]="true"
              (click)="onSubmit()"
              [disabled]="emailFormControl.invalid"
            ></app-rounded-button>
            <app-rounded-button
              id="back-to-login"
              [color]="(backBtnClass | async)!"
              class="log"
              [button_text]="'account-setup.Back to log in' | translate"
              [icon_category]="'arrows'"
              [icon_name]="'arrow-left'"
              [icon_left]="true"
              [routerLink]="'/login'"
            ></app-rounded-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
