import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CertificateDashboardComponent } from './certificate-dashboard.component';
import { SharedModule } from '@app/shared/shared.module';
import { ReactiveFormsModule } from '@angular/forms';
import { CertificateDashboardRoutingModule } from './certificate-dashboard-routing.module';
import { CertificatesFacade } from '@app/core/facade/certificates.facade';

@NgModule({
  declarations: [CertificateDashboardComponent],
  imports: [CommonModule, SharedModule, ReactiveFormsModule, CertificateDashboardRoutingModule],
  providers: [CertificatesFacade],
  exports: [CertificateDashboardComponent],
})
export class CertificateDashboardModule {}
