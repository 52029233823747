<div class="wraper" cdkScrollable>
  <div class="breadcrump" (click)="goBack()">
    <app-custom-icon [icon_category]="'arrows'" [icon_name]="'arrow-circle-left-short'"></app-custom-icon>
    <h4 mat-dialog-close>{{ 'Publication Tool' | translate }}</h4>
    <app-custom-icon [icon_category]="'arrows'" [icon_name]="'arrow-right-2'"></app-custom-icon>
    <h3>{{ 'Setting up your chain' | translate }}</h3>
  </div>

  <div class="background-holder"></div>

  <div class="row holder">
    <div class="watch-video" *ngIf="showVideoMsg">
      <p>
        Please note that you need to order your steps on the left to be able to create your chain. You can change the
        chain phases and create new ones. You can also create groups for steps.
      </p>
      <app-custom-icon
        [icon_category]="'essential'"
        [icon_name]="'close-circle'"
        (click)="showVideoMsg = false"
      ></app-custom-icon>
    </div>

    <div
      class="row p-0"
      cdkDropList
      [cdkDropListAutoScrollStep]="20"
      cdkDropListGroup
      [cdkDropListData]="classifications"
      (cdkDropListDropped)="dropClassification($event)"
    >
      <div class="col-6 left-side">
        <div class="create-new box" (click)="editClassification()">
          <app-rounded-button color="primary" [button_text]="'Create New Chain Phase'" [size]="'md'">
          </app-rounded-button>
        </div>

        <div class="manage-classifications box">
          <div
            class="classification-card"
            *ngFor="let classification of classifications; let i = index"
            cdkDropListGroup
            cdkDrag
            [cdkDragData]="classification"
          >
            <div class="card">
              <div class="classification-info">
                <div class="name">
                  <div class="index-circle" [style.background]="classification?.color">
                    {{ i + 1 }}
                  </div>
                  <h2>{{ classification.name }}</h2>
                  <app-custom-icon
                    [icon_category]="'files'"
                    [icon_name]="'edit'"
                    (click)="editClassification(classification)"
                  ></app-custom-icon>
                  <app-custom-icon
                    [icon_category]="'essential'"
                    [icon_name]="'trash'"
                    (click)="removeClassification(classification.uuid)"
                  ></app-custom-icon>
                </div>
                <div class="reorder-info">
                  <span>{{ 'click & drag up or down' | translate }}</span>
                  <div class="icon-wraper">
                    <app-custom-icon class="edit" [icon_category]="'arrows'" [icon_name]="'arrow-2'"></app-custom-icon>
                  </div>
                </div>
              </div>
              <div class="create-group" (click)="addEditGroup(classification.uuid)">
                <h3>Create a group of steps</h3>
                <app-custom-icon [icon_category]="'essential'" [icon_name]="'add'"></app-custom-icon>
              </div>
              <div
                class="drop-steps"
                cdkDropList
                [cdkDropListAutoScrollStep]="20"
                id="{{ classification.uuid }}"
                [cdkDropListData]="classification.steps || []"
                (cdkDropListDropped)="dropItem($event, classification.uuid)"
                [cdkDropListConnectedTo]="getConnectedListAll()"
              >
                <div class="steps-drop">
                  <app-step-item
                    *ngFor="let step of classification.steps"
                    [step]="step"
                    [color]="classification.color"
                    cdkDrag
                    [cdkDragData]="step"
                    (editStepClick)="editStep($event)"
                    (hideStepClick)="hideStep($event)"
                  ></app-step-item>

                  <div class="drop-placeholder">
                    <div class="text">
                      <p>Drop the step here</p>
                      <app-custom-icon [icon_category]="'essential'" [icon_name]="'mouse-square'"></app-custom-icon>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="groups"
                cdkDropList
                [cdkDropListAutoScrollStep]="20"
                id="{{ classification.uuid }}"
                [cdkDropListData]="classification.groups"
                (cdkDropListDropped)="dropGroup($event, classification)"
                [cdkDropListConnectedTo]="getConnectedListAll()"
              >
                <div class="group-card" *ngFor="let group of classification.groups" cdkDrag [cdkDragData]="group">
                  <div class="group-info">
                    <div class="name">
                      <h2>{{ group.name }}</h2>
                      <app-custom-icon
                        [icon_category]="'files'"
                        [icon_name]="'edit'"
                        (click)="addEditGroup(classification.uuid, group)"
                      ></app-custom-icon>
                      <app-custom-icon
                        [icon_category]="'essential'"
                        [icon_name]="'trash'"
                        (click)="deleteGroup(group.uuid)"
                      ></app-custom-icon>
                    </div>
                    <div class="reorder-info">
                      <span>{{ 'click & drag up or down' | translate }}</span>
                      <div class="icon-wraper">
                        <app-custom-icon
                          class="edit"
                          [icon_category]="'arrows'"
                          [icon_name]="'arrow-2'"
                        ></app-custom-icon>
                      </div>
                    </div>
                  </div>
                  <div
                    class="steps"
                    cdkDropList
                    [cdkDropListAutoScrollStep]="20"
                    id="{{ classification.uuid + '-' + group.uuid }}"
                    [cdkDropListData]="group.steps"
                    (cdkDropListDropped)="dropGroupItem($event, classification.uuid, group.uuid)"
                    [cdkDropListConnectedTo]="getConnectedListAll()"
                  >
                    <app-step-item
                      *ngFor="let groupStep of group.steps"
                      [step]="groupStep"
                      [color]="classification.color"
                      cdkDrag
                      [cdkDragData]="groupStep"
                      (editStepClick)="editStep($event)"
                      (hideStepClick)="hideStep($event)"
                    ></app-step-item>

                    <div class="drop-placeholder">
                      <div class="text">
                        <p>Drop the step here</p>
                        <app-custom-icon [icon_category]="'essential'" [icon_name]="'mouse-square'"></app-custom-icon>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-6 right-side">
        <div class="create-new-step box" (click)="createNewStep()">
          <app-rounded-button color="primary" [button_text]="'Create New Step'" [size]="'md'"> </app-rounded-button>
        </div>
        <mat-accordion>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title
                ><app-custom-icon [icon_category]="'essential'" [icon_name]="'info-circle'"></app-custom-icon>
                <p>How to classify the steps?</p>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <p>
              All of the unclassified steps are your original steps and they must be classified before publishing. In
              order to classify them, please drag & drop each step into a corresponding classification, or hide the
              step.
            </p>
          </mat-expansion-panel>
        </mat-accordion>
        <div class="manage-steps">
          <h4>{{ 'Your Unclassified Steps' | translate }}</h4>
          <div
            cdkDropList
            id="unclassified"
            [cdkDropListAutoScrollStep]="20"
            [cdkDropListData]="unclassifiedSteps || []"
            (cdkDropListDropped)="dropItem($event)"
            [cdkDropListConnectedTo]="getConnectedListAll()"
          >
            <app-step-item
              *ngFor="let step of unclassifiedSteps"
              [step]="step"
              cdkDrag
              [cdkDragData]="step"
              (editStepClick)="editStep($event)"
              (hideStepClick)="hideStep($event)"
            ></app-step-item>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="footer">
    <app-rounded-button
      color="primary"
      [button_text]="'Done with setup'"
      [icon_category]="'arrows'"
      [icon_name]="'arrow-right-long'"
      [size]="'md'"
      [icon_right]="true"
      (click)="goBack()"
    >
    </app-rounded-button>
  </div>
</div>
