<div class="dialog-header">
  <p class="title-text">{{ 'Access to publication' | translate }}</p>
  <app-custom-icon
    class="close-icon cursor-pointer"
    icon_category="essential"
    icon_name="close-circle"
    mat-dialog-close
  ></app-custom-icon>
</div>
<div class="content-wrapper">
  <div class="info-wrapper">
    <p class="info-title">{{ 'Publication URL' | translate }}</p>
    <div class="url-note col-12 d-flex">
      <img src="assets/logos/symbol-logo-dark.svg" alt="" />
      <p class="text-note">
        {{ 'Please note that, below you can find the access link to your publication on Respect-Code.' | translate }}
      </p>
    </div>
    <div class="ulr-link-field">
      <a class="url-link-text cursor-pointer" (click)="goToLink()">{{ url }}</a>
    </div>
  </div>
</div>
