import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ICountry } from '@app/core/interface/suppliers.interface';
import { CountryList } from '@app/core/utils/country-list';
import { Observable, map, startWith, takeWhile } from 'rxjs';
import { DigitalSheetGroup } from '../../create-digital-product-sheet-dialog.group';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-set-step-type-card',
  templateUrl: './set-step-type-card.component.html',
  styleUrls: ['./set-step-type-card.component.scss'],
  providers: [DigitalSheetGroup],
})
export class SetStepTypeCardComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {
  @Input() step!: { title: string; options: Array<string>; country: string; header?: string };
  @Input() stepNum!: string;
  @Input() country?: ICountry;
  filteredCountries!: Observable<ICountry[]>;
  countryList = CountryList.isoCountries;
  form: UntypedFormGroup;
  // valid = false;
  alive = true;
  @Output() isValidForm = new EventEmitter<boolean>(false);
  constructor(private _group: DigitalSheetGroup) {
    this.form = _group.countryForm;
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['country'] && changes['country'].firstChange) {
      this.form.controls['country'].setValue(changes['country'].currentValue);
    }
  }
  ngAfterViewInit(): void {
    this.form.markAsPristine();
  }
  ngOnDestroy(): void {
    this.alive = false;
  }

  ngOnInit(): void {
    this.filteredCountries = this.form.controls['country'].valueChanges.pipe(
      takeWhile(() => this.alive),
      startWith(''),
      map(value => this._filter(value || ''))
    );

    this.form.valueChanges.pipe(takeWhile(() => this.alive)).subscribe(() => {
      this.isValidForm.emit(this.form.valid);
    });

    if (this.step.options.length === 1) this.form.controls['choice'].setValue(this.step.options[0]);
    this.form.markAsPristine();
  }

  displayFn(subject: ICountry | null): string {
    return subject?.name ?? '';
  }
  private _filter(value: string): ICountry[] {
    const filterValue = value.toString().toLowerCase();
    return this.countryList.filter(country => country.name.toLowerCase().includes(filterValue));
  }
}
