import { BreakpointObserver } from '@angular/cdk/layout';
import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { Component, OnInit } from '@angular/core';
import { StepperOrientation } from '@angular/material/stepper';
import { Router } from '@angular/router';
import { REGISTER_STEP, Storage } from '@app/core/constants';
import { DataStorageService } from '@app/core/service/data-localstorage.service';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/internal/operators/map';

@Component({
  selector: 'app-complete-register-steps',
  templateUrl: './complete-register-steps.component.html',
  styleUrls: ['./complete-register-steps.component.scss'],
})
export class CompleteRegisterStepsComponent implements OnInit {
  stepperOrientation: Observable<StepperOrientation>;
  selectedStepIndex = 0;

  constructor(
    private _breakpointObserver: BreakpointObserver,
    private _router: Router,
    private _dataStorageService: DataStorageService
  ) {
    this.stepperOrientation = this._breakpointObserver
      .observe('(min-width: 1024px)')
      .pipe(map(({ matches }) => (matches ? 'vertical' : 'horizontal')));
  }

  ngOnInit(): void {
    let stepFromSession = null;
    stepFromSession = this._dataStorageService.get(REGISTER_STEP, Storage.session);

    if (stepFromSession) {
      this.selectedStepIndex = parseInt(stepFromSession) - 1;
    } else {
      this._router.navigate(['/login']);
    }
  }

  stepChange(step: StepperSelectionEvent) {
    this.selectedStepIndex = step.selectedIndex;

    this._dataStorageService.set(REGISTER_STEP, (this.selectedStepIndex + 1).toString(), Storage.session);
  }
}
