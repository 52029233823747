import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CompanyInfoComponent } from './company-info.component';
import { SharedModule } from '@app/shared/shared.module';
import { CompanyInfoRoutingModule } from './company-info-routing.module';
import { SuppSuppliersFacade } from '@app/core/facade/supp-suppliers.facade';
import { EditInfoComponent } from './edit-info/edit-info.component';
import { FormsModule } from '@angular/forms';
import { SupplierSitesTableComponent } from './supplier-sites-table/supplier-sites-table.component';
import { SiteDialogComponent } from './site-dialog/site-dialog.component';
import { AllSitesDialogComponent } from './all-sites-dialog/all-sites-dialog.component';

@NgModule({
  declarations: [
    CompanyInfoComponent,
    EditInfoComponent,
    SupplierSitesTableComponent,
    SiteDialogComponent,
    AllSitesDialogComponent,
  ],
  imports: [CommonModule, CompanyInfoRoutingModule, SharedModule, FormsModule],
  providers: [SuppSuppliersFacade],
})
export class CompanyInfoModule {}
