<div class="pin-row row">
  <div class="col-8 cursor-pointer" (click)="expandArea()">
    <div class="types">
      <div class="pin">
        <span class="position">{{ (position ?? 0) + 1 }}</span>
        <app-custom-icon
          class="pin-svg"
          [ngStyle]="{ color: color }"
          [icon_category]="'essential'"
          [icon_name]="'location-pin-solid'"
        ></app-custom-icon>
      </div>
      <div class="types-name">{{ text | uppercase }}</div>
    </div>
  </div>
  <div class="col d-flex justify-content-end cursor-pointer" (click)="expandArea()">
    <app-custom-icon
      [style]="{ stroke: color }"
      [icon_category]="'essential'"
      [icon_name]="areaExpanded ? 'minus-circle' : 'add-circle-colored'"
    ></app-custom-icon>
  </div>
  <div class="col-12">
    <div [@expandAnimation]="areaExpanded ? 'expanded' : 'collapsed'" class="expandedBox">
      <ul *ngFor="let types of filteredSteps" class="mt-0">
        <!-- Loop the groups -->
        <div class="cursor-pointer expandedArea" *ngIf="types.steps">
          <div class="step-name">
            <h5>{{ types.name }}</h5>
          </div>
          <div *ngFor="let stepsOfGroup of types.steps" (click)="onSupplierDialog(stepsOfGroup)">
            <div class="group-info ms-4">
              <h6>{{ stepsOfGroup?.name }}</h6>
            </div>
            <div class="d-flex step-area ms-4">
              <app-custom-icon
                [ngStyle]="{ color: color }"
                [icon_category]="'essential'"
                [icon_name]="'location-pin-small'"
              ></app-custom-icon>
              <div class="d-flex flex-column">
                <div>{{ stepsOfGroup?.supplier?.name }}</div>
                <div>
                  {{
                    stepsOfGroup.manufactories?.country
                      ? getCountryName(stepsOfGroup.manufactories?.country)
                      : getCountryName(stepsOfGroup.supplier?.country)
                  }}
                </div>
              </div>
              <mat-icon class="info-icon">info</mat-icon>
            </div>
          </div>
        </div>
        <!-- Loop the steps without groups -->
        <div class="cursor-pointer expandedArea step-no-group" (click)="onSupplierDialog(types)" *ngIf="!types.steps">
          <div class="step-name">
            <h5 *ngIf="types.supplier">{{ types.name }}</h5>
            <div *ngIf="!types.supplier" class="d-flex step-area">
              <h5>{{ types.name }}</h5>
            </div>
            <mat-icon *ngIf="!types.supplier" class="info-icon">info</mat-icon>
          </div>
          <div *ngIf="types.supplier" class="d-flex step-area">
            <app-custom-icon
              [ngStyle]="{ color: color }"
              [icon_category]="'essential'"
              [icon_name]="'location-pin-small'"
            ></app-custom-icon>
            <div class="d-flex flex-column">
              <span> {{ types.supplier.is_hidden ? 'Confidential Supplier' : types.supplier.name }}</span>
              <span *ngIf="!types.supplier.is_hidden">
                {{ types.manufactories?.country ? getCountryName(types.manufactories?.country) : '' }}</span
              >
              <span> {{ types.supplier.is_hidden ? getCountryName(types.supplier.country) : '' }}</span>
            </div>
            <mat-icon class="info-icon">info</mat-icon>
          </div>
        </div>
      </ul>
    </div>
  </div>
</div>
