import { Component, Input } from '@angular/core';
import { SharedModule } from '@app/shared/shared.module';
import { PinInfoComponent } from '../pin-info/pin-info.component';
import { CommonModule } from '@angular/common';
import { IPreviewPublication, Step } from '@app/core/interface/preview_publicaton.interface';

@Component({
  selector: 'app-brand-info',
  standalone: true,
  imports: [SharedModule, PinInfoComponent, CommonModule],
  templateUrl: './brand-info.component.html',
  styleUrl: './brand-info.component.scss',
})
export class BrandInfoComponent {
  @Input() productChain?: IPreviewPublication;
  brandUrl?: string;
  expandedStep?: number;

  stepsByClass?: Step[];

  constructor() {}

  ngOnChanges() {
    let brandName = this.productChain?.brand.name;

    // If brand name empty space.
    if (brandName?.indexOf(' ') !== -1) {
      brandName = brandName?.replace(/\s/g, '-');
    }

    if (brandName == 'Maison-Desplanches') {
      this.brandUrl = 'https://www.respect-code.org/brand/maison-desplanches';
    } else {
      this.brandUrl = `/brand/${brandName?.toLowerCase()}`;
    }
  }

  closeOpenSteps(position: number) {
    this.expandedStep = position;
  }
}
