<div class="holder d-flex flex-column">
  <div *ngIf="!is_view" class="info-holder d-flex align-items-center">
    <div class="logo"></div>
    <p class="bolder-lr m-0">{{ 'supply-batch-main.How to collaborate for this step?' | translate }}</p>
    <p (click)="showHideTooltipInfo(false)" class="link m-0">
      {{ 'supply-batch-main.Click here for more details' | translate }}
    </p>
  </div>
  <form [formGroup]="form">
    <div class="step-details-wrap">
      <mat-accordion multi>
        <mat-expansion-panel
          #firstPanel
          expanded
          hideToggle
          (opened)="firstPanelOpenState = true"
          (closed)="firstPanelOpenState = false"
        >
          <mat-expansion-panel-header>
            <mat-panel-title>
              <p class="bolder-xl m-0">{{ 'supply-batch-main.Step details' | translate }}</p>
              <app-custom-icon
                [icon_category]="'arrows'"
                [icon_name]="firstPanelOpenState ? 'arrow-up-2' : 'arrow-down-2'"
              ></app-custom-icon>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="step-details">
            <div class="left-section">
              <div class="info-wrap align-items-center">
                <p class="light-sm m-0">{{ 'supply-batch-main.Step name:' | translate }}</p>
                <p class="bolder-md m-0">{{ stepBatch?.name }}</p>
              </div>
              <div class="info-wrap align-items-center">
                <p class="light-sm m-0">{{ 'supply-batch-main.Product name:' | translate }}</p>
                <p class="bolder-md m-0">{{ stepBatch?.product }}</p>
              </div>
            </div>
            <div class="right-section">
              <div class="info-wrap align-items-center">
                <p class="light-sm m-0">{{ 'supply-batch-main.Supply chain name:' | translate }}</p>
                <p class="bolder-md m-0">{{ stepBatch?.chain }}</p>
              </div>
              <div *ngIf="stepBatch?.project && is_enabled" class="info-wrap align-items-center">
                <p class="light-sm m-0">{{ 'supply-batch-main.Project name:' | translate }}</p>
                <p class="bolder-md m-0">{{ stepBatch?.project }}</p>
              </div>
            </div>
          </div>
          <div class="batch-details">
            <div class="left-section">
              <div class="info-wrap align-items-center">
                <p class="light-sm m-0">{{ 'supply-batch-main.Batch name:' | translate }}</p>
                <p class="bolder-md m-0">{{ stepBatch?.batch?.name }}</p>
              </div>
              <div class="info-wrap align-items-center">
                <p class="light-sm m-0">{{ 'supply-batch-main.Batch start date:' | translate }}</p>
                <p *ngIf="stepBatch?.batch?.date_order; else not_entered_yet" class="bolder-md m-0">
                  {{ stepBatch?.batch?.date_order | date: 'dd.MM.yyyy' }}
                </p>
              </div>
            </div>
            <div class="right-section justify-content-end">
              <div class="info-wrap align-items-center">
                <p class="light-sm m-0">{{ 'supply-batch-main.Batch reference:' | translate }}</p>
                <p class="bolder-md m-0">{{ stepBatch?.batch?.reference }}</p>
              </div>
              <div class="info-wrap align-items-center">
                <p class="light-sm m-0">{{ 'supply-batch-main.Batch end date:' | translate }}</p>
                <p *ngIf="stepBatch?.batch?.date_delivered; else not_entered_yet" class="bolder-md m-0">
                  {{ stepBatch?.batch?.date_delivered | date: 'dd.MM.yyyy' }}
                </p>
              </div>
            </div>
          </div>
        </mat-expansion-panel>
        <div class="divider" [ngClass]="{ 'd-none': firstPanelOpenState || secondPanelOpenState }"></div>
        <mat-expansion-panel
          #secondPanel
          expanded
          hideToggle
          (opened)="secondPanelOpenState = true"
          (closed)="secondPanelOpenState = false"
        >
          <mat-expansion-panel-header>
            <mat-panel-title>
              <p class="bolder-xl m-0">
                {{
                  "supply-batch-main.Data that concerns all the deliveries you've made to your client for this batch:"
                    | translate
                }}
              </p>
              <app-custom-icon
                [icon_category]="'arrows'"
                [icon_name]="secondPanelOpenState ? 'arrow-up-2' : 'arrow-down-2'"
              ></app-custom-icon>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="data-wrap d-flex flex-column">
            <div class="client-holder d-flex justify-content-between">
              <div class="client-info align-items-baseline">
                <p class="light-sm m-0">{{ 'supply-batch-main.Client:' | translate }}</p>
                <p class="bolder-md m-0">{{ stepBatch?.client_name }}</p>
              </div>
              <div class="client-info align-items-baseline delivered-quantity">
                <p class="light-sm m-0">{{ 'supply-batch-main.Delivered quantity:' | translate }}</p>
                <p *ngIf="stepBatch?.quantity; else not_entered_yet" class="bolder-md m-0">{{ stepBatch?.quantity }}</p>
              </div>
              <div class="client-info align-items-baseline">
                <p class="light-sm m-0">{{ 'supply-batch-main.Unit:' | translate }}</p>
                <p *ngIf="stepBatch?.quantity_unit; else not_entered_yet" class="bolder-md m-0">
                  {{ stepBatch?.quantity_unit?.name }}
                </p>
              </div>
            </div>
          </div>

          <p class="light-sm m-0">{{ 'complete-direct-supplier.References' | translate }}:</p>
          <div class="references-wrap d-flex justify-content-between">
            <p *ngIf="stepBatch?.transactions_references; else not_entered_yet" class="m-0">
              {{ stepBatch?.transactions_references }}
            </p>
          </div>
          <p class="light-sm m-0">{{ 'supply-batch-main.Documents' | translate }}:</p>
          <div class="doc-wrap">
            <div
              *ngIf="stepBatch?.proofs?.length; else not_entered_yet"
              class="d-flex justify-content-between align-items-center proofs"
            >
              <div
                class="doc-holder d-flex align-items-center justify-content-between"
                *ngFor="let document of stepBatch?.proofs | slice: 0 : 3"
              >
                <p class="bolder-lr m-0">{{ document.name }}</p>
                <!-- <app-custom-icon
                  (click)="openFileList('documents', document)"
                  [icon_category]="'security'"
                  [icon_name]="'eye'"
                ></app-custom-icon> -->
                <app-custom-icon
                  (click)="openDocument(document)"
                  [icon_category]="'security'"
                  [icon_name]="'eye'"
                ></app-custom-icon>
              </div>
              <app-custom-icon
                class="cursor-pointer view-more-icon"
                mat-button
                [matMenuTriggerFor]="actionMenu"
                *ngIf="stepBatch && stepBatch.proofs && stepBatch.proofs.length >= 4"
                [icon_category]="'settings'"
                [icon_name]="'more-2'"
              ></app-custom-icon>
              <mat-menu
                class="mat-menu-primary project-action doc-menu"
                #actionMenu="matMenu"
                xPosition="before"
                yPosition="above"
              >
                <button
                  class="custom-item d-flex align-items-center justify-content-between"
                  mat-menu-item
                  *ngFor="let document of stepBatch?.proofs | slice: 3"
                >
                  <p class="name m-0">{{ document.name }}</p>
                  <!-- <app-custom-icon
                    (click)="openFileList('documents', document)"
                    [icon_category]="'security'"
                    [icon_name]="'eye'"
                  ></app-custom-icon> -->
                  <app-custom-icon
                    (click)="openDocument(document)"
                    [icon_category]="'security'"
                    [icon_name]="'eye'"
                  ></app-custom-icon>
                </button>
              </mat-menu>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>

    <div
      *ngIf="confirmationPanel && secondPanelOpenState && !alreadyApproved && !disapprovedViewOnly && !is_view"
      class="confirmation-wrap"
      [ngClass]="warningConfirmation ? 'warning' : 'confirmation'"
    >
      <p class="bolder-xl m-0">
        {{ 'supply-batch-main.Do you confirm that all the information mentioned above is correct ?' | translate }}
      </p>
      <div class="radio-buttons-holder">
        <mat-radio-group
          formControlName="step_confirmed_by_supplier"
          class="light-radio-button"
          (change)="confirmInfo($event)"
        >
          <mat-radio-button [value]="true">{{ 'supply-batch-main.Yes' | translate }}</mat-radio-button>
          <mat-radio-button [value]="false">{{ 'supply-batch-main.No' | translate }}</mat-radio-button>
        </mat-radio-group>
      </div>
      <div *ngIf="disapproved" class="diaspproved-text d-flex flex-column">
        <div class="reason-holder">
          <p class="bolder-lr m-0">{{ 'supply-batch-main.Disapproval Reason:' | translate }}</p>
          <p class="light-md m-0">{{ '“' + stepBatch?.supplier_disapproval_reason + '”' }}</p>
        </div>
        <p class="light-md contact m-0">
          {{
            'supply-batch-main.Please review again the information mentioned above and update your answer to this question
          accordingly.' | translate
          }}
        </p>
      </div>
      <div class="attention d-flex align-items-center" *ngIf="warningConfirmation">
        <app-custom-icon [icon_category]="'essential'" [icon_name]="'danger-red'"></app-custom-icon>
        <p class="attention-info">{{ 'supply-batch-main.Attention required!' | translate }}</p>
      </div>
    </div>
    <div *ngIf="secondPanelOpenState && (alreadyApproved || disapprovedViewOnly)" class="already-approved-wrap d-flex">
      <app-custom-icon [icon_category]="'essential'" [icon_name]="'danger'"></app-custom-icon>
      <div *ngIf="disapprovedViewOnly" class="diaspproved-text d-flex flex-column">
        <div class="title-holder d-flex">
          <p class="bolder-150 m-0">{{ 'You have disapproved the information mentioned above.' | translate }}</p>
        </div>
        <div class="reason-holder">
          <p class="bolder-lr m-0">{{ 'supply-batch-main.Disapproval Reason:' | translate }}</p>
          <p class="light-md m-0">{{ '“' + stepBatch?.supplier_disapproval_reason + '”' }}</p>
        </div>
        <p class="light-md contact m-0">
          {{
            'supply-batch-main.If you want to change this input please contact us on “Need Help?” link, and write your
          inquiry. Thanks' | translate
          }}
        </p>
      </div>
      <div *ngIf="alreadyApproved" class="already-approved d-flex flex-column">
        <div class="title-holder d-flex">
          <p class="bolder-150 m-0">
            {{ 'supply-batch-main.You have confirmed the information mentioned above as correct.' | translate }}
          </p>
        </div>
        <div class="reason-holder">
          <p class="light-md m-0">
            {{
              'supply-batch-main.If you want to change this input please contact us on “Need Help?” link, and write your
            inquiry. Thanks' | translate
            }}
          </p>
        </div>
      </div>
    </div>
    <div class="divider"></div>
    <div
      class="supp-site-wrap"
      *ngIf="screen === 'main'"
      (mouseover)="mouseEvHandler(true)"
      (mouseout)="mouseEvHandler(false)"
      [ngClass]="{ 'view-supp-holder': is_view }"
    >
      <div class="supp-holder" [ngClass]="{ inactive: supplier_site_inactive && !is_view }">
        <!-- Complete step first tooltop -->
        <div *ngIf="completeStepFirstTootltip" class="complete-step-tooltip-first d-flex flex-column">
          <div class="content d-flex flex-column">
            <div class="header d-flex justify-content-between">
              <img class="logo" src="../../../../assets/logos/symbol-logo-dark.svg" alt="" />
              <app-custom-icon
                class="cursor-pointer"
                (click)="completeStepFirstTootltip = !completeStepFirstTootltip"
                [icon_category]="'essential'"
                [icon_name]="'close-circle'"
              ></app-custom-icon>
            </div>
            <div class="description">
              {{
                'supply-batch-main.In order to complete this step first review the site, the place where the production or
              the action performed for this step related to this batch takes place.' | translate
              }}
            </div>
          </div>
          <app-rounded-button
            color="secondary"
            [icon_right]="true"
            icon_category="arrows"
            icon_name="arrow-right"
            size="md"
            [button_text]="'supply-chain-step.Ok, next' | translate"
            (click)="completeStepSecondTootltip = true; completeStepFirstTootltip = false"
          ></app-rounded-button>
        </div>
        <p *ngIf="!is_view">
          {{
            'supply-batch-main.Please make sure that this site is the place where the production, or the action performed
          for this step related to this batch takes place.' | translate
          }}
        </p>
        <div class="section">
          <div class="section-label d-flex align-items-center">
            {{ 'Site*' | translate }}
            <app-custom-icon
              class="info-icon cursor-pointer"
              icon_category="essential"
              icon_name="info-circle"
              [matTooltip]="
                ('supply-chain-step.The site is the place where the production or the action performed for this step takes place. Usually it is a factory or a plant, it can also be a field, forest or mine in the case of raw materials extraction or crop'
                  | translate) + '.'
              "
              matTooltipPosition="above"
              matTooltipClass="custom-tooltip-center"
            ></app-custom-icon>
          </div>

          <div *ngIf="is_view" class="view-site-info d-flex align-items-center">
            <h6 class="m-0">{{ stepBatch?.manufactory?.name }}</h6>
            <p class="m-0" *ngIf="stepBatch?.manufactory">
              {{ stepBatch?.manufactory?.address ? stepBatch?.manufactory?.address + ', ' : '' }}
              {{ stepBatch?.manufactory?.city ? stepBatch?.manufactory?.city + ', ' : '' }}
              {{ stepBatch?.manufactory?.region ? stepBatch?.manufactory?.region + ', ' : '' }}
              {{ stepBatch?.manufactory?.country ? stepBatch?.manufactory?.country : '' }}
            </p>
          </div>

          <div
            *ngIf="!is_view"
            class="supplier-site d-flex align-items-center"
            [ngClass]="{ 'disable-site': alreadyApproved }"
          >
            <mat-form-field
              #supplierSiteDropdown
              appearance="outline"
              class="dropdown-primary select-type"
              subscriptSizing="dynamic"
            >
              <mat-select
                formControlName="manufactory"
                panelClass="dropdown-primary dropdown-select-type"
                (selectionChange)="onManufactoryChangeValue()"
                placeholder="{{ 'supply-chain-step.Choose Supplier Site' | translate }}"
              >
                <mat-select-trigger>
                  <div class="trigger-content d-flex align-items-center">
                    <p *ngIf="stepBatch?.manufactory?.name" class="p1-strong">{{ stepBatch?.manufactory?.name }}</p>
                    <p class="description">
                      <span *ngIf="stepBatch?.manufactory?.address">{{ stepBatch?.manufactory?.address }}</span>
                      <span *ngIf="stepBatch?.manufactory?.city">{{ stepBatch?.manufactory?.city }}</span>
                      <span *ngIf="stepBatch?.manufactory?.region">{{ stepBatch?.manufactory?.region }}</span>
                      <span *ngIf="stepBatch?.manufactory?.country">{{ stepBatch?.manufactory?.country }}</span>
                    </p>
                  </div>
                </mat-select-trigger>
                <mat-option class="d-flex align-items-center" *ngFor="let site of supplier_sites" [value]="site.uuid">
                  <p class="p1-strong">{{ site.name }}</p>
                  <p class="description">
                    <span *ngIf="site.address">{{ site.address }}</span>
                    <span *ngIf="site.city">{{ site.city }}</span>
                    <span *ngIf="site.region">{{ site.region }}</span>
                    <span *ngIf="site.country">{{ site.country }}</span>
                  </p>
                </mat-option>
              </mat-select>
            </mat-form-field>

            <div *ngIf="!alreadyApproved" class="create-site d-flex align-items-center">
              <p class="p2 m-0">{{ 'supply-chain-step.Not existing' | translate }}?</p>
              <app-rounded-button
                class="create-site-btn"
                color="secondary"
                size="sm"
                button_text="{{ 'supply-chain-step.Create new site' | translate }}"
                icon_category="buildings"
                icon_name="factory"
                [icon_right]="true"
                (click)="onCreateNewSite()"
              >
              </app-rounded-button>
            </div>
            <div class="overlay-input"></div>
          </div>
          <div class="attention d-flex align-items-center" *ngIf="warningSite">
            <app-custom-icon [icon_category]="'essential'" [icon_name]="'danger-red'"></app-custom-icon>
            <p class="attention-info m-0">{{ 'supply-batch-main.Attention required!' | translate }}</p>
          </div>
        </div>
      </div>

      <div *ngIf="supplier_site_inactive && !is_view" class="overlay">
        <!-- Supplier site inactive tooltip -->
        <div *ngIf="inactive_tooltip" class="inactiv-supp-site-wrap">
          <p class="bolder-lr m-0">
            {{
              'supply-batch-main.Sorry but you are not able to review or update the site, before reviewing all of the
            documents & references and confirming the delivered quantity.' | translate
            }}
          </p>
        </div>
      </div>
    </div>
  </form>

  <!-- How to collaborate tooltip -->
  <div *ngIf="stepBatch && showTooltip && !is_view && !is_first_update" class="collab-wrap d-flex">
    <div class="text-holder d-flex flex-column">
      <p class="bolder-lr m-0">
        {{
          "supply-batch-main.You have been identified as the owner of this step which is one of the steps in the supply
        chain of the brand's product." | translate
        }}
      </p>
      <p class="bolder-lr m-0">
        {{
          "supply-batch-main.Since a batch represents for the brand one or a group of 'productions by her / deliveries to
        her' of the product, this step concerns all the information related to your step for this batch which
        characteristics are mentioned in the section 'Step details'." | translate
        }}
      </p>
      <p class="bolder-lr m-0">
        {{
          "supply-batch-main.Therefore, we invite you first to review the 'Step details' and the 'Data that concerns all
        the deliveries you've made to your client for this batch' so you can proceed and complete other information."
            | translate
        }}
      </p>
    </div>
    <app-custom-icon
      (click)="showHideTooltipInfo(true)"
      [icon_category]="'essential'"
      [icon_name]="'close-circle'"
    ></app-custom-icon>
  </div>
  <!-- Complete step second tooltip -->
  <div *ngIf="completeStepSecondTootltip" class="complete-step-tooltip-second d-flex flex-column">
    <div class="content d-flex flex-column">
      <div class="header d-flex justify-content-between">
        <img class="logo" src="../../../../assets/logos/symbol-logo-dark.svg" alt="" />
        <app-custom-icon
          class="cursor-pointer"
          (click)="completeStepSecondTootltip = !completeStepSecondTootltip"
          [icon_category]="'essential'"
          [icon_name]="'close-circle'"
        ></app-custom-icon>
      </div>
      <div class="description">
        {{
          'supply-batch-main.Next, review and complete here the direct suppliers you have worked with in this step related
        to this batch.' | translate
        }}
      </div>
    </div>
    <app-rounded-button
      color="secondary"
      [icon_right]="true"
      icon_category="arrows"
      icon_name="arrow-right"
      size="md"
      (click)="completeStepSecondTootltip = !completeStepSecondTootltip"
      [button_text]="'supply-chain-step.Ok, got it' | translate"
    ></app-rounded-button>
  </div>
  <ng-template #not_entered_yet>
    <div class="p-light m-0">
      {{ 'supply-batch-main.not entered yet' | translate }}
    </div>
  </ng-template>
</div>
