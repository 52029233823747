<h1 style="padding: 0 12px">{{ 'billing-management.Billing Management' | translate }}</h1>

<div class="header-holder">
  <div class="row">
    <div class="col-12 d-flex justify-content-between align-items-center p-0">
      <h2>{{ 'billing-management.Billing Account Details' | translate }}</h2>
      <app-rounded-button
        color="primary"
        [button_text]="'billing-management.Manage Billing Account' | translate"
        [icon_category]="'finance'"
        [icon_name]="'card-edit'"
        [size]="'md'"
        [icon_right]="true"
        (click)="editDetails()"
      >
      </app-rounded-button>
    </div>
  </div>
  <div class="row info" *ngIf="customerDetails">
    <div class="col-12 p-0">
      <div class="row">
        <div class="col-lg-4 p-0 info-box">
          <div class="icon">
            <app-custom-icon [icon_category]="'user'" [icon_name]="'profile'"></app-custom-icon>
          </div>
          <div class="text">
            <p class="m-0">{{ 'billing-management.Customer Name' | translate }}</p>
            <p class="info-details m-0">{{ customerDetails.first_name }} {{ customerDetails.last_name }}</p>
          </div>
        </div>
        <div class="col-lg-4 p-0 info-box">
          <div class="icon">
            <app-custom-icon [icon_category]="'messages'" [icon_name]="'sms'"></app-custom-icon>
          </div>
          <div class="text">
            <p class="m-0">{{ 'billing-management.Invoice Email Address' | translate }}</p>
            <p class="info-details m-0">{{ customerDetails.email }}</p>
          </div>
        </div>
        <div class="col-lg-4 p-0 info-box">
          <div class="icon">
            <app-custom-icon [icon_category]="'location'" [icon_name]="'location-tick'"></app-custom-icon>
          </div>
          <div class="text">
            <p class="m-0">{{ 'billing-management.Billing Address' | translate }}</p>
            <p class="info-details m-0">
              {{ customerDetails.billing_address.line1 }}, {{ customerDetails.billing_address.city }},
              {{ customerDetails.billing_address.country }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="content-holder">
  <mat-tab-group
    class="p-0"
    [(selectedIndex)]="selectedIndex"
    [ngClass]="{
      emptyTableHeight: (isEmptyTable && selectedIndex === 1) || (isEmptyUnpaidTable && selectedIndex === 2),
      tableHeight: (selectedIndex === 1 && !isEmptyTable) || (selectedIndex === 2 && !isEmptyUnpaidTable)
    }"
  >
    <mat-tab label="{{ 'billing-management.Paid Users' | translate }}">
      <div class="tables-holder">
        <app-paid-users></app-paid-users>
      </div>
    </mat-tab>
    <mat-tab label="{{ 'billing-management.Paid Invoices' | translate }}">
      <div class="tables-holder p-20">
        <app-paid-invoices (emptyTable)="checkTableData($event)"></app-paid-invoices>
      </div>
    </mat-tab>
    <mat-tab label="{{ 'billing-management.Unpaid Invoices' | translate }}">
      <div class="tables-holder p-20">
        <app-unpaid-invoices (emptyUnpaidTable)="checkUnpaidTableData($event)"></app-unpaid-invoices>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
