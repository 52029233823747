import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormGroupDirective, UntypedFormGroup } from '@angular/forms';
import { QuestionnairesManagementGroup } from '@module/questionnaires-management/questionnaires-management-group';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-questionnaire-name-edit-modal',
  templateUrl: './questionnaire-name-edit-modal.component.html',
  styleUrls: ['./questionnaire-name-edit-modal.component.scss'],
})
export class QuestionnaireNameEditModalComponent implements OnInit {
  title!: string;
  form!: UntypedFormGroup;
  btn!: string;
  @ViewChild(FormGroupDirective, { static: false }) formGroupDirective!: FormGroupDirective;

  constructor(
    private _group: QuestionnairesManagementGroup,
    @Inject(MAT_DIALOG_DATA)
    public data: { title: string; data: string },
    public dialogRef: MatDialogRef<QuestionnaireNameEditModalComponent>
  ) {}

  ngOnInit(): void {
    this.form = this._group.editName;
    if (this.data.data != null) {
      this.form.patchValue({
        name: this.data.data,
      });
    }
    this.title = this.data.title;
  }

  closeDialog() {
    this.dialogRef.close(this.form.controls['name'].value);
    this.formGroupDirective.resetForm();
  }
}
