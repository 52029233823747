<div class="video-player">
  <video class="video" controls>
    <source [src]="data" type="video/mp4" />
    Your browser does not support the video tag.
  </video>
  <app-custom-icon
    mat-dialog-close
    class="close-circle"
    [icon_category]="'icons'"
    [icon_name]="'close-circle'"
  ></app-custom-icon>
</div>
