<div class="dialog-header">
  <h3>{{ 'complete-direct-supplier.Direct Supplier Information' | translate }}</h3>
  <app-custom-icon mat-dialog-close [icon_category]="'essential'" [icon_name]="'close-circle'" class="cursor-pointer">
  </app-custom-icon>
</div>
<div class="dialog-body">
  <div class="dialog-row">
    <div class="dialog-field">
      <div class="p1-normal">{{ 'complete-direct-supplier.Direct supplier name:' | translate }}</div>
      <div class="p1-strong">
        {{ data.directSupplierBatch?.supplier ?? data.directSupplier?.name }}
      </div>
    </div>
    <div class="dialog-field">
      <div class="p1-normal">{{ 'complete-direct-supplier.Direct supplier role in the chain:' | translate }}</div>
      <div class="p1-strong">{{ data.directSupplierBatch?.name ?? data.directSupplier?.role }}</div>
    </div>
  </div>

  <!-- Quantity box content on Batch Step -->
  <div class="dialog-box" *ngIf="data.directSupplierBatch">
    <div class="box-row">
      <div class="dialog-field">
        <div class="p1-normal">{{ 'complete-direct-supplier.ReceivedQuantity' | translate }}:</div>
        <div class="p1-normal royal-blue">
          {{ data.directSupplierBatch?.quantity ?? ('complete-direct-supplier.not entered yet' | translate) }}
          {{ data.directSupplierBatch?.quantity_unit?.symbol }}
          {{
            data.directSupplierBatch?.quantity_unit?.name
              ? '(' + data.directSupplierBatch?.quantity_unit?.name + ')'
              : ''
          }}
        </div>
      </div>
    </div>
    <div class="box-row">
      <div class="dialog-field">
        <div class="p1-normal">{{ 'complete-direct-supplier.References' | translate }}:</div>
        <div class="p1-normal royal-blue">
          {{
            data.directSupplierBatch?.reference !== ''
              ? data.directSupplierBatch?.reference
              : ('complete-direct-supplier.not entered yet' | translate)
          }}
        </div>
      </div>

      <!-- PROOF CONTAINER -->
      <div class="dialog-field">
        <div class="p1-normal">{{ 'complete-direct-supplier.proofs' | translate }}:</div>
        <div *ngIf="!data.directSupplierBatch?.proofs?.length" class="p-0">
          <div class="p1-normal royal-blue">{{ 'complete-direct-supplier.not entered yet' | translate }}</div>
        </div>
        <div *ngIf="data.directSupplierBatch?.proofs?.length" class="p1-normal royal-blue">
          <div class="proofs-container row align-items-center">
            <div
              *ngFor="let proof of data.directSupplierBatch?.proofs | slice: 0 : 2"
              class="d-flex"
              [ngClass]="
                data.directSupplierBatch?.proofs?.length === 1
                  ? 'col-12'
                  : data.directSupplierBatch?.proofs?.length! >= 3
                    ? 'col-5'
                    : 'col-6'
              "
            >
              <p class="proof-name mb-0 me-1">{{ proof.name }}</p>
              <div class="d-flex" viewProof>
                <app-custom-icon
                  [icon_category]="'settings'"
                  [icon_name]="'more'"
                  [matMenuTriggerFor]="menu"
                  [matTooltip]="'complete-direct-supplier.View document' | translate"
                  matTooltipClass="custom-tooltip-center"
                  [matTooltipPosition]="'above'"
                  class="pt-1 cursor-pointer"
                ></app-custom-icon>
                <mat-menu #menu="matMenu" class="actions-holder">
                  <button mat-menu-item (click)="openDocument(proof)">
                    <app-custom-icon [icon_category]="'security'" [icon_name]="'eye'"> </app-custom-icon>
                    <span class="p1-normal">{{ 'complete-direct-supplier.Open document' | translate }}</span>
                  </button>
                </mat-menu>
              </div>
              <ng-template #viewProof>
                <app-custom-icon
                  class="cursor-pointer"
                  [icon_category]="'security'"
                  [icon_name]="'eye'"
                  (click)="openDocument(proof)"
                ></app-custom-icon>
              </ng-template>
            </div>

            <div
              *ngIf="data.directSupplierBatch?.proofs !== null && data.directSupplierBatch?.proofs?.length! >= 3"
              class="col-2"
            >
              <app-custom-icon
                class="cursor-pointer"
                [icon_category]="'settings'"
                [icon_name]="'more-2'"
                [matMenuTriggerFor]="allDocuments"
                matTooltip="More"
                matTooltipClass="custom-tooltip-center"
                [matTooltipPosition]="'above'"
              ></app-custom-icon>
              <mat-menu #allDocuments="matMenu">
                <div
                  *ngIf="data.directSupplierBatch?.proofs !== null && (data.directSupplierBatch?.proofs)!.length >= 3"
                >
                  <div
                    *ngFor="let proof of data.directSupplierBatch?.proofs | slice: 2"
                    class="d-flex proofs-container row"
                    (click)="$event.stopPropagation()"
                  >
                    <p class="proof-name mb-0 me-1 ms-2 col-8">{{ proof.name }}</p>
                    <app-custom-icon
                      [icon_category]="'settings'"
                      [icon_name]="'more'"
                      [matMenuTriggerFor]="menu2"
                      [matTooltip]="'complete-direct-supplier.View document' | translate"
                      matTooltipClass="custom-tooltip-center"
                      [matTooltipPosition]="'above'"
                      class="col-2"
                    ></app-custom-icon>
                    <mat-menu #menu2="matMenu">
                      <button mat-menu-item (click)="openDocument(proof)">
                        <app-custom-icon [icon_category]="'security'" [icon_name]="'eye'"> </app-custom-icon>
                        <span class="p1-normal">{{ 'complete-direct-supplier.Open document' | translate }}</span>
                      </button>
                    </mat-menu>
                  </div>
                </div>
              </mat-menu>
            </div>
          </div>
        </div>
      </div>

      <!-- PROOF END -->
    </div>
  </div>

  <!-- Contact Row details -->
  <div class="dialog-row content-row">
    <div class="dialog-field">
      <div class="p1-normal">{{ 'complete-direct-supplier.Contact e-mail' | translate }}:</div>
      <div class="p1-normal royal-blue">
        {{ data.directSupplier?.email ?? data.directSupplierBatch?.email }}
      </div>
    </div>
    <div class="dialog-field">
      <div class="p1-normal">{{ 'complete-direct-supplier.Contact Language' | translate }}:</div>
      <div class="p1-normal royal-blue">
        <ng-container *ngIf="data.directSupplier?.language_prefered as preferredName; else batchPreferredLanguage">
          {{ 'complete-direct-supplier.' + (preferredName.name ?? preferredName) | translate }}
        </ng-container>
        <ng-template #batchPreferredLanguage>
          {{ 'complete-direct-supplier.' + data.directSupplierBatch?.language_prefered | translate }}
        </ng-template>
      </div>
    </div>
  </div>
</div>
