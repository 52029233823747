import {
  IActivateDeactivateProjectResponse,
  IDeleteProjectResponse,
  IProject,
  IProjectLookUpResponse,
  IProjectResponse,
  IProjectsListResponse,
  ISubprojectResponse,
} from '../interface/projects.interface';

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class ProjectsService {
  private apiUrl = environment.api;

  constructor(private _http: HttpClient) {}

  getProjects$(ordering?: string, limit?: number): Observable<IProjectsListResponse> {
    if (ordering && limit) {
      const params = new HttpParams().set('ordering', ordering).set('limit', limit);
      return this._http.get<IProjectsListResponse>(`${this.apiUrl}/projects/`, { params: params });
    }
    return this._http.get<IProjectsListResponse>(`${this.apiUrl}/projects/`);
  }
  getProject$(uuid: string): Observable<IProjectResponse> {
    return this._http.get<IProjectResponse>(`${this.apiUrl}/projects/${uuid}/`);
  }
  createProject$(projectForm: Partial<IProject>): Observable<IProjectResponse> {
    return this._http.post<IProjectResponse>(`${this.apiUrl}/projects/`, projectForm);
  }
  updateProject$(projectForm: Partial<IProject>, uuid: string): Observable<IProjectResponse> {
    return this._http.put<IProjectResponse>(`${this.apiUrl}/projects/${uuid}/`, projectForm);
  }

  getSubproject$(uuid: string): Observable<ISubprojectResponse> {
    return this._http.get<ISubprojectResponse>(`${this.apiUrl}/subprojects/${uuid}/`);
  }
  updateSubproject$(projectForm: FormData, uuid: string): Observable<ISubprojectResponse> {
    return this._http.put<ISubprojectResponse>(`${this.apiUrl}/subprojects/${uuid}/`, projectForm);
  }
  createSubproject$(projectForm: FormData): Observable<ISubprojectResponse> {
    return this._http.post<ISubprojectResponse>(`${this.apiUrl}/subprojects/`, projectForm);
  }
  deleteProject$(uuid: string): Observable<IDeleteProjectResponse> {
    return this._http.delete<IDeleteProjectResponse>(`${this.apiUrl}/projects/${uuid}/`);
  }
  deleteSubProject$(uuid: string): Observable<IDeleteProjectResponse> {
    return this._http.delete<IDeleteProjectResponse>(`${this.apiUrl}/subprojects/${uuid}/`);
  }

  getProjectStatus$(): Observable<IProjectLookUpResponse> {
    return this._http.get<IProjectLookUpResponse>(`${this.apiUrl}/projects/status/`);
  }

  activateDeactivateSubproject$(uuid: string, data: FormData): Observable<IActivateDeactivateProjectResponse> {
    return this._http.patch<IActivateDeactivateProjectResponse>(`${this.apiUrl}/subprojects/${uuid}/active/`, data);
  }

  activateDeactivatePproject$(
    uuid: string,
    data: Partial<IActivateDeactivateProjectResponse>
  ): Observable<IActivateDeactivateProjectResponse> {
    return this._http.patch<IActivateDeactivateProjectResponse>(`${this.apiUrl}/projects/${uuid}/active/`, data);
  }
}
