<div class="main-holder">
  <div class="header">
    <app-chain-setup-header
      [isSupplyChainSetup]="true"
      [isFormValid]="form && form.valid"
      (saveAndClose)="createProductChain(true)"
    ></app-chain-setup-header>
  </div>

  <div class="chain-setup-container">
    <div class="row header-section">
      <h3 *ngIf="!selectedProductChainUuid">{{ 'Supply Chain Setup' | translate }}</h3>
      <h3 *ngIf="selectedProductChainUuid">{{ 'Setup your duplicated supply chain' | translate }}</h3>
      <div class="horizontal-line"></div>
    </div>

    <div class="chain-info-section">
      <div class="chain-name">
        <div class="form-search">
          <div class="form-input">
            <form [formGroup]="form">
              <h6>{{ 'Supply chain name' | translate }}</h6>
              <mat-form-field class="input-primary" appearance="outline">
                <mat-label>{{ 'Enter Name' | translate }}</mat-label>
                <input matInput formControlName="name" placeholder="Some Product Chain Name" required />
              </mat-form-field>
            </form>
          </div>

          <div class="need-help" (click)="needHelpTooltip = !needHelpTooltip">
            <img class="search-logo" src="../../../assets/images/symbol-blue-logo.svg" />
            <h6 class="need-help-text">need help ?</h6>

            <div class="tooltip" *ngIf="needHelpTooltip">
              <p>
                {{ 'Use the name of the product, or a name related to the product you want to trace.' | translate }}
              </p>
              <p class="example-text">
                {{ 'Example: name of raw material, different product version...' | translate }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="!selectedProductChainUuid" class="vertical-line"></div>
      <div *ngIf="!selectedProductChainUuid" class="chain-direction-wrapper">
        <h6>{{ 'Choose chain direction' | translate }}</h6>
        <div class="chain-direction">
          <app-custom-icon
            *ngFor="let arrow of iconList"
            (click)="selectChainDirection(arrow)"
            [ngClass]="{ selected: arrow === selectedDirection }"
            [icon_category]="'arrows'"
            [icon_name]="'arrow-square-' + arrow"
          >
          </app-custom-icon>
        </div>
        <p>
          {{
            'The arrows represent in which direction the chain steps can be connected. Right is default.' | translate
          }}
        </p>
      </div>
      <div *ngIf="!selectedProductChainUuid" class="image-section">
        <img src="{{ chainPlaceholderImage }}" />
      </div>
    </div>

    <div class="row products-section">
      <div class="col-6 subheader p-0">
        <h6 *ngIf="!selectedProductUuid">{{ 'Select product you want to trace' | translate }}</h6>
        <h6 *ngIf="selectedProductUuid">{{ 'Your selected product' | translate }}</h6>
      </div>

      <div class="col-12 p-0">
        <div class="row product-cards-wraper">
          <div class="col-3" *ngFor="let product of productsObservable$ | async; let i = index">
            <div
              [ngClass]="{
                'first-card-divider': i === 0 && selectedProductUuid === product.uuid && selectedProductChainUuid,
              }"
            >
              <app-image-card
                (click)="selectProduct(product)"
                [image]="product.image"
                [title]="product.name"
                [innerHTMLDescription]="product.description"
                [selected]="product.uuid === selectedProductUuid"
              >
              </app-image-card>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row pagination-section">
    <div class="footer-pagination">
      <mat-paginator [pageSizeOptions]="[8, 16, 24]" showFirstLastButtons></mat-paginator>
      <app-rounded-button
        color="turquoise"
        [button_text]="'Continue'"
        [icon_category]="'arrows'"
        [icon_name]="'arrow-right-long'"
        [icon_right]="true"
        [size]="'md'"
        [disabled]="form && !form.valid"
        (click)="selectedProductChainUuid ? duplicateProductChain() : createProductChain()"
      >
      </app-rounded-button>
    </div>
  </div>
</div>
