<div class="sidenav-container">
  <div class="logo">
    <img src="../../../../assets/images/symbol.svg" alt="" />
    <img src="../../../../assets/images/logo_type.svg" alt="" />
  </div>

  <div class="brand">
    <div class="brand-info d-flex flex-row not-showing">
      <img src="{{ brand?.logo ? brand.logo : '../../../../assets/images/brand-avatar.png' }}" />
      <p>{{ brand?.name }}</p>
    </div>
  </div>

  <div class="nav-content">
    <ul class="navigation-items">
      <li *ngIf="small_screen">
        <img src="../../../../assets/images/symbol.svg" alt="" />
      </li>
      <li *ngIf="small_screen">
        <div class="brand">
          <div class="brand-info d-flex flex-row">
            <img src="{{ brand?.logo ? brand.logo : '../../../../assets/images/brand-avatar.png' }}" />
          </div>
        </div>
      </li>
      <li
        *ngFor="let item of navList"
        [routerLink]="item.isDisabled ? null : item.url"
        [ngClass]="{ 'active-link': item.url | activeRoute, disabled: item.isDisabled }"
      >
        <app-custom-icon [icon_category]="item.category" [icon_name]="item.icon"></app-custom-icon>
        <h6>{{ item.label | translate }}</h6>
      </li>
    </ul>

    <div class="action" *ngIf="!isSupplierUser">
      <p class="label">{{ 'Start tracing your products' | translate }}</p>
      <app-rounded-button
        color="secondary"
        class="link"
        [button_text]="'Create Supply Chain'"
        [icon_category]="'essential'"
        [icon_name]="'add'"
        [icon_right]="true"
        [routerLink]="'/chain-setup'"
        [size]="'xd'"
      >
      </app-rounded-button>
    </div>

    <ul class="navigation-items" *ngIf="!isSupplierUser">
      <li [routerLink]="'settings'" routerLinkActive="active-link">
        <app-custom-icon [icon_category]="'settings'" [icon_name]="'setting-2'"></app-custom-icon>
        <h6>{{ 'Settings' | translate }}</h6>
      </li>
    </ul>
    <ul class="navigation-items need-help">
      <li (click)="needSupportDialog()" routerLinkActive="active-link">
        <app-custom-icon [icon_category]="'support'" [icon_name]="'support'"></app-custom-icon>
        <h6 class="text-decoration-underline">{{ 'supplier-menu.Need Help?' | translate }}</h6>
      </li>
    </ul>
    <ul class="navigation-items terms-conditions">
      <li (click)="navigationService.openTermAndCondition('/terms-and-conditions')" routerLinkActive="active-link">
        <h6>{{ 'PDNAs Terms and Conditions' | translate }}</h6>
      </li>
    </ul>
  </div>

  <div class="overlay-fixed" *ngIf="!isSupplierUser && !isOnSupplyChainsPage">
    <app-rounded-button
      color="primary"
      class="link"
      [button_text]="'Create Product Chain'"
      [icon_category]="'essential'"
      [icon_name]="'add'"
      [icon_right]="true"
      [routerLink]="'/chain-setup'"
    >
    </app-rounded-button>
  </div>
</div>
