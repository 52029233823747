<div class="task-card-wrap">
  <div class="task-card-header">
    <div class="task-name">
      {{ task.name }}
    </div>
    <div
      class="task-card-status"
      [ngClass]="{
        task_pending: task.status === 'TO DO',
        task_in_progress: task.status === 'IN PROGRESS',
        task_done: task.status === 'COMPLETED'
      }"
    >
      <div
        class="cyrcle"
        [ngClass]="{
          task_pending_c: task.status === 'TO DO',
          task_in_progress_c: task.status === 'IN PROGRESS',
          task_done_c: task.status === 'COMPLETED'
        }"
      ></div>
      <div class="task-stat">
        {{ task.status }}
      </div>
    </div>
  </div>

  <p class="headers">{{ 'created by' | translate }}</p>
  <div class="created-by">
    <div class="avatar">
      <img
        [src]="task.created_by.avatar ?? '../../../../../assets/images/image-placeholder.jpg'"
        alt="Author avatar"
        class="avatar"
      />
    </div>
    <div class="creator-info">
      <p class="creator-name">{{ task.created_by.username }}</p>
      <p class="creator-role">{{ task.created_by.role }}</p>
    </div>
  </div>
  <div class="description">
    <p class="headers">{{ 'task description' | translate }}</p>
    <p #desc_text>{{ description }}</p>
  </div>
  <div class="btn-wrap">
    <button mat-button (click)="openTaskDetails(task)" disableRipple>
      {{ 'see task details' | translate }}
      <app-custom-icon [icon_category]="'arrows'" [icon_name]="'arrow-right-long'"></app-custom-icon>
    </button>
  </div>
  <br />
  <br />
  <p class="headers">{{ 'related object' | translate }}</p>
  <div class="related-object">
    <p class="related-object-name">
      {{ reletedObj.name }}
    </p>
  </div>
  <div class="dates">
    <div class="start">
      {{ 'Start Date :' | translate }}
    </div>
    <div class="date start-d">{{ task.start_at | date : 'MM.dd.yyyy' }}</div>
    <div class="end">{{ 'End Date :' | translate }}</div>
    <div class="date end-d">
      {{ task.end_date ? (task.end_date | date : 'MM.dd.yyyy') : '/' }}
    </div>
  </div>
</div>
