import { ActivatedRoute, Router } from '@angular/router';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import {
  IResetPasswordConfirmErrorResponse,
  IResetPasswordConfirmResponse,
} from '@app/core/interface/forgot-password.interface';

import { Component } from '@angular/core';
import { ForgotPasswordFacade } from '@app/core/facade/forgot-password.facade';
import { NewPasswordGroup } from './new-password-group';
import { NotificationType } from '@app/core/constants';
import { SnackbarService } from '@app/core/service/snackbar.service';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-new-password',
  templateUrl: './new-password.component.html',
  styleUrls: ['./new-password.component.scss'],
  providers: [NewPasswordGroup],
})
export class NewPasswordComponent {
  hide_pass1 = true;
  hide_pass2 = true;
  form: UntypedFormGroup;
  small_screen = false;
  uuid!: string;

  constructor(
    private _newPasswordtGroup: NewPasswordGroup,
    private breakpointObserver: BreakpointObserver,
    private _facade: ForgotPasswordFacade,
    private _snackbarService: SnackbarService,
    private _route: ActivatedRoute,
    private _router: Router
  ) {
    this.form = this._newPasswordtGroup.newPasswordForm;
    this.detectScreen();
    const email = this._route.snapshot.queryParams['email'];
    this.uuid = this._route.snapshot.queryParams['uuid'];
    const uid = this._route.snapshot.paramMap.get('uid');
    const token = this._route.snapshot.paramMap.get('token');
    if (token && email && uid) {
      this.form.patchValue({
        uid,
        token,
      });
    }
  }

  resetPassword(): void {
    // Check if form is valid
    if (this.form.valid) {
      const formValue = this.form.value;
      const consentObject = {
        is_consent_apporved: formValue.is_consent_apporved,
      };
      const { new_password1, new_password2, uid, token } = formValue;
      const restOfValues = { new_password1, new_password2, uid, token };

      this._facade.setUserConsentAndResetPassword$(consentObject, this.uuid, restOfValues).subscribe({
        next: this._success.bind(this),
        error: this._error.bind(this),
      });
    }
  }

  private _success(data: IResetPasswordConfirmResponse): void {
    this._snackbarService.openTypeSnackbar(data.detail, NotificationType.success);
    sessionStorage.removeItem('resetPassword');
    this._router.navigate(['/password_reset/password-changed']);
  }

  private _error(error: IResetPasswordConfirmErrorResponse): void {
    Object.values(error).map(err => this._snackbarService.openTypeSnackbar(err[0], NotificationType.error));
  }

  // detect screen size changes
  detectScreen() {
    this.breakpointObserver.observe(['(max-width: 768px)']).subscribe((result: BreakpointState) => {
      this.small_screen = result.matches;
    });
  }
}
