import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Breadcrumb } from '@interface/header.interface';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class BreadcrumbHeaderService {
  /**
   * this behavior subject allows us to refresh the header from any other component that have this service when needed
   */
  public setHeaders: BehaviorSubject<Array<Breadcrumb>> = new BehaviorSubject<Array<Breadcrumb>>([
    { url: '', label: '' },
  ]);
  public setHeaders$ = this.setHeaders.asObservable();
  bindBreadCrumbs: Breadcrumb[] = [];

  constructor(private router: Router, public translate: TranslateService) {}
  /**
   * Depending on the this.router.url we are setting the header's urls and labels
   * @param refresh_headers we are setting this to true when from other component we want to set substring as value
   * @param sub_str_1 string that we want to use in the header as value (ex. name)
   * @returns array of objects that contain url and label text
   */
  setBreadCrumbs(refresh_headers?: boolean, sub_str_1?: string): Breadcrumb[] {
    this.bindBreadCrumbs = [];
    if (this.router.url.includes('/dashboard/product/')) {
      this.bindBreadCrumbs.push({
        url: '/products',
        label: 'Products Dashboard',
      });
      this.bindBreadCrumbs.push({
        url: '/products/list',
        label: 'Product List',
      });
      this.bindBreadCrumbs.push({
        url: '/dashboard/product/',
        label: '',
      });
    }
    if (this.router.url.includes('/supplier-management')) {
      this.bindBreadCrumbs.push({
        url: '/dashboard/supplier',
        label: 'Dashboard',
      });
      this.bindBreadCrumbs.push({
        url: '/dashboard/supplier/supplier-list',
        label: 'Supplier List',
      });
    }
    if (this.router.url.includes('questionnaire-details')) {
      this.bindBreadCrumbs.push({
        url: '/settings',
        label: 'Settings Dashboard',
      });
      this.bindBreadCrumbs.push({
        url: '/settings/questionnaires-management',
        label: 'Questionnaires management',
      });
      sub_str_1 &&
        this.bindBreadCrumbs.push({
          url: '/settings/questionnaires-management',
          label: sub_str_1,
        });
    }
    if (this.router.url.includes('/settings/translation')) {
      this.bindBreadCrumbs.push({
        url: '/settings',
        label: 'Settings',
      });
      this.bindBreadCrumbs.push({
        url: '/settings/translation',
        label: 'Manage Translations',
      });
    }
    if (this.router.url.includes('/settings/questionnaires-management/create')) {
      this.bindBreadCrumbs.push({
        url: '/settings',
        label: 'Settings Dashboard',
      });
      this.bindBreadCrumbs.push({
        url: '/settings/questionnaires-management',
        label: 'Questionnaires management',
      });
      this.bindBreadCrumbs.push({
        url: '/settings/questionnaires-management/create',
        label: 'Create Questionnaire',
      });
    }
    if (this.router.url.includes('/dashboard/tasks/all')) {
      this.bindBreadCrumbs.push({
        url: '/dashboard/tasks',
        label: 'Tasks Dashboard',
      });
      this.bindBreadCrumbs.push({
        url: '/dashboard/tasks/all',
        label: 'See all Tasks',
      });
    }
    if (this.router.url === '/dashboard/tasks') {
      this.bindBreadCrumbs.push({
        url: '/dashboard/tasks',
        label: 'Tasks Dashboard',
      });
    }
    if (this.router.url.includes('/chain-steps-info/edit-step/')) {
      this.bindBreadCrumbs.push({
        url: '/chain-steps-info',
        label: this.translate.instant('supply-chain-step.Supply Chains Steps'),
      });
      this.bindBreadCrumbs.push({
        url: '/chain-steps-info',
        label: this.translate.instant('supply-chain-step.Manage step'),
      });
    }
    if (this.router.url.includes('/chain-steps-info/view-step/')) {
      this.bindBreadCrumbs.push({
        url: '/chain-steps-info',
        label: this.translate.instant('supply-chain-step.Supply Chains Steps'),
      });
      this.bindBreadCrumbs.push({
        url: '/chain-steps-info',
        label: this.translate.instant('supply-chain-step.View step'),
      });
    }
    if (this.router.url.includes('products/projects')) {
      this.bindBreadCrumbs.push({
        url: '/products',
        label: 'Products Dashboard',
      });
      this.bindBreadCrumbs.push({
        url: '/products/projects',
        label: 'Projects List',
      });
      sub_str_1 &&
        this.bindBreadCrumbs.push({
          url: '/products/projects',
          label: sub_str_1,
        });
    }
    if (this.router.url.includes('settings/email-templates/modify')) {
      this.bindBreadCrumbs.push({
        url: '/settings',
        label: 'Settings Dashboard',
      });
      this.bindBreadCrumbs.push({
        url: '/settings/email-templates',
        label: 'Email Templates',
      });
      this.bindBreadCrumbs.push({
        url: '/settings/email-templates',
        label: 'Modifying Template',
      });
    }
    if (this.router.url.includes('dashboard/product-chains')) {
      this.bindBreadCrumbs.push({
        url: '/dashboard/product-chains',
        label: 'Supply Chains & Batches',
      });
    }
    switch (this.router.url) {
      case '/dashboard':
        this.bindBreadCrumbs.push({
          url: '/dashboard',
          label: 'Welcome',
        });
        break;
      case '/products':
        this.bindBreadCrumbs.push({
          url: '/products',
          label: 'Products Dashboard',
        });
        break;
      case '/products/list':
        this.bindBreadCrumbs.push({
          url: '/products',
          label: 'Products Dashboard',
        });
        this.bindBreadCrumbs.push({
          url: '/products/list',
          label: 'Product List',
        });
        break;
      case '/dashboard/supplier':
        this.bindBreadCrumbs.push({
          url: '/dashboard/supplier',
          label: 'Suppliers Dashboard',
        });
        break;
      case '/dashboard/supplier/supplier-list':
        // this.bindBreadCrumbs.push({
        //   url: '/dashboard',
        //   label: 'Dashboard',
        // });
        this.bindBreadCrumbs.push({
          url: '/dashboard/supplier/supplier-list',
          label: 'Supplier List',
        });
        break;
      case '/dashboard/certificates':
        // this.bindBreadCrumbs.push({
        //   url: '/dashboard/certificates',
        //   label: 'Certificates Dashboard',
        // });
        this.bindBreadCrumbs.push({
          url: '/dashboard/certificates',
          label: 'Certificates List',
        });
        break;
      case '/settings/certificate-management':
        this.bindBreadCrumbs.push({
          url: '/settings',
          label: 'Settings Dashboard',
        });
        this.bindBreadCrumbs.push({
          url: '/settings/certificate-management',
          label: 'Manage Certificate Type',
        });
        break;
      case '/settings/unit-management':
        this.bindBreadCrumbs.push({
          url: '/settings',
          label: 'Settings Dashboard',
        });
        this.bindBreadCrumbs.push({
          url: '/settings/unit-management',
          label: 'Unit management',
        });
        break;
      case '/settings/features-management':
        this.bindBreadCrumbs.push({
          url: '/settings',
          label: 'Settings Dashboard',
        });
        this.bindBreadCrumbs.push({
          url: '/settings/features-management',
          label: 'Features management',
        });
        break;
      case '/settings/component-management':
        this.bindBreadCrumbs.push({
          url: '/settings',
          label: 'Settings Dashboard',
        });
        this.bindBreadCrumbs.push({
          url: '/settings/component-management',
          label: 'Component management',
        });
        break;
      case '/settings/archived-objects-management':
        this.bindBreadCrumbs.push({
          url: '/settings',
          label: 'Settings Dashboard',
        });
        this.bindBreadCrumbs.push({
          url: '/settings/archived-objects-management',
          label: 'Archived Objects management',
        });
        break;
      case '/settings/billing-management':
        this.bindBreadCrumbs.push({
          url: '/settings',
          label: 'Settings Dashboard',
        });
        this.bindBreadCrumbs.push({
          url: '/settings/billing-management',
          label: 'Billing Management',
        });
        break;
      case '/settings/users-access':
        this.bindBreadCrumbs.push({
          url: '/settings',
          label: 'Settings Dashboard',
        });
        this.bindBreadCrumbs.push({
          url: '/settings/users-access',
          label: 'User Access',
        });
        break;
      case '/settings/user-settings':
        this.bindBreadCrumbs.push({
          url: '/settings',
          label: 'Settings',
        });
        this.bindBreadCrumbs.push({
          url: '/settings/user-settings',
          label: 'Users Management',
        });
        break;
      case '/settings/questionnaires-management':
        this.bindBreadCrumbs.push({
          url: '/settings',
          label: 'Settings Dashboard',
        });
        this.bindBreadCrumbs.push({
          url: '/settings/questionnaires-management',
          label: 'Questionnaires management',
        });
        break;
      case '/settings/brand-management':
        this.bindBreadCrumbs.push({
          url: '/settings',
          label: 'Settings',
        });
        this.bindBreadCrumbs.push({
          url: '/settings/brand-management',
          label: 'Brand Management',
        });
        break;
      case '/settings/address-management':
        this.bindBreadCrumbs.push({
          url: '/settings',
          label: 'Settings Dashboard',
        });
        this.bindBreadCrumbs.push({
          url: '/settings/brand-management',
          label: 'Brand Management',
        });
        break;
      case '/settings/languages':
        this.bindBreadCrumbs.push({
          url: '/settings',
          label: 'Settings Dashboard',
        });
        this.bindBreadCrumbs.push({
          url: '/settings/languages',
          label: 'Languages',
        });
        break;
      case '/settings/email-templates':
        this.bindBreadCrumbs.push({
          url: '/settings',
          label: 'Settings Dashboard',
        });
        this.bindBreadCrumbs.push({
          url: '/settings/email-templates',
          label: 'Email Templates',
        });
        break;
      case '/settings/add-fields':
        this.bindBreadCrumbs.push({
          url: '/settings',
          label: 'Settings',
        });
        this.bindBreadCrumbs.push({
          url: '/settings/add-fields',
          label: 'Add Custom Fields',
        });
        break;
      case '/settings':
        this.bindBreadCrumbs.push({
          url: '/settings',
          label: 'Settings Dashboard',
        });
        break;
      case '/chain-steps-info':
        this.bindBreadCrumbs.push({
          url: '/chain-steps-info',
          label: this.translate.instant('supply-chain-step.Supply Chains Steps'),
        });
        break;
      case '/company-info':
        this.bindBreadCrumbs.push({
          url: '/company-info',
          label: this.translate.instant('company-information.Company Information'),
        });
    }
    refresh_headers && this.setHeaders.next(this.bindBreadCrumbs);
    return this.bindBreadCrumbs;
  }
  /**
   * depending on this.router.url we are returning previous screen url
   * @returns string witch represent url to the previous screen, on many urls we just remove the last param in the url
   * for the rest we are using if statement
   */
  goBack(): string {
    if (this.router.url.includes('/dashboard/product/')) return '/products/list';
    if (this.router.url.includes('/dashboard/certificate/')) return '/dashboard/certificates';
    if (this.router.url.includes('supplier-management')) return '/dashboard/supplier/supplier-list';
    if (this.router.url.includes('dashboard/supplier/supplier-list')) return '/dashboard';
    if (
      this.router.url.includes('chain-steps-info/edit-step') ||
      this.router.url.includes('chain-steps-info/view-step')
    )
      return '/chain-steps-info';
    if (this.router.url.includes('settings/email-templates/modify/')) return '/settings/email-templates';
    return this.router.url.split('/').slice(0, -1).join('/');
  }
}
