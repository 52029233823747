import { ChangeDetectorRef, Component, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import {
  ICertificationProduct,
  IProduct,
  IProductCertificatesRespone,
  IProductResponse,
} from '@app/core/interface/products.interface';
import { NotificationType, infoDialogHeight, infoDialogWidth } from '@app/core/constants';

import { CertificateViewDetailsComponent } from '@app/shared/components/certificate-view-details/certificate-view-details.component';
import { CertificationDashboardComponent } from '../../certificates/certification-dashboard/certification-dashboard.component';
import { InfoDialogComponent } from '@app/shared/components/info-dialog/info-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Observable } from 'rxjs';
import { ProductsFacade } from '@app/core/facade/products.facade';
import { SnackbarService } from '@app/core/service/snackbar.service';

@Component({
  selector: 'app-product-certificates-grid',
  templateUrl: './product-certificates-grid.component.html',
  styleUrls: ['./product-certificates-grid.component.scss'],
})
export class ProductCertificatesGridComponent implements OnInit {
  @Input() id!: string;
  @Input() product!: IProduct | IProductResponse;
  @ViewChild(MatSort, { static: false }) sort!: MatSort;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  cardDataSource: MatTableDataSource<ICertificationProduct> = new MatTableDataSource<ICertificationProduct>();
  certificatesObservable$!: Observable<ICertificationProduct[]>;
  @Input() certification_product!: ICertificationProduct[];
  constructor(
    private _productsFacade: ProductsFacade,
    private _snackbarService: SnackbarService,
    private _dialog: MatDialog,
    private _changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.getProductCertificates();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['certification_product']) this.cardDataSource.data = changes['certification_product'].currentValue;
    this.certificatesObservable$ = this.cardDataSource.connect();
  }
  /**
   * Angular lifecycle hook after view checked we are setting data sort
   */
  ngAfterViewInit() {
    this.cardDataSource.sort = this.sort;
    this.cardDataSource.paginator = this.paginator;
    this.paginator.pageSize = 6;
    this._changeDetectorRef.detectChanges();
  }

  getProductCertificates() {
    this._productsFacade.getProductCertificates$(this.id).subscribe({
      next: this._getcertificatesSuccess.bind(this),
      error: this._error.bind(this),
    });
  }

  /**
   *
   * @param params after successful  fetching product certificates we are setting them in the grid
   */
  private _getcertificatesSuccess(params: IProductCertificatesRespone) {
    this.cardDataSource.data = params.results?.length ? this.mapResponse(params.results) : [];
    this.certificatesObservable$ = this.cardDataSource.connect();
  }

  /**
   *
   * @param error handles error form API using error message
   */
  private _error(error: Record<string, string[]>): void {
    Object.values(error).map(err => this._snackbarService.openTypeSnackbar(err[0], NotificationType.error));
  }

  /**
   *
   * @param list Grid data
   * @returns list of product certification mapped with new field expired
   */
  mapResponse(list: ICertificationProduct[]): ICertificationProduct[] {
    return list.map(res => {
      return {
        ...res,
        expired: res.validity_end ? this.expired(res.validity_end) : false,
      };
    });
  }

  /**
   *
   * @param date validity_end
   * @returns boolean comparating validity_end with date now
   */
  expired(date: string) {
    return new Date(date) < new Date();
  }

  /**
   *
   * @param element elements detail from element selected for change (we pass that detail to the dialog component)
   */
  editCertification(element: ICertificationProduct) {
    const dialogRef = this._dialog.open(CertificationDashboardComponent, {
      width: '1160px',
      height: '768px',
      panelClass: 'top-padding-0',
      data: {
        content_type: 'Product',
        uuid: element.product,
        element: element,
      },
      autoFocus: false,
    });
    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.getProductCertificates();
      }
    });
  }

  /**
   *
   * Delete certificate
   */
  deleteCertificate(certificate_uuid: string, name: string): void {
    const dialogRef = this._dialog.open(InfoDialogComponent, {
      width: infoDialogWidth,
      height: infoDialogHeight,
      data: {
        infoText: `Are you sure you want to delete certificate ${name}?`,
        confirmationText: 'Please Confirm',
        btnText: 'Yes, Delete',
        type: 'warning',
        text: 'warning-text',
      },
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this._productsFacade.deleteCertification$(this.id, certificate_uuid).subscribe({
          next: this.getProductCertificates.bind(this),
          error: this._error.bind(this),
        });
      }
    });
  }

  openViewFile(certificate: ICertificationProduct) {
    const name = certificate?.document?.split('/').at(-1);
    this._dialog.open(CertificateViewDetailsComponent, {
      width: '90%',
      maxHeight: '90%',
      data: {
        url: certificate.document,
        name,
        mimetype: name,
        certificate,
        productName: this.product.name,
        logo: this.product.image,
      },
    });
  }
}
