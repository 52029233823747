<div class="container-fluid">
  <div class="row container-inside">
    <div class="col-md-6 left p0">
      <!-- Illustration-design cpomponent witch can be used multiple times only with diferent input color -->
      <app-illustration-design color="primary"></app-illustration-design>
    </div>
    <div class="col-md-6 right">
      <div class="content">
        <div class="text-inputs-button mb-0">
          <div class="text-box-inputs mb-0">
            <div class="title-box">
              <h2 class="mb-0">{{ 'account-setup.New password added!' | translate }}</h2>
              <h5 class="mb-0">{{ 'account-setup.You have successfully reset your password.' | translate }}</h5>
              <app-custom-icon [icon_category]="'user'" [icon_name]="'profile-tick'"></app-custom-icon>
            </div>
          </div>
          <div class="icon-holder">
            <div class="icon-circle">
              <app-custom-icon [icon_category]="'security'" [icon_name]="'lock-circle'"></app-custom-icon>
            </div>
          </div>

          <div class="bottom-line"></div>
          <!-- <div class="bottom-area"> -->
          <div class="text-box">
            <h4 class="link mb-0">
              {{ 'account-setup.Continue to the log in page with your newly added password.' | translate }}
            </h4>
            <h4 class="link-sm mb-0">
              {{ 'account-setup.Contunie to the log in page, and' | translate }} <br />
              {{ 'account-setup.use your' | translate }} <br />
              {{ 'account-setup.newly added password.' | translate }}
            </h4>
          </div>
          <div class="back-btn">
            <app-rounded-button
              color="turquoise"
              [button_text]="'account-setup.Continue' | translate"
              [icon_category]="'arrows'"
              [icon_name]="'arrow-right'"
              [icon_right]="true"
              [routerLink]="'/login'"
            ></app-rounded-button>
          </div>

          <!-- </div> -->
        </div>
      </div>
    </div>
  </div>
</div>
