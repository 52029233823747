<div class="table-wraper">
  <div class="mat-elevation-z8">
    <table mat-table matSort [dataSource]="dataSource">
      <ng-container matColumnDef="brand_name">
        <th mat-header-cell *matHeaderCellDef>Brand name</th>
        <td mat-cell *matCellDef="let brand">{{ brand.brand.name }}</td>
      </ng-container>

      <ng-container matColumnDef="assigned_csm_user">
        <th mat-header-cell *matHeaderCellDef class="text-center">Assigned CSM user</th>
        <td mat-cell *matCellDef="let brand" class="text-center">
          <mat-form-field appearance="fill">
            <mat-label>Select user</mat-label>
            <mat-select [value]="brand.user.uuid" (selectionChange)="onUserChanged($event.value, brand.brand.uuid)">
              <mat-option *ngFor="let user of csmUsers" [value]="user.uuid" placeholder="User">
                {{ user.first_name }} {{ user.last_name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons aria-label="Select page of brands">
    </mat-paginator>
  </div>
</div>
