<div class="project-wrapper">
  <app-project-details [project]="project" (onUpdateSuccess)="updateProject()"></app-project-details>
  <mat-tab-group [selectedIndex]="selectedIndex">
    <mat-tab [label]="'Products Traced' | translate">
      <ng-template matTabContent>
        <app-project-products-traced
          [allProducts]="allProducts"
          [project]="project"
          (onUpdateSuccess)="updateProject()"
          (onUpdateProductSuccess)="getProducts()"
        ></app-project-products-traced>
      </ng-template>
    </mat-tab>
    <mat-tab [label]="'Sub-Projects' | translate">
      <ng-template matTabContent>
        <app-project-subprojects
          [projectId]="project.uuid"
          [allSubprojects]="subprojects"
          (onUpdateSubproject)="updateProject()"
        ></app-project-subprojects>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</div>
