<div class="comp-info-wrap">
  <div class="comp-info-header cursor-pointer" *ngIf="hideDetails" (click)="hideDetails = false">
    <img src="../../../../assets/images/symbol.svg" alt="Symbol" />
    <span class="more-details">
      {{ 'company-information.More Details' | translate }}
    </span>
  </div>
  <div class="comp-info-header-det" *ngIf="!hideDetails">
    <div class="detail-txt">
      <img src="../../../../assets/images/symbol-blue.svg" alt="Symbol" height="45" width="45" />
      <span class="more-details">
        {{ 'company-information.Here you can manage your company information' | translate }}
      </span>
    </div>
    <app-custom-icon
      class="close-icon cursor-pointer cls-more-details"
      icon_category="essential"
      icon_name="close-circle"
      (click)="hideDetails = true"
    ></app-custom-icon>
  </div>

  <div class="cont">
    <div *ngIf="!company.info_validated && company.info_validated_on !== 'initial'" class="full-width">
      <div class="row validate-info">
        <div class="col-1">
          <img
            src="../../../../assets/images/symbol-blue.svg"
            alt="Symbol"
            height="45"
            width="45"
            class="align-mid-p"
          />
        </div>
        <div class="col-11 mid-wrap p1-strong">
          <div class="align-mid">
            {{
              'company-information.For your correct identification, please check first and update your company info, then
            click on validate so
            you can proceed with updating other information.' | translate
            }}
          </div>
        </div>
      </div>
      <div class="row min-h">
        <div class="validate-warn">
          <div class="red-cir"></div>
          <span class="info-not-v">
            {{ 'company-information.Information not validated yet' | translate }}
          </span>
        </div>
      </div>
    </div>
    <div class="row min-h" *ngIf="company.info_validated && company.info_validated_on !== 'initial'">
      <div class="validate-warn">
        <div class="green-cir"></div>
        <span class="validationdate">
          {{
            ('company-information.Information validated on' | translate) +
              ' ' +
              (company?.info_validated_on | date: 'dd.MM.yyyy')
          }}
        </span>
      </div>
    </div>
    <div class="row">
      <!--logo-->
      <div class="col-6">
        <div class="ic-wrap">
          <img
            [src]="company.logo ? company.logo : '../../../assets/images/upload-placeholder-blue.svg'"
            alt="Logo"
            class="ic-wrap logo-b"
          />
        </div>
        <div class="cont-tit p1-strong">
          {{ 'company-information.Logo' | translate }}
          <app-custom-icon
            [icon_category]="'files'"
            [icon_name]="'edit'"
            class="cursor-pointer edit-ic"
            (click)="openImageEditDialg()"
          ></app-custom-icon>
          <p class="upload-hint">{{ 'png.jpg.svg / maximum 5MB' | translate }}</p>
        </div>
      </div>
      <!--Name-->
      <div class="col-6">
        <span class="ic-wrap">
          <app-custom-icon [icon_category]="'buildings'" [icon_name]="'buildings-2'" class="add"></app-custom-icon>
        </span>
        <div class="cont-tit p1-strong">
          {{ 'company-information.Name' | translate }}
          <app-custom-icon
            [icon_category]="'files'"
            [icon_name]="'edit'"
            class="cursor-pointer edit-ic"
            (click)="onEdit('name', company.name, true, 255)"
          ></app-custom-icon>
        </div>
        <div class="val">
          {{ company.name }}
        </div>
      </div>
    </div>
    <hr />
    <div class="row">
      <!--Description-->
      <div class="col-6">
        <span class="ic-wrap">
          <app-custom-icon [icon_category]="'business'" [icon_name]="'personalcard'"></app-custom-icon>
        </span>
        <div class="cont-tit p1-strong">
          {{ 'company-information.Description' | translate
          }}<app-custom-icon
            [icon_category]="'files'"
            [icon_name]="'edit'"
            class="cursor-pointer edit-ic"
            (click)="onEdit('description', company.description, false)"
          ></app-custom-icon>
        </div>
        <div
          [ngClass]="{ 'val-desc': true, expanded: isDescriptionExpanded }"
          *ngIf="company.description; else not_entered_yet"
        >
          <!-- {{ company.description }} -->
          <app-view-rich-text [content]="company.description"></app-view-rich-text>
        </div>

        <div class="btn-wrp-see-more" *ngIf="showSeeMoreButton">
          <button mat-button (click)="toggleDescriptionExpansion()" disableRipple>
            {{
              isDescriptionExpanded
                ? ('company-information.Read less' | translate)
                : ('company-information.Read more' | translate)
            }}
            <app-custom-icon
              [icon_category]="'arrows'"
              [icon_name]="isDescriptionExpanded ? 'arrow-up-2' : 'arrow-down-2'"
            ></app-custom-icon>
          </button>
        </div>
      </div>
      <!--Company unique identifier-->
      <div class="col-6">
        <span class="ic-wrap">
          <app-custom-icon [icon_category]="'essential'" [icon_name]="'unique-id'"></app-custom-icon>
        </span>
        <div class="cont-tit p1-strong">
          {{ 'company-information.Company Unique Identifier' | translate }}<span class="required"> *</span
          ><app-custom-icon
            [icon_category]="'files'"
            [icon_name]="'edit'"
            class="cursor-pointer edit-ic"
            (click)="onEdit('supplier_unique_identifier', company.supplier_unique_identifier, false)"
          ></app-custom-icon>
        </div>
        <div *ngIf="company.supplier_unique_identifier || company.unique_identifier_type; else not_entered_yet">
          <div class="val">
            <span class="p2-strong">{{ 'Number' | translate }}: </span> {{ company.supplier_unique_identifier }}
          </div>
          <div class="val">
            <span class="p2-strong">{{ 'Type' | translate }}: </span> {{ company.unique_identifier_type }}
          </div>
        </div>
      </div>
    </div>
    <hr />
    <!--Main Adress -->
    <div class="row">
      <div class="col-12">
        <span class="ic-wrap">
          <app-custom-icon [icon_category]="'location'" [icon_name]="'map'"></app-custom-icon>
        </span>
        <div class="cont-tit p1-strong">
          {{ 'company-information.Main Address' | translate
          }}<app-custom-icon
            [icon_category]="'files'"
            [icon_name]="'edit'"
            class="cursor-pointer edit-ic"
            (click)="onEdit('address', 'address', true)"
          ></app-custom-icon>
        </div>
        <div class="val" *ngIf="mainAddress; else not_entered_yet">
          <span *ngIf="mainAddress?.country">{{ mainAddress?.country }}</span>
          <span *ngIf="mainAddress?.region">, {{ mainAddress?.region }}</span>
          <span *ngIf="mainAddress?.city">, {{ mainAddress?.city }}</span>
          <span *ngIf="mainAddress?.address">, {{ mainAddress?.address }}</span>
        </div>
      </div>
    </div>
    <hr *ngIf="company.info_validated && company.info_validated_on !== 'initial'" />
    <!--Sites-->
    <div class="row" *ngIf="company.info_validated && company.info_validated_on !== 'initial'">
      <div class="col-12 d-flex align-items-center">
        <span class="ic-wrap">
          <app-custom-icon [icon_category]="'buildings'" [icon_name]="'factory'"></app-custom-icon>
        </span>
        <div class="cont-tit p1-strong sites-tit">
          <div class="pull-left com-txt d-flex align-items-center">
            {{ 'company-information.Other Company Sites' | translate }}
          </div>

          <div class="filter-info" *ngIf="company.manufactories && company.manufactories.length">
            <mat-form-field class="input-primary mat-form-field-10-px-brd-rd" appearance="outline" floatLabel="auto">
              <mat-label class="label-text">{{ 'company-information.Quick search...' | translate }}</mat-label>
              <input
                class="placeholder"
                matInput
                (keyup)="applyFilter($event)"
                [placeholder]="'company-information.Quick search...' | translate"
                #input
              />
              <app-custom-icon
                class="search-filter"
                [icon_category]="'search'"
                [icon_name]="'search-normal-2'"
              ></app-custom-icon>
            </mat-form-field>
          </div>

          <app-rounded-button
            class="add-site"
            color="primary"
            [button_text]="'company-information.Add Sites' | translate"
            [icon_category]="'buildings'"
            [icon_name]="'factory'"
            [icon_right]="true"
            size="sm"
            (click)="addSite()"
          >
          </app-rounded-button>
        </div>
      </div>
      <div class="col-12" *ngIf="company.manufactories && company.manufactories.length; else not_entered_site">
        <app-supplier-sites-table
          [sites]="company.manufactories"
          (refresh)="$event && getInfo()"
        ></app-supplier-sites-table>
      </div>
    </div>
    <!--Validate button -->
    <div *ngIf="!company.info_validated && company.info_validated_on !== 'initial'" class="col-12">
      <hr />
      <div class="row">
        <app-rounded-button
          color="turquoise"
          size="md"
          [button_text]="'company-information.Validate' | translate"
          [disabled]="!company.supplier_unique_identifier || !company.unique_identifier_type"
          (click)="validate()"
          class="validate-btn"
        ></app-rounded-button>
      </div>
    </div>
  </div>
  <!--Not entered yet-->
  <ng-template #not_entered_yet>
    <div class="not-set">
      {{ 'company-information.Not Entered Yet' | translate }}
    </div>
  </ng-template>
  <ng-template #not_entered_site>
    <div class="not-set site">
      <div class="logo"></div>
      {{ 'company-information.Not Entered Yet' | translate }}
    </div>
  </ng-template>
</div>
