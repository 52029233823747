import { RouterModule, Routes } from '@angular/router';

import { ChainStepsInfoComponent } from './chain-steps-info.component';
import { EditStepComponent } from './edit-step/edit-step.component';
import { NgModule } from '@angular/core';
import { ConfirmGuard } from '@app/core/guard/confirm.guard';

const routes: Routes = [
  { path: '', component: ChainStepsInfoComponent },
  {
    path: 'edit-step/:id',
    component: EditStepComponent,
    canDeactivate: [ConfirmGuard],
  },
  {
    path: 'view-step/:id',
    component: EditStepComponent,
    canDeactivate: [ConfirmGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ChainStepsInfoRoutingModule {}
