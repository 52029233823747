<div class="container-fluid">
  <div class="row container-inside">
    <div class="col-md-6 left p-0">
      <app-illustration-design [color]="'primary'"></app-illustration-design>
    </div>
    <div class="col-md-6 right row justify-content-center">
      <div class="col-md-10 content row align-content-center">
        <div class="col-md-8 box mb-0 p-0 row flex-nowrap">
          <div class="flex-nowrap" *ngIf="supplierEmail">
            <div class="supplier-greeting-h">{{ 'account-setup.Welcome to Respect-SaaS' | translate }} <br /></div>
            <h6 class="supplier-greeting-txt">
              {{
                'account-setup.Welcome to our traceability platform, where you can contribute in achieving end-to-end traceability.'
                  | translate
              }}
            </h6>
          </div>
          <h2 *ngIf="!supplierEmail">{{ 'account-setup.Create account' | translate }}</h2>

          <form class="row p-0" [formGroup]="form">
            <mat-form-field
              class="input-primary field-box"
              appearance="outline"
              *ngIf="!supplierEmail; else invitedEmail"
            >
              <mat-label>{{ 'account-setup.Email' | translate }}</mat-label>
              <input matInput required formControlName="email" />
              <mat-error *ngIf="form.controls['email'].hasError('required')">
                {{ 'account-setup.Email is required.' | translate }}</mat-error
              >
              <mat-error *ngIf="form.controls['email'].hasError('pattern')">
                {{ 'account-setup.Email is invalid.' | translate }}</mat-error
              >
            </mat-form-field>
            <h4>{{ 'account-setup.Setup your account' | translate }}</h4>
            <h6 *ngIf="supplierEmail" class="your-mail">{{ 'account-setup.Enter a username' | translate }}</h6>
            <mat-form-field
              class="input-primary field-box"
              appearance="outline"
              *ngIf="form.controls['username'] || supplierEmail"
            >
              <mat-label>{{ 'account-setup.Enter username' | translate }}</mat-label>
              <input
                type="text"
                autocomplete="off"
                matInput
                formControlName="username"
                placeholder="{{ 'account-setup.Enter username' | translate }}"
                required
              />
              <mat-error *ngIf="form.controls['username'].hasError('required')">
                {{ 'account-setup.Username is' | translate }}
                <span>{{ 'account-setup.required' | translate }}</span></mat-error
              >
            </mat-form-field>

            <!-- Password -->
            <h6 *ngIf="supplierEmail" class="your-mail">{{ 'account-setup.Configure your password' | translate }}</h6>

            <section class="d-flex password-section">
              <mat-form-field class="input-primary field-box" appearance="outline">
                <mat-label>{{ 'account-setup.Enter password' | translate }}</mat-label>
                <input
                  [ngClass]="hide ? 'autocompleteOff' : ''"
                  autocomplete="new-password"
                  name="one-time-code"
                  id="one-time-code"
                  matInput
                  formControlName="password1"
                  [type]="hide ? 'search' : 'text'"
                />
                <app-custom-icon
                  class="show-hide-pass"
                  [icon_category]="'security'"
                  [icon_name]="hide ? 'eye-slash' : 'eye'"
                  (click)="hide = !hide"
                ></app-custom-icon>
                <mat-error *ngIf="form.controls['password1'].hasError('required')">
                  {{ 'account-setup.Password is' | translate }}
                  <span>{{ 'account-setup.required' | translate }}</span></mat-error
                >
                <div
                  class="pass-field-message"
                  *ngIf="
                    form.controls['password1'].hasError('minlength') &&
                    form.controls['password1'].getError('minlength').actualLength <
                      form.controls['password1'].getError('minlength').requiredLength
                  "
                >
                  <span class="weak-pass-circle"></span>
                  {{ 'Weak password, use at least 8 characters.' | translate }}
                </div>
                <div
                  *ngIf="form.controls['password1'].value?.length > 7 && form.controls['password1'].value?.length < 12"
                  class="pass-field-message"
                >
                  <span class="fair-pass-circle"></span>
                  {{ 'Fair password, use 12 characters to make it stronger.' | translate }}
                </div>
                <div *ngIf="form.controls['password1'].value?.length > 11" class="pass-field-message">
                  <span class="strong-pass-circle"></span>
                  {{ 'account-setup.strong password, you are good to go' | translate }}
                </div>
              </mat-form-field>
              <app-custom-icon
                class="indicator"
                [icon_category]="'security'"
                [icon_name]="'lock-2'"
                [matTooltip]="'account-setup.Your password is securely encrypted.' | translate"
                matTooltipClass="custom-tooltip-center"
                matTooltipPosition="above"
              ></app-custom-icon>
            </section>

            <!-- Confirm password -->
            <section class="d-flex password-section">
              <mat-form-field class="input-primary field-box" appearance="outline">
                <mat-label>{{ 'account-setup.Confirm password' | translate }}</mat-label>
                <input
                  [ngClass]="hide ? 'autocompleteOff' : ''"
                  name="pass-2"
                  [autocomplete]="hide ? 'new-password' : 'new-pass-2'"
                  matInput
                  formControlName="password2"
                  [type]="hide ? 'search' : 'text'"
                />
                <app-custom-icon
                  class="show-hide-pass"
                  [icon_category]="'security'"
                  [icon_name]="hide ? 'eye-slash' : 'eye'"
                  (click)="hide = !hide"
                ></app-custom-icon>
                <mat-error *ngIf="form.controls['password2'].hasError('required')">
                  {{ 'account-setup.Please confirm your password.' | translate }}
                </mat-error>
                <mat-error *ngIf="form.controls['password2'].hasError('mustMatch')">
                  {{ 'account-setup.Passwords do not match' | translate }}</mat-error
                >
              </mat-form-field>
              <app-custom-icon
                class="indicator"
                [icon_category]="'security'"
                [icon_name]="'lock-2'"
                [matTooltip]="'account-setup.Your password is securely encrypted.' | translate"
                matTooltipClass="custom-tooltip-center"
                matTooltipPosition="above"
              ></app-custom-icon>
            </section>

            <mat-checkbox class="checkbox-primary" [formControl]="$any(form.controls['is_consent_apporved'])">
              <p>
                {{ 'account-setup.by creating an account, you agree with' | translate }}
                <span class="policy-link" [routerLink]="'/terms-and-conditions'">{{
                  'account-setup.PDNAs Terms and Conditions' | translate
                }}</span>
              </p>
            </mat-checkbox>

            <app-rounded-button
              color="primary"
              button_text="{{ supplierEmail ? ('account-setup.Next' | translate) : ('Create account' | translate) }}"
              [icon_category]="'user'"
              [icon_name]="'user-tick'"
              [icon_right]="true"
              [disabled]="form.invalid || form.pristine"
              (click)="createAccount()"
            >
            </app-rounded-button>
          </form>
        </div>

        <div class="col-12 divider-holder">
          <mat-divider></mat-divider>
        </div>

        <div class="col-md-8 box mb-0 p-0 row">
          <h3>{{ 'account-setup.Already have an account setup?' | translate }}</h3>

          <app-rounded-button
            color="secondary"
            [button_text]="'account-setup.Log in'"
            [icon_category]="'arrows'"
            [icon_name]="'login-2'"
            [icon_right]="true"
            (click)="logout()"
          >
          </app-rounded-button>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #invitedEmail>
  <h6 class="your-mail">
    {{ 'account-setup.Your email' | translate }}
  </h6>
  <div class="supplier-email">
    {{ supplierEmail }}
  </div>
  <div class="col-12 divider-holder">
    <mat-divider></mat-divider>
  </div>
</ng-template>
