import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AddFieldsRoutingModule } from './add-fields-routing.module';
import { AddFieldsComponent } from './add-fields.component';
import { AngularMaterialModule } from '../../material.module';
import { SharedModule } from '../../shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CreateCustomFieldComponent } from './create-custom-field/create-custom-field.component';
import { OptionRemoveErrorComponent } from './create-custom-field/option-remove-error/option-remove-error.component';

@NgModule({
  declarations: [AddFieldsComponent, CreateCustomFieldComponent, OptionRemoveErrorComponent],
  imports: [
    CommonModule,
    AddFieldsRoutingModule,
    AngularMaterialModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
  ],
})
export class AddFieldsModule {}
