<div class="row recent-suppliers-wrapper">
  <mat-card
    appearance="outlined"
    class="supplier-card col-12"
    *ngFor="let supplier of suppliers"
    [routerLink]="['/supplier-management', supplier.entity_uuid]"
  >
    <mat-card-header class="supplier-name">
      {{ supplier?.entity_name }}
    </mat-card-header>
    <mat-card-content class="preview-container">
      <img
        [src]="supplier?.entity_logo ? supplier?.entity_logo : '../../../assets/images/image-placeholder.jpg'"
        [alt]="supplier?.entity_name"
        class="preview"
      />
    </mat-card-content>
  </mat-card>
</div>
