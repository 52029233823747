<div class="title-wrap">
  <h2 class="title">{{ 'Task Details' | translate }}</h2>
  <div class="close-d">
    <app-custom-icon [icon_category]="'essential'" [icon_name]="'close-circle'" mat-dialog-close></app-custom-icon>
  </div>
</div>
<div class="task-card-wrap">
  <div class="task-card-header">
    <div class="task-name">
      {{ data.name }}
    </div>
    <div
      class="task-card-status"
      [ngClass]="{
        task_pending: data.status === 'TO DO',
        task_in_progress: data.status === 'IN PROGRESS',
        task_done: data.status === 'COMPLETED',
      }"
    >
      <div
        class="cyrcle"
        [ngClass]="{
          task_pending_c: data.status === 'TO DO',
          task_in_progress_c: data.status === 'IN PROGRESS',
          task_done_c: data.status === 'COMPLETED',
        }"
      ></div>
      <div class="task-stat">
        {{ data.status }}
      </div>
    </div>
  </div>
  <hr />

  <p class="headers">{{ 'Created by' | translate }}</p>
  <div class="created-by">
    <div class="avatar">
      <img
        [src]="data.created_by.avatar ?? '../../../../../assets/images/image-placeholder.jpg'"
        alt="Author avatar"
        class="avatar"
      />
    </div>
    <div class="creator-info">
      <p class="creator-name">{{ data.created_by.username }}</p>
      <p class="creator-role">{{ data.created_by.role }}</p>
    </div>
  </div>
  <div class="description">
    <p class="headers headers-d">{{ 'Task Description' | translate }}</p>
    <p class="desc">{{ data.description }}</p>
  </div>
  <div>
    <p class="headers">{{ 'Related Object' | translate }}</p>

    <div class="related-object">
      <div class="avatar avatar-ro">
        <img
          [src]="data.related_obj?.avatar ?? '../../../../../assets/images/image-placeholder.jpg'"
          alt="Author avatar"
          class="avatar"
        />
      </div>
      <p class="related-object-name">
        <ng-container *ngIf="data.content_object.supplier && data.content_object.supplier.length">
          {{ data.content_object.supplier[0].name }}
        </ng-container>
        <ng-container *ngIf="data.content_object.product_chain && data.content_object.product_chain.length">
          {{ data.content_object.product_chain[0].name }}
        </ng-container>
        <ng-container *ngIf="data.content_object.product && data.content_object.product.length">
          {{ data.content_object.product[0].name }}
        </ng-container>
        <ng-container *ngIf="data.content_object.step && data.content_object.step.length">
          {{ data.content_object.step[0].name }}
        </ng-container>
      </p>
    </div>
    <hr />
    <div class="dates">
      <div class="start">
        {{ 'Start Date :' | translate }}
      </div>
      <div class="date start-d">{{ data.start_at ? (data.start_at | date: 'MM/dd/yyyy') : '/' }}</div>
      <div class="end">{{ 'End Date :' | translate }}</div>
      <div class="date end-d">
        {{ data.end_date ? (data.end_date | date: 'MM/dd/yyyy') : '/' }}
      </div>
    </div>
  </div>
</div>
