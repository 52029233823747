import { Component, OnInit } from '@angular/core';
import { FIRST_LOGIN, NotificationType, USER } from '@app/core/constants';

import { CookiesBannerComponent } from '@app/shared/components/cookies-banner/cookies-banner.component';
import { FirstProductInfoDialogComponent } from '../first-product-info-dialog/first-product-info-dialog.component';
import { IDashboardDefaultInfo } from '@app/core/interface/dashboard.interface';
import { MatDialog } from '@angular/material/dialog';
import { ProductsFacade } from '@app/core/facade/products.facade';
import { Router } from '@angular/router';
import { SnackbarService } from '@app/core/service/snackbar.service';

@Component({
  selector: 'app-dashboard-overview',
  templateUrl: './dashboard-overview.component.html',
  styleUrls: ['./dashboard-overview.component.scss'],
  providers: [ProductsFacade],
})
export class DashboardOverviewComponent implements OnInit {
  firstLogin!: boolean;
  dashboardInfo!: IDashboardDefaultInfo;

  constructor(
    public dialog: MatDialog,
    private _productsFacade: ProductsFacade,
    private _snackbarService: SnackbarService,
    private _router: Router
  ) {
    const storageUser = localStorage.getItem(USER);
    const user = storageUser ? JSON.parse(storageUser) : '';
    if (localStorage.getItem(FIRST_LOGIN) === 'false') {
      localStorage.removeItem(FIRST_LOGIN);
      this.firstLogin = false;
    }
    const alreadyLoged = JSON.parse(localStorage.getItem(FIRST_LOGIN) ?? '[]');
    if (!alreadyLoged.includes(user.uuid)) {
      if (user) {
        alreadyLoged.push(user.uuid);
      }
      localStorage.setItem(FIRST_LOGIN, JSON.stringify(alreadyLoged));
      if (!user.is_supplier) this.firstLogin = true;
    } else {
      this.firstLogin = false;
    }
    if (user.is_supplier) {
      this._router.navigate(['/overview']);
    }
  }

  ngOnInit(): void {
    this.getDashboardDefault();
  }

  getDashboardDefault(): void {
    this._productsFacade.getDashboardDefault$().subscribe({
      next: this._getDashboardSuccess.bind(this),
      error: this._error.bind(this),
    });
  }

  _getDashboardSuccess(data: IDashboardDefaultInfo): void {
    if (data) {
      this.dashboardInfo = data;
      if (this.firstLogin) {
        this.showCookiesBanner();
      }
    }
  }

  private _error(error: Record<string, string>): void {
    Object.values(error).map(err => {
      this._snackbarService.openTypeSnackbar(err, NotificationType.error);
    });
  }

  createProductDialog() {
    this.dialog.open(FirstProductInfoDialogComponent, {
      panelClass: 'first-product-dialog',
      data: 'first product',
    });
  }

  showCookiesBanner(): void {
    const dialog = this.dialog.open(CookiesBannerComponent, {
      width: '1215px',
      disableClose: true,
      autoFocus: false,
      panelClass: 'padding-0',
      position: { bottom: '32px' },
    });

    dialog.afterClosed().subscribe(() => {
      this.createProductDialog();
    });
  }

  getLineClass(length: number | undefined): string {
    if (length !== undefined && length <= 8) {
      return 'long';
    } else if (length !== undefined && length > 8 && length <= 99) {
      return 'short';
    } else if (length !== undefined && length >= 100) {
      return 'extra-short';
    } else {
      return '';
    }
  }
}
