<div class="background" *ngIf="questionnaireDetails">
  <div class="questionnaires-view">
    <mat-card class="questionnaires-wrapper">
      <div class="header-wrapper">
        <mat-card class="options-header">
          <div class="questionnaires-header">
            <div class="questionnaires-heading">
              <div class="row">
                <div class="col-1 d-flex align-items-center">
                  <p>{{ questionnaireDetails.name }}</p>
                  <app-custom-icon
                    matTooltip="{{ 'Edit Name' | translate }}"
                    matTooltipClass="custom-tooltip above"
                    matTooltipPosition="above"
                    [icon_category]="'files'"
                    [icon_name]="'edit-2'"
                    (click)="editName(questionnaireDetails.name)"
                  >
                  </app-custom-icon>
                </div>
                <div class="midle-line"></div>
                <div class="col-3 d-flex align-items-center">
                  <p>{{ questionnaireDetails.sections.length }} {{ 'Questionnaire sections' | translate }}</p>
                </div>
                <div class="midle-line-1"></div>
                <div class="col-2 d-flex align-items-center">
                  <p>{{ getQuestionsCount() }} {{ 'Questions' | translate }}</p>
                </div>
                <div class="col-2 offset-2">
                  <div class="icon">
                    <app-custom-icon
                      [icon_category]="'weather'"
                      [icon_name]="icon"
                      [matTooltip]="icon === 'saving' ? 'changes not saved yet' : 'All changes are saved'"
                      matTooltipClass="custom-tooltip below"
                      [matTooltipPosition]="'below'"
                    >
                    </app-custom-icon>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </mat-card>
      </div>
    </mat-card>
  </div>
  <div class="questionnaires-view">
    <mat-card class="questionnaires-wrapper">
      <div class="header-wrapper">
        <div class="section-div">
          <div class="left">
            <p>{{ 'Sections' | translate }}</p>
          </div>
          <div class="right d-flex">
            <div class="info d-flex justify-content-evenly">
              <p>filter</p>
              <app-custom-icon
                class="search-filter"
                [icon_category]="'essential'"
                [icon_name]="'filter-add'"
              ></app-custom-icon>
            </div>
            <div class="midle-line-2"></div>
            <div class="info text-center"><p>not started (0)</p></div>
            <div class="midle-line-2"></div>
            <div class="info text-center"><p>drafts (0)</p></div>
            <div class="midle-line-2"></div>
            <div class="info text-center"><p>started (0)</p></div>
          </div>
        </div>
        <ng-container *ngFor="let section of questionnaireDetails.sections">
          <app-section-question
            [section]="section"
            [questionnaireUuid]="questionnaireDetails.uuid"
          ></app-section-question>
        </ng-container>
      </div>
    </mat-card>
  </div>
</div>
