import { RouterModule, Routes } from '@angular/router';

import { AddFieldsModule } from '../add-fields/add-fields.module';
import { AddressManagementModule } from '../address-management/address-management.module';
import { BillingManagementModule } from '../billing-management/billing-management.module';
import { BrandManagementModule } from '../brand-management/brand-managment.module';
import { LanguagesModule } from '../languages/languages.module';
import { NgModule } from '@angular/core';
import { SettingsComponent } from './settings.component';
import { UserSettingsModule } from '../user-settings/user-settings.module';
import { UsersAccessModule } from '../users-access/users-access.module';
import { EmailTemplatesModule } from './email-templates/email-templates.module';
import { CertificateManagementModule } from '../certificate-management/certificate-management.module';
import { UnitManagementModule } from '../unit-management/unit-management.module';
// import { ComponentManagementModule } from '../component-management/component-management.module';
import { FeaturesManagementModule } from '../features-management/features-management.module';
import { QuestionnairesManagementModule } from '@module/questionnaires-management/questionnaires-management.module';
import { ArchivedObjectsManagementModule } from '../archived-objects-management/archived-objects-management.module';
import { AuthGuard } from '@app/core/guard/auth.guard';
import { TranslationtModule } from '../translation/translation.module';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        component: SettingsComponent,
      },
      {
        path: 'user-settings',
        loadChildren: () => UserSettingsModule,
      },
      {
        path: 'billing-management',
        loadChildren: () => BillingManagementModule,
      },
      {
        path: 'users-access',
        loadChildren: () => UsersAccessModule,
      },
      {
        path: 'add-fields',
        loadChildren: () => AddFieldsModule,
      },
      {
        path: 'brand-management',
        loadChildren: () => BrandManagementModule,
      },
      {
        path: 'address-management',
        loadChildren: () => AddressManagementModule,
      },
      {
        path: 'languages',
        loadChildren: () => LanguagesModule,
      },
      {
        path: 'certificate-management',
        loadChildren: () => CertificateManagementModule,
      },
      {
        path: 'unit-management',
        loadChildren: () => UnitManagementModule,
      },
      // {
      //   path: 'component-management',
      //   loadChildren: () => ComponentManagementModule,
      // },
      {
        path: 'features-management',
        loadChildren: () => FeaturesManagementModule,
      },
      {
        path: 'questionnaires-management',
        loadChildren: () => QuestionnairesManagementModule,
      },
      {
        path: 'archived-objects-management',
        loadChildren: () => ArchivedObjectsManagementModule,
      },
      {
        path: 'email-templates',
        loadChildren: () => EmailTemplatesModule,
      },
      {
        path: 'translation',
        canActivate: [AuthGuard],
        loadChildren: () => TranslationtModule,
      },
      {
        path: '**',
        redirectTo: '',
        pathMatch: 'full',
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SettingsRoutingModule {}
