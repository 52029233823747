import { PhasesFacade } from '@app/core/facade/phases.facade';
import { IPhase } from '@app/core/interface/phase-management.interface';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IProductChainResult } from '@app/core/interface/productchain.interface';
import { ProductChainGroup } from '../product-chain.group';
import { UntypedFormGroup } from '@angular/forms';
import { NotificationType } from '@app/core/constants';
import { SnackbarService } from '@app/core/service/snackbar.service';

@Component({
  selector: 'app-add-steps-phase',
  templateUrl: './add-steps-phase.component.html',
  styleUrls: ['./add-steps-phase.component.scss'],
  providers: [ProductChainGroup, PhasesFacade],
})
export class AddStepsPhaseComponent {
  selectedPhase?: IPhase;
  form: UntypedFormGroup;

  constructor(
    private _group: ProductChainGroup,
    private _facade: PhasesFacade,
    private _snackbarService: SnackbarService,
    @Inject(MAT_DIALOG_DATA) public data: { product_chain: IProductChainResult; selectedSteps: string[] },
    public dialogRef: MatDialogRef<AddStepsPhaseComponent>
  ) {
    this.form = this._group.addStepToPhaseForm;
    this.form.patchValue({
      product_chain: this.data.product_chain.uuid,
    });
    this.data.product_chain.steps.sort((a, b) => a.name.localeCompare(b.name));
  }

  selectPhase(phase: IPhase): void {
    this.data.selectedSteps = [];
    this.selectedPhase = phase;
    const selectedSteps = this.data.product_chain.steps.filter(step => step.type_step?.uuid === phase.uuid);
    selectedSteps.filter(step => step.uuid && this.data.selectedSteps.push(step.uuid));
    this.form.patchValue({
      steps: this.data.selectedSteps,
      name: this.selectedPhase?.name,
    });
  }

  updateStepList(stepUuid: string): void {
    const findStep = this.data.selectedSteps.find(step => step === stepUuid);
    if (findStep) {
      this.data.selectedSteps = this.data.selectedSteps.filter(step => step !== findStep);
    } else {
      this.data.selectedSteps.push(stepUuid);
    }

    this.form.patchValue({
      steps: this.data.selectedSteps,
    });
  }

  updatePhase(): void {
    if (this.form.valid && this.selectedPhase?.uuid) {
      this._facade.updatePhase$(this.selectedPhase?.uuid, this.data.product_chain.uuid, this.form.value).subscribe({
        next: this._updatePhaseSuccess.bind(this),
        error: this._error.bind(this),
      });
    }
  }

  private _updatePhaseSuccess(): void {
    this.dialogRef.close(true);
  }

  private _error(error: IPhase): void {
    Object.values(error).map(err => this._snackbarService.openTypeSnackbar(err[0], NotificationType.error));
  }
}
