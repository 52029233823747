<div tabindex="-1" class="modal-content">
  <div class="modal-body">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-6">
          <div>
            <h3 class="certification-details__title">Certification</h3>
            <ul class="certification-details">
              <li class="certification-details__list">
                <label>Certificator | Certificators:</label><span>{{ data.certificator }}</span>
              </li>
              <li class="certification-details__list">
                <label>File:</label
                ><span
                  ><a href="{{ data.document }}" target="_blank">
                    <app-custom-icon
                      mat-dialog-close
                      [icon_category]="'icons'"
                      [icon_name]="'pdf'"
                    ></app-custom-icon></a
                ></span>
              </li>
              <li class="certification-details__list">
                <label>Validity:</label>
                <div>Start Date: {{ data.validity_start | date: 'longDate' }}</div>
                <div>End date: {{ data.validity_end | date: 'longDate' }}</div>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-md-6">
          <div>
            <h3 class="certification-details__title">Certificate</h3>
            <ul class="certification-details">
              <li class="certification-details__list">
                <label>Name:</label><span>{{ data.certificate?.name }}</span>
              </li>
              <li class="certification-details__list">
                <label>Description:</label><span>/</span>
                <div class="py-2">
                  <a href="{{ data.document ?? '' }}" target="_blank"></a>
                </div>
              </li>
              <li class="certification-details__list">
                <label>Overall Impact Areas:</label><span>{{ data.certificate?.domain?.name }}</span>
              </li>
              <!---->
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>

  <app-custom-icon
    mat-dialog-close
    class="close-circle"
    [icon_category]="'icons'"
    [icon_name]="'close-circle'"
  ></app-custom-icon>
</div>
