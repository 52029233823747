<div class="wrapper">
  <div class="header">
    <h1>{{ data.group ? ('Edit Group' | translate) : ('Create Group' | translate) }}</h1>
  </div>
  <div class="content">
    <form [formGroup]="form">
      <div class="row">
        <div class="col-12 p-0">
          <h5>{{ 'Step Group Name' | translate }}</h5>
          <div class="d-flex align-items-center">
            <mat-form-field class="input-primary" appearance="outline">
              <mat-label>{{ 'Enter Step Group Name' | translate }}</mat-label>
              <input matInput formControlName="name" placeholder="{{ 'Enter Step Group Name' | translate }}" required />
            </mat-form-field>
            <div class="note">
              <app-custom-icon [icon_category]="'essential'" [icon_name]="'info-circle'"></app-custom-icon>
              <span>{{
                'Please note that in order to proceed with the publication, you will have to add at least 2 steps into the group.'
                  | translate
              }}</span>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
<mat-dialog-actions align="end">
  <app-rounded-button color="tetriary-link" [button_text]="'Cancel'" [size]="'sm'" mat-dialog-close>
  </app-rounded-button>
  <app-rounded-button
    color="primary"
    [button_text]="data.group ? 'Done Editing' : 'Create Group'"
    [size]="'sm'"
    [icon_right]="!data.group"
    [icon_category]="'grid'"
    [icon_name]="'format-square'"
    (click)="!data.group ? saveChanges() : updateGroup()"
    [disabled]="form && !form.valid"
  >
  </app-rounded-button>
</mat-dialog-actions>
