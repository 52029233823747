import { IProductChainStep, IStepSection } from '@app/core/interface/steps.interface';

export const STEP_SECTIONS: IStepSection[] = [
  {
    section: 'main',
    title: 'supply-chain-step.Main information',
    locked: false,
    icon: { category: 'essential', name: 'info-circle' },
  },
  {
    section: 'direct_supplier',
    title: 'supply-chain-step.Direct Supplier',
    locked: false,
    icon: { category: 'product', name: 'truck-tick' },
  },
  // {
  //   section: 'componant_info',
  //   title: 'Component info',
  //   locked: true,
  //   icon: { category: 'essential', name: 'box-2' },
  // },
  // {
  //   section: 'documents',
  //   title: 'Step Documents',
  //   locked: false,
  //   icon: { category: 'finance', name: 'document' },
  // },
  // {
  //   section: 'impact',
  //   title: 'Environmental Impact',
  //   locked: true,
  //   icon: { category: 'essential', name: 'danger' },
  // },
  // {
  //   section: 'costum_fields',
  //   title: 'Custom Fields',
  //   locked: true,
  //   icon: { category: 'grid', name: 'element-plus' },
  // },
];

export const STEP: IProductChainStep = {
  name: '',
  position_x: 0,
  position_y: 0,
  uuid: '',
  manufactories: {
    country: '',
    logo: null,
    name: '',
    uuid: '',
  },
  product_chain: '',
  type_step: {
    name: '',
    uuid: '',
    color: '',
    position: 0,
  },
  supplier_batch_number: '',
};
