import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';

import { CreateDigitalProductSheetDialogComponent } from '../../create-digital-product-sheet-dialog/create-digital-product-sheet-dialog.component';
import { IProduct } from '@app/core/interface/products.interface';
import { MatDialog } from '@angular/material/dialog';
import { ReviewDigitalProductSheetComponent } from '../../review-digital-product-sheet/review-digital-product-sheet.component';

@Component({
  selector: 'app-product-agec-regulation',
  templateUrl: './product-agec-regulation.component.html',
  styleUrls: ['./product-agec-regulation.component.scss'],
})
export class ProductAgecRegulationComponent {
  @Input() product?: IProduct;
  @Input() brand_regulation?: string;
  @Output() refresh = new EventEmitter<boolean>();
  steps = [{ completed: true }, { completed: true }, { completed: true }, { completed: false }, { completed: false }];

  constructor(private _dialog: MatDialog) {}
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['product']?.currentValue)
      this.steps = this.steps.map((step, index: number) => {
        step.completed = index + 1 <= changes['product'].currentValue.regulations[0]?.datas[0]?.steps_completed;
        return step;
      });
  }
  createDialogProductSheet(regulation: string): void {
    const dialog = this._dialog.open(CreateDigitalProductSheetDialogComponent, {
      width: '1160px',
      height: '726px',
      panelClass: ['padding-0', 'overflow-y-hidden-dialog'],
      data: {
        regulation: regulation,
        uuid:
          this.product?.regulations && this.product.regulations[0].datas[0]
            ? this.product?.regulations[0].datas[0].uuid
            : '',
        product: this.product,
        brand_regulation: this.brand_regulation,
      },
    });
    dialog.afterClosed().subscribe((res: boolean) => {
      res && this.refresh.emit(res);
    });
  }
  reviewDialogProductSheet(): void {
    this._dialog.open(ReviewDigitalProductSheetComponent, {
      width: '1160px',
      height: '786px',
      panelClass: ['padding-0', 'overflow-y-hidden-dialog'],
      data: {
        product: this.product,
        regulation: 'agec',
        uuid:
          this.product?.regulations && this.product.regulations[0].datas[0]
            ? this.product?.regulations[0].datas[0].uuid
            : '',
        brand_regulation: this.brand_regulation,
      },
    });
  }
  publishDialogProductSheet() {
    return;
  }
}
