<mat-card class="projects-list-wrapper">
  <div class="header-wrapp d-flex flex-column">
    <div class="top-holder d-flex align-items-center justify-content-between">
      <h6 class="pr-title">
        {{ 'All projects' | translate }}
      </h6>
      <div class="filter-holder">
        <mat-form-field class="input-primary mat-form-field-10-px-brd-rd" appearance="outline">
          <mat-label class="label-text">{{ 'Search project by name here...' | translate }}</mat-label>
          <input matInput (keyup)="applyFilter($event)" placeholder="Ex. Project" #input />
          <app-custom-icon
            class="search-filter"
            [icon_category]="'search'"
            [icon_name]="'search-normal-2'"
          ></app-custom-icon>
        </mat-form-field>
      </div>
      <div>
        <app-rounded-button
          color="primary"
          (click)="openCreateDialog('', false)"
          [button_text]="'Create Project'"
          [icon_category]="'essential'"
          [icon_name]="'project'"
          [icon_right]="true"
          [size]="'md'"
          class="crt-btn"
        >
        </app-rounded-button>
      </div>
    </div>
    <div class="checkbox-holder d-flex justify-content-end" *ngIf="showCheckbox">
      <mat-checkbox class="checkbox-primary" (change)="toggleDeactiveView($event)" [checked]="deactiveVisible">
        {{ 'show deactivated projects' | translate }}
      </mat-checkbox>
    </div>
  </div>
  <div class="table-wraper">
    <div class="row">
      <table class="project-table" mat-table matSort [dataSource]="dataSource">
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Project Name' | translate }}</th>
          <td mat-cell *matCellDef="let element" [ngClass]="{ inactive: !element.is_active }">
            {{ element.name }}
          </td>
        </ng-container>

        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Status' | translate }}</th>
          <td mat-cell *matCellDef="let element" [ngClass]="{ inactive: !element.is_active }">
            <div class="status-holder">
              <div *ngIf="element.is_active" class="status-set d-flex align-items-center">
                <div
                  [ngClass]="{
                    to_do: element.status?.name === 'Not started',
                    in_progress: element.status?.name === 'In progress',
                    completed: element.status?.name === 'Completed',
                  }"
                ></div>

                <div class="">
                  {{ element.status?.name }}
                </div>
              </div>
              <p *ngIf="!element.is_active" class="m-0">{{ 'not set' | translate }}</p>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="sub_projects_num">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Number of subprojects' | translate }}</th>
          <td mat-cell *matCellDef="let element" [ngClass]="{ inactive: !element.is_active }">
            {{ element.subprojects?.length }}
          </td>
        </ng-container>

        <ng-container matColumnDef="product_traced_num">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Number of products traced' | translate }}</th>
          <td mat-cell *matCellDef="let element" [ngClass]="{ inactive: !element.is_active }">
            {{ element.project_products?.length }}
          </td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef class="actions-header">
            <div class="d-flex justify-content-end">{{ 'Actions' | translate }}</div>
          </th>
          <td mat-cell *matCellDef="let element" class="actions-body">
            <div *ngIf="element.is_active">
              <div class="action d-flex justify-content-end align-items-ecenter">
                <app-custom-icon
                  id="eye"
                  [icon_category]="'security'"
                  [icon_name]="'eye'"
                  matTooltip="{{ 'View Project Details' | translate }}"
                  matTooltipClass="custom-tooltip-center"
                  matTooltipPosition="above"
                  [routerLink]="'./' + element.uuid"
                >
                </app-custom-icon>
                <app-custom-icon
                  id="deactivate"
                  (click)="deactivateProject(element)"
                  matTooltip="{{ 'Deactivate Project' | translate }}"
                  matTooltipClass="custom-tooltip-end"
                  matTooltipPosition="above"
                  [icon_category]="'essential'"
                  [icon_name]="'deactivate-red'"
                >
                </app-custom-icon>
              </div>
            </div>
            <div *ngIf="!element.is_active" class="activate justify-content-end d-flex">
              <app-custom-icon
                id="activate"
                [icon_category]="'essential'"
                [icon_name]="'activate'"
                *ngIf="!element.is_active"
                (click)="reactivateProject(element)"
                matTooltip="{{ 'Reactivate Sub-project' | translate }}"
                matTooltipClass="custom-tooltip-end"
                [matTooltipPosition]="'above'"
              ></app-custom-icon>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>
  </div>

  <div class="card-footer">
    <mat-paginator showFirstLastButtons aria-label="Select page of projects"> </mat-paginator>
  </div>
</mat-card>
