<h2>{{ 'billing-management.Unpaid Invoices List' | translate }}</h2>
<div class="table-wraper" *ngIf="tableDataSource.data.length > 0">
  <table mat-table matSort [dataSource]="tableDataSource">
    <ng-container matColumnDef="invoice-id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'billing-management.Invoice Number' | translate }}</th>
      <td mat-cell *matCellDef="let element">{{ element.invoice.id }}</td>
    </ng-container>

    <ng-container matColumnDef="total">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'billing-management.Invoice Amount' | translate }}</th>
      <td mat-cell *matCellDef="let element">{{ element.invoice.total / 100 | number : '1.2-2' }}</td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef class="actions-header text-center">
        {{ 'billing-management.Download Invoice' | translate }}
      </th>
      <td mat-cell *matCellDef="let element" class="actions-body text-center">
        <app-custom-icon
          [icon_category]="'files'"
          [icon_name]="'document-download'"
          (click)="getInvoiceDownloadLink(element.invoice.id.id)"
          matTooltip="{{ 'billing-management.Download Invoice' | translate }}"
          matTooltipClass="custom-tooltip-end mb-16"
          [matTooltipPosition]="'above'"
        ></app-custom-icon>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>

  <div class="card-footer">
    <mat-paginator class="custom-paginator" showFirstLastButtons aria-label="Select page of users"> </mat-paginator>
  </div>
</div>
<div class="no-data-wrapper" *ngIf="tableDataSource.data.length === 0">
  <img src="assets/images/illustration_no_unpaid_invoices.svg" alt="" />
  <p>You don't have any Unpaid Invoices yet.</p>
</div>
