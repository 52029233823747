import { Component, Input } from '@angular/core';
import { IRecentSupplier } from '@app/core/interface/suppliers.interface';

@Component({
  selector: 'app-recent-suppliers-list',
  templateUrl: './recent-suppliers-list.component.html',
  styleUrls: ['./recent-suppliers-list.component.scss'],
  providers: [],
})
export class RecentSuppliersList {
  @Input() suppliers: Array<IRecentSupplier> = [];
}
