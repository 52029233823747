<div class="background">
  <div class="holder">
    <mat-card class="address-wrapper" *ngIf="addressList">
      <div class="header-wrapper">
        <div class="options-header">
          <div class="address-heading">
            <p class="address-title">
              {{ "Manage Brand's Addresses" | translate }}
            </p>
          </div>
          <!-- Filter -->
          <div class="filter-view-wrapper">
            <div class="filter">
              <mat-form-field class="input-primary mat-form-field-10-px-brd-rd" appearance="outline">
                <mat-label class="label-text">{{ 'Search for address...' | translate }}</mat-label>
                <input matInput (keyup)="applyFilter($event)" placeholder="Ex. Basel" #input />
                <app-custom-icon
                  class="search-filter"
                  [icon_category]="'search'"
                  [icon_name]="'search-normal-2'"
                ></app-custom-icon>
              </mat-form-field>
            </div>

            <div class="btn-holder">
              <div class="toggle-group">
                <mat-button-toggle-group #group="matButtonToggleGroup" (change)="viewChange($event.value)">
                  <div class="table-view toggle">
                    <mat-button-toggle
                      value="tableView"
                      aria-label="Table View"
                      [checked]="true"
                      matTooltip="{{ 'List View' | translate }}"
                      matTooltipClass="custom-tooltip-center"
                      matTooltipPosition="above"
                    >
                      <app-custom-icon [icon_category]="'essential'" [icon_name]="'menu-3lines'"></app-custom-icon>
                    </mat-button-toggle>
                  </div>
                  <div class="card-view toggle">
                    <mat-button-toggle
                      value="cardView"
                      aria-label="Card View"
                      matTooltip="{{ 'Grid View' | translate }}"
                      matTooltipClass="custom-tooltip-center"
                      matTooltipPosition="above"
                    >
                      <app-custom-icon [icon_category]="'grid'" [icon_name]="'element-3-large'"></app-custom-icon>
                    </mat-button-toggle>
                  </div>
                </mat-button-toggle-group>
              </div>
              <div class="create-address-btn">
                <app-rounded-button
                  color="primary"
                  [button_text]="'Add New Address'"
                  [icon_category]="'location'"
                  [icon_name]="'map'"
                  [icon_right]="true"
                  [size]="'md'"
                  (click)="createAddressDialog()"
                >
                </app-rounded-button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="main-holder">
        <!-- Table View -->
        <div class="table-wraper" *ngIf="group.value === 'tableView'">
          <table class="custom-table" mat-table matSort [dataSource]="tableDataSource">
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ 'address-management.Address Name' | translate }}
              </th>
              <td mat-cell *matCellDef="let element">{{ element.name }}</td>
            </ng-container>

            <ng-container matColumnDef="street_line_1">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Street Line ' | translate }}</th>
              <td mat-cell *matCellDef="let element">{{ element.street_line_1 }}</td>
            </ng-container>

            <ng-container matColumnDef="city">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'address-management.City' | translate }}</th>
              <td mat-cell *matCellDef="let element">{{ element.city }}</td>
            </ng-container>

            <ng-container matColumnDef="country">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Country' | translate }}</th>
              <td mat-cell *matCellDef="let element">{{ element.country }}</td>
            </ng-container>

            <ng-container matColumnDef="actions">
              <th mat-header-cell *matHeaderCellDef class="actions-header text-center">{{ 'Actions' | translate }}</th>
              <td mat-cell *matCellDef="let element" class="actions-body text-center">
                <div class="actions-holder">
                  <app-custom-icon
                    id="eye"
                    [icon_category]="'security'"
                    [icon_name]="'eye'"
                    matTooltip="{{ 'View Details' | translate }}"
                    matTooltipClass="custom-tooltip-center"
                    matTooltipPosition="above"
                    (click)="addressViewDialog(element)"
                  >
                  </app-custom-icon>
                  <app-custom-icon
                    id="edit"
                    [icon_category]="'files'"
                    [icon_name]="'edit'"
                    matTooltip="{{ 'Edit Address' | translate }}"
                    matTooltipClass="custom-tooltip-center"
                    matTooltipPosition="above"
                    (click)="editAddressDialog(element)"
                  ></app-custom-icon>

                  <app-custom-icon
                    id="trash"
                    [icon_category]="'essential'"
                    [icon_name]="'trash'"
                    matTooltip="{{ 'Delete Address' | translate }}"
                    matTooltipClass="custom-tooltip-end"
                    matTooltipPosition="above"
                    (click)="deleteAddressDialog(element.uuid)"
                  >
                  </app-custom-icon>
                </div>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          </table>
        </div>
        <!-- Card View -->
        <div class="cards-wraper row" *ngIf="group.value === 'cardView'">
          <mat-card *ngFor="let address of addressObservable$ | async" class="custom-card col-12 col-md-6">
            <div class="heading col-12" (click)="addressViewDialog(address)">
              <p id="heading-txt">{{ address.name }}</p>
            </div>

            <div class="data-holder">
              <div class="address-holder">
                <div class="data-street">
                  <p class="subtitle-street">street line</p>
                  <p class="title-street">{{ address.street_line_1 }}</p>
                </div>
                <div class="data">
                  <p class="subtitle">city</p>
                  <p class="title">{{ address.city }}</p>
                </div>
                <div class="data">
                  <p class="subtitle">country</p>
                  <p class="title">{{ address.country }}</p>
                </div>
              </div>

              <div class="address-holder">
                <div class="data-street">
                  <div class="" [ngClass]="!address.street_line_2 ? 'd-none' : 'd-block'">
                    <p class="subtitle-street">street line 2</p>
                    <p class="title-street">{{ address.street_line_2 }}</p>
                  </div>
                </div>
                <div class="data">
                  <div class="" [ngClass]="!address.state ? 'd-none' : 'd-block'">
                    <p class="subtitle">state</p>
                    <p class="title">{{ address.state }}</p>
                  </div>
                </div>
                <div class="data">
                  <div class="" [ngClass]="!address.postcode ? 'd-none' : 'd-block'">
                    <p class="subtitle">zip code</p>
                    <p class="title">{{ address.postcode }}</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="dropdown-actions">
              <app-custom-icon
                id="dots"
                [icon_category]="'settings'"
                [icon_name]="'more'"
                matTooltip="{{ 'View All Actions' | translate }}"
                matTooltipClass="custom-tooltip-end"
                matTooltipPosition="above"
                [matMenuTriggerFor]="beforeMenu"
              >
              </app-custom-icon>

              <mat-menu #beforeMenu="matMenu" xPosition="before">
                <div class="menu-wrapper">
                  <button class="custom-item" mat-menu-item>
                    <p class="edit-text" (click)="editAddressDialog(address)">{{ 'Edit Address' | translate }}</p>
                  </button>

                  <button class="custom-item" mat-menu-item>
                    <p class="delete-text" (click)="deleteAddressDialog(address.uuid)">
                      {{ 'Delete Address' | translate }}
                    </p>
                  </button>
                </div>
              </mat-menu>
            </div>
          </mat-card>
        </div>
      </div>

      <div class="card-footer">
        <mat-paginator class="custom-paginator" showFirstLastButtons aria-label="Select page of suppliers">
        </mat-paginator>
      </div>
    </mat-card>
    <!-- No Address Design -->
    <mat-card class="no-address-design" *ngIf="!addressList">
      <div class="no-address-holder">
        <div class="no-address-header">
          <div class="no-address-title">
            <p>{{ "Manage Brand's Addresses" | translate }}</p>
          </div>
          <div class="create-address-btn">
            <app-rounded-button
              color="primary"
              [button_text]="'Add New Address'"
              [icon_category]="'location'"
              [icon_name]="'map'"
              [icon_right]="true"
              [size]="'md'"
              (click)="createAddressDialog()"
            >
            </app-rounded-button>
          </div>
        </div>
        <div class="no-address-content">
          <div class="logo-text">
            <div class="icon">
              <app-custom-icon id="trash" [icon_category]="'location'" [icon_name]="'map'"> </app-custom-icon>
            </div>
            <div class="txt">
              <h4>There are no addresses added</h4>
              <br />
              <h4>to this Brand yet.</h4>
            </div>
          </div>
        </div>
      </div>
    </mat-card>
  </div>
</div>
