import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotificationType } from '@app/core/constants';
import { RegulationsFacade } from '@app/core/facade/regulations.facade';
import { IPackagingDisplay, IReviewDigitalProductSheet } from '@app/core/interface/digital-product-sheet.interface';
import { IProduct } from '@app/core/interface/products.interface';
import {
  AgecPackagingType,
  IAgecPackaging,
  IAgecRegulation,
  PackageRecyclabitity,
  SheetCategory,
  TracebilityStepOne,
  TracebilityStepThree,
  TracebilityStepTwo,
} from '@app/core/interface/regulations.interface';
import { SnackbarService } from '@app/core/service/snackbar.service';
import { CountryList } from '@app/core/utils/country-list';

@Component({
  selector: 'app-review-digital-product-sheet',
  templateUrl: './review-digital-product-sheet.component.html',
  styleUrls: ['./review-digital-product-sheet.component.scss'],
  providers: [RegulationsFacade],
})
export class ReviewDigitalProductSheetComponent implements OnInit {
  displaySheet!: IReviewDigitalProductSheet;
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public dialogData: { product: IProduct; regulation: string; uuid: string; brand_regulation: string },
    private _facade: RegulationsFacade,
    private _snackbarService: SnackbarService
  ) {}

  ngOnInit(): void {
    this.getDigitalSheet();
  }

  getDigitalSheet(): void {
    this._facade.getDigitalSheet$(this.dialogData.regulation, this.dialogData.uuid).subscribe({
      next: this._success.bind(this),
      error: this._error.bind(this),
    });
  }
  private _success(response: IAgecRegulation): void {
    if (response)
      this.displaySheet = {
        ...response,
        hazardous_substances:
          response.hazardous_substances?.map(sub => {
            return sub.name;
          }) ?? [],
        traceability_steps: response.traceability_steps
          ? {
              step_one_country:
                CountryList.getCountryByCode(response.traceability_steps[0]?.step_one_country)?.name ?? '',
              step_two_country:
                CountryList.getCountryByCode(response.traceability_steps[0]?.step_two_country)?.name ?? '',
              step_three_country:
                CountryList.getCountryByCode(response.traceability_steps[0]?.step_three_country)?.name ?? '',
              step_one_type: TracebilityStepOne[response.traceability_steps[0]?.step_one_type] ?? '',
              step_two_type: TracebilityStepTwo[response.traceability_steps[0]?.step_two_type] ?? '',
              step_three_type: TracebilityStepThree[response.traceability_steps[0]?.step_three_type] ?? '',
            }
          : undefined,
        productType: SheetCategory[response.product_type],
        recycledMaterialPercentage: response.recycled_material_percentage?.toString() ?? null,
        packaging: response.packaging?.map((pack: IAgecPackaging) => {
          const pac: IPackagingDisplay = {
            agec: pack.agec,
            hazardous_substances:
              pack.hazardous_substances?.map(sub => {
                return sub.name;
              }) ?? [],
            is_recyclable: pack.is_recyclable,
            is_recyclable_display: pack.is_recyclable_display,
            is_recycled_materials: pack.is_recycled_materials,
            is_recycled_materials_display: pack.is_recycled_materials_display,
            name: pack.name,
            packageType: AgecPackagingType[pack.package_type],
            packageRecyclability: this._facade.recycleTypeMsg[PackageRecyclabitity[+pack.package_recyclability]],
            uuid: pack.uuid,
          };
          return pac;
        }),
      };
  }
  private _error(error: Record<string, string[]>): void {
    Object.values(error).map(err => this._snackbarService.openTypeSnackbar(err[0], NotificationType.error));
  }
}
