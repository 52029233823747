<mat-card>
  <mat-card-header class="align-items-center justify-content-between">
    <mat-card-title>
      <h6>
        <strong>{{ 'Add Fields' | translate }}</strong>
      </h6>
    </mat-card-title>
    <!-- <div class="save-status d-flex align-items-center justify-content-center">
      <app-custom-icon icon_category="weather" icon_name="saved-changes"></app-custom-icon>
    </div> -->
  </mat-card-header>

  <mat-divider></mat-divider>

  <mat-card-content>
    <div class="choose-object-row d-flex">
      <mat-form-field appearance="outline" class="dropdown-primary col">
        <mat-select [formControl]="selected_object" panelClass="dropdown-primary">
          <!-- If show_project is true, display the full list -->
          <ng-container *ngIf="show_project; else singleObject">
            <mat-option *ngFor="let object of objectsList" [value]="object.uuid">
              {{ object.name }}
            </mat-option>
          </ng-container>

          <!-- If show_project is false, display only the first object -->
          <ng-template #singleObject>
            <mat-option [value]="objectsList[0]?.uuid">
              {{ objectsList[0]?.name }}
            </mat-option>
          </ng-template>
        </mat-select>
      </mat-form-field>

      <div class="choose-object-note col d-flex align-items-center">
        <img src="assets/logos/symbol-logo-dark.svg" alt="pdna-logo" />
        <p class="p2-strong">
          {{ 'Choose from the dropdown menu the object you want to add a custom field to' | translate }}
        </p>
      </div>
    </div>

    <div class="fields-section d-flex">
      <!-------------------------------------- Standard fields ----------------------------------------->
      <div class="col">
        <div class="subsection-header">
          <div class="title d-flex">
            <p class="p1-strong">{{ 'Standard Fields' | translate }}</p>
            <app-custom-icon
              class="info-icon"
              icon_category="essential"
              icon_name="info-circle"
              [matTooltip]="'standard fields \n cannot be changed' | translate"
              matTooltipPosition="above"
              matTooltipClass="custom-tooltip-center custom-tooltip-text-blue"
              matTooltipVisibility="visible"
            ></app-custom-icon>
          </div>
          <div class="subtitle-note d-flex align-items-center">
            <span class="note-text">{{ 'the standard fields are marked with the symbol' | translate }}</span>
            <span class="green-circle-mark"></span>
          </div>
        </div>
        <!-- Product -->
        <div *ngIf="selected_object.value === 'products'" class="fields-card product d-flex flex-column">
          <div class="card-header">
            <p class="p1-strong">{{ 'Product Details' | translate }}</p>
          </div>
          <div class="card-content flex-grow-1">
            <div class="object-name d-flex align-items-center">
              <img
                src="https://www.bodenimages.com/productimages/r1aproductlarge/23gspr_g3760_bbl.jpg"
                alt="object-image"
              />
              <h6>
                <strong>{{ 'Your product name' | translate }}</strong>
              </h6>
              <span class="green-circle-mark"></span>
            </div>
            <div class="field-row">
              <div class="field-title d-flex align-items-center royal-blue-brand">
                <h6>
                  <strong>{{ 'Product Description' | translate }}</strong>
                </h6>
                <span class="green-circle-mark"></span>
              </div>
              <p class="p2 field-description">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac
                aliquet odio mattis. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos
                himenaeos, libero et velit interdum.
              </p>
            </div>
            <div class="field-row">
              <div class="field-title d-flex align-items-center royal-blue-light-20">
                <h6>
                  <strong>{{ 'Product Summary' | translate }}</strong>
                </h6>
                <span class="green-circle-mark"></span>
              </div>
              <p class="p2 field-description">
                Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos, libero et
                velit interdum.
              </p>
            </div>
          </div>
        </div>
        <!-- Project/Subproject -->
        <div
          *ngIf="selected_object.value === 'project' || selected_object.value === 'subproject'"
          class="fields-card project d-flex flex-column"
          [ngStyle]="{ background: selected_object.value === 'project' ? '#DCECFE' : '#F5FAFF' }"
        >
          <div class="name title-value-row">
            <h6 class="title">
              {{ selected_object.value === 'project' ? ('Project name' | translate) : ('Subproject name' | translate) }}
            </h6>
            <span class="green-circle-mark"></span>
            <h5 class="full-width">
              <strong>
                {{ 'Some ' + (selected_object.value === 'project' ? 'project' : 'subproject') + ' name' | translate }}
              </strong>
            </h5>
          </div>

          <div class="status title-value-row">
            <p class="title">{{ 'Status' | translate }}</p>
            <span class="green-circle-mark"></span>
            <p class="full-width">
              <strong>{{ 'in progress' | translate }}</strong>
            </p>
          </div>
          <div class="dates d-flex">
            <div class="col-6 title-value-row">
              <p class="title">{{ 'start date' | translate }}</p>
              <span class="green-circle-mark"></span>
              <p class="full-width"><strong>20.10.2022</strong></p>
            </div>
            <div class="col-6 title-value-row">
              <p class="title">{{ 'end date' | translate }}</p>
              <span class="green-circle-mark"></span>
              <p class="full-width"><strong>20.10.2022</strong></p>
            </div>
          </div>
          <div class="horizontal-line"></div>
          <div class="location title-value-row">
            <p class="title">location</p>
            <span class="green-circle-mark"></span>
            <p class="full-width">
              <strong>{{ '725 NE 166th St, Miami, Florida' | translate }}</strong>
            </p>
          </div>
        </div>
        <!-- Certificate -->
        <div *ngIf="selected_object.value === 'certificate'" class="fields-card certificate d-flex flex-column">
          <div class="header title-value-row">
            <h6>{{ 'Certificate type' | translate }}</h6>
            <span class="green-circle-mark"></span>
            <h5 class="full-width"><strong>ISO 9001</strong></h5>
          </div>
          <div class="content flex-grow-1 d-flex flex-column">
            <div class="title-value-row">
              <p class="p1 title">{{ 'certificator' | translate }}</p>
              <span class="green-circle-mark"></span>
              <p class="p1-strong full-width">{{ 'Some Company' | translate }}</p>
            </div>
            <div class="dates d-flex">
              <div class="col-6 title-value-row">
                <app-custom-icon class="icon-blue" icon_category="time" icon_name="calendar"></app-custom-icon>
                <p class="p1 title">{{ 'start date' | translate }}</p>
                <span class="green-circle-mark"></span>
                <p class="p1-strong full-width"><strong>20.10.2022</strong></p>
              </div>
              <div class="col-6 title-value-row">
                <app-custom-icon class="icon-red" icon_category="essential" icon_name="danger"></app-custom-icon>
                <p class="p1 color-red">{{ 'expires' | translate }}</p>
                <span class="green-circle-mark"></span>
                <p class="p1-strong color-red full-width"><strong>20.10.2023</strong></p>
              </div>
            </div>
          </div>
        </div>
        <!-- Component -->
        <div *ngIf="selected_object.value === 'component'" class="fields-card component d-flex flex-column">
          <div class="name title-value-row">
            <h6 class="title">{{ 'Component name' | translate }}</h6>
            <span class="green-circle-mark"></span>
            <h5 class="full-width">
              <strong>{{ 'Some component name' | translate }}</strong>
            </h5>
          </div>
          <div class="status title-value-row">
            <p class="title p1">{{ 'Reference' | translate }}</p>
            <span class="green-circle-mark"></span>
            <p class="p1-strong full-width">{{ 'Some Reference Number' | translate }}</p>
          </div>
          <div class="status title-value-row">
            <p class="title p1">{{ 'Product Description' | translate }}</p>
            <span class="green-circle-mark"></span>
            <p class="p2 full-width">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac
              aliquet odio mattis. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos
              himenaeos, libero et velit interdum.
            </p>
          </div>
          <div class="horizontal-line"></div>
          <div class="dates d-flex">
            <div class="col-6 title-value-row">
              <app-custom-icon class="icon-blue" icon_category="product" icon_name="truck"></app-custom-icon>
              <p class="p1 title">{{ 'Supplier' | translate }}</p>
              <span class="green-circle-mark"></span>
              <p class="p1-strong full-width royal-blue-brand">{{ 'Sampson Gin' | translate }}</p>
            </div>
            <div class="col-6 title-value-row">
              <app-custom-icon class="icon-blue" icon_category="buildings" icon_name="factory"></app-custom-icon>
              <p class="p1">{{ 'Supplier site' | translate }}</p>
              <span class="green-circle-mark"></span>
              <div class="d-flex align-items-center gap-6px">
                <p class="p1-strong royal-blue-light-20">{{ 'Factory 1' | translate }}</p>
                <p class="p2 royal-blue-brand">{{ 'El Paso, USA' | translate }}</p>
              </div>
            </div>
          </div>
        </div>
        <!-- Suppliers -->
        <div *ngIf="selected_object.value === 'supplier'" class="fields-card supplier d-flex flex-column">
          <div class="card-header">
            <p class="p1-strong">{{ 'Supplier Details' | translate }}</p>
          </div>
          <div class="card-content flex-grow-1">
            <div class="object-name d-flex align-items-center">
              <img src="https://thrakika.gr/images/posts/main/1188x594/cotton-usa-3.jpg" alt="object-image" />
              <div>
                <div class="d-flex align-items-center">
                  <h6>
                    <strong>{{ 'Supplier name' | translate }}</strong>
                  </h6>
                  <span class="green-circle-mark"></span>
                </div>
                <div class="d-flex align-items-center">
                  <p class="p1-strong">{{ 'Main site address:' | translate }}</p>
                  &nbsp;
                  <p class="p1 royal-blue-brand">{{ 'El Paso, US' | translate }}</p>
                  <span class="green-circle-mark"></span>
                </div>
              </div>
            </div>
            <div class="field-row">
              <div class="field-title d-flex align-items-center royal-blue-brand">
                <h6>
                  <strong>{{ 'Supplier Description' | translate }}</strong>
                </h6>
                <span class="green-circle-mark"></span>
              </div>
              <p class="p1 field-description">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac
                aliquet odio mattis. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos
                himenaeos, libero et velit interdum.
              </p>
            </div>
          </div>
        </div>
      </div>
      <!------------------------------------- Custom fields ------------------------------------->
      <div class="col d-flex flex-column">
        <div class="subsection-header">
          <div class="title d-flex">
            <p class="p1-strong">{{ 'Custom Fields' | translate }}</p>
            <app-custom-icon
              class="info-icon"
              icon_category="essential"
              icon_name="info-circle"
              [matTooltip]="
                'if you need additional fields\n for your product, you can\n create custom fields' | translate
              "
              matTooltipPosition="above"
              matTooltipClass="custom-tooltip-center custom-tooltip-text-blue"
            ></app-custom-icon>
          </div>
          <div class="check-box d-flex justify-content-start">
            <mat-checkbox
              (change)="showDeactivatedCustomFields($event)"
              [checked]="show_deactivated"
              class="checkbox-primary"
            >
              {{ 'show deactivated custom fields' | translate }}
            </mat-checkbox>
          </div>
        </div>
        <div class="custom-fields-card d-flex flex-wrap align-content-start">
          <div *ngIf="showCustomFieldsNote" class="custom-fields-note d-flex align-items-center full-width">
            <p class="p2-strong">
              {{
                'Depending on the product, custom fields could be: product’s format, weight, length, volume...'
                  | translate
              }}
            </p>
            <app-custom-icon
              class="close-icon cursor-pointer"
              icon_category="essential"
              icon_name="close-circle"
              (click)="showCustomFieldsNote = false"
            ></app-custom-icon>
          </div>
          <div *ngIf="!customFieldsList?.length" class="no-fields-added-yet d-flex full-width">
            <app-custom-icon class="element-plus-icon" icon_category="grid" icon_name="element-plus"></app-custom-icon>
            <p class="p1-strong">{{ 'No Custom Fields Added Yet' | translate }}</p>
          </div>
          <div
            class="add-custom-field d-flex align-items-center justify-content-between cursor-pointer full-width"
            (click)="onAddCustomField()"
          >
            <h6>{{ 'Add Custom Field' | translate }}</h6>
            <app-custom-icon class="add-icon" icon_category="essential" icon_name="add"></app-custom-icon>
          </div>
          <!-- List of existing custom fields -->
          <div *ngIf="customFieldsList?.length" class="custom-fields-list d-flex flex-wrap full-width">
            <div *ngFor="let field of customFieldsList; let i = index" class="custom-field-item full-width">
              <div *ngIf="editItemUuid !== field.uuid" class="view-field d-flex">
                <mat-form-field
                  [ngClass]="field.is_active ? 'input-primary col' : 'col'"
                  appearance="outline"
                  floatLabel="always"
                >
                  <div role="group" class="d-flex" [ngStyle]="{ opacity: field.is_active ? '1' : '0.4' }">
                    <input
                      #field_input
                      matInput
                      [placeholder]="'Enter Field Name Here...*' | translate"
                      required
                      [disabled]="editItemUuid !== field.uuid"
                      [value]="field.name"
                    />
                    <input
                      *ngIf="
                        (selected_object.value === 'project' ||
                          selected_object.value === 'products' ||
                          selected_object.value === 'subproject') &&
                        field.field_options?.length
                      "
                      matInput
                      disabled
                      [value]="'+' + field.field_options.length + ' options created'"
                    />
                  </div>
                </mat-form-field>
                <div class="action-icons d-flex flex-column justify-content-center">
                  <app-custom-icon
                    *ngIf="field.is_active"
                    matTooltip="{{ 'Edit Custom Field' | translate }}"
                    matTooltipClass="custom-tooltip-end"
                    matTooltipPosition="above"
                    class="cursor-pointer"
                    icon_category="files"
                    icon_name="edit"
                    (click)="onEditField(i, field)"
                  ></app-custom-icon>

                  <!-- <app-custom-icon
                    matTooltip="{{ 'Delete Custom Field' | translate }}"
                    matTooltipClass="custom-tooltip-end"
                    matTooltipPosition="above"
                    class="delete-icon cursor-pointer"
                    icon_category="essential"
                    icon_name="trash"
                    (click)="onDeleteField(i, field.uuid)"
                  ></app-custom-icon> -->
                  <app-custom-icon
                    [matTooltip]="
                      field.is_active
                        ? ('Deactivate Custom Field' | translate)
                        : ('Reactivate Custom Field ' | translate)
                    "
                    matTooltipClass="custom-tooltip-end"
                    matTooltipPosition="above"
                    [class]="field.is_active ? 'deactivate-icon cursor-pointer' : 'reactivate-icon cursor-pointer'"
                    icon_category="essential"
                    icon_name="activate"
                    (click)="deactivateCustomField(field)"
                  ></app-custom-icon>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>
