import { Component, Input, OnDestroy } from '@angular/core';
import { IPaymentChargebeePaymentForm, IPaymentChargebeePaymentResponse } from '@app/core/interface/purchase.interface';
import { PurchaseFacade } from '@app/core/facade/purchase.facade';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { NotificationType } from '@app/core/constants';
import { SnackbarService } from '@app/core/service/snackbar.service';

@Component({
  selector: 'app-purchase-card',
  templateUrl: './purchase-card.component.html',
  styleUrls: ['./purchase-card.component.scss'],
})
export class PurchaseCardComponent implements OnDestroy {
  iconsPath = '../../../../assets/Icons/';
  @Input() icon_name = '';
  @Input() card_title = '';
  @Input() price = '';
  @Input() card_list = Array<string>();
  @Input() color = '';
  @Input() icon_category = '';
  @Input() icon_category_list = '';
  @Input() icon_name_list = '';
  @Input() data_plan = '';

  /**
   * Public variables
   */
  chargebeeScript!: HTMLScriptElement;
  hostedPageId!: string;
  isChargeBeeDialogOpened = false;
  header_variable = false;
  chargeBeePayment: IPaymentChargebeePaymentForm = {
    chargebee_subscription_id: '',
  };

  // Unsubscribe cleaner
  private _unsubscribe$: Subject<void> = new Subject();

  constructor(
    private _purchaseFacade: PurchaseFacade,
    private _router: Router,
    private _snackbarService: SnackbarService
  ) {}

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }

  /**
   * Function to disable spinner
   */
  private _subscribeToSpinner() {
    const spinner = sessionStorage.getItem('spinner');
    if (spinner == 'stop') {
      sessionStorage.removeItem('spinner');

      if (sessionStorage.getItem('paymentId') != null) {
        const paymentId = sessionStorage.getItem('paymentId');
        if (typeof paymentId === 'string') {
          this.createSubscription(paymentId);
        }
      }
    } else {
      setTimeout(() => {
        this._subscribeToSpinner();
      }, 500);
    }
  }

  /**
   * Function for handling successfull api response
   */
  private _success(data: IPaymentChargebeePaymentResponse): void {
    const { customer } = data;
    sessionStorage.setItem('customer', JSON.stringify(customer));
    document.querySelector('#cb-container')?.remove();
    this._snackbarService.openTypeSnackbar(`Subscription is successfully created`, NotificationType.success);
    this._router.navigate(['/register/create-account']);
    this.isChargeBeeDialogOpened = false;
  }

  /**
   * Function for handling error api response
   */
  private _error(error: Record<string, string[]>): void {
    Object.values(error).map(err => this._snackbarService.openTypeSnackbar(err[0], NotificationType.error));
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Function to init subscription dialog
   */
  initSubscriptionDialog() {
    this.isChargeBeeDialogOpened = true;
    this._subscribeToSpinner();
  }

  /**
   * Function to create subscription
   */
  createSubscription(hostedPageId: string) {
    this.chargeBeePayment.chargebee_subscription_id = hostedPageId;
    if (this.chargeBeePayment.chargebee_subscription_id) {
      this._purchaseFacade.createPayment$({ ...this.chargeBeePayment }).subscribe({
        next: this._success.bind(this),
        error: this._error.bind(this),
      });
    }
  }
}
