import { INameID } from './projects.interface';
import { IErrorResponse } from './register.interface';

export interface IRegulationObject {
  info?: string;
  is_enabled: boolean;
  name: string;
  displayName?: string;
  uuid: string;
}
export interface IProductRegulation {
  are_regulation_enabled: boolean;
  name: string;
  datas: Array<IProductRegulationData>;
}
export interface IBrandRegularionsResponse {
  count: number;
  next: string | null; // uuid
  previous: string | null; // uuid
  results: [
    {
      is_enabled: boolean;
      name: string;
      uuid: string;
    },
  ];
  error: IErrorResponse;
}
export interface IProductRegulationData {
  uuid: string;
  steps_completed: number;
}

export interface IAgecRegulation {
  brand_regulation: string; //($uuid)
  is_hazardous_substance: boolean;
  is_plastic_microfibers: boolean;
  is_recycled_material: boolean;
  is_submitted: boolean;
  product: string; //($uuid)
  product_type: number;
  // 1=Clothing, 2=Household linen, 3=Shoes
  recycled_material_percentage: number | null;
  is_recycled_materials_display?: boolean | null;
  uuid: string; //($uuid)
  hazardous_substances: INameID[];
  packaging: IAgecPackaging[];
  traceability_steps: IAgecTracebility[];
  error?: IErrorResponse;
  steps_completed?: number;
}

export interface IAgecRegulationResponse {
  count: number | null;
  next: string | null; //($uri)
  previous: string | null; //($uri)
  results: Array<IAgecRegulation>;
}
export enum SheetCategory {
  Clothing = 1,
  'Household Linen' = 2,
  Shoes = 3,
}
export enum TracebilityStepOne {
  Knitting = 1,
  Stitching = 2,
  Weaving = 3,
}
export enum TracebilityStepTwo {
  Assembly = 1,
  Dyeing = 2,
  'Dyeing & Printing' = 3,
  Printing = 4,
}
export enum TracebilityStepThree {
  Assembly = 1,
  Finishing = 2,
}

export enum AgecPackagingType {
  'E-commerce' = 1,
  Store = 2,
  'E-commerce & Store' = 3,
}
export enum PackageRecyclabitity {
  'Mostly recyclable' = 1,
  'Fully recyclable' = 2,
  'Same nature' = 3,
  'Not recyclable' = 4,
}
export interface IAgecTracebility {
  agec: string; //($uuid)
  is_submitted: boolean;
  step_one_country: string;
  step_two_country: string;
  step_three_country: string;
  step_one_type: number;
  // 1=Knitting, 2=Stitching, 3=Weaving
  step_two_type: number;
  // 1=Assembly, 2=Dyeing, 3=Dyeing and Printing, 4=Printing
  step_three_type: number;
  // 1=Assembly, 2=Finishing
  uuid: string; //($uuid
  error?: IErrorResponse;
  steps_completed?: number;
}

export interface IAgecPackaging {
  agec: string; //($uuid)
  hazardous_substance?: Array<INameID> | Array<string>;
  hazardous_substances?: Array<INameID>;
  is_recyclable: boolean;
  is_recyclable_display?: boolean | null;
  is_recycled_materials?: boolean;
  is_recycled_materials_display?: boolean | null;
  is_recyclable_type?: string;
  name: string;
  package_type: number;
  package_recyclability: number;
  uuid: string; //($uuid)
  invalid?: boolean;
  steps_completed?: number;
  error?: IErrorResponse;
}
export interface IAgecPackagings {
  count: number;
  next: string | null; //($uri)
  previous: string | null; //($uri)
  results: Array<IAgecPackaging>;
  error?: IErrorResponse;
}

export interface IPacData {
  name: string;
  dirty: boolean;
  type: string;
  uuid?: string;
  formValues: Array<Partial<IAgecPackaging>>;
}
