<div class="holder d-flex flex-column">
  <div class="table-holder">
    <table mat-table matSort [dataSource]="dataSource" class="custom-table">
      <ng-container matColumnDef="first_name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Name' | translate }}</th>
        <td mat-cell *matCellDef="let element">{{ element.first_name }} {{ element.last_name }}</td>
      </ng-container>

      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Email' | translate }}</th>
        <td mat-cell *matCellDef="let element">{{ element.email }}</td>
      </ng-container>

      <ng-container matColumnDef="phone">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Phone' | translate }}</th>
        <td mat-cell *matCellDef="let element">{{ element.phone }}</td>
      </ng-container>

      <ng-container matColumnDef="position">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Title' | translate }}</th>
        <td mat-cell *matCellDef="let element">{{ element.position }}</td>
      </ng-container>

      <ng-container matColumnDef="language_prefered">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Language' | translate }}</th>
        <td mat-cell *matCellDef="let element">{{ element.language_prefered.name }}</td>
      </ng-container>

      <ng-container matColumnDef="is_active">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Status' | translate }}</th>
        <td mat-cell *matCellDef="let element" [ngClass]="element.is_active ? 'is-active' : 'inactive'">
          {{ element.is_active ? 'Active' : ('Inactive' | translate) }}
        </td>
      </ng-container>

      <ng-container matColumnDef="main_contact">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Main Contact' | translate }}</th>
        <td mat-cell *matCellDef="let element" [ngClass]="element.main_contact ? 'main-contact' : 'not-main-contact'">
          {{ element.main_contact ? 'Yes' : ('No' | translate) }}
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef class="actions-header">
          <div class="actions">{{ 'Actions' | translate }}</div>
        </th>
        <td mat-cell *matCellDef="let element" class="actions-body">
          <div class="icon-holder">
            <app-custom-icon
              [icon_category]="'files'"
              [icon_name]="'edit'"
              (click)="editSupplierContact(element)"
              matTooltip="{{ 'Edit Contact' | translate }}"
              matTooltipClass="custom-tooltip-center centering"
              matTooltipPosition="above"
            ></app-custom-icon>
            <app-custom-icon
              id="trash"
              [icon_category]="'essential'"
              [icon_name]="'trash'"
              (click)="deleteSupplierContact(element)"
              matTooltip="{{ 'Delete Contact' | translate }}"
              matTooltipClass="custom-tooltip-end"
              matTooltipPosition="above"
            ></app-custom-icon>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </div>

  <div class="card-footer">
    <mat-paginator showFirstLastButtons aria-label="Select page of contacts"> </mat-paginator>
  </div>
</div>
