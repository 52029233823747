import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable } from 'rxjs/internal/Observable';
import {
  IAssignBrandCsmResponse,
  ICsmUserAssignResult,
  ICsmUserResponse,
} from '../interface/customer-success-management.interface';
import { IBatchPublishRequestsResponse } from '../interface/customer-success-management.interface';

@Injectable({
  providedIn: 'root',
})
export class CustomerSuccessManagementService {
  private apiUrl = environment.api;

  constructor(private _http: HttpClient) {}

  getCsmBrands$(): Observable<IAssignBrandCsmResponse> {
    return this._http.get<IAssignBrandCsmResponse>(`${this.apiUrl}/csm/brand/`);
  }

  getCsmUsers$(): Observable<ICsmUserResponse> {
    return this._http.get<ICsmUserResponse>(`${this.apiUrl}/csm/brand/users/`);
  }

  assignCsmUser$(assign_user: FormData): Observable<ICsmUserAssignResult> {
    return this._http.post<ICsmUserAssignResult>(`${this.apiUrl}/csm/brand/assignuserbrand/`, assign_user);
  }

  getBatchPublishingRequests$(): Observable<IBatchPublishRequestsResponse> {
    return this._http.get<IBatchPublishRequestsResponse>(`${this.apiUrl}/csm/batchpublish/`);
  }
}
