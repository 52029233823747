import { Injectable, OnDestroy } from '@angular/core';
import { QuestionnaireService } from '@service/questionnaire.service';
import { catchError, Observable, ObservableInput, Subject, take } from 'rxjs';
import {
  IQuestionnaire,
  IQuestionnaireDetailsCreateUpdate,
  IQuestionnaireResponse,
} from '@interface/questionnaire.interface';
import { throwError } from 'rxjs/internal/observable/throwError';
import { generateFormData } from '@app/core/utils/generate-formData';

@Injectable()
export class QuestionnaireFacade implements OnDestroy {
  private _unsubscribe$: Subject<void> = new Subject();

  constructor(private questionnaireService: QuestionnaireService) {}

  ngOnDestroy(): void {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }

  createQuestionnaires$(name: string): Observable<IQuestionnaireDetailsCreateUpdate> {
    const formData = generateFormData({ name });
    return this.questionnaireService.createQuestionnaire$(formData).pipe(
      take(1),
      catchError((err): ObservableInput<IQuestionnaireDetailsCreateUpdate> => throwError(() => err.error))
    );
  }

  createQuestionnaireSection$(uuid: string, name: string): Observable<IQuestionnaireDetailsCreateUpdate> {
    const formData = generateFormData({ name, questionnaire: uuid });
    return this.questionnaireService.createQuestionnaireSection$(formData).pipe(
      take(1),
      catchError((err): ObservableInput<IQuestionnaireDetailsCreateUpdate> => throwError(() => err.error))
    );
  }

  createQuestionnaireSectionQuestion$(
    uuid: string,
    questionnaireUid: string,
    name: string
  ): Observable<IQuestionnaireDetailsCreateUpdate> {
    const formData = generateFormData({ name, section: uuid });
    return this.questionnaireService.createQuestionnaireSectionQuestion$(formData).pipe(
      take(1),
      catchError((err): ObservableInput<IQuestionnaireDetailsCreateUpdate> => throwError(() => err.error))
    );
  }

  getQuestionnaires$(): Observable<IQuestionnaireResponse> {
    return this.questionnaireService.getQuestionnaires$().pipe(
      take(1),
      catchError((err): ObservableInput<IQuestionnaireResponse> => throwError(() => err.error))
    );
  }

  getQuestionnaireDetails$(uuid: string): Observable<IQuestionnaire> {
    return this.questionnaireService.getQuestionnaireDetails$(uuid).pipe(
      take(1),
      catchError((err): ObservableInput<IQuestionnaire> => throwError(() => err.error))
    );
  }

  updateQuestionnaireDetails$(uuid: string, name: string): Observable<IQuestionnaireDetailsCreateUpdate> {
    const formData = generateFormData({ name });
    return this.questionnaireService.updateQuestionnaireDetails$(uuid, formData).pipe(
      take(1),
      catchError((err): ObservableInput<IQuestionnaireDetailsCreateUpdate> => throwError(() => err.error))
    );
  }

  updateQuestionnaireSectionQuestionDetails$(
    uuid: string,
    section: string,
    name: string
  ): Observable<IQuestionnaireDetailsCreateUpdate> {
    const formData = generateFormData({ name, section });
    return this.questionnaireService.updateQuestionnaireSectionQuestionDetails$(uuid, formData).pipe(
      take(1),
      catchError((err): ObservableInput<IQuestionnaireDetailsCreateUpdate> => throwError(() => err.error))
    );
  }

  deleteQuestionnaireSectionQuestion$(uuid: string): Observable<IQuestionnaireDetailsCreateUpdate> {
    return this.questionnaireService.deleteQuestionnaireSectionQuestion$(uuid).pipe(
      take(1),
      catchError((err): ObservableInput<IQuestionnaireDetailsCreateUpdate> => throwError(() => err.error))
    );
  }

  updateQuestionnaireSectionDetails$(
    sectionUuid: string,
    questionnaireUid: string,
    name: string
  ): Observable<IQuestionnaireDetailsCreateUpdate> {
    const formData = generateFormData({ questionnaire: questionnaireUid, name });
    return this.questionnaireService.updateQuestionnaireSectionDetails$(sectionUuid, formData).pipe(
      take(1),
      catchError((err): ObservableInput<IQuestionnaireDetailsCreateUpdate> => throwError(() => err.error))
    );
  }
}
