import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';

import { IProduct, IProductChain, IProductResponse } from '@app/core/interface/products.interface';

@Component({
  selector: 'app-product-supply-chains',
  templateUrl: './product-supply-chains.component.html',
  styleUrls: ['./product-supply-chains.component.scss'],
})
export class ProductSupplyChainsComponent {
  @Input() chains!: Array<IProductChain>;
  @Input() productUuid: string | null = null;
  @Output() isChains = new EventEmitter<boolean>();
  @Input() product!: IProduct | IProductResponse;
  chainUuidList: string[] = [];

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['chains']) {
      this.chains.map(chain => this.chainUuidList.push(chain.uuid));
    }
  }

  onToggleChange(event: boolean) {
    this.isChains.emit(event);
  }
}
