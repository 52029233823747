import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';

import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { LoaderFacade } from '@app/core/facade/loader.facade';

import Timer = NodeJS.Timer;

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LoaderComponent implements OnInit, OnDestroy {
  @Input('delayed')
  set delayed(value: number) {
    if (value > -1) {
      this._delayed = value * 1000;
      this._delayed$.next(this._delayed);
    }
  }

  // Loader view flag
  isLoading = false;

  // Timer default value
  private _timeout = 0;

  // Delayed default value
  private _delayed = 0;

  // Loader timer instance
  private _loaderTimer!: Timer;

  // Delayed observable subject
  private _delayed$: BehaviorSubject<number>;

  constructor(private _facade: LoaderFacade) {
    // Define delayed as behavior subject
    this._delayed$ = new BehaviorSubject<number>(this._delayed);
  }

  ngOnInit(): void {
    // Start reacting on loading flag
    this._facade.loader$(this._delayed$).subscribe(this._onStatusChange.bind(this));
  }

  ngOnDestroy(): void {
    // Remove timer
    if (this._loaderTimer) {
      clearTimeout(this._loaderTimer);
    }
  }

  private _onStatusChange(status: boolean): void {
    // Clear timeout if it's declared
    if (this._loaderTimer) {
      clearTimeout(this._loaderTimer);
    }

    // Show / hide loader
    if (status) {
      // Set new loader timer
      this._loaderTimer = setTimeout(() => (this.isLoading = status), this._timeout - this._delayed);
    } else {
      // Hide loader and remove overlay body class
      this.isLoading = status;
    }
  }
}
