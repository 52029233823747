<h2 mat-dialog-title>{{ 'Add Step' | translate }}</h2>
<mat-dialog-content>
  <div class="col-12 create-step-wraper">
    <form [formGroup]="form">
      <div class="row">
        <div class="col-12">
          <mat-form-field class="input-primary" appearance="outline">
            <mat-label>{{ 'Step Name' | translate }}</mat-label>
            <input matInput formControlName="name" placeholder="Step Name" required />
          </mat-form-field>
        </div>
      </div>

      <div class="row" *ngIf="supplierList?.length">
        <div class="col-12">
          <mat-form-field class="input-primary" appearance="outline">
            <mat-label>{{ 'Supplier' | translate }}</mat-label>
            <input type="text" matInput formControlName="supplier" [matAutocomplete]="auto" placeholder="Supplier" />
            <mat-autocomplete #auto="matAutocomplete" [displayWith]="getSupplierName">
              <mat-option *ngFor="let supplier of filteredOptions$ | async" [value]="supplier">
                {{ supplier.name }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
      </div>

      <div class="row no-suppliers" *ngIf="!supplierList?.length">
        <p>
          You didn’t add any suppliers yet.<br />
          You can create and add it to this step later.
        </p>
      </div>
    </form>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <app-rounded-button color="tetriary-link" [button_text]="'Cancel'" [size]="'md'" mat-dialog-close>
  </app-rounded-button>
  <app-rounded-button
    color="primary"
    [button_text]="'Add Step'"
    [size]="'md'"
    [disabled]="form && !form.valid"
    (click)="addStep()"
  >
  </app-rounded-button>
</mat-dialog-actions>
