<div [ngClass]="general_regulations ? 'bg-dark' : 'bg-light'" class="info-wrapp d-flex flex-column">
  <div class="text-wrapper d-flex full-width align-items-center">
    <h4 class="title m-0">{{ 'Regulations' | translate }}</h4>
    <div
      class="toggle-holder d-flex"
      (hover)="toolTip.style.display = 'block'; toolTip.style.display = 'block'"
      ngModel
    >
      <mat-slide-toggle
        [checked]="general_regulations"
        [color]="'primary'"
        [(ngModel)]="general_regulations"
        [color]="'primary'"
        (change)="
          !general_regulations && warningDialog(general_regulations);
          general_regulations && enableDisableGeneralRegulation(true)
        "
        ngModel
        #generalReg="ngModel"
      >
      </mat-slide-toggle>
      <div class="tooltip" #toolTip>
        <p class="m-0 tooltip-text">
          {{ general_regulations ? ('switch off to disable' | translate) : ('switch on to enable' | translate) }}
        </p>
      </div>
    </div>
  </div>
  <div [ngClass]="general_regulations ? 'text-blue' : 'text-gray'" class="text-holder d-flex align-items-center">
    <app-custom-icon [icon_category]="'essential'" [icon_name]="'info-circle'"> </app-custom-icon>
    <p class="m-0">
      {{ 'Some explanation text for why and when they should switch the regulations on.' | translate }}
    </p>
  </div>

  <div *ngIf="general_regulations" class="divider"></div>
  <div class="full-width regulation-list d-flex flex-column">
    <div *ngIf="general_regulations" class="holder d-flex flex-column">
      <div
        *ngFor="let reg of regulations; let i = index"
        [ngClass]="reg.is_enabled ? 'bg-darkk' : 'bg-lightt'"
        class="info-wrapp d-flex flex-column"
      >
        <div class="text-wrapper d-flex align-items-center">
          <p class="m-0 reg-name">{{ reg.displayName ?? '' | translate }}</p>
          <div
            class="toggle-holder d-flex"
            (mouseover)="toolTip2.style.display = 'block'; toolTip2.style.display = 'block'"
            (mouseout)="toolTip2.style.display = 'none'"
          >
            <mat-slide-toggle
              [checked]="reg.is_enabled"
              [color]="'primary'"
              [color]="'primary'"
              (click)="toggle($event, reg)"
            >
            </mat-slide-toggle>
            <div class="tooltip" #toolTip2>
              <p class="m-0 tooltip-text">
                {{ reg.is_enabled ? ('switch off to disable' | translate) : ('switch on to enable' | translate) }}
              </p>
            </div>
          </div>
        </div>
        <div class="text-holder d-flex align-items-center text-blue">
          <app-custom-icon [icon_category]="'essential'" [icon_name]="'info-circle'"> </app-custom-icon>
          <p class="m-0">
            {{ reg.info ?? 'Explanation text for a regulation' | translate }}
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
