<div class="holder">
  <h2 mat-dialog-title>{{ 'supplier-menu.Manage Profile' | translate }}</h2>
  <form action="" class="profile-form d-flex flex-column" [formGroup]="form">
    <app-personal-data-tooltip></app-personal-data-tooltip>
    <div class="username-holder d-flex flex-column">
      <p class="m-0">{{ 'supplier-menu.Username' | translate }}</p>
      <mat-form-field class="input-primary" appearance="outline">
        <mat-label>{{ 'supplier-menu.Enter Username' | translate }}</mat-label>
        <input
          matInput
          formControlName="username"
          placeholder="{{ 'supplier-menu.Enter Name' | translate }}"
          required
        />
        <mat-error *ngIf="form.controls['username'].hasError('required')">
          {{ 'supplier-menu.Username is required' | translate }}</mat-error
        >
        <mat-error *ngIf="form.controls['username'].hasError('valieExist')">
          {{ 'supplier-menu.Please choose another username as the entered one is already used' | translate }}</mat-error
        >
        <mat-error *ngIf="form.controls['username'].hasError('invalidUserName')">
          {{
            'supplier-menu.Enter a valid username. This value may contain only letters, numbers, and @/./+/-/_ characters'
              | translate
          }}.</mat-error
        >
      </mat-form-field>
    </div>
    <div class="divider"></div>
    <div class="other-info-holder d-flex flex-column align-start">
      <app-file-upload
        [iconType]="'add_photo_alternate'"
        [imageUrl]="imageUrl"
        (fileData)="saveImageData($event)"
        [uploadImageText]="'Upload your avatar'"
      >
      </app-file-upload>
      <div class="first-last-name d-flex">
        <mat-form-field class="input-primary" appearance="outline">
          <mat-label>{{ 'supplier-menu.First Name' | translate }}</mat-label>
          <input matInput formControlName="first_name" placeholder="{{ 'supplier-menu.First Name' | translate }}" />
        </mat-form-field>
        <mat-form-field class="input-primary" appearance="outline">
          <mat-label>{{ 'supplier-menu.Last Name' | translate }}</mat-label>
          <input matInput formControlName="last_name" placeholder="{{ 'supplier-menu.Last Name' | translate }}" />
        </mat-form-field>
      </div>
    </div>
  </form>
  <mat-dialog-actions align="end" class="d-flex justify-content-between">
    <app-data-secured-badge></app-data-secured-badge>
    <div>
      <app-rounded-button
        [color]="'tetriary-link underlined'"
        [button_text]="'supplier-menu.Cancel' | translate"
        mat-dialog-close=""
        [size]="'sm'"
      ></app-rounded-button>

      <app-rounded-button
        class="update"
        (click)="completeProfile()"
        [color]="'turquoise'"
        [size]="'sm'"
        [button_text]="'supplier-menu.Update Profile' | translate"
        [icon_category]="'user'"
        [icon_name]="'user-edit'"
        [icon_right]="true"
      ></app-rounded-button>
    </div>
  </mat-dialog-actions>
</div>
