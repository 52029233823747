<div class="wrapper d-flex flex-column full-height">
  <h2 mat-dialog-title>{{ (data.field ? 'Edit' : 'Create') + ' Custom Field' | translate }}</h2>

  <mat-dialog-content class="m-0">
    <form [formGroup]="form">
      <h6>
        <strong>{{ 'Custom field name*' | translate }}</strong>
      </h6>
      <mat-form-field class="field-name input-primary" appearance="outline">
        <mat-label>{{ 'Enter Field Name' | translate }}</mat-label>
        <input matInput formControlName="name" [placeholder]="'Enter Field Name Here...*' | translate" />
      </mat-form-field>
      <h6 *ngIf="!data.field">
        <strong>{{ 'Select Type of Field*' | translate }}</strong>
      </h6>
      <mat-form-field
        *ngIf="!data.field"
        class="select-type"
        appearance="outline"
        class="input-primary select-type col-6"
      >
        <mat-select
          formControlName="field_type"
          panelClass="dropdown-primary dropdown-select-type"
          placeholder="{{ 'Choose Type' | translate }}"
          (selectionChange)="onFieldTypeChange($event)"
        >
          <mat-select-trigger>
            <div class="trigger-content d-flex align-items-center">
              <p class="p1-strong">{{ selectedFieldType?.name }}</p>
              <p *ngIf="selectedFieldType?.value !== 'RADIO MULTIPLES'" class="description">
                {{ selectedFieldType?.selected_description }}
              </p>
            </div>
          </mat-select-trigger>
          <mat-option class="d-flex align-items-center" *ngFor="let type of fieldTypes" [value]="type.value">
            <p class="p1-strong">{{ type.name }}</p>
            <p class="description">{{ type.description }}</p>
          </mat-option>
        </mat-select>
      </mat-form-field>
      <!-- Create multiple options -->
      <div *ngIf="selectedFieldType?.value === 'RADIO MULTIPLES'" class="multiple-options">
        <h6>
          <strong>{{ 'Create multiple options' | translate }}</strong>
        </h6>
        <p class="p2">
          {{
            'Create multiple options, that will be added to the Custom Field, where one or many can be selected'
              | translate
          }}
        </p>
        <div class="options-list d-flex flex-wrap align-items-center" formArrayName="field_options">
          <ng-container *ngFor="let option of optionsFormArray.controls; let i = index">
            <div class="option-item" [formGroupName]="i">
              <mat-form-field class="input-primary full-width" appearance="outline" subscriptSizing="dynamic">
                <input #fieldOption matInput formControlName="option" placeholder="Enter Option Name*" />
              </mat-form-field>
              <div
                *ngIf="optionsFormArray.controls.length > 1"
                class="remove-field-circle d-flex justify-content-center align-items-center"
                (click)="onRemoveOption(i)"
              >
                <app-custom-icon
                  class="cursor-pointer"
                  icon_category="essential"
                  icon_name="minus-circle"
                  matTooltip="{{ 'remove option' | translate }}"
                  matTooltipClass="custom-tooltip-end"
                  matTooltipPosition="above"
                ></app-custom-icon>
              </div>
            </div>
          </ng-container>
          <app-rounded-button
            class="add-option-btn"
            color="tetriary-link"
            size="md"
            button_text="Add Option"
            icon_category="essential"
            icon_name="add-circle"
            [icon_right]="true"
            [disabled]="optionsFormArray.length > 0 && optionsFormArray.invalid"
            (click)="onAddOption()"
          >
          </app-rounded-button>
        </div>
      </div>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <app-rounded-button
      size="sm"
      color="tetriary-link underlined"
      button_text="{{ 'Cancel' | translate }}"
      mat-dialog-close
    ></app-rounded-button>
    <app-rounded-button
      color="turquoise"
      size="md"
      [disabled]="form.invalid || form.pristine"
      [button_text]="(data.field ? 'Update' : 'Add') + ' Field' | translate"
      (click)="onAddEditField()"
    ></app-rounded-button>
  </mat-dialog-actions>
</div>
