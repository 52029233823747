import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import {
  IBatchChainStep,
  ISuppChainStep,
  ISuppDirectSupplier,
  ISuppStepBatchDirectSupplier,
  ISuppStepDirectSupplier,
} from '@app/core/interface/supp.interface';
import { NotificationType, infoDialogHeight, infoDialogWidth } from '@app/core/constants';

import { AddDirectSupplierComponent } from '../add-direct-supplier/add-direct-supplier.component';
import { CompleteDirectSupplierDialogComponent } from '../complete-direct-supplier-dialog/complete-direct-supplier-dialog.component';
import { DirectSupplierModalComponent } from './direct-supplier-modal/direct-supplier-modal.component';
import { InfoDialogComponent } from '@app/shared/components/info-dialog/info-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { MatRadioGroup } from '@angular/material/radio';
import { MatTableDataSource } from '@angular/material/table';
import { SnackbarService } from '@app/core/service/snackbar.service';
import { SuppSuppliersFacade } from '@app/core/facade/supp-suppliers.facade';
import { TranslateService } from '@ngx-translate/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-step-direct-supplier',
  templateUrl: './step-direct-supplier.component.html',
  styleUrls: ['./step-direct-supplier.component.scss'],
})
export class StepDirectSupplierComponent implements AfterViewInit {
  @Input() step!: ISuppChainStep;
  @Input() dataSource = new MatTableDataSource<ISuppStepBatchDirectSupplier | ISuppStepDirectSupplier>();
  @Input() is_batch!: boolean;
  @Input() stepBatch!: IBatchChainStep;
  @Input() is_view = false;

  @Output() refreshDataSource = new EventEmitter<void>();
  @ViewChild('btnAddDirectSupplier', { read: ElementRef }) btnAddDirectSupplier!: ElementRef<HTMLElement>;
  @ViewChild('reasonNoDirectSuppliers', { read: ElementRef }) reasonNoDirectSuppliers!: ElementRef<HTMLElement>;
  @ViewChild(MatRadioGroup) radioGroup!: MatRadioGroup;
  displayedColumns = ['name', 'supplier', 'email', 'supplier_used', 'actions'];
  isReadMore = false;
  showReadMore = false;
  radioControl!: UntypedFormControl;

  constructor(
    public dialog: MatDialog,
    public _suppSupplierFacade: SuppSuppliersFacade,
    private _snackbarService: SnackbarService,
    private _cdRef: ChangeDetectorRef,
    public translate: TranslateService
  ) {
    this.radioControl = new UntypedFormControl(null);
  }

  ngAfterViewInit(): void {
    // count the number of lines in the paragraph (21 is line height in pixels)
    const numberOfTextLines: number = this.reasonNoDirectSuppliers?.nativeElement.offsetHeight / 21;
    this.showReadMore = numberOfTextLines > 1 ? true : false; // show Read more button if number of lines is greater than 1
    if (this.reasonNoDirectSuppliers) this.reasonNoDirectSuppliers.nativeElement.style.webkitLineClamp = '1'; // set paragraph to show just the first line initially

    this.displayedColumns = this.is_batch
      ? ['name', 'supplier', 'supplier_used', 'actions']
      : ['name', 'supplier', 'email', 'actions'];
    this._cdRef.detectChanges();
  }

  onReadMore() {
    this.isReadMore = !this.isReadMore;
    this.reasonNoDirectSuppliers.nativeElement.style.webkitLineClamp = this.isReadMore ? 'unset' : '1';
  }

  onAddDirectSupplier(dir_supplier?: ISuppStepDirectSupplier) {
    const dialogRef = this.dialog.open(AddDirectSupplierComponent, {
      width: '1160px',
      height: '768px',
      panelClass: 'padding-0',
      data: {
        step_uuid: this.step.uuid,
        dir_supplier: dir_supplier,
      },
    });

    dialogRef.afterClosed().subscribe((result: ISuppStepDirectSupplier) => {
      if (result) this.refreshDataSource.emit();
    });
  }

  completeDirectSupp(directSupplier: ISuppStepBatchDirectSupplier) {
    const initialIsActive = directSupplier.is_active;
    const dialogRef = this.dialog.open(CompleteDirectSupplierDialogComponent, {
      width: '1160px',
      height: '768px',
      panelClass: 'top-padding-0',
      data: {
        step_uuid: this.stepBatch.uuid,
        directSupplier: directSupplier,
        proofs: directSupplier.proofs,
        // is_active: is_active,
      },
    });

    dialogRef.afterClosed().subscribe((result: ISuppDirectSupplier) => {
      if (result) {
        this._suppSupplierFacade.changingFlagSignal.set(true);
        this._suppSupplierFacade.warningDirectSuppliersSignal.set(false);
      } else {
        directSupplier.is_active = initialIsActive;
      }
      this.refreshDataSource.emit(); // Refresh data source regardless of result
    });
  }

  onDeleteDirectSupplier(uuid: string): void {
    const dialogRef = this.dialog.open(InfoDialogComponent, {
      width: infoDialogWidth,
      height: infoDialogHeight,
      data: {
        infoText:
          'You are about to delete the selected direct supplier. Please note that if you delete the supplier now, all of the changes will be lost.',
        confirmationText: 'Do you wish to continue?',
        btnText: 'Yes, Delete',
        type: 'warning',
        text: 'warning-text',
      },
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this._suppSupplierFacade.deleteDirectSupplier$(this.step.uuid, uuid).subscribe({
          next: this._successDelete.bind(this),
          error: this._error.bind(this),
        });
      }
    });
  }
  viewDirectSupplier(directSupplier: ISuppDirectSupplier | ISuppStepBatchDirectSupplier): void {
    this.dialog.open(DirectSupplierModalComponent, {
      width: '1160px',
      height: '768px',
      data: {
        directSupplier: !this.is_batch ? directSupplier : null,
        directSupplierBatch: this.is_batch ? directSupplier : null,
      },
    });
  }

  private _successDelete(): void {
    this._snackbarService.openTypeSnackbar(`The direct supplier is deleted.`, NotificationType.success);
    this.refreshDataSource.emit();
  }

  private _error(error: Record<string, string[]>): void {
    Object.values(error).map(err => this._snackbarService.openTypeSnackbar(err[0], NotificationType.error));
  }
  disapproveSupplier(directSupplier: ISuppStepBatchDirectSupplier) {
    const initialIsActive = directSupplier.is_active;
    const dialogRef = this.dialog.open(InfoDialogComponent, {
      width: infoDialogWidth,
      height: infoDialogHeight,
      data: {
        infoText: this.translate.instant(
          'complete-direct-supplier.Please note that you are about to indicate that you didn’t work with this supplier for this batch. All of the previously entered direct supplier information will be lost.'
        ),
        confirmationText: this.translate.instant('complete-direct-supplier.Do you wish to continue?'),
        btnText: 'complete-direct-supplier.Yes, Continue',
        type: 'warning',
        text: 'warning-text',
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this._suppSupplierFacade.deactivateDirectSupplier$(directSupplier.uuid).subscribe({
          next: this._successDeactivate.bind(this),
          error: this._error.bind(this),
        });
      } else {
        // If cancel button is clicked
        directSupplier.is_active = initialIsActive; // Restore initial value for the clicked element
        this.refreshDataSource.emit();
      }
    });
  }

  private _successDeactivate(): void {
    this._suppSupplierFacade.changingFlagSignal.set(true);
    this._suppSupplierFacade.warningDirectSuppliersSignal.set(false);
    this.refreshDataSource.emit();
  }
}
