<div class="wrapper">
  <div class="row py-2">
    <form [formGroup]="billingInfoForm" class="col-12 p-0">
      <div class="d-flex justify-content-between">
        <mat-form-field appearance="outline" class="w-100 input-primary px-1">
          <mat-label>{{ 'billing-management.First Name' | translate }}</mat-label>
          <input
            matInput
            formControlName="billing_first_name"
            placeholder="{{ 'billing-management.First Name' | translate }}"
          />
        </mat-form-field>
        <mat-form-field appearance="outline" class="w-100 input-primary px-1">
          <mat-label>{{ 'billing-management.Last Name' | translate }}</mat-label>
          <input
            matInput
            formControlName="billing_last_name"
            placeholder="{{ 'billing-management.Last Name' | translate }}"
          />
        </mat-form-field>
      </div>
      <div class="d-flex justify-content-between">
        <mat-form-field appearance="outline" class="w-100 input-primary px-1">
          <mat-label>{{ 'billing-management.Street' | translate }}</mat-label>
          <input matInput formControlName="line1" placeholder="{{ 'billing-management.Street' | translate }}" />
        </mat-form-field>
      </div>
      <div class="d-flex justify-content-between">
        <mat-form-field appearance="outline" class="w-100 input-primary px-1">
          <mat-label>{{ 'billing-management.City' | translate }}</mat-label>
          <input matInput formControlName="city" placeholder="{{ 'billing-management.City' | translate }}" />
        </mat-form-field>
        <mat-form-field appearance="outline" class="w-100 input-primary px-1">
          <mat-label>{{ 'billing-management.Zip Code' | translate }}</mat-label>
          <input matInput formControlName="zip" placeholder="{{ 'billing-management.Zip Code' | translate }}" />
        </mat-form-field>
      </div>
      <div class="d-flex justify-content-between">
        <mat-form-field appearance="outline" class="w-100 input-primary px-1">
          <mat-label>{{ 'billing-management.State' | translate }}</mat-label>
          <input matInput formControlName="state" placeholder="{{ 'billing-management.State' | translate }}" />
        </mat-form-field>
        <mat-form-field appearance="outline" class="w-100 input-primary px-1">
          <mat-label>{{ 'billing-management.Country' | translate }}</mat-label>
          <input matInput formControlName="country" placeholder="{{ 'billing-management.Country' | translate }}" />
        </mat-form-field>
      </div>
    </form>
  </div>
  <mat-dialog-actions align="end">
    <app-rounded-button
      color="secondary"
      class="me-2"
      [button_text]="'billing-management.Cancel' | translate"
      [size]="'md'"
      [icon_right]="false"
      mat-dialog-close
    >
    </app-rounded-button>
    <app-rounded-button
      color="primary"
      [disabled]="billingInfoForm && !billingInfoForm.valid"
      [button_text]="'billing-management.Update' | translate"
      [size]="'md'"
      [icon_right]="false"
      (click)="updateBillingInfo()"
    >
    </app-rounded-button>
  </mat-dialog-actions>
</div>
