import { IPaymentChargebeePaymentForm, IPaymentChargebeePaymentResponse } from '../interface/purchase.interface';
import { Observable, ObservableInput, catchError, take, throwError } from 'rxjs';

import { Injectable } from '@angular/core';
import { PurchaseService } from '../service/purchase.service';
import { generateFormData } from '../utils/generate-formData';

@Injectable()
export class PurchaseFacade {
  constructor(private _purchaseService: PurchaseService) {}

  /**
   * Function to create payment
   */
  createPayment$(data: IPaymentChargebeePaymentForm): Observable<IPaymentChargebeePaymentResponse> {
    const formData = generateFormData(data);

    return this._purchaseService.createPayment$(formData).pipe(
      take(1),
      catchError(
        ({ error }: IPaymentChargebeePaymentResponse): ObservableInput<IPaymentChargebeePaymentResponse> =>
          throwError(() => error)
      )
    );
  }
}
