import { CustomerSuccessManagementService } from '../service/customer-success-management.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { ObservableInput } from 'rxjs/internal/types';
import { catchError } from 'rxjs/internal/operators/catchError';
import { generateFormData } from '../utils/generate-formData';
import { take } from 'rxjs/internal/operators/take';
import { throwError } from 'rxjs/internal/observable/throwError';
import {
  IAssignBrandCsmResponse,
  ICsmUserAssign,
  ICsmUserAssignResult,
  ICsmUserResponse,
} from '../interface/customer-success-management.interface';
import { IBatchPublishRequestsResponse } from '../interface/customer-success-management.interface';

@Injectable()
export class CustomerSuccessManagementFacade {
  constructor(private _customerSuccessManagementService: CustomerSuccessManagementService) {}

  getCsmBrands$(): Observable<IAssignBrandCsmResponse> {
    return this._customerSuccessManagementService.getCsmBrands$().pipe(
      take(1),
      catchError(
        ({ error }: IAssignBrandCsmResponse): ObservableInput<IAssignBrandCsmResponse> => throwError(() => error)
      )
    );
  }

  getCsmUsers$(): Observable<ICsmUserResponse> {
    return this._customerSuccessManagementService.getCsmUsers$().pipe(
      take(1),
      catchError(({ error }: ICsmUserResponse): ObservableInput<ICsmUserResponse> => throwError(() => error))
    );
  }

  assignCsmUser$(data: ICsmUserAssign): Observable<ICsmUserAssignResult> {
    const formData = generateFormData(data);

    return this._customerSuccessManagementService.assignCsmUser$(formData).pipe(
      take(1),
      catchError(({ error }: ICsmUserAssignResult): ObservableInput<ICsmUserAssignResult> => throwError(() => error))
    );
  }

  getBatchPublishingRequests$(): Observable<IBatchPublishRequestsResponse> {
    return this._customerSuccessManagementService.getBatchPublishingRequests$().pipe(
      take(1),
      catchError(
        ({ error }: IBatchPublishRequestsResponse): ObservableInput<IBatchPublishRequestsResponse> =>
          throwError(() => error)
      )
    );
  }
}
