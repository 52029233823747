<div class="d-flex flex-column full-height">
  <h2 mat-dialog-title *ngIf="!isFlagged">{{ 'Flag component as chemical' | translate }}</h2>
  <div class="dialog-title" *ngIf="isFlagged">
    <div class="d-flex justify-content-between flagged-title">
      <div class="d-flex align-items-center">
        <h4 class="m-0">
          <strong>{{ 'Flagged Component' | translate }}</strong>
        </h4>
        <app-custom-icon icon_category="essential" icon_name="flag"></app-custom-icon>
      </div>
      <app-rounded-button
        class="btn-width-180px"
        color="secondary"
        button_text="{{ 'Unflag Component' | translate }}"
        size="md"
        (click)="onFlagUnflagComponent(false)"
      >
      </app-rounded-button>
    </div>
  </div>
  <mat-dialog-content class="flex-grow-1">
    <div *ngIf="showNote" class="note d-flex align-items-center">
      <app-custom-icon class="icon-danger" icon_category="essential" icon_name="danger"></app-custom-icon>
      <p class="p1-strong m-0">
        {{
          'Please note that you will flag this component as chemical. Please enter the fields as identifier number of the checmical.'
            | translate
        }}
      </p>
      <app-custom-icon
        class="icon-close-circle cursor-pointer"
        icon_category="essential"
        icon_name="close-circle"
        (click)="showNote = false"
      ></app-custom-icon>
    </div>
    <form [formGroup]="form" class="d-flex">
      <div class="col">
        <p class="m-0">{{ 'EC number' | translate }}</p>
        <mat-form-field class="input-primary full-width" appearance="outline">
          <input matInput formControlName="ec_list" placeholder="{{ 'Enter number' | translate }}" />
        </mat-form-field>
      </div>
      <div class="col">
        <p class="m-0">{{ 'CAS number' | translate }}</p>
        <mat-form-field class="input-primary full-width" appearance="outline">
          <input matInput formControlName="cas_no" placeholder="{{ 'Enter number' | translate }}" />
        </mat-form-field>
      </div>
    </form>
    <div *ngIf="isFlagged" class="update-button d-flex justify-content-end">
      <app-rounded-button
        class="btn-width-180px"
        color="turquoise"
        size="md"
        [button_text]="'Update numbers' | translate"
        [disabled]="form.pristine || form.invalid"
        (click)="onFlagUnflagComponent(true)"
      >
      </app-rounded-button>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <ng-container *ngIf="!isFlagged; else doneButton">
      <app-rounded-button
        class="btn-width-180px"
        color="tetriary-link underlined"
        button_text="{{ 'Cancel' | translate }}"
        size="md"
        mat-dialog-close
      >
      </app-rounded-button>
      <app-rounded-button
        class="btn-width-180px"
        color="primary"
        button_text="{{ 'Flag Component' | translate }}"
        size="md"
        [disabled]="form.invalid"
        (click)="onFlagUnflagComponent(true)"
      >
      </app-rounded-button>
    </ng-container>
    <ng-template #doneButton>
      <app-rounded-button
        class="btn-width-180px"
        color="primary"
        button_text="{{ 'Done' | translate }}"
        size="md"
        mat-dialog-close
      >
      </app-rounded-button>
    </ng-template>
  </mat-dialog-actions>
</div>
