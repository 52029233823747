<div class="sites-table-wraper">
  <table mat-table matSort [dataSource]="tableDataSource" class="custom-table">
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'company-information.Actions' | translate }}</th>
      <td mat-cell *matCellDef="let element" class="actions-body text-center">
        <app-custom-icon
          id="edit"
          [icon_category]="'files'"
          [icon_name]="'edit'"
          matTooltip="{{ 'Edit Site' | translate }}"
          matTooltipClass="custom-tooltip-center"
          matTooltipPosition="above"
          (click)="editSiteDialog(element)"
          class="cursor-pointer icn"
        ></app-custom-icon>

        <app-custom-icon
          id="trash"
          [icon_category]="'essential'"
          [icon_name]="'trash'"
          matTooltip="{{ 'Delete Site' | translate }}"
          matTooltipClass="custom-tooltip-end"
          matTooltipPosition="above"
          (click)="deleteSiteDialog(element)"
          class="cursor-pointer icn-del"
        >
        </app-custom-icon>
      </td>
    </ng-container>
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'company-information.Name' | translate }}</th>
      <td mat-cell *matCellDef="let element">{{ element.name }}</td>
    </ng-container>
    <ng-container matColumnDef="country">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'company-information.Country' | translate }}</th>
      <td mat-cell *matCellDef="let element">
        {{ element.country ? element.country : ('company-information.Not set' | translate) }}
      </td>
    </ng-container>
    <ng-container matColumnDef="region">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'company-information.Region' | translate }}</th>
      <td mat-cell *matCellDef="let element">
        {{ element.region ? element.region : ('company-information.Not set' | translate) }}
      </td>
    </ng-container>
    <ng-container matColumnDef="city">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'company-information.City' | translate }}</th>
      <td mat-cell *matCellDef="let element">
        {{ element.city ? element.city : ('company-information.Not set' | translate) }}
      </td>
    </ng-container>
    <ng-container matColumnDef="address">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'company-information.Address' | translate }}</th>
      <td mat-cell *matCellDef="let element">
        {{ element.address ? element.address : ('company-information.Not set' | translate) }}
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>
