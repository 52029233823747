<div class="dialog-header">
  <h2>{{ 'Supplier Details' | translate }}</h2>
  <app-custom-icon mat-dialog-close [icon_category]="'essential'" [icon_name]="'close-circle'" class="cursor-pointer">
  </app-custom-icon>
</div>

<div class="holder">
  <div class="supplier-info">
    <div class="suplier-logo">
      <app-file-upload
        [placeholder]="supplier?.logo ? supplier?.logo : ('Logo' | translate)"
        [iconType]="'add_photo_alternate'"
        [imageUrl]="supplier?.logo"
        [readonly]="true"
      ></app-file-upload>
    </div>

    <div class="info d-flex justify-content-between">
      <div class="info-folder">
        <div class="info-header">
          <div class="name">
            <h5>
              {{ supplier?.name }}
            </h5>
          </div>
        </div>
        <div class="address">
          {{ 'Main site address' | translate }} :
          <span *ngIf="supplier?.address; else noneblock">
            {{ supplier?.address }}
          </span>
        </div>
      </div>
      <div *ngIf="supplier?.info_validated" class="validation d-flex">
        <div class="green-cir"></div>
        <p class="validationdate m-0">
          {{ ('Information validated on ' | translate) + (supplier?.info_validated_on | date: 'dd.MM.yyyy') }}
        </p>
      </div>
    </div>
  </div>

  <div class="divider"></div>

  <div class="supplier-description">
    <h6>{{ 'Supplier Description' | translate }}</h6>
    <p>
      <app-view-rich-text [content]="supplier?.description ?? ''"></app-view-rich-text>
    </p>
  </div>
</div>

<ng-template #noneblock>
  <span class="col-8">
    {{ 'None' | translate }}
  </span>
</ng-template>
