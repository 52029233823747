<div class="publication-setup-wrapper d-flex flex-column">
  <div class="header d-flex justify-content-between align-items-center">
    <h6 class="header-text">{{ 'Configure what you want to display for the Publication' | translate }}</h6>
    <div class="custom-button-wrapper">
      <app-rounded-button
        color="primary"
        [icon_category]="'essential'"
        [icon_name]="'publication'"
        [icon_right]="true"
        [button_text]="'Save publication setup'"
        [size]="'mb'"
        matTooltip="{{ 'All publications will be updated' | translate }}"
        matTooltipClass="custom-tooltip-center centering"
        matTooltipPosition="below"
        (click)="onSubmit()"
      >
      </app-rounded-button>
    </div>
  </div>
  <div class="body-wrapper d-flex">
    <!-- FORM -->
    <form [formGroup]="form" class="flex-grow-1">
      <div formArrayName="dynamicFields">
        <!-- Toggle fields -->
        <div class="form-wrapper flex-grow-1">
          <div class="toggle-wrapper d-flex align-items-center" [formGroupName]="0">
            <div class="label">{{ 'Product description' | translate }}</div>
            <mat-slide-toggle
              [checked]="!form.get('dynamicFields.0.is_description_private')?.value"
              (change)="onToggleChange(0, 'is_description_private', $event)"
            ></mat-slide-toggle>
          </div>
          <div class="toggle-wrapper d-flex align-items-center" [formGroupName]="1">
            <div class="label">{{ 'Product reference number' | translate }}</div>
            <mat-slide-toggle
              [checked]="!form.get('dynamicFields.1.is_reference_private')?.value"
              (change)="onToggleChange(1, 'is_reference_private', $event)"
            ></mat-slide-toggle>
          </div>
          <!-- Toggles for custom fields. -->
          @for (item of dynamicFields?.controls?.slice(2); track $index) {
            <div class="toggle-wrapper d-flex align-items-center" [formGroupName]="$index + 2">
              <div class="label">{{ getCustomFieldName(item) }}{{ ' is a custom field' | translate }}</div>
              <mat-slide-toggle
                [checked]="!item.get(getCustomFieldFormControl(item))?.value"
                (change)="onToggleChange($index + 2, getCustomFieldFormControl(item), $event)"
              ></mat-slide-toggle>
            </div>
          }
        </div>
      </div>
    </form>
    <div class="card-wrapper flex-grow-1">
      <mat-card>
        <div class="inner-card-wrapper">
          <div class="image">
            <img src="{{ '../../../../assets/images/brand-avatar.png' }}" alt="" />
          </div>
          <div class="content">
            <h6>{{ 'Check useful data tab' | translate }}</h6>
            <p class="p2-normal">{{ 'to manage the media that will be displayed in publication.' | translate }}</p>
          </div>
        </div>
      </mat-card>
    </div>
  </div>
</div>

<!-- TEMPLATES -->
<ng-template #toggleButton>
  <div class="toggle-wrapper d-flex align-items-center">
    <div class="label">{{ 'Product description' | translate }}</div>
    <mat-slide-toggle></mat-slide-toggle>
  </div>
</ng-template>
