import { Injectable, OnDestroy } from '@angular/core';

import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { LoaderService } from '@service/loader.service';
import { Observable } from 'rxjs/internal/Observable';
import { Subject } from 'rxjs/internal/Subject';
import { debounce } from 'rxjs/internal/operators/debounce';
import { map } from 'rxjs/internal/operators/map';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { timer } from 'rxjs/internal/observable/timer';
import { withLatestFrom } from 'rxjs/internal/operators/withLatestFrom';

@Injectable()
export class LoaderFacade implements OnDestroy {
  // Unsubscribe cleaner
  private _unsubscribe$: Subject<void> = new Subject();

  ngOnDestroy(): void {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }

  /**
   * Loader debounced observable
   */
  loader$(delayed$: BehaviorSubject<number>): Observable<boolean> {
    return LoaderService.isLoading$.pipe(
      takeUntil(this._unsubscribe$),
      withLatestFrom(delayed$),
      debounce(values => timer(values[1])),
      map(values => values[0])
    );
  }
}
