import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { Injectable } from '@angular/core';

@Injectable()
export class BillingManagementGroup {
  customerDetailsForm: UntypedFormGroup;
  billingInfoForm: UntypedFormGroup;
  creditCardForm: UntypedFormGroup;

  constructor(private _fb: UntypedFormBuilder) {
    this.customerDetailsForm = this._fb.group({
      first_name: [null, [Validators.required]],
      last_name: [null, [Validators.required]],
      email: [{ value: null, disabled: true }, [Validators.required]],
      phone: [null, [Validators.required]],
    });

    this.billingInfoForm = this._fb.group({
      billing_first_name: [null, [Validators.required]],
      billing_last_name: [null, [Validators.required]],
      line1: [null, [Validators.required]],
      city: [null, [Validators.required]],
      state: [null],
      zip: [null],
      country: [null, [Validators.required]],
    });

    this.creditCardForm = this._fb.group({
      id: [null],
      first_name: [null, [Validators.required]],
      last_name: [null, [Validators.required]],
      number: [null],
      cvv: [null],
      expiry_month: [null, [Validators.required, Validators.pattern('((^0[1-9]$)|(^1[0-2]$))')]],
      expiry_year: [null, [Validators.required]],
    });
  }
}
