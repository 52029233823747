<!-- Supplier Dashboard Header -->
<div class="supp-wrap">
  <mat-card class="supplier-dashboard-header row">
    <h2>{{ 'supplier.Supplier Dashboard' | translate }}</h2>
    <mat-form-field class="search-input-wrapper offset-3 col-5">
      <mat-icon matPrefix>search</mat-icon>
      <input
        matInput
        type="search"
        (ngModelChange)="onQuickFilterChanged($event)"
        placeholder="{{ 'supplier.Search' | translate }}"
        #search
        ngModel
        class="search-input"
      />
    </mat-form-field>
    <div class="create-button">
      <button mat-raised-button color="secondary" (click)="createSupplierDialog()">
        {{ 'supplier.Create New Supplier' | translate }}
      </button>
      <button mat-raised-button color="primary" routerLink="supplier-list">
        {{ 'Supplier List' | translate }}
      </button>
    </div>
  </mat-card>
  <!-- Supplier Dashboard Sections -->

  <div class="row" id="container">
    <mat-card class="col-2 supplier-info-left">
      <!-- <mat-card-header> -->
      <h4 class="recent-supplier-header">
        {{ 'supplier.Recent Suppliers' | translate }}
      </h4>

      <!-- Recent Suppliers-->
      <mat-card-content class="cards-wrapper">
        <app-recent-suppliers-list [suppliers]="suppliers"></app-recent-suppliers-list>
      </mat-card-content>
    </mat-card>
    <!--Supplier info-->
    <div class="col-10 supplier-info-wrapper">
      <div class="row info-wrappers-height">
        <!--Supplier info 1-->
        <mat-card class="supplier-info-1 col-4">
          <!--Supplier info 1 stats-->
          <mat-card class="supplier-info-1-card">
            <h4>{{ 'supplier.Suppliers Cube Status' | translate }}</h4>
            <div class="supplier-info">
              <mat-card class="supplier-info-1-card" *ngFor="let stat of cubeStatus">
                <mat-card-header class="supplier-info-1-title">
                  <h4 class="supplier-cube-status-title">
                    {{ stat.title | translate }}
                  </h4>
                </mat-card-header>
                <mat-card-content class="supplier-cube-status">
                  {{ stat.value }}
                </mat-card-content>
              </mat-card>
            </div>
          </mat-card>
          <!--Supplier info 1 Alerts-->

          <mat-card class="supplier-info-table row">
            <h4 class="supplier-alerts">{{ 'supplier.Supplier Alerts' | translate }}</h4>

            <!-- MAT TABLE -->
            <mat-card-content class="alerts-content">
              <app-alerts-table [data]="dataSourceData"></app-alerts-table>
            </mat-card-content>
          </mat-card>
        </mat-card>
        <!--Supplier info 2-->
        <mat-card class="supplier-info-2 col-3">
          <mat-card class="supplier-info-2-card" *ngFor="let stat of stats">
            <mat-card-header class="info-header">
              <h4 class="title">
                {{ stat.title | translate }}
              </h4>
            </mat-card-header>
            <mat-card-content class="info-content">
              <p class="supplier-card-value">
                {{ stat.value }}
              </p>
            </mat-card-content>
          </mat-card>
        </mat-card>
      </div>
    </div>
  </div>
</div>
