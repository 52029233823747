import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BatchPublishingRoutingModule } from './batch-publishing-routing.module';
import { BatchPublishingComponent } from './batch-publishing.component';
import { AngularMaterialModule } from '@app/material.module';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { BatchPublicationRequestsTableComponent } from './batch-publication-requests-table/batch-publication-requests-table.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [BatchPublishingComponent, BatchPublicationRequestsTableComponent],
  imports: [
    CommonModule,
    AngularMaterialModule,
    BatchPublishingRoutingModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    TranslateModule.forChild({}),
  ],
})
export class BatchPublishingModule {}
