<div class="wrp">
  <!-- <mat-card> -->
  <div class="head-wrap">
    <h5 class="header m-0">{{ 'Packaging - Hazardous Substances' | translate }}</h5>

    <div #infoWrap class="info-wrapp">
      <div class="info" #info>
        <span>
          {{
            'SVHC (Substances of Very High Concern) are substances of great concern in Europe, listed as candidates
            for authorization under the REACH regulation. Once a substance is identified by REACH, it must be reported
            in your AGEC sheet within six months. For more information, you can refer to the ' | translate
          }}

          <a href="https://echa.europa.eu/fr/candidate-list-table" target="_blank">REACH SVHC list</a>
        </span>
      </div>
      <app-custom-icon
        class="cursor-pointer"
        id="info"
        [icon_category]="'essential'"
        [icon_name]="'info-circle'"
        (click)="info.style.display = 'flex'"
      ></app-custom-icon>
    </div>
  </div>

  <form [formGroup]="form">
    <mat-radio-group
      class="d-flex justify-content-between re-gr"
      formControlName="hazardous"
      aria-label="Select an option"
    >
      <div class="radio-wrapper d-flex">
        <div class="radio-section d-flex flex-column">
          <div class="wp2 d-flex flex-column">
            <p class="p2 m-0">
              {{ 'If the packaging contains less than 0.1% SVHC, choose this option' | translate }}
            </p>
          </div>
          <div class="radio-wrp">
            <mat-radio-button [value]="false" class="align-items-start">
              {{ 'The packaging does not contain any substance(s) of very high concern' | translate }}
            </mat-radio-button>
          </div>
        </div>
        <div class="radio-section d-flex flex-column">
          <div class="wp2 d-flex flex-column">
            <p class="p2 m-0">
              {{ 'If the packaging contains more than 0.1% SVHC, choose this option' | translate }}
            </p>
          </div>
          <div class="radio-wrp">
            <mat-radio-button [value]="true" class="align-items-start">
              {{ 'The packaging contains substance(s) of very high concern' | translate }}
            </mat-radio-button>
          </div>

          <div class="action d-flex align-items-center" *ngIf="form.controls['hazardous'].value === true">
            <div class="input-wrp" *ngFor="let control of list?.controls ?? []; let i = index">
              <mat-form-field appearance="outline" class="w-100 input-primary px-1 col-10">
                <mat-label>{{ 'Substance Name' | translate }}</mat-label>
                <input
                  matInput
                  placeholder="{{ 'Enter substance Name' | translate }}"
                  [formControl]="$any(control)"
                  required
                />
              </mat-form-field>
              <app-custom-icon
                id="arrow-down"
                [icon_category]="'essential'"
                *ngIf="i > 0"
                [icon_name]="'minus-circle'"
                class="col-1 remove-btn"
                (click)="removeInput(i)"
              ></app-custom-icon>
            </div>
            <span class="add-p"
              >{{ 'Add Subsctance' | translate }}
              <app-custom-icon
                [icon_category]="'essential'"
                [icon_name]="'add-circle'"
                class="ic"
                (click)="addInput()"
              ></app-custom-icon>
            </span>
          </div>
        </div>
      </div>
    </mat-radio-group>
  </form>
  <!-- </mat-card> -->
</div>
