<!-- Batch Publishing Header -->
<mat-card class="batch-publishing-header row">
  <h2>Batch Publishing Management</h2>
  <!-- <h2>{{ "Batch Publishing" | translate }}</h2> -->
</mat-card>

<!-- Batch Publishing Sections -->
<div class="publication-requests-table row">
  <mat-card class="col-6">
    <h4>Publication Requests</h4>

    <mat-card-content class="table-holder">
      <app-batch-publication-requests-table></app-batch-publication-requests-table>
    </mat-card-content>
  </mat-card>
</div>
