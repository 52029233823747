import { Chart, ChartOptions, ChartType } from 'chart.js';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

import { NodeService } from '@app/core/service/node.service';

@Component({
  selector: 'app-doughnut-chart',
  templateUrl: './doughnut.component.html',
  styleUrls: ['./doughnut.component.scss'],
})
export class DoughnutComponent implements OnChanges {
  @Input() selectedPhase = '';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() doughnutChartData!: any;
  chart!: Chart;
  options?: ChartOptions = {
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: data => {
            return data.label;
          },
        },
      },
    },
    events: ['mousemove', 'click'],
  };
  doughnutChartType: ChartType = 'doughnut';

  constructor(private nodeService: NodeService) {}

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  chartClicked(event: any): void {
    const index = event.active[0].index;
    const newSelectedPhase = this.doughnutChartData.labels?.[index];

    this.doughnutChartData.datasets[0].backgroundColor?.forEach((_element: string, i: number) => {
      this.doughnutChartData.datasets[0].backgroundColor[i] = this.doughnutChartData.datasets[0].hoverBorderColor[i];
      if (i !== index && newSelectedPhase !== this.selectedPhase) {
        this.doughnutChartData.datasets[0].backgroundColor[i] = '#EBECED';
      }
    });

    if (newSelectedPhase !== this.selectedPhase) {
      this.nodeService.selectedPhase.next(newSelectedPhase);
      this.selectedPhase = this.doughnutChartData.labels?.[index];
    } else {
      this.nodeService.selectedPhase.next(null);
      this.selectedPhase = '';
    }

    this.chart = event.event.chart;

    event.event.chart.update();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['selectedPhase']?.currentValue === 'all') {
      this.doughnutChartData.datasets[0].backgroundColor?.forEach((_element: string, i: number) => {
        this.doughnutChartData.datasets[0].backgroundColor[i] = this.doughnutChartData.datasets[0].hoverBorderColor[i];
      });

      this.chart.update();
      this.selectedPhase = '';
    }
  }
}
