import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { Injectable } from '@angular/core';
import { IsCountry } from '@app/core/utils/country.validator';

@Injectable()
export class SupplierDashboardGroup {
  newSupplierForm: UntypedFormGroup;

  constructor(private _fb: UntypedFormBuilder) {
    this.newSupplierForm = this._fb.group(
      {
        name: [null, [Validators.required]],
        description: [null],
        logo: [null],
        address: [null],
        country: [null],
        city: [null],
        supplier_unique_identifier: [null],
        unique_identifier_type: [null],
        is_hidden: [false],
      },
      { validator: IsCountry() }
    );
  }
}
