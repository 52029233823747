<div class="row wrp">
  <div class="flex-h">
    <p class="strong hs">{{ 'Product Packaging' | translate }}</p>
    <span class="col-6 add-p">
      <app-rounded-button
        size="md"
        color="primary"
        button_text="Add Packaging"
        (click)="addPac()"
        [icon_category]="'essential'"
        [icon_name]="'3dcube'"
        [icon_right]="true"
      >
      </app-rounded-button>
    </span>
  </div>
  <div class="inf-wrp">
    <app-custom-icon
      class="cursor-pointer ic"
      id="info"
      [icon_category]="'essential'"
      [icon_name]="'info-circle'"
    ></app-custom-icon>
    <span class="pack-info col-11">{{ 'Please list all the types of packaging used for this products.' }}</span>
  </div>
  <div *ngIf="packaging.length < 1" class="no-pac"></div>
  <div *ngIf="packaging.length < 1" class="no-pack-screen row">
    <div class="offset-2 col-8 row">
      <div class="col-2">
        <div class="pack-ic">
          <app-custom-icon [icon_category]="'essential'" [icon_name]="'3dcube'"></app-custom-icon>
          <!-- <img src="../../../../../../assets/Icons/essential/32px/3dcube-32.svg" alt="Packaging"> -->
        </div>
      </div>
      <div class="col-10">
        <h4 class="normal nopack-head">
          {{ 'There is no packaging added yet.' | translate }}
        </h4>
        <h6 class="normal nopack-inf">
          {{ 'Please add packaging' | translate }}
        </h6>
      </div>
    </div>
  </div>
  <div *ngIf="packaging.length > 0" class="pac">
    <div *ngFor="let pac of packaging; let i = index">
      <div class="view-wrap">
        <app-packaging-view
          [packaging]="pac"
          (packageDeleted)="removePackaging($event)"
          (refresh)="$event && refresh.emit(true)"
          [product]="product"
        ></app-packaging-view>
      </div>
    </div>
  </div>
</div>
