<div class="table-wraper">
  <div class="inner-wraper d-flex flex-wrap">
    <div class="d-flex justify-content-between align-items-center full-width">
      <h6 class="m-0">
        <strong>{{ 'supply-chain-step.Steps List' | translate }}</strong>
      </h6>
      <!-- <mat-form-field class="input-primary mat-form-field-10-px-brd-rd custom-filter-header" appearance="outline"> -->
      <mat-form-field class="input-primary mat-form-field-10-px-brd-rd" appearance="outline">
        <mat-label class="label-text">{{ 'supply-chain-step.Quick search...' | translate }}</mat-label>
        <input
          matInput
          (keyup)="applyFilter($event)"
          [placeholder]="'supply-chain-step.Quick search...' | translate"
          #input
        />
        <app-custom-icon
          class="search-filter"
          [icon_category]="'search'"
          [icon_name]="'search-normal-2'"
          matIconSuffix
        ></app-custom-icon>
      </mat-form-field>
      <div class="width-200px"></div>
    </div>

    <div *ngIf="showNote" class="note d-flex justify-content-between align-items-center">
      <img class="logo" src="assets/logos/symbol-logo-dark.svg" alt="{{ 'pdna logo' | translate }}" />
      <p class="p1-strong m-0">
        {{
          "supply-chain-step.Please note that your company was placed as a supplier in each of these steps of the brand's
        products supply chains and batches. Please click on the edit button to review and complete each of these steps
        that are in status “To update”." | translate
        }}
      </p>
      <app-custom-icon
        class="close-icon cursor-pointer"
        icon_category="essential"
        icon_name="close-circle"
        (click)="showNote = !showNote"
      ></app-custom-icon>
    </div>

    <table mat-table #outerSort="matSort" [dataSource]="dataSource" multiTemplateDataRows class="custom-table" matSort>
      <ng-container matColumnDef="{{ column.property }}" *ngFor="let column of columnsToDisplay">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ column.label }}</th>
        <td mat-cell *matCellDef="let element">
          <app-custom-icon
            class="cursor-pointer expand-icon"
            icon_category="arrows"
            icon_name="{{ element.expanded ? 'arrow-circle-up' : 'arrow-circle-down' }}"
            *ngIf="column.property === 'name' && element.batch_chains?.data?.length"
          ></app-custom-icon>
          <app-custom-icon
            class="cursor-pointer action-icon"
            icon_category="{{ !isStatusComplete(element) ? 'files' : 'essential' }}"
            icon_name="{{ !isStatusComplete(element) ? 'edit' : 'send-2' }}"
            *ngIf="column.property === 'actions' && element.supplier_status !== NOT_APPLICABLE"
            [ngClass]="{
              'send-icon': isStatusComplete(element),
            }"
            (click)="editStep(element)"
            [matTooltipDisabled]="!isStatusComplete(element)"
            [matTooltip]="
              ('supply-chain-step.This step is not editable anymore if you need to edit it please click here to send an edit request'
                | translate) + '.'
            "
            matTooltipPosition="above"
            matTooltipClass="custom-tooltip-end"
          ></app-custom-icon>
          <app-custom-icon
            class="cursor-pointer action-icon ms-3"
            icon_category="security"
            icon_name="eye"
            *ngIf="
              column.property === 'actions' && isStatusComplete(element) && element.supplier_status !== NOT_APPLICABLE
            "
            (click)="viewStep(element)"
            [matTooltip]="('supply-chain-step.View step' | translate) + '.'"
            matTooltipPosition="above"
            matTooltipClass="custom-tooltip-end"
          ></app-custom-icon>
          <div
            *ngIf="column.property === 'status'"
            class="status"
            [ngClass]="{
              'to-update': element[column.property].toLowerCase() === 'to update',
              updated: element[column.property].toLowerCase() === 'updated',
            }"
          ></div>
          <span>{{ element[column.property] }}</span>
        </td>
      </ng-container>

      <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
      <ng-container matColumnDef="expandedDetail">
        <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length">
          <div
            class="example-element-detail"
            *ngIf="element.batch_chains?.data?.length"
            [@detailExpand]="element.expanded ? 'expanded' : 'collapsed'"
          >
            <div class="inner-table" *ngIf="expandedElement">
              <table
                #innerTables
                mat-table
                #innerSort="matSort"
                [dataSource]="element.batch_chains"
                matSort
                class="custom-table"
              >
                <ng-container [matColumnDef]="innerColumn.property" *ngFor="let innerColumn of innerDisplayedColumns">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ innerColumn.label }}</th>
                  <td mat-cell *matCellDef="let element">
                    <app-custom-icon
                      class="cursor-pointer action-icon"
                      icon_category="{{ !isStatusComplete(element) ? 'files' : 'essential' }}"
                      icon_name="{{ !isStatusComplete(element) ? 'edit' : 'send-2' }}"
                      [ngClass]="{
                        'send-icon': isStatusComplete(element),
                      }"
                      *ngIf="innerColumn.property === 'actions' && element.supplier_status !== NOT_APPLICABLE"
                      (click)="editBatchStep(element)"
                      matTooltip="This step is not editable anymore if you need to edit it please click here to send an edit request"
                      [matTooltipDisabled]="!isStatusComplete(element)"
                      [matTooltip]="
                        ('supply-chain-step.Please first confirm if for this batch, you have worked with this supplier'
                          | translate) + '.'
                      "
                      matTooltipPosition="above"
                      matTooltipClass="custom-tooltip-end"
                    ></app-custom-icon>

                    <app-custom-icon
                      class="cursor-pointer action-icon ms-3"
                      icon_category="security"
                      icon_name="eye"
                      *ngIf="
                        innerColumn.property === 'actions' &&
                        isStatusComplete(element) &&
                        element.supplier_status !== NOT_APPLICABLE
                      "
                      (click)="viewStepBatch(element)"
                      matTooltip="View batch"
                      [matTooltip]="('supply-chain-step.View batch' | translate) + '.'"
                      matTooltipPosition="above"
                      matTooltipClass="custom-tooltip-end"
                    ></app-custom-icon>
                    <div
                      *ngIf="innerColumn.property === 'status'"
                      class="status"
                      [ngClass]="{
                        'to-update': element[innerColumn.property].toLowerCase() === 'to update',
                        updated: element[innerColumn.property].toLowerCase() === 'updated',
                      }"
                    ></div>
                    {{ element[innerColumn.property] }}
                  </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="getInnerTableHeaderColumns()"></tr>
                <tr mat-row *matRowDef="let row; columns: getInnerTableHeaderColumns()"></tr>
              </table>
            </div>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="getTableHeaderColumns()"></tr>
      <tr
        mat-row
        *matRowDef="let element; columns: getTableHeaderColumns()"
        [class.example-element-row]="element.batch_chains?.data?.length"
        [class.example-expanded-row]="expandedElement === element.extended"
        (click)="toggleRow(element)"
      ></tr>
      <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
    </table>
  </div>

  <div class="card-footer">
    <mat-paginator [pageSizeOptions]="[8, 12, 16]" showFirstLastButtons aria-label="Select page of steps">
    </mat-paginator>
  </div>
</div>
