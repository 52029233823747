<div class="wrapper">
  <div class="header">
    <h1>{{ data.classification ? ('Edit Classification' | translate) : ('Create Classification' | translate) }}</h1>
  </div>

  <div class="content">
    <form [formGroup]="form">
      <div class="row">
        <div class="col-5">
          <h5>{{ 'Classification Name' | translate }}</h5>
          <mat-form-field class="input-primary" appearance="outline">
            <mat-label>{{ 'Classification Name' | translate }}</mat-label>
            <input matInput formControlName="name" placeholder="{{ 'Enter Name' | translate }}" required />
          </mat-form-field>
        </div>
        <div class="col-12">
          <h5>{{ 'Classification Color' | translate }}</h5>
          <span class="note">{{
            'We suggest to use a color that is significantly different from other classifications color.' | translate
          }}</span>
          <div class="color-wraper" (click)="colorPicker.click()">
            <p>{{ 'Change color here' | translate }}</p>
            <div class="cluster-color" [style.background]="form.get('color')?.value"></div>
          </div>
          <input
            class="color-picker-input"
            #colorPicker
            [colorPicker]="form.get('color')?.value"
            (colorPickerChange)="colorPickerChange($event)"
          />
        </div>
      </div>
    </form>
  </div>
</div>
<div class="footer">
  <app-rounded-button
    color="primary"
    [button_text]="data.classification ? 'Done Editing' : 'Create Classification'"
    [size]="'md'"
    [icon_right]="!data.classification"
    [icon_category]="'essential'"
    [icon_name]="'classification'"
    (click)="!data.classification ? saveChanges() : updateClassification()"
    [disabled]="form && !form.valid"
  >
  </app-rounded-button>
</div>
