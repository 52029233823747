import { Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { IsCountry } from '@app/core/utils/country.validator';

@Injectable()
export class CompanyInfoGroup {
  address_group!: UntypedFormGroup;
  identifier_group!: UntypedFormGroup;
  description_group!: UntypedFormGroup;

  constructor(private _fb: UntypedFormBuilder) {
    this.address_group = this._fb.group(
      {
        country: [null, Validators.required],
        region: [null],
        city: [null],
        address: [null],
      },
      { validator: IsCountry() }
    );
    this.identifier_group = this._fb.group({
      supplier_unique_identifier: [null, Validators.required],
      unique_identifier_type: [null, Validators.required],
    });
    this.description_group = this._fb.group({
      description: [null],
    });
  }
}
