import { RouterModule, Routes } from '@angular/router';

import { CertificateDashboardModule } from './certificates/certificate-dashboard/certificate-dashboard.module';
import { CertificatesDashboardModule } from './certificates/certificates-dashboard.module';
import { DashboardComponent } from '@app/module/dashboard/dashboard.component';
import { DashboardOverviewComponent } from './dashboard-overview/dashboard-overview.component';
import { NgModule } from '@angular/core';
import { ProductChainDashboardModule } from './product-chain/product-chain-dashboard.module';
import { ProductDashboardModule } from './product/product-dashboard.module';
import { SupplierDashboardModule } from './supplier/supplier-dashboard.module';
import { TasksDashboardModule } from './tasks-dashboard/tasks-dashboard.module';

const routes: Routes = [
  {
    path: '',
    component: DashboardComponent,
    children: [
      {
        path: '',
        component: DashboardOverviewComponent,
      },
      {
        path: 'supplier',
        loadChildren: () => SupplierDashboardModule,
      },
      {
        path: 'product',
        loadChildren: () => ProductDashboardModule,
      },
      {
        path: 'certificates',
        loadChildren: () => CertificatesDashboardModule,
      },
      {
        path: 'certificate',
        loadChildren: () => CertificateDashboardModule,
      },
      {
        path: 'certificate/:id',
        loadChildren: () => CertificateDashboardModule,
      },
      {
        path: 'product-chains',
        loadChildren: () => ProductChainDashboardModule,
      },

      {
        path: 'tasks',
        loadChildren: () => TasksDashboardModule,
      },
      {
        path: '**',
        redirectTo: '',
        pathMatch: 'full',
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DashboardRoutingModule {}
