<div class="container-fluid">
  <div class="row container-inside">
    <div class="col-md-6 left p0">
      <!-- Illustration-design cpomponent witch can be used multiple times only with diferent input color -->
      <app-illustration-design color="primary"></app-illustration-design>
    </div>
    <div class="col-md-6 right">
      <div class="content">
        <div class="text-inputs-button mb-0">
          <div class="text-box-inputs mb-0">
            <div class="title-box">
              <h2 class="mb-0">{{ 'account-setup.Reset your Password' | translate }}</h2>
              <h5 class="mb-0">{{ 'account-setup.Link was sent to your email' | translate }}</h5>
            </div>
            <div class="info mb-0">
              <p class="mb-0">
                {{ 'account-setup.A link was sent to your email,' | translate }} <br class="brake-sm" />
                {{ 'account-setup.in order to reset your password.' | translate }}
              </p>
              <app-custom-icon [icon_category]="'messages'" [icon_name]="'direct-send'"></app-custom-icon>
            </div>

            <div class="bottom-line"></div>
          </div>
          <div class="icon-holder">
            <div class="icon-circle">
              <app-custom-icon [icon_category]="'messages'" [icon_name]="'direct-send'"></app-custom-icon>
            </div>
          </div>
          <div class="bottom-area">
            <div class="text-box">
              <p class="link mb-0">{{ 'account-setup.Didn’t get the link?' | translate }}</p>
              <app-rounded-button
                class="send-again"
                (click)="resendEmail()"
                color="tetriary-link"
                [button_text]="'account-setup.Send me again' | translate"
                [icon_category]="'messages'"
                [icon_name]="'directbox-send'"
                [icon_right]="true"
              ></app-rounded-button>
            </div>
            <div class="back-btn">
              <app-rounded-button
                class="back-to"
                color="secondary"
                [button_text]="'account-setup.Back to log in' | translate"
                [icon_category]="'arrows'"
                [icon_name]="'arrow-left'"
                [icon_left]="true"
                [routerLink]="'/login'"
              ></app-rounded-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
