import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ISupplier } from '@app/core/interface/suppliers.interface';
import { IManufactory } from '@interface/manufactories.interface';

@Component({
  selector: 'app-supplier-details-dialog',
  templateUrl: './supplier-details-dialog.component.html',
  styleUrls: ['./supplier-details-dialog.component.scss'],
})
export class SupplierDetailsDialogComponent {
  supplier: ISupplier | undefined;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ISupplier,
    public dialogRef: MatDialogRef<SupplierDetailsDialogComponent>
  ) {
    this.supplier = this.data;
    const mainManufactory: IManufactory | undefined = this.supplier.manufactories.find(x => x.is_main_location);
    if (mainManufactory) {
      this.supplier.city = mainManufactory.city;
      this.supplier.country = mainManufactory.country;
      this.supplier.address = `${mainManufactory.address ? mainManufactory.address + ',' : ''}
                               ${mainManufactory.city ? mainManufactory.city + ',' : ''}
                               ${mainManufactory.country ?? ''}`;
    }
  }
}
