import { IPublicationProductChainResult } from '@app/core/interface/publication.interface';
import { Component, Input, SimpleChanges } from '@angular/core';
import { ICustomPublication, ICustomPublicationTypeStep } from '../custom-publications/custom-publications.models';

@Component({
  selector: 'app-chain-overview',
  templateUrl: './chain-overview.component.html',
  styleUrls: ['./chain-overview.component.scss'],
})
export class ChainOverviewComponent {
  productChain!: IPublicationProductChainResult;
  classifications!: ICustomPublicationTypeStep[];

  @Input() publicationData!: ICustomPublication;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['publicationData'].currentValue) {
      this.classifications = this.publicationData.type_steps;
    }
  }
}
