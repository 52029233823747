<div class="taks-over-wrap">
  <div class="overview-header">
    <h6>{{ 'Tasks Overview' | translate }}</h6>
    <app-rounded-button
      color="primary"
      class="btn"
      [button_text]="'Create Task'"
      [icon_category]="'essential'"
      [icon_name]="'task-square'"
      [icon_right]="true"
      [size]="'md'"
      (click)="createTaskDialog()"
    >
    </app-rounded-button>
  </div>
  <div class="overview">
    <section class="holder col-3">
      <div class="created-sec">
        <div class="number">
          <p>{{ tasksInfo.total_tasks }}</p>
        </div>
      </div>
      <p class="created-paragraph">
        {{ 'Tasks Created' | translate }}
      </p>
    </section>
    <div class="holder col-3">
      <div class="todo-status">
        <div class="status-title-todo">
          <h2 class="percent-wrap">
            <span class="percentage"> {{ tasksInfo.to_do_tasks }} </span>
            <span class="symb"> % </span>
          </h2>
          <p class="stat-to-do">{{ 'To Do' | translate }}</p>
        </div>
        <mat-progress-spinner [mode]="'determinate'" [value]="100" [diameter]="180" class="gray-spinner">
        </mat-progress-spinner>
        <mat-progress-spinner
          [mode]="'determinate'"
          [value]="tasksInfo.to_do_tasks"
          [diameter]="180"
          class="todo-spinner"
        >
        </mat-progress-spinner>
      </div>
    </div>
    <div class="holder col-3">
      <div class="progress-status">
        <div class="status-title-progress">
          <h2 class="percent-wrap">
            <span class="percentage"> {{ tasksInfo.in_progress_tasks }} </span>
            <span class="symb"> % </span>
          </h2>
          <p class="stat-in-pr">{{ 'In Progress' | translate }}</p>
        </div>
        <mat-progress-spinner [mode]="'determinate'" [value]="100" [diameter]="180" class="gray-spinner">
        </mat-progress-spinner>
        <mat-progress-spinner
          [mode]="'determinate'"
          [value]="tasksInfo.in_progress_tasks"
          [diameter]="180"
          class="progress-spinner"
        >
        </mat-progress-spinner>
      </div>
    </div>
    <div class="holder col-3">
      <div class="completed-status">
        <div class="status-title-completed">
          <h2 class="percent-wrap">
            <span class="percentage"> {{ tasksInfo.completed_tasks }} </span>
            <span class="symb"> % </span>
          </h2>
          <p class="stat-com">{{ 'Completed' | translate }}</p>
        </div>
        <mat-progress-spinner [mode]="'determinate'" [value]="100" [diameter]="180" class="gray-spinner">
        </mat-progress-spinner>
        <mat-progress-spinner
          [mode]="'determinate'"
          [value]="tasksInfo.completed_tasks"
          [diameter]="180"
          class="completed-spinner"
        >
        </mat-progress-spinner>
      </div>
    </div>
  </div>
</div>
