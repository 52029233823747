<div class="header">
  <div class="header-actions" [ngClass]="{ 'chain-complete-header': nodeObj?.isChainComplete }">
    <app-rounded-button
      color="secondary-outline"
      [button_text]="nodeObj?.isChainComplete ? 'Close Step' : 'Update Step'"
      [size]="'sm'"
      [disabled]="form && !form.valid"
      (click)="nodeObj?.isChainComplete ? closeDialog.emit(true) : updateStep()"
    >
    </app-rounded-button>
    <app-rounded-button
      *ngIf="!nodeObj?.isChainComplete"
      class="cancel-btn"
      color="tetriary-link"
      [button_text]="'Cancel'"
      [size]="'sm'"
      (click)="cancel()"
    >
    </app-rounded-button>
  </div>
  <div class="header-container">
    <h2>{{ !nodeObj?.isChainComplete ? 'Manage Step:' : 'View Step:' }}</h2>
    <p>{{ form.controls['name'].value }}</p>
  </div>
</div>
<div class="custom-container">
  <div class="sidenav-content">
    <div class="col-12 update-supplier-wraper">
      <form [formGroup]="form">
        <mat-tab-group>
          <mat-tab>
            <ng-template mat-tab-label>
              <app-custom-icon [icon_category]="'essential'" [icon_name]="'info-circle'"></app-custom-icon>
              {{ 'Main info' | translate }}
            </ng-template>
            <div class="row">
              <!-- TEMPORALLY HIDDEN THE STATUS OF THE STEP. -->
              <!-- <div class="p-0">
                <h4 class="mb-0">{{ 'Step Status' | translate }}</h4>
                <div class="col-12">
                  <mat-form-field class="input-primary" appearance="outline">
                    <input matInput formControlName="brand_status" placeholder="Status" readonly />
                  </mat-form-field>
                </div>
              </div> -->

              <div class="pt-3 ps-0">
                <div class="d-flex align-items-center gap-2 supplier-status">
                  <p class="p2-strong mb-0">{{ 'Status at supplier' | translate }}</p>
                  <div [ngSwitch]="stepData?.supplier_status">
                    <app-custom-icon
                      *ngSwitchCase="'Updated'"
                      [icon_category]="'essential'"
                      [icon_name]="'tick-circle-green'"
                    ></app-custom-icon>
                    <app-custom-icon
                      *ngSwitchCase="'To update'"
                      [icon_category]="'essential'"
                      [icon_name]="'more-circle-red'"
                    ></app-custom-icon>
                    <app-custom-icon
                      *ngSwitchCase="'Not applicable'"
                      [icon_category]="'essential'"
                      [icon_name]="'circle-slash'"
                    ></app-custom-icon>
                    <div *ngSwitchDefault></div>
                  </div>
                  <p class="p2-normal mb-0">{{ stepData?.supplier_status }}</p>
                </div>
              </div>

              <div class="horizontal-line mb-3 mt-3"></div>

              <h4 class="mb-0">{{ 'General info' | translate }}</h4>
              <div class="col-12">
                <mat-form-field
                  class="input-primary"
                  appearance="outline"
                  *ngIf="!nodeObj?.isChainComplete; else readOnlyName"
                >
                  <mat-label>{{ 'Name' | translate }}</mat-label>
                  <input matInput formControlName="name" placeholder="Name" required />
                </mat-form-field>
                <ng-template #readOnlyName>
                  <p class="p1-normal mb-0">{{ 'Step Name:' | translate }}</p>
                  <p class="p1-strong mb-0">{{ stepData?.name ? stepData?.name : 'Empty case' }}</p>
                </ng-template>
              </div>

              <div class="col-12">
                <div
                  class="input-primary textarea"
                  appearance="outline"
                  *ngIf="!nodeObj?.isChainComplete; else readOnlyDescription"
                >
                  <mat-label>{{ 'Description' | translate }}</mat-label>
                  <!-- <textarea
                    matInput
                    formControlName="description"
                    placeholder="Description"
                    appTextareaAutoResize
                  ></textarea> -->
                  <div class="wrp">
                    <app-rs-rte
                      formControlName="description"
                      [c]="$any(form.controls['description'])"
                      [placeholder]="'Description' | translate"
                    ></app-rs-rte>
                  </div>
                </div>
                <ng-template #readOnlyDescription>
                  <p class="p1-normal mb-0">{{ 'Step Description:' | translate }}</p>
                  <p class="p1-strong mb-0" *ngIf="stepData?.description">
                    <app-view-rich-text [content]="stepData?.description || ''"></app-view-rich-text>
                  </p>
                  <p class="p1-strong mb-0" *ngIf="!stepData?.description">{{ 'Empty case' | translate }}</p>
                </ng-template>
              </div>

              <div class="horizontal-line"></div>

              <div class="col-12">
                <h4>{{ 'Step Cluster' | translate }}</h4>
                <div class="cluster-wraper">
                  <div
                    class="cluster-color"
                    [style.backgroundColor]="stepData?.group?.color ? stepData?.group?.color : '#C3C5C7'"
                  ></div>
                  <p>{{ stepData?.group?.name ? stepData?.group?.name : 'No cluster yet' }}</p>
                </div>
              </div>
            </div>

            <div class="horizontal-line"></div>

            <div class="row">
              <h4 *ngIf="!nodeObj?.isChainComplete">{{ 'Supply Chain Phase' | translate }}</h4>
              <div class="col-12">
                <mat-form-field
                  class="input-primary"
                  appearance="outline"
                  *ngIf="!nodeObj?.isChainComplete; else readOnlyPhase"
                >
                  <mat-label>{{ 'Phase' | translate }}</mat-label>
                  <mat-select formControlName="type_step" panelClass="dropdown-primary">
                    <mat-option *ngFor="let phase of phases" [value]="phase.uuid" placeholder="Phase">
                      {{ phase.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <ng-template #readOnlyPhase>
                  <p class="p1-normal mb-0">{{ 'Supply Chain Phase:' | translate }}</p>
                  <p class="p1-strong mb-0">{{ stepData?.type_step?.name ?? 'Empty case' }}</p>
                </ng-template>
              </div>
            </div>

            <div class="horizontal-line"></div>

            <div class="row">
              <div class="col-12 invite-supplier" *ngIf="!nodeObj?.isChainComplete">
                <p>{{ 'Need help with this step?' | translate }}</p>
                <app-rounded-button
                  color="secondary"
                  [button_text]="'Invite Supplier'"
                  [icon_category]="'product'"
                  [icon_name]="'truck'"
                  [size]="'sm'"
                  [icon_right]="true"
                  (click)="openEmailDialog()"
                  [disabled]="!selectedSupplier"
                >
                </app-rounded-button>
              </div>
              <div *ngIf="stepData?.reason_no_direct_supplier" class="col-12 reason-msg font-14 mt-2">
                {{ 'This supplier didn’t identified any of his suppliers.' | translate }} <br />
                <div class="text-container">
                  <div class="short-text" *ngIf="!showReadMore">
                    {{ 'The reason is:' }} {{ stepData.reason_no_direct_supplier | slice: 0 : 30
                    }}{{ stepData.reason_no_direct_supplier!.length > 30 ? '...' : '' }}
                  </div>
                  <div class="long-text" *ngIf="showReadMore">
                    {{ 'The reason is:' }} {{ stepData.reason_no_direct_supplier }}
                  </div>
                  <a class="align-self-center" (click)="toggleText()"
                    >{{ showReadMore ? 'Show less' : 'Read more' }}
                    <div class="mat-select-arrow-wrapper" style="height: 12px">
                      <div class="mat-select-arrow"></div>
                    </div>
                  </a>
                </div>
              </div>
              <div class="col-12" *ngIf="!nodeObj?.isChainComplete">
                <h4 class="mb-0">{{ 'Supplier' | translate }}</h4>

                <div
                  *ngIf="
                    stepData?.step_supplier &&
                    stepData.sip_supplier_identified &&
                    this.form.controls['supplier']?.value?.supplier_unique_identifier ===
                      stepData?.sip_supplier_identified?.supplier_unique_identifier
                  "
                  class="mb-2 d-flex align-items-center"
                >
                  <div
                    class="step-status"
                    [ngClass]="{
                      'in-progress': sipApprovedStatus === 0,
                      disapproved: sipApprovedStatus === 1,
                      approved:
                        sipApprovedStatus === 2 &&
                        this.form.controls['supplier']?.value?.uuid === stepData?.supplier?.uuid,
                      none:
                        sipApprovedStatus === 2 &&
                        this.form.controls['supplier']?.value?.uuid !== stepData?.supplier?.uuid,
                    }"
                  ></div>
                  <div>
                    <p *ngIf="sipApprovedStatus === 0" class="font-10 mb-0 px-2 align-self-center">
                      Matching supplier not verified yet
                    </p>
                    <p *ngIf="sipApprovedStatus === 1" class="font-10 mb-0 px-2 align-self-center">
                      Matching supplier disapproved
                    </p>
                    <p
                      *ngIf="
                        sipApprovedStatus === 2 &&
                        this.form.controls['supplier']?.value?.uuid === stepData?.supplier?.uuid
                      "
                      class="font-10 mb-0 px-2 align-self-center"
                    >
                      Matching Supplier approved
                    </p>
                  </div>

                  <app-custom-icon
                    *ngIf="sipApprovedStatus === 1"
                    matTooltip="{{
                      'This step was created automatically by the system with a new supplier based on the data given by the supplier of a previous step in the chain. The system has found an existing supplier that matches the new one and you have disapproved this matching. Therefore,  at the moment of the approval, this step’s supplier was kept to the new supplier.'
                        | translate
                    }}"
                    matTooltipClass="custom-tooltip-end"
                    matTooltipPosition="above"
                    class="cursor-pointer"
                    [icon_category]="'essential'"
                    [icon_name]="'info-circle'"
                  ></app-custom-icon>
                  <app-custom-icon
                    *ngIf="
                      sipApprovedStatus === 2 &&
                      this.form.controls['supplier']?.value?.uuid === stepData?.supplier?.uuid
                    "
                    matTooltip="{{
                      'This step was created automatically by the system with a new supplier based on the data given by the supplier of a previous step in the chain. The system has found an existing supplier that matches the new one and you have approved this matching. Therefore, at the moment of the approval, this step’s supplier was updated to be the existing supplier.'
                        | translate
                    }}"
                    matTooltipClass="custom-tooltip-end"
                    matTooltipPosition="above"
                    class="cursor-pointer"
                    [icon_category]="'essential'"
                    [icon_name]="'info-circle'"
                  ></app-custom-icon>
                </div>

                <div
                  class="mb-3"
                  *ngIf="
                    this.form.controls['supplier']?.value?.supplier_unique_identifier ===
                      stepData?.sip_supplier_identified?.supplier_unique_identifier &&
                    stepData?.step_supplier &&
                    stepData.sip_supplier_identified &&
                    sipApprovedStatus === 0
                  "
                >
                  <app-rounded-button
                    color="primary"
                    [button_text]="'Verify the matching'"
                    [size]="'md'"
                    (click)="onVerifySupplier()"
                    [icon_category]="'arrows'"
                    [icon_name]="'arrow-right-long'"
                    [icon_right]="true"
                  >
                  </app-rounded-button>
                </div>

                <mat-form-field appearance="outline" class="input-primary">
                  <mat-icon matSuffix class="icon-display">arrow_drop_down</mat-icon>
                  <mat-label>{{ 'Supplier' | translate }}</mat-label>
                  <input
                    type="text"
                    matInput
                    formControlName="supplier"
                    [matAutocomplete]="auto"
                    [matAutocompletePosition]="'above'"
                  />
                  <mat-autocomplete
                    class="autocomplete-primary"
                    #auto="matAutocomplete"
                    [displayWith]="displayFn"
                    (optionSelected)="onSupplierChange($event.option.value.uuid)"
                  >
                    <mat-option *ngFor="let supplier of filteredSuppliers | async" [value]="supplier"
                      >{{ supplier.name }}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
                <div
                  *ngIf="selectedSupplier?.sip_is_created_by_sip"
                  class="mb-0 d-flex ps-0 pe-0 pt-2 align-items-center"
                >
                  <app-custom-icon [icon_category]="'essential'" [icon_name]="'danger-yellow'" class="cursor-pointer">
                  </app-custom-icon>
                  <p class="font-14 neutral mb-0 me-2 ms-2 align-self-center">
                    Supplier created automatically by system
                  </p>
                </div>
              </div>
            </div>

            <div class="horizontal-line" *ngIf="!nodeObj?.isChainComplete"></div>

            <div class="row" *ngIf="!nodeObj?.isChainComplete; else supplierInfo">
              <h4>{{ 'Supplier Site' | translate }}</h4>
              <div class="col-12">
                <mat-form-field appearance="outline" class="input-primary">
                  <mat-label>{{ 'Supplier Site' | translate }}</mat-label>
                  <mat-select
                    panelClass="dropdown-primary"
                    formControlName="manufactories"
                    (selectionChange)="onSupplierSiteChange()"
                  >
                    <mat-option
                      *ngFor="let factory of manufactories"
                      [value]="factory.uuid"
                      placeholder="Supplier Site"
                    >
                      {{ factory.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>

            <ng-template #supplierInfo>
              <div class="row">
                <h4>{{ 'Supplier Info' | translate }}</h4>
                <div class="col-12">
                  <p class="p1-normal mb-0">{{ 'Supplier Name:' | translate }}</p>
                  <p class="p1-strong">{{ stepData?.supplier?.name ? stepData?.supplier?.name : 'Empty case' }}</p>
                  <p class="p1-normal mb-0">{{ 'Supplier Site:' | translate }}</p>
                  <p class="p1-strong">
                    {{ stepData?.manufactories?.name ? stepData?.manufactories?.name : 'Empty case' }}
                  </p>
                </div>
              </div>
            </ng-template>

            <div class="row" *ngIf="stepData?.step_supplier && !nodeObj?.isChainComplete">
              <div class="col-12">
                <div class="d-flex ps-0 pe-0 pt-2 align-items-center">
                  <app-custom-icon [icon_category]="'essential'" [icon_name]="'danger-yellow'" class="cursor-pointer">
                  </app-custom-icon>
                  <p class="font-14 neutral mb-0 me-2 ms-2 align-self-center">
                    {{ 'Step created automatically by system' | translate }}
                  </p>
                </div>
              </div>
            </div>

            <div class="row" *ngIf="stepData?.step_supplier && !nodeObj?.isChainComplete">
              <div class="col-12">
                <app-rounded-button
                  color="tetriary-link"
                  [button_text]="'Check the details'"
                  [icon_category]="'arrows'"
                  [icon_name]="'arrow-right-long'"
                  [size]="'md'"
                  (click)="onCheckDetails()"
                  [icon_right]="true"
                >
                </app-rounded-button>
                <div class="pt-2">
                  <h4 class="font-14"></h4>
                </div>
              </div>
            </div>
          </mat-tab>
          <!-- <mat-tab *ngIf="!nodeObj?.isChainComplete">
            <ng-template mat-tab-label>
              <app-custom-icon [icon_category]="'essential'" [icon_name]="'box-2'"></app-custom-icon>
              {{ 'Component info' | translate }}
            </ng-template>
            <div class="row">
              <h4>{{ 'Choose component from this supplier site' | translate }}</h4>
              <div class="col-12">
                <mat-form-field appearance="outline" class="input-primary">
                  <mat-label>{{ 'Supplier Components' | translate }}</mat-label>
                  <mat-select formControlName="ingredient">
                    <mat-option
                      *ngFor="let ingredient of ingredients"
                      [value]="ingredient.uuid"
                      placeholder="Supplier Component"
                    >
                      {{ ingredient.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <app-ingredient-details-form
                  #ingredientDetailsForm
                  *ngIf="form.get('ingredient')?.value"
                  [componentUuid]="form.get('ingredient')?.value"
                ></app-ingredient-details-form>
              </div>
            </div>
          </mat-tab> -->
          <mat-tab>
            <ng-template mat-tab-label>
              <app-custom-icon [icon_category]="'finance'" [icon_name]="'document'"></app-custom-icon>
              {{ 'Step Documents' | translate }}
            </ng-template>
            <div class="row">
              <h4>{{ 'Step Documents' | translate }}</h4>
              <div class="col-12">
                <app-step-media-accordion
                  *ngIf="stepData"
                  [canUpload]="!nodeObj?.isChainComplete"
                  [photos]="photos"
                  [videos]="videos"
                  [documents]="stepData.documents"
                  [type]="'steps'"
                  [typeUuid]="stepData?.uuid"
                  [canDelete]="!nodeObj?.isChainComplete"
                  (refreshStep)="getStep(stepData.uuid)"
                ></app-step-media-accordion>
              </div>
            </div>
          </mat-tab>
          <!-- // TEMPORALLY HIDDEN -->
          <!-- <mat-tab *ngIf="!nodeObj?.isChainComplete">
            <ng-template mat-tab-label>
              <app-custom-icon [icon_category]="'essential'" [icon_name]="'danger'"></app-custom-icon>
              {{ 'Environmental Impact' | translate }}
            </ng-template>
            <div class="row mt-2">
              <div class="d-flex px-0">
                <h4 class="pe-2">{{ 'CO2 impact' | translate }}</h4>
                <app-custom-icon
                  [icon_category]="'essential'"
                  [icon_name]="'info-circle'"
                  mat-raised-button
                  matTooltip="{{
                    'Each step can have a CO2 impact, so we can calculate the total impact of the chain.' | translate
                  }}"
                  matTooltipPosition="above"
                  matTooltipClass="custom-tooltip-center"
                  aria-label="Icon that displays a tooltip when focused or hovered over"
                >
                </app-custom-icon>
              </div>
              <div class="col-12">
                <div class="row">
                  <div class="col-4 ps-0">
                    <mat-form-field appearance="outline" class="input-primary">
                      <mat-label>{{ 'number' | translate }}</mat-label>
                      <input
                        matInput
                        formControlName="co2_value"
                        placeholder="Enter CO2 impact in this format 100gr/km"
                      />
                    </mat-form-field>
                    <app-custom-icon
                      [icon_category]="'essential'"
                      [icon_name]="'info-circle'"
                      mat-raised-button
                      matTooltip="{{ 'Enter the number of CO2 units (grams, kg or tons)' | translate }}"
                      matTooltipPosition="below"
                      matTooltipClass="custom-tooltip-center"
                      aria-label="Icon that displays a tooltip when focused or hovered over"
                    >
                    </app-custom-icon>
                  </div>
                  <div class="col-3 px-0">
                    <mat-form-field appearance="outline" class="input-primary">
                      <mat-label>{{ 'weight' | translate }}</mat-label>
                      <mat-select formControlName="co2_unit">
                        <mat-option *ngFor="let weight of units" [value]="weight.value">
                          {{ weight.name }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                    <app-custom-icon
                      [icon_category]="'essential'"
                      [icon_name]="'info-circle'"
                      mat-raised-button
                      matTooltip="{{ 'choose the unit of weight (gr, kg, t)' | translate }}"
                      matTooltipPosition="below"
                      matTooltipClass="custom-tooltip-center"
                      aria-label="Icon that displays a tooltip when focused or hovered over"
                    >
                    </app-custom-icon>
                  </div>
                  <div class="col-2 px-0">
                    <p class="text-center">per</p>
                  </div>
                  <div class="col-3 px-0">
                    <mat-form-field appearance="outline" class="input-primary">
                      <mat-label>{{ 'unit' | translate }}</mat-label>
                      <mat-select formControlName="co2_unit2">
                        <mat-option *ngFor="let unit of units2" [value]="unit.value">
                          {{ unit.name }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                    <app-custom-icon
                      [icon_category]="'essential'"
                      [icon_name]="'info-circle'"
                      mat-raised-button
                      matTooltip="{{ 'choose the corresponding unit of (meters, kilometers) ' | translate }}"
                      matTooltipPosition="below"
                      matTooltipClass="custom-tooltip-center"
                      aria-label="Icon that displays a tooltip when focused or hovered over"
                    >
                    </app-custom-icon>
                  </div>
                </div>
              </div>
            </div>
          </mat-tab> -->
          <!-- <mat-tab *ngIf="!nodeObj?.isChainComplete">
            <ng-template mat-tab-label>
              <app-custom-icon [icon_category]="'grid'" [icon_name]="'element-plus'"></app-custom-icon>
              {{ 'Custom Fields' | translate }}
            </ng-template>

            <div class="row">
              <h4>{{ 'Supplier Batch Number' | translate }}</h4>
              <div class="col-12">
                <mat-form-field class="input-primary" appearance="outline">
                  <mat-label>{{ 'Enter number here...' | translate }}</mat-label>
                  <input matInput formControlName="supplier_batch_number" placeholder="Enter number here..." />
                </mat-form-field>
              </div>
            </div>
          </mat-tab> -->
          <mat-tab [labelClass]="'data-secured-tab'">
            <ng-template mat-tab-label>
              <app-data-secured-badge
                [routerLink]="'/terms-and-conditions'"
                [isColumnDirection]="true"
              ></app-data-secured-badge>
            </ng-template>
          </mat-tab>
        </mat-tab-group>
      </form>
    </div>
  </div>
</div>
