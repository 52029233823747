import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-unassing-project-dialog',
  templateUrl: './unassing-project-dialog.component.html',
  styleUrls: ['./unassing-project-dialog.component.scss'],
})
export class UnassingProjectDialogComponent {
  is_project!: boolean;
  subproject_text =
    'Please note that if you unassign the product from this sub-project, the sub-project won’t be deleted, and you could switch to this subproject at anytime.';
  project_text =
    'Please note that if you unassign this project from the product, the project won’t be deleted, and you could switch to this project at anytime.';
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { is_project: boolean },
    public dialogRef: MatDialogRef<UnassingProjectDialogComponent>
  ) {
    if (data) {
      this.is_project = this.data.is_project;
    }
  }

  unassign() {
    this.dialogRef.close({ uuid: '' });
  }

  close() {
    this.dialogRef.close({ close: true });
  }
}
