<form [formGroup]="form">
  <mat-form-field class="input-primary full-width" appearance="outline">
    <mat-label>{{ 'Component Reference Number' | translate }}</mat-label>
    <input matInput formControlName="reference" />
  </mat-form-field>
  <div class="quantity-row d-flex flex-wrap align-items-center">
    <mat-form-field class="input-primary" appearance="outline">
      <mat-label>{{ 'Component Quantity' | translate }}</mat-label>
      <input matInput formControlName="quantity" type="number" />
    </mat-form-field>
    <div class="component-unit d-flex align-items-baseline">
      <p class="p2 m-0">component unit</p>
      <p *ngIf="component?.category" class="p1-strong m-0">{{ component.category?.quantity_unit?.symbol || 'n/a' }}</p>
    </div>
  </div>
  <mat-form-field class="input-primary full-width" appearance="outline">
    <mat-label>{{ 'Component Location' | translate }}</mat-label>
    <input matInput formControlName="sourcing_location" />
  </mat-form-field>
</form>
