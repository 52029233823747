import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PurchaseComponent } from './purchase.component';
import { PurchaseRoutingModule } from './purchase-routing.module';
import { AngularMaterialModule } from '@app/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { SharedModule } from '@app/shared/shared.module';
import { PurchaseCardComponent } from './purchase-card/purchase-card.component';

@NgModule({
  declarations: [PurchaseComponent, PurchaseCardComponent],
  imports: [
    CommonModule,
    PurchaseRoutingModule,
    AngularMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forChild(),
    SharedModule,
  ],
})
export class PurchaseModule {}
