<div class="background">
  <div class="task-grid-view">
    <mat-card class="task-list-wraper">
      <div class="header-wraper">
        <div class="options-header">
          <h6 class="tasks-title">
            {{ 'All Tasks' | translate }}
          </h6>
          <div class="filter-view-wraper">
            <div class="toggle-group">
              <mat-button-toggle-group #group="matButtonToggleGroup" (change)="viewChange($event.value)">
                <div class="table-view toggle">
                  <mat-button-toggle value="tableView" aria-label="Table View" [checked]="true">
                    <app-custom-icon [icon_category]="'essential'" [icon_name]="'menu-3lines'"></app-custom-icon>
                  </mat-button-toggle>
                </div>
                <div class="card-view toggle">
                  <mat-button-toggle value="cardView" aria-label="Card View">
                    <app-custom-icon [icon_category]="'grid'" [icon_name]="'element-3-large'"></app-custom-icon>
                  </mat-button-toggle>
                </div>
              </mat-button-toggle-group>
            </div>
            <div class="create-task-btn">
              <app-rounded-button
                color="primary"
                (click)="createTaskDialog()"
                [button_text]="'Create task'"
                [icon_category]="'essential'"
                [icon_name]="'task-square'"
                [icon_right]="true"
                [size]="'md'"
              >
              </app-rounded-button>
            </div>
          </div>
          <mat-label class="filter-by">
            <p class="filter">Filter by</p>
            <app-custom-icon matSuffix [icon_category]="'search'" [icon_name]="'search-filter'"></app-custom-icon>
          </mat-label>
        </div>
      </div>
      <div class="main-holder">
        <!-- Card View -->
        <div class="cards-wraper row" *ngIf="group.value === 'cardView'">
          <div *ngFor="let task of listObservable$ | async" class="task-card col-4">
            <div class="card-wr">
              <app-task-card [task]="task"></app-task-card>
            </div>
          </div>
        </div>
        <!-- Table View -->
        <div class="table-wraper" *ngIf="group.value === 'tableView'">
          <table class="task-table" mat-table matSort [dataSource]="tableDataSource">
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef mat-sort-header class="name">{{ 'Task Name' | translate }}</th>
              <td mat-cell *matCellDef="let element" class="name-t">{{ element?.name }}</td>
            </ng-container>

            <ng-container matColumnDef="created_by">
              <th mat-header-cell *matHeaderCellDef mat-sort-header class="crt_by">{{ 'Created by' | translate }}</th>
              <td mat-cell *matCellDef="let element" class="crt_by">{{ element?.created_by.username }}</td>
            </ng-container>

            <ng-container matColumnDef="assign_to">
              <th mat-header-cell *matHeaderCellDef mat-sort-header class="assign">{{ 'Assigned to' | translate }}</th>
              <td mat-cell *matCellDef="let element" class="assign">{{ element?.assigned_to }}</td>
            </ng-container>
            <ng-container matColumnDef="related_obj">
              <th mat-header-cell *matHeaderCellDef mat-sort-header class="rel">{{ 'Related Object' | translate }}</th>
              <td mat-cell *matCellDef="let element" class="rel">
                <ng-container *ngIf="element?.content_object?.supplier">
                  {{ element.content_object.supplier[0].name }}
                </ng-container>
                <ng-container *ngIf="element?.content_object?.product_chain">
                  {{ element.content_object.product_chain[0].name }}
                </ng-container>
                <ng-container *ngIf="element?.content_object?.product">
                  {{ element.content_object.product[0].name }}
                </ng-container>
              </td>
            </ng-container>
            <ng-container matColumnDef="status">
              <th mat-header-cell *matHeaderCellDef mat-sort-header class="stat-col">{{ 'Status' | translate }}</th>
              <td mat-cell *matCellDef="let element" class="stat-col">
                <div *ngIf="element.status === 'TO DO'" class="to-do-t">
                  {{ 'To Do' | translate }}
                </div>
                <div *ngIf="element.status === 'IN PROGRESS'" class="in-pr-t">
                  {{ 'In Progress' | translate }}
                </div>
                <div *ngIf="element.status === 'COMPLETED'" class="comp-t">
                  {{ 'Completed' | translate }}
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="end_date">
              <th mat-header-cell *matHeaderCellDef mat-sort-header class="end-d">{{ 'End Date' | translate }}</th>
              <td mat-cell *matCellDef="let element" class="end-d">{{ element?.end_date | date : 'dd.MM.YYYY' }}</td>
            </ng-container>
            <ng-container matColumnDef="actions">
              <th mat-header-cell *matHeaderCellDef class="actions-header text-center">{{ 'Actions' | translate }}</th>
              <td mat-cell *matCellDef="let element" class="actions-body text-center">
                <app-custom-icon
                  id="eye"
                  [icon_category]="'security'"
                  class="action"
                  [icon_name]="'eye'"
                  (click)="openTaskDetails(element)"
                >
                </app-custom-icon>
                <app-custom-icon id="edit" [icon_category]="'files'" class="action" [icon_name]="'edit'">
                </app-custom-icon>
                <app-custom-icon
                  id="close-square"
                  class="action"
                  [icon_category]="'essential'"
                  [icon_name]="'close-square'"
                  (click)="deleteTask(element)"
                >
                </app-custom-icon>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          </table>
        </div>
      </div>

      <div class="card-footer">
        <mat-paginator
          class="custom-paginator"
          [pageSizeOptions]="[8, 12, 16]"
          showFirstLastButtons
          aria-label="Select page of tasks"
        >
        </mat-paginator>
      </div>
    </mat-card>
  </div>
</div>
