<div class="wrap">
  <form [formGroup]="form" class="d-flex flex-column">
    <div class="pack-wrap d-flex justify-content-between">
      <div class="product-wrap d-flex align-items-center">
        <h5 class="m-0">{{ 'Packaging for Product' | translate }}</h5>
        <div class="product d-flex align-items-center">
          <img [src]="product?.image ?? '../../../../../../../../assets/images/image-placeholder.jpg'" alt="" />
          <p class="m-0">{{ product?.name }}</p>
        </div>
      </div>
      <div class="progress-bar d-flex align-items-center flex-column">
        <p class="m-0">{{ '3 sections required / ' + selectedItems + ' completed' }}</p>

        <mat-progress-bar
          mode="determinate"
          [value]="(selectedItems / 3) * 100"
          class="custom-progress-bar light-progress-bar"
        >
        </mat-progress-bar>
        <img class="indicator-1-progress-bar" [src]="indicatorImg" alt="" />
        <img class="indicator-2-progress-bar" [src]="indicatorImg" alt="" />
      </div>
    </div>
    <div class="input-wrap d-flex">
      <div class="menu-wrap d-flex flex-column">
        {{ 'Packaging name*' | translate }}

        <mat-form-field class="input-primary col-12" appearance="outline">
          <mat-label class="label-text">{{ 'Enter a packaging name' | translate }}</mat-label>
          <input matInput placeholder="Add name" formControlName="name" #name />
        </mat-form-field>
      </div>
      <div class="menu-wrap d-flex flex-column">
        {{ 'Packaging type*' | translate }}

        <mat-form-field class="select-type" appearance="outline" class="input-primary col-12">
          <!-- <mat-select formControlName="type" panelClass="dropdown-primary dropdown-select-type"
            placeholder="{{ 'Choose Type' | translate }}" (selectionChange)="onFieldTypeChange($event)"> -->
          <mat-select
            formControlName="type"
            panelClass="dropdown-primary dropdown-select-type"
            placeholder="{{ 'Choose Type' | translate }}"
          >
            <mat-select-trigger>
              <div class="trigger-content d-flex align-items-center">
                {{ form.controls['type'].value ?? '' }}
              </div>
            </mat-select-trigger>
            <mat-option class="d-flex align-items-center" *ngFor="let type of types" [value]="type.name">
              <p class="p1-strong">{{ type.name }}</p>
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <!--Details section-->
    <!-- <div> -->
    <div class="d-flex flex-column section-holder">
      <div class="section-wrp">
        <app-recyclability
          [recyclability]="form.controls['recyclability']?.value"
          [is_recyclable_display]="!!packaging?.is_recyclable_display"
          (isSelected)="onSelection()"
          (checkValidation)="checkValidity()"
        ></app-recyclability>
      </div>
      <div class="section-wrp">
        <app-recycled-materials-info
          [recycled_materials]="form.controls['recycled_materials'].value"
          [is_recycled_materials_display]="!!packaging?.is_recycled_materials_display"
          (isSelected)="onSelection()"
          (checkValidation)="checkValidity()"
        ></app-recycled-materials-info>
      </div>
      <div class="section-wrp">
        <app-hazardous-substance
          [hazardousSubstances]="form.controls['hazardous_substances'].value"
          (isSelected)="onSelection()"
          (isInvalid)="checkValidity()"
        ></app-hazardous-substance>
      </div>
    </div>
    <!-- </div> -->
  </form>
</div>
