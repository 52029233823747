import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ProductChainDashboardComponent } from './product-chain-dashboard.component';
import { ProductChainModule } from '@app/module/product-chain/product-chain.module';
import { SharedModule } from '@app/shared/shared.module';
import { SupplyChainDashboardRoutingModule } from './product-chain-dashboard-routing.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [ProductChainDashboardComponent],
  imports: [
    CommonModule,
    SupplyChainDashboardRoutingModule,
    SharedModule,
    TranslateModule.forChild({}),
    ProductChainModule,
  ],
  exports: [ProductChainDashboardComponent],
})
export class ProductChainDashboardModule {}
