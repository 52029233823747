<div class="d-flex flex-column full-height">
  <div class="content flex-grow-1">
    <ng-container *ngIf="isCreateSupplier">
      <mat-tab-group #tabs>
        <!------------------ Create supplier ----------------->
        <mat-tab>
          <ng-template mat-tab-label>
            <app-custom-icon icon_category="product" icon_name="truck"></app-custom-icon>
            {{ 'Supplier Details' | translate }}
          </ng-template>
          <div *ngIf="supplierUuid && isShowNote" class="created-supplier-note d-flex align-items-center">
            <img src="assets/logos/symbol-logo-dark.svg" />
            <h6 class="m-0 flex-grow-1">
              <strong>{{
                'The supplier was successfully created. \nNow you can add supplier sites at anytime.' | translate
              }}</strong>
            </h6>
            <app-custom-icon
              class="cursor-pointer"
              icon_category="essential"
              icon_name="close-circle"
              (click)="isShowNote = false"
            ></app-custom-icon>
          </div>
          <form [formGroup]="form">
            <div class="d-flex">
              <div class="col-4">
                <div class="upload-dialog">
                  <app-file-upload
                    [placeholder]="'Logo'"
                    [iconType]="'add_photo_alternate'"
                    (fileData)="saveImageData($event)"
                    [status]="form.controls['logo'].status"
                  >
                  </app-file-upload>
                </div>
              </div>

              <div class="col-8">
                <mat-form-field class="input-primary full-width" appearance="outline">
                  <mat-label>{{ 'Supplier Name' | translate }}</mat-label>
                  <input matInput formControlName="name" placeholder="Supplier Name" required />
                </mat-form-field>

                <mat-form-field class="input-primary full-width" appearance="outline">
                  <mat-label>{{ 'Supplier Description' | translate }}</mat-label>
                  <input matInput formControlName="description" placeholder="Supplier Description" />
                </mat-form-field>
              </div>
            </div>
          </form>
          <!-- Create supplier form -->
          <div *ngIf="!supplierUuid" class="form-buttons d-flex justify-content-start">
            <app-rounded-button
              class="btn-width-180px"
              color="primary"
              button_text="{{ 'Create Supplier' | translate }}"
              size="md"
              [disabled]="form.invalid"
              (click)="onCreateSupplier()"
            >
            </app-rounded-button>
            <app-rounded-button
              class="btn-width-180px"
              color="secondary"
              button_text="{{ 'Discard' | translate }}"
              size="sm"
              (click)="createdSite.emit(null)"
            >
            </app-rounded-button>
          </div>
        </mat-tab>
        <!--------------- Create supplier site for new supplier -------------->
        <mat-tab [disabled]="!supplierUuid">
          <ng-template mat-tab-label>
            <app-custom-icon icon_category="buildings" icon_name="factory"></app-custom-icon>
            {{ 'Supplier Sites' | translate }}
          </ng-template>
          <app-create-site-form
            *ngIf="!isShowSitesList"
            [supplierUuid]="supplierUuid"
            (siteCreated)="onSiteCreated($event)"
          ></app-create-site-form>
          <app-sites-list
            *ngIf="isShowSitesList"
            [supplierSites]="supplierSites"
            (addSupplierSite)="isShowSitesList = false"
          ></app-sites-list>
        </mat-tab>
      </mat-tab-group>
    </ng-container>
    <!-------------------- Create supplier site for existing supplier ---------------->
    <ng-container *ngIf="!isCreateSupplier">
      <app-create-site-form [supplierUuid]="supplierUuid" (siteCreated)="onSiteCreated($event)"></app-create-site-form>
    </ng-container>
  </div>

  <div *ngIf="isCreateSupplier" class="buttons d-flex justify-content-end">
    <app-rounded-button
      class="btn-width-180px"
      color="turquoise"
      button_text="{{ 'Done' | translate }}"
      size="md"
      (click)="onDone()"
    >
    </app-rounded-button>
  </div>
</div>
