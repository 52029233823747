import { Component } from '@angular/core';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent {
  openSnav = false;
  spacing_x = 40;
  spacing_y = 100;

  onToggleSidenav(open: boolean) {
    this.openSnav = open;
  }
}
