import { ChangeDetectorRef, Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { UntypedFormGroup } from '@angular/forms';
import { GetDirtyValues } from '@app/core/utils/form-dirty-values';
import { ISupplier } from '@app/core/interface/suppliers.interface';
import { SupplierDashboardGroup } from '../../supplier-dashboard.group';
import { SuppliersFacade } from '@app/core/facade/suppliers.facade';
import { ProductsFacade } from '@app/core/facade/products.facade';
import { SuppliersService } from '@app/core/service/suppliers.service';
import { Subject, takeUntil } from 'rxjs';
import { NotificationType } from '@app/core/constants';
import { SnackbarService } from '@app/core/service/snackbar.service';

@Component({
  selector: 'app-supplier-details',
  templateUrl: './supplier-details.component.html',
  styleUrls: ['./supplier-details.component.scss'],
  providers: [SupplierDashboardGroup],
})
export class SupplierDetailsComponent {
  @Output() form!: UntypedFormGroup;
  @Input() supplierData!: ISupplier;
  @Input() productId!: string;
  @Output() supplier = new EventEmitter<ISupplier>();
  @Output() isFormValid = new EventEmitter<boolean>(false);
  @Input() show_buttons!: boolean;
  isShowNote = true;
  showButtons = true;
  data!: ISupplier;
  showDetailsForm = false;
  isformvalid = false;
  uniqueIdentifierInfo!: boolean;
  identifierTypeInfo!: boolean;
  supplierConfidentialInfo!: boolean;
  currentSupplierLogo!: string;
  private _unsubscribe$: Subject<void> = new Subject();
  is_hidden = false;
  initialFileData!: File;
  constructor(
    private _group: SupplierDashboardGroup,
    private _facade: SuppliersFacade,
    private _snackbarService: SnackbarService,
    public dialog: MatDialog,
    private _productsFacade: ProductsFacade,
    private _suppliersService: SuppliersService,
    public dialogRef: MatDialogRef<SupplierDetailsComponent>,
    private cdr: ChangeDetectorRef
  ) {
    this.form = this._group.newSupplierForm;
  }

  ngOnInit(): void {
    const currentLogo = this.supplierData.logo as string;
    this.currentSupplierLogo = currentLogo;
    this.form.valueChanges.subscribe(() => {
      this.isFormValid.emit(this.form.valid);
      this.isformvalid = this.form.valid;

      if (this.supplierData?.uuid && this.isformvalid) {
        this.showButtons = true;
      }
    });
    this._suppliersService.supplierDetailsActions.pipe(takeUntil(this._unsubscribe$)).subscribe(() => {
      if (this.supplierData?.uuid) {
        this.updateSupplier();
      } else {
        this.createSupplier();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['supplierData']) {
      this.showButtons = false;
      this.form.patchValue({
        logo: this.supplierData.logo,
        name: this.supplierData.name,
        description: this.supplierData.description,
        supplier_unique_identifier: this.supplierData.supplier_unique_identifier,
        unique_identifier_type: this.supplierData.unique_identifier_type,
        is_hidden: this.supplierData.is_hidden,
      });
    }
  }

  clearChanges(): void {
    // Reset form values to their original state
    this.form.patchValue({
      logo: this.currentSupplierLogo ?? '',
      name: this.supplierData.name,
      description: this.supplierData.description,
      supplier_unique_identifier: this.supplierData.supplier_unique_identifier,
      unique_identifier_type: this.supplierData.unique_identifier_type,
      is_name_private: this.supplierData.is_name_private,
    });

    this.setInitialFileData();
  }

  // This function set initial file data if the changes are cancelled

  setInitialFileData(): void {
    // Extract the filename from the URL
    const filename = this.extractFilename(this.currentSupplierLogo, 'original-logo.png');

    // Create a File object from the URL and set it to fileData
    this.updateFileData(this.currentSupplierLogo, filename).then(() => {
      // Reset form state to pristine and untouched
      this.form.markAsPristine();
      this.form.markAsUntouched();

      // Emit form validity and hide buttons
      this.isFormValid.emit(false);
      this.showButtons = false;
    });
  }

  private extractFilename(urlString: string, defaultFilename: string): string {
    try {
      const url = new URL(urlString);
      return url.pathname.split('/').pop() || defaultFilename;
    } catch (error) {
      return defaultFilename;
    }
  }

  private async updateFileData(url: string, filename: string): Promise<void> {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const blob = await response.blob();
    const file = new File([blob], filename, { type: blob.type });
    this.initialFileData = file;
    this.form.get('logo')?.setValue(file);
    this.cdr.detectChanges();
  }

  closeDialog(): void {
    this.dialogRef.close(true);
  }

  saveImageData(file: File): void {
    this.form.get('logo')?.setValue(file ? file : '');
    this.form.get('logo')?.markAsDirty();
    //this.supplierData.logo = '';
  }

  createSupplier(): void {
    const changedFormValues: Partial<ISupplier> = GetDirtyValues(this.form);
    if (this.form.valid) {
      this._facade.createSupplier$({ ...changedFormValues }).subscribe({
        next: this._successCreate.bind(this),
        error: this._error.bind(this),
      });
    }
  }

  updateSupplier(): void {
    const changedFormValues: Partial<ISupplier> = GetDirtyValues(this.form);
    if (this.form.valid && this.supplierData?.uuid) {
      this._facade.updateSupplier$(this.supplierData.uuid, { ...changedFormValues }).subscribe({
        next: this._successUpdate.bind(this),
        error: this._error.bind(this),
      });
    }
    this.isShowNote = false;
    this.isFormValid.emit(false);
  }

  private _successCreate(data: ISupplier): void {
    data.action = 'create';
    this._success(data);
  }

  private _successUpdate(data: ISupplier): void {
    data.action = 'edit';
    this._success(data);
    this.showButtons = false;
  }

  private _success(data: ISupplier): void {
    const currentLogo = data.logo as string;
    this.currentSupplierLogo = currentLogo;
    this.supplier.emit(data);
    this.form.markAsPristine();
    this.showButtons = false;

    if (this.productId) {
      this.addSupplier(data.uuid);
    }

    this._snackbarService.openTypeSnackbar(
      `Supplier was ${this.supplierData ? 'updated' : 'created'} successfully`,
      NotificationType.success
    );
    this.supplierData = data;
  }

  private _error(error: Record<string, string[]>): void {
    Object.values(error).map(err => this._snackbarService.openTypeSnackbar(err[0], NotificationType.error));
  }

  addSupplier(uuid: string) {
    this._productsFacade.updateSupplierList.next(true);

    this._productsFacade.addSupplierToProduct$(this.productId, uuid).subscribe({
      // next: this.success.bind(this),
      error: this.error.bind(this),
    });
  }
  private error(error: Record<string, string[]>): void {
    Object.values(error).map(err => this._snackbarService.openTypeSnackbar(err[0], NotificationType.error));
  }

  ngOnDestroy(): void {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }
}
