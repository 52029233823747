import { AngularMaterialModule } from '@app/material.module';
import { BrandsFacade } from './../../facade/brands.facade';
import { CommonModule } from '@angular/common';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { LanguagesFacade } from './../../facade/languages.facade';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '@app/shared/shared.module';
import { SidenavComponent } from './sidenav.component';
import { TranslateModule } from '@ngx-translate/core';
import { ActiveRoutePipe } from './active-route.pipe';

@NgModule({
  declarations: [SidenavComponent, ActiveRoutePipe],
  exports: [SidenavComponent],
  imports: [CommonModule, AngularMaterialModule, RouterModule, TranslateModule.forChild({}), SharedModule],
  providers: [BrandsFacade, LanguagesFacade, provideHttpClient(withInterceptorsFromDi())],
})
export class SidenavModule {}
