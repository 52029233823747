import { IBatchQuanityUnit } from '@interface/batch.interface';

export interface IQuestionnaireResponse {
  count: number;
  next?: string;
  previous?: string;
  results: IQuestionnaire[];
}

export interface IQuestionnaire {
  name: string;
  sections: IQuestionnaireSection[];
  status: string;
  uuid: string;
}

export interface IQuestionnaireDetailsCreateUpdate {
  name: string;
  uuid: string;
}

export interface IQuestionnaireErrorResponse {
  count: number;
  next: number;
  previous: number;
  results: IBatchQuanityUnit[];
  error?: {
    detail: string[];
  };
}

export interface IQuestionnaireSection {
  name: string;
  questions: IQuestionnaireSectionQuestion[];
  submitted_answers: string;
  uuid: string;
}

export interface IQuestionnaireSectionQuestion {
  answer: string;
  name: string;
  submitted: boolean;
  uuid: string;
}

export class IQuestionnaireStatus {
  public static NotStarted = 'NOT STARTED';
  public static InProgress = 'IN PROGRESS';
  public static Completed = 'COMPLETED';

  public static get All(): string[] {
    return [IQuestionnaireStatus.NotStarted, IQuestionnaireStatus.InProgress, IQuestionnaireStatus.Completed];
  }
}
