import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import {
  ICustomPublication,
  ICustomPublicationType,
  ISupplierCertificatesResponse,
} from '@module/publication/custom-publications/custom-publications.models';
import { IPublicationGroup, IPublicationType } from '@interface/publication.interface';
import { IProductChainResult } from '@interface/productchain.interface';
import { IProductGroup } from '@interface/products.interface';

@Injectable({
  providedIn: 'root',
})
export class CustomPublicationsService {
  private apiUrl = environment.api;

  constructor(private _http: HttpClient) {}

  getPublication(publicationUid: string): Observable<ICustomPublication> {
    return this._http.get<ICustomPublication>(`${this.apiUrl}/productchain/${publicationUid}/publication-module/`);
  }

  addStepToClassification(body: FormData, step_uuid: string): Observable<string[]> {
    return this._http.patch<string[]>(`${this.apiUrl}/steps/${step_uuid}/`, body);
  }

  addCustomFieldToGroup(body: FormData, group_uuid: string): Observable<string[]> {
    return this._http.post<string[]>(`${this.apiUrl}/groups/${group_uuid}/customfields/`, body);
  }

  createGroup$(data: FormData): Observable<IPublicationGroup> {
    return this._http.post<IPublicationGroup>(`${this.apiUrl}/groups/`, data);
  }

  updateGroup$(data: FormData, group_uuid: string): Observable<IPublicationGroup> {
    return this._http.patch<IPublicationGroup>(`${this.apiUrl}/groups/${group_uuid}/`, data);
  }

  deleteGroup(group_uuid: string): Observable<IPublicationGroup> {
    return this._http.delete<IPublicationGroup>(`${this.apiUrl}/groups/${group_uuid}/`, {});
  }

  getSupplierCertificates$(supplier_uuid: string): Observable<ISupplierCertificatesResponse> {
    return this._http.get<ISupplierCertificatesResponse>(`${this.apiUrl}/suppliers/${supplier_uuid}/certificates/`);
  }

  publishProductChain$(uuid: string): Observable<IProductChainResult> {
    return this._http.post<IProductChainResult>(`${this.apiUrl}/productchain/${uuid}/publish/`, {
      is_custom_project: true,
    });
  }

  attachProductGroup(productUuid: string, product_group_uuid: string): Observable<IProductGroup> {
    return this._http.patch<IProductGroup>(`${this.apiUrl}/products/${productUuid}/`, {
      product_group: product_group_uuid,
    });
  }

  createClassification(productChainUUid: string, data: Partial<IPublicationType>): Observable<ICustomPublicationType> {
    return this._http.post<ICustomPublicationType>(`${this.apiUrl}/productchain/${productChainUUid}/type-steps/`, data);
  }

  updateClassification(
    productChainUUid: string,
    typeStepUid: string,
    data: Partial<IPublicationType>
  ): Observable<ICustomPublicationType> {
    return this._http.patch<ICustomPublicationType>(
      `${this.apiUrl}/productchain/${productChainUUid}/type-steps/${typeStepUid}/`,
      data
    );
  }

  deleteClassification(productChainUUid: string, typeStepUid: string): Observable<ICustomPublicationType> {
    return this._http.delete<ICustomPublicationType>(
      `${this.apiUrl}/productchain/${productChainUUid}/type-steps/${typeStepUid}/`
    );
  }

  updateClassificationsPosition$(data: string[]): Observable<string[]> {
    return this._http.post<string[]>(`${this.apiUrl}/type-step-position/`, {
      type_steps: data,
    });
  }

  updateGroupPositions$(data: FormData): Observable<string[]> {
    return this._http.patch<string[]>(`${this.apiUrl}/groups/publication-position/`, data);
  }
}
