<div class="wrapper d-flex flex-column justify-content-between">
  <div class="top-text d-flex flex-column align-items-center">
    <img src="assets/logos/symbol-logo-dark.svg" alt="pdna-logo" />
    <h5 class="m-0">
      <strong>{{
        'The selected option is already used in a specific instance of the selected object.' | translate
      }}</strong>
    </h5>
  </div>

  <div class="center-text d-flex flex-column align-items-center">
    <div class="icon-circle d-flex align-items-center justify-content-center">
      <app-custom-icon [icon_category]="'essential'" [icon_name]="'danger-white'"> </app-custom-icon>
    </div>
    <h3 class="m-0">
      <strong>{{ 'The option cannot be removed!' | translate }}</strong>
    </h3>
  </div>

  <div class="button d-flex justify-content-center">
    <app-rounded-button mat-dialog-close color="primary" [button_text]="'Ok, Got It'" [size]="'md'">
    </app-rounded-button>
  </div>
</div>
