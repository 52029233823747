import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription, takeWhile } from 'rxjs';
import { INameID } from '@app/core/interface/projects.interface';
import { DigitalSheetGroup } from '../../../../create-digital-product-sheet-dialog.group';

@Component({
  selector: 'app-hazardous-substance',
  templateUrl: './hazardous-substance.component.html',
  styleUrls: ['./hazardous-substance.component.scss'],
  providers: [DigitalSheetGroup],
})
export class HazardousSubstanceComponent implements OnInit, OnDestroy, OnChanges {
  @Output() isSelected = new EventEmitter<boolean>(false);
  @Output() isInvalid = new EventEmitter<boolean>(true);
  @Input() hazardousSubstances!: Array<INameID>;
  form: FormGroup;
  selectedValue = '';
  selected = false;
  subscription!: Subscription;
  alive = true;
  @ViewChild('infoWrap') infoWrapElement!: ElementRef;
  @ViewChild('info') infoElement!: ElementRef;

  @HostListener('document:click', ['$event'])
  handleClickOutside(event: Event): void {
    if (
      !this.infoWrapElement?.nativeElement.contains(event.target) &&
      this.infoElement?.nativeElement.style.display !== 'none'
    ) {
      this.hideInfo();
    }
  }
  constructor(private _group: DigitalSheetGroup, private fb: FormBuilder) {
    this.form = _group.hazardousForm;
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['hazardousSubstances']?.currentValue) {
      if (!this.form.controls['hazardous_substances'] && !!changes['hazardousSubstances'].currentValue.length)
        this.form.addControl(
          'hazardous_substances',
          this.fb.array([this.fb.control('', [Validators.required, Validators.minLength(3)])])
        );
      changes['hazardousSubstances'].currentValue.forEach((sub: INameID) => this.addInput(sub.name));
      this.form.controls['hazardous'].setValue(!!changes['hazardousSubstances'].currentValue.length);
      this.removeInput(0);
    }
  }
  ngOnDestroy(): void {
    this.alive = false;
  }
  ngOnInit(): void {
    this.addRemoveHazardorSubstances();
    this.updateValidity();
  }
  hideInfo(): void {
    if (this.infoElement) this.infoElement.nativeElement.style.display = 'none';
  }
  updateValidity(): void {
    this.form.valueChanges.pipe(takeWhile(() => this.alive)).subscribe(() => {
      this.isInvalid.emit(this.form.invalid);
    });
  }
  addRemoveHazardorSubstances(): void {
    this.form.controls['hazardous'].valueChanges.pipe(takeWhile(() => this.alive)).subscribe((value: boolean) => {
      this.onSelection();
      if (value === true && !this.form.controls['hazardous_substances'])
        this.form.addControl(
          'hazardous_substances',
          this.fb.array([this.fb.control('', [Validators.required, Validators.minLength(3)])])
        );

      if (value === false && this.form.controls['hazardous_substances'])
        this.form.removeControl('hazardous_substances');
    });
  }

  onSelection(): void {
    if (!this.selected) this.isSelected.emit(true);
    this.selected = true;
  }
  addInput(value?: string): void {
    const additionalName = this.fb.control('', [Validators.required, Validators.minLength(3)]);
    value && additionalName.setValue(value);
    this.list?.push(additionalName);
  }

  removeInput(index: number): void {
    this.list?.removeAt(index);
  }

  get list(): FormArray {
    return this.form.get('hazardous_substances') as FormArray;
  }
}
