<div class="full-wrap">
  <div class="header d-flex align-items-center justify-content-between" *ngIf="!is_view">
    <app-rounded-button
      class="secondary-btn-white"
      color="secondary"
      [button_text]="'supply-chain-step.Mark step as completed' | translate"
      (click)="is_batch ? onMarkBatchStepAsCompleted() : onMarkStepAsCompleted()"
      [size]="'xd'"
    >
    </app-rounded-button>

    <div class="autosave-icon-holder d-flex justify-content-center align-items-center">
      <app-custom-icon icon_category="weather" [icon_name]="isSaving ? 'saving' : 'saved-changes'"></app-custom-icon>
    </div>
  </div>
  <div [ngClass]="{ 'manage-wrap': !is_view, 'manage-wrap-full-h': is_view }">
    <div class="content" *ngIf="step && !is_batch">
      <app-step-main-info
        *ngIf="screen === 'main'"
        [step]="step"
        [selected_site]="selected_site"
        [supplier_sites]="supplier_sites"
        [is_view]="is_view"
        [is_enabled]="is_enabled"
        (refresh)="$event && getSupplierSites()"
      ></app-step-main-info>
      <app-step-direct-supplier
        *ngIf="screen === 'direct_supplier'"
        [step]="step"
        [dataSource]="dataSource"
        [is_view]="is_view"
        (refreshDataSource)="refreshDataSource()"
      >
      </app-step-direct-supplier>
    </div>
    <div class="content content-wrap" *ngIf="is_batch">
      <app-batch-main-info
        *ngIf="screen !== 'direct_supplier' || (screen === 'direct_supplier' && !is_view)"
        [stepBatch]="stepBatch"
        [screen]="screen"
        [step]="step"
        [is_view]="is_view"
        [supplier_sites]="supplier_sites"
        (disableDirectSupp)="setDisableDirectSupp($event)"
        (stepBatchObj)="getBatchStep($event)"
        [warningConfirmation]="warningConfirmation"
        [warningAnyField]="warningAnyField"
        [warningSite]="warningSite"
        [is_enabled]="is_enabled"
        (refresh)="$event && getSuppStepBatch($event)"
      ></app-batch-main-info>
      <app-step-direct-supplier
        *ngIf="screen === 'direct_supplier'"
        [stepBatch]="stepBatch"
        [step]="step"
        [dataSource]="dataSourceStepBatch"
        (refreshDataSource)="getStepBatchDirectSuppliers()"
        [is_batch]="is_batch"
        [is_view]="is_view"
      >
      </app-step-direct-supplier>
    </div>
  </div>
  <!------------------- Sections sidebar ---------------->
  <div class="sections-wrap" [ngClass]="{ 'sections-wrap': !is_view, 'sections-wrap-full-h': is_view }">
    <div class="list-wrap">
      <div
        #sectionsList
        [ngClass]="{
          section: true,
          selected: screen === section.section,
          row: true,
          'cursor-pointer': !section.locked,
        }"
        *ngFor="let section of sections"
        (click)="!inactiveDirectSupp || section.section === 'main' ? onSectionClicked(section) : null"
        (mouseover)="inactiveDirectSupp && section.section === 'direct_supplier' ? mouseEvHandler(true) : null"
        (mouseout)="inactiveDirectSupp && section.section === 'direct_supplier' ? mouseEvHandler(false) : null"
      >
        <div
          class="d-flex justify-content-center section-item"
          [ngClass]="{ inactive: inactiveDirectSupp && section.section === 'direct_supplier' }"
        >
          <app-custom-icon [icon_category]="section.icon.category" [icon_name]="section.icon.name"></app-custom-icon>
          <p class="p1-strong m-0">{{ section.title | translate }}</p>
        </div>
      </div>
      <div *ngIf="showInactiveTooltip" class="inactive-direct-supp-tooltip">
        <p class="m-0">
          {{
            'supply-chain-step.Sorry but you are not able to review the direct suppliers, before reviewing all of the
          documents & references and confirming the delivered quantity.' | translate
          }}
        </p>
      </div>
    </div>
  </div>

  <!-- Tutorial tooltip -->
  <div
    *ngIf="showTooltip && firstLogin && !is_batch && !is_view"
    #stepTutorialTooltip
    class="step-tutorial-tooltip"
    [ngClass]="{ 'caret-right': tutorialTooltip.id === 2, 'caret-top': tutorialTooltip.id === 3 }"
    [style.top]="tutorialTooltip.y"
    [style.left]="tutorialTooltip.x"
  >
    <div class="content d-flex flex-column">
      <img src="assets/logos/symbol-logo-dark.svg" alt="" />
      <p class="p1-strong">{{ tutorialTooltip.text | translate }}.</p>
      <app-rounded-button
        color="secondary"
        [icon_right]="tutorialTooltip.id === 1"
        icon_category="arrows"
        icon_name="arrow-right"
        size="md"
        [button_text]="tutorialTooltip.buttonText"
        (click)="onTutorialTooltipClick()"
      ></app-rounded-button>
      <app-custom-icon
        class="cursor-pointer"
        icon_category="essential"
        icon_name="close-circle"
        (click)="showTooltip = false"
      ></app-custom-icon>
    </div>
  </div>
</div>
