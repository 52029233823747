<div class="steps-phase">
  <h2 mat-dialog-title>{{ 'Add steps to Phase' | translate }}</h2>

  <mat-dialog-content class="mat-typography">
    <h4>{{ 'Choose a phase to add steps' | translate }}</h4>
    <div class="phases-legend row">
      <div
        class="phase col-3"
        [ngClass]="{ selected: phase.uuid === selectedPhase?.uuid }"
        *ngFor="let phase of data.product_chain.type_steps"
        (click)="selectPhase(phase)"
      >
        <div class="phase-color" [style.background]="phase.color">
          <app-custom-icon
            *ngIf="phase.uuid === selectedPhase?.uuid"
            [icon_category]="'essential'"
            [icon_name]="'tick-circle'"
          ></app-custom-icon>
        </div>
        <h5>{{ phase.name }}</h5>
      </div>
    </div>

    <div class="steps" *ngIf="selectedPhase">
      <div class="row">
        <p>{{ 'Choose which steps would you like to add to ' | translate }}{{ selectedPhase?.name }}</p>
        <div class="col-4 step-box" *ngFor="let step of data.product_chain.steps; let i = index">
          <mat-slide-toggle
            class="custom-slide-toggle reverse-label-toggle"
            (change)="updateStepList(step.uuid ? step.uuid : '')"
            [color]="'primary'"
            [checked]="data.selectedSteps.includes(step.uuid ? step.uuid : '')"
          >
            {{ step.name }}
          </mat-slide-toggle>
          <div class="steps-divider" *ngIf="i % 3 !== 2"></div>
        </div>
      </div>
    </div>

    <mat-dialog-actions align="end">
      <div class="buttons-holder">
        <app-rounded-button color="tetriary-link" [button_text]="'Cancel'" [size]="'md'" mat-dialog-close>
        </app-rounded-button>
        <app-rounded-button
          color="primary"
          [button_text]="'Update Steps'"
          [size]="'md'"
          [disabled]="(form && !form.valid) || !selectedPhase"
          (click)="updatePhase()"
        >
        </app-rounded-button>
      </div>
    </mat-dialog-actions>
  </mat-dialog-content>
</div>
