<div class="background">
  <div class="holder d-flex flex-column">
    <mat-card class="features-wrapper d-flex justify-content-between flex-column">
      <div class="content-wrapper">
        <div class="header-wrapper">
          <h6 class="title m-0">{{ 'Manage SaaS Features' | translate }}</h6>
        </div>
        <div class="feat-wrap d-flex flex-column">
          <!--PROJECT MANAGEMENT-->
          <div [ngClass]="toggle_on ? 'bg-dark' : 'bg-light'" class="info-holder">
            <div class="text-wrapper d-flex align-items-center">
              <h4>{{ 'Project Management' | translate }}</h4>
              <div
                class="toggle-holder"
                (mouseover)="tooltip_on.style.display = 'block'"
                (mouseout)="tooltip_on.style.display = 'none'"
                ngModel
              >
                <mat-slide-toggle
                  [checked]="toggle_on"
                  (click)="onSwitchOff()"
                  (change)="getToggleStatus($event)"
                  [color]="'primary'"
                  [disabled]="toggle_on"
                >
                </mat-slide-toggle>
                <div class="tooltip" #tooltip_on>
                  <p class="m-0 tooltip-text">
                    {{ toggle_on ? ('switch off to disable' | translate) : ('switch on to enable' | translate) }}
                  </p>
                </div>
              </div>
            </div>
            <div [ngClass]="toggle_on ? 'text-blue' : 'text-gray'" class="text-holder d-flex align-items-center">
              <app-custom-icon [icon_category]="'essential'" [icon_name]="'info-circle'"> </app-custom-icon>
              <p>
                {{
                  'Traced products can be grouped into projects and traceability data can be then aggregated to be
                available
                at project level.' | translate
                }}
              </p>
            </div>
          </div>
          <!--REGULATIONS-->
          <app-regulations *ngIf="notQaOrProd"></app-regulations>
        </div>
      </div>
      <div class="card-footer">
        <div class="row">
          <div class="col-12">
            <mat-paginator class="custom-paginator" showFirstLastButtons aria-label="Select page of features">
            </mat-paginator>
          </div>
        </div>
      </div>
    </mat-card>
  </div>
</div>
