import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Certification, FilePreview, Step } from '@app/core/interface/preview_publicaton.interface';
import { SharedModule } from '@app/shared/shared.module';

import { CountryList } from 'src/app/core/utils/country-list';
import { StepInfoComponent } from './step-info/step-info.component';
import { SupplierInfoComponent } from './supplier-info/supplier-info.component';
import { CertificateInfoDialogComponent } from './certificate-info-dialog/certificate-info-dialog.component';
import { ImageDialogComponent } from './image-dialog/image-dialog.component';
import { VideoDialogComponent } from './video-dialog/video-dialog.component';
import { MatTabChangeEvent } from '@angular/material/tabs';

@Component({
  standalone: true,
  imports: [SharedModule, CommonModule, StepInfoComponent, SupplierInfoComponent],
  selector: 'app-pin-info-dialog',
  templateUrl: './pin-info-dialog.component.html',
  styleUrls: ['./pin-info-dialog.component.scss'],
})
export class PinInfoDialogComponent {
  getCountryName = CountryList.getCountryName;
  videos?: FilePreview[];
  images?: FilePreview[];
  logo = this.data.supplier?.logo;
  selectedTabIndex = 0;
  SupplierData?: Step;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: Step,
    public dialog: MatDialog
  ) {
    this.videos = data.supplier?.medias?.filter(el => el.file_type == 'video');
    this.images = data.supplier?.medias?.filter(el => el.file_type == 'image');
    if (data?.medias && data.medias.length != 0) {
      const newVideos = data.medias?.filter(el => el.file_type == 'video');
      const newImages = data.medias?.filter(el => el.file_type == 'image');

      newImages.forEach(element => {
        this.images?.push(element);
      });

      newVideos.forEach(element => {
        this.videos?.push(element);
      });
    }
  }
  slideConfig = { slidesToShow: 3, slidesToScroll: 2 };
  slideConfigVideo = { slidesToShow: this.videos?.length && this.videos?.length == 1 ? 1 : 3, slidesToScroll: 2 };

  onCertificateDialog(certificate: Certification) {
    this.dialog.open(CertificateInfoDialogComponent, {
      data: certificate,
      width: '800px',
      panelClass: 'step-info-dialog',
    });
  }

  onImageDialog(imgUrl: File[]) {
    this.dialog.open(ImageDialogComponent, {
      data: imgUrl,
      width: 'fit-content',
      height: 'fit-content',
      panelClass: 'step-info-dialog',
    });
  }

  onVideoDialog(videoUrl: string) {
    this.dialog.open(VideoDialogComponent, {
      data: videoUrl,
      width: 'fit-content',
      height: 'fit-content',
      panelClass: 'step-info-dialog',
    });
  }

  tabChanged(tabChangeEvent: MatTabChangeEvent): void {
    this.SupplierData = this.data;
    this.selectedTabIndex = tabChangeEvent.index;
  }
}
