import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CountryList } from '@app/core/utils/country-list';
import { ICustomPublicationStep } from '@module/publication/custom-publications/custom-publications.models';
import { ISupplier } from '@interface/suppliers.interface';

@Component({
  selector: 'app-amway-step-item',
  templateUrl: './amway-step-item.component.html',
  styleUrl: './amway-step-item.component.scss',
})
export class AmwayStepItemComponent {
  @Input() step!: ICustomPublicationStep;
  @Input() color!: string;
  @Input() mode = 'edit';
  @Input() supplier!: ISupplier;
  @Output() editStepClick = new EventEmitter<ICustomPublicationStep>();
  @Output() hideStepClick = new EventEmitter<ICustomPublicationStep>();
  getCountryName = CountryList.getCountryName;

  constructor() {}

  editStep(step: ICustomPublicationStep): void {
    this.editStepClick.emit(step);
  }

  hideStep(step: ICustomPublicationStep): void {
    this.hideStepClick.emit(step);
  }
}
