import { Component, OnInit } from '@angular/core';
import { FormControl, UntypedFormControl, Validators } from '@angular/forms';
import {
  ICustomField,
  ICustomFieldsListResponse,
  ICustomFieldsObjectType,
  INameId,
} from '@app/core/interface/add-fields.interface';
import { NotificationType, PROJECT_MANAGEMENT, infoDialogHeight, infoDialogWidth } from '@app/core/constants';

import { AddFieldsFacade } from '@app/core/facade/add-fields.facade';
import { CreateCustomFieldComponent } from './create-custom-field/create-custom-field.component';
import { InfoDialogComponent } from '@app/shared/components/info-dialog/info-dialog.component';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { SnackbarService } from '@app/core/service/snackbar.service';
import { takeWhile } from 'rxjs';
import { BrandsFacade } from '@app/core/facade/brands.facade';
import { IBrandFeatures, IBrandFeaturesResponse } from '@app/core/interface/brands.interface';

@Component({
  selector: 'app-add-fields',
  templateUrl: './add-fields.component.html',
  styleUrls: ['./add-fields.component.scss'],
  providers: [AddFieldsFacade],
})
export class AddFieldsComponent implements OnInit {
  objectsList: INameId[] = [
    {
      name: 'Product',
      uuid: 'products',
    },
    {
      name: 'Product Project',
      uuid: 'project',
    },
    {
      name: 'Product Subproject',
      uuid: 'subproject',
    },
  ];

  selected_object: UntypedFormControl = new UntypedFormControl();

  subscribe = true;
  showCustomFieldsNote = true;
  customFieldsList!: Array<ICustomField>;
  customFieldsFullList: Array<ICustomField> = [];
  addFieldFormControl = new FormControl('', Validators.required);
  editItemUuid: string | null = null;
  editItemIndex = -1;
  show_deactivated = false;
  brandFeatures!: IBrandFeatures[];
  projectManagement!: IBrandFeatures | undefined;
  show_project!: boolean;

  constructor(
    public dialog: MatDialog,
    private _addFieldsFacade: AddFieldsFacade,
    private _snackbarService: SnackbarService,
    private _brandsFacade: BrandsFacade
  ) {}

  ngOnInit(): void {
    this.selected_object.valueChanges.pipe(takeWhile(() => this.subscribe)).subscribe(value => {
      this.getExtraFields(value);
    });

    this.selected_object.setValue(this.objectsList[0].uuid);
    this.getBrandFeatures();
  }

  ngOnDestroy(): void {
    this.subscribe = false;
  }

  get selectedObject(): ICustomFieldsObjectType {
    return this.selected_object.value;
  }

  getExtraFields(object: ICustomFieldsObjectType) {
    this._addFieldsFacade.getCustomFields$(object).subscribe({
      next: this._getFieldsSuccess.bind(this),
      error: this._error.bind(this),
    });
  }

  getBrandFeatures(): void {
    this._brandsFacade.getBrandFeatures$().subscribe({
      next: this._successBrandFeatures.bind(this),
      error: this._error.bind(this),
    });
  }

  private _successBrandFeatures(data: IBrandFeaturesResponse) {
    this.brandFeatures = data.results;
    if (this.brandFeatures) {
      this.projectManagement = this.brandFeatures.find(project => project.name === PROJECT_MANAGEMENT);
      if (this.projectManagement) {
        this.show_project = this.projectManagement.is_enabled;
      }
    }
  }
  onEditField(index: number, field: ICustomField) {
    if (
      this.selectedObject === 'project' ||
      this.selectedObject === 'products' ||
      this.selectedObject === 'subproject'
    ) {
      const dialogRef = this.dialog.open(CreateCustomFieldComponent, {
        width: '1160px',
        height: '768px',
        panelClass: ['top-padding-0'],
        data: {
          object_type: this.selectedObject,
          field: field,
        },
      });

      dialogRef.afterClosed().subscribe((result: ICustomField) => {
        if (result) {
          this.getExtraFields(this.selectedObject);
        }
      });
    } else {
      this.editItemUuid = field.uuid;
      this.editItemIndex = index;
      this.addFieldFormControl.patchValue(field.name);
    }
  }

  onAddCustomField() {
    if (
      this.selectedObject === 'project' ||
      this.selectedObject === 'products' ||
      this.selectedObject === 'subproject'
    ) {
      const dialogRef = this.dialog.open(CreateCustomFieldComponent, {
        panelClass: ['top-padding-0', 'height-width-static-figma'],
        data: {
          object_type: this.selectedObject,
          field: null,
        },
      });

      dialogRef.afterClosed().subscribe((result: ICustomField) => {
        if (result) {
          this.getExtraFields(this.selectedObject);
        }
      });
    }
  }

  onDeleteField(index: number, uuid: string) {
    const dialogRef = this.dialog.open(InfoDialogComponent, {
      width: infoDialogWidth,
      height: infoDialogHeight,
      data: {
        infoText:
          'You are about to delete this custom brand field. Please note that if you delete the field now, it will be removed from everywhere, that’s been used.',
        confirmationText: 'Do you wish to continue?',
        btnText: 'Yes, Delete',
        type: 'warning',
        text: 'warning-text',
      },
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.editItemIndex = index;
        this._addFieldsFacade.deleteCustomField$(this.selected_object.value, uuid).subscribe({
          next: this._deleteFieldSuccess.bind(this),
          error: this._error.bind(this),
        });
      }
    });
  }

  deactivateCustomField(field: ICustomField) {
    const dialogRef = this.dialog.open(InfoDialogComponent, {
      width: infoDialogWidth,
      height: infoDialogHeight,
      data: {
        infoText: field.is_active
          ? 'Please note that once you deactivate this custom field, you can reactivate it again anytime.'
          : 'You are about to reactivate this custom field.',
        confirmationText: field.is_active
          ? 'Are you sure you want to deactivate this custom field?'
          : 'Do you wish to continue?',
        btnText: field.is_active ? 'Deactivate Custom Field' : 'Yes, Reactivate',
        type: field.is_active ? 'warning' : 'info-actions',
        text: field.is_active ? 'warning-text' : 'info-text',
        reactivate: field.is_active ? false : true,
      },
    });
    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this._addFieldsFacade
          .editCustomFieldWithType$(this.selected_object.value, field.uuid, { is_active: !field.is_active })
          .subscribe({
            next: this._activateDeactivateSuccess.bind(this),
            error: this._error.bind(this),
          });
      }
    });
  }

  private _getFieldsSuccess(data: ICustomFieldsListResponse): void {
    this.customFieldsFullList = data.results; // Store the full list of custom fields
    this.showDeactivatedCustomFields();
  }

  private _deleteFieldSuccess(): void {
    this._snackbarService.openTypeSnackbar(`Field deleted.`, NotificationType.success);
    this.customFieldsList.splice(this.editItemIndex, 1);
    this.editItemIndex = -1;
  }

  private _activateDeactivateSuccess(): void {
    this.getExtraFields(this.selected_object.value);
  }

  private _error(error: Record<string, string>): void {
    Object.values(error).map(err => this._snackbarService.openTypeSnackbar(err, NotificationType.error));
  }

  showDeactivatedCustomFields(event?: MatCheckboxChange): void {
    let filteredCustomFields: ICustomField[];
    this.show_deactivated = event?.checked ?? this.show_deactivated;
    if (this.show_deactivated) {
      // Show inactive certificate types first, followed by active certificate types
      filteredCustomFields = this.customFieldsFullList?.sort((a, b) => {
        if (a.is_active && !b.is_active) {
          return 1;
        } else if (!a.is_active && b.is_active) {
          return -1;
        } else {
          return 0; // no change in order
        }
      });
    } else {
      // Show only active certificate types
      filteredCustomFields = this.customFieldsFullList?.filter(certificate => certificate?.is_active);
    }
    this.customFieldsList = filteredCustomFields;
  }
}
