import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { Injectable } from '@angular/core';

@Injectable()
export class UserSettingsGroup {
  userSettingsForm: UntypedFormGroup;
  constructor(private _fb: UntypedFormBuilder) {
    this.userSettingsForm = this._fb.group({
      email: [
        null,
        [
          Validators.required,
          Validators.email,
          Validators.pattern('[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,3}$'),
        ],
      ],
      first_name: [null, [Validators.required]],
      last_name: [null, [Validators.required]],
      role: [null],
      entreprise: [null],
      function: [null],
      phone: [null],
      phone_mobile: [null],
      address: [null],
      avatar: [null],
      is_brand_admin: [false],
    });
  }
}
