<div class="wrapper">
  <div class="row py-2">
    <form [formGroup]="creditCardDetailsForm" class="col-12 p-0">
      <div class="d-flex justify-content-between">
        <mat-form-field appearance="outline" class="w-100 input-primary px-1">
          <mat-label>{{ 'billing-management.Card Holder First Name' | translate }}</mat-label>
          <input
            matInput
            formControlName="first_name"
            placeholder="{{ 'billing-management.Card Holder First Name' | translate }}"
          />
        </mat-form-field>
        <mat-form-field appearance="outline" class="w-100 input-primary px-1">
          <mat-label>{{ 'billing-management.Card Holder Last Name' | translate }}</mat-label>
          <input
            matInput
            formControlName="last_name"
            placeholder="{{ 'billing-management.Card Holder Last Name' | translate }}"
          />
        </mat-form-field>
      </div>
      <div class="d-flex justify-content-between">
        <mat-form-field appearance="outline" class="w-100 input-primary px-1">
          <mat-label>{{ 'billing-management.Card Number' | translate }}</mat-label>
          <input
            matInput
            type="text"
            formControlName="number"
            placeholder="1234 5678 9012 3456"
            [imask]="{ mask: '0000 0000 0000 0000' }"
          />
        </mat-form-field>
        <mat-form-field appearance="outline" class="w-100 input-primary px-1">
          <mat-label>{{ 'billing-management.CVV' | translate }}</mat-label>
          <input
            matInput
            type="text"
            formControlName="cvv"
            placeholder="000"
            [maxlength]="3"
            [imask]="{ mask: '000' }"
          />
        </mat-form-field>
      </div>
      <div class="d-flex justify-content-between">
        <mat-form-field appearance="outline" class="w-100 input-primary px-1">
          <mat-label>{{ 'billing-management.Exp Month' | translate }}</mat-label>
          <input
            matInput
            type="text"
            formControlName="expiry_month"
            placeholder="07"
            max="12"
            [imask]="{ mask: '00' }"
          />
        </mat-form-field>
        <mat-form-field appearance="outline" class="w-100 input-primary px-1">
          <mat-label>{{ 'billing-management.Exp Year' | translate }}</mat-label>
          <input
            matInput
            type="number"
            formControlName="expiry_year"
            [min]="minExpYear"
            placeholder="2022"
            [imask]="{ mask: '0000' }"
          />
        </mat-form-field>
      </div>
    </form>
  </div>
  <div class="row py-2">
    <div class="col-12 p-0 d-flex justify-content-end">
      <app-rounded-button
        color="secondary"
        class="me-2"
        [button_text]="'billing-management.Cancel' | translate"
        [size]="'md'"
        [icon_right]="false"
        mat-dialog-close
      >
      </app-rounded-button>
      <app-rounded-button
        color="primary"
        [disabled]="creditCardDetailsForm && !creditCardDetailsForm.valid"
        [button_text]="'billing-management.Update' | translate"
        [size]="'md'"
        [icon_right]="false"
        (click)="updateCreditCardDetails()"
      >
      </app-rounded-button>
    </div>
  </div>
</div>
