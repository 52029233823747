<p class="headd">{{ 'Traceability steps' | translate }}</p>
<p class="info">
  {{
    'Please indicate the country where the following main production stages take place,
  in order to provide traceability information to consumers.' | translate
  }}
</p>

<div class="cards-wrap">
  <div class="crd-wrp row" *ngFor="let step of steps; let i = index">
    <div style="display: flex">
      <app-set-step-type-card
        [step]="step"
        [stepNum]="'0' + (i + 1)"
        (isValidForm)="checkValidation()"
        [country]="data[i]?.country"
      ></app-set-step-type-card>
      <img src="../../../../../../assets/images/connect.svg" alt="connect" class="im-con" *ngIf="i !== 2" />
    </div>
  </div>
</div>
