import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { NotificationType, infoDialogHeight, infoDialogWidth } from '@app/core/constants';

import { AddContactComponent } from './add-contact/add-contact.component';
import { ILanguage } from '@app/core/interface/languages.interface';
import { ISupplierContact } from '@app/core/interface/suppliers.interface';
import { InfoDialogComponent } from '@app/shared/components/info-dialog/info-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SnackbarService } from '@app/core/service/snackbar.service';
import { SuppliersFacade } from '@app/core/facade/suppliers.facade';

@Component({
  selector: 'app-supplier-contacts',
  templateUrl: './supplier-contacts.component.html',
  styleUrls: ['./supplier-contacts.component.scss'],
  providers: [SuppliersFacade],
})
export class SupplierContactsComponent implements OnInit {
  @Input() supplier_contacts!: ISupplierContact[];
  @Input() supplier_uuid?: string;
  @Input() languages: ILanguage[] = [];
  @Output() fetchSupplierContacts = new EventEmitter<boolean>();
  @ViewChild(MatSort, { static: false }) sort!: MatSort;
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  dataSource: MatTableDataSource<ISupplierContact> = new MatTableDataSource<ISupplierContact>();
  displayedColumns: string[] = [
    'first_name',
    'email',
    'phone',
    'position',
    'language_prefered',
    'is_active',
    'main_contact',
    'actions',
  ];

  constructor(
    private _dialog: MatDialog,
    private _facade: SuppliersFacade,
    private _snackbarService: SnackbarService,
    private _changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['supplier_contacts']) this.dataSource.data = this.supplier_contacts;
  }

  ngOnInit(): void {
    this.dataSource.data = this.supplier_contacts;
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.paginator.pageSize = 6;
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'status':
          if (item['is_active']) {
            return 1;
          }
          return 0;
        default:
          return item[property as keyof ISupplierContact] as string;
      }
    };
    this._changeDetectorRef.detectChanges();
  }

  editSupplierContact(contact: ISupplierContact): void {
    const dialogRef = this._dialog.open(AddContactComponent, {
      width: '1160px',
      height: '768px',
      data: { supplierContact: contact, supplierUuid: this.supplier_uuid, languages: this.languages },
    });
    dialogRef.afterClosed().subscribe(data => {
      if (data) {
        this.fetchSupplierContacts.emit(true);
      }
    });
  }

  deleteSupplierContact(contact: ISupplierContact): void {
    const dialogRef = this._dialog.open(InfoDialogComponent, {
      width: infoDialogWidth,
      height: infoDialogHeight,
      data: {
        infoText: `Are you sure you want to delete Supplier Contact: ${contact.first_name}?`,
        confirmationText: 'Please Confirm',
        btnText: 'Yes, Remove',
        type: 'warning',
        text: 'warning-text',
      },
    });
    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result && this.supplier_uuid) {
        this._facade.deleteSupplierContact$(this.supplier_uuid, contact.uuid).subscribe({
          next: this._deleteSupplierContactSuccess.bind(this),
          error: this._error.bind(this),
        });
      }
    });
  }

  private _deleteSupplierContactSuccess(): void {
    this.fetchSupplierContacts.emit(true);
  }

  private _error(error: Record<string, string[]>): void {
    Object.values(error).map(err => this._snackbarService.openTypeSnackbar(err[0], NotificationType.error));
  }
}
