<div class="dialog-wrapper d-flex flex-column">
  <!-- Breadcrumb -->
  <div class="breadcrump d-flex align-items-center">
    <div class="all-certificates-crumb d-flex align-items-center cursor-pointer" mat-dialog-close>
      <app-custom-icon
        class="icon-arrow-l-circle"
        id="icon-arrow-l-circle"
        icon_category="arrows"
        icon_name="arrow-circle-left-short"
      ></app-custom-icon>
      <h6>
        <strong>{{ 'All Certificates' | translate }}</strong>
      </h6>
    </div>
    <div
      *ngIf="!data"
      class="object-selection-crumb d-flex align-items-center cursor-pointer"
      (click)="mode = 'select-object'"
    >
      <app-custom-icon id="arrow-right-2" icon_category="arrows" icon_name="arrow-right-2"></app-custom-icon>
      <h4 *ngIf="mode === 'select-object'">
        <strong>{{ 'Object Selection' | translate }}</strong>
      </h4>
      <h6 *ngIf="mode !== 'select-object'">
        <strong>{{ 'Object Selection' | translate }}</strong>
      </h6>
    </div>
    <div *ngIf="mode === 'forms'" class="forms-crumb d-flex align-items-center">
      <app-custom-icon id="arrow-right-2" icon_category="arrows" icon_name="arrow-right-2"></app-custom-icon>
      <h4>
        <strong>{{ (data ? 'Edit' : 'Create') + ' Certificate' | translate }}</strong>
      </h4>
    </div>
  </div>
  <div class="horizontal-line"></div>
  <mat-dialog-content class="m-0">
    <!-- Object (brand/product/supplier) selection tabs -->
    <div *ngIf="mode === 'select-object' || showTabSelector" class="tabs-section d-flex flex-column full-height">
      <div class="tabs-section-content">
        <div *ngIf="showChooseObjectNote" class="choose-object-note d-flex align-items-center">
          <img src="assets/logos/symbol-logo-dark.svg" alt="" />
          <p class="p1-strong col m-0">
            Choose from the menu below, for which object you want create this certificate.
          </p>
          <app-custom-icon
            class="cursor-pointer icon-close-circle"
            icon_category="essential"
            icon_name="close-circle"
            (click)="showChooseObjectNote = false"
          ></app-custom-icon>
        </div>
        <mat-tab-group (selectedTabChange)="onTabChange($event)" [(selectedIndex)]="selectedTabIndex">
          <!-- Brand tab -->
          <mat-tab [label]="'Brand' | translate">
            <ng-template matTabContent>
              <app-certification-brands-list
                [data]="data"
                (objectSelected)="onObjectSelected($event)"
                [showTabSelector]="showTabSelector"
              >
              </app-certification-brands-list>
              <app-brand-product-form
                [entityForCertification]="objectType"
                [certificationObjectUuid]="selectedObject?.uuid!"
                [certificateObject]="data"
                [showTabSelector]="showTabSelector"
                [selectedObject]="selectedObject!"
                [submitClicked]="submitClicked"
                (resetSubmit)="resetSubmit()"
                (onSubmitSuccess)="closeDialog()"
              >
              </app-brand-product-form>
            </ng-template>
          </mat-tab>
          <!-- Product tab -->
          <mat-tab [label]="'Product' | translate">
            <ng-template matTabContent>
              <app-certification-products-list
                (objectSelected)="onObjectSelected($event)"
              ></app-certification-products-list>
            </ng-template>
          </mat-tab>
          <!-- Supplier tab -->
          <mat-tab [label]="'Supplier' | translate">
            <ng-template matTabContent>
              <app-certification-suppliers-list
                (objectSelected)="onObjectSelected($event)"
              ></app-certification-suppliers-list>
            </ng-template>
          </mat-tab>
        </mat-tab-group>
      </div>
      <div class="object-selection-buttons d-flex justify-content-end">
        <app-rounded-button
          color="tetriary-link underlined"
          button_text="{{ showTabSelector ? ('Discard' | translate) : ('Cancel' | translate) }}"
          size="md"
          mat-dialog-close
        >
        </app-rounded-button>
        <app-rounded-button
          color="primary"
          button_text="{{
            showTabSelector
              ? data
                ? ('Update Certificate' | translate)
                : ('Create Certificate' | translate)
              : ('Continue To Create' | translate)
          }}"
          size="md"
          [disabled]="!selectedObject"
          (click)="continueToCreate()"
        >
        </app-rounded-button>
      </div>
    </div>

    <div *ngIf="mode === 'forms'" class="forms-section">
      <app-brand-product-form
        [entityForCertification]="objectType"
        [certificationObjectUuid]="selectedObject?.uuid!"
        [certificateObject]="data"
        [selectedObject]="selectedObject!"
        [showTabSelector]="showTabSelector"
        [submitClicked]="submitClicked"
        (resetSubmit)="resetSubmit()"
        (onSubmitSuccess)="closeDialog()"
      >
        <!-- [clickCreate]="createCertificate" -->
      </app-brand-product-form>
    </div>
  </mat-dialog-content>
</div>
