import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CustomerAccountRoutingModule } from './customer-account-routing.module';
import { CustomerAccountComponent } from './customer-account.component';

@NgModule({
  declarations: [CustomerAccountComponent],
  imports: [CommonModule, CustomerAccountRoutingModule],
})
export class CustomerAccountModule {}
