import { AfterViewInit, Component, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ISupplierAlerts } from '@app/core/interface/suppliers.interface';

@Component({
  selector: 'app-alerts-table',
  templateUrl: './alerts-table.component.html',
  styleUrls: ['./alerts-table.component.scss'],
})
export class AlertsTableComponent implements OnInit, AfterViewInit, OnChanges {
  ngOnChanges(): void {
    this.dataSource.data = this.data;
    this.dataSource.paginator = this.paginator;
  }
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  @Input() data!: Array<ISupplierAlerts>;
  dataSource: MatTableDataSource<ISupplierAlerts> = new MatTableDataSource<ISupplierAlerts>();
  displayedColumns: string[] = ['name', 'message', 'actions'];

  ngOnInit(): void {
    this.dataSource.data = this.data;
    this.dataSource.paginator = this.paginator;
  }
  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
}
