import { IProductChainStep } from '@app/core/interface/steps.interface';
import { ISupplier } from '@app/core/interface/suppliers.interface';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IBatch } from '@app/core/interface/batch.interface';

@Component({
  selector: 'app-documents-certificates',
  templateUrl: './documents-certificates.component.html',
  styleUrls: ['./documents-certificates.component.scss'],
})
export class DocumentsCertificatesComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { product_chain: string; batch?: IBatch; step?: IProductChainStep; suppliers?: ISupplier[] }
  ) {}
}
