import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { LoaderService } from '@service/loader.service';
import { Observable } from 'rxjs/internal/Observable';
import { Observer } from 'rxjs/internal/types';

@Injectable({
  providedIn: 'root',
})
export class LoaderInterceptor implements HttpInterceptor {
  // List of active requests
  private _requests: Array<HttpRequest<unknown>> = [];

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    // Add this request to the list of requests
    this._requests.push(request);

    // Show the loader
    LoaderService.isLoading$.next(true);

    // We create a new observable which we return instead of the original
    return new Observable((observer: Observer<HttpEvent<unknown>>) => {
      // And subscribe to the original observable to ensure the HttpRequest is made
      const subscription = next.handle(request).subscribe({
        next: event => {
          if (event instanceof HttpResponse) {
            this._removeRequest(request);
            observer.next(event);
          }
        },
        error: error => {
          this._removeRequest(request);
          observer.error(error);
        },
        complete: () => {
          this._removeRequest(request);
          observer.complete();
        },
      });

      // Return tear down logic in case of cancelled requests
      return () => {
        this._removeRequest(request);
        subscription.unsubscribe();
      };
    });
  }

  private _removeRequest(request: HttpRequest<unknown>): void {
    const i = this._requests.indexOf(request);

    // Check do we have this request in sequence
    if (i >= 0 && request.url) {
      this._requests.splice(i, 1);
    }

    // Emit status
    LoaderService.isLoading$.next(!!this._requests.length);
  }
}
