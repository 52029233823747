import {
  ITaskResponse,
  ITasksObjectResponse,
  ITasksResponse,
  ITasksTypesResponse,
  ITasksUsersResponse,
} from '../interface/tasks.interface';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class TasksService {
  private apiUrl = environment.api;

  constructor(private _http: HttpClient) {}

  getTasks$(): Observable<ITasksResponse> {
    return this._http.get<ITasksResponse>(`${this.apiUrl}/tasks/`);
  }

  deleteTask$(uuid: string): Observable<ITasksResponse> {
    return this._http.delete<ITasksResponse>(`${this.apiUrl}/tasks/${uuid}`);
  }

  getTasksObjects$(): Observable<ITasksObjectResponse> {
    return this._http.get<ITasksObjectResponse>(`${this.apiUrl}/tasks/objects`);
  }

  getTasksTypes$(): Observable<ITasksTypesResponse> {
    return this._http.get<ITasksTypesResponse>(`${this.apiUrl}/tasks/types`);
  }

  getTasksUsers$(): Observable<ITasksUsersResponse> {
    return this._http.get<ITasksUsersResponse>(`${this.apiUrl}/tasks/users`);
  }

  createTask$(data: FormData): Observable<ITaskResponse> {
    return this._http.post<ITaskResponse>(`${this.apiUrl}/tasks/`, data);
  }
}
