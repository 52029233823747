import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Storage, USER } from '@app/core/constants';

import { DataAuthService } from '../service/data-auth.service';
import { DataStorageService } from '@app/core/service/data-localstorage.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  constructor(
    private router: Router,
    private _dataStorageService: DataStorageService,
    private _dataAuthService: DataAuthService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    let user = this._dataAuthService.user;
    user = typeof user === 'string' ? JSON.parse(user) : user;
    /**
     * If user is_supplier and role of the route is is_supplier allow access
     */
    if (route.data['role'] === 'is_supplier' && user?.is_supplier) {
      return true;
    }

    /**
     * If user is_supplier but the roule in the route is not is_supplier (first case handle if it's is_supplier) redirect to select
     */
    if (this._dataStorageService.get(USER, Storage.local) || this._dataStorageService.get(USER, Storage.session)) {
      // When non-csm user tries to access csm routes
      if (route.data && route.data['role'] && !user?.user_permissions.includes(route.data['role'])) {
        this.router.navigate(['dashboard']);
        return false;
      }
      // When csm user tries to access non-csm routes
      if (route.data && !route.data['role'] && user?.user_permissions.includes('customer_success_manager')) {
        this.router.navigate(['csm']);
        return false;
      }

      // logged in so return true
      return true;
    }

    // not logged in so redirect to login page with the return url
    this.router.navigate(['/login'], { queryParams: { returnUrl: state.url }, queryParamsHandling: 'merge' });
    return false;
  }
}
