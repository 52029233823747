import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AngularMaterialModule } from '@app/material.module';
import { BrandManagementComponent } from './brand-management.component';
import { BrandManagmentRoutingModule } from './brand-management-routing.module';
import { BrandUsersComponent } from './brand-users/brand-users.component';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RegisterGroup } from './../register/register.group';
import { SharedModule } from '@app/shared/shared.module';
import { UpdateBrandDetailsDialogComponent } from './update-brand-details-dialog/update-brand-details-dialog.component';
import { InviteBrandUserComponent } from './invite-brand-user/invite-brand-user.component';

@NgModule({
  imports: [
    CommonModule,
    BrandManagmentRoutingModule,
    AngularMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
  ],
  declarations: [
    BrandManagementComponent,
    BrandUsersComponent,
    UpdateBrandDetailsDialogComponent,
    InviteBrandUserComponent,
  ],
  exports: [BrandManagementComponent, BrandUsersComponent],
  providers: [RegisterGroup],
})
export class BrandManagementModule {}
