<div class="manage-classifications box">
  <div class="classification-card" *ngFor="let classification of classifications; let i = index">
    <div class="card">
      <div class="classification-info">
        <div class="name">
          <div class="index-circle" [style.background]="classification?.color">
            {{ i + 1 }}
          </div>
          <h2>{{ classification.name }}</h2>
        </div>
      </div>

      <div class="groups" id="{{ classification.uuid }}">
        <div class="group-card" *ngFor="let group of classification.groups">
          <div class="group-info">
            <div class="name">
              <h2>{{ group.name }}</h2>
            </div>
          </div>
          <div class="steps" id="{{ group.uuid }}">
            <app-step-item
              *ngFor="let groupStep of group.steps"
              [step]="groupStep"
              [color]="classification.color"
              [mode]="'view'"
            ></app-step-item>
          </div>
        </div>
      </div>

      <div class="drop-steps" id="{{ classification.uuid }}" *ngIf="classification.steps.length">
        <div class="steps-drop">
          <app-step-item
            *ngFor="let step of classification.steps"
            [step]="step"
            [color]="classification.color"
            [mode]="'view'"
          ></app-step-item>
        </div>
      </div>
    </div>
  </div>
</div>
