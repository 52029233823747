import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';

import { AccountCreatedComponent } from './account-created/account-created.component';
import { AngularMaterialModule } from '@app/material.module';
import { CommonModule } from '@angular/common';
import { CompleteProfileNewComponent } from './complete-register-steps/complete-profile-new/complete-profile-new.component';
import { CompleteRegisterStepsComponent } from './complete-register-steps/complete-register-steps.component';
import { CreateAccountNewComponent } from './create-account-new/create-account-new.component';
import { CreateBrandNewComponent } from './complete-register-steps/create-brand-new/create-brand-new.component';
import { CreateProductNewComponent } from './complete-register-steps/create-product-new/create-product-new.component';
import { NgModule } from '@angular/core';
import { ProfileCompletedComponent } from './complete-register-steps/profile-completed/profile-completed.component';
import { ReactiveFormsModule } from '@angular/forms';
import { RegisterRoutingModule } from './register-routing.module';
import { RouterModule } from '@angular/router';
import { SharedModule } from '@app/shared/shared.module';
import { UsePolicyDialogComponent } from './use-policy-dialog/use-policy-dialog.component';
import { VideoTutorialComponent } from './complete-register-steps/video-tutorial/video-tutorial.component';

@NgModule({
  declarations: [
    CompleteRegisterStepsComponent,
    AccountCreatedComponent,
    CreateAccountNewComponent,
    CompleteProfileNewComponent,
    CreateBrandNewComponent,
    CreateProductNewComponent,
    VideoTutorialComponent,
    ProfileCompletedComponent,
    UsePolicyDialogComponent,
  ],
  exports: [RouterModule, CompleteProfileNewComponent],
  imports: [CommonModule, RegisterRoutingModule, AngularMaterialModule, ReactiveFormsModule, SharedModule],
  providers: [provideHttpClient(withInterceptorsFromDi())],
})
export class RegisterModule {}
