import { Component, Input, OnInit } from '@angular/core';
import { ICompleteProfileErrorResponse, ICompleteProfileForm } from '@app/core/interface/register.interface';

import { UntypedFormGroup } from '@angular/forms';
import { GetDirtyValues } from '@app/core/utils/form-dirty-values';
import { MatStepper } from '@angular/material/stepper';
import { RegisterFacade } from '@app/core/facade/register.facade';
import { RegisterGroup } from '../../register.group';
import { NotificationType } from '@app/core/constants';
import { SnackbarService } from '@app/core/service/snackbar.service';

@Component({
  selector: 'app-complete-profile-new',
  templateUrl: './complete-profile-new.component.html',
  styleUrls: ['./complete-profile-new.component.scss'],
  providers: [RegisterFacade, RegisterGroup],
})
export class CompleteProfileNewComponent implements OnInit {
  @Input() stepper!: MatStepper;
  form: UntypedFormGroup;

  constructor(
    private _facade: RegisterFacade,
    private _snackbarService: SnackbarService,
    private _regGrup: RegisterGroup
  ) {
    this.form = this._regGrup.completeProfileForm;
  }

  saveImageData(file: File): void {
    this.form.get('avatar')?.setValue(file);
    this.form.get('avatar')?.markAsDirty();
  }

  ngOnInit() {
    const customer = JSON.parse(sessionStorage.getItem('customer') || '{}');

    if (customer) {
      this.form.patchValue({
        first_name: customer.first_name,
        last_name: customer.last_name,
        // role: customer.role
      });
      this.form.get('first_name')?.markAsDirty();
      this.form.get('last_name')?.markAsDirty();
      // this.form.get('role')?.markAsDirty();
    }
  }

  completeProfile(): void {
    const changedFormValues: Partial<ICompleteProfileForm> = GetDirtyValues(this.form);
    if (this.form.valid) {
      this._facade.completeProfile$({ ...changedFormValues }).subscribe({
        next: this._success.bind(this),
        error: this._error.bind(this),
      });
    }
  }

  private _success = () => {
    this._snackbarService.openTypeSnackbar('Profile details added successfully', NotificationType.success);

    this.stepper.next();
  };

  private _error(error: ICompleteProfileErrorResponse): void {
    Object.values(error).map(err => this._snackbarService.openTypeSnackbar(err[0], NotificationType.error));
  }
}
