import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { SharedModule } from '@app/shared/shared.module';
import { CertificationDashboardComponent } from './certification-dashboard.component';
import { CertificateDashboardRoutingModule } from './certification-dashboard-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { AngularMaterialModule } from '@app/material.module';
import { CertificatesFacade } from '@app/core/facade/certificates.facade';

@NgModule({
  declarations: [CertificationDashboardComponent],
  imports: [
    CommonModule,
    SharedModule,
    CertificateDashboardRoutingModule,
    ReactiveFormsModule,
    AngularMaterialModule,
    FormsModule,
  ],
  providers: [DatePipe, { provide: MAT_DATE_LOCALE, useValue: 'en-GB' }, CertificatesFacade],
  exports: [CertificationDashboardComponent],
})
export class CertificationDashboardModule {}
