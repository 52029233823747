<form [formGroup]="form">
  <div class="row wrp">
    <div class="col-1 pl">
      <img />
    </div>
    <p class="col-9 step-header">{{ 'Presence of plastic microfibers' | translate }}</p>
    <mat-card class="col-12 sec">
      <mat-radio-group
        class="d-flex justify-content-between"
        formControlName="plastic_presetce"
        required
        aria-label="Select an option"
      >
        <div class="row">
          <div class="col-6">
            <p class="p2-strong">
              {{ 'Please select this option, if the product contains MORE than 50% of synthetic fibers' | translate }}
            </p>
            <mat-radio-button [value]="true" class="align-items-start">
              {{ 'Releases plastic microfibers into the environment during washing.' | translate }}
            </mat-radio-button>
          </div>
          <div class="col-6">
            <p class="p2-strong">
              {{
                'Or if the product contains LESS than 50% of synthetic fibers, please select this option' | translate
              }}
            </p>
            <mat-radio-button [value]="false" class="align-items-start">
              {{ 'Does not release plastic microfibers into the environment during washing.' | translate }}
            </mat-radio-button>
          </div>
        </div>
      </mat-radio-group>
    </mat-card>
    <div class="col-1 ha">
      <img />
    </div>
    <div class="col-9">
      <div class="header-wrp">
        <p class="step-header">{{ 'Presence of hazardous substances' | translate }}</p>
        <sup #infoWrap>
          <div class="info" #info>
            <span>
              {{
                'SVHC (Substances of Very High Concern) are substances of great concern in Europe, listed as candidates
              for authorization under the REACH regulation. Once a substance is identified by REACH, it must be reported
              in your AGEC sheet within six months. For more information, you can refer to the ' | translate
              }}
              <a href="https://echa.europa.eu/candidate-list-table" target="_blank">REACH SVHC list</a>
            </span>
          </div>
          <app-custom-icon
            class="cursor-pointer"
            id="info"
            [icon_category]="'essential'"
            [icon_name]="'info-circle'"
            (click)="info.style.display = 'flex'"
          ></app-custom-icon>
        </sup>
      </div>
    </div>
    <mat-card class="col-12">
      <mat-radio-group
        class="d-flex justify-content-between"
        formControlName="hazardous_substances"
        required
        aria-label="Select an option"
      >
        <div class="row">
          <div class="col-6">
            <p class="p2-strong">
              {{
                'If any substances of very high concern (REACH regulation) are present in the final product at concentrations greater than 0.1%, select this option'
                  | translate
              }}
            </p>
            <mat-radio-button [value]="true" class="align-items-start">
              {{ 'The product contains a substance(s) of very high concern' | translate }}
            </mat-radio-button>
          </div>
          <div class="col-6">
            <p class="p2-strong">
              {{
                'If there are no substances of very high concern (REACH) at concentrations greater than 0.1%,
              choose this option' | translate
              }}
            </p>
            <mat-radio-button [value]="false" class="align-items-start">
              {{ 'The product does not contain any substance(s) of very high concern' | translate }}
            </mat-radio-button>
          </div>
          <div class="col-6 row" *ngIf="form.controls['hazardous_substances'].value === true">
            <div class="row col-6" *ngFor="let control of list?.controls ?? []; let i = index">
              <mat-form-field appearance="outline" class="w-100 input-primary px-1 col-10">
                <mat-label>{{ 'Substance Name' | translate }}</mat-label>
                <input
                  matInput
                  placeholder="{{ 'Substance Name' | translate }}"
                  [formControl]="$any(control)"
                  required
                />
                <mat-hint>{{ 'Substance Name' | translate }} </mat-hint>
              </mat-form-field>
              <app-custom-icon
                id="arrow-down"
                [icon_category]="'essential'"
                *ngIf="i > 0"
                [icon_name]="'minus-circle'"
                class="col-1 remove-btn"
                (click)="removeInput(i)"
              ></app-custom-icon>
            </div>
            <span class="add-p col-6"
              >{{ 'Add Substance' | translate }}
              <app-custom-icon
                [icon_category]="'essential'"
                [icon_name]="'add-circle'"
                class="ic"
                (click)="addInput()"
              ></app-custom-icon>
            </span>
          </div>
        </div>
      </mat-radio-group>
    </mat-card>
  </div>
</form>
