<div class="form-wrapper d-flex flex-column">
  <!-- General info -->
  <div class="section">
    <div *ngIf="!is_view" class="section-label">
      {{ 'supply-chain-step.General Info' | translate }}
    </div>
    <div class="section-content d-flex flex-column">
      <p class="m-0 title" *ngIf="is_view">{{ 'supply-chain-step.Step details' | translate }}</p>
      <div class="section-holder" [ngClass]="is_view ? 'view-holder' : 'edit-holder'">
        <div class="left-section">
          <div class="step-property">
            <p>{{ 'supply-chain-step.Step' | translate }}:</p>
            <p class="p1-strong">{{ step?.name }}</p>
          </div>
          <div class="step-property">
            <p>{{ 'supply-chain-step.Supply Chain' | translate }}:</p>
            <p class="p1-strong">
              {{ step?.product_chain?.name }}
            </p>
          </div>
        </div>
        <div class="right-section">
          <div class="step-property">
            <p>{{ 'supply-chain-step.Product' | translate }}:</p>
            <p class="p1-strong">
              {{ step?.product_chain?.product?.name }}
            </p>
          </div>
          <div *ngIf="is_enabled" class="step-property">
            <p>{{ 'supply-chain-step.Project' | translate }}:</p>
            <p class="p1-strong">
              {{
                step?.product_chain?.product?.project
                  ? step?.product_chain?.product?.project?.name
                  : ('No project assigned' | translate)
              }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="horizontal-line"></div>

  <!-- Supplier site -->
  <div class="section" [ngClass]="is_view ? 'view-site-section d-flex flex-column' : 'edit-site-section'">
    <div class="section-label d-flex align-items-center">
      {{ 'supply-chain-step.Site' | translate }}
      <app-custom-icon
        class="info-icon"
        icon_category="essential"
        icon_name="info-circle"
        [matTooltip]="
          ('supply-chain-step.The site is the place where the production or the action performed for this step takes place. Usually it is a factory or a plant, it can also be a field, forest or mine in the case of raw materials extraction or crop'
            | translate) + '.'
        "
        matTooltipPosition="above"
        matTooltipClass="custom-tooltip-center"
      ></app-custom-icon>
    </div>

    <div *ngIf="is_view" class="site-info d-flex align-items-center">
      <h6 class="m-0">{{ selected_site.value?.name }}</h6>
      <p *ngIf="selected_site.value">
        {{ selected_site.value.address ? selected_site.value.address + ', ' : '' }}
        {{ selected_site.value.city ? selected_site.value.city + ', ' : '' }}
        {{ selected_site.value.region ? selected_site.value.region + ', ' : '' }}
        {{ selected_site.value.country ? selected_site.value.country : '' }}
      </p>
      <p *ngIf="!selected_site.value">{{ 'supply-chain-step.not entered yet' | translate }}</p>
    </div>

    <div *ngIf="!is_view" class="supplier-site d-flex align-items-center">
      <mat-form-field
        #supplierSiteDropdown
        appearance="outline"
        class="dropdown-primary select-type"
        subscriptSizing="dynamic"
      >
        <mat-select
          [formControl]="selected_site"
          panelClass="dropdown-primary dropdown-select-type"
          placeholder="{{ 'supply-chain-step.Choose Supplier Site' | translate }}"
        >
          <mat-select-trigger>
            <div class="trigger-content d-flex align-items-center">
              <p class="p1-strong">{{ selected_site.value?.name }}</p>
              <p class="description">
                <span *ngIf="selected_site.value?.address">{{ selected_site.value?.address }}</span>
                <span *ngIf="selected_site.value?.city">{{ selected_site.value?.city }}</span>
                <span *ngIf="selected_site.value?.region">{{ selected_site.value?.region }}</span>
                <span *ngIf="selected_site.value?.country">{{ selected_site.value?.country }}</span>
              </p>
            </div>
          </mat-select-trigger>
          <mat-option class="d-flex align-items-center" *ngFor="let site of supplier_sites" [value]="site">
            <p class="p1-strong">{{ site.name }}</p>
            <p class="description">
              <span *ngIf="site.address">{{ site.address }}</span>
              <span *ngIf="site.city">{{ site.city }}</span>
              <span *ngIf="site.region">{{ site.region }}</span>
              <span *ngIf="site.country">{{ site.country }}</span>
            </p>
          </mat-option>
        </mat-select>
      </mat-form-field>

      <div class="create-site d-flex align-items-center">
        <p class="p2">{{ 'supply-chain-step.Not existing' | translate }}?</p>
        <app-rounded-button
          class="create-site-btn"
          color="secondary"
          size="sm"
          button_text="{{ 'supply-chain-step.Create new site' | translate }}"
          icon_category="buildings"
          icon_name="factory"
          [icon_right]="true"
          (click)="onCreateNewSite()"
        >
        </app-rounded-button>
      </div>
    </div>
  </div>
</div>
