<div class="row">
  <div class="col">
    <h2 class="modal-step__title alt-black">{{ data.name }}</h2>
    <p class="modal-step__description" [innerHTML]="data.description"></p>
    <div *ngIf="images && images?.length !== 0" class="row">
      <div class="col">
        <div class="modal-step__label mb-1">
          {{ 'Media Gallery' | translate }}
        </div>
        <section class="modal-step__gallery">
          <div *ngIf="images.length >= 3" class="carousels">
            <!-- <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig">
              <div ngxSlickItem *ngFor="let slide of images" class="slide">
                <div (click)="onImageDialog(images, slide)">
                  <img
                    src="{{ slide.file }}"
                    alt=""
                    width="200px"
                    height="200px"
                    class="media-swiper__item__img cursor-pointer"
                  />
                </div>
              </div>
            </ngx-slick-carousel> -->
          </div>
          <div *ngIf="images.length < 3" class="carousels-flex">
            <div class="d-flex">
              <div *ngFor="let slide of images" class="slide">
                <div (click)="onImageDialog(images, slide)">
                  <img
                    src="{{ slide.file }}"
                    alt=""
                    width="200px"
                    height="200px"
                    class="media-item__img cursor-pointer"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
    <div *ngIf="videos && videos?.length !== 0" class="row">
      <div class="col">
        <div class="modal-step__label mb-1">
          {{ 'Video' | translate }}
        </div>
        <section class="modal-step__gallery">
          <div *ngIf="videos.length >= 3" class="carousels">
            <!-- <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfigVideo">
              <div ngxSlickItem *ngFor="let slide of videos" class="slide">
                <div (click)="onVideoDialog(slide.file)">
                  <img
                    src="{{ slide.medium }}"
                    alt=""
                    width="200px"
                    height="200px"
                    class="media-swiper__item__img cursor-pointer"
                  />
                </div>
              </div>
            </ngx-slick-carousel> -->
          </div>
          <div *ngIf="videos.length < 3" class="carousels-flex">
            <div class="d-flex">
              <div *ngFor="let slide of videos" class="slide">
                <div (click)="onVideoDialog(slide.file)">
                  <img
                    src="{{ slide.medium }}"
                    alt=""
                    width="200px"
                    height="200px"
                    class="media-item__img cursor-pointer"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</div>
