import { Component, OnInit } from '@angular/core';
import { IQuestionnaire, IQuestionnaireResponse } from '@interface/questionnaire.interface';
import { QuestionnaireFacade } from '@facade/questionnaire.facade';
import { IBatchQuanityUnitResponse } from '@interface/batch.interface';
import { NotificationType } from '@app/core/constants';
import { SnackbarService } from '@app/core/service/snackbar.service';

@Component({
  selector: 'app-questionnaires-management',
  templateUrl: './questionnaires-management.component.html',
  styleUrls: ['./questionnaires-management.component.scss'],
})
export class QuestionnairesManagementComponent implements OnInit {
  questionnaires!: IQuestionnaire[];

  constructor(
    private questionnaireFacade: QuestionnaireFacade,
    private _snackbarService: SnackbarService
  ) {}

  ngOnInit(): void {
    this.getQuestionnaires();
  }

  private getQuestionnaires() {
    this.questionnaireFacade.getQuestionnaires$().subscribe({
      next: this.getQuestionnairesSuccess.bind(this),
      error: this._error.bind(this),
    });
  }

  private getQuestionnairesSuccess(data: IQuestionnaireResponse) {
    this.questionnaires = data.results;
  }

  private _error(error: IBatchQuanityUnitResponse): void {
    Object.values(error).map(err => this._snackbarService.openTypeSnackbar(err[0], NotificationType.error));
  }

  countQuestions(questionnaireUid: string) {
    return this.questionnaires
      .find(x => x.uuid === questionnaireUid)
      ?.sections.map(x => (x.questions.length > 0 ? x.questions.length : 0))
      .reduce((sum, current) => sum + current, 0);
  }
}
