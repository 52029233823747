import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { AdminHeaderComponent } from './admin-header/admin-header.component';
import { AngularMaterialModule } from '@app/material.module';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header.component';
import { HelpHeaderComponent } from './help-header/help-header.component';
import { NgModule } from '@angular/core';
import { BreadcrumbHeaderComponent } from './breadcrumb-header/breadcrumb-header.component';
import { SharedModule } from '@app/shared/shared.module';

@NgModule({
  declarations: [HeaderComponent, AdminHeaderComponent, HelpHeaderComponent, BreadcrumbHeaderComponent],
  imports: [CommonModule, AngularMaterialModule, RouterModule, SharedModule, TranslateModule.forChild({})],
  exports: [HeaderComponent],
})
export class HeaderModule {}
