<h2>{{ 'billing-management.Paid Users List' | translate }}</h2>
<div class="table-wraper" *ngIf="tableDataSource.data.length > 0">
  <table mat-table matSort [dataSource]="tableDataSource">
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'billing-management.User Name' | translate }}</th>
      <td mat-cell *matCellDef="let element">
        {{ element.first_name || element.last_name ? element.first_name + ' ' + element.last_name : 'N/A' }}
      </td>
    </ng-container>

    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'billing-management.User Email' | translate }}</th>
      <td mat-cell *matCellDef="let element">{{ element.email }}</td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef class="actions-header text-center">
        {{ 'billing-management.Actions' | translate }}
      </th>
      <td mat-cell *matCellDef="let element" class="actions-body text-center">
        <app-custom-icon
          class="cursor-pointer"
          [icon_category]="'user'"
          [icon_name]="'profile-remove'"
          (click)="deleteBrandUser(element.uuid, element.email)"
          matTooltip="{{ 'billing-management.Remove Account' | translate }}"
          matTooltipClass="custom-tooltip-end mb-16"
          [matTooltipPosition]="'above'"
        ></app-custom-icon>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>
<div class="card-footer">
  <mat-paginator class="custom-paginator" showFirstLastButtons aria-label="Select page of users"> </mat-paginator>
</div>
