import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  IBrandUserListResponse,
  IPermissionListResponse,
  IPutDeleteBrandUserPermissions,
  IPutDeleteBrandUserPermissionsResult,
} from '../interface/users-access.interface';
import { UsersAccessService } from '../service/users-access.service';
import { take } from 'rxjs/internal/operators/take';
import { generateFormData } from '../utils/generate-formData';

@Injectable()
export class UsersAccessFacade {
  constructor(private _usersAccessService: UsersAccessService) {}

  getBrandUsers$(): Observable<IBrandUserListResponse> {
    return this._usersAccessService.getBrandUsers$().pipe(take(1));
  }

  getPermissionsListLookup$(): Observable<IPermissionListResponse> {
    return this._usersAccessService.getPermissionsListLookup$().pipe(take(1));
  }

  putBrandUserPermissions$(data: IPutDeleteBrandUserPermissions): Observable<IPutDeleteBrandUserPermissionsResult> {
    const formData = generateFormData(data);

    return this._usersAccessService.putBrandUserPermissions$(formData).pipe(take(1));
  }

  deleteBrandUserPermissions$(data: IPutDeleteBrandUserPermissions): Observable<IPutDeleteBrandUserPermissionsResult> {
    const formData = generateFormData(data);

    return this._usersAccessService.deleteBrandUserPermissions$(formData).pipe(take(1));
  }
}
