<div class="wraper" cdkScrollable>
  <div class="breadcrump">
    <app-custom-icon
      [icon_category]="'arrows'"
      [icon_name]="'arrow-circle-left-short'"
      (click)="goBack()"
    ></app-custom-icon>
    <h6>{{ product?.name }}</h6>
    <app-custom-icon [icon_category]="'arrows'" [icon_name]="'arrow-right-short'"></app-custom-icon>

    <h6>{{ customPublication?.name }}</h6>
    <app-custom-icon [icon_category]="'arrows'" [icon_name]="'arrow-right-short'"></app-custom-icon>
    <h3>{{ 'Setup Your Chain' | translate }}</h3>
  </div>

  <div class="background-holder"></div>

  <div class="row holder">
    <div class="watch-video" *ngIf="showVideoMsg">
      <p>
        Watch this video to understand how to organize your original steps, so you can make amazing looking publication.
      </p>
      <div class="link">
        <h5>Watch Video</h5>
        <app-custom-icon [icon_category]="'essential'" [icon_name]="'video'"></app-custom-icon>
      </div>
      <app-custom-icon
        [icon_category]="'essential'"
        [icon_name]="'close-circle'"
        (click)="showVideoMsg = false"
      ></app-custom-icon>
    </div>

    <div
      class="row p-0"
      cdkDropList
      [cdkDropListAutoScrollStep]="20"
      cdkDropListGroup
      [cdkDropListData]="classifications"
      (cdkDropListDropped)="dropClassification($event)"
    >
      <div class="col-6 left-side">
        <div class="create-new box">
          <div class="button-wrapper">
            <div class="">
              <app-rounded-button
                (click)="editClassification()"
                color="primary"
                [button_text]="'Create New Classification'"
                [size]="'md'"
              >
              </app-rounded-button>
            </div>
            <div class="">
              <mat-form-field
                class="input-primary mat-form-field-30-px-brd-rd-dd"
                appearance="outline"
                subscriptSizing="dynamic"
              >
                <mat-select
                  panelClass="dropdown-primary choose-unit-dropdown"
                  (valueChange)="productGroupChanged($event)"
                  [value]="groupSelected"
                  placeholder="{{ 'Choose Product Group' | translate }}"
                >
                  <mat-option *ngIf="productGroups?.count === 0">{{
                    'No product group is created yet' | translate
                  }}</mat-option>
                  <mat-option *ngFor="let productGroup of productGroups?.results" [value]="productGroup.uuid">
                    {{ productGroup.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>

        <div class="manage-classifications box">
          <div
            class="classification-card"
            *ngFor="let classification of classifications; let classificationIndex = index"
            cdkDropListGroup
            cdkDrag
            [cdkDragData]="classification"
          >
            <div class="card">
              <div class="classification-info">
                <div class="name">
                  <div class="index-circle" [style.background]="classification?.color">
                    {{ classificationIndex + 1 }}
                  </div>
                  <h2>{{ classification.name }}</h2>
                  <app-custom-icon
                    [icon_category]="'files'"
                    [icon_name]="'edit'"
                    (click)="editClassification(classification, classificationIndex)"
                  ></app-custom-icon>
                  <app-custom-icon
                    [icon_category]="'essential'"
                    [icon_name]="'trash'"
                    (click)="removeClassification(classification.uuid, classificationIndex)"
                  ></app-custom-icon>
                </div>
                <div class="reorder-info">
                  <span>{{ 'click & drag up or down' | translate }}</span>
                  <div class="icon-wraper">
                    <app-custom-icon class="edit" [icon_category]="'arrows'" [icon_name]="'arrow-2'"></app-custom-icon>
                  </div>
                </div>
              </div>
              <div class="create-group" (click)="addEditGroup(classification.uuid, undefined, classificationIndex)">
                <h3>Create a group of steps</h3>
                <app-custom-icon [icon_category]="'essential'" [icon_name]="'add'"></app-custom-icon>
              </div>
              <div
                class="drop-steps"
                cdkDropList
                [cdkDropListAutoScrollStep]="20"
                id="{{ classification.uuid }}"
                [cdkDropListData]="classification.steps || []"
                (cdkDropListDropped)="dropItem($event, classification.uuid)"
                [cdkDropListConnectedTo]="getConnectedListAll()"
              >
                <div class="steps-drop">
                  <app-amway-step-item
                    *ngFor="let step of classification.steps; let i = index"
                    [step]="step"
                    [color]="classification.color"
                    cdkDrag
                    [cdkDragData]="step"
                    (editStepClick)="editStep($event, i, -1, classificationIndex)"
                    (hideStepClick)="hideStep($event, i, -1, classificationIndex)"
                  ></app-amway-step-item>

                  <div class="drop-placeholder">
                    <div class="text">
                      <p>Drop the step here</p>
                      <app-custom-icon [icon_category]="'essential'" [icon_name]="'mouse-square'"></app-custom-icon>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="groups"
                cdkDropList
                [cdkDropListAutoScrollStep]="20"
                id="{{ classification.uuid }}"
                [cdkDropListData]="classification.groups"
                (cdkDropListDropped)="dropGroup($event, classification)"
                [cdkDropListConnectedTo]="getConnectedListAll()"
              >
                <div
                  class="group-card"
                  *ngFor="let group of classification.groups; let groupIndex = index"
                  cdkDrag
                  [cdkDragData]="group"
                >
                  <div class="group-info">
                    <div class="name">
                      <h2>{{ group.name }}</h2>
                      <app-custom-icon
                        [icon_category]="'files'"
                        [icon_name]="'edit'"
                        (click)="addEditGroup(classification.uuid, group, classificationIndex, groupIndex)"
                      ></app-custom-icon>
                      <app-custom-icon
                        [icon_category]="'essential'"
                        [icon_name]="'trash'"
                        (click)="deleteGroup(group.uuid, classificationIndex, groupIndex)"
                      ></app-custom-icon>
                    </div>
                    <div class="reorder-info">
                      <span>{{ 'click & drag up or down' | translate }}</span>
                      <div class="icon-wraper">
                        <app-custom-icon
                          class="edit"
                          [icon_category]="'arrows'"
                          [icon_name]="'arrow-2'"
                        ></app-custom-icon>
                      </div>
                    </div>
                  </div>
                  <div
                    class="steps"
                    cdkDropList
                    [cdkDropListAutoScrollStep]="20"
                    id="{{ group.uuid }}"
                    [cdkDropListData]="group.steps"
                    (cdkDropListDropped)="dropGroupItem($event, classification.uuid, group.uuid)"
                    [cdkDropListConnectedTo]="getConnectedListAll()"
                  >
                    <app-amway-step-item
                      *ngFor="let groupStep of group.steps; let i = index"
                      [step]="groupStep"
                      [color]="classification.color"
                      cdkDrag
                      [cdkDragData]="groupStep"
                      (editStepClick)="editStep($event, i, groupIndex, classificationIndex)"
                      (hideStepClick)="hideStep($event, i, groupIndex, -1)"
                    ></app-amway-step-item>

                    <div class="drop-placeholder">
                      <div class="text">
                        <p>Drop the step here</p>
                        <app-custom-icon [icon_category]="'essential'" [icon_name]="'mouse-square'"></app-custom-icon>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-6 right-side" *ngIf="classifications?.length">
        <div class="create-new-step box">
          <app-rounded-button color="primary" [button_text]="'Create New Step'" [size]="'md'" (click)="createNewStep()">
          </app-rounded-button>
        </div>
        <mat-accordion>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <app-custom-icon [icon_category]="'essential'" [icon_name]="'info-circle'"></app-custom-icon>
                <p>How to classify the steps?</p>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <p>
              All of the unclassified steps are your original steps and they must be classified before publishing. In
              order to classify them, please drag & drop each step into a corresponding classification, or hide the
              step.
            </p>
          </mat-expansion-panel>
        </mat-accordion>
        <div class="manage-steps">
          <div
            cdkDropList
            id="unclassified"
            [cdkDropListAutoScrollStep]="20"
            [cdkDropListData]="unclassifiedSteps || []"
            (cdkDropListDropped)="dropItem($event)"
            [cdkDropListConnectedTo]="getConnectedListAll()"
          >
            <h4>{{ 'Your Unclassified Steps' | translate }}</h4>
            <app-amway-step-item
              *ngFor="let step of unclassifiedSteps; let i = index"
              [step]="step"
              cdkDrag
              [cdkDragData]="step"
              (editStepClick)="editStep($event, i, -1, -1)"
              (hideStepClick)="hideStep($event, i, -1, -1)"
            ></app-amway-step-item>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="footer">
    <app-rounded-button
      color="primary"
      [button_text]="'Publish Now'"
      [icon_category]="'arrows'"
      [icon_name]="'arrow-right-long'"
      [size]="'md'"
      [icon_right]="true"
      [disabled]="shouldDisableFinish()"
      (click)="publish()"
    >
    </app-rounded-button>
  </div>
</div>
