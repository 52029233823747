import { Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AgecPackagingType, IAgecPackaging, PackageRecyclabitity } from '@app/core/interface/regulations.interface';
import { SetPackagingInfoComponent } from './set-packaging-info/set-packaging-info.component';
import { RegulationsFacade } from '@app/core/facade/regulations.facade';
import { IProduct } from '@app/core/interface/products.interface';
import { NotificationType } from '@app/core/constants';
import { SnackbarService } from '@app/core/service/snackbar.service';

@Component({
  selector: 'app-create-packaging-dialog',
  templateUrl: './create-packaging-dialog.component.html',
  styleUrls: ['./create-packaging-dialog.component.scss'],
})
export class CreatePackagingDialogComponent {
  @ViewChild(SetPackagingInfoComponent) setPackagingInfoComponent!: SetPackagingInfoComponent;
  product!: IProduct;
  pac: IAgecPackaging = {
    agec: '',
    is_recyclable: false,
    name: '',
    package_type: 0,
    package_recyclability: 0,
    uuid: '',
  };
  invalid = true;
  showTooltip = false;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { edit: boolean; agec: string; pac: IAgecPackaging; product: IProduct },
    public dialogRef: MatDialogRef<CreatePackagingDialogComponent>,
    private _facade: RegulationsFacade,
    private _snackbarService: SnackbarService
  ) {
    this.pac = data.pac;
    this.product = data.product;
  }

  isInvalid(event: boolean) {
    this.invalid = event;
  }

  createPackaging() {
    const pac = {
      name: this.setPackagingInfoComponent.form.value['name'],
      type: this.setPackagingInfoComponent.form.value['type'],
      uuid: this.setPackagingInfoComponent.packaging?.uuid,
      formValues: this.setPackagingInfoComponent.formValues,
      dirty: this.setPackagingInfoComponent.form.dirty,
    };

    const package_type = this._facade.isKeyOfAgecPackagingType(pac.type)
      ? AgecPackagingType[pac.type as keyof typeof AgecPackagingType]
      : -1;
    const package_recyclability = this._facade.isKeyOfPackageRecyclabitity(pac.formValues[1].is_recyclable_type ?? '')
      ? PackageRecyclabitity[pac.formValues[1].is_recyclable_type as keyof typeof PackageRecyclabitity]
      : -1;
    const data: IAgecPackaging = {
      agec: this.data.agec ?? '',
      is_recyclable: package_recyclability !== 4,
      is_recyclable_display: pac.formValues[1].is_recyclable_display,
      is_recycled_materials: pac.formValues[1].is_recycled_materials,
      is_recycled_materials_display: pac.formValues[1].is_recycled_materials_display,
      name: pac.name,
      package_type: package_type,
      package_recyclability: package_recyclability,
      uuid: pac.uuid ?? '',
      hazardous_substance: pac.formValues[0].hazardous_substances,
      steps_completed: 5,
    };

    if (pac && pac.uuid) {
      this._facade.updatePackaging$(data, pac.uuid ?? '', 'agec').subscribe({
        next: this._success.bind(this),
        error: this._error.bind(this),
      });
    } else {
      this._facade.createPackaging$(data, 'agec').subscribe({
        next: this._success.bind(this),
        error: this._error.bind(this),
      });
    }
  }
  onHover(visible: boolean): void {
    this.showTooltip = visible;
  }
  onHoverOut(): void {
    this.showTooltip = false;
  }
  private _success(resposne: IAgecPackaging) {
    this.dialogRef.close(resposne);
  }
  private _error(error: Record<string, string[]>): void {
    Object.values(error).map(err => this._snackbarService.openTypeSnackbar(err[0], NotificationType.error));
  }
}
