import { Component, OnInit, ViewChild } from '@angular/core';
import { IRecentSupplier, IRecentSuppliersResponse, ISupplierAlerts } from '@app/core/interface/suppliers.interface';

import { CreateSupplierComponent } from './create-supplier/create-supplier.component';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { NotificationType } from '@app/core/constants';
import { SnackbarService } from '@app/core/service/snackbar.service';
import { SuppliersFacade } from '@app/core/facade/suppliers.facade';

@Component({
  selector: 'app-supplier-dashboard',
  templateUrl: './supplier-dashboard.component.html',
  styleUrls: ['./supplier-dashboard.component.scss'],
})
export class SupplierDashboardComponent implements OnInit {
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  suppliers: Array<IRecentSupplier> = [];
  fullListsuppliers: Array<IRecentSupplier> = [];
  displayedColumns: string[] = ['name', 'message', 'actions'];
  dataSourceData: Array<ISupplierAlerts> = Array(497).fill({
    name: 'Name',
    message: 'Message from supplier',
  });
  stats: Array<{ title: string; value: unknown }> = [
    { title: 'Suppliers Created', value: 192 },
    { title: 'Suppliers Invited', value: 122 },
    { title: 'Suppliers Accounts Created', value: 430 },
    { title: 'Verified Suppliers Accounts', value: 192 },
  ];
  cubeStatus: Array<{ title: string; value: unknown }> = [
    { title: 'Completed', value: 43 },
    { title: 'Missing Info', value: 82 },
    { title: 'Not Sent', value: 24 },
  ];

  constructor(
    private _suppliersFacade: SuppliersFacade,
    private _snackbarService: SnackbarService,
    private dialog: MatDialog
  ) {}
  ngOnInit(): void {
    this.getSuppliers();
  }

  getSuppliers() {
    this._suppliersFacade.getRecentSuppliers$().subscribe({
      next: this._getSuppliersSuccess.bind(this),
      error: this._error.bind(this),
    });
  }

  onQuickFilterChanged(value: string) {
    this.suppliers =
      value.trim() === ''
        ? this.fullListsuppliers
        : this.fullListsuppliers.filter((element: IRecentSupplier) => {
            return element.entity_name.trim().toLowerCase().includes(value.trim().toLowerCase());
          });
  }

  private _getSuppliersSuccess(params: IRecentSuppliersResponse) {
    this.suppliers = params.results ? params.results : [];
    this.fullListsuppliers = JSON.parse(JSON.stringify(this.suppliers));
  }

  createSupplierDialog(): void {
    const dialogRef = this.dialog.open(CreateSupplierComponent, { panelClass: 'top-padding-0' });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.getSuppliers();
      }
    });
  }

  private _error(error: Record<string, string[]>): void {
    Object.values(error).map(err => this._snackbarService.openTypeSnackbar(err[0], NotificationType.error));
  }
}
