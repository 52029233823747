import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';

const routes: Routes = [
  // { path: '', component: ProjectComponent }, // TODO remove routing for this module?
  // { path: ':uuid', component: ProjectComponent },
  // { path: 'subprojects', component: SubProjectComponent },
  // { path: 'subprojects/:uuid', component: SubProjectComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ProjectsRoutingModule {}
