import { Component } from '@angular/core';

@Component({
  selector: 'app-product-type',
  templateUrl: './product-type.component.html',
  styleUrls: ['./product-type.component.scss'],
})
export class ProductTypeComponent {
  dirty = false;
  types: { name: string; checked: boolean }[] = [
    { name: 'Clothing', checked: false },
    { name: 'Household Linen', checked: false },
    { name: 'Shoes', checked: false },
  ];
  selectedType?: {
    name: string;
    checked: boolean;
  };
}
