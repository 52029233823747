import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { IMedia } from '@interface/medias.interface';
import { IProductChainStep } from '@interface/steps.interface';

@Component({
  selector: 'app-amway-step-details',
  templateUrl: './amway-step-details.component.html',
  styleUrl: './amway-step-details.component.scss',
})
export class AmwayStepDetailsComponent implements OnChanges {
  @Input() form!: UntypedFormGroup;
  @Input() step?: IProductChainStep;
  @Output() refreshStep = new EventEmitter<boolean>();
  photos?: IMedia[];
  videos?: IMedia[];

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['step'] && this.step) {
      this.photos = this.step.medias?.filter((media: IMedia) => media.mimetype.indexOf('image') > -1);
      this.videos = this.step.medias?.filter((media: IMedia) => media.mimetype.indexOf('video') > -1);
    }
  }
}
