import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { LanguagesFacade } from '@app/core/facade/languages.facade';
import { ILanguage, ILanguagesResponse } from '@app/core/interface/languages.interface';
import { CompleteDirectSupplierGroup } from './complete-direct-supplier-dialog.group';
import { UntypedFormGroup } from '@angular/forms';
import {
  IQuantityUnit,
  IStepBatchQuanityUnitResponse,
  ISuppStepBatchDirectSupplier,
} from '@app/core/interface/supp.interface';
import { IMedia } from '@app/core/interface/medias.interface';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatMenu } from '@angular/material/menu';
import { SuppSuppliersFacade } from '@app/core/facade/supp-suppliers.facade';
import { InfoDialogComponent } from '@app/shared/components/info-dialog/info-dialog.component';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { FilesMeadiasLinksFacade } from '@app/core/facade/files-medias-links.facade';
import {
  ADD_DOCUMENT_ALLOWED_TYPES,
  NotificationType,
  OFFICE_FILENAME_EXTENSIONS,
  infoDialogHeight,
  infoDialogWidth,
  uploadFileSize,
} from '@app/core/constants';
import { SnackbarService } from '@app/core/service/snackbar.service';
import { TranslateService } from '@ngx-translate/core';
import { FileViewComponent } from '@app/shared/components/file-view/file-view.component';

@Component({
  selector: 'app-complete-direct-supplier-dialog',
  templateUrl: './complete-direct-supplier-dialog.component.html',
  styleUrls: ['./complete-direct-supplier-dialog.component.scss'],
  providers: [LanguagesFacade, CompleteDirectSupplierGroup],
})
export class CompleteDirectSupplierDialogComponent implements OnInit {
  languages: ILanguage[] = [];
  form!: UntypedFormGroup;
  formFileUpload!: UntypedFormGroup;
  isActionViewMenuOpen = false;
  isViewMoreMenuOpen = false;
  isViewMore = false;
  activeIndex!: number | null;
  activeIndexdocList!: number | null;
  actionViewMenus: MatMenu[] = [];
  directSupplier!: ISuppStepBatchDirectSupplier;
  proofs!: IMedia[];
  unitList!: IQuantityUnit[];

  fileName? = '';
  typeWarn = false;
  docURL = '';
  docURLTransformed: SafeResourceUrl = '';
  warn = false;
  viewer: 'url' | 'office' = 'office';

  @ViewChild('actionViewMenu') actionViewMenu: MatMenu | undefined; // Reference to actionViewMenu
  @ViewChild('actionViewMenu') viewMoreMenu: MatMenu | undefined; // Reference to actionViewMenu
  initialFormValues!: UntypedFormGroup; // Store initial form values
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      step_uuid: string;
      directSupplier: ISuppStepBatchDirectSupplier;
      is_active: boolean;
      proofs: IMedia[];
    },
    public dialogRef: MatDialogRef<CompleteDirectSupplierDialogComponent>,
    private _languagesFacade: LanguagesFacade,
    private _snackbarService: SnackbarService,
    private _group: CompleteDirectSupplierGroup,
    private _dialog: MatDialog,
    private _facade: SuppSuppliersFacade,
    private _filesMediasfacade: FilesMeadiasLinksFacade,
    public sanitizer: DomSanitizer,
    public translate: TranslateService
  ) {
    this.dialogRef.disableClose = true;
    this.form = this._group.completeDirectSupplier;
    this.directSupplier = data.directSupplier;
    this.proofs = data.proofs;
  }

  ngOnInit(): void {
    this.getLanguageList();
    this.getStepBatchUnits();
  }

  patchValue(): void {
    const languagePrefered = this.directSupplier.language_prefered;
    const preferredLanguage = this.languages.find(lang => lang.name === languagePrefered);
    this.form.patchValue({
      email: this.directSupplier.email,
      language_prefered: preferredLanguage?.uuid,
      quantity: this.directSupplier.quantity,
      reference: this.directSupplier.reference,
      quantity_unit: this.directSupplier?.quantity_unit?.uuid,
      proofs: this.directSupplier?.proofs,
    });
    this.initialFormValues = { ...this._group.completeDirectSupplier.value };
  }

  getStepBatchUnits(): void {
    this._facade.getStepBatchQuantityUnit$().subscribe({
      next: this._getStepBatchQuantityUnitSuccess.bind(this),
      error: this._error.bind(this),
    });
  }

  getLanguageList(): void {
    this._languagesFacade.getLanguages$().subscribe({
      next: this._setLanguagesList.bind(this),
      error: this._error.bind(this),
    });
  }

  updateDirectSupplier(): void {
    this._facade.updateStepBatchDirectSupplier$(this.form.value, this.data.directSupplier.uuid).subscribe({
      next: this._updateStepBatchDirectSupplierhSuccess.bind(this),
      error: this._error.bind(this),
    });
  }

  // openFileList(screen: IMediaScreens, media?: IMedia): void {
  //   const list: IMedia[] = [];
  //   this._dialog.open(ViewUsefullDataDialogComponent, {
  //     width: '1160px',
  //     height: '660px',
  //     panelClass: ['overflow-hidden-dialog', 'view-documents-dialog'],
  //     data: {
  //       data: list,
  //       screen: screen,
  //       selectedFile: media,
  //       is_supplier: true,
  //     },
  //     disableClose: true,
  //   });
  // }

  openDocument(file: IMedia) {
    let mimetype;
    const extIndex = file.name.split('.').length - 1;
    const ext = file.name.split('.')[extIndex];
    if (OFFICE_FILENAME_EXTENSIONS.includes(ext)) {
      mimetype = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
    } else {
      mimetype = 'application/pdf';
    }

    this._dialog.open(FileViewComponent, {
      width: '1185px',
      height: '780px',
      data: {
        url: file.file,
        name: file.name,
        mimetype: mimetype,
        created_at: file.created_at,
        isBatch: true,
        backTitle: 'Step Details',
      },
    });
  }

  removeProofDialog(mediaUuid: string): void {
    const dialogRef = this._dialog.open(InfoDialogComponent, {
      width: '580px',
      height: '460px',
      data: {
        infoText:
          'You are about to delete this proof. Please note that if you delete the selected proof, the changes cannot be undone.',
        confirmationText: 'Do you wish to continue?',
        btnText: 'Yes, Delete',
        type: 'warning',
        text: 'warning-text',
      },
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result && mediaUuid != null) {
        this._removeFile(mediaUuid);
      }
    });
  }

  onFileSelected(event: Event): void {
    const target = event.target as HTMLInputElement;
    const file = target.files?.[0];
    this.fileName = this.docURL = this.docURLTransformed = '';

    if (file) {
      this.fileName = file.name;
      this.typeWarn = !this._filesMediasfacade.isValidUploadType(file.type, ADD_DOCUMENT_ALLOWED_TYPES);
      if (this.typeWarn) return;
      if (file.size > uploadFileSize) {
        this.warn = true;
        return;
      }

      file.arrayBuffer().then(arrayBuffer => {
        const blob = new Blob([new Uint8Array(arrayBuffer)], {
          type: file.type,
        });
        this.docURL = URL.createObjectURL(blob);
        this.docURLTransformed = this._transformTrustURL(this.docURL);
      });
      this.saveDocumentData(file);
    }
  }

  saveDocumentData(transFile: File): void {
    const file = transFile;
    const name = file.name.split('.').slice(0, -1).join('-');

    const formData: FormData = new FormData();
    formData.append('file', file);
    formData.append('name', name);

    this._facade.addDirectSupplierProof$(formData, this.directSupplier.uuid).subscribe({
      next: this._addFileSuccess.bind(this),
      error: this._error.bind(this),
    });
  }

  toggleActionViewMenu(event: MouseEvent, index: number) {
    this.isActionViewMenuOpen = !this.isActionViewMenuOpen;
    this.activeIndex = index;
    event.stopPropagation();
  }

  onActionViewMenuClosed() {
    this.isActionViewMenuOpen = false;
    this.activeIndex = null;
  }

  toggleViewMoreMenu(event: MouseEvent) {
    this.isViewMoreMenuOpen = !this.isViewMoreMenuOpen;
    event.stopPropagation();
  }

  onViewMoreMenuClosed() {
    this.isViewMoreMenuOpen = false;
  }

  toggleViewMore(event: MouseEvent, index: number) {
    this.isViewMore = !this.isViewMore;
    this.activeIndexdocList = index;
    event.stopPropagation();
  }

  onViewMoreClosed() {
    this.isViewMore = false;
    this.activeIndexdocList = null;
  }

  onCancel(): void {
    if (this._hasFormChanges()) {
      const dialogRef = this._dialog.open(InfoDialogComponent, {
        width: infoDialogWidth,
        height: infoDialogHeight,
        data: {
          infoText: this.translate.instant(
            'complete-direct-supplier.Please note that you have unsaved changes, and if you cancel now all progress will be lost.'
          ),
          confirmationText: this.translate.instant('complete-direct-supplier.Do you wish to continue?'),
          btnText: this.translate.instant('complete-direct-supplier.Yes, Continue'),
          type: 'warning',
          text: 'warning-text',
          disapproved: true,
        },
      });

      dialogRef.afterClosed().subscribe((result: boolean) => {
        if (result) {
          this.dialogRef.close();
        }
      });
    } else {
      // Close the dialog directly if there are no changes in the form
      this.dialogRef.close();
    }
  }

  ///////////////////////////////////// PRIVATE METHODS //////////////////////////////////////////

  private _addFileSuccess(data: IMedia): void {
    this.proofs?.push(data);
    this.form.controls['proofs'].setValue(this.proofs?.length ? this.proofs : null);
    this._snackbarService.openTypeSnackbar(`File uploaded successfully`, NotificationType.success);
  }

  private _removeFile(mediaUuid: string): void {
    if (mediaUuid != null) {
      this._facade.deleteDirectSupplierProof$(mediaUuid).subscribe({
        next: () => {
          this._removeFileSuccess(mediaUuid);
        },
        error: error => {
          this._error(error);
        },
      });
    }
  }

  private _removeFileSuccess(mediaUuid: string): void {
    const index = this.proofs?.findIndex(obj => obj.uuid === mediaUuid);
    if (index !== null && index !== undefined) {
      this.proofs?.splice(index, 1);
      this.form.controls['proofs'].setValue(this.proofs?.length ? this.proofs : null);
      this._snackbarService.openTypeSnackbar(`File removed successfully`, NotificationType.success);
    }
  }

  private _hasFormChanges(): boolean {
    // Check if there are any changes in the form by comparing current and initial form values
    return JSON.stringify(this.form.value) !== JSON.stringify(this.initialFormValues);
  }

  private _getStepBatchQuantityUnitSuccess(data: IStepBatchQuanityUnitResponse): void {
    this.unitList = data.results;
  }

  private _updateStepBatchDirectSupplierhSuccess(response: ISuppStepBatchDirectSupplier): void {
    this.dialogRef.close(response);
  }

  private _setLanguagesList(data: ILanguagesResponse): void {
    this.languages = data.results;
    this.patchValue();
  }

  private _transformTrustURL(url: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  private _error(error: Record<string, string>): void {
    Object.values(error).map(err => this._snackbarService.openTypeSnackbar(err, NotificationType.error));
  }
}
