import { ClusterService } from '../service/cluster.service';
import { ICluster, IDeleteCluster } from './../interface/cluster.interface';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { ObservableInput } from 'rxjs/internal/types';
import { catchError } from 'rxjs/internal/operators/catchError';
import { generateFormData } from '../utils/generate-formData';
import { take } from 'rxjs';
import { throwError } from 'rxjs/internal/observable/throwError';

@Injectable()
export class ClusterFacade {
  constructor(private _clusterService: ClusterService) {}

  createCluster$(data: Partial<ICluster>): Observable<ICluster> {
    const formData = generateFormData(data);

    return this._clusterService.createCluster$(formData).pipe(
      take(1),
      catchError((error): ObservableInput<ICluster> => throwError(() => error))
    );
  }

  updateCluster$(uuid: string, data: Partial<ICluster>): Observable<ICluster> {
    const formData = generateFormData(data);

    return this._clusterService.updateCluster$(uuid, formData).pipe(
      take(1),
      catchError((error): ObservableInput<ICluster> => throwError(() => error))
    );
  }

  deleteCluster$(uuid: string): Observable<IDeleteCluster> {
    return this._clusterService.deleteCluster$(uuid).pipe(
      take(1),
      catchError(({ error }: IDeleteCluster): ObservableInput<IDeleteCluster> => throwError(() => error))
    );
  }
  getCluster$(uuid: string): Observable<ICluster> {
    return this._clusterService.getCluster$(uuid).pipe(
      take(1),
      catchError((err): ObservableInput<ICluster> => throwError(() => err.error))
    );
  }
}
