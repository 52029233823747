import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedModule } from '../../../shared/shared.module';
import { ProductInfoComponent } from './product-info/product-info.component';
import { BrandInfoComponent } from './brand-info/brand-info.component';
import mapboxgl from 'mapbox-gl';
import * as geojson from 'geojson';
import { PublicationFacade } from '@app/core/facade/publication.facade';
import {
  IBatchPreview,
  IBrandPreview,
  IPreviewPublication,
  IProductPreview,
  Step,
  Type,
} from '@app/core/interface/preview_publicaton.interface';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-publication-preview',
  standalone: true,
  imports: [SharedModule, ProductInfoComponent, BrandInfoComponent],
  templateUrl: './publication-preview.component.html',
  styleUrl: './publication-preview.component.scss',
})
export class PublicationPreviewComponent implements OnInit, OnDestroy {
  readonly #dialog = inject(MatDialog);

  readonly #route = inject(ActivatedRoute);
  readonly _publicationFacade = inject(PublicationFacade);
  readonly #router = inject(Router);

  map?: mapboxgl.Map;
  center: mapboxgl.LngLat = new mapboxgl.LngLat(5.1297097, 45.9068105);
  // Create a popup, but don't add it to the map yet.
  popup = new mapboxgl.Popup({
    anchor: 'bottom-left',
    offset: 30,
    closeButton: false,
    closeOnClick: false,
    focusAfterOpen: true,
    closeOnMove: true,
  });
  mapConfig = {
    accessToken: 'pk.eyJ1IjoicHJvZHVjdGRuYSIsImEiOiJja2huaWpjMWIwMjFyMzBwZW1wbnU4MzRuIn0.OC1GTcD3Qj3b70yKiv1l6w',
    style: 'mapbox://styles/productdna/ckvmjtbqg109s14p83e0l5vgr',
  };
  product?: IProductPreview;
  brand?: IBrandPreview;
  batches?: IBatchPreview;
  types?: Type[];
  previewPublication?: IPreviewPublication;
  reference?: string;
  productId?: string;
  layers?: number[];

  productChainUuid?: string | null;

  ngOnInit(): void {
    this.initializeMap();
    this.productChainUuid = this.#route.snapshot.paramMap.get('id');

    this.getPublicationData();

    // Get the selected position of the types and filter the pins if position is selected.
    this._publicationFacade.expandedType.subscribe(position => {
      // if no type is selected.
      if (position === -1 && this.types) {
        this.handlePins(this.types);
        this.removeGreyLayers();
      } else {
        const filteredPins = this.types?.filter(el => el.position === position);
        if (filteredPins) {
          // Center the map to the filtered steps.
          const newPins = filteredPins[0].steps.filter(el => el.manufactories?.latitude && el.manufactories.longitude);

          if (newPins.length > 0) {
            const newCenter = new mapboxgl.LngLat(
              newPins[0].manufactories!.longitude!,
              newPins[0].manufactories!.latitude!
            );
            if (newCenter) {
              this.map?.setZoom(3);
              this.map?.flyTo({ center: newCenter });
            }
          }

          if (this.types) {
            this.handleUnselectedPins(this.types, position);
          }

          // Handle the pins.
          this.handlePins(filteredPins);
        }
      }
    });

    // Set the map on step selected.
    this._publicationFacade.onStepSelected.subscribe(data => {
      if (data.step.manufactories && data.step.manufactories.latitude && data.step.manufactories.longitude)
        this.center = new mapboxgl.LngLat(data.step.manufactories.longitude, data.step.manufactories.latitude);
      this.map?.setCenter(this.center);
      this.map?.flyTo({ center: this.center });
      this.map?.setZoom(10);
      this.handlePins([data.type]);
    });
  }

  private handlePins(types: Type[]) {
    const pinsOnMap: geojson.FeatureCollection<geojson.Point> = {
      type: 'FeatureCollection',
      features: [],
    };

    types?.forEach((type: Type) => {
      let pinOnMap: geojson.Feature<geojson.Point, geojson.GeoJsonProperties>;
      type.steps.forEach((step: Step, index: number) => {
        // If the step is a group.
        if (step.steps && step.steps.length >= 0) {
          step.steps.forEach(stepOfGroup => {
            if (stepOfGroup.manufactories && stepOfGroup.manufactories.is_hidden != true) {
              pinOnMap = {
                id: index,
                type: 'Feature',
                geometry: {
                  type: 'Point',
                  // eslint-disable-next-line
                  coordinates: [stepOfGroup.manufactories?.longitude!, stepOfGroup.manufactories?.latitude!],
                },
                properties: {
                  position: type.position,
                  color: type.color,
                  name: type.name,
                  lng: stepOfGroup.manufactories?.longitude,
                  lat: stepOfGroup.manufactories?.latitude,
                },
              };
            } else if (stepOfGroup.supplier?.is_hidden) {
              pinOnMap = {
                id: index,
                type: 'Feature',
                geometry: {
                  type: 'Point',
                  coordinates: [stepOfGroup.supplier.longitude!, stepOfGroup.supplier.latitude!],
                },
                properties: {
                  position: type.position,
                  color: type.color,
                  name: type.name,
                  lng: stepOfGroup.supplier.longitude,
                  lat: stepOfGroup.supplier?.latitude,
                },
              };
            }
            pinsOnMap.features.push(pinOnMap);
          });
        } else if (step.manufactories && step.manufactories.is_hidden != true) {
          pinOnMap = {
            id: index,
            type: 'Feature',
            geometry: {
              type: 'Point',
              // eslint-disable-next-line
              coordinates: [step.manufactories?.longitude!, step.manufactories?.latitude!],
            },
            properties: {
              position: type.position,
              color: type.color,
              name: type.name,
              lng: step.manufactories?.longitude,
              lat: step.manufactories?.latitude,
            },
          };
          pinsOnMap.features.push(pinOnMap);
        } else if (step.supplier?.is_hidden) {
          pinOnMap = {
            id: index,
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates: [step.supplier.longitude!, step.supplier.latitude!],
            },
            properties: {
              position: type.position,
              color: type.color,
              name: type.name,
              lng: step.supplier.longitude,
              lat: step.supplier?.latitude,
            },
          };
          pinsOnMap.features.push(pinOnMap);
        }
      });
    });

    this.renderPins(pinsOnMap);
  }

  private renderPins(pins: geojson.FeatureCollection<geojson.Point>): void {
    if (this.map) {
      // Remove the previous layers and pins.
      this.layers?.map(item => {
        if (this.map?.getLayer(`pin${item}`)) {
          this.map?.removeLayer(`pin${item}`);
          //   // this.map.removeLayer('cluster');
          this.map?.removeSource(`pin${item}`);
        }
      });

      // Return if there is no Pins.
      if (pins.features.length == 0) {
        return;
      }

      // set to true if all pins in the step are the same.
      let haveSamePins = false;
      if (pins) {
        haveSamePins = pins.features.every(
          value =>
            value.geometry.coordinates[0] == pins.features[0].geometry.coordinates[0] &&
            value.geometry.coordinates[1] == pins.features[0].geometry.coordinates[1]
        );
      }

      // If the pins have more then 1 pin or the pins have different position set fitBounds.
      if (pins.features.length > 1 && !haveSamePins && window.innerWidth > 920) {
        this.setBounds(pins);
      } else if (pins.features.length == 1 || haveSamePins) {
        // If there is one pin or the step have same coordinates.
        this.center = new mapboxgl.LngLat(
          pins.features[0].geometry.coordinates[0],
          pins.features[0].geometry.coordinates[1]
        );
        this.map?.setCenter(this.center);
        this.map?.flyTo({ center: this.center });

        // Set zoom to 7 if we have clicked on the classification with only one address or all the addresses are the same.
        // If the click is on the step then we have zoom 10 and did not want to change.
        if (this.map?.getZoom() != 10) {
          this.map?.setZoom(7);
        }
      }

      const pinFeatures = pins;

      // Duplicates into clusters.
      const uniquePins = pinFeatures.features.filter((obj, index, arr) => {
        // Find the index of the first occurrence of the object in the array
        const firstIndex = arr.findIndex(
          item =>
            item.geometry.coordinates[0] === obj.geometry.coordinates[0] &&
            item.geometry.coordinates[1] === obj.geometry.coordinates[1]
        );

        // Include the object in the resulting array only if the current index is the first occurrence
        return index === firstIndex;
      });
      pinFeatures.features = uniquePins;

      // Load the image for the pin.
      this.map.loadImage('assets/images/map-pin.png', (error, image) => {
        if (error) throw error;
        if (!this.map?.hasImage('map-pin')) {
          this.map?.addImage('map-pin', image as HTMLImageElement, {
            sdf: true,
          });
        }

        const layersArr: number[] = [];

        // Make pins with the smaller position to be on the top of the layers.
        pinFeatures.features.sort((a, b) => {
          return b.properties!['position'] - a.properties!['position'];
        });

        // Add Layer and Add Source inside the LoadImage function because it is async.
        pinFeatures.features.map((item, index) => {
          layersArr.push(index);

          // Add Source for every pin.
          this.map?.addSource(`pin${index}`, {
            type: 'geojson',
            data: {
              type: 'FeatureCollection',
              features: [item],
            },
            cluster: false,
            clusterRadius: 30,
          });

          // Add layer for every pin.
          this.map?.addLayer({
            id: `pin${index}`,
            type: 'symbol',
            filter: ['!=', 'cluster', true],
            source: `pin${index}`,
            layout: {
              'icon-anchor': 'bottom',
              'icon-image': 'map-pin',
              'icon-size': 1,
              'text-field': '{position}',
              'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
              'text-size': 16,
              'text-offset': [0, -1.45],
              'icon-allow-overlap': true,
              'text-allow-overlap': true,
              // 'text-justify': 'auto',
              // 'text-optional': true,
              'text-ignore-placement': true,
            },
            paint: {
              'text-color': '#ffffff',
              'icon-color': ['get', 'color'],
            },
          });
        });

        this.layers = layersArr;

        this.layers?.map(item => {
          this.map?.on('mouseenter', `pin${item}`, e => {
            this.map!.getCanvas().style.cursor = 'pointer';

            setTimeout(() => {
              const pin = e.features?.slice();
              if (pin) {
                const lng = pin[0].properties ? pin[0].properties['lng'] : '';
                const lat = pin[0].properties ? pin[0].properties['lat'] : '';
                const coordinates = { lng, lat };
                const title = pin[0].properties ? pin[0].properties['name'] : '';

                if (coordinates.lng && coordinates.lat) {
                  this.popup
                    .setLngLat(coordinates)
                    .setHTML(`<div class="popup-title">${title}</div>`)
                    .addTo(this.map as mapboxgl.Map);
                }
              }
            }, 500);
          });

          this.map?.on('mouseleave', `pin${item}`, () => {
            this.map!.getCanvas().style.cursor = '';
            this.popup.remove();
          });
        });
      });

      // TEMPORARY REMOVE THE CLUSTERS
      // this.map.loadImage('assets/images/circle.png', (error, image) => {
      //   if (error) throw error;
      //   if (!this.map?.hasImage('map-cluster'))
      //     this.map?.addImage('map-cluster', image as HTMLImageElement, {
      //       sdf: true,
      //     });
      // });

      // TEMPORARY REMOVE THE CLUSTERS
      // if (!this.map.getLayer('cluster')) {
      //   this.map?.addLayer({
      //     id: 'cluster',
      //     type: 'symbol',
      //     filter: ['==', 'cluster', true],
      //     source: 'pins',
      //     layout: {
      //       'icon-anchor': 'bottom',
      //       'icon-image': 'map-cluster',
      //       'icon-size': 1,
      //       'text-field': '{point_count}',
      //       'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
      //       'text-size': 16,
      //       'text-line-height': 22,
      //       'text-offset': [0, -1],
      //       'icon-allow-overlap': true,
      //       'text-allow-overlap': true,
      //       'text-justify': 'center',
      //     },
      //     paint: {
      //       'text-color': '#ffffff',
      //       'icon-color': ['get', 'color'],
      //     },
      //   });
      // }
    }
  }

  // Create steps from groups and do sort on steps.
  private handleGroups(types: Type[]) {
    types.forEach(type => {
      if (type.groups?.length > 0) {
        type.groups?.forEach((group: Step) => {
          type.steps?.push(group);
          type.steps?.sort((a, b) => {
            return a.position! - b.position!;
          });
        });
      }
    });
  }

  private setBounds(pins: geojson.FeatureCollection<geojson.Point>): void {
    let minLat = Number.MAX_VALUE;
    let maxLat = Number.MIN_VALUE;
    let minLng = Number.MAX_VALUE;
    let maxLng = Number.MIN_VALUE;

    for (const marker of pins.features) {
      const lat = marker.geometry.coordinates[0];
      const lng = marker.geometry.coordinates[1];

      minLat = Math.min(minLat, lat);
      maxLat = Math.max(maxLat, lat);
      minLng = Math.min(minLng, lng);
      maxLng = Math.max(maxLng, lng);
    }

    if (maxLat && maxLng && minLat && minLng) {
      const bounds = new mapboxgl.LngLatBounds(
        new mapboxgl.LngLat(maxLat, maxLng),
        new mapboxgl.LngLat(minLat, minLng)
      );

      this.map?.fitBounds(bounds, {
        padding: 400, // optional padding around the bounds (in pixels)
      });
    }
  }

  private handleUnselectedPins(types: Type[], position: number) {
    const pinsOnMap: geojson.FeatureCollection<geojson.Point> = {
      type: 'FeatureCollection',
      features: [],
    };

    const filteredTypes = types.filter(el => el.position != position);

    filteredTypes?.forEach((type: Type) => {
      let pinOnMap: geojson.Feature<geojson.Point, geojson.GeoJsonProperties>;
      type.steps.forEach((step: Step, index: number) => {
        // If the step is a group.
        if (step.steps && step.steps.length >= 0) {
          step.steps.forEach(stepOfGroup => {
            if (stepOfGroup.manufactories && stepOfGroup.manufactories.is_hidden != true) {
              pinOnMap = {
                id: index,
                type: 'Feature',
                geometry: {
                  type: 'Point',
                  // eslint-disable-next-line
                  coordinates: [stepOfGroup.manufactories?.longitude!, stepOfGroup.manufactories?.latitude!],
                },
                properties: {
                  position: type.position,
                  color: type.color,
                  name: type.name,
                  lng: stepOfGroup.manufactories?.longitude,
                  lat: stepOfGroup.manufactories?.latitude,
                },
              };
            } else if (stepOfGroup.supplier?.is_hidden) {
              pinOnMap = {
                id: index,
                type: 'Feature',
                geometry: {
                  type: 'Point',
                  coordinates: [stepOfGroup.supplier.longitude!, stepOfGroup.supplier.latitude!],
                },
                properties: {
                  position: type.position,
                  color: type.color,
                  name: type.name,
                  lng: stepOfGroup.supplier.longitude,
                  lat: stepOfGroup.supplier?.latitude,
                },
              };
            }
            pinsOnMap.features.push(pinOnMap);
          });
        } else if (step.manufactories && step.manufactories.is_hidden != true) {
          pinOnMap = {
            id: index,
            type: 'Feature',
            geometry: {
              type: 'Point',
              // eslint-disable-next-line
              coordinates: [step.manufactories?.longitude!, step.manufactories?.latitude!],
            },
            properties: {
              position: type.position,
              color: type.color,
              name: type.name,
              lng: step.manufactories?.longitude,
              lat: step.manufactories?.latitude,
            },
          };
          pinsOnMap.features.push(pinOnMap);
        } else if (step.supplier?.is_hidden) {
          pinOnMap = {
            id: index,
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates: [step.supplier.longitude!, step.supplier.latitude!],
            },
            properties: {
              position: type.position,
              color: type.color,
              name: type.name,
              lng: step.supplier.longitude,
              lat: step.supplier?.latitude,
            },
          };
          pinsOnMap.features.push(pinOnMap);
        }
      });
    });

    this.renderUnSelectedPins(pinsOnMap);
  }

  private renderUnSelectedPins(pins: geojson.FeatureCollection<geojson.Point>): void {
    if (this.map) {
      // Remove the previous layers and pins.
      if (this.map.getLayer('grey-pins')) {
        this.map.removeLayer('grey-pins');
        // this.map.removeLayer('grey-cluster');
        this.map.removeSource('grey-pins');
      }

      const pinFeatures = pins;

      // Load the image for the pin.
      this.map.loadImage('assets/images/map-pin.png', (error, image) => {
        if (error) throw error;
        if (!this.map?.hasImage('map-pin'))
          this.map?.addImage('map-pin', image as HTMLImageElement, {
            sdf: true,
          });
      });

      // TEMPORARY REMOVE THE CLUSTERS
      // this.map.loadImage('assets/images/circle.png', (error, image) => {
      //   if (error) throw error;
      //   if (!this.map?.hasImage('map-cluster'))
      //     this.map?.addImage('map-cluster', image as HTMLImageElement, {
      //       sdf: true,
      //     });
      // });

      // Add Source
      if (!this.map.getSource('grey-pins')) {
        this.map.addSource('grey-pins', {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: pinFeatures.features,
          },
          cluster: false,
          clusterRadius: 30,
        });
      }

      // Add layers with the pins
      if (!this.map.getLayer('grey-pins')) {
        this.map?.addLayer({
          id: 'grey-pins',
          type: 'symbol',
          filter: ['!=', 'cluster', true],
          source: 'grey-pins',
          layout: {
            'icon-anchor': 'bottom',
            'icon-image': 'map-pin',
            'icon-size': 0.8,
            'text-field': '{position}',
            'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
            'text-size': 16,
            'text-line-height': 22,
            'text-offset': [0, -1.2],
            'icon-allow-overlap': true,
            'text-allow-overlap': true,
            'text-justify': 'auto',
          },
          paint: {
            'text-color': '#ffffff',
            'icon-color': '#B2BEB5',
          },
        });
      }

      // TEMPORARY REMOVE THE CLUSTERS
      // if (!this.map.getLayer('grey-cluster')) {
      //   this.map?.addLayer({
      //     id: 'grey-cluster',
      //     type: 'symbol',
      //     filter: ['==', 'cluster', true],
      //     source: 'grey-pins',
      //     layout: {
      //       'icon-anchor': 'bottom',
      //       'icon-image': 'map-cluster',
      //       'icon-size': 1,
      //       'text-field': '{point_count}',
      //       'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
      //       'text-size': 16,
      //       'text-line-height': 22,
      //       'text-offset': [0, -1],
      //       'icon-allow-overlap': false,
      //       'text-allow-overlap': false,
      //       'text-justify': 'center',
      //     },
      //     paint: {
      //       'text-color': '#ffffff',
      //       'icon-color': ['get', 'color'],
      //     },
      //   });
      // }

      this.map.on('mouseenter', 'grey-pins', e => {
        this.map!.getCanvas().style.cursor = 'pointer';

        setTimeout(() => {
          const pin = e.features?.slice();
          if (pin) {
            const lng = pin[0].properties ? pin[0].properties['lng'] : '';
            const lat = pin[0].properties ? pin[0].properties['lat'] : '';
            const coordinates = { lng, lat };
            const title = pin[0].properties ? pin[0].properties['name'] : '';

            if (coordinates.lat && coordinates.lng) {
              this.popup
                .setLngLat(coordinates)
                .setHTML(`<div class="popup-title">${title}</div>`)
                .addTo(this.map as mapboxgl.Map);
            }
          }
        }, 500);
      });

      this.map.on('mouseleave', 'grey-pins', () => {
        this.map!.getCanvas().style.cursor = '';
        this.popup.remove();
      });
    }
  }

  private removeGreyLayers() {
    if (this.map?.getLayer('grey-pins')) {
      this.map.removeLayer('grey-pins');
      // this.map.removeLayer('grey-cluster');
      this.map.removeSource('grey-pins');
    }
  }

  // Initialize the Map.
  private initializeMap(): void {
    this.map = new mapboxgl.Map({
      accessToken: this.mapConfig.accessToken,
      container: 'map',
      style: this.mapConfig.style,
      minZoom: 2,
      maxZoom: 12,
      zoom: 3,
      center: this.center,
      doubleClickZoom: true,
      scrollZoom: true,
      dragPan: true,
    });
    this.map.addControl(new mapboxgl.NavigationControl());

    this.map.on('load', () => {
      this.map!.setLayoutProperty('country-label', 'text-field', ['get', `name_en`]);
    });
  }

  getPublicationData() {
    this._publicationFacade.getPreviewPublication$(this.productChainUuid!).subscribe(res => {
      this.previewPublication = res;

      this.types = res.types;
      this.reference = res.reference;
      this.product = res.product;
      this.brand = res.brand;

      // eslint-disable-next-line
      this.handleGroups(this.previewPublication?.types!);
      // eslint-disable-next-line
      this.handlePins(this.previewPublication?.types!);
    });
  }

  goBack(): void {
    this.#router.navigate([`/publish/${this.productChainUuid}`], {
      queryParams: { product: this.#route.snapshot.queryParamMap.get('product') },
    });
  }

  ngOnDestroy(): void {
    this.#dialog.closeAll();
  }
}
