<div class="holder d-flex flex-column">
  <div class="wrapper">
    <h2 mat-dialog-title>
      {{ title }}
    </h2>
    <mat-dialog-content class="d-flex align-items-start flex-column">
      <form [formGroup]="form" class="d-flex">
        <mat-form-field class="input-primary" appearance="outline">
          <mat-label>{{ 'Enter Unit Symbol' | translate }}</mat-label>
          <input
            formControlName="symbol"
            (keyup)="checkIsExistingUnitName($event)"
            matInput
            placeholder="{{ 'Enter Unit Symbol *' | translate }}"
          />
          <mat-error *ngIf="form.get('symbol')?.hasError('valueExists')">
            {{ 'This unit symbol already exists. \n Please specify another name.' | translate }}</mat-error
          >
        </mat-form-field>
        <mat-form-field class="input-primary" appearance="outline">
          <mat-label>{{ 'Enter Unit Name' | translate }}</mat-label>
          <input formControlName="name" matInput placeholder="{{ 'Enter Unit Name' | translate }}" />
        </mat-form-field>
      </form>
      <div
        *ngIf="!is_editing"
        (mouseover)="mouseEvHandler(true)"
        (mouseout)="mouseEvHandler(false)"
        class="help-holder d-flex align-items-center justify-content-center"
      >
        <div class="logo"></div>
        <p class="m-0">{{ 'need help?' }}</p>
        <div class="tooltip" *ngIf="needHelpTooltip">
          <p>
            {{
              "You can precise an abbreviation as the symbol of the unit you want to manage here, and as name the unit's complete name."
                | translate
            }}
          </p>
          <p class="example-text">
            {{ 'Example, to create "kilogram" unit, you can put "Kg" in symbol and "Kilogram" in name' | translate }}
          </p>
        </div>
      </div>
    </mat-dialog-content>
  </div>

  <mat-dialog-actions align="end">
    <app-rounded-button mat-dialog-close color="tetriary-link" [size]="'md'" [button_text]="'Cancel'">
    </app-rounded-button>
    <app-rounded-button
      (click)="createQuantityUnit()"
      [color]="is_editing ? 'turquoise' : 'primary'"
      [button_text]="button_text"
      [size]="'md'"
      [icon_category]="'essential'"
      [icon_name]="'unit'"
      [icon_right]="true"
      [disabled]="form && (!form.valid || form.pristine)"
    >
    </app-rounded-button>
  </mat-dialog-actions>
</div>
