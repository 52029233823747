<div [formGroup]="form" class="wraper">
  <div class="row">
    <div class="col-6">
      <h3>{{ 'Step Details' | translate }}</h3>
      <h5>{{ 'Step Name' | translate }}</h5>
      <mat-form-field class="input-primary" appearance="outline">
        <mat-label>{{ 'Step Name' | translate }}</mat-label>
        <input matInput formControlName="name" placeholder="{{ 'Enter Name' | translate }}" required />
      </mat-form-field>

      <h5>{{ 'Step Description' | translate }}</h5>
      <div class="input-primary text-area textarea" appearance="outline">
        <mat-label>{{ 'Step Description' | translate }}</mat-label>
        <div class="wrp">
          <app-rs-rte
            formControlName="description"
            [c]="$any(form.controls['description'])"
            [placeholder]="'Step Description' | translate"
          ></app-rs-rte>
        </div>
      </div>
    </div>
    <div class="col-6" *ngIf="step">
      <h3>{{ 'Step Documents' | translate }}</h3>
      <app-step-media-accordion
        [canUpload]="true"
        [canDelete]="true"
        [photos]="photos"
        [videos]="videos"
        [documents]="step.documents"
        [typeUuid]="step?.uuid"
        [type]="'steps'"
        (refreshStep)="refreshStep.emit(true)"
      ></app-step-media-accordion>
    </div>
  </div>
</div>
