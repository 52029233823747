<div class="dialog-wrapper d-flex flex-column">
  <h3 class="dialog-title m-0">
    <strong>{{
      data.dir_supplier
        ? ('supply-chain-step.Edit Direct Supplier' | translate)
        : ('supply-chain-step.Add direct supplier' | translate)
    }}</strong>
  </h3>
  <div class="horizontal-line"></div>
  <mat-dialog-content class="d-flex flex-column flex-grow-1 m-0">
    <form class="d-flex flex-wrap" [formGroup]="form">
      <div class="field-wrapper">
        <div class="field-title">
          <span>{{ 'supply-chain-step.Supplier name' | translate }}</span>
          <app-custom-icon
            icon_category="essential"
            icon_name="info-circle"
            [matTooltip]="'supply-chain-step.Please enter the full name of your supplier' | translate"
            matTooltipPosition="above"
            matTooltipClass="custom-tooltip-center"
          >
          </app-custom-icon>
        </div>
        <mat-form-field class="input-primary" appearance="outline">
          <mat-label>{{ 'supply-chain-step.Enter supplier name' | translate }}</mat-label>
          <input matInput formControlName="name" />
        </mat-form-field>
      </div>
      <div class="field-wrapper">
        <div class="field-title supplier-role-title">
          <span>{{ 'supply-chain-step.Supplier role in the chain' | translate }}</span>
          <span #supplierRoleInfoIcon>
            <app-custom-icon
              icon_category="essential"
              icon_name="info-circle"
              (mouseenter)="onHoverSupplierRoleInfo(true)"
              (mouseleave)="onHoverSupplierRoleInfo(false)"
            ></app-custom-icon>
          </span>
          <div
            *ngIf="showSupplierRoleTooltip"
            class="supplier-role-tooltip"
            [ngStyle]="{ left: supplierRoleTooltipXY.x, top: supplierRoleTooltipXY.y }"
          >
            {{ 'supply-chain-step.Please enter the role of your supplier' | translate }}<br />{{
              'supply-chain-step.in the supply chain' | translate
            }},<br />{{ 'supply-chain-step.Examples' | translate }}:
            <strong
              >{{ 'supply-chain-step.Delivery of ..., Assembly' | translate }}<br />{{
                'supply-chain-step.of ..., Manufacturing of ...' | translate
              }},<br />{{ 'supply-chain-step.Extraction of ...' | translate }}</strong
            >
          </div>
        </div>
        <mat-form-field class="input-primary" appearance="outline">
          <mat-label>{{ 'supply-chain-step.Enter supplier role' | translate }}</mat-label>
          <input matInput formControlName="role" />
        </mat-form-field>
      </div>
      <div class="field-wrapper">
        <div class="field-title">
          <span>{{ 'supply-chain-step.Supplier contact e-mail' | translate }}</span>
          <app-custom-icon
            icon_category="essential"
            icon_name="info-circle"
            [matTooltip]="
              ('supply-chain-step.Please enter the email address of your contact person at the supplier. Please make sure that you got his consent before entering it'
                | translate) + '.'
            "
            matTooltipPosition="above"
            matTooltipClass="custom-tooltip-center"
          >
          </app-custom-icon>
        </div>
        <mat-form-field class="input-primary" appearance="outline">
          <mat-label>{{ 'supply-chain-step.Supplier contact e-mail' | translate }}</mat-label>
          <input matInput formControlName="email" />
        </mat-form-field>
      </div>
      <div class="field-wrapper">
        <div class="field-title">
          <span>{{ 'supply-chain-step.Supplier contact language' | translate }}</span>
          <app-custom-icon
            icon_category="essential"
            icon_name="info-circle"
            [matTooltip]="(contactLanguageTooltip | translate) + '.'"
            matTooltipPosition="above"
            matTooltipClass="custom-tooltip-center"
          >
          </app-custom-icon>
        </div>
        <mat-form-field class="input-primary" appearance="outline">
          <mat-select
            formControlName="language_prefered"
            panelClass="dropdown-primary"
            [placeholder]="'supply-chain-step.Choose supplier contact language' | translate"
          >
            <mat-option *ngFor="let lang of languages" [value]="lang.uuid">
              {{ 'supply-chain-step.' + lang.name | translate }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions [align]="'end'">
    <app-rounded-button
      [color]="'tetriary-link'"
      [size]="'sm'"
      [button_text]="'supply-chain-step.Cancel' | translate"
      class="cancel"
      (click)="dialogRef.close()"
    >
    </app-rounded-button>
    <app-rounded-button
      class="add-supplier"
      size="md"
      color="primary"
      button_text="{{
        data.dir_supplier
          ? ('supply-chain-step.Edit Direct Supplier' | translate)
          : ('supply-chain-step.Add direct supplier' | translate)
      }}"
      [disabled]="form.invalid || form.pristine"
      (click)="onAddDirectSupplier()"
    >
    </app-rounded-button>
  </mat-dialog-actions>
</div>
