import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { ProjectFormComponent } from './project-form/project-form.component';
import { ProjectsRoutingModule } from './projects-routing.module';
import { SharedModule } from '@app/shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ProjectsFacade } from '@app/core/facade/projects.facade';
import { ProjectComponent } from './project/project.component';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SubProjectTableComponent } from './project-form/sub-project-table/sub-project-table.component';
import { ProjectDetailsDialogComponent } from './project-details-dialog/project-details-dialog.component';
import { UnassingProjectDialogComponent } from './unassing-project-dialog/unassing-project-dialog.component';

import { DateFormat } from './date-format';
import { DateAdapter } from '@angular/material/core';

@NgModule({
  declarations: [
    ProjectFormComponent,
    ProjectComponent,
    SubProjectTableComponent,
    ProjectDetailsDialogComponent,
    UnassingProjectDialogComponent,
  ],
  imports: [CommonModule, ProjectsRoutingModule, SharedModule, ReactiveFormsModule, FormsModule],
  providers: [
    ProjectsFacade,
    DatePipe,
    { provide: MAT_DIALOG_DATA, useValue: {} },
    { provide: MatDialogRef, useValue: {} },
    { provide: DateAdapter, useClass: DateFormat },
  ],
})
export class ProjectsModule {}
