import { HttpClient, HttpParams } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from '@env/environment';
import {
  ICertificateCategoriesResponse,
  ICertificateDomainResponse,
  ICertificateResponse,
  ICertificatesResponse,
  ICertificationResponse,
  ICertificatsContentType,
  IContentTypeURL,
  IDeleteCertificate,
  IDeleteCertification,
} from '../interface/certificates.interface';
import { ICertificationSupplier } from '../interface/suppliers.interface';

@Injectable({
  providedIn: 'root',
})
export class CertificatesService {
  private apiUrl = environment.api;

  constructor(private _http: HttpClient) {}

  getCertificates$(ordering?: string, limit?: number): Observable<ICertificatesResponse> {
    if (ordering && limit) {
      const params = new HttpParams().set('ordering', ordering).set('limit', limit);
      return this._http.get<ICertificatesResponse>(`${this.apiUrl}/certificates/`, { params: params });
    }
    return this._http.get<ICertificatesResponse>(`${this.apiUrl}/certificates/`);
  }
  getCertifications$(ordering?: string, limit?: number): Observable<ICertificationResponse> {
    if (ordering && limit) {
      const params = new HttpParams().set('ordering', ordering).set('limit', limit);
      return this._http.get<ICertificationResponse>(`${this.apiUrl}/certifications/`, { params: params });
    }
    return this._http.get<ICertificationResponse>(`${this.apiUrl}/certifications/`);
  }
  getFilteredCertificates$(content_type: ICertificatsContentType): Observable<ICertificatesResponse> {
    return this._http.get<ICertificatesResponse>(`${this.apiUrl}/certificates?content_type=${content_type}`);
  }
  getCertificate$(uuid: string): Observable<ICertificateResponse> {
    return this._http.get<ICertificateResponse>(`${this.apiUrl}/certificates/${uuid}/`);
  }
  createCertificate$(formData: FormData): Observable<ICertificateResponse> {
    return this._http.post<ICertificateResponse>(`${this.apiUrl}/certificates/`, formData);
  }
  getCertificatesDomains$(): Observable<ICertificateDomainResponse> {
    return this._http.get<ICertificateDomainResponse>(`${this.apiUrl}/certificates/domains/`);
  }
  getCertificatesCategories$(): Observable<ICertificateCategoriesResponse> {
    return this._http.get<ICertificateCategoriesResponse>(`${this.apiUrl}/certificates/categories/`);
  }
  updateCertificate$(uuid: string, formData: FormData): Observable<ICertificateResponse> {
    return this._http.patch<ICertificateResponse>(`${this.apiUrl}/certificates/${uuid}/`, formData);
  }

  ////delete any certificate from  certificates in settings
  deleteCertificate$(uuid: string): Observable<IDeleteCertificate> {
    return this._http.delete<IDeleteCertificate>(`${this.apiUrl}/certificates/${uuid}/`);
  }

  ////delete any certificate from  certificates in dashboard
  deleteCertifications$(uuid: string): Observable<IDeleteCertificate> {
    return this._http.delete<IDeleteCertificate>(`${this.apiUrl}/certifications/${uuid}/`);
  }

  createCertification$(
    formData: FormData,
    content_type: IContentTypeURL,
    uuid: string
  ): Observable<ICertificationResponse> {
    return this._http.post<ICertificationResponse>(`${this.apiUrl}/${content_type}/${uuid}/certificates/`, formData);
  }
  // For creating certificate of content_type 'brands'
  createBrandCertification$(formData: FormData): Observable<ICertificationResponse> {
    return this._http.post<ICertificationResponse>(`${this.apiUrl}/brands/certificates/`, formData);
  }
  updateCertification$(
    formData: FormData,
    content_type: IContentTypeURL,
    uuid: string,
    certificationUuid: string
  ): Observable<ICertificationResponse> {
    return this._http.put<ICertificationResponse>(
      `${this.apiUrl}/${content_type}/${uuid}/certificates/${certificationUuid}/`,
      formData
    );
  }
  // For updating certificate of content_type 'brands'
  updateBrandCertification$(formData: FormData, certificationUuid: string): Observable<ICertificationResponse> {
    return this._http.put<ICertificationResponse>(`${this.apiUrl}/brands/certificates/${certificationUuid}/`, formData);
  }

  deleteCertification$(supplier_uuid: string, uuid: string): Observable<IDeleteCertification> {
    return this._http.delete<IDeleteCertification>(`${this.apiUrl}/suppliers/${supplier_uuid}/certificates/${uuid}`);
  }

  getSupplierCertificate$(certificate_uuid: string): Observable<ICertificationSupplier> {
    return this._http.get<ICertificationSupplier>(`${this.apiUrl}/suppliers/certificates/${certificate_uuid}`);
  }
}
