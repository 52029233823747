<div class="digital-sheet-header">
  <div class="product-wrap d-flex align-items-center col-8">
    <h5 class="m-0 titl">{{ 'Creating Digital Product Sheet' | translate }}</h5>
    <div class="product d-flex align-items-center">
      <img [src]="product?.image ?? '../../../../../assets/images/image-placeholder.jpg'" alt="" />
      <p class="m-0">{{ product?.name }}</p>
    </div>
  </div>
  <div class="col-4">
    <div class="head">
      <app-rounded-button
        color="secondary-outline"
        class="back-to-reg"
        [button_text]="'Back to regulations'"
        [icon_category]="'arrows'"
        [icon_name]="'arrow-left-long'"
        [size]="'md'"
        [icon_left]="true"
        (click)="close()"
      >
      </app-rounded-button>
      <div class="icon">
        <app-custom-icon
          [icon_category]="'weather'"
          [icon_name]="false ? 'saving' : 'saved-changes'"
          [matTooltip]="'All changes saved'"
          matTooltipClass="custom-tooltip-end mt-20"
          [matTooltipPosition]="'below'"
        >
        </app-custom-icon>
      </div>
    </div>
  </div>
</div>
<div class="col-12 head cont-wrp">
  <hr />
  <div class="step-holder">
    <mat-stepper
      [orientation]="'horizontal'"
      class="col-12 sheet-steps"
      [disableRipple]="true"
      labelPosition="bottom"
      [linear]="false"
      [selectedIndex]="selectedStepIndex"
      #sheetSteper
    >
      <!-- change default 'edit' icon -->
      <ng-template matStepperIcon="edit">
        <mat-icon>checked</mat-icon>
      </ng-template>
      <mat-step [editable]="true" [completed]="selectedStepIndex > 0">
        <ng-template matStepperIcon let-index="index" let-step="step">
          <!-- <mat-icon *ngIf="!step.completed">
            {{ index + 1 }}
          </mat-icon>
          <mat-icon *ngIf="step.completed"> check_circle </mat-icon> -->
        </ng-template>
        <ng-template matStepLabel>{{ 'Type of product' | translate }}</ng-template>
        <app-product-type></app-product-type>
      </mat-step>
      <mat-step [editable]="true" [completed]="selectedStepIndex > 1">
        <ng-template matStepLabel>{{ 'Traceability' | translate }}</ng-template>
        <div class="row step-cont cont-2">
          <app-tracebility-steps
            [shoes]="productTypeComponent?.selectedType?.name === 'Shoes'"
            [stepDetails]="sheet?.traceability_steps"
          ></app-tracebility-steps>
        </div>
      </mat-step>
      <mat-step [editable]="true" [completed]="selectedStepIndex > 2">
        <ng-template matStepLabel>{{ 'Recycled Materials Used' | translate }}</ng-template>
        <div class="row step-cont cont-3">
          <app-recycled-materials></app-recycled-materials>
        </div>
      </mat-step>
      <mat-step [editable]="true" [completed]="selectedStepIndex > 3">
        <ng-template matStepLabel>{{
          'Presence of plastic microfibers & hazardous substances' | translate
        }}</ng-template>
        <div class="row step-cont cont-4">
          <app-presence-plastic-microfiber></app-presence-plastic-microfiber>
        </div>
      </mat-step>
      <mat-step [editable]="true" [completed]="selectedStepIndex > 4">
        <ng-template matStepLabel>{{ 'Packaging' | translate }}</ng-template>
        <div class="row step-cont cont-5">
          <app-packaging
            [agec]="sheet?.uuid ?? ''"
            [product]="product"
            (refresh)="refreshData = $event"
          ></app-packaging>
        </div>
      </mat-step>
    </mat-stepper>
  </div>
</div>
<div class="step-action row">
  <div class="action-buttons d-flex pull-left">
    <app-rounded-button
      #btn
      color="secondary"
      [button_text]="'Previous Step' | translate"
      [size]="'md'"
      (click)="selectedStepIndex !== 0 && prevStep()"
      [icon_category]="'arrows'"
      [icon_name]="'arrow-left-long'"
      [icon_left]="true"
      *ngIf="selectedStepIndex !== 0"
    >
    </app-rounded-button>
  </div>

  <div #infoWrap class="btn-tooltip d-flex align-items-center" [style.visibility]="showTooltip ? 'visible' : 'hidden'">
    <div class="info" #info>
      <p class="p1_strong">
        {{
          'Please note that in order to procced to the next step you must fill all of the required fields.' | translate
        }}
      </p>
    </div>
  </div>

  <div class="action-buttons d-flex pull-right" (mouseover)="onHover(!!btn.disabled)" (mouseout)="onHoverOut()">
    <app-rounded-button
      #btn
      color="primary"
      [button_text]="(selectedStepIndex !== 4 ? 'Next Step' : 'Complete') | translate"
      [size]="'md'"
      [icon_category]="'arrows'"
      [icon_name]="'arrow-right-long'"
      [icon_right]="true"
      [disabled]="
        (selectedStepIndex === 0 && !productTypeComponent?.selectedType) ||
        (selectedStepIndex === 1 && tracebilityStepsComponent?.invalid) ||
        (selectedStepIndex === 2 && recycledMaterialsComponent?.form?.invalid) ||
        (selectedStepIndex === 3 && presencePlasticMicrofiberComponent?.form?.invalid) ||
        (selectedStepIndex === 4 && !packagingComponent?.packaging?.length)
      "
      (click)="!btn.disabled && updateStep()"
    >
    </app-rounded-button>
  </div>
</div>
