<div class="landing-wrap" *ngIf="storedUser.is_supplier && storedUser.is_account_set">
  <!-- <div class="landing-wrap"> -->
  <!-- <app-company-preview-info></app-company-preview-info> -->

  <!-- <div class="col-12">
  </div> -->
  <!-- <div class="col-4 questionnaires">
    Questionnaires
    <mat-divider></mat-divider>

    <div class="no">No Questionnaires Assigned</div>
  </div> -->

  <div class="grid-container">
    <div class="grid-item">
      <app-company-info-overview></app-company-info-overview>
    </div>
    <div class="grid-item">
      <app-steps-overview></app-steps-overview>
    </div>
  </div>
</div>
