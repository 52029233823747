import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from '@env/environment';
import {
  IAgecPackaging,
  IAgecRegulation,
  IAgecRegulationResponse,
  IAgecTracebility,
  IBrandRegularionsResponse,
} from '../interface/regulations.interface';

@Injectable({
  providedIn: 'root',
})
export class RegulationsService {
  private apiUrl = environment.api;

  constructor(private _http: HttpClient) {}

  getBrandRegulations$(): Observable<IBrandRegularionsResponse> {
    return this._http.get<IBrandRegularionsResponse>(`${this.apiUrl}/brandregulations/`);
  }

  updateBrandRegulationStatus$(uuid: string, is_active: boolean): Observable<IBrandRegularionsResponse> {
    const status = is_active ? 'enable' : 'disable';
    return this._http.post<IBrandRegularionsResponse>(`${this.apiUrl}/brandregulations/${uuid}/${status}/`, null);
  }
  updateGeneralBrandRegulationStatus$(data: FormData): Observable<IBrandRegularionsResponse> {
    return this._http.post<IBrandRegularionsResponse>(`${this.apiUrl}/brandregulations/areregulationsenabled/`, data);
  }

  getDigitalSheets$(regulation: string): Observable<IAgecRegulationResponse> {
    return this._http.get<IAgecRegulationResponse>(`${this.apiUrl}/${regulation}/`);
  }

  updateDigitalSheet$(data: FormData, uuid: string, regulation: string): Observable<IBrandRegularionsResponse> {
    return this._http.patch<IBrandRegularionsResponse>(`${this.apiUrl}/${regulation}/${uuid}/`, data);
  }

  getDigitalSheet$(regulation: string, uuid: string): Observable<IAgecRegulation> {
    return this._http.get<IAgecRegulation>(`${this.apiUrl}/${regulation}/${uuid}`);
  }

  createDigitalSheet$(data: FormData, regulation: string): Observable<IAgecRegulation> {
    return this._http.post<IAgecRegulation>(`${this.apiUrl}/${regulation}/`, data);
  }

  createTracebilityStep$(data: FormData, regulation: string): Observable<IAgecTracebility> {
    return this._http.post<IAgecTracebility>(`${this.apiUrl}/${regulation}/traceabilitysteps/`, data);
  }
  updateTracebilityStep$(data: FormData, uuid: string, regulation: string): Observable<IAgecTracebility> {
    return this._http.put<IAgecTracebility>(`${this.apiUrl}/${regulation}/traceabilitysteps/${uuid}/`, data);
  }
  createPackaging$(data: FormData, regulation: string): Observable<IAgecPackaging> {
    return this._http.post<IAgecPackaging>(`${this.apiUrl}/${regulation}/packaging/`, data);
  }
  updatePackaging$(data: FormData, uuid: string, regulation: string): Observable<IAgecPackaging> {
    return this._http.put<IAgecPackaging>(`${this.apiUrl}/${regulation}/packaging/${uuid}/`, data);
  }
  deletePackaging$(uuid: string, regulation: string): Observable<IAgecPackaging> {
    return this._http.delete<IAgecPackaging>(`${this.apiUrl}/${regulation}/packaging/${uuid}/`);
  }
}
