import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SupplierLandingComponent } from './supplier-landing/supplier-landing.component';
import { SharedModule } from '@app/shared/shared.module';
import { SupplierLandingRoutingModule } from './supplier-landing-routing.module';
import { CompanyPreviewInfoComponent } from './supplier-landing/company-info/company-preview-info.component';
import { SuppliersFacade } from '@app/core/facade/suppliers.facade';
import { SuppSuppliersFacade } from '@app/core/facade/supp-suppliers.facade';
import { RegisterModule } from '@module/register/register.module';
import { CompanyInfoOverviewComponent } from './supplier-landing/supplier-dashboard/company-info-overview/company-info-overview.component';
import { StepsOverviewComponent } from './supplier-landing/supplier-dashboard/steps-overview/steps-overview.component';
import { DoughnutStepOverviewComponent } from './supplier-landing/supplier-dashboard/steps-overview/doughnut-step-overview/doughnut-step-overview.component';

@NgModule({
  declarations: [
    SupplierLandingComponent,
    CompanyPreviewInfoComponent,
    CompanyInfoOverviewComponent,
    StepsOverviewComponent,
    DoughnutStepOverviewComponent,
  ],
  imports: [CommonModule, SupplierLandingRoutingModule, SharedModule, RegisterModule],
  providers: [SuppliersFacade, SuppSuppliersFacade],
})
export class SupplierLandingModule {}
