<mat-card class="products-traced-wrapper">
  <div class="header-wrapp d-flex flex-column">
    <div class="title-wrapp d-flex justify-content-between align-items-center">
      <h6 class="pr-title m-0">
        {{ 'Products Traced' | translate }}
      </h6>
      <div [matMenuTriggerFor]="beforeMenu">
        <app-rounded-button
          color="secondary"
          class="link"
          [button_text]="'Add product'"
          [icon_category]="'essential'"
          [icon_name]="'add-circle'"
          [icon_right]="true"
          [size]="'md'"
          matTooltip="{{ 'Add a new or an existing product to this project' | translate }}"
          matTooltipClass="custom-tooltip-end mb-20"
          matTooltipPosition="above"
        >
        </app-rounded-button>
      </div>
      <mat-menu
        class="mat-menu-primary existing-project-menu product-menu add-new-project-menu"
        #beforeMenu="matMenu"
        xPosition="after"
        (closed)="clearFilter()"
      >
        <div class="menu-wrapper">
          <div id="menu-text">
            <p class="add-text">{{ 'Click on the product you want to add' | translate }}</p>
          </div>
          <div class="filterR" (click)="$event.stopPropagation()">
            <mat-form-field class="input-primary modal-filter mat-form-field-10-px-brd-rd" appearance="outline">
              <!-- temporary comment to fix position MDC after modal migration -->
              <mat-label class="label-text">{{ 'Search for product' | translate }}</mat-label>

              <input (blur)="onBlur()" (input)="filterItem($event)" matInput placeholder="" #inputFilter />

              <app-custom-icon
                class="search-filter"
                [icon_category]="'search'"
                [icon_name]="'search-normal-2'"
              ></app-custom-icon>
            </mat-form-field>
          </div>
          <div class="item-holder">
            <div *ngIf="products && products.length > 0">
              <button
                (click)="updateProduct(project.uuid, true, product.uuid)"
                class="custom-item"
                mat-menu-item
                *ngFor="let product of products"
              >
                <div class="menu-items">
                  <div class="supp-logo">
                    <div class="logo-wrapper">
                      <img
                        class="supp-img"
                        [src]="product.logo ?? 'assets/images/upload-placeholder-lightblue.png'"
                        alt=""
                      />
                    </div>
                  </div>

                  <div class="supp-name">
                    <div class="name-wrapper">
                      <p class="name">{{ product.name }}</p>
                    </div>
                  </div>
                </div>
              </button>
            </div>

            <button *ngIf="products && products.length <= 0" class="custom-empty-item" mat-menu-item disabled>
              <div class="empty-holder">
                <div class="icon-circle">
                  <app-custom-icon [icon_category]="'essential'" [icon_name]="'danger'"></app-custom-icon>
                </div>
                <p class="d-flex align-items-center">{{ 'There are no products created yet!' }}</p>
              </div>
            </button>
          </div>
          <div class="bottom-action">
            <p class="info-text">{{ "Can't find the product you are looking for?" | translate }}</p>
            <app-rounded-button color="primary" button_text="Create Product" size="md" (click)="openCreateDialog()">
            </app-rounded-button>
          </div>
        </div>
      </mat-menu>
    </div>

    <div *ngIf="dataSource.data.length >= 1" class="filter-subprojects d-flex align-items-center">
      <p class="m-0">{{ 'show only the products assigned to:' | translate }}</p>
      <div *ngIf="!is_filtered" [matMenuTriggerFor]="subProjectMenu" class="subproject-btn d-flex align-items-center">
        <p class="m-0">{{ 'choose sub-project' | translate }}</p>
        <app-custom-icon icon_category="arrows" icon_name="arrow-down-2"></app-custom-icon>
      </div>
      <div *ngIf="is_filtered" class="subproject-selected d-flex align-items-center">
        <p class="m-0">{{ selectedSubproject }}</p>
        <app-custom-icon
          icon_category="essential"
          icon_name="close-circle"
          (click)="is_filtered = !is_filtered; setTableData()"
        ></app-custom-icon>
      </div>
      <mat-menu class="mat-menu-primary subproject-custom-menu" #subProjectMenu="matMenu" xPosition="before">
        <div *ngIf="!emptySubprojects">
          <button
            (click)="filterBySubproject(subProject)"
            class="custom-item"
            mat-menu-item
            *ngFor="let subProject of subprojectsMenuList"
          >
            <p class="name m-0">{{ subProject.name }}</p>
          </button>
        </div>

        <button *ngIf="emptySubprojects" class="empty-project-list" mat-menu-item disabled>
          <div class="d-flex">
            <div class="icon-circle">
              <app-custom-icon [icon_category]="'essential'" [icon_name]="'danger'"></app-custom-icon>
            </div>
            <p class="m-0">{{ 'There are no sub-projects assigned yet' | translate }}</p>
          </div>
        </button>
      </mat-menu>
    </div>
  </div>
  <div class="table-wraper">
    <div class="row">
      <table *ngIf="dataSource.data.length >= 1" class="project-table" mat-table matSort [dataSource]="dataSource">
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Product name' | translate }}</th>
          <td mat-cell *matCellDef="let element">
            {{ element.name }}
          </td>
        </ng-container>

        <ng-container matColumnDef="product_description">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Product description' | translate }}</th>
          <td mat-cell *matCellDef="let element">
            <div class="description">
              <app-view-rich-text [content]="element.description" *ngIf="element.description"></app-view-rich-text>
              <span *ngIf="!element.description" class="description">{{ 'not set' | translate }}</span>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="assigned">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Assigned to sub-project' | translate }}</th>
          <td mat-cell *matCellDef="let element">
            {{ element.subproject?.name ? element.subproject?.name : ('not assigned' | translate) }}
          </td>
        </ng-container>

        <ng-container matColumnDef="chains_created">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Chains created' | translate }}</th>
          <td mat-cell *matCellDef="let element">
            <div class="row">
              <div class="col-1">
                <div [ngClass]="element.chains > 0 ? 'chains-green' : 'chains-gray'"></div>
              </div>
              <div class="col-10">
                <div class="text">{{ element.chains }} {{ 'suppy chains' | translate }}</div>
              </div>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef class="actions-header">{{ 'Actions' | translate }}</th>
          <td mat-cell *matCellDef="let element" class="actions-body text-center">
            <div class="row actions">
              <div class="col-1">
                <a href="" target="_blank" routerLink="../../../dashboard/product/{{ element.uuid }}">
                  <app-custom-icon
                    id="eye"
                    [icon_category]="'security'"
                    [icon_name]="'eye'"
                    matTooltip="{{ 'View Product Details' | translate }}"
                    matTooltipClass="custom-tooltip-center"
                    matTooltipPosition="above"
                  >
                  </app-custom-icon>
                </a>
              </div>
              <div class="col-1 offset-1">
                <app-custom-icon
                  *ngIf="element.subproject !== null"
                  id="unassign"
                  [icon_category]="'essential'"
                  [icon_name]="'unassign-from-sub-project'"
                  matTooltip="{{ 'Unassign from sub-project' | translate }}"
                  matTooltipClass="custom-tooltip-center"
                  matTooltipPosition="above"
                  (click)="unassignSubroject(element.uuid, false)"
                >
                </app-custom-icon>
                <app-custom-icon
                  *ngIf="element.subproject === null"
                  id="assign"
                  [icon_category]="'essential'"
                  [icon_name]="'assign-to-sub-project'"
                  matTooltip="{{ 'Assign to sub-project' | translate }}"
                  matTooltipClass="custom-tooltip-center"
                  matTooltipPosition="above"
                  [matMenuTriggerFor]="addSubproject"
                >
                </app-custom-icon>
                <mat-menu
                  class="mat-menu-primary existing-project-menu subprojects-menu add-new-project-menu"
                  #addSubproject="matMenu"
                  xPosition="after"
                >
                  <div class="menu-wrapper" (click)="$event.stopPropagation()">
                    <div id="menu-text">
                      <p class="add-text">
                        {{ 'Choose the sub-project to which you want to assign this product' | translate }}
                      </p>
                    </div>
                    <div class="item-holder">
                      <div *ngIf="project.subprojects && project.subprojects.length > 0">
                        <div *ngFor="let subproject of project.subprojects">
                          <button
                            *ngIf="subproject.is_active"
                            class="custom-item"
                            mat-menu-item
                            (click)="updateProduct(subproject.uuid, false, element.uuid)"
                          >
                            <div class="menu-items">
                              <div class="supp-logo">
                                <div class="logo-wrapper">
                                  <img
                                    class="supp-img"
                                    [src]="
                                      subproject.logo
                                        ? subproject.logo
                                        : 'assets/images/upload-placeholder-lightblue.png'
                                    "
                                    alt=""
                                  />
                                </div>
                              </div>

                              <div class="supp-name">
                                <div class="name-wrapper">
                                  <p class="name">{{ subproject.name }}</p>
                                </div>
                              </div>
                            </div>
                          </button>
                        </div>
                      </div>

                      <button *ngIf="project.subprojects.length <= 0" class="custom-empty-item" mat-menu-item>
                        <div class="empty-holder">
                          <div class="icon-circle">
                            <app-custom-icon [icon_category]="'essential'" [icon_name]="'danger'"></app-custom-icon>
                          </div>
                          <p class="d-flex align-items-center">{{ 'There are no sub-projects created yet!' }}</p>
                        </div>
                      </button>
                    </div>
                    <div class="bottom-action">
                      <p class="info-text">{{ "Can't find the sub-project you are looking for?" | translate }}</p>
                      <app-rounded-button
                        color="primary"
                        button_text="Create Sub-project"
                        size="md"
                        (click)="createSubprojectDialog(project.uuid, false, element.uuid)"
                      >
                      </app-rounded-button>
                    </div>
                  </div>
                </mat-menu>
              </div>
              <div class="col-1 offset-1">
                <div class="remove-icon-wrap">
                  <app-custom-icon
                    id="unassign-project"
                    [icon_category]="'essential'"
                    [icon_name]="'unassign-from-project'"
                    matTooltip="{{ 'Unassign from project' | translate }}"
                    matTooltipClass="custom-tooltip-end"
                    matTooltipPosition="above"
                    (click)="unassignSubroject(element.uuid, true)"
                  ></app-custom-icon>
                </div>
              </div>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
      <div *ngIf="dataSource.data.length <= 0" class="empty-list d-flex align-items-center justify-content-center">
        <div class="icon-holder d-flex align-items-center justify-content-center">
          <app-custom-icon icon_category="product" icon_name="3d-cube-scan"></app-custom-icon>
        </div>
        <h4 class="m-0">{{ 'There are no products assigned to this project yet.' | translate }}</h4>
      </div>
    </div>
  </div>

  <div class="card-footer" *ngIf="dataSource.data.length >= 1">
    <mat-paginator showFirstLastButtons aria-label="Select page of products traced"> </mat-paginator>
  </div>
</mat-card>
