import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { Injectable } from '@angular/core';

@Injectable()
export class AddressManagementGroup {
  createAddressForm: UntypedFormGroup;

  constructor(private _fb: UntypedFormBuilder) {
    this.createAddressForm = this._fb.group({
      name: [null, [Validators.required]],
      city: [null, [Validators.required]],
      postcode: [null],
      street_line_1: [null, [Validators.required]],
      street_line_2: [null],
      street_number: [null],
      state: [null],
      country: [null, [Validators.required]],
    });
  }
}
