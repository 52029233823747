import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { NotificationType, Storage, USER } from '@app/core/constants';
import { combineLatest, take } from 'rxjs';

import { BrandsFacade } from '@app/core/facade/brands.facade';
import { DataStorageService } from '@app/core/service/data-localstorage.service';
import { IBrandListResult } from '@app/core/interface/brands.interface';
import { ICertificationProduct, IProductResponse } from '@app/core/interface/products.interface';

import { SnackbarService } from '@app/core/service/snackbar.service';
import { CustomPublicationsFacade } from '../custom-publications/custom-publications.facade';
import { ProductsFacade } from '@app/core/facade/products.facade';
import { ICustomPublication } from '../custom-publications/custom-publications.models';

@Component({
  selector: 'app-product-preview',
  templateUrl: './product-preview.component.html',
  styleUrls: ['./product-preview.component.scss'],
})
export class ProductPreviewComponent implements OnInit {
  selectedBrand!: IBrandListResult;
  publicationData!: ICustomPublication;
  product?: IProductResponse;
  activeCertificates: ICertificationProduct[] = [];
  hideLearnMore = false;
  images: string[] = [];
  currentIndex = 0;
  interval!: NodeJS.Timer;
  certicate_note = true;
  emptyImage = '../../../../assets/images/brand-avatar.png';
  productChainUuid?: string | null;

  constructor(
    private _brandsFacade: BrandsFacade,
    private _snackbarService: SnackbarService,
    private _dataStorageService: DataStorageService,
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _customPublicationFacade: CustomPublicationsFacade,
    private _productsFacade: ProductsFacade,
    private _route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.productChainUuid = this._route.snapshot.paramMap.get('id');
    const storedUser = this._dataStorageService.get(USER, Storage.local)
      ? this._dataStorageService.get(USER, Storage.local)
      : this._dataStorageService.get(USER, Storage.session);

    this._brandsFacade.getBrands$().subscribe({
      next: data =>
        (this.selectedBrand =
          data.results.find(brand => brand.uuid === JSON.parse(storedUser).brand) ?? data.results[0]),
      error: this._error.bind(this),
    });
    this.getData();
  }

  getData(): void {
    combineLatest([this._activatedRoute.params.pipe(take(1)), this._activatedRoute.queryParams.pipe(take(1))])
      .pipe(take(1))
      .subscribe(([params, queryParams]) => {
        if (params['id'])
          this._customPublicationFacade.getPublication$(params['id']).subscribe({
            next: res => {
              this.publicationData = res;
              this._productsFacade.getProduct$(queryParams['product']).subscribe({
                next: productData => {
                  this.product = productData;
                  this.activeCertificates = productData.certification_product.filter(
                    certificate => !certificate.expired
                  );
                  this.images = [];
                  this.publicationData.reference =
                    this.product.product_chains.find(res => res.uuid === params['id'])?.reference ?? '';
                  if (productData.image) {
                    this.images.push(productData.image);
                  }
                },
              });
            },
            error: this._error.bind(this),
          });
      });
  }

  goBack(): void {
    this._router.navigate([`/publish/${this.productChainUuid}`], {
      queryParams: { product: this._route.snapshot.queryParamMap.get('product') },
    });
  }

  private _error(error: Record<string, string[]>): void {
    Object.values(error).map(err => this._snackbarService.openTypeSnackbar(err[0], NotificationType.error));
  }
}
