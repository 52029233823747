import { ChangeDetectorRef, Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import {
  IBatchChainStep,
  IBatchChainStepTableItem,
  ISuppChainStep,
  ISuppChainStepResponse,
  ITableSuppChainStep,
} from '@app/core/interface/supp.interface';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { NOT_APPLICABLE, PROJECT_MANAGEMENT } from '@app/core/constants';
import { Status, SupplierStatus } from '@app/core/interface/steps.interface';
import { Subject, takeUntil } from 'rxjs';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { columnsToDisplay, innerDisplayedColumns } from './chain-steps-info.constants';

import { BrandsFacade } from '@app/core/facade/brands.facade';
import { IBrandFeaturesResponse } from '@app/core/interface/brands.interface';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { NotificationType } from '@app/core/constants';
import { Router } from '@angular/router';
import { SnackbarService } from '@app/core/service/snackbar.service';
import { SuppSuppliersFacade } from '@app/core/facade/supp-suppliers.facade';
import { SupportDialogComponent } from '@app/shared/components/support-dialog/support-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { TranslationService } from '@app/core/service/translation.service';
import { applyFullDataFilter } from '@app/core/utils/apply-filter';

@Component({
  selector: 'app-chain-steps-info',
  templateUrl: './chain-steps-info.component.html',
  styleUrls: ['./chain-steps-info.component.scss'],
  providers: [SuppSuppliersFacade],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class ChainStepsInfoComponent implements OnInit {
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  chain_steps: ISuppChainStep[] = [];
  columnsToDisplay = columnsToDisplay;
  innerDisplayedColumns = innerDisplayedColumns;
  showNote = true;
  hideProjectColumn = false;
  readonly NOT_APPLICABLE = NOT_APPLICABLE;

  private _unsubscribe$: Subject<void> = new Subject();
  chainStepsUrl = false;

  @ViewChild('outerSort', { static: true }) sort!: MatSort;
  @ViewChildren('innerSort') innerSort!: QueryList<MatSort>;
  @ViewChildren('innerTables') innerTables!: QueryList<MatTable<IBatchChainStepTableItem>>;
  dataSource!: MatTableDataSource<ITableSuppChainStep>;
  expandedElement!: ITableSuppChainStep | null;

  constructor(
    private _facade: SuppSuppliersFacade,
    private _facadeBrands: BrandsFacade,
    private _snackbarService: SnackbarService,
    private _router: Router,
    public translate: TranslateService,
    public translationService: TranslationService,
    private cd: ChangeDetectorRef,
    private _dialog: MatDialog
  ) {}

  toggleRow(element: ITableSuppChainStep) {
    element.expanded = !element.expanded;
    element.batch_chains && (element.batch_chains as MatTableDataSource<IBatchChainStepTableItem>).data?.length
      ? (this.expandedElement = element)
      : null;
    this.cd.detectChanges();
    this.innerTables.forEach(
      (table, index) =>
        ((table.dataSource as MatTableDataSource<IBatchChainStepTableItem>).sort = this.innerSort.toArray()[index])
    );
  }

  ngOnInit() {
    this._getSuppliers();
    this._subscribeToLang();
    this._getBrandFeatures();
  }

  editStep(step: ITableSuppChainStep): void {
    switch (step.product_chain.status) {
      case Status.complete: {
        //if status is COMPLETE open dialog modal to write request
        this.openSupportDialog(step.uuid);
        break;
      }
      case Status.in_progress: {
        //if status is IN PROGRESS navigate to edit step
        this._router.navigate([`chain-steps-info/edit-step/${step.uuid}`]);
        break;
      }
      default: {
        //if status is not COMPLETE or IN PROGRESS break the loop and inform user status is empty or not applicable
        this._snackbarService.openTypeSnackbar(`Status is empty or not applicable`, NotificationType.warning);
        break;
      }
    }
  }
  viewStep(step: ITableSuppChainStep): void {
    this._router.navigate([`chain-steps-info/view-step/${step.uuid}`]);
  }
  // We can move this function to a service and inject it in the constructor to make it reusable
  openSupportDialog(stepUuid: string): void {
    this._dialog.open(SupportDialogComponent, {
      width: '1160px',
      height: '660px',
      panelClass: ['top-padding-0', 'position-relative'],
      data: stepUuid,
    });
  }

  viewStepBatch(step: ITableSuppChainStep): void {
    this._router.navigate([`chain-steps-info/view-step/${step.uuid}`], {
      queryParams: { is_batch: true },
    });
  }

  editBatchStep(step: IBatchChainStepTableItem): void {
    switch (step.chain_status) {
      case Status.complete: {
        //if status is COMPLETE open dialog modal to write request
        this.openSupportDialog(step.uuid);
        break;
      }
      case Status.in_progress: {
        //if status is IN PROGRESS navigate to edit step
        this._router.navigate([`chain-steps-info/edit-step/${step.uuid}`], { queryParams: { is_batch: true } });
        break;
      }
      default: {
        //if status is not COMPLETE or IN PROGRESS break the loop and inform user status is empty or not applicable
        this._snackbarService.openTypeSnackbar(`Status is empty or not applicable`, NotificationType.warning);
        break;
      }
    }
  }

  applyFilter(event: Event): void {
    applyFullDataFilter(event, undefined, this.dataSource);
  }

  getInnerTableHeaderColumns(): string[] {
    return this.innerDisplayedColumns.map(column => column.property);
  }

  getTableHeaderColumns(): string[] {
    return this.columnsToDisplay.map(column => column.property);
  }

  isStatusComplete(element: IBatchChainStepTableItem | ITableSuppChainStep): boolean {
    const status = (element as ITableSuppChainStep).product_chain
      ? (element as ITableSuppChainStep).product_chain.status.toLowerCase()
      : (element as IBatchChainStepTableItem).chain_status.toLowerCase();

    return status === 'complete';
  }

  ngOnDestroy(): void {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }

  ///////////////////////////////////// PRIVATE METHODS //////////////////////////////////////////

  private _translatecolumnsToDisplay(): void {
    [...this.columnsToDisplay, ...this.innerDisplayedColumns].forEach(column => {
      column.label = this.translate.instant(column.translation_key);
    });
  }

  private _mapChainSteps(): ITableSuppChainStep[] {
    this._setPaginatorLabels();

    const data: ITableSuppChainStep[] = this.chain_steps
      // .filter(step => step.supplier_status !== SupplierStatus.not_applicable)
      .map(step => {
        return {
          ...step,
          chain: step.product_chain?.name,
          product: step.product_chain?.product?.name,
          project:
            step.product_chain?.product?.project?.name ?? this.translate.instant('supplier-dashboard.not entered'),
          status:
            step.supplier_status !== NOT_APPLICABLE
              ? this.translate.instant('supply-chain-step.' + step.supplier_status)
              : '',
          batches: (step.batch_chains as IBatchChainStep[]).length,
          batch_chains: (step.batch_chains as IBatchChainStep[]).length
            ? new MatTableDataSource(this._mapBatchChains(step.batch_chains as IBatchChainStep[]))
            : null,
          expanded: false,
          default_expanded: (step.batch_chains as IBatchChainStep[]).some(
            x => x.supplier_status === SupplierStatus.to_update
          )
            ? true
            : false,
        };
      });

    return data;
  }

  private _getSupplierStepsSuccess(data: ISuppChainStepResponse): void {
    if (data && data.results.length) {
      this.chain_steps = data.results;
      this.dataSource = new MatTableDataSource(this._mapChainSteps());
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this._defaultExpander(this.dataSource);
    }
  }

  private _defaultExpander(dataSource: MatTableDataSource<ITableSuppChainStep>): void {
    dataSource.data.forEach(element => {
      if (element.default_expanded) {
        this.expandedElement = element;
        element.expanded = true;
      }
    });
  }

  private _getSuppliers() {
    this._facade.getSupplierSteps$().subscribe({
      next: this._getSupplierStepsSuccess.bind(this),
      error: this._error.bind(this),
    });
  }

  // Const uuid is temporary, it should be dynamic and related with the brand
  private _getBrandFeatures() {
    this._facadeBrands.getSuppBrandFeatures$().subscribe({
      next: this._getBrandFeaturesSuccess.bind(this),
      error: this._error.bind(this),
    });
  }

  private _getBrandFeaturesSuccess(data: IBrandFeaturesResponse): void {
    if (data.results) {
      const projectManagement = data.results.find(project => project.name === PROJECT_MANAGEMENT);
      if (projectManagement) {
        this.hideProjectColumn = !projectManagement.is_enabled;
      }
    }
    if (this.hideProjectColumn) {
      this._removeColumn('project');
    }
  }

  private _removeColumn(columnProp: string): void {
    this.columnsToDisplay = this.columnsToDisplay.filter(col => col.property !== columnProp);
  }

  private _error(error: Record<string, string>): void {
    Object.values(error).map(err => {
      this._snackbarService.openTypeSnackbar(err, NotificationType.error);
    });
  }

  private _subscribeToLang(): void {
    this.translationService.language.pipe(takeUntil(this._unsubscribe$)).subscribe((data: string) => {
      if (data) {
        this._mapChainSteps();
        this._translatecolumnsToDisplay();
      }
    });
  }

  private _mapBatchChains(batchChains: IBatchChainStep[]): IBatchChainStepTableItem[] {
    return batchChains
      .filter(batch => batch.supplier_status !== 'Not applicable')
      .map(batch => {
        return {
          ...batch,
          step_name: batch.name,
          name: batch.batch.name,
          status: batch.supplier_status,
        };
      });
  }

  private _setPaginatorLabels(): void {
    this.paginator._intl.itemsPerPageLabel = this.translate.instant('supply-chain-step.Items per page') + ':';
  }
}
