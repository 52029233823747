import { RouterModule, Routes } from '@angular/router';
import { CertificateManagementComponent } from './certificate-management.component';
import { NgModule } from '@angular/core';

const routes: Routes = [
  {
    path: '',
    children: [{ path: '', component: CertificateManagementComponent }],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CertificateManagementRoutingModule {}
