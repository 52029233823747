import { RouterModule, Routes } from '@angular/router';

import { NgModule } from '@angular/core';
import { BillingManagementComponent } from './billing-management.component';

const routes: Routes = [
  {
    path: '',
    children: [{ path: '', component: BillingManagementComponent }],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class BillingManagementRoutingModule {}
