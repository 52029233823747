import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { IProject, IProjectInfoDialogData, IProjectsListResponse } from '@app/core/interface/projects.interface';
import { NotificationType, infoDialogHeight, infoDialogWidth } from '@app/core/constants';

import { IProduct } from '@app/core/interface/products.interface';
import { InfoDialogComponent } from '@app/shared/components/info-dialog/info-dialog.component';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ProjectComponent } from '@app/module/projects/project/project.component';
import { ProjectDetailsDialogComponent } from '@app/module/projects/project-details-dialog/project-details-dialog.component';
import { ProjectsFacade } from '@app/core/facade/projects.facade';
import { Router } from '@angular/router';
import { SnackbarService } from '@app/core/service/snackbar.service';
import { applyFilter } from '@app/core/utils/apply-filter';

@Component({
  selector: 'app-projects-list',
  templateUrl: './projects-list.component.html',
  styleUrls: ['./projects-list.component.scss'],
})
export class ProjectsListComponent implements OnInit {
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort!: MatSort;
  @Input() dataSource = new MatTableDataSource<IProject>();
  displayedColumns = ['name', 'status', 'sub_projects_num', 'product_traced_num', 'actions'];
  allProjects: Array<IProject> = [];
  showCheckbox = false;
  deactiveVisible = false;
  has_products!: boolean;
  constructor(
    private _facade: ProjectsFacade,
    private _snackbarService: SnackbarService,
    private _dialog: MatDialog,
    private _projectFacade: ProjectsFacade,
    public router: Router,
    private _changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.getProjects();
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.paginator.pageSize = 12;
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'name':
          return item['name'].toUpperCase();
        case 'status':
          return item['status']?.name;
        case 'sub_projects_num':
          return item['subprojects'].length;
        case 'product_traced_num':
          return item['project_products'].length;
        default:
          return item[property as keyof IProject] as string;
      }
    };
    this._changeDetectorRef.detectChanges();
  }

  getProjects(): void {
    this._facade.getProjects$('is_active,name', 999999).subscribe({
      next: this._success.bind(this),
      error: this._error.bind(this),
    });
  }

  openCreateDialog(uuid: string, is_editing: boolean): void {
    const dialogRef = this._dialog.open(ProjectComponent, {
      width: '1160px',
      height: '768px',
      panelClass: ['overflow-hidden-dialog', 'top-padding-0'],
      data: {
        is_editing: is_editing,
        projectId: uuid,
        is_project: true,
        project_view: true,
      },
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((result: IProject) => {
      if (result) {
        this.router.navigate(['/products/projects/' + result.uuid]);
      }
    });
  }

  deactivateProject(project: IProject) {
    this.has_products = project.project_products.length >= 1;
    const dialogRef = this._dialog.open(InfoDialogComponent, {
      width: infoDialogWidth,
      height: infoDialogHeight,
      data: {
        infoText: this.has_products
          ? 'The selected project has active products under it.'
          : 'Note that once you deactivate this project, its sub-projects will be deactivated too. You can reactivate the project at anytime.',

        confirmationText: this.has_products
          ? 'The project cannot be deactivated!'
          : 'Are you sure you want to deactivate this project?',
        btnText: this.has_products ? 'Ok, Got It' : 'Deactivate Project',
        type: this.has_products ? 'forbidden' : 'warning',
        text: this.has_products ? 'forbidden-text' : 'warning-text',
      },
    });
    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this._projectFacade.activateDeactivateProject$(project.uuid, { is_active: false }).subscribe({
          next: this.getProjects.bind(this),
          error: this._error.bind(this),
        });
      }
    });
  }

  reactivateProject(project: IProject) {
    this._projectFacade.activateDeactivateProject$(project.uuid, { is_active: true }).subscribe({
      next: this.getProjects.bind(this),
      error: this._error.bind(this),
    });
  }

  activateProject(project: IProject): void {
    this._facade
      .updateProject$(
        {
          is_active: true,
          name: project.name,
          status: project.status?.uuid,
          project_products: project.project_products.map(product => product.uuid),
        } as unknown as IProduct,
        project.uuid
      )
      .subscribe({
        next: this.ngOnInit.bind(this),
        error: this._error.bind(this),
      });
  }

  getInfoData(activeProduct?: IProduct): IProjectInfoDialogData {
    if (activeProduct)
      return {
        infoText: 'The selected project has active products under it.',
        confirmationText: 'The project cannot be deactivated!',
        btnText: 'Ok, Got It',
        type: 'forbidden',
        text: 'forbidden-text',
      };
    return {
      infoText:
        'Note that once you deactivate this project, its sub-projects will be deactivated too. You can reactivate the project at anytime.',
      confirmationText: 'Are you sure you want to deactivate this project?',
      btnText: 'Deactivate Project',
      type: 'warning',
      text: 'warning-text',
    };
  }

  openViewDialog(project: IProject): void {
    this._dialog.open(ProjectDetailsDialogComponent, {
      width: '700px',
      height: '768px',
      panelClass: 'padding-0',
      data: {
        project: project,
        is_project: true,
        project_view: true,
        project_products: project.project_products,
      },
      disableClose: true,
    });
  }

  applyFilter(event: Event): void {
    applyFilter(event, undefined, this.dataSource);
  }

  toggleDeactiveView(event: MatCheckboxChange): void {
    this.deactiveVisible = event.checked;
    this.dataSource.paginator?.firstPage();
    this.setTableData();
  }

  setTableData(): void {
    this.dataSource.data = this.deactiveVisible
      ? this.allProjects
      : this.allProjects.filter(project => project.is_active);
  }

  private _success(response: IProjectsListResponse): void {
    this.allProjects = response.results;
    this.showCheckbox = !!this.allProjects.find(project => !project.is_active);
    this.setTableData();
  }

  private _error(error: Record<string, string[]>): void {
    Object.values(error).map(err => this._snackbarService.openTypeSnackbar(err[0], NotificationType.error));
  }
}
