import { Component, Inject } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ISuppChainStep } from '@app/core/interface/supp.interface';

@Component({
  selector: 'app-update-step-alert',
  templateUrl: './update-step-alert.component.html',
  styleUrls: ['./update-step-alert.component.scss'],
})
export class UpdateStepAlertComponent {
  radioControl!: UntypedFormControl;
  reasonInput!: UntypedFormControl;
  isSpecifyReason = false;

  constructor(
    public dialogRef: MatDialogRef<UpdateStepAlertComponent>,
    @Inject(MAT_DIALOG_DATA) public step: ISuppChainStep
  ) {
    this.radioControl = new UntypedFormControl(null);
    this.reasonInput = new UntypedFormControl(null, Validators.required);

    this.radioControl.valueChanges.subscribe((value: boolean) => {
      if (value) {
        this.dialogRef.close(true);
      } else {
        this.isSpecifyReason = true;
      }
    });
  }

  onProceed() {
    this.dialogRef.close(this.reasonInput.value);
  }
}
