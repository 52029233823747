import { RouterModule, Routes } from '@angular/router';

import { AmwayPublicationPageComponent } from './custom-publications/amway/amway-publication-page/amway-publication-page.component';
import { BrandPreviewComponent } from './brand-preview/brand-preview.component';
import { NgModule } from '@angular/core';
import { ProductPreviewComponent } from './product-preview/product-preview.component';
import { PublicationComponent } from './publication.component';
import { SetupChainComponent } from './chain-overview/setup-chain/setup-chain.component';
import { AmwayFinishedPublicationComponent } from './custom-publications/amway/amway-finished-publication/amway-finished-publication.component';
import { PublicationPreviewComponent } from './publication-preview/publication-preview.component';
// import { PublicationPreviewComponent } from './publication-preview/publication-preview.component';

const routes: Routes = [
  {
    path: '',
    component: PublicationComponent,
    // pathMatch: 'full',
  },
  {
    path: 'setup-chain',
    component: SetupChainComponent,
  },
  {
    path: 'preview-product',
    component: ProductPreviewComponent,
  },
  {
    path: 'preview-brand',
    component: BrandPreviewComponent,
  },
  {
    path: 'preview-publication',
    component: PublicationPreviewComponent,
  },
  {
    path: 'custom-publications/amway',
    component: AmwayPublicationPageComponent,
  },
  {
    path: 'custom-publications/amway/finished',
    component: AmwayFinishedPublicationComponent,
  },
  {
    path: 'custom-publications/finished',
    component: AmwayFinishedPublicationComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PublicationRouterModule {}
