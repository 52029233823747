import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-recycled-materials-info',
  templateUrl: './recycled-materials-info.component.html',
  styleUrls: ['./recycled-materials-info.component.scss'],
})
export class RecycledMaterialsInfoComponent implements OnChanges {
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['recycled_materials']) {
      this.selectedValue = changes['recycled_materials'].currentValue;
    }
    if (changes['is_recycled_materials_display']) {
      this.visibility = changes['is_recycled_materials_display'].currentValue;
    }
  }
  @Output() isSelected = new EventEmitter<boolean>(false);
  @Output() checkValidation = new EventEmitter<boolean>(false);
  @Input() recycled_materials!: boolean;
  @Input() is_recycled_materials_display!: boolean;

  selected = false;
  selectedValue!: boolean;
  visibility = true;
  onSelection() {
    if (!this.selected) this.isSelected.emit(true);
    this.selected = true;
  }
  onVisibilityChange() {
    this.checkValidation.emit(this.visibility);
  }
}
