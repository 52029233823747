<ng-container *ngIf="!showTabSelector && !data">
  <h6 class="title-note"><strong>Select for which Brand you want to create this certificate</strong></h6>
  <div class="brands-list d-flex">
    <div
      *ngFor="let brand of brandsObservable$ | async"
      class="brand-thumb d-flex align-items-center cursor-pointer"
      [ngClass]="selectedObject?.uuid === brand.uuid ? 'brand-thumb-selected' : ''"
      (click)="onSelectObject(brand)"
    >
      <img [src]="brand.logo || 'assets/images/upload-placeholder-blue.svg'" alt="" />
      <p class="m-0">
        <strong>{{ brand.name }}</strong>
      </p>
      <app-custom-icon
        *ngIf="selectedObject?.uuid === brand.uuid"
        class="icon-tick-circle"
        icon_category="essential"
        icon_name="tick-circle"
      ></app-custom-icon>
    </div>
  </div>
</ng-container>
