<h2 mat-dialog-title>
  {{ 'billing-management.Manage Your Billing Account' | translate }}
</h2>
<mat-tab-group mat-stretch-tabs>
  <mat-tab>
    <ng-template mat-tab-label>
      <div class="d-flex flex-row align-items-center">
        <app-custom-icon [icon_category]="'user'" [icon_name]="'profile'"></app-custom-icon>
        <span class="text-mutted mb-0">{{ 'billing-management.Update Details' | translate }}</span>
      </div>
    </ng-template>
    <app-manage-customer-details [customerDetails]="customerDetails" (closeDialog)="closeDialog($event)">
    </app-manage-customer-details>
  </mat-tab>
  <mat-tab>
    <ng-template mat-tab-label>
      <div class="d-flex flex-row align-items-center">
        <app-custom-icon [icon_category]="'location'" [icon_name]="'location-tick'"></app-custom-icon>
        <span class="text-mutted mb-0">{{ 'billing-management.Billing Address' | translate }}</span>
      </div>
    </ng-template>
    <app-manage-billing-address [billingDetails]="billingDetails" (closeDialog)="closeDialog($event)">
    </app-manage-billing-address>
  </mat-tab>
  <mat-tab>
    <ng-template mat-tab-label>
      <div class="d-flex flex-row align-items-center">
        <app-custom-icon [icon_category]="'finance'" [icon_name]="'card'"></app-custom-icon>
        <span class="text-mutted mb-0">{{ 'billing-management.Credit Card' | translate }}</span>
      </div>
    </ng-template>
    <app-manage-credit-card [creditCardDetails]="creditCardDetails" (closeDialog)="closeDialog($event)">
    </app-manage-credit-card>
  </mat-tab>
</mat-tab-group>
