<div class="title-search-row d-flex justify-content-between align-items-end">
  <h6 class="title-note m-0"><strong>Select for which Supplier you want to create this certificate</strong></h6>
  <mat-form-field class="input-primary mat-form-field-10-px-brd-rd" appearance="outline">
    <input matInput placeholder="Search for supplier" (keyup)="applyFilter($event)" />
    <app-custom-icon matSuffix icon_category="search" icon_name="search-normal-2"></app-custom-icon>
  </mat-form-field>
</div>

<div class="suppliers-list d-flex flex-wrap">
  <div
    *ngFor="let supplier of suppliersObservable$ | async"
    class="supplier-thumb d-flex align-items-center cursor-pointer"
    [ngClass]="selectedObject?.uuid === supplier.uuid ? 'supplier-thumb-selected' : ''"
    (click)="onSelectObject(supplier)"
  >
    <img [src]="supplier.logo || 'assets/images/upload-placeholder-blue.svg'" alt="" />
    <h6>
      <strong>{{ supplier.name }}</strong>
    </h6>
    <app-custom-icon
      *ngIf="selectedObject?.uuid === supplier.uuid"
      class="icon-tick-circle"
      icon_category="essential"
      icon_name="tick-circle"
    ></app-custom-icon>
  </div>
</div>
