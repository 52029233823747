import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { IFactory, ISupplier, ISupplierResponse } from '@app/core/interface/suppliers.interface';
import { IMapBoxFullAddress, IPublicationStep } from '@app/core/interface/publication.interface';
import { map, startWith } from 'rxjs';

import { IMedia } from '@app/core/interface/medias.interface';
import { LngLatLike } from 'mapbox-gl';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { Observable } from 'rxjs/internal/Observable';
import { PublicationFacade } from '@app/core/facade/publication.facade';
import { SuppliersFacade } from '@app/core/facade/suppliers.facade';
import { UntypedFormGroup } from '@angular/forms';
import { DataStorageService } from '@service/data-localstorage.service';
import { ICustomPublicationStep } from '@app/module/publication/custom-publications/custom-publications.models';

@Component({
  selector: 'app-supplier-details',
  templateUrl: './supplier-details.component.html',
  styleUrls: ['./supplier-details.component.scss'],
})
export class SupplierDetailsComponent implements OnInit {
  @Input() supplierForm!: UntypedFormGroup;
  @Input() manufactoryForm!: UntypedFormGroup;
  @Input() step?: IPublicationStep | ICustomPublicationStep;

  @Input() supplier?: ISupplier | ISupplierResponse;
  @Output() refreshSupplier = new EventEmitter<boolean>();
  photos?: IMedia[];
  videos?: IMedia[];
  mapCenter?: LngLatLike = [0, 0];
  isSupplierConfidential? = false;
  isSupplierPrivate? = false;
  supplierList!: ISupplier[];
  filteredSuppliers!: Observable<ISupplier[]>;
  filteredManufactories?: Observable<IFactory[] | undefined>;
  selectedSupplier?: ISupplier;
  constructor(
    private _facade: PublicationFacade,
    private _suppliersFacade: SuppliersFacade,
    private _dataStorageService: DataStorageService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['supplier'] && this.supplier) {
      this.photos = this.supplier.medias?.filter((media: IMedia) => media.mimetype.indexOf('image') > -1);
      this.videos = this.supplier.medias?.filter((media: IMedia) => media.mimetype.indexOf('video') > -1);
      this.isSupplierConfidential = this.supplier.is_hidden;
    }
  }

  ngOnInit(): void {
    if (
      this.step &&
      this.step.manufactories &&
      ((Array.isArray(this.step.manufactories) && this.step.manufactories.length > 0) ||
        !Array.isArray(this.step.manufactories))
    ) {
      const { manufactories } = this.step;
      const { longitude, latitude } = Array.isArray(manufactories)
        ? manufactories[manufactories.length - 1]
        : manufactories;

      this.mapCenter = [longitude ?? 0, latitude ?? 0];
    }

    this._suppliersFacade.getSupplierList$().subscribe(data => {
      this.supplierList = data.results;

      this.filteredSuppliers = this.supplierForm.controls['name'].valueChanges.pipe(
        startWith(''),
        map(value => this._filter(value.name ? value.name : value || ''))
      );
    });
  }

  saveImageData(file: File): void {
    this.supplierForm.get('logo')?.setValue(file ? file : '');
    this.supplierForm.get('logo')?.markAsDirty();
  }

  generatePin(): void {
    const searchQuery = this.manufactoryForm.get('city')?.value
      ? this.manufactoryForm.get('city')?.value
      : this.manufactoryForm.get('country')?.value
        ? this.manufactoryForm.get('country')?.value
        : this.manufactoryForm.get('address')?.value;
    this._facade.getFullAddress$(searchQuery).subscribe((data: IMapBoxFullAddress) => {
      this.mapCenter = data.features[0].center;
    });
  }

  validateMapFields(): boolean {
    const address = this.manufactoryForm.get('address')?.value;
    const city = this.manufactoryForm.get('city')?.value;
    const country = this.manufactoryForm.get('country')?.value;

    if (!address && !city && !country) {
      return false;
    }

    return true;
  }

  toggleSupplierConfidental(event: MatCheckboxChange): void {
    this.isSupplierConfidential = event.checked;
  }

  onSupplierChange(selection: MatAutocompleteSelectedEvent): void {
    this.supplierForm.get('name')?.setValue(selection.option.value.name);
    this.supplierForm.get('description')?.setValue(selection.option.value.description);
    this.supplierForm.get('description')?.markAsDirty();
    this.selectedSupplier = selection.option.value;

    this.filteredManufactories = this.manufactoryForm.controls['address'].valueChanges.pipe(
      startWith(''),
      map(value => this._filterManufactory(value.name ? value.name : value || ''))
    );
  }

  onAddressChange(selection: MatAutocompleteSelectedEvent): void {
    this.manufactoryForm.get('address')?.setValue(selection.option.value.address);
    this.manufactoryForm.get('city')?.setValue(selection.option.value.city);
    this.manufactoryForm.get('city')?.markAsDirty();
    this.manufactoryForm.get('country')?.setValue(selection.option.value.country);
    this.manufactoryForm.get('country')?.markAsDirty();
  }

  displayFn(supplier: ISupplier | null): string {
    return supplier?.name ?? '';
  }

  private _filter(value: string): ISupplier[] {
    const filterValue = value.toLowerCase();

    return this.supplierList?.filter(option => option.name.toLowerCase().includes(filterValue));
  }

  private _filterManufactory(value: string): IFactory[] | undefined {
    const filterValue = value.toLowerCase();

    return this.selectedSupplier?.manufactories?.filter(option => option.name.toLowerCase().includes(filterValue));
  }
}
