import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PublicationFacade } from 'src/app/core/facade/publication.facade';

import { CountryList } from 'src/app/core/utils/country-list';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { SharedModule } from '@app/shared/shared.module';
import { CommonModule } from '@angular/common';
import { Step, Type } from '@app/core/interface/preview_publicaton.interface';
import { PinInfoDialogComponent } from './pin-info-dialog/pin-info-dialog.component';
import { StepInfoComponent } from './pin-info-dialog/step-info/step-info.component';

@Component({
  standalone: true,
  selector: 'app-pin-info',
  templateUrl: './pin-info.component.html',
  styleUrls: ['./pin-info.component.scss'],
  imports: [SharedModule, CommonModule, StepInfoComponent],
  animations: [
    trigger('expandAnimation', [
      state('expanded', style({ height: '*' })),
      state('collapsed', style({ height: '0' })),
      transition('expanded <=> collapsed', animate('350ms ease-in-out')),
    ]),
  ],
})
export class PinInfoComponent {
  @Output() onExpandedArea: EventEmitter<number> = new EventEmitter();
  @Input() position?: number;
  @Input() text?: string;
  @Input() color?: string;
  @Input() steps?: Step[];
  @Input() type?: Type;
  @Input() expandedStep?: number;
  filteredSteps?: Step[];
  areaExpanded = false;
  getCountryName = CountryList.getCountryName;

  constructor(
    private _publicationFacade: PublicationFacade,
    public dialog: MatDialog
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['expandedStep']?.currentValue == this.position) {
      this.areaExpanded = true;
    } else this.areaExpanded = false;
  }

  expandArea() {
    this.dialog.closeAll();
    this.areaExpanded = !this.areaExpanded;
    this.filteredSteps = this.steps;
    if (this.areaExpanded) this.onExpandedArea.emit(this.position);

    // Render pins for all steps into the types
    if (this.position !== undefined && this.areaExpanded == true) {
      this._publicationFacade.expandedType.next(this.position);
    } else {
      this._publicationFacade.expandedType.next(-1);
    }
  }

  onSupplierDialog(step: Step) {
    this.dialog.closeAll();
    this.dialog.open(PinInfoDialogComponent, {
      data: step,
      panelClass: 'step-info-dialog',
      hasBackdrop: false,
      position: { right: '5%' },
    });

    // Render the pin only for the selected step.

    if (this.type) {
      const newType = { ...this.type, steps: [step] };
      this._publicationFacade.onStepSelected.next({ step: step, type: newType });
    }
  }
}
