<div class="background">
  <div class="questionnaires-view">
    <mat-card class="questionnaires-wrapper">
      <div class="header-wrapper">
        <mat-card class="options-header">
          <div class="questionnaires-header">
            <div class="questionnaires-heading">
              <p>
                {{ 'All Questionnaires' | translate }}
              </p>
            </div>

            <div class="create-questionnaire-btn">
              <app-rounded-button
                color="primary"
                [button_text]="'Create Questionnaire' | translate"
                [size]="'md'"
                routerLink="create"
              >
              </app-rounded-button>
            </div>
          </div>
        </mat-card>
      </div>

      <ng-container *ngFor="let questionnaire of questionnaires">
        <div class="p-3">
          <div class="colored-box">
            <div class="colored-portion"></div>
            <div class="row">
              <div class="col-4">
                <p class="questionnaire-name">{{ questionnaire.name }}</p>
                <p class="questionnaire-details">
                  {{ questionnaire.sections.length }} {{ 'questionnaire sections' | translate }}
                </p>
                <p class="questionnaire-details-1">
                  {{ countQuestions(questionnaire.uuid) }} {{ 'Questions' | translate }}
                </p>
              </div>
              <div class="col-4 offset-4">
                <div>
                  <div class="one-line">
                    <span class="questionnaire-details"> {{ 'last updated: ' | translate }} <strong>N/A</strong> </span>
                    <div class="midle-line"></div>
                    <div class="colored-circle"></div>
                    <span class="questionnaire-details">
                      {{ questionnaire.status | questionnaireStatusToRes | translate }}
                    </span>
                  </div>
                </div>
                <div style="padding-top: 30px; text-align: end">
                  <app-rounded-button
                    color="secondary"
                    [button_text]="'Manage Questionnaire' | translate"
                    [size]="'md'"
                    [routerLink]="['questionnaire-details', questionnaire.uuid]"
                  >
                  </app-rounded-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </mat-card>
  </div>
</div>
