<div class="mat-elevation-z8">
  <table mat-table class="full-width-table" matSort [dataSource]="dataSource">
    <!-- Brand Column -->
    <ng-container matColumnDef="brand">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Brand</th>
      <td mat-cell *matCellDef="let row">{{ row.brand.name }}</td>
    </ng-container>

    <!-- Request Date Column -->
    <ng-container matColumnDef="request_date">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Request Date</th>
      <td mat-cell *matCellDef="let row">{{ row.created_at }}</td>
    </ng-container>

    <!-- Assigned to Column -->
    <ng-container matColumnDef="assigned_to">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Assigned to</th>
      <td mat-cell *matCellDef="let row">{{ row.csm_assign.username }}</td>
    </ng-container>

    <!-- Batch Number Column -->
    <ng-container matColumnDef="batch_number">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Batch Number</th>
      <td mat-cell *matCellDef="let row">{{ row.batch.reference }}</td>
    </ng-container>

    <!-- Status Column -->
    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
      <td mat-cell *matCellDef="let row">{{ row.status }}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>

  <mat-paginator
    #paginator
    [length]="dataSource.data.length"
    [pageIndex]="0"
    [pageSize]="10"
    [pageSizeOptions]="[5, 10, 20]"
    aria-label="Select page"
  >
  </mat-paginator>
</div>
