import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';

import { ACCESS_TOKEN } from '@app/core/constants';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root',
})
export class JwtInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const jwt = localStorage.getItem(ACCESS_TOKEN)
      ? localStorage.getItem(ACCESS_TOKEN)
      : sessionStorage.getItem(ACCESS_TOKEN);

    if (jwt) {
      const cloned = req.clone({
        headers: req.headers.set('Authorization', `Bearer ${jwt}`),
      });

      return next.handle(cloned);
    } else {
      return next.handle(req);
    }
  }
}
