import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AddIngredientComponent } from './product-ingredients/add-ingredient/add-ingredient.component';
import { AngularMaterialModule } from '@app/material.module';
import { BatchStatusUpdateComponent } from './batch-status-update/batch-status-update.component';
import { CertificationDashboardModule } from '../certificates/certification-dashboard/certification-dashboard.module';
import { CommonModule } from '@angular/common';
import { CreateBatchComponent } from './create-batch/create-batch.component';
import { FilesMeadiasLinksFacade } from '@app/core/facade/files-medias-links.facade';
import { NgModule } from '@angular/core';
import { ProductCertificatesComponent } from './product-certificates/product-certificates.component';
import { ProductDashboardComponent } from './product-dashboard.component';
import { ProductDashboardRoutingModule } from './product-dashboard-routing.module';
import { ProductDetailsDialogComponent } from './product-details-dialog/product-details-dialog.component';
import { ProductProductIngredientsComponent } from './product-ingredients/product-ingredients.component';
import { ProductSuppliersComponent } from './product-suppliers/product-suppliers.component';
import { ProductSupplyChainFormComponent } from './product-supply-chains/product-supply-chain-form/product-supply-chain-form.component';
import { ProductSupplyChainsComponent } from './product-supply-chains/product-supply-chains.component';
import { ProductsFacade } from '@app/core/facade/products.facade';
import { ProjectsFacade } from '@app/core/facade/projects.facade';
import { SharedModule } from '@app/shared/shared.module';
import { ProductCertificatesGridComponent } from './product-certificates-grid/product-certificates-grid.component';
import { ProductPublicationsComponent } from './product-publications/product-publications.component';
import { CreateAddComponentComponent } from './product-ingredients/add-ingredient/create-add-component/create-add-component.component';
import { CreateSupplierAndSiteComponent } from './product-ingredients/add-ingredient/create-supplier-and-site/create-supplier-and-site.component';
import { SitesListComponent } from './product-ingredients/add-ingredient/create-supplier-and-site/sites-list/sites-list.component';
import { FlagComponentAsChemicalComponent } from '../../../shared/components/flag-component-as-chemical/flag-component-as-chemical.component';
import { ProductProjectComponent } from './product-project/product-project.component';
import { ProjectOverviewComponent } from './project-overview/project-overview.component';
import { ProductRegulationsComponent } from './product-regulations/product-regulations.component';
import { CreateDigitalProductSheetDialogComponent } from './create-digital-product-sheet-dialog/create-digital-product-sheet-dialog.component';
import { ProductTypeComponent } from './create-digital-product-sheet-dialog/product-type/product-type.component';
import { TracebilityStepsComponent } from './create-digital-product-sheet-dialog/tracebility-steps/tracebility-steps.component';
import { SetStepTypeCardComponent } from './create-digital-product-sheet-dialog/tracebility-steps/set-step-type-card/set-step-type-card.component';
import { RecycledMaterialsComponent } from './create-digital-product-sheet-dialog/recycled-materials/recycled-materials.component';
import { PresencePlasticMicrofiberComponent } from './create-digital-product-sheet-dialog/presence-plastic-microfiber/presence-plastic-microfiber.component';
import { PackagingComponent } from './create-digital-product-sheet-dialog/packaging/packaging.component';
import { ReviewDigitalProductSheetComponent } from './review-digital-product-sheet/review-digital-product-sheet.component';
import { CreatePackagingDialogComponent } from './create-digital-product-sheet-dialog/packaging/create-packaging-dialog/create-packaging-dialog.component';
import { HazardousSubstanceComponent } from './create-digital-product-sheet-dialog/packaging/create-packaging-dialog/set-packaging-info/hazardous-substance/hazardous-substance.component';
import { RecyclabilityComponent } from './create-digital-product-sheet-dialog/packaging/create-packaging-dialog/set-packaging-info/recyclability/recyclability.component';
import { RecycledMaterialsInfoComponent } from './create-digital-product-sheet-dialog/packaging/create-packaging-dialog/set-packaging-info/recycled-materials-info/recycled-materials-info.component';
import { SetPackagingInfoComponent } from './create-digital-product-sheet-dialog/packaging/create-packaging-dialog/set-packaging-info/set-packaging-info.component';
import { RegulationsFacade } from '@app/core/facade/regulations.facade';
import { PackagingViewComponent } from './create-digital-product-sheet-dialog/packaging/packaging-view/packaging-view.component';
import { ProductAgecRegulationComponent } from './product-regulations/product-agec-regulation/product-agec-regulation.component';
import { ProductPublicationSetupComponent } from './product-publication-setup/product-publication-setup.component';
import { AccessPublicationDialogComponent } from './product-publications/access-publication-dialog/access-publication-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    AngularMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    ProductDashboardRoutingModule,
    CertificationDashboardModule,
    ProductPublicationSetupComponent,
  ],
  declarations: [
    ProductDashboardComponent,
    ProductSuppliersComponent,
    ProductCertificatesComponent,
    ProductSupplyChainsComponent,
    ProductProductIngredientsComponent,
    AddIngredientComponent,
    CreateBatchComponent,
    ProductSupplyChainFormComponent,
    BatchStatusUpdateComponent,
    ProductDetailsDialogComponent,
    ProductCertificatesGridComponent,
    ProductPublicationsComponent,
    CreateAddComponentComponent,
    CreateSupplierAndSiteComponent,
    SitesListComponent,
    FlagComponentAsChemicalComponent,
    ProductProjectComponent,
    ProjectOverviewComponent,
    ProductRegulationsComponent,
    CreateDigitalProductSheetDialogComponent,
    ProductTypeComponent,
    TracebilityStepsComponent,
    SetStepTypeCardComponent,
    RecycledMaterialsComponent,
    PresencePlasticMicrofiberComponent,
    PackagingComponent,
    SetPackagingInfoComponent,
    RecyclabilityComponent,
    HazardousSubstanceComponent,
    RecycledMaterialsInfoComponent,
    ReviewDigitalProductSheetComponent,
    CreatePackagingDialogComponent,
    PackagingViewComponent,
    ProductAgecRegulationComponent,
    AccessPublicationDialogComponent,
  ],
  providers: [ProductsFacade, ProjectsFacade, FilesMeadiasLinksFacade, RegulationsFacade],
})
export class ProductDashboardModule {}
