import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import {
  IBrandUserListResponse,
  IBrandUserListResult,
  INameId,
  IPermissionItemObject,
  IPermissionListResponse,
  IPermissionListResult,
  IPutDeleteBrandUserPermissions,
  IPutDeleteBrandUserPermissionsResult,
} from '@app/core/interface/users-access.interface';

import { NotificationType } from '@app/core/constants';
import { SnackbarService } from '@app/core/service/snackbar.service';
import { Subscription } from 'rxjs';
import { UntypedFormControl } from '@angular/forms';
import { UsersAccessFacade } from '@app/core/facade/users-access.facade';

@Component({
  selector: 'app-users-access',
  templateUrl: './users-access.component.html',
  styleUrls: ['./users-access.component.scss'],
  providers: [UsersAccessFacade],
})
export class UsersAccessComponent implements OnInit, OnDestroy {
  usersList: IBrandUserListResult[] = [];
  selectedUser!: IBrandUserListResult;
  rolesList: INameId[] = [];

  permissionsListLookup: IPermissionListResult[] = [];
  permissionsListForListing: IPermissionItemObject[] = [];
  brand_user: UntypedFormControl = new UntypedFormControl();
  subscription!: Subscription;

  constructor(
    private _usersAccessFacade: UsersAccessFacade,
    private _snackbarService: SnackbarService
  ) {}

  ngOnDestroy(): void {
    this.subscription && this.subscription.unsubscribe();
  }

  ngOnInit() {
    this._usersAccessFacade.getPermissionsListLookup$().subscribe({
      next: this._getPermissionsListLookupSuccess.bind(this),
      error: this._error.bind(this),
    });

    this._usersAccessFacade.getBrandUsers$().subscribe({
      next: this._getBrandUsersSuccess.bind(this),
      error: this._error.bind(this),
    });

    this.subscription = this.brand_user.valueChanges.subscribe(() => {
      this.loadAccessRights();
    });
  }

  private _getBrandUsersSuccess(data: IBrandUserListResponse): void {
    if (data) {
      this.usersList = data.results;
      this.brand_user.setValue(this.usersList[0]);
    }
  }

  private _getPermissionsListLookupSuccess(data: IPermissionListResponse): void {
    if (data) {
      this.permissionsListLookup = data.results;
    }
  }

  loadAccessRights() {
    this.permissionsListForListing = this.permissionsListLookup.map((permission: IPermissionListResult) => {
      return {
        name: permission.name,
        uuid: permission.uuid,
        permission_type: permission.permission_type,
        is_active: this.isPermissionActive(permission.name),
      };
    });
  }

  isPermissionActive(name: string): boolean {
    const user_permissions: string[] = this.brand_user.value.custom_permissions;

    return user_permissions.includes(name);
  }

  changePermissionStatus(event: MatSlideToggleChange) {
    const selected_permissions: IPutDeleteBrandUserPermissions = {
      user: this.brand_user.value.uuid,
      custom_permissions: [event.source.id],
    };

    if (event.checked) {
      this._usersAccessFacade.putBrandUserPermissions$(selected_permissions).subscribe({
        next: this._putBrandUserPermissionsSuccess.bind(this),
        error: this._error.bind(this),
      });
    } else {
      this._usersAccessFacade.deleteBrandUserPermissions$(selected_permissions).subscribe({
        next: this._removeBrandUserPermissionsSuccess.bind(this),
        error: this._error.bind(this),
      });
    }
  }

  private _putBrandUserPermissionsSuccess(data: IPutDeleteBrandUserPermissionsResult) {
    this.brand_user.value.custom_permissions = data.custom_permissions;
    this._snackbarService.openTypeSnackbar(`Permission added`, NotificationType.success);
  }

  private _removeBrandUserPermissionsSuccess(data: IPutDeleteBrandUserPermissionsResult) {
    this.brand_user.value.custom_permissions = data.custom_permissions;
    this._snackbarService.openTypeSnackbar(`Permission removed`, NotificationType.success);
  }

  private _error(error: Record<string, string[]>): void {
    Object.values(error).map(err => this._snackbarService.openTypeSnackbar(err[0], NotificationType.error));
  }
}
