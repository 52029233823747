<div class="holder d-flex flex-column">
  <div class="table-holder">
    <table mat-table matSort [dataSource]="dataSource" class="mat-table section-table custom-table">
      <ng-container matColumnDef="name">
        <th mat-header-cell mat-sort-header class="ingredient-name-header" *matHeaderCellDef>
          {{ 'Component Name' | translate }}
        </th>
        <td mat-cell *matCellDef="let element" [ngClass]="{ inactive: !element.is_active }" class="alerts-name">
          <strong>
            {{ element.name }}
          </strong>
        </td>
      </ng-container>
      <ng-container matColumnDef="reference">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ 'Reference' | translate }}
        </th>
        <td mat-cell *matCellDef="let element" class="ingredient_reference">
          {{ element.reference }}
        </td>
      </ng-container>
      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ 'Description' | translate }}
        </th>
        <td mat-cell *matCellDef="let element" class="ingredient_description">
          <div class="overflow">{{ element.description }}</div>
        </td>
      </ng-container>
      <ng-container matColumnDef="site_location">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ 'Site location' | translate }}
        </th>
        <td mat-cell *matCellDef="let element" class="site_lcoation">
          {{ element.manufactory?.country?.name }}
        </td>
      </ng-container>
      <ng-container matColumnDef="actions" class="product-ingredients-action">
        <th mat-header-cell *matHeaderCellDef class="product-ingredients-action">
          <div class="action">{{ 'Actions' | translate }}</div>
        </th>
        <td mat-cell *matCellDef="let element" class="product-ingredients-action">
          <div class="icon-holder">
            <app-custom-icon
              *ngIf="element.is_active"
              [matTooltip]="'Edit component' | translate"
              matTooltipClass="custom-tooltip-center centering"
              matTooltipPosition="above"
              [icon_category]="'files'"
              [icon_name]="'edit'"
              (click)="editIngredientDialog(element)"
            ></app-custom-icon>
            <app-custom-icon
              [matTooltip]="'Deactivate component' | translate"
              matTooltipClass="custom-tooltip-end"
              matTooltipPosition="above"
              [icon_category]="'essential'"
              [icon_name]="'deactivate-red'"
              *ngIf="element.is_active"
              (click)="removeComponent(true, element)"
            ></app-custom-icon>
            <app-custom-icon
              [matTooltip]="'Reactivate component' | translate"
              matTooltipClass="custom-tooltip-end"
              matTooltipPosition="above"
              [icon_category]="'essential'"
              [icon_name]="'activate-green'"
              *ngIf="!element.is_active"
              (click)="restoreComponent(false, element)"
            ></app-custom-icon>
            <app-custom-icon
              [matTooltip]="
                element.cas_no && element.ec_list
                  ? 'Flagged as chemical! \n Update status'
                  : ('Flag component \n as chemical' | translate)
              "
              matTooltipPosition="above"
              matTooltipClass="custom-tooltip-end"
              class="flag-icon cursor-pointer"
              [ngClass]="{ 'is-flagged': element.cas_no && element.ec_list }"
              icon_category="essential"
              icon_name="flag"
              (click)="onFlagAsChemical(element)"
            >
            </app-custom-icon>
          </div>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </div>
  <div class="card-footer">
    <mat-paginator #paginator showFirstLastButtons aria-label="Select page of components"> </mat-paginator>
  </div>
</div>
