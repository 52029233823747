<div class="mat-card-main">
  <div class="vertical-line-preview"></div>
  <mat-card>
    <mat-card-content>
      <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start">
        <mat-tab label="Traceability" class="custom-mat">
          <div *ngFor="let singleClassification of productChain?.types">
            <app-pin-info
              [position]="singleClassification.position"
              text="{{ singleClassification.name }}"
              [color]="singleClassification.color"
              [steps]="singleClassification.steps"
              [type]="singleClassification"
              [expandedStep]="expandedStep"
              (onExpandedArea)="closeOpenSteps($event)"
            ></app-pin-info>
          </div>
        </mat-tab>
        <mat-tab label="Brand Info" bodyClass="custom-mat-tab">
          <div class="pb-4 container map-panel-brand">
            <div class="row">
              <div class="col">
                <div class="brand-description" [innerHTML]="productChain?.brand?.summary"></div>
              </div>
            </div>
            <div class="row">
              <div class="text-center mt-4 col">
                <a class="btn btn-primary">Check the brand’s traceability</a>
              </div>
            </div>
          </div>
        </mat-tab>
      </mat-tab-group>
    </mat-card-content>
  </mat-card>
</div>
