<div class="wrapper">
  <div class="header">
    <div class="address-name">
      <p>{{ address.name }} Details</p>
    </div>

    <div class="close">
      <app-custom-icon [icon_category]="'essential'" [icon_name]="'close-circle'" mat-dialog-close></app-custom-icon>
    </div>
  </div>

  <div class="data-holder">
    <div class="address-holder">
      <div class="data-street">
        <p class="subtitle-street">street line</p>
        <p class="title-street">{{ address.street_line_1 }}</p>
      </div>

      <div class="data">
        <p class="subtitle">city</p>
        <p class="title">{{ address.city }}</p>
      </div>

      <div class="data">
        <p class="subtitle">country</p>
        <p class="title">{{ address.country }}</p>
      </div>
    </div>

    <div class="address-holder">
      <div class="data-street">
        <div class="" [ngClass]="!address.street_line_2 ? 'd-none' : 'd-block'">
          <p class="subtitle-street">street line 2</p>
          <p class="title-street">{{ address.street_line_2 }}</p>
        </div>
      </div>

      <div class="data">
        <div class="" [ngClass]="!address.state ? 'd-none' : 'd-block'">
          <p class="subtitle">state</p>
          <p class="title">{{ address.state }}</p>
        </div>
      </div>

      <div class="data">
        <div class="" [ngClass]="!address.postcode ? 'd-none' : 'd-block'">
          <p class="subtitle">zip code</p>
          <p class="title">{{ address.postcode }}</p>
        </div>
      </div>
    </div>
  </div>
</div>
