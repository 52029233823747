import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AngularMaterialModule } from '@app/material.module';
import { CommonModule } from '@angular/common';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { SharedModule } from '@app/shared/shared.module';
import { UserSettingsComponent } from './user-settings.component';
import { UserSettingsRoutingModule } from './user-settings-routing.module';
import { ManageUserDialogComponent } from './manage-user-dialog/manage-user-dialog.component';
import { ManageProfileDialogComponent } from './manage-profile-dialog/manage-profile-dialog.component';
import { BrandManagementModule } from '@module/brand-management/brand-managment.module';

@NgModule({
  declarations: [UserSettingsComponent, ManageUserDialogComponent, ManageProfileDialogComponent],
  exports: [UserSettingsComponent],
  imports: [
    CommonModule,
    UserSettingsRoutingModule,
    AngularMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    BrandManagementModule,
  ],
  providers: [provideHttpClient(withInterceptorsFromDi())],
})
export class UserSettingsModule {}
