<div class="wrp">
  <div
    class="row mat-dialog-content"
    [ngClass]="{
      row: true,
      'mat-dialog-content': true,
      supplier_unique_identifier: data.fieldName === 'supplier_unique_identifier',
    }"
  >
    <h2 mat-dialog-title>
      {{ title }}
    </h2>

    <!-- LOGO -->
    <app-file-upload
      *ngIf="data.fieldName === 'logo'"
      [placeholder]="'Upload Logo'"
      [iconType]="'add_photo_alternate'"
      (fileData)="saveImageData($event)"
      [status]="fieldControl.status"
      [imageUrl]="fieldControl.value"
    >
    </app-file-upload>
    <!-- ONE  TEXT FIELD -->

    <mat-form-field
      class="col-12"
      class="input-primary textarea-input"
      appearance="outline"
      *ngIf="
        data.fieldName !== 'logo' &&
        data.fieldName !== 'supplier_unique_identifier' &&
        data.fieldName !== 'address' &&
        data.fieldName !== 'description'
      "
    >
      <mat-label>{{ label }}</mat-label>
      <textarea
        id="area"
        matInput
        #editField
        [formControl]="fieldControl"
        [placeholder]="label"
        rows="6"
        appTextareaAutoResize
      ></textarea>
      <mat-error *ngIf="fieldControl.hasError('required')">{{
        'company-information.This field is required' | translate
      }}</mat-error>
      <mat-error *ngIf="fieldControl.errors?.['maxlength']">{{
        'Ensure this field has no more than ' + data.max + ' characters.' | translate
      }}</mat-error>
    </mat-form-field>
    <!-- ONE  TEXT FIELD -->
    <div class="col-12" class="input-primary" appearance="outline" *ngIf="data.fieldName === 'description'">
      <mat-label>{{ label }}</mat-label>
      <!-- <textarea
          id="area"
          matInput
          #editField
          [formControl]="fieldControl"
          [placeholder]="label"
          rows="6"
          appTextareaAutoResize
        ></textarea> -->

      <div class="wrp-rs" [formGroup]="form">
        <app-rs-rte
          formControlName="description"
          [c]="$any(form.controls['description'])"
          [placeholder]="'Enter description' | translate"
        ></app-rs-rte>
      </div>
      <!-- <mat-error *ngIf="fieldControl.hasError('required')">{{
          'company-information.This field is required' | translate
        }}</mat-error>
        <mat-error *ngIf="fieldControl.errors?.['maxlength']">{{
          'Ensure this field has no more than ' + data.max + ' characters.' | translate
        }}</mat-error> -->
    </div>

    <!-- ADDRESS -->
    <form class="addres-holder d-flex flex-column" [formGroup]="form" *ngIf="data.fieldName === 'address'">
      <div class="address-row d-flex">
        <mat-form-field class="input-primary" appearance="outline" floatLabel="auto">
          <mat-label>{{ 'company-information.Enter Country' | translate }}</mat-label>
          <input
            matInput
            [formControlName]="'country'"
            [placeholder]="'company-information.Enter Country' | translate"
            [matAutocomplete]="auto"
          />
          <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
            <mat-option *ngFor="let country of filteredCountries | async" [value]="country">
              {{ country.name }}
            </mat-option>
          </mat-autocomplete>
          <mat-error *ngIf="form.controls['country'].hasError('required')">{{
            'company-information.This field is required' | translate
          }}</mat-error>
          <mat-error *ngIf="form.controls['country'].errors?.['maxlength']">{{
            'Ensure this field has no more than ' + data.max + ' characters.' | translate
          }}</mat-error>
        </mat-form-field>
        <mat-form-field class="input-primary" appearance="outline" floatLabel="auto">
          <mat-label>{{ 'company-information.Enter Region' | translate }}</mat-label>
          <input matInput [formControlName]="'region'" [placeholder]="'company-information.Enter Region' | translate" />
          <mat-error *ngIf="form.controls['region'].hasError('required')">{{
            'company-information.This field is required' | translate
          }}</mat-error>
          <mat-error *ngIf="form.controls['region'].errors?.['maxlength']">{{
            'Ensure this field has no more than ' + data.max + ' characters.' | translate
          }}</mat-error>
        </mat-form-field>
      </div>

      <div class="address-row d-flex">
        <mat-form-field class="input-primary full-width" appearance="outline" floatLabel="auto">
          <mat-label>{{ 'company-information.Enter City' | translate }}</mat-label>
          <input matInput [formControlName]="'city'" [placeholder]="'company-information.Enter City' | translate" />
          <mat-error *ngIf="form.controls['city'].hasError('required')">{{
            'company-information.This field is required' | translate
          }}</mat-error>
          <mat-error *ngIf="form.controls['city'].errors?.['maxlength']">{{
            'Ensure this field has no more than ' + data.max + ' characters.' | translate
          }}</mat-error>
        </mat-form-field>
        <mat-form-field class="input-primary full-width" appearance="outline" floatLabel="auto">
          <mat-label>{{ 'company-information.Enter Address' | translate }}</mat-label>
          <input
            matInput
            [formControlName]="'address'"
            [placeholder]="'company-information.Enter Address' | translate"
          />
          <mat-error *ngIf="form.controls['address'].hasError('required')">{{
            'company-information.This field is required' | translate
          }}</mat-error>
          <mat-error *ngIf="form.controls['address'].errors?.['maxlength']">{{
            'Ensure this field has no more than ' + data.max + ' characters.' | translate
          }}</mat-error>
        </mat-form-field>
      </div>
    </form>

    <!-- IDENTIFIER -->
    <form class="full-width row" [formGroup]="form" *ngIf="data.fieldName === 'supplier_unique_identifier'">
      <div class="col-12 p-0 mb-3">
        {{
          'company-information.Here you can add the unique identifier of your company. Example: CHE-112.674.306. Please follow the official format of the number.'
            | translate
        }}
      </div>
      <div class="col-12 p-0">
        <mat-form-field class="input-primary full-width" appearance="outline" floatLabel="auto">
          <mat-label>{{ 'company-information.Enter identifier number' | translate }}</mat-label>
          <input
            matInput
            [formControlName]="'supplier_unique_identifier'"
            [placeholder]="'company-information.Enter identifier number' | translate"
          />
          <mat-error *ngIf="form.controls['supplier_unique_identifier'].hasError('required')">{{
            'company-information.This field is required' | translate
          }}</mat-error>
          <mat-error *ngIf="form.controls['supplier_unique_identifier'].errors?.['maxlength']">{{
            'Ensure this field has no more than ' + data.max + ' characters.' | translate
          }}</mat-error>
        </mat-form-field>
      </div>
      <div class="col-12 p-0 mb-3">
        {{
          'company-information.Here you specify if the unique identifier of your company is a VAT number, Duns number, E.I.N number or other type. Example: VAT number'
            | translate
        }}
      </div>
      <div class="col-12 p-0">
        <mat-form-field class="input-primary full-width" appearance="outline" floatLabel="auto">
          <mat-label>{{ 'company-information.Enter identifier type' | translate }}</mat-label>
          <input
            matInput
            [formControlName]="'unique_identifier_type'"
            [placeholder]="'company-information.Enter identifier type' | translate"
          />
          <mat-error *ngIf="form.controls['unique_identifier_type'].hasError('required')">{{
            'company-information.This field is required' | translate
          }}</mat-error>
          <mat-error *ngIf="form.controls['unique_identifier_type'].errors?.['maxlength']">{{
            'Ensure this field has no more than ' + data.max + ' characters.' | translate
          }}</mat-error>
        </mat-form-field>
      </div>
    </form>
  </div>

  <mat-dialog-actions align="end">
    <app-rounded-button
      size="sm"
      color="tetriary-link underlined"
      button_text="{{ 'company-information.Cancel' | translate }}"
      mat-dialog-close
    >
    </app-rounded-button>
    <app-rounded-button
      class="update-btn"
      size="sm"
      color="turquoise"
      button_text="{{ 'company-information.Update' | translate }}"
      [disabled]="
        (fieldControl && (fieldControl.invalid || fieldControl.pristine)) || (form && (form.invalid || form.pristine))
      "
      (click)="
        ((fieldControl && fieldControl.valid && fieldControl.dirty) || (form && form.valid && form.dirty)) && onSave()
      "
    >
    </app-rounded-button>
  </mat-dialog-actions>
</div>
