import { RouterModule, Routes } from '@angular/router';

import { CreateProductNewComponent } from '../register/complete-register-steps/create-product-new/create-product-new.component';
import { NgModule } from '@angular/core';
import { ProductsComponent } from './products.component';
import { ProductsListComponent } from './products-list/products-list.component';
import { ProjectsDashboardModule } from '../dashboard/projects-dashboard/projects-dashboard.module';

const routes: Routes = [
  { path: '', component: ProductsComponent },
  { path: 'list', component: ProductsListComponent },
  { path: 'create-product', component: CreateProductNewComponent },
  { path: 'clone-product/:id', component: CreateProductNewComponent },
  {
    path: 'projects',
    loadChildren: () => ProjectsDashboardModule,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ProductsRoutingModule {}
