import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { NotificationType, Storage, USER } from '@app/core/constants';
import { combineLatest, take } from 'rxjs';

import { BrandsFacade } from '@app/core/facade/brands.facade';
import { DataStorageService } from '@app/core/service/data-localstorage.service';
import { IBrandListResult } from '@app/core/interface/brands.interface';
import { IProductResponse } from '@app/core/interface/products.interface';
import { SnackbarService } from '@app/core/service/snackbar.service';
import { CustomPublicationsFacade } from './custom-publications/custom-publications.facade';
import { ProductsFacade } from '@app/core/facade/products.facade';
import { ICustomPublication } from './custom-publications/custom-publications.models';
import { ProductChainFacade } from '@app/core/facade/productchain.facade';
import { IProductChainResult } from '@app/core/interface/productchain.interface';

@Component({
  selector: 'app-publication',
  templateUrl: './publication.component.html',
  styleUrls: ['./publication.component.scss'],
})
export class PublicationComponent implements OnInit {
  selectedStepIndex = 0;
  selectedBrand!: IBrandListResult;
  publicationData!: ICustomPublication;
  product!: IProductResponse;
  productChainUUID!: string | null;
  constructor(
    private _brandsFacade: BrandsFacade,
    private _dataStorageService: DataStorageService,
    private _snackbarService: SnackbarService,
    private _productChanin: ProductsFacade,
    private _customPublicationFacade: CustomPublicationsFacade,
    private _productChainFacade: ProductChainFacade,
    private _activatedRoute: ActivatedRoute,
    private _router: Router
  ) {}

  ngOnInit(): void {
    const storedUser = this._dataStorageService.get(USER, Storage.local)
      ? this._dataStorageService.get(USER, Storage.local)
      : this._dataStorageService.get(USER, Storage.session);

    this._brandsFacade.getBrands$().subscribe({
      next: data =>
        (this.selectedBrand =
          data.results.find(brand => brand.uuid === JSON.parse(storedUser).brand) ?? data.results[0]),
      error: this._error.bind(this),
    });

    this.getData();
  }

  getData(): void {
    combineLatest([this._activatedRoute.params.pipe(take(1)), this._activatedRoute.queryParams.pipe(take(1))])
      .pipe(take(1))
      .subscribe(([params, queryParams]) => {
        if (params['id'])
          this._customPublicationFacade.getPublication$(params['id']).subscribe({
            next: res => {
              this.publicationData = res;
              this._productChanin.getProduct$(queryParams['product']).subscribe({
                next: product => {
                  this.product = product;
                },
              });
            },
            error: this._error.bind(this),
          });
      });
  }

  setupChain(): void {
    const productChainUuid = this._activatedRoute.snapshot.paramMap.get('id');

    this._router.navigate([`publish/${productChainUuid}/setup-chain`], {
      queryParams: { product: this._activatedRoute.snapshot.queryParamMap.get('product') },
    });
  }

  publish(): void {
    // const brandSite = sessionStorage.getItem('brandSite') ?? this.selectedBrand.external_url;
    // if (brandSite) {
    //   window.open(brandSite, '_blank');
    // }
    const productChainUuid = this._activatedRoute.snapshot.paramMap.get('id');
    this.productChainUUID = productChainUuid;
    if (productChainUuid)
      this._productChainFacade.publishProductChain$(productChainUuid).subscribe({
        next: this.publishSuccess.bind(this), // Pass response to publishSuccess
        error: this._error.bind(this),
      });
  }
  publishSuccess(response: IProductChainResult) {
    this._router.navigate([`/publish/${this.productChainUUID}/custom-publications/finished`], {
      state: { product: this.product, reference: response?.reference },
    });
  }
  previewProduct(): void {
    const productChainUuid = this._activatedRoute.snapshot.paramMap.get('id');

    this._router.navigate([`publish/${productChainUuid}/preview-product`], {
      queryParams: { product: this._activatedRoute.snapshot.queryParamMap.get('product') },
    });
  }

  previewBrand(): void {
    const productChainUuid = this._activatedRoute.snapshot.paramMap.get('id');

    this._router.navigate([`publish/${productChainUuid}/preview-brand`], {
      queryParams: { brand: this.selectedBrand.uuid },
    });
  }

  previewPublication(): void {
    const productChainUuid = this._activatedRoute.snapshot.paramMap.get('id');

    this._router.navigate([`publish/${productChainUuid}/preview-publication`], {
      queryParams: { brand: this.selectedBrand.uuid, product: this.product.uuid },
    });
  }

  private _error(error: Record<string, string[]>): void {
    Object.values(error).map(err => this._snackbarService.openTypeSnackbar(err[0], NotificationType.error));
  }
}
