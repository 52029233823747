import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomerSuccessManagerComponent } from './customer-success-manager.component';
import { CustomerSuccessManagerRoutingModule } from './customer-success-manager-routing';
import { CustomerSuccessManagementFacade } from '@app/core/facade/customer-success-manager.facade';

@NgModule({
  declarations: [CustomerSuccessManagerComponent],
  imports: [CommonModule, CustomerSuccessManagerRoutingModule],
  providers: [CustomerSuccessManagementFacade],
})
export class CustomerSuccessManagerModule {}
