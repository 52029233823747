<div class="sec-recent-tasks-wrap row">
  <div class="task-header col-12">
    <h6>{{ 'Your Recent Tasks' | translate }}</h6>
    <app-rounded-button
      color="secondary"
      class="btn"
      [button_text]="'See All Tasks' | translate"
      [icon_category]="'essential'"
      [icon_name]="'tasks'"
      [icon_right]="true"
      [routerLink]="'/dashboard/tasks/all'"
      [size]="'md'"
    >
    </app-rounded-button>
  </div>
  <div class="recent-tasks-wrap col-12">
    <div *ngFor="let task of tasks" class="list">
      <div class="card-wr">
        <app-task-card [task]="task"></app-task-card>
      </div>
    </div>
  </div>
</div>
