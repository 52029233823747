import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { IBrandUserResult, IBrandUsersResponse, IDeleteBrandUser } from '@app/core/interface/brands.interface';
import { NotificationType, infoDialogHeight, infoDialogWidth } from '@app/core/constants';

import { BrandManagementGroup } from '../brand-management.group';
import { BrandsFacade } from '@app/core/facade/brands.facade';
import { InfoDialogComponent } from '@app/shared/components/info-dialog/info-dialog.component';
import { InviteBrandUserComponent } from '../invite-brand-user/invite-brand-user.component';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { SnackbarService } from '@app/core/service/snackbar.service';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-brand-users',
  templateUrl: './brand-users.component.html',
  styleUrls: ['./brand-users.component.scss'],
  providers: [BrandsFacade, BrandManagementGroup],
})
export class BrandUsersComponent implements OnInit, OnChanges {
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @Input() newInvitedUser!: IBrandUserResult;
  displayedColumns: string[] = ['username', 'user_email', 'role', 'admin', 'actions'];
  @Input() dataSource = new MatTableDataSource<IBrandUserResult>();
  inviteUserForm: UntypedFormGroup;
  brandUsers: IBrandUserResult[] = [];
  deleteUuid: string;

  constructor(
    private _group: BrandManagementGroup,
    private _snackbarService: SnackbarService,
    private _brandsFacade: BrandsFacade,
    public dialog: MatDialog
  ) {
    this.inviteUserForm = this._group.inviteBrandUser;
    this.deleteUuid = '';
  }

  ngOnInit() {
    this._brandsFacade.getBrandUsers$().subscribe({
      next: this._setBrandUsers.bind(this),
      error: this._error.bind(this),
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['newInvitedUser'].currentValue) {
      const newInvitedUser = changes['newInvitedUser'].currentValue;
      this._refetchUsers(newInvitedUser);
    }
  }

  deleteBrandUser(uuid: string, email: string): void {
    this.deleteUuid = uuid;
    const dialogRef = this.dialog.open(InfoDialogComponent, {
      width: infoDialogWidth,
      height: infoDialogHeight,
      data: {
        infoText: `Are you sure you want to delete brand user: ${email}?`,
        confirmationText: 'Please Confirm',
        btnText: 'Yes, Remove',
        type: 'warning',
        text: 'warning-text',
      },
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this._brandsFacade.deleteBrandUser$(uuid).subscribe({
          next: this._deleteUser.bind(this),
          error: this._error.bind(this),
        });
      }
    });
  }

  resendInvite(email: string): void {
    this._brandsFacade.resendInvite$({ email: email }).subscribe({
      next: this._showModal.bind(this),
      error: this._error.bind(this),
    });
  }

  private _showModal(): void {
    this.dialog.open(InviteBrandUserComponent, {
      width: '1160px',
      height: '90vh',
      panelClass: 'padding-0',
    });
  }

  private _setBrandUsers(data: IBrandUsersResponse): void {
    this.brandUsers = data.results;
    this.dataSource.data = data.results;
  }

  private _refetchUsers(data: IBrandUserResult): void {
    this.brandUsers = [...this.brandUsers, data];
    this.dataSource.data = this.brandUsers;
  }

  private _deleteUser(data: IDeleteBrandUser): void {
    this.brandUsers = this.brandUsers.filter(user => user.uuid !== this.deleteUuid);
    this.dataSource.data = this.brandUsers;

    this._snackbarService.openTypeSnackbar(data.detail, NotificationType.success);

    this.deleteUuid = '';
  }

  private _error(error: Record<string, string[]>): void {
    Object.values(error).map(err => this._snackbarService.openTypeSnackbar(err[0], NotificationType.error));
  }
}
