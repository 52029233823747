import { Component, EventEmitter, Output, Inject, ViewChild } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSelect, MatSelectChange } from '@angular/material/select';
import { IngredientFacade } from '@app/core/facade/ingredient.facade';
import { ManufactoriesFacade } from '@app/core/facade/manufactories.facade';
import { ProductsFacade } from '@app/core/facade/products.facade';
import { SuppliersFacade } from '@app/core/facade/suppliers.facade';
import { IIngredient, IIngredientsResponse } from '@app/core/interface/ingredient.interface';
import { IManufactory, INameUuid } from '@app/core/interface/manufactories.interface';
import { IProduct, IProductResponse } from '@app/core/interface/products.interface';
import { IFactory, ISupplier, ISupplierResponse } from '@app/core/interface/suppliers.interface';
import { CreateSupplierComponent } from '@app/module/dashboard/supplier/create-supplier/create-supplier.component';
import { CreateAddComponentComponent } from './create-add-component/create-add-component.component';
import { NotificationType } from '@app/core/constants';
import { SnackbarService } from '@app/core/service/snackbar.service';

@Component({
  selector: 'app-add-ingredient',
  templateUrl: './add-ingredient.component.html',
  styleUrls: ['./add-ingredient.component.scss'],
  providers: [SuppliersFacade, ManufactoriesFacade, IngredientFacade, ProductsFacade],
})
export class AddIngredientComponent {
  @Output() toggleAdd = new EventEmitter<boolean>(false);
  @ViewChild('createAddComponent') createAddComponent!: CreateAddComponentComponent;
  @ViewChild('sitesDropdownElement') sitesDropdownElement!: MatSelect;
  mode = 'supplier';
  isCreateComponent = false;
  isCreateSupplierAndSite = false;
  selectedIngredient = {};
  selectedSupplier!: ISupplier; // = {};
  suppliers: Array<ISupplier> = [];
  sites: Array<IFactory> = [];
  components: Array<INameUuid> = [];
  noSupplierComponents: Array<IIngredient> = [];
  opened = false;
  suppliersDropdown = new UntypedFormControl(null);
  sitesDropdown = new UntypedFormControl({ value: null, disabled: true });
  componentsDropdown = new UntypedFormControl(null, Validators.required);
  noSupplierComponentsDropdown = new UntypedFormControl(null, Validators.required);
  isButtonDisabled = true;
  isCreateSupplier = true;

  constructor(
    private _dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public product: IProduct | IProductResponse,
    public dialogRef: MatDialogRef<AddIngredientComponent>,
    private _suppliersFacade: SuppliersFacade,
    private _manufactoriesFacade: ManufactoriesFacade,
    private _productsFacade: ProductsFacade,
    private _ingredientFacade: IngredientFacade,
    private _snackbarService: SnackbarService
  ) {
    this.suppliers = product.suppliers;
    dialogRef.disableClose = true;
  }

  onButtonToggleChange(event: MatButtonToggleChange) {
    this.mode = event.value;

    if (event.value === 'no-supplier') {
      this.getNoSupplierComponents();
    } else {
      if (!this.suppliersDropdown.value && !this.sitesDropdown.value) {
        this.isCreateComponent = false;
        this.isCreateSupplierAndSite = false;
      }
    }
  }

  filterItem(event: Event) {
    const value = (<HTMLInputElement>event.target).value;
    if (!value) {
      this.suppliers = this.product.suppliers;
    } else {
      this.suppliers = this.product.suppliers.filter(el => el.name.toLowerCase().includes(value.toLowerCase()));
    }
  }

  displayFn(supplier: ISupplier | null): string {
    return supplier?.name ?? '';
  }

  openOrClosePanel(evt: Event, trigger: MatAutocompleteTrigger): void {
    evt.stopPropagation();
    if (trigger.panelOpen) {
      trigger.closePanel();
      this.opened = false;
    } else {
      trigger.openPanel();
      this.opened = true;
    }
  }

  onSupplierSelected(event: MatAutocompleteSelectedEvent) {
    const selectedUuid: string = (event.option.value as ISupplier).uuid;
    this._suppliersFacade.getSupplier$(selectedUuid).subscribe({
      next: this._setSupplierResponse.bind(this),
      error: this._error.bind(this),
    });
  }

  onSupplierSiteSelected(event: MatSelectChange) {
    const selectedUuid: string = event.value.uuid;

    this._manufactoriesFacade.getSupplierSiteDetails$(selectedUuid).subscribe({
      next: this._setSupplierSiteResponse.bind(this),
      error: this._error.bind(this),
    });
  }

  onAddComponent() {
    this._productsFacade
      .addComponentToProduct$(
        this.product.uuid,
        this.mode === 'supplier' ? this.componentsDropdown.value : this.noSupplierComponentsDropdown.value
      )
      .subscribe({
        next: this._addComponentSuccess.bind(this),
        error: this._error.bind(this),
      });
  }

  /**
   * On create supplier we are oppening CreateSupplier Dialog
   */
  createSupplierDialog(): void {
    const dialogRef = this._dialog.open(CreateSupplierComponent, { panelClass: 'top-padding-0' });
    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        // this.getSuppliers();
      }
    });
  }

  getNoSupplierComponents() {
    this._ingredientFacade.getIngredients$().subscribe({
      next: this._getNoSupplierComponentsSuccess.bind(this),
      error: this._error.bind(this),
    });
  }

  onAddedSupplier(supplier: ISupplier) {
    this.suppliers.push(supplier);
    this.suppliersDropdown.setValue(supplier);
  }

  onCreatedSite(site: IManufactory | IManufactory[] | null) {
    if (site) {
      if (this.isCreateSupplier) {
        this.sites = site as IFactory[];
      } else {
        this.sites.push(site as IFactory);
      }
      this.isCreateSupplierAndSite = false;
      setTimeout(() => {
        this.sitesDropdown.enable();
        this.sitesDropdownElement.open();
      });
    } else {
      this.isCreateSupplierAndSite = false;
    }
  }

  private _setSupplierResponse(response: ISupplierResponse) {
    this.sites = response.manufactories as IFactory[];
    this.sitesDropdown.enable();
  }

  private _setSupplierSiteResponse(response: IManufactory) {
    this.components = response.ingredients as INameUuid[];
  }

  private _addComponentSuccess() {
    this._snackbarService.openTypeSnackbar(`Component successfuly added!`, NotificationType.success);
    this.dialogRef.close(true);
  }

  private _getNoSupplierComponentsSuccess(response: IIngredientsResponse) {
    this.noSupplierComponents = response.results.filter(ingredient => ingredient.supplier === null);
  }

  private _error(error: Record<string, string[]>): void {
    Object.values(error).map(err => this._snackbarService.openTypeSnackbar(err[0], NotificationType.error));
  }
}
