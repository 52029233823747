import { CanActivate, Router, UrlTree } from '@angular/router';

import { DataAuthService } from '@app/core/service/data-auth.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { NotificationType } from '../constants';
import { SnackbarService } from '../service/snackbar.service';

@Injectable({
  providedIn: 'root',
})
export class LoginGuard implements CanActivate {
  constructor(
    private _dataAuthService: DataAuthService,
    private _snackbarService: SnackbarService,
    private _router: Router
  ) {}

  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this._dataAuthService.user) {
      this._snackbarService.openTypeSnackbar('You are already logged in!', NotificationType.success);
      this._router.navigate(['/dashboard']);
      return false;
    }
    return true;
  }
}
