import {
  IResetPasswordConfirm,
  IResetPasswordConfirmResponse,
  IResetPasswordResponse,
  IUserConsent,
  IUserUuid,
} from '../interface/forgot-password.interface';

import { AccountsService } from '@service/accounts.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { ObservableInput } from 'rxjs/internal/types';
import { catchError } from 'rxjs/internal/operators/catchError';
import { concatMap } from 'rxjs';
import { generateFormData } from '../utils/generate-formData';
import { take } from 'rxjs/internal/operators/take';
import { throwError } from 'rxjs/internal/observable/throwError';

@Injectable({
  providedIn: 'root',
})
export class ForgotPasswordFacade {
  constructor(private _accountsService: AccountsService) {}

  resetPassword$(email: string): Observable<IResetPasswordResponse> {
    const formData: FormData = new FormData();
    formData.append('email', email);

    return this._accountsService.resetPassword$(formData).pipe(
      take(1),
      catchError((err): ObservableInput<IResetPasswordResponse> => throwError(() => err.error))
    );
  }

  resetPasswordConfirm$(data: IResetPasswordConfirm): Observable<IResetPasswordConfirmResponse> {
    const formData = generateFormData(data);

    return this._accountsService.resetPasswordConfirm(formData).pipe(
      take(1),
      catchError((err): Observable<IResetPasswordConfirmResponse> => throwError(() => err.error))
    );
  }

  getUserUuid$(email: string): Observable<IUserUuid> {
    return this._accountsService.getUserUuid$(email).pipe(
      take(1),
      catchError((err): Observable<IUserUuid> => throwError(() => err.error))
    );
  }

  setUserConsent$(data: { is_consent_apporved: string }, uuid: string): Observable<IUserConsent> {
    const formData = generateFormData(data);

    return this._accountsService.setUserConsent$(formData, uuid).pipe(
      take(1),
      catchError((err): Observable<IUserConsent> => throwError(() => err.error))
    );
  }

  setUserConsentAndResetPassword$(
    data: { is_consent_apporved: string },
    uuid: string,
    resetData: IResetPasswordConfirm
  ): Observable<IResetPasswordConfirmResponse> {
    const formDataForConsent = generateFormData(data);
    const formDataForResetPassword = generateFormData(resetData);

    return this._accountsService.setUserConsent$(formDataForConsent, uuid).pipe(
      take(1),
      concatMap(() => {
        return this._accountsService.resetPasswordConfirm(formDataForResetPassword).pipe(take(1));
      }),
      catchError((err): Observable<IResetPasswordConfirmResponse> => throwError(() => err.error))
    );
  }
}
