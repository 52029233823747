import { HttpClient } from '@angular/common/http';
import { IPaymentChargebeePaymentResponse } from '../interface/purchase.interface';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class PurchaseService {
  /**
   * Private variables
   */
  private apiUrl = environment.api;

  constructor(private _http: HttpClient) {}

  /**
   * Function to get payment details
   */
  createPayment$(data: FormData): Observable<IPaymentChargebeePaymentResponse> {
    return this._http.post<IPaymentChargebeePaymentResponse>(`${this.apiUrl}/chargebee/getuserinfo/`, data);
  }
}
