import { Component } from '@angular/core';
import { environment } from './../../../../environments/environment';

@Component({
  selector: 'app-product-chain-dashboard',
  templateUrl: './product-chain-dashboard.component.html',
  styleUrls: ['./product-chain-dashboard.component.scss'],
})
export class ProductChainDashboardComponent {
  environment = environment;
}
