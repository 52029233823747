<div class="d-wrap">
  <div class="digital-sheet-header">
    <div class="d-tit">
      {{ 'Review Digital Product Sheet' | translate }}
    </div>
    <div class="close">
      <app-custom-icon [icon_category]="'essential'" [icon_name]="'close-circle'" mat-dialog-close></app-custom-icon>
    </div>
  </div>
  <div class="content">
    <div class="cont-top">
      <img
        class="pr-logo"
        [src]="
          dialogData.product?.image
            ? dialogData.product?.image
            : '../../../../../assets/images/upload-placeholder-blue.svg'
        "
        alt="logo"
      />
      <div class="top-txt">
        {{ dialogData.product.name }}
      </div>
    </div>
    <hr />
    <div class="cont-msg" #infoMsg>
      <div class="cont-txt-wrp">
        <img
          src="../../../../../assets/images/symbol-blue.svg"
          alt="Symbol"
          height="45"
          width="45"
          class="align-mid-p"
        />
        <div class="cont-msg-txt">
          {{ 'Please review how the Digital Product Sheet will be presented to the consumer.' | translate }}
        </div>
      </div>
      <app-custom-icon
        [icon_category]="'essential'"
        [icon_name]="'close-circle'"
        (click)="infoMsg.style.display = 'none'"
      ></app-custom-icon>
    </div>
    <!-- STEP 1 -->
    <div class="step step-1">
      <div class="step-head">
        <div class="num-wrap">1</div>
        <div class="tit">
          {{ 'Type of product' | translate }}
        </div>
      </div>
      <div class="step-cont">
        <app-custom-icon [icon_category]="'essential'" [icon_name]="'tick-circle'"></app-custom-icon>
        <div class="step-cont-txt">
          {{ displaySheet?.productType ?? '' | translate }}
        </div>
      </div>
    </div>
    <hr />
    <!-- STEP 2 -->
    <div class="step step-2">
      <div class="step-head">
        <div class="num-wrap">2</div>
        <div class="tit">
          {{ 'Traceability' | translate }}
        </div>
      </div>
      <div class="row pr-wrp">
        <div class="col-1 ts">
          <app-custom-icon [icon_category]="'location'" [icon_name]="'routing-2'"></app-custom-icon>
        </div>
        <p class="col-11 presence step-header">{{ 'Traceability Steps' | translate }}</p>
        <div class="step-cont block">
          <div class="step-cont-txtt">
            <div class="row" *ngIf="displaySheet?.traceability_steps?.step_one_type">
              <div class="ste-wr col-5">
                <div class="data-wr">
                  <div class="col-12 ic-val-wrap">
                    <app-custom-icon [icon_category]="'essential'" [icon_name]="'tick-circle'"></app-custom-icon>
                    <div class="step-name">
                      {{ displaySheet?.traceability_steps?.step_one_type ?? '' | translate }}
                    </div>
                  </div>
                  <div class="col-12 ic-val-wrap locat">
                    <app-custom-icon [icon_category]="'location'" [icon_name]="'location'"></app-custom-icon>
                    <div class="step-name">
                      {{ displaySheet?.traceability_steps?.step_one_country ?? '' | translate }}
                    </div>
                  </div>
                </div>
                <div class="step-line"></div>
              </div>
              <div class="ste-wr col-5">
                <div class="data-wr">
                  <div class="col-12 ic-val-wrap">
                    <app-custom-icon [icon_category]="'essential'" [icon_name]="'tick-circle'"></app-custom-icon>
                    <div class="step-name">
                      {{ displaySheet?.traceability_steps?.step_two_type ?? '' | translate }}
                    </div>
                  </div>
                  <div class="col-12 ic-val-wrap locat">
                    <app-custom-icon [icon_category]="'location'" [icon_name]="'location'"></app-custom-icon>
                    <div class="step-name">
                      {{ displaySheet?.traceability_steps?.step_two_country ?? '' | translate }}
                    </div>
                  </div>
                </div>
                <div class="step-line"></div>
              </div>
              <div class="col-2 last">
                <div class="data-wr">
                  <div class="col-12 ic-val-wrap">
                    <app-custom-icon [icon_category]="'essential'" [icon_name]="'tick-circle'"></app-custom-icon>
                    <div class="step-name">
                      {{ displaySheet?.traceability_steps?.step_three_type ?? '' | translate }}
                    </div>
                  </div>
                  <div class="col-12 ic-val-wrap locat">
                    <app-custom-icon [icon_category]="'location'" [icon_name]="'location'"></app-custom-icon>
                    <div class="step-name">
                      {{ displaySheet?.traceability_steps?.step_three_country ?? '' | translate }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <hr />
    <!-- STEP 3 -->
    <div class="step step-3" *ngIf="displaySheet?.is_recycled_materials_display || displaySheet?.is_recycled_material">
      <div class="step-head">
        <div class="num-wrap">3</div>
        <div class="tit">
          {{ 'Recycled materials used' | translate }}
        </div>
      </div>
      <div class="step-cont">
        <app-custom-icon [icon_category]="'essential'" [icon_name]="'tick-circle'"></app-custom-icon>
        <div class="step-cont-txt">
          {{
            (displaySheet?.is_recycled_material
              ? displaySheet.recycledMaterialPercentage + '% recycled material in this product'
              : 'There are no recycled
          materials used in this product'
            ) | translate
          }}
        </div>
        <!-- <div class="percentage" *ngIf="displaySheet?.is_recycled_material">
          {{ displaySheet.recycledMaterialPercentage }} {{ '% recycled material in this product' }}
        </div> -->
      </div>
    </div>
    <hr *ngIf="displaySheet?.is_recycled_materials_display || displaySheet?.is_recycled_material" />
    <!-- STEP 4 -->
    <div class="step step-4">
      <div class="step-head">
        <div class="num-wrap">4</div>
        <div class="tit">
          {{ 'Presence of plastic microfibers & hazardous substances' | translate }}
        </div>
      </div>
      <div class="row pr-wrp">
        <div class="col-1 pl">
          <img />
        </div>
        <p class="col-11 presence step-header">{{ 'Presence of plastic microfibers' | translate }}</p>
        <div class="step-cont">
          <app-custom-icon [icon_category]="'essential'" [icon_name]="'tick-circle'"></app-custom-icon>
          <div class="step-cont-txt">
            {{
              (displaySheet?.is_plastic_microfibers
                ? 'Releases plastic microfibers into the environment during
            washing.'
                : 'Does not release plastic microfibers into the environment during washing.'
              ) | translate
            }}
          </div>
        </div>
      </div>
      <div class="row pr-wrp">
        <div class="col-1 ha">
          <img />
        </div>
        <div class="col-11 presence">
          <div class="header-wrp">
            <p class="step-header">{{ 'Presence of hazardous substances' | translate }}</p>
          </div>
        </div>
        <div class="step-cont">
          <app-custom-icon [icon_category]="'essential'" [icon_name]="'tick-circle'"></app-custom-icon>
          <div class="step-cont-txt">
            {{
              (displaySheet?.is_hazardous_substance
                ? 'The product contains one or more substance(s) of very high concern'
                : ' The product does not contain any substance(s) of very high concern'
              ) | translate
            }}
          </div>
        </div>
        <div class="substance" *ngIf="displaySheet?.hazardous_substances">
          <ul *ngIf="displaySheet?.hazardous_substances?.length">
            <li class="substance" *ngFor="let substance of displaySheet?.hazardous_substances">
              {{ substance }}
            </li>
          </ul>
        </div>
      </div>
    </div>
    <hr />
    <!-- STEP 5 -->
    <div class="step step-5">
      <div class="step-head">
        <div class="num-wrap">5</div>
        <div class="tit">
          {{ 'Recyclability of the packaging' | translate }}
        </div>
      </div>
      <div class="step-cont row">
        <div class="pack col-12" *ngFor="let pack of displaySheet?.packaging; let i = index">
          <div class="pack-tit">{{ 'Packaging' | translate }}{{ ' ' }}{{ i + 1 }}</div>
          <div class="pack-name">
            {{ pack.name }}
          </div>
          <div class="pack-tit">
            {{ 'Packaging Type:' | translate }}
          </div>
          <div class="pack-type">
            {{ pack.packageType }}
          </div>
          <div class="recyclability row" *ngIf="pack.is_recyclable_display || pack.is_recyclable">
            <div class="col-1 pack-ic">
              <app-custom-icon [icon_category]="'essential'" [icon_name]="'recycle'"></app-custom-icon>
            </div>
            <div class="pack-step-tit col-11">
              {{ 'Recyclability of the packaging' | translate }}
            </div>
            <div class="pack-step-cont">
              <div class="col-12 ic-val-wrap">
                <app-custom-icon [icon_category]="'essential'" [icon_name]="'tick-circle'"></app-custom-icon>
                <div class="step-namee">
                  {{ pack.packageRecyclability | translate }}
                </div>
              </div>
            </div>
          </div>
          <div class="recycled row" *ngIf="pack.is_recycled_materials_display || pack.is_recycled_materials">
            <div class="col-1 pack-ic">
              <app-custom-icon [icon_category]="'essential'" [icon_name]="'recycled'"></app-custom-icon>
            </div>
            <div class="pack-step-tit col-11">
              {{ 'Packaging' | translate }}
            </div>
            <div class="col-12 ic-val-wrap">
              <app-custom-icon [icon_category]="'essential'" [icon_name]="'tick-circle'"></app-custom-icon>
              <div class="step-namee">
                {{
                  (pack.is_recycled_materials
                    ? 'There are recycled materials in the packaging'
                    : ' Packaging does not
                contain any recycled materials '
                  ) | translate
                }}
              </div>
            </div>
          </div>
          <div class="hazardous row">
            <div class="col-1 pack-ic">
              <app-custom-icon [icon_category]="'essential'" [icon_name]="'poison'"></app-custom-icon>
            </div>
            <div class="pack-step-tit col-11">
              {{ 'Hazardous Substances' | translate }}
            </div>
            <div class="col-12 ic-val-wrap">
              <app-custom-icon [icon_category]="'essential'" [icon_name]="'tick-circle'"></app-custom-icon>
              <div class="step-namee">
                {{
                  (pack.hazardous_substances.length
                    ? ' The packaging contains substance(s) of very high concern '
                    : '
                The packaging does not contain any substance(s) of very high concern'
                  ) | translate
                }}
              </div>
            </div>
            <ul *ngIf="pack.hazardous_substances?.length">
              <li class="substance" *ngFor="let substance of pack.hazardous_substances">
                {{ substance }}
              </li>
            </ul>
            <hr class="shortt" *ngIf="displaySheet?.packaging?.length && displaySheet.packaging.length - 1 !== i" />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
