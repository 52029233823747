import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NotificationType } from '@app/core/constants';
import { ClusterFacade } from '@app/core/facade/cluster.facade';
import { ICluster } from '@app/core/interface/cluster.interface';
import { SnackbarService } from '@app/core/service/snackbar.service';

@Component({
  selector: 'app-remove-cluster-confirmation',
  templateUrl: './remove-cluster-confirmation.component.html',
  styleUrls: ['./remove-cluster-confirmation.component.scss'],
  providers: [ClusterFacade],
})
export class RemoveClusterConfirmationComponent {
  uuid!: string;
  name!: string;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { uuid: string; name: string },
    public dialogRef: MatDialogRef<RemoveClusterConfirmationComponent>,
    private _facade: ClusterFacade,
    private _snackbarService: SnackbarService
  ) {
    this.uuid = data.uuid;
    this.name = data.name;
  }

  deleteCluster(): void {
    this._facade.deleteCluster$(this.uuid).subscribe({
      next: this._deleteClusterSuccess.bind(this),
      error: this._deleteClusterError.bind(this),
    });
  }

  private _deleteClusterSuccess(): void {
    this.dialogRef.close(true);
  }

  private _deleteClusterError(error: ICluster): void {
    Object.values(error).map(err => this._snackbarService.openTypeSnackbar(err[0], NotificationType.error));
  }
}
