import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ISuppChainStep, ISuppManufactory } from '@app/core/interface/supp.interface';

import { IManufactory } from '@app/core/interface/manufactories.interface';
import { MatDialog } from '@angular/material/dialog';
import { NotificationType } from '@app/core/constants';
import { SiteDialogComponent } from '@app/module/company-info/site-dialog/site-dialog.component';
import { SnackbarService } from '@app/core/service/snackbar.service';
import { SuppSuppliersFacade } from '@app/core/facade/supp-suppliers.facade';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-step-main-info',
  templateUrl: './step-main-info.component.html',
  styleUrls: ['./step-main-info.component.scss'],
  providers: [SuppSuppliersFacade],
})
export class StepMainInfoComponent {
  @Input() step!: ISuppChainStep;
  @ViewChild('supplierSiteDropdown', { read: ElementRef }) supplierSiteDropdown!: ElementRef<HTMLElement>;
  @Input() selected_site!: UntypedFormControl;
  @Input() supplier_sites!: ISuppManufactory[];
  @Input() is_view!: boolean;
  @Input() is_enabled!: boolean;
  @Output() refresh = new EventEmitter<boolean>();
  isShowMore = false;

  constructor(
    private _snackbarService: SnackbarService,
    private _dialog: MatDialog
  ) {
    // this.form = this._group.editSupplierStep;
  }

  onCreateNewSite() {
    const dialogRef = this._dialog.open(SiteDialogComponent, {
      width: '1160px',
      height: '768px',
      data: {
        suppSupplier: this.step.supplier,
      },
      panelClass: 'top-padding-0',
    });

    dialogRef.afterClosed().subscribe((result: ISuppManufactory | null) => {
      if (result) {
        this.supplier_sites.push(result);
        this.refresh.emit(true);
        this.selected_site.setValue(result);
        this.selected_site.markAsDirty();
      }
    });
  }

  private _error(error: Record<string, string>): void {
    Object.values(error).map(err => {
      this._snackbarService.openTypeSnackbar(err, NotificationType.error);
    });
  }

  // Method to check if the value is of type IManufactory
  isManufactoryObject(manufactory: string | IManufactory | undefined): manufactory is IManufactory {
    return (manufactory as IManufactory).name !== undefined;
  }
}
