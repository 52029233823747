import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { NotificationType, Storage, USER } from '@app/core/constants';
import { BrandsFacade } from '@app/core/facade/brands.facade';
import { LanguagesFacade } from '@app/core/facade/languages.facade';
import { IBrandListResult, IBrandsListErrorResponse } from '@app/core/interface/brands.interface';
import { ILanguage, ILanguagesResponse } from '@app/core/interface/languages.interface';
import { IUser } from '@app/core/interface/login.interface';
import { DataStorageService } from '@app/core/service/data-localstorage.service';
import { SnackbarService } from '@app/core/service/snackbar.service';

@Component({
  selector: 'app-languages',
  templateUrl: './languages.component.html',
  styleUrls: ['./languages.component.scss'],
})
export class LanguagesComponent implements OnInit {
  languages: ILanguage[] = [];
  storedUser: IUser;
  languagesControl: UntypedFormControl = new UntypedFormControl([], Validators.required);
  constructor(
    private _languagesFacade: LanguagesFacade,
    private _brandsFacade: BrandsFacade,
    private _snackbarService: SnackbarService,
    private _dataStorageService: DataStorageService
  ) {
    this.storedUser = this._dataStorageService.get(USER, Storage.local)
      ? JSON.parse(this._dataStorageService.get(USER, Storage.local))
      : JSON.parse(this._dataStorageService.get(USER, Storage.session));
  }
  /**
   * On OnInit we get the brand (to get brand selected languages) and dimains and lang (to get all languages)
   */
  ngOnInit(): void {
    this.storedUser.brand &&
      this._brandsFacade.getBrand$(this.storedUser.brand).subscribe({
        next: this.setLanguages.bind(this),
        error: this._error.bind(this),
      });
    this._languagesFacade.getLanguages$().subscribe({
      next: this._setLanguagesList.bind(this),
      error: this._error.bind(this),
    });
  }
  /**
   * Triggers api in order to save new order
   */
  save() {
    const saveLangs = this.languagesControl.value.map((res: ILanguage) => {
      return res.uuid;
    });
    this.storedUser.brand &&
      this._brandsFacade.updateBrand$({ languages: saveLangs }, this.storedUser.brand).subscribe({
        next: this._saveSuccess.bind(this),
        error: this._error.bind(this),
      });
  }
  /**
   * Removes language from selection
   * @param language that needs to be removed
   */
  removeLang(language: ILanguage) {
    const langs = this.languagesControl.value.filter((res: ILanguage) => res.uuid !== language.uuid);
    this.languagesControl.setValue(langs);
    this.languagesControl.markAsTouched();
    this.languagesControl.markAsDirty();
  }
  /**
   * Sets selected languages
   * @param response response from brand response
   */
  setLanguages(response: IBrandListResult) {
    this.languagesControl.setValue(response.languages);
  }
  /**
   * Compares languages (needed for correct comparing)
   * @param object1 ILanguaage
   * @param object2 ILanguage
   * @returns boolean
   */
  compareCategoryObjects(object1: ILanguage, object2: ILanguage) {
    return object1 && object2 && object1.uuid === object2.uuid;
  }
  /**
   * Shows msg on successul save
   */
  private _saveSuccess() {
    this._snackbarService.openTypeSnackbar(`Languages updated`, NotificationType.success);
  }
  /**
   *
   * @param data response from get Languages
   */
  private _setLanguagesList(data: ILanguagesResponse): void {
    this.languages = data.results;
  }
  /**
   * Displays error message if orror occurs during api call
   */
  private _error(error: IBrandsListErrorResponse): void {
    Object.values(error).map(err => this._snackbarService.openTypeSnackbar(err, NotificationType.error));
  }
}
