import { Component, OnInit } from '@angular/core';
import { IBillingCardDetails, IBillingCustomerDetails } from '@app/core/interface/billing.interface';

import { BillingFacade } from '@app/core/facade/billing.facade';
import { ManageDetailsComponent } from './manage-details/manage-details.component';
import { MatDialog } from '@angular/material/dialog';
import { NotificationType } from '@app/core/constants';
import { SnackbarService } from '@app/core/service/snackbar.service';

@Component({
  selector: 'app-billing-management',
  templateUrl: './billing-management.component.html',
  styleUrls: ['./billing-management.component.scss'],
  providers: [BillingFacade],
})
export class BillingManagementComponent implements OnInit {
  customerDetails!: IBillingCustomerDetails;
  creditCardDetails!: Array<IBillingCardDetails>;
  isEmptyTable = false;
  isEmptyUnpaidTable = false;
  selectedIndex = 0;

  constructor(
    private _billingFacade: BillingFacade,
    public dialog: MatDialog,
    private _snackbarService: SnackbarService
  ) {}

  ngOnInit(): void {
    this._getCustomerDetails();
  }

  private _getCustomerDetails() {
    this._billingFacade.getCustomerDetails$().subscribe({
      next: this._setCustomerDetails.bind(this),
      error: this._error.bind(this),
    });

    this._billingFacade.getCreditCardDetails$().subscribe({
      next: this._setCreditCardDetails.bind(this),
      error: this._error.bind(this),
    });
  }

  editDetails() {
    const dialogRef = this.dialog.open(ManageDetailsComponent, {
      data: {
        customerDetails: this.customerDetails,
        creditCardDetails: this.creditCardDetails,
      },
      panelClass: ['custom-dialog-container', 'top-padding-0'],
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this._getCustomerDetails();
      }
    });
  }

  private _setCustomerDetails(data: IBillingCustomerDetails): void {
    this.customerDetails = data;
  }

  private _setCreditCardDetails(data: Array<IBillingCardDetails>): void {
    this.creditCardDetails = data;
  }

  private _error(error: Record<string, string[]>): void {
    Object.values(error).map(err => this._snackbarService.openTypeSnackbar(err[0], NotificationType.error));
  }

  checkTableData(emptyTable: boolean) {
    this.isEmptyTable = emptyTable;
  }

  checkUnpaidTableData(emptyUnpaidTable: boolean) {
    this.isEmptyUnpaidTable = emptyUnpaidTable;
  }
}
