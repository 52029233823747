import { IPageTranslationResultsResponse, ITranslationResponse } from './core/interface/translation.interface';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TranslateLoader } from '@ngx-translate/core';
import { environment } from '@env/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CustomLoader implements TranslateLoader {
  constructor(private http: HttpClient) {}

  apiUrl = environment.baseApiUrl;

  getTranslation(langCountry: string): Observable<ITranslationResponse> {
    const suffix = 'csm/translation';

    return this.http.get(`${this.apiUrl}${langCountry}/api/v3/${suffix}`).pipe(
      map((data: ITranslationResponse) => {
        const res = {};
        data['results'].forEach((obj: IPageTranslationResultsResponse) => {
          Object.assign(res, obj);
        });
        return res;
      })
    );
  }
}
