import { Component, Input } from '@angular/core';

import { ISupplierResponse } from '@app/core/interface/suppliers.interface';

@Component({
  selector: 'app-supplier-cube',
  templateUrl: './supplier-cube.component.html',
  styleUrls: ['./supplier-cube.component.scss'],
})
export class SupplierCubeComponent {
  @Input() supplier!: ISupplierResponse;
}
