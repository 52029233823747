import { AddContactComponent } from './supplier-contacts/add-contact/add-contact.component';
import { AddFactoryComponent } from './add-factory/add-factory.component';
import { AngularMaterialModule } from '@app/material.module';
import { ArenaAccountComponent } from './arena-account/arena-account.component';
import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '@app/shared/shared.module';
import { SupplierContactsComponent } from './supplier-contacts/supplier-contacts.component';
import { SupplierCubeComponent } from './supplier-cube/supplier-cube.component';
import { SupplierManagementComponent } from './supplier-management.component';
import { SupplierManagementRoutingModule } from './supplier-management-routing.module';
import { SupplierSitesComponent } from './supplier-sites/supplier-sites.component';
import { SuppliersFacade } from '@app/core/facade/suppliers.facade';
import { TranslateModule } from '@ngx-translate/core';
import { SupplierPageDetailsComponent } from './supplier-page-details/supplier-page-details.component';
import { SupplierCertificatesComponent } from './supplier-certificates/supplier-certificates.component';
import { SuppliersIngredientsComponent } from './supplier-ingredients/supplier-ingredients.component';
import { IngredientFacade } from '@app/core/facade/ingredient.facade';
import { SupplierDetailsDialogComponent } from './supplier-details-dialog/supplier-details-dialog.component';
import { FilesMeadiasLinksFacade } from '@app/core/facade/files-medias-links.facade';
import { SupplierCertificatesGridComponent } from './supplier-certificates-grid/supplier-certificates-grid.component';
import { CertificatesFacade } from '@app/core/facade/certificates.facade';
import { ProductsFacade } from '@app/core/facade/products.facade';
import { LanguagesFacade } from '@app/core/facade/languages.facade';
import { SupplierPublicationSetupComponent } from './supplier-publication-setup/supplier-publication-setup.component';

@NgModule({
  declarations: [
    SupplierManagementComponent,
    ArenaAccountComponent,
    SupplierCubeComponent,
    SupplierContactsComponent,
    AddContactComponent,
    SupplierSitesComponent,
    AddFactoryComponent,
    SupplierPageDetailsComponent,
    SupplierCertificatesComponent,
    SuppliersIngredientsComponent,
    SupplierDetailsDialogComponent,
    SupplierCertificatesGridComponent,
  ],
  imports: [
    CommonModule,
    SupplierManagementRoutingModule,
    AngularMaterialModule,
    TranslateModule.forChild({}),
    ReactiveFormsModule,
    SharedModule,
    FormsModule,
    SupplierPublicationSetupComponent,
  ],
  providers: [
    SuppliersFacade,
    DatePipe,
    IngredientFacade,
    FilesMeadiasLinksFacade,
    CertificatesFacade,
    ProductsFacade,
    LanguagesFacade,
  ],
})
export class SupplierManagementModule {}
