import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

import { IMedia } from '@app/core/interface/medias.interface';
import { IPublicationStep } from '@app/core/interface/publication.interface';
import { UntypedFormGroup } from '@angular/forms';
import { ICustomPublicationStep } from '@app/module/publication/custom-publications/custom-publications.models';

@Component({
  selector: 'app-step-details',
  templateUrl: './step-details.component.html',
  styleUrls: ['./step-details.component.scss'],
})
export class StepDetailsComponent implements OnChanges {
  @Input() form!: UntypedFormGroup;
  @Input() step?: IPublicationStep | ICustomPublicationStep;
  @Output() refreshStep = new EventEmitter<boolean>();
  photos?: IMedia[];
  videos?: IMedia[];

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['step'] && this.step) {
      this.photos = this.step.medias?.filter((media: IMedia) => media.mimetype.indexOf('image') > -1);
      this.videos = this.step.medias?.filter((media: IMedia) => media.mimetype.indexOf('video') > -1);
    }
  }
}
