<div class="col-12 form-card">
  <form [formGroup]="form" class="d-flex">
    <div class="project-logo d-flex flex-column">
      <h6>{{ projectTitle + ' Photo' | translate }}</h6>
      <app-file-upload
        *ngIf="!is_editing"
        [iconType]="'add_photo_alternate'"
        [readonly]="false"
        (fileData)="saveImageData($event)"
      ></app-file-upload>
      <app-file-upload
        *ngIf="is_editing && projectResponse"
        [placeholder]="projectResponse.logo ? projectResponse.logo : 'Upload product image'"
        [imageUrl]="projectResponse.logo ? projectResponse.logo : ''"
        [status]="form.controls['logo'].status"
        [iconType]="'add_photo_alternate'"
        [readonly]="false"
        (fileData)="saveImageData($event)"
      ></app-file-upload>
    </div>
    <div class="inputs-holder">
      <div class="details d-flex">
        <mat-form-field class="input-primary" appearance="outline">
          <mat-label>{{ 'Name' | translate }}</mat-label>
          <input matInput formControlName="name" placeholder="{{ 'Name' | translate }}" [readonly]="viewMode" />
          <mat-error *ngIf="form.controls['name'].hasError('required')">{{
            'please enter project name in order to proceed' | translate
          }}</mat-error>
        </mat-form-field>
        <mat-form-field class="input-primary" appearance="outline">
          <mat-label>{{ 'Status' | translate }}</mat-label>
          <mat-select
            panelClass="dropdown-primary"
            formControlName="status"
            [value]="form.controls['status'].value"
            *ngIf="!viewMode"
            placeholder="{{ 'Status' | translate }}"
          >
            <mat-option *ngFor="let status of projectStatusList" [value]="status.uuid" placeholder="status">
              {{ status.name }}
            </mat-option>
          </mat-select>
          <input
            matInput
            [value]="form.controls['status'].value"
            placeholder="{{ 'Project' | translate }}"
            *ngIf="viewMode"
            readonly
          />
        </mat-form-field>
      </div>
      <div class="date-holder d-flex">
        <mat-form-field appearance="outline" class="input-primary">
          <mat-label>{{ 'Estimated start date' | translate }}</mat-label>
          <input matInput [matDatepicker]="picker_start" formControlName="estimated_start_date" [readonly]="viewMode" />
          <mat-datepicker-toggle matSuffix [for]="picker_start" [disabled]="viewMode"></mat-datepicker-toggle>
          <mat-datepicker #picker_start></mat-datepicker>
          <app-custom-icon [icon_category]="'time'" [icon_name]="'calendar-search'"></app-custom-icon>
        </mat-form-field>
        <mat-form-field appearance="outline" class="input-primary">
          <mat-label>{{ 'Estimated end date' | translate }}</mat-label>
          <input
            matInput
            [matDatepicker]="picker_end"
            formControlName="estimated_end_date"
            [readonly]="viewMode"
            [min]="minEnd"
          />
          <mat-datepicker-toggle matSuffix [for]="picker_end" [disabled]="viewMode"> </mat-datepicker-toggle>
          <mat-datepicker #picker_end></mat-datepicker>
          <app-custom-icon [icon_category]="'time'" [icon_name]="'calendar-search'"></app-custom-icon>
        </mat-form-field>
      </div>

      <div class="text-area">
        <mat-form-field class="input-primary textarea" id="text-area" appearance="outline">
          <mat-label>{{ projectTitle + ' Description' | translate }}</mat-label>
          <textarea
            matInput
            formControlName="description"
            [placeholder]="projectTitle + ' Description'"
            rows="4"
            appTextareaAutoResize
          ></textarea>
        </mat-form-field>
      </div>

      <div class="example-margin">
        <mat-checkbox formControlName="is_location_specific" class="checkbox-primary" color="primary" *ngIf="!viewMode">
          <span>
            {{ projectTitle + ' linked to a location?' | translate }}
          </span>
        </mat-checkbox>
      </div>
      <div class="location-holder d-flex flex-column" *ngIf="form.controls['is_location_specific'].value">
        <div class="address-city-holder d-flex">
          <mat-form-field appearance="outline" class="input-primary">
            <mat-label>{{ 'Enter Address' | translate }}</mat-label>
            <input
              matInput
              placeholder="{{ 'Enter Address' | translate }}"
              formControlName="address"
              [readonly]="viewMode"
            />
          </mat-form-field>
          <mat-form-field appearance="outline" class="input-primary">
            <mat-label>{{ 'Enter City' | translate }}</mat-label>
            <input matInput placeholder="{{ 'Enter City' | translate }}" formControlName="city" [readonly]="viewMode" />
          </mat-form-field>
        </div>
        <div class="country-holder d-flex">
          <mat-form-field appearance="outline" class="input-primary">
            <mat-label>{{ 'Enter State / Zip Code' | translate }}</mat-label>
            <input
              matInput
              formControlName="statezipcode"
              placeholder="{{ 'Enter State  / Zip Code' | translate }}"
              [readonly]="viewMode"
            />
          </mat-form-field>
          <mat-form-field class="input-primary" appearance="outline">
            <mat-label>{{ 'Enter Country' | translate }}</mat-label>
            <input
              type="text"
              *ngIf="viewMode"
              matInput
              [value]="form.controls['country']?.value?.name ?? form.controls['country']?.value"
              [readonly]="viewMode"
            />
            <div *ngIf="!viewMode">
              <input type="text" matInput formControlName="country" [matAutocomplete]="auto" [readonly]="viewMode" />
              <mat-autocomplete class="autocomplete-primary" #auto="matAutocomplete" [displayWith]="displayFn">
                <mat-option *ngFor="let country of filteredCountries | async" [value]="country">
                  {{ country.name }}
                </mat-option>
              </mat-autocomplete>
            </div>
          </mat-form-field>
        </div>
      </div>
    </div>
  </form>
</div>
