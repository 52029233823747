<div class="background">
  <div class="user-grid-view">
    <mat-card class="user-wrapper" [ngClass]="selectedTabIndex === 0 ? 'minHeightTab1' : 'minHeightTab2'">
      <div class="header-wrapper">
        <div class="pv-20px">
          <h6 class="mb-24">
            <strong>{{ 'Manage Users' | translate }}</strong>
          </h6>

          <div class="search-brand-user-button">
            <mat-form-field class="input-primary mat-form-field-10-px-brd-rd" appearance="outline">
              <input
                placeholder="{{ 'Quick search...' | translate }}"
                matInput
                #autoCompleteInput
                [matAutocomplete]="auto"
                [formControl]="myControl"
                #inputFilter
                #trigger="matAutocompleteTrigger"
                required
              />
              <mat-autocomplete class="user-list" #auto="matAutocomplete">
                <div class="option">
                  <mat-option *ngFor="let user of filteredOptions | async" placeholder="certificate.name">
                    <div class="menu-items">
                      <div class="avatar">
                        <div class="logo-wrapper">
                          <img class="supp-img" src="{{ user.avatar }}" />
                        </div>
                      </div>

                      <div class="supp-name">
                        <div class="name-wrapper">
                          <p class="name">{{ user.first_name }} {{ user.last_name }}</p>
                        </div>
                      </div>
                    </div>
                  </mat-option>
                </div>

                <mat-option>
                  <div class="same-name">
                    <p>
                      <span>{{ userCount > 3 ? userCount - 3 + ' more...' : '' }}</span>
                    </p>
                  </div>
                </mat-option>
              </mat-autocomplete>

              <app-custom-icon
                class="cursor-pointer"
                matSuffix
                [icon_category]="'search'"
                [icon_name]="'search-normal-2'"
              ></app-custom-icon>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div *ngIf="selectedBrand" class="brand-data">
        <div class="tabs-section position-relative">
          <div class="button-holder">
            <app-rounded-button
              *ngIf="selectedTabIndex === 0"
              class="invite-brand-user-button"
              color="primary"
              button_text="Invite Brand User"
              size="md"
              [icon_right]="true"
              icon_category="user"
              icon_name="profile-add"
              (click)="openInviteBrandUserDialog()"
            >
            </app-rounded-button>
          </div>

          <div class="tab-group">
            <mat-tab-group [(selectedIndex)]="selectedTabIndex" (selectedTabChange)="onTabChange($event.index)">
              <!-- Add Users tab -->
              <mat-tab [label]="'Add User' | translate">
                <ng-template matTabContent>
                  <app-brand-users [newInvitedUser]="newInvitedUser" [dataSource]="dataSource"></app-brand-users>
                </ng-template>
              </mat-tab>
              <!-- Brand Details tab -->
              <mat-tab [label]="'Update user details' | translate">
                <ng-template matTabContent>
                  <div class="main-holder">
                    <div class="cards-wrapper row">
                      <mat-card class="custom-card" *ngFor="let brandUser of usersObservable$ | async">
                        <mat-card-title>
                          <div class="title-holder">
                            <div class="title">
                              <div class="user-img-card">
                                <img
                                  class="user-pic"
                                  src="{{
                                    brandUser.avatar
                                      ? brandUser.avatar
                                      : '../../../../../assets/images/image-placeholder.jpg'
                                  }}"
                                  alt="User Image"
                                />
                              </div>
                              <div class="user-name">
                                <p>{{ brandUser.first_name }} {{ brandUser.last_name }}</p>
                              </div>
                            </div>

                            <div class="edit" (click)="manageUserDialog(brandUser)">
                              <p>Manage</p>
                              <app-custom-icon
                                matTooltip="{{ 'Manage User' | translate }}"
                                matTooltipClass="custom-tooltip-end"
                                matTooltipPosition="above"
                                [icon_category]="'files'"
                                [icon_name]="'edit-2'"
                              >
                              </app-custom-icon>
                            </div>
                          </div>
                          <div class="email">
                            <p>{{ brandUser.email }}</p>
                          </div>
                          <div class="h-line">
                            <div class="horizontal-line"></div>
                          </div>
                        </mat-card-title>

                        <div class="data-holder">
                          <div class="address-holder">
                            <div class="data">
                              <p class="subtitle">role</p>
                              <p class="title">{{ brandUser.role }}</p>
                            </div>
                            <div class="data">
                              <p class="subtitle">phone</p>
                              <p class="title">{{ brandUser.phone }}</p>
                            </div>
                            <div class="data">
                              <p class="subtitle">company</p>
                              <p class="title">{{ brandUser.entreprise }}</p>
                            </div>
                          </div>

                          <div class="address-holder">
                            <div class="data">
                              <p class="subtitle">title</p>
                              <p class="title">{{ brandUser.function }}</p>
                            </div>
                            <div class="data">
                              <p class="subtitle">mobile phone</p>
                              <p class="title">{{ brandUser.phone_mobile }}</p>
                            </div>
                            <div class="data-address">
                              <p class="subtitle-address">address</p>
                              <p class="title-address">{{ brandUser.address }}</p>
                            </div>
                          </div>
                        </div>
                      </mat-card>
                    </div>
                  </div>
                </ng-template>
              </mat-tab>
            </mat-tab-group>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <mat-paginator class="custom-paginator" showFirstLastButtons aria-label="Select page of users"> </mat-paginator>
      </div>
    </mat-card>
  </div>
</div>
