import { Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DigitalSheetGroup } from '../create-digital-product-sheet-dialog.group';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-presence-plastic-microfiber',
  templateUrl: './presence-plastic-microfiber.component.html',
  styleUrls: ['./presence-plastic-microfiber.component.scss'],
  providers: [DigitalSheetGroup],
})
export class PresencePlasticMicrofiberComponent implements OnInit, OnDestroy {
  form!: FormGroup;
  substances: Array<{ name: string }> = [{ name: '' }];
  subscription!: Subscription;
  constructor(private group: DigitalSheetGroup, private fb: FormBuilder) {
    this.form = group.plasticPresentce;
  }

  @ViewChild('infoWrap') infoWrapElement!: ElementRef;
  @ViewChild('info') infoElement!: ElementRef;

  @HostListener('document:click', ['$event'])
  handleClickOutside(event: Event): void {
    if (
      !this.infoWrapElement?.nativeElement.contains(event.target) &&
      this.infoElement?.nativeElement.style.display !== 'none'
    ) {
      this.hideInfo();
    }
  }
  ngOnDestroy(): void {
    this.subscription && this.subscription.unsubscribe();
  }
  ngOnInit(): void {
    this.subscription = this.form.controls['hazardous_substances'].valueChanges.subscribe(value => {
      if (value === true && !this.form.controls['hazardous_list'])
        this.form.addControl(
          'hazardous_list',
          this.fb.array([this.fb.control('', [Validators.required, Validators.minLength(3)])])
        );
      if (value === false) this.form.removeControl('hazardous_list');
    });
  }

  get list(): FormArray {
    return this.form.get('hazardous_list') as FormArray;
  }

  hideInfo(): void {
    if (this.infoElement) this.infoElement.nativeElement.style.display = 'none';
  }
  addInput(name?: string): void {
    const additionalName = this.fb.control('', [Validators.required, Validators.minLength(3)]);
    name && additionalName.setValue(name);
    this.list?.push(additionalName);
  }

  removeInput(index: number): void {
    this.list?.removeAt(index);
  }
}
