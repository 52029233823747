<div class="row step-cont cont-1">
  <div class="col-1">
    <img src="assets/logos/symbol-logo-dark.svg" alt="pdna-logo" />
  </div>
  <div class="col-10">
    <p class="head-info">
      {{ 'Please select the type of your product' | translate }}
    </p>
    <div class="info">
      {{
        'By selecting the type of product, you are helping us provide you with more precise
      information, in some of the sections required by the AGEC law regulation.' | translate
      }}
    </div>
    <div class="sel row">
      <mat-card>
        <mat-radio-group [(ngModel)]="selectedType" (change)="dirty = true" class="row">
          <mat-radio-button *ngFor="let type of types" [value]="type" class="col-4 align-items-start">{{
            type.name
          }}</mat-radio-button>
        </mat-radio-group>
      </mat-card>
    </div>
  </div>
</div>
