<div class="d-flex justify-content-center align-items-center">
  <div class="content-holder">
    <div class="logo-holder"></div>

    <div class="text-holder">
      <h3>
        <strong>{{ 'Complete your profile by filling in the following fields.' | translate }}</strong>
      </h3>
      <h6>{{ 'You can update the info anytime after the setup.' | translate }}</h6>
    </div>

    <div class="divider-holder">
      <mat-divider></mat-divider>
    </div>

    <form class="row" [formGroup]="form">
      <div class="col-12 col-md-4">
        <app-file-upload
          [placeholder]="'Upload your Avatar' | translate"
          [iconType]="'add_photo_alternate'"
          (fileData)="saveImageData($event)"
          [status]="form.controls['avatar'].status"
          [uploadImageText]="'Upload your avatar'"
        >
        </app-file-upload>
      </div>
      <div class="col-12 col-md-8 row p-0">
        <mat-form-field class="col-12 col-md-6 input-primary" appearance="outline">
          <mat-label>{{ 'First Name' | translate }}</mat-label>
          <input
            matInput
            formControlName="first_name"
            placeholder="{{ 'First Name' | translate }}"
            (focus)="form.get('first_name')?.markAsTouched()"
          />
          <mat-error *ngIf="form.get('first_name')?.hasError('required') && form.get('first_name')?.touched">
            {{ 'First name is required.' | translate }}
          </mat-error>
        </mat-form-field>
        <mat-form-field class="col-12 col-md-6 input-primary" appearance="outline">
          <mat-label>{{ 'Last Name' | translate }}</mat-label>
          <input
            matInput
            formControlName="last_name"
            placeholder="{{ 'Last Name' | translate }}"
            (focus)="form.get('last_name')?.markAsTouched()"
          />
          <mat-error *ngIf="form.get('last_name')?.hasError('required') && form.get('last_name')?.touched">
            {{ 'Last name is required.' | translate }}
          </mat-error>
        </mat-form-field>
        <mat-form-field class="col-12 input-primary" appearance="outline">
          <mat-label>{{ 'Position' | translate }}</mat-label>
          <input matInput formControlName="function" placeholder="{{ 'Position' | translate }}" />
        </mat-form-field>
        <mat-form-field class="col-12 input-primary" appearance="outline">
          <mat-label>{{ 'Phone' | translate }}</mat-label>
          <input matInput formControlName="phone" placeholder="{{ 'Phone' | translate }}" />
        </mat-form-field>
      </div>

      <div class="buttons-row d-flex">
        <app-rounded-button
          color="primary"
          button_text="{{ 'Continue' | translate }}"
          [icon_category]="'arrows'"
          [icon_name]="'arrow-right'"
          [icon_right]="true"
          [disabled]="form.invalid || form.pristine"
          (click)="completeProfile()"
        >
        </app-rounded-button>
      </div>
    </form>
  </div>
</div>
