<div class="wraper">
  <h4 class="section-header">
    {{ 'Publications' | translate }}
  </h4>

  <div class="table-wraper row" *ngIf="dataSource.data">
    <table class="custom-table" mat-table matSort [dataSource]="dataSource">
      <ng-container matColumnDef="reference">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Publication ID' | translate }}</th>
        <td mat-cell *matCellDef="let element">
          <div class="row">
            <div class="res-icon col-3"></div>
            <div class="col-9">
              {{ element.publication_reference }}
            </div>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Supply Chain Name' | translate }}</th>
        <td mat-cell *matCellDef="let element">{{ element.name }}</td>
      </ng-container>

      <ng-container matColumnDef="created">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Created On' | translate }}</th>
        <td mat-cell *matCellDef="let element">{{ element.created_at | date: 'dd.MM.yyyy' }}</td>
      </ng-container>

      <ng-container matColumnDef="updated">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Last Updated' | translate }}</th>
        <td mat-cell *matCellDef="let element">{{ element.updated_at | date: 'dd.MM.yyyy' }}</td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Publication Status' | translate }}</th>
        <td mat-cell *matCellDef="let element">
          <div
            class="status"
            [ngClass]="{ 'in-progress': element.publication_status === 0, completed: element.publication_status === 2 }"
          ></div>
          @switch (element.publication_status) {
            @case (0) {
              {{ 'In Progress' | translate }}
            }
            @case (1) {
              {{ 'Pending Validation' | translate }}
            }
            @case (2) {
              {{ 'Published' | translate }}
            }
          }
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef class="actions-header">{{ 'Actions' | translate }}</th>
        <td mat-cell *matCellDef="let element" class="actions-body">
          <div class="icon-holder">
            <app-custom-icon
              matTooltipClass="custom-tooltip-center centering"
              [matTooltipPosition]="'above'"
              [matTooltip]="'Edit Publication'"
              [icon_category]="'files'"
              [icon_name]="'edit'"
              (click)="editPublication(element)"
            ></app-custom-icon>
            <app-custom-icon
              matTooltipClass="custom-tooltip-center centering"
              [matTooltipPosition]="'above'"
              [matTooltip]="'View in respect-code'"
              [icon_category]="'security'"
              [icon_name]="'eye'"
              (click)="viewPublication()"
              *ngIf="isAmway && element.publication_status === 2"
            ></app-custom-icon>
            <app-custom-icon
              matTooltipClass="custom-tooltip-center centering"
              [matTooltipPosition]="'above'"
              [matTooltip]="'Access to publication'"
              [icon_category]="'essential'"
              [icon_name]="'link'"
              (click)="accessToPublication(element.publication_reference)"
              *ngIf="!isAmway && element.publication_status === 2"
            ></app-custom-icon>
            <app-custom-icon
              matTooltipClass="custom-tooltip-center centering"
              [matTooltipPosition]="'above'"
              [matTooltip]="'Access to publication'"
              [icon_category]="'essential'"
              [icon_name]="'link'"
              (click)="editPublication(element)"
              *ngIf="isAmway && element.publication_status === 2"
            ></app-custom-icon>
            <!-- Define the action for the icon -->
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </div>
  <div class="card-footer">
    <mat-paginator showFirstLastButtons aria-label="Select page of publications"> </mat-paginator>
  </div>
</div>
