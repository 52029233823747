<ng-container>
  <app-loader [delayed]="0.3"></app-loader>
  <mat-sidenav-container class="main-container" autosize>
    <mat-sidenav #sidenav mode="side" opened="true">
      <app-sidenav
        *ngIf="
          (user$ | async) &&
          !registerUrl &&
          !chainSetup &&
          !landingUrl &&
          !purchaseUrl &&
          !isPublish &&
          !isSwitchBrand &&
          !isSelectBrand &&
          !isCreatePassword &&
          !navigationService.isTermsAndConditions()
        "
      >
      </app-sidenav>
    </mat-sidenav>
    <mat-sidenav-content
      [ngClass]="landingUrl || purchaseUrl || chainSetup || isPublish ? 'landing' : 'mat-sidenav-content'"
    >
      <app-header
        *ngIf="
          (user$ | async) &&
          !registerUrl &&
          !landingUrl &&
          !purchaseUrl &&
          !isSwitchBrand &&
          !isSelectBrand &&
          !isCreatePassword &&
          !navigationService.isTermsAndConditions()
        "
        [isChainSetup]="chainSetup"
        [isPublish]="isPublish"
        [showWelcomeMessage]="showWelcomeMessage"
      ></app-header>

      <div
        [ngClass]="
          (user$ | async) &&
          !registerUrl &&
          !landingUrl &&
          !purchaseUrl &&
          !chainSetup &&
          !isPublish &&
          !isCreatePassword &&
          !isSwitchBrand &&
          !isSelectBrand
            ? 'content background-color'
            : 'content h-100'
        "
      >
        <router-outlet></router-outlet>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
  <img
    *ngIf="user$ | async"
    class="cookies-icon"
    src="../../../assets/images/cookie.png"
    matTooltip="{{ 'Manage cookies' | translate }}"
    matTooltipClass="custom-tooltip-center"
    matTooltipPosition="above"
    (click)="openCookiesManagement()"
  />
</ng-container>
