<div class="container-fluid">
  <div class="row container-inside">
    <div class="col-md-6 left p-0">
      <app-illustration-design [color]="'primary'"></app-illustration-design>
    </div>
    <div class="col-md-6 right row justify-content-center">
      <div class="col-md-10 content row">
        <div class="text-inputs-buttons">
          <h1 class="password-added">
            {{
              supplierEmail
                ? ('account-setup.New password added!' | translate)
                : ('account-setup.Welcome back' | translate)
            }}
          </h1>
          <h6 *ngIf="supplierEmail" class="password">
            {{ 'account-setup.You have successfully configured your password.' | translate }}
          </h6>
          <div class="col-md-8 box mb-0 p-0 row flex-nowrap">
            <h4>{{ 'account-setup.Log into Respect-SaaS' | translate }}</h4>

            <div *ngIf="supplierEmail">
              <div class="your-mail">
                {{ 'account-setup.Your email' | translate }}
              </div>
              <div class="supplier-email">
                {{ supplierEmail }}
              </div>
            </div>
            <form [formGroup]="loginForm" (keydown.enter)="onSubmit()">
              <section class="email-section">
                <mat-form-field *ngIf="!supplierEmail" class="input-primary" color="primary" appearance="outline">
                  <mat-label>{{ 'account-setup.E-mail' | translate }}</mat-label>
                  <input
                    type="text"
                    matInput
                    formControlName="username"
                    placeholder="example@gmail.com"
                    [attr.autocomplete]="autocomplete ? 'username' : null"
                    (input)="onInput()"
                  />

                  <mat-error
                    *ngIf="loginForm.controls['username'].errors && loginForm.controls['username'].errors['required']"
                  >
                    {{ 'account-setup.Username or e-mail is' | translate }}
                    <strong>{{ 'account-setup.required' | translate }}</strong>
                  </mat-error>
                </mat-form-field>
                <app-custom-icon
                  class="indicator"
                  [icon_category]="'essential'"
                  [icon_name]="'info-circle'"
                  [matTooltip]="
                    'account-setup.We collect your email address to send you important updates and notifications related to your account. Your email will only be used for these purposes and will not be shared with third parties.'
                      | translate
                  "
                  matTooltipClass="custom-tooltip-center"
                ></app-custom-icon>
              </section>

              <section class="password-section d-flex">
                <mat-form-field class="input-primary" color="primary" appearance="outline">
                  <mat-label>{{ 'account-setup.Enter password' | translate }}</mat-label>
                  <input
                    matInput
                    formControlName="password"
                    [type]="hide && autocomplete ? 'password' : 'text'"
                    [autocomplete]="autocomplete ? 'current-password' : 'off'"
                    [ngClass]="hide ? 'autocompleteOff' : ''"
                  />
                  <app-custom-icon
                    class="show-hide-pass"
                    [icon_category]="'security'"
                    [icon_name]="hide ? 'eye-slash' : 'eye'"
                    (click)="hide = !hide"
                  ></app-custom-icon>

                  <mat-error
                    *ngIf="loginForm.controls['password'].errors && loginForm.controls['password'].errors['required']"
                  >
                    {{ 'account-setup.Password is' | translate }}
                    <strong>{{ 'account-setup.required' | translate }}</strong>
                  </mat-error>
                </mat-form-field>
                <app-custom-icon
                  class="indicator"
                  [icon_category]="'security'"
                  [icon_name]="'lock-2'"
                  [matTooltip]="'account-setup.Your password is securely encrypted.' | translate"
                  matTooltipClass="custom-tooltip-center"
                ></app-custom-icon>
              </section>

              <section class="example-section mb">
                <a routerLink="/password_reset" class="pull-left l1">
                  {{ 'account-setup.Forgot your password?' | translate }}
                </a>
              </section>
            </form>

            <section class="actions">
              <div class="login-btn">
                <app-rounded-button
                  color="primary"
                  [button_text]="'account-setup.Log in' | translate"
                  [icon_category]="'arrows'"
                  [icon_name]="'login-2'"
                  [icon_right]="true"
                  (click)="onSubmit()"
                  [size]="'xd'"
                  [disabled]="loginForm.invalid"
                >
                </app-rounded-button>
              </div>
              <div class="ssl">
                <span>{{ 'account-setup.SSL SECURED LOGIN' | translate }}</span>
                <app-custom-icon [icon_category]="'security'" [icon_name]="'lock-2'"></app-custom-icon>
              </div>
            </section>
          </div>

          <div class="col-12 divider-holder" *ngIf="!supplierEmail">
            <mat-divider></mat-divider>
          </div>

          <div class="col-md-8 box mb-0 p-0 row" *ngIf="!supplierEmail && !environment.qa && !environment.production">
            <h6>{{ 'account-setup.Did not created an account yet?' | translate }}</h6>

            <app-rounded-button
              color="secondary"
              [button_text]="'account-setup.Create account' | translate"
              [icon_category]="'user'"
              [icon_name]="'profile-tick'"
              [icon_right]="true"
              [routerLink]="'../register/create-account'"
              [size]="'xd'"
            >
            </app-rounded-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
