<div class="wrapper d-flex flex-column" [ngClass]="is_batch ? 'batch-wrapper' : 'pad-24'">
  <div *ngIf="is_batch && !is_view" class="review-note d-flex align-items-center">
    <img class="logo" src="assets/logos/symbol-logo-dark.svg" />
    <p class="m-0">
      {{
        'supply-chain-step.Please review and update this list to indicate exactly the direct suppliers that you used to accomplish your step for this batch.'
          | translate
      }}
    </p>
  </div>
  <div class="header d-flex justify-content-between" [ngClass]="{ 'top-marg': is_view && is_batch }">
    <div class="label d-flex align-items-center">
      {{ 'supply-chain-step.Direct Suppliers' | translate }}
      <app-custom-icon
        class="info-icon cursor-pointer"
        icon_category="essential"
        icon_name="info-circle"
        [matTooltip]="
          ('supply-chain-step.A direct supplier is the company that provided you goods and with whom you had a direct commercial relationship'
            | translate) + '.'
        "
        matTooltipPosition="above"
        matTooltipClass="custom-tooltip-center"
      >
      </app-custom-icon>
    </div>

    <app-rounded-button
      *ngIf="!is_batch && !is_view"
      #btnAddDirectSupplier
      class="btn-add-supplier"
      color="secondary"
      size="sm"
      button_text="{{ 'supply-chain-step.Add direct supplier' | translate }}"
      icon_category="essential"
      icon_name="add"
      [icon_right]="true"
      (click)="onAddDirectSupplier()"
    >
    </app-rounded-button>
    <div class="attention-wrapp" *ngIf="_suppSupplierFacade.warningDirectSuppliersSignal() && is_batch">
      <app-custom-icon [icon_category]="'essential'" [icon_name]="'danger-red'"></app-custom-icon>
      <p class="m-0">{{ 'supply-batch-main.Attention required!' | translate }}</p>
    </div>
  </div>

  <!-- Show reason for continuing with no direct suppliers -->
  <div
    *ngIf="step?.reason_no_direct_supplier && !dataSource.data.length && !is_batch! && !is_view"
    class="not-validated-note d-flex"
  >
    <img class="logo" src="assets/logos/symbol-logo-dark.svg" />
    <div class="text">
      <p class="p2-strong m-0">
        {{ 'supply-chain-step.Please note that you specified to continue without direct suppliers' | translate }}.
      </p>
      <p #reasonNoDirectSuppliers class="p2-strong m-0 reason-text">
        {{ ('supply-chain-step.Reasons' | translate) + ': ' + step.reason_no_direct_supplier }}
      </p>
      <div *ngIf="showReadMore" class="read-more d-flex cursor-pointer" (click)="onReadMore()">
        <p class="p2 m-0">
          {{ (isReadMore ? 'supply-chain-step.Read less' : 'supply-chain-step.Read more') | translate }}
        </p>
        <app-custom-icon
          icon_category="arrows"
          [icon_name]="isReadMore ? 'arrow-up-2' : 'arrow-down-2'"
        ></app-custom-icon>
      </div>
    </div>
  </div>

  <!-- Show table of direct suppliers -->
  <div *ngIf="dataSource.data.length" class="table-wraper">
    <table class="custom-table" mat-table matSort [dataSource]="dataSource">
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="col-3">
          {{ 'supply-chain-step.Supplier name' | translate }}
        </th>
        <td mat-cell *matCellDef="let element" class="col-3">
          {{ is_batch ? element.supplier : element.step_supplier?.name ?? element.name }}
        </td>
      </ng-container>

      <ng-container matColumnDef="supplier">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="col-3">
          {{ 'supply-chain-step.Supplier role in the chain' | translate }}
        </th>
        <td mat-cell *matCellDef="let element" class="col-3">
          {{ is_batch ? element.name : element.step_supplier?.role ?? element.role }}
        </td>
      </ng-container>

      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="col-3">
          {{ 'supply-chain-step.Supplier contact e-mail' | translate }}
        </th>
        <td mat-cell *matCellDef="let element" class="col-3">
          <span
            [matTooltip]="element.email"
            matTooltipPosition="above"
            matTooltipClass="custom-tooltip-center no-max-width"
          >
            {{ element.email }}
          </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="supplier_used">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="col-3">
          {{ 'supply-chain-step.Supplier used for this batch?' | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let element"
          class="col-3 radio-buttons-holder"
          [ngClass]="{ 'attention-button': _suppSupplierFacade.warningDirectSuppliersSignal() }"
        >
          <mat-radio-group
            *ngIf="!is_view"
            class="d-flex align-items-center small-radio-button"
            [value]="element.is_active"
          >
            <mat-radio-button [value]="true" (click)="completeDirectSupp(element)">{{
              'supply-batch-main.Yes' | translate
            }}</mat-radio-button>
            <mat-radio-button [value]="false" (click)="disapproveSupplier(element)">{{
              'supply-batch-main.No' | translate
            }}</mat-radio-button>
          </mat-radio-group>
          <div *ngIf="is_view">
            {{ (element.is_active ? 'supply-batch-main.Yes' : 'supply-batch-main.No') | translate }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef class="actions-header col">
          {{ 'supply-chain-step.Actions' | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let element"
          class="actions-body col"
          [ngClass]="{
            'disable-edit': (element.is_active === null || element.is_active === false) && !is_view && is_batch,
          }"
        >
          <app-custom-icon
            *ngIf="!is_view"
            class="cursor-pointer"
            [icon_category]="'files'"
            [icon_name]="'edit'"
            (click)="
              element.is_active
                ? is_batch
                  ? completeDirectSupp(element)
                  : onAddDirectSupplier(element)
                : !is_batch
                  ? onAddDirectSupplier(element)
                  : null
            "
            [matTooltip]="
              ('supply-chain-step.Please first confirm if for this batch, you have worked with this supplier'
                | translate) + '.'
            "
            matTooltipPosition="above"
            matTooltipClass="custom-tooltip-end"
            [matTooltip]="
              ('supply-chain-step.Please first confirm if for this batch, you have worked with this supplier'
                | translate) + '.'
            "
            [matTooltipDisabled]="element.is_active || !is_batch"
          ></app-custom-icon>
          <app-custom-icon
            *ngIf="(is_view && is_batch && element.is_active) || (is_view && !is_batch)"
            class="cursor-pointer"
            [icon_category]="'security'"
            [icon_name]="'eye'"
            (click)="viewDirectSupplier(element)"
            [matTooltip]="('supply-chain-step.View direct supplier information' | translate) + '.'"
            matTooltipPosition="above"
            matTooltipClass="custom-tooltip-end"
          ></app-custom-icon>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </div>

  <!-- Show message if no direct suppliers and reason present -->
  <div
    *ngIf="!dataSource.data.length && step && !step.reason_no_direct_supplier"
    class="info-no-direct-suppliers d-flex align-items-center"
  >
    <div class="circle d-flex justify-content-center align-items-center">
      <app-custom-icon icon_category="product" icon_name="truck-tick"></app-custom-icon>
    </div>

    <h4 class="m-0">{{ 'supply-chain-step.There are no direct suppliers added yet' | translate }}.</h4>
  </div>
</div>
