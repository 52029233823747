import { IAddressListResponse, ICreateAddressResponse, IDeleteAddress } from '../interface/address.interface';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class AddressService {
  private apiUrl = environment.api;

  constructor(private _http: HttpClient) {}

  createAddress$(data: FormData): Observable<ICreateAddressResponse> {
    return this._http.post<ICreateAddressResponse>(`${this.apiUrl}/address/`, data);
  }

  updateAddress$(data: FormData, uuid: string): Observable<ICreateAddressResponse> {
    return this._http.patch<ICreateAddressResponse>(`${this.apiUrl}/address/${uuid}`, data);
  }

  getAddresses$(): Observable<IAddressListResponse> {
    return this._http.get<IAddressListResponse>(`${this.apiUrl}/address/`);
  }

  getAddress$(uuid: string): Observable<ICreateAddressResponse> {
    return this._http.get<ICreateAddressResponse>(`${this.apiUrl}/address/${uuid}`);
  }

  deleteAddress$(uuid: string): Observable<IDeleteAddress> {
    return this._http.delete<IDeleteAddress>(`${this.apiUrl}/address/${uuid}/`);
  }
}
