<div class="wrp d-flex flex-column">
  <h5 class="header m-0">{{ 'Recyclability of the packaging' | translate }}</h5>
  <mat-radio-group
    class="d-flex justify-content-between re-gr"
    [(ngModel)]="selectedValue"
    (ngModelChange)="onSelection()"
    aria-label="Select an option"
  >
    <div class="radio-wrapper d-flex">
      <div class="radio-section d-flex flex-column">
        <p class="p2 wp2 m-0">
          {{
            'If the recycled material produced is equivalent to more than 95% by mass of the end-of-life
            product' | translate
          }}
        </p>
        <div class="radio-wrp">
          <mat-radio-button [value]="'Fully recyclable'" class="align-items-start">
            {{ 'Fully recyclable' | translate }}
          </mat-radio-button>
        </div>
      </div>
      <div class="radio-section d-flex flex-column">
        <div class="wp2 d-flex flex-column">
          <p class="p2 m-0">
            {{ 'Package is mostly recyclable if all 5 criteria below are met:' | translate }}
          </p>

          <div #infoWrap class="criteria d-flex align-items-center">
            <div class="info" #info>
              <span>
                <p class="list-h">
                  {{ 'This 5 criteria must be met' | translate }}
                </p>
                <ol class="m-0 d-flex flex-column">
                  <li *ngFor="let criterion of criteriaList">{{ criterion | translate }}</li>
                </ol>
              </span>
            </div>
            <p class="m-0">{{ 'See 5 criteria' | translate }}</p>
            <app-custom-icon
              (mouseover)="info.style.display = 'block'; info.style.display = 'block'"
              (mouseout)="info.style.display = 'none'"
              class="cursor-pointer"
              id="info"
              [icon_category]="'essential'"
              [icon_name]="'info-circle'"
              (click)="info.style.display = 'flex'"
            ></app-custom-icon>
          </div>
        </div>
        <div class="radio-wrp">
          <mat-radio-button [value]="'Mostly recyclable'" class="align-items-start">
            {{ 'Mostly recyclable' | translate }}
          </mat-radio-button>
        </div>
      </div>
      <div class="radio-section d-flex flex-column">
        <p class="p2 wp2 m-0">
          {{
            'If more than 50% of the product’s materials, are used to manufacture products of the same
            nature' | translate
          }}
        </p>
        <div class="radio-wrp">
          <mat-radio-button [value]="'Same nature'" class="align-items-start">
            {{ 'Packaging recyclable into packaging of the same nature' | translate }}
          </mat-radio-button>
        </div>
      </div>
      <div class="radio-section d-flex flex-column">
        <p class="p2 wp2 m-0">
          {{ 'If the product does not fall under any of the three previous recyclability categories' | translate }}
        </p>
        <div class="radio-wrp d-flex flex-column">
          <mat-radio-button [value]="'Not recyclable'" class="align-items-start">
            {{ 'Not recyclable' | translate }}
          </mat-radio-button>
          <div class="action d-flex flex-row-reverse align-items-center" *ngIf="selectedValue === 'Not recyclable'">
            <div class="action-text">
              {{ 'Display that the packaging is not recyclable' | translate }}
            </div>
            <mat-slide-toggle
              [checked]="visibility"
              class="custom-slide-toggle"
              [color]="'primary'"
              [(ngModel)]="visibility"
              (ngModelChange)="onVisibilityChange()"
            >
            </mat-slide-toggle>
          </div>
        </div>
      </div>
    </div>
  </mat-radio-group>
</div>
