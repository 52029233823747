import {
  IPageTranslationResponse,
  IPageTranslationResultsResponse,
  ITranslationFormData,
  ITranslationResponse,
} from '../interface/translation.interface';
import { Observable, ObservableInput, catchError, map, switchMap, take, throwError } from 'rxjs';

import { Injectable } from '@angular/core';
import { TranslationService } from '../service/translation.service';

@Injectable()
export class TranslationFacade {
  constructor(private _translationService: TranslationService) {}

  getUkTranslation$(page: string): Observable<ITranslationResponse> {
    return this._translationService.getUkTranslation$(page).pipe(
      take(1),
      catchError(({ error }: ITranslationResponse): ObservableInput<ITranslationResponse> => throwError(() => error))
    );
  }

  getTranslation$(language: string, page: string): Observable<IPageTranslationResultsResponse> {
    return this._translationService.getTranslation$(language, page).pipe(
      take(1),
      catchError(
        ({ error }: IPageTranslationResultsResponse): ObservableInput<IPageTranslationResultsResponse> =>
          throwError(() => error)
      )
    );
  }

  getDeTranslation$(page: string): Observable<ITranslationResponse> {
    return this._translationService.getDeTranslation$(page).pipe(
      take(1),
      catchError(({ error }: ITranslationResponse): ObservableInput<ITranslationResponse> => throwError(() => error))
    );
  }

  getFrTranslation$(page: string): Observable<ITranslationResponse> {
    return this._translationService.getFrTranslation$(page).pipe(
      take(1),
      catchError(({ error }: ITranslationResponse): ObservableInput<ITranslationResponse> => throwError(() => error))
    );
  }

  putTranslation$(data: ITranslationFormData): Observable<IPageTranslationResponse> {
    const formData = new FormData();
    formData.append('name', data.select_page.name);
    formData.append('uuid', data.select_page.uuid);
    formData.append('data', JSON.stringify(data.translation_data));

    return this._translationService.putTranslation$(formData, data.select_language).pipe(
      take(1),
      switchMap(() => {
        return this.getPageTranslation$();
      }),
      catchError(
        ({ error }: IPageTranslationResponse): ObservableInput<IPageTranslationResponse> => throwError(() => error)
      )
    );
  }

  getPageTranslation$(language?: string): Observable<IPageTranslationResponse> {
    return this._translationService.getPageTranslation$(language).pipe(map((data: IPageTranslationResponse) => data));
  }
}
