import { Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

@Injectable()
export class EditStepBatch {
  editStepBatch: UntypedFormGroup;

  constructor(private _fb: UntypedFormBuilder) {
    this.editStepBatch = this._fb.group({
      manufactory: [null, Validators.required],
      step_confirmed_by_supplier: [null, Validators.required],
      supplier_disapproval_reason: [null, [Validators.required, Validators.email]],
      uuid: [null, Validators.required],
    });
  }
}
