import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UnitManagementComponent } from './unit-management.component';
import { SharedModule } from '@app/shared/shared.module';
import { UnitManagementRoutingModule } from './unit-management-routing.module';
import { CreateUnitComponent } from './create-unit/create-unit.component';
import { AddUnitToBatchComponent } from './add-unit-to-batch/add-unit-to-batch.component';

@NgModule({
  declarations: [UnitManagementComponent, CreateUnitComponent, AddUnitToBatchComponent],
  imports: [CommonModule, UnitManagementRoutingModule, SharedModule],
})
export class UnitManagementModule {}
