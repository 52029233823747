import { ObservableInput, catchError, throwError } from 'rxjs';

import { ChartData } from 'chart.js';
import { IPhase } from '../interface/phase-management.interface';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { PhasesService } from './../service/phases.service';
import { map } from 'rxjs/internal/operators/map';
import { take } from 'rxjs/internal/operators/take';

@Injectable()
export class PhasesFacade {
  constructor(private _phasesService: PhasesService) {}

  getRespectcyclePhasesWithoutLabels$(uuid: string): Observable<[ChartData<'doughnut'>, IPhase[]]> {
    return this._phasesService.getRespectcyclePhases$(uuid).pipe(
      take(1),
      map((response: IPhase[]) => {
        response = response.map((phase: IPhase) => {
          return {
            ...phase,
            color: phase.color,
          };
        });

        const doughtnutChartDatasets = response.map(() => 1);
        const colors: string[] = response.map(data => data.color);
        const labels: string[] = response.map(data => data.name);

        const doughnutChartData: ChartData<'doughnut'> = {
          datasets: [
            {
              data: [...doughtnutChartDatasets],
              backgroundColor: [...colors],
              hoverBackgroundColor: [...colors],
              hoverBorderColor: [...colors],
            },
          ],
          labels: labels,
        };
        return [doughnutChartData, response];
      })
    );
  }

  getRespectcyclePhases$(uuid: string): Observable<[ChartData<'doughnut'>, IPhase[]]> {
    return this._phasesService.getRespectcyclePhases$(uuid).pipe(
      take(1),
      map((response: IPhase[]) => {
        const doughtnutChartLabels = response.map((phase: IPhase) => phase.name);
        const doughtnutChartDatasets = response.map(() => 100);
        const colors: string[] = response.map(data => data.color);

        const doughnutChartData: ChartData<'doughnut'> = {
          labels: doughtnutChartLabels,
          datasets: [
            {
              data: [...doughtnutChartDatasets],
              backgroundColor: [...colors],
              hoverBackgroundColor: [...colors],
              hoverBorderColor: [...colors],
            },
          ],
        };
        return [doughnutChartData, response];
      })
    );
  }

  getChartTypeSteps(type_steps: IPhase[]): [ChartData<'doughnut'>, IPhase[]] {
    const doughtnutChartLabels = type_steps.map((phase: IPhase) => phase.name);
    const doughtnutChartDatasets = type_steps.map(() => 100);
    const colors: string[] = type_steps.map(data => data.color);

    const doughnutChartData: ChartData<'doughnut'> = {
      labels: doughtnutChartLabels,
      datasets: [
        {
          data: [...doughtnutChartDatasets],
          backgroundColor: [...colors],
          hoverBackgroundColor: [...colors],
          hoverBorderColor: [...colors],
        },
      ],
    };
    return [doughnutChartData, type_steps];
  }

  updatePhase$(uuid: string, chainUuid: string, data: Partial<IPhase>): Observable<IPhase> {
    return this._phasesService.updatePhase$(uuid, chainUuid, data).pipe(
      take(1),
      catchError((error): ObservableInput<IPhase> => throwError(() => error))
    );
  }
}
