import { RouterModule, Routes } from '@angular/router';

import { ConfirmGuard } from '@app/core/guard/confirm.guard';
import { NgModule } from '@angular/core';
import { ProductChainComponent } from './product-chain.component';

const routes: Routes = [
  {
    path: '',
    component: ProductChainComponent,
    canDeactivate: [ConfirmGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ProductChainRouterModule {}
