import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ITask } from '@app/core/interface/tasks.interface';
import { TaskDetailsDialogComponent } from '../task-details-dialog/task-details-dialog.component';
import { INameID } from '@app/core/interface/products.interface';

@Component({
  selector: 'app-task-card',
  templateUrl: './task-card.component.html',
  styleUrls: ['./task-card.component.scss'],
})
export class TaskCardComponent implements OnInit, OnChanges {
  description = '';
  reletedObj: INameID = { name: '', uuid: '' };
  @Input() task!: ITask;
  constructor(private _dialog: MatDialog) {}
  ngOnInit(): void {
    this.setText();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['task'].currentValue.content_object.supplier)
      this.reletedObj = changes['task'].currentValue.content_object.supplier[0];

    if (changes['task'].currentValue.content_object.product_chain)
      this.reletedObj = changes['task'].currentValue.content_object.product_chain[0];

    if (changes['task'].currentValue.content_object.product)
      this.reletedObj = changes['task'].currentValue.content_object.product[0];
    if (changes['task'].currentValue.content_object.step)
      this.reletedObj = changes['task'].currentValue.content_object.step[0];
  }
  setText(): void {
    let text = this.task.description;
    if (text.length > 80) text = text.substring(0, 80) + '...';
    this.description = text;
  }

  openTaskDetails(task: ITask) {
    this._dialog.open(TaskDetailsDialogComponent, {
      data: { ...task, related_obj: this.reletedObj },
      panelClass: 'top-padding-0',
    });
  }
}
