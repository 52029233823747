<div class="background">
  <div class="questionnaires-view">
    <mat-card class="questionnaires-wrapper">
      <div class="header-wrapper">
        <mat-card class="options-header">
          <div class="questionnaires-header">
            <div class="questionnaires-heading">
              <div class="row">
                <div class="col-2">
                  <h2>{{ 'Questionnaire Creation' | translate }}</h2>
                </div>
                <div class="col-2 offset-6">
                  <app-rounded-button
                    color="secondary"
                    [button_text]="'Done' | translate"
                    [size]="'md'"
                    routerLink="/settings/questionnaires-management"
                    class="btn-done"
                  >
                  </app-rounded-button>
                </div>
                <div class="col-2">
                  <div class="icon">
                    <app-custom-icon
                      [icon_category]="'weather'"
                      [icon_name]="icon"
                      [matTooltip]="icon === 'saving' ? 'changes not saved yet' : 'All changes are saved'"
                      matTooltipClass="custom-tooltip below"
                      [matTooltipPosition]="'below'"
                    >
                    </app-custom-icon>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </mat-card>
        <div class="horizontal-line mb-5"></div>

        <form [formGroup]="form">
          <p>{{ 'Questionnaire Name' | translate }}</p>
          <mat-form-field class="input-primary" appearance="outline">
            <mat-label>{{ 'Enter Questionnaire Name' | translate }}</mat-label>
            <input matInput #nameInput formControlName="name" placeholder="Some Questionnaire Name" required />
          </mat-form-field>
        </form>

        <p>{{ 'Questionnaire Sections' | translate }}</p>
        <ng-container *ngIf="questionnaireDetails">
          <ng-container *ngFor="let section of questionnaireDetails.sections">
            <app-section-question
              [section]="section"
              [questionnaireUuid]="questionnaireDetails.uuid"
              [isCreate]="true"
            ></app-section-question>
          </ng-container>
        </ng-container>
      </div>

      <p (click)="createSection()" style="padding-bottom: 10px; padding-left: 15px; cursor: pointer">
        <app-custom-icon icon_category="essential" icon_name="add"></app-custom-icon>
        {{ 'Add New Section' | translate }}
      </p>
    </mat-card>
  </div>
</div>
