import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IManufactory } from '@app/core/interface/manufactories.interface';
import { ISuppSupplier } from '@app/core/interface/supp.interface';

@Component({
  selector: 'app-site-dialog',
  templateUrl: './site-dialog.component.html',
  styleUrls: ['./site-dialog.component.scss'],
})
export class SiteDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { supplierUuid?: string; site: IManufactory; suppSupplier?: ISuppSupplier },
    public dialogRef: MatDialogRef<SiteDialogComponent>
  ) {}

  onSiteCreated(event: IManufactory | null): void {
    this.dialogRef.close(event);
  }
}
