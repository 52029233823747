import {
  BRAND_LOGO,
  BRAND_NAME,
  DefaultUserNavList,
  NotificationType,
  QaEnvUserNavList,
  SupplierUserNavList,
} from '@app/core/constants';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { IBrandListResult, IBrandsListResponse } from '@app/core/interface/brands.interface';
import { ILanguage, ILanguagesResponse } from '@app/core/interface/languages.interface';
import { NavigationEnd, Router } from '@angular/router';
import { Storage, USER } from '@app/core/constants';
import { Subject, filter, takeUntil } from 'rxjs';

import { BrandsFacade } from '@app/core/facade/brands.facade';
import { DataAuthService } from '@app/core/service/data-auth.service';
import { DataStorageService } from '@app/core/service/data-localstorage.service';
import { IUser } from '@app/core/interface/login.interface';
import { LanguagesFacade } from '@app/core/facade/languages.facade';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs/internal/Observable';
import { SidenavService } from '@app/core/service/sidenav.service';
import { SnackbarService } from '@app/core/service/snackbar.service';
import { SupportDialogComponent } from '@app/shared/components/support-dialog/support-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '@env/environment';
import { NavigationService } from '@app/core/service/navigation.service';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidenavComponent implements OnInit, OnDestroy {
  @Output() open = new EventEmitter<boolean>(false);
  languages$!: Observable<ILanguagesResponse | null>;
  languages!: Array<ILanguage>;
  brand$!: Observable<IBrandsListResponse | null>;
  openSnav!: boolean;
  storedUser: IUser;
  brand!: IBrandListResult;
  supplierAccount = false;
  private _unsubscribe$: Subject<void> = new Subject();

  isExpanded = true;
  showSubmenu = false;
  isShowing = false;
  showSubSubMenu = false;
  isCsmUser = false;
  isSupplierUser = false;
  small_screen = false;
  isOnSupplyChainsPage = false;

  localLanguageName = '';

  navList = environment.qa || environment.production ? QaEnvUserNavList : DefaultUserNavList;

  constructor(
    private _languagesFacade: LanguagesFacade,
    private _dataAuthService: DataAuthService,
    public translations: TranslateService,
    private breakpointObserver: BreakpointObserver,
    private _cdRef: ChangeDetectorRef,
    private _brandsFacade: BrandsFacade,
    private cdRef: ChangeDetectorRef,
    private router: Router,
    private _dataStorageService: DataStorageService,
    private _dialog: MatDialog,
    private _sidenavService: SidenavService,
    private _snackbarService: SnackbarService,
    public navigationService: NavigationService
  ) {
    this.storedUser = this._dataStorageService.get(USER, Storage.local)
      ? JSON.parse(this._dataStorageService.get(USER, Storage.local))
      : JSON.parse(this._dataStorageService.get(USER, Storage.session));
  }

  ngOnInit(): void {
    this.languages$ = this._languagesFacade.getLanguages$();
    let user = this._dataAuthService.user;
    user = typeof user === 'string' ? JSON.parse(user) : user;

    this.isCsmUser = user?.user_permissions.includes('customer_success_manager') as boolean;

    this.router.events
      .pipe(filter((event): event is NavigationEnd => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.isOnSupplyChainsPage = event.url.includes('/product-chains');
      });

    this.isSupplierUser = user?.is_supplier as boolean;

    if (this.isSupplierUser) {
      this.navList = SupplierUserNavList;
      this._sidenavService.enableDisableSuppLinks$.pipe(takeUntil(this._unsubscribe$)).subscribe(res => {
        this.enableDisableSuppLinks(res);
      });
    }

    this.detectScreen();

    this.storedUser.brand &&
      this._brandsFacade.getBrand$(this.storedUser.brand).subscribe({
        next: this.getBrandSuccess.bind(this),
        error: this._error.bind(this),
      });
    this.treaceActiveRoute();
  }
  /**
   * This method is trigering change detection in order to activate the activeRoute pipe on global level,
   *  we are invoking at the end of every redirection
   */
  treaceActiveRoute(): void {
    this.router.events
      .pipe(
        takeUntil(this._unsubscribe$),
        filter(event => event instanceof NavigationEnd)
      )
      .subscribe(() => {
        /**
         * Trigger change detection every time the route changes,
         *  but first we filter only navigation ends in order to trigger only once per change
         */
        this.cdRef.markForCheck();
      });
  }

  ngOnDestroy(): void {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }

  onToggleSidenav() {
    this.openSnav = !this.openSnav;
    this.open.emit(this.openSnav);
  }

  changeLanguage(langName: string): void {
    this.localLanguageName = langName === 'uk' ? 'en-uk' : langName;
    this.translations.setDefaultLang(this.localLanguageName);
    this.translations.use(this.localLanguageName);
  }

  detectScreen() {
    this.breakpointObserver
      .observe(['(max-width: 1023px)'])
      .pipe(takeUntil(this._unsubscribe$))
      .subscribe((result: BreakpointState) => {
        this.small_screen = result.matches;
        this._cdRef.detectChanges();
      });
  }

  enableDisableSuppLinks(validated: boolean) {
    this.navList = this.navList.map(row => {
      if (this.isSupplierUser && row.label !== 'Company Info') row.isDisabled = !validated;
      return row;
    });
    this._cdRef.detectChanges();
  }

  private getBrandSuccess(response: IBrandListResult) {
    this.brand = response;
    this._dataStorageService.set(BRAND_LOGO, this.brand.logo, Storage.local);
    this._dataStorageService.set(BRAND_NAME, this.brand.name, Storage.local);
    this._cdRef.detectChanges();
  }

  private _error(error: Record<string, string>): void {
    Object.values(error).map(err => this._snackbarService.openTypeSnackbar(err, NotificationType.error));
  }

  needSupportDialog(): void {
    const dialogRef = this._dialog.open(SupportDialogComponent, {
      width: '1160px',
      height: '660px',
      panelClass: ['top-padding-0', 'position-relative'],
    });
    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.ngOnInit();
      }
    });
  }
}
