import { ChainOverviewGroup } from '../../chain-overview.group';
import { Component, Inject } from '@angular/core';
import { PublicationFacade } from '@app/core/facade/publication.facade';
import { FormControl, UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { forkJoin, switchMap } from 'rxjs';
import { GetDirtyValues } from '@app/core/utils/form-dirty-values';
import { ICreatePublicationStep } from '@app/core/interface/publication.interface';
import { ISupplier, ISupplierResponse } from '@app/core/interface/suppliers.interface';
import { IManufactory } from '@app/core/interface/manufactories.interface';
import { NotificationType, Storage } from '@app/core/constants';
import { SnackbarService } from '@app/core/service/snackbar.service';
import { DataStorageService } from '@service/data-localstorage.service';
import { CustomPublicationsFacade } from '@app/module/publication/custom-publications/custom-publications.facade';
import {
  ICustomPublication,
  ICustomPublicationStep,
} from '@app/module/publication/custom-publications/custom-publications.models';

@Component({
  selector: 'app-create-step',
  templateUrl: './create-step.component.html',
  styleUrls: ['./create-step.component.scss'],
})
export class CreateStepComponent {
  createStepForm: UntypedFormGroup;
  createSupplierForm: UntypedFormGroup;
  createManufactoryForm: UntypedFormGroup;
  newSupplierUuid!: string;
  supplier!: ISupplierResponse;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { productChainUuid: string; step: ICustomPublicationStep },
    private _group: ChainOverviewGroup,
    private _facade: PublicationFacade,
    private _customPublicationFacade: CustomPublicationsFacade,
    private _snackbarService: SnackbarService,
    public dialogRef: MatDialogRef<CreateStepComponent>,
    public _dataStorageService: DataStorageService
  ) {
    this.createStepForm = this._group.createStepForm;
    this.createSupplierForm = this._group.createSupplierForm;
    this.createManufactoryForm = this._group.createManufactoryForm;
    const brandName = this._dataStorageService.get('brand_name', Storage.local);
    const isAmway = brandName?.toLowerCase() === 'amway';
    this.setupStepForm();
    this.setupSupplierForm();
    this.setupManufactoryForm();
    if (this.data.step?.supplier) {
      if (isAmway) {
        this.createSupplierForm.addControl('is_address_private', new FormControl(this.supplier?.is_address_private));
        this.createSupplierForm.addControl(
          'is_description_private',
          new FormControl(this.supplier?.is_description_private)
        );
        this.createSupplierForm.addControl('is_tags_private', new FormControl(this.supplier?.is_tags_private));
        this.createSupplierForm.addControl('is_country_private', new FormControl(this.supplier?.is_country_private));
        this.createSupplierForm.addControl('is_name_private', new FormControl(this.supplier?.is_name_private));
        this.createSupplierForm.addControl('is_logo_private', new FormControl(this.supplier?.is_logo_private));
      }
      this.createSupplierForm.addControl('uuid', new FormControl(this.data.step.supplier.uuid));
      this.createSupplierForm.get('uuid')?.setValue(this.data.step.supplier.uuid);
      this.getSupplierDetails(this.data.step.supplier.uuid);
    }
  }
  getSupplierDetails(uuid: string): void {
    this._customPublicationFacade.getSupplier$(uuid).subscribe({
      next: (data: ISupplierResponse) => (this.supplier = data),
      error: this._error.bind(this),
    });
  }
  checkBeforeCreate(): void {
    if (this.createStepForm.valid && this.createSupplierForm.valid && this.validateMapFields()) {
      this.createStepSupplierManufactory();
    } else if (this.createStepForm.valid && this.createSupplierForm.valid && !this.validateMapFields()) {
      this.createStepAndSupplier();
    } else if (this.createStepForm.valid) {
      this.createStep();
    }
  }
  createStepSupplierManufactory(): void {
    const changedStepFormValues: Partial<ICreatePublicationStep> = GetDirtyValues(this.createStepForm);
    const changedSupplierFormValues: Partial<ISupplier> = GetDirtyValues(this.createSupplierForm);
    const changedManufactoryFormValues: Partial<IManufactory> = GetDirtyValues(this.createManufactoryForm);
    this._customPublicationFacade
      .createStepSupplierManufactory$(
        this.data.productChainUuid,
        changedStepFormValues,
        changedSupplierFormValues,
        changedManufactoryFormValues
      )
      .subscribe({
        next: this._updateStepSuccess.bind(this),
        error: this._error.bind(this),
      });
  }
  createStepAndSupplier(): void {
    const changedStepFormValues: Partial<ICreatePublicationStep> = GetDirtyValues(this.createStepForm);
    const changedSupplierFormValues: Partial<ISupplier> = GetDirtyValues(this.createSupplierForm);
    this._customPublicationFacade
      .createStepAndSupplier$(this.data.productChainUuid, changedStepFormValues, changedSupplierFormValues)
      .subscribe({
        next: this._updateStepSuccess.bind(this),
        error: this._error.bind(this),
      });
  }
  createStep(): void {
    const changedStepFormValues: Partial<ICreatePublicationStep> = GetDirtyValues(this.createStepForm);
    this._customPublicationFacade.createStep$(changedStepFormValues, this.data.productChainUuid).subscribe({
      next: this._updateStepSuccess.bind(this),
      error: this._error.bind(this),
    });
  }

  setupStepForm(): void {
    this.createStepForm.reset();
    if (this.data.step) {
      this.createStepForm.patchValue({
        name: this.data.step.name,
        description: this.data.step.description,
      });
    }
    this.createStepForm.patchValue({
      product_chain: this.data.productChainUuid,
      position_x: 0,
      position_y: 0,
    });
    this.createStepForm.get('product_chain')?.markAsDirty();
    this.createStepForm.get('position_x')?.markAsDirty();
    this.createStepForm.get('position_y')?.markAsDirty();
  }
  setupSupplierForm(): void {
    this.createSupplierForm.reset();
    if (this.data.step && this.data.step.supplier) {
      this.createSupplierForm.patchValue({
        name: this.data.step.supplier.name,
        logo: this.data.step.supplier.logo,
        description: this.data.step.supplier.description,
      });
    }
    this.createSupplierForm.get('product_chain')?.setValue(this.data.productChainUuid);
    this.createSupplierForm.get('product_chain')?.markAsDirty();
  }
  setupManufactoryForm(): void {
    this.createManufactoryForm.reset();
    if (this.data.step && this.data.step.manufactories) {
      this.createManufactoryForm.patchValue({
        address: this.data.step.manufactories[this.data.step.manufactories.length - 1]?.address ?? '',
        city: this.data.step.manufactories[this.data.step.manufactories.length - 1]?.city ?? '',
        country: this.data.step.manufactories[this.data.step.manufactories.length - 1]?.country ?? '',
      });
    }
    if (this.data.step && this.data.step.supplier) {
      this.createManufactoryForm.patchValue({
        supplier: this.data.step.supplier.uuid,
      });
    }
    this.createManufactoryForm.get('name')?.setValue('No name');
    this.createManufactoryForm.get('name')?.markAsDirty();
    this.createManufactoryForm.get('supplier')?.markAsDirty();
  }
  checkBeforeUpdate(): void {
    if (this.createStepForm.valid && this.createSupplierForm.valid && this.validateMapFields()) {
      this.checkUpdateType();
    } else if (this.createStepForm.valid && this.createSupplierForm.valid && !this.validateMapFields()) {
      if (!this.data.step.supplier?.uuid) {
        this.updateStepNewSupplier();
      } else {
        this.updateStepAndSupplier();
      }
    } else if (this.createStepForm.valid) {
      this.updateStep();
    }
  }
  checkUpdateType(): void {
    if (
      !this.data.step.manufactories[this.data.step.manufactories.length - 1]?.uuid &&
      !this.data.step.supplier?.uuid
    ) {
      this.updateStepNewSupplierAndManufactory();
    } else if (!this.data.step.manufactories[this.data.step.manufactories.length - 1]?.uuid) {
      this.updateStepNewManufactory();
    } else {
      this.updateStepSupplierManufactory();
    }
  }

  updateStepSupplierManufactory(): void {
    const changedStepFormValues: Partial<ICreatePublicationStep> = GetDirtyValues(this.createStepForm);
    const changedSupplierFormValues: Partial<ISupplier> = GetDirtyValues(this.createSupplierForm);
    const changedManufactoryFormValues: Partial<IManufactory> = GetDirtyValues(this.createManufactoryForm);
    let supplierUpdate;
    if (this.createSupplierForm.controls['uuid']?.value) {
      supplierUpdate = this._customPublicationFacade.updatePublicationSupplier(
        { ...changedSupplierFormValues },
        this.createSupplierForm.controls['uuid']?.value
      );
    } else {
      supplierUpdate = this._customPublicationFacade.createPublicationSupplier$({ ...changedSupplierFormValues });
    }

    const stepUpdate = this._customPublicationFacade.updateStep$(
      { ...changedStepFormValues, supplier: this.createSupplierForm.controls['uuid']?.value },
      this.data.step.uuid
    );
    let manufactoryUpdate;
    if (this.createManufactoryForm.controls['uuid']?.value) {
      manufactoryUpdate = this._customPublicationFacade.updatePublicationManufactory$(
        { ...changedManufactoryFormValues },
        this.createManufactoryForm.controls['uuid']?.value
      );
    } else {
      manufactoryUpdate = this._customPublicationFacade.createPublicationManufactory$({
        ...changedManufactoryFormValues,
      });
    }

    forkJoin({
      stepUpdate,
      supplierUpdate,
      manufactoryUpdate,
    }).subscribe({
      next: this._updateStepSuccess.bind(this),
      error: this._error.bind(this),
    });
  }

  updateStepNewManufactory(): void {
    const changedStepFormValues: Partial<ICreatePublicationStep> = GetDirtyValues(this.createStepForm);
    const changedSupplierFormValues: Partial<ISupplier> = GetDirtyValues(this.createSupplierForm);
    const changedManufactoryFormValues: Partial<IManufactory> = GetDirtyValues(this.createManufactoryForm);
    let manufactoryUuid: string;
    this._customPublicationFacade
      .createPublicationManufactory$({ ...changedManufactoryFormValues })
      .pipe(
        switchMap(res => {
          manufactoryUuid = res.uuid;

          return this._customPublicationFacade.updatePublicationSupplier(
            { ...changedSupplierFormValues },
            this.data.step.supplier.uuid
          );
        }),
        switchMap(res => {
          return this._customPublicationFacade.updateStep$(
            { ...changedStepFormValues, manufactories: manufactoryUuid, supplier: res.uuid },
            this.data.step.uuid
          );
        })
      )
      .subscribe({
        next: this._updateStepSuccess.bind(this),
        error: this._error.bind(this),
      });
  }

  updateStepNewSupplierAndManufactory(): void {
    const changedStepFormValues: Partial<ICreatePublicationStep> = GetDirtyValues(this.createStepForm);
    const changedSupplierFormValues: Partial<ISupplier> = GetDirtyValues(this.createSupplierForm);
    const changedManufactoryFormValues: Partial<IManufactory> = GetDirtyValues(this.createManufactoryForm);
    this._customPublicationFacade
      .createPublicationSupplier$({ ...changedSupplierFormValues })
      .pipe(
        switchMap(res => {
          this.newSupplierUuid = res.uuid;
          this.createSupplierForm.controls['uuid']?.setValue(this.newSupplierUuid);
          return this._customPublicationFacade.createPublicationManufactory$({
            ...changedManufactoryFormValues,
            supplier: res.uuid,
          });
        }),
        switchMap(res => {
          return this._facade.updatePublicationChainStep$(
            { ...changedStepFormValues, manufactories: res.uuid, supplier: this.newSupplierUuid },
            this.data.step.uuid
          );
        })
      )
      .subscribe({
        next: this._updateStepSuccess.bind(this),
        error: this._error.bind(this),
      });
  }
  updateStepAndSupplier(): void {
    const changedStepFormValues: Partial<ICreatePublicationStep> = GetDirtyValues(this.createStepForm);
    const changedSupplierFormValues: Partial<ISupplier> = GetDirtyValues(this.createSupplierForm);
    const supplierUpdate = this._customPublicationFacade.updatePublicationSupplier(
      { ...changedSupplierFormValues },
      this.data.step.supplier.uuid
    );
    const stepUpdate = this._facade.updatePublicationChainStep$({ ...changedStepFormValues }, this.data.step.uuid);
    forkJoin({
      stepUpdate,
      supplierUpdate,
    }).subscribe({
      next: this._updateStepSuccess.bind(this),
      error: this._error.bind(this),
    });
  }

  updateStepNewSupplier(): void {
    const changedStepFormValues: Partial<ICreatePublicationStep> = GetDirtyValues(this.createStepForm);
    const changedSupplierFormValues: Partial<ISupplier> = GetDirtyValues(this.createSupplierForm);
    this._customPublicationFacade
      .createPublicationSupplier$({ ...changedSupplierFormValues })
      .pipe(
        switchMap(res => {
          return this._facade.updatePublicationChainStep$(
            { ...changedStepFormValues, supplier: res.uuid },
            this.data.step.uuid
          );
        })
      )
      .subscribe({
        next: this._updateStepSuccess.bind(this),
        error: this._error.bind(this),
      });
  }
  updateStep(): void {
    const changedStepFormValues: Partial<ICreatePublicationStep> = GetDirtyValues(this.createStepForm);
    this._facade.updatePublicationChainStep$(changedStepFormValues, this.data.step.uuid).subscribe({
      next: this._updateStepSuccess.bind(this),
      error: this._error.bind(this),
    });
  }
  validateMapFields(): boolean {
    const address = this.createManufactoryForm.get('address')?.value;
    const city = this.createManufactoryForm.get('city')?.value;
    const country = this.createManufactoryForm.get('country')?.value;
    if (!address && !city && !country) {
      return false;
    }
    return true;
  }
  refreshStep(): void {
    if (this.data.productChainUuid) {
      this._customPublicationFacade.getPublication$(this.data.productChainUuid).subscribe({
        next: data => {
          const mergedSteps = this.mergeSteps(data);
          const step = mergedSteps.find(step => step.uuid === this.data.step.uuid);
          if (step) this.data.step = step;
        },
        error: this._error.bind(this),
      });
    }
  }

  mergeSteps(data: ICustomPublication): ICustomPublicationStep[] {
    const allSteps: ICustomPublicationStep[] = [];
    data.type_steps.forEach(typeStep => {
      allSteps.push(...typeStep.steps);
      typeStep.groups.forEach(group => {
        allSteps.push(...group.steps);
      });
    });
    allSteps.push(...data.unclassified_steps);
    return allSteps;
  }
  private _updateStepSuccess(): void {
    this.dialogRef.close(true);
  }
  private _error(error: Record<string, string[]>): void {
    Object.values(error).map(err => this._snackbarService.openTypeSnackbar(err[0], NotificationType.error));
  }
}
