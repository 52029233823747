import { Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { IsCountry } from '@app/core/utils/country.validator';

@Injectable()
export class DigitalSheetGroup {
  countryForm: UntypedFormGroup;
  packagingForm: UntypedFormGroup;
  plasticPresentce: UntypedFormGroup;
  recycledMaterialsForm: UntypedFormGroup;
  hazardousForm: UntypedFormGroup;

  constructor(private _fb: UntypedFormBuilder) {
    this.countryForm = this._fb.group(
      {
        country: [null, Validators.required],
        choice: [null, Validators.required],
      },
      { validator: IsCountry() }
    );
    this.packagingForm = this._fb.group({
      name: [null, Validators.required],
      type: [null, Validators.required],
      recyclability: [null],
      recycled_materials: [null],
      hazardous_substances: [null],
    });
    this.plasticPresentce = this._fb.group({
      plastic_presetce: [null, Validators.required],
      hazardous_substances: [null, Validators.required],
      hazardous_list: this._fb.array([this._fb.control('', Validators.required)]),
    });
    this.recycledMaterialsForm = this._fb.group({
      recycled_materials: [null, Validators.required],
    });
    this.hazardousForm = this._fb.group({
      hazardous: [null, Validators.required],
      // hazardous_substances: this._fb.array([this._fb.control('', Validators.required)]),
    });
  }
}
