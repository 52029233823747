import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DigitalSheetGroup } from '../create-digital-product-sheet-dialog.group';

@Component({
  selector: 'app-recycled-materials',
  templateUrl: './recycled-materials.component.html',
  styleUrls: ['./recycled-materials.component.scss'],
  providers: [DigitalSheetGroup],
})
export class RecycledMaterialsComponent {
  form: FormGroup;
  types = [
    { uuid: 0, name: 'There are recycled materials', value: true },
    { uuid: 1, name: 'There are no recycled materials used in this product', value: false },
  ];
  selectedType? = { name: '', uuid: -1, value: false };
  showInfo = true;
  constructor(private _group: DigitalSheetGroup) {
    this.form = _group.recycledMaterialsForm;
  }
  onSelection(type: { uuid: number; name: string; value: boolean } | undefined) {
    if (!type) return;
    this.selectedType = type;
    if (this.selectedType.uuid === 0) {
      !this.form.controls['percentage'] && this.form.addControl('percentage', new FormControl('', Validators.required));
    } else {
      this.form.controls['percentage'] && this.form.removeControl('percentage');
    }
  }
}
