import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { NotificationType } from '@app/core/constants';
import { CustomerSuccessManagementFacade } from '@app/core/facade/customer-success-manager.facade';
import {
  IAssignBrandCsmResponse,
  IAssignBrandCsmResult,
  ICsmUserAssign,
  ICsmUserResponse,
  ICsmUsersResult,
} from '@app/core/interface/customer-success-management.interface';
import { SnackbarService } from '@app/core/service/snackbar.service';

@Component({
  selector: 'app-assign-brands-table',
  templateUrl: './assign-brands-table.component.html',
  styleUrls: ['./assign-brands-table.component.scss'],
  providers: [CustomerSuccessManagementFacade],
})
export class AssignBrandsTableComponent implements OnInit {
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  displayedColumns: string[] = ['brand_name', 'assigned_csm_user'];
  dataSource = new MatTableDataSource<IAssignBrandCsmResult>();
  csmBrands: IAssignBrandCsmResult[] = [];
  csmUsers: ICsmUsersResult[] = [];

  select_csm_user = new UntypedFormControl(null);

  constructor(
    private _CSMFacade: CustomerSuccessManagementFacade,
    private _snackbarService: SnackbarService
  ) {}

  ngOnInit(): void {
    this._CSMFacade.getCsmBrands$().subscribe({
      next: this._setCsmBrands.bind(this),
      error: this._error.bind(this),
    });

    this._CSMFacade.getCsmUsers$().subscribe({
      next: this._setCsmUsers.bind(this),
      error: this._error.bind(this),
    });
  }

  setDropdownValue(csm_user_uuid: string): ICsmUsersResult {
    return this.csmUsers.find(user => user.uuid === csm_user_uuid) as ICsmUsersResult;
  }

  private _setCsmBrands(data: IAssignBrandCsmResponse): void {
    this.csmBrands = data.results;
    this.dataSource.data = data.results;
  }

  private _setCsmUsers(data: ICsmUserResponse): void {
    this.csmUsers = data.results;
  }

  onUserChanged(user_uuid: string, brand_uuid: string) {
    const assignUser: ICsmUserAssign = { brand: brand_uuid, user: user_uuid };

    this._CSMFacade.assignCsmUser$(assignUser).subscribe({
      next: this._assignUserSuccess.bind(this),
      error: this._error.bind(this),
    });
  }

  private _assignUserSuccess() {
    this._snackbarService.openTypeSnackbar(`User has been assigned`, NotificationType.success);
  }

  private _error(error: Record<string, string>): void {
    Object.values(error).map(err => this._snackbarService.openTypeSnackbar(err, NotificationType.error));
  }
}
