<div class="d-flex md-screen">
  <!-- <mat-accordion> -->
  <div *ngIf="accountDetails" class="profile">
    <div class="logo"></div>
    <div class="profile-details-holder">
      <div class="details">
        <div class="name-holder">
          <img
            class="image"
            src="{{
              accountDetails.avatar ? accountDetails.avatar : '../../../../../assets/images/profile-avatar.png'
            }}"
            alt=""
          />
          <!-- <div class="image"></div> -->
          <h5>{{ accountDetails.first_name }} {{ accountDetails.last_name }}</h5>
        </div>

        <div class="description-sm">
          <div class="label">
            <app-custom-icon [icon_category]="'essential'" [icon_name]="'tick-circle-blue'"></app-custom-icon>
            <h6>{{ 'Your Profile Details' | translate }}</h6>
          </div>
          <p>{{ 'You can update the info at anytime, after the setup.' | translate }}</p>
        </div>
      </div>

      <div class="divider"></div>
      <div class="info">
        <div class="role">
          <h6>{{ 'Position' | translate }}</h6>
          <p>{{ accountDetails.function ? accountDetails.function : '/' }}</p>
        </div>
        <div class="phone">
          <h6>{{ 'Phone' | translate }}</h6>
          <p>{{ accountDetails.phone }}</p>
        </div>
      </div>

      <div class="description">
        <div class="label">
          <app-custom-icon [icon_category]="'essential'" [icon_name]="'tick-circle-blue'"></app-custom-icon>
          <h6>{{ 'Your Profile Details' | translate }}</h6>
        </div>
        <p>{{ 'You can update the info at anytime, after the setup.' | translate }}</p>
      </div>
    </div>
  </div>
  <div class="brand" *ngIf="brand">
    <div class="brand-details-holder">
      <div class="details">
        <div class="name-holder">
          <img
            class="brand-image"
            src="{{ brand.logo ? brand.logo : '../../../../../assets/images/brand-avatar.png' }}"
            alt=""
          />
          <!-- <div class="brand-image"></div> -->
          <h5 class="brand-name">{{ brand.name }}</h5>
        </div>
        <div class="created-brand-sm">
          <div class="label">
            <app-custom-icon [icon_category]="'essential'" [icon_name]="'tick-circle'"></app-custom-icon>
            <h6>{{ 'Your Created Brand' | translate }}</h6>
          </div>
          <p>{{ 'You can update the info at anytime, after the setup.' | translate }}</p>
        </div>
      </div>
      <div class="divider"></div>
      <div class="description">
        <div class="industry">
          <h6>{{ 'Industry' | translate }}</h6>
          <p>{{ brand.domain_activity.name }}</p>
        </div>
        <div class="description-info">
          <div class="summary">
            <h6>{{ 'Brand Description' | translate }}</h6>
            <p>
              <!-- {{ brand.summary }} -->
              <app-view-rich-text [content]="brand.description"></app-view-rich-text>
            </p>
          </div>
          <div class="brand-description">
            <h6>{{ 'Traceability Commitment' | translate }}</h6>
            <p>
              <app-view-rich-text [content]="brand.traceability_commitment"></app-view-rich-text>
            </p>
          </div>
        </div>
      </div>
      <div class="created-brand">
        <div class="label">
          <app-custom-icon [icon_category]="'essential'" [icon_name]="'tick-circle'"></app-custom-icon>
          <h6>{{ 'Your Created Brand' | translate }}</h6>
        </div>
        <p>{{ 'You can update the info at anytime, after the setup.' | translate }}</p>
      </div>
    </div>
  </div>
  <div *ngIf="product" class="product">
    <div class="product-details-holder">
      <div class="details">
        <div class="name-holder">
          <img
            class="product-image"
            src="{{ product.image ? product.image : '../../../../../assets/images/brand-avatar.png' }}"
            alt=""
          />
          <!-- <div class="product-image"></div> -->
          <h5 class="product-name">{{ product.name }}</h5>
        </div>

        <div class="created-product-sm">
          <div class="label">
            <app-custom-icon [icon_category]="'essential'" [icon_name]="'tick-circle'"></app-custom-icon>
            <h6>{{ 'Your Created Product' | translate }}</h6>
          </div>
          <p>{{ 'You can update the info at anytime, after the setup.' | translate }}</p>
        </div>
      </div>
      <div class="divider"></div>
      <div class="description">
        <div class="product-description">
          <h6>{{ 'Product Description' | translate }}</h6>
          <p>
            <app-view-rich-text [content]="product.description"></app-view-rich-text>
          </p>
        </div>
        <div class="summary">
          <h6>{{ 'Product Reference Number' | translate }}</h6>
          <p>{{ product.reference }}</p>
        </div>
      </div>
      <div class="created-product">
        <div class="label">
          <app-custom-icon [icon_category]="'essential'" [icon_name]="'tick-circle'"></app-custom-icon>
          <h6>{{ 'Your Created Product' | translate }}</h6>
        </div>
        <p>{{ 'You can update the info at anytime, after the setup.' | translate }}</p>
      </div>

      <div class="button-holder">
        <app-rounded-button
          (click)="completeSetup()"
          color="primary"
          button_text="{{ 'Proceed to the App' | translate }}"
          [icon_category]="'arrows'"
          [icon_name]="'arrow-right-long'"
          [icon_right]="true"
        >
        </app-rounded-button>
      </div>
    </div>
  </div>
  <!-- </mat-accordion> -->
</div>

<div class="d-flex mobile">
  <!-- #docregion basic-panel -->
  <!-- #docregion hide-toggle -->
  <mat-accordion>
    <div *ngIf="accountDetails" class="profile">
      <div class="logo"></div>
      <div class="profile-details-holder">
        <div class="details">
          <div class="name-holder">
            <img
              class="image"
              src="{{
                accountDetails.avatar ? accountDetails.avatar : '../../../../../assets/images/profile-avatar.png'
              }}"
              alt=""
            />
            <h5>{{ accountDetails.first_name }} {{ accountDetails.last_name }}</h5>
          </div>

          <div class="description-sm">
            <div class="label">
              <app-custom-icon [icon_category]="'essential'" [icon_name]="'tick-circle-blue'"></app-custom-icon>
              <h6>{{ 'Your Profile Details' | translate }}</h6>
            </div>
            <p>{{ 'You can update the info at anytime, after the setup.' | translate }}</p>
          </div>
        </div>

        <div class="divider"></div>
      </div>
      <mat-expansion-panel hideToggle>
        <!-- #enddocregion hide-toggle -->
        <mat-expansion-panel-header>
          <mat-panel-title>
            <p>{{ 'See more of your profile' | translate }}</p>
            <app-custom-icon [icon_category]="'arrows'" [icon_name]="'arrow-square-down-white'"></app-custom-icon>
          </mat-panel-title>
          <!-- <mat-panel-description>
            This is a summary of the content
          </mat-panel-description> -->
        </mat-expansion-panel-header>
        <div class="info">
          <div class="role">
            <h6>{{ 'Role' | translate }}</h6>
            <p>{{ accountDetails.role ? accountDetails.role : '/' }}</p>
          </div>
          <div class="phone">
            <h6>{{ 'Phone' | translate }}</h6>
            <p>{{ accountDetails.phone }}</p>
          </div>
        </div>
        <div class="description">
          <div class="label">
            <app-custom-icon [icon_category]="'essential'" [icon_name]="'tick-circle-blue'"></app-custom-icon>
            <h6>{{ 'Your Profile Details' | translate }}</h6>
          </div>
          <p>{{ 'You can update the info at anytime, after the setup.' | translate }}</p>
        </div>
      </mat-expansion-panel>
    </div>

    <div *ngIf="brand" class="brand">
      <div class="brand-details-holder">
        <div class="details">
          <div class="name-holder">
            <img
              class="brand-image"
              src="{{ brand.logo ? brand.logo : '../../../../../assets/images/brand-avatar.png' }}"
              alt=""
            />
            <h5 class="brand-name">{{ brand.name }}</h5>
          </div>
          <div class="created-brand-sm">
            <div class="label">
              <app-custom-icon [icon_category]="'essential'" [icon_name]="'tick-circle'"></app-custom-icon>
              <h6>{{ 'Your Created Brand' | translate }}</h6>
            </div>
            <p>{{ 'You can update the info at anytime, after the setup.' | translate }}</p>
          </div>
        </div>
        <!-- #enddocregion basic-panel -->
        <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <p>{{ 'See more of your brand' | translate }}</p>
              <app-custom-icon [icon_category]="'arrows'" [icon_name]="'arrow-square-down'"></app-custom-icon>
            </mat-panel-title>
            <!-- <mat-panel-description>
                            Currently I am {{panelOpenState ? 'open' : 'closed'}}
                        </mat-panel-description> -->
          </mat-expansion-panel-header>
          <!-- <p>I'm visible because I am open</p> -->

          <div class="divider"></div>
          <div class="description">
            <div class="industry">
              <h6>{{ 'Industry' | translate }}</h6>
              <p>{{ brand.domain_activity.name }}</p>
            </div>
            <div class="description-info">
              <div class="summary">
                <h6>{{ 'Brand Summary' | translate }}</h6>
                <p>
                  <!-- {{ brand.summary }} -->
                  <app-view-rich-text [content]="brand.description"></app-view-rich-text>
                </p>
              </div>
              <div class="brand-description">
                <h6>{{ 'Brand description' | translate }}</h6>
                <p>
                  <app-view-rich-text [content]="brand.traceability_commitment"></app-view-rich-text>
                </p>
              </div>
            </div>
          </div>
          <div class="created-brand">
            <div class="label">
              <app-custom-icon [icon_category]="'essential'" [icon_name]="'tick-circle'"></app-custom-icon>
              <h6>{{ 'Your Created Brand' | translate }}</h6>
            </div>
            <p>{{ 'You can update the info at anytime, after the setup.' | translate }}</p>
          </div>
        </mat-expansion-panel>
      </div>
    </div>
    <div *ngIf="product" class="product">
      <div class="product-details-holder">
        <div class="details">
          <div class="name-holder">
            <!-- <div class="product-image"></div> -->
            <img
              class="product-image"
              src="{{ product.image ? product.image : '../../../../../assets/images/brand-avatar.png' }}"
              alt=""
            />
            <h5 class="product-name">{{ product.name }}</h5>
          </div>

          <div class="created-product-sm">
            <div class="label">
              <app-custom-icon [icon_category]="'essential'" [icon_name]="'tick-circle'"></app-custom-icon>
              <h6>{{ 'Your Created Product' | translate }}</h6>
            </div>
            <p>{{ 'You can update the info at anytime, after the setup.' | translate }}</p>
          </div>
        </div>
        <!-- #enddocregion basic-panel -->
        <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <p>{{ ' See more of your product' | translate }}</p>
              <app-custom-icon [icon_category]="'arrows'" [icon_name]="'arrow-square-down'"></app-custom-icon>
            </mat-panel-title>
            <!-- <mat-panel-description>
                                Currently I am {{panelOpenState ? 'open' : 'closed'}}
                            </mat-panel-description> -->
          </mat-expansion-panel-header>
          <div class="divider"></div>
          <div class="description">
            <div class="product-description">
              <h6>{{ 'Product Description' | translate }}</h6>
              <p>
                <app-view-rich-text [content]="product.description"></app-view-rich-text>
              </p>
              <!--RTETODO-->
            </div>
            <div class="summary">
              <h6>{{ 'Product Reference Number' | translate }}</h6>
              <p>{{ product.reference }}</p>
            </div>
          </div>
          <div class="created-product">
            <div class="label">
              <app-custom-icon [icon_category]="'essential'" [icon_name]="'tick-circle'"></app-custom-icon>
              <h6>{{ 'Your Created Product' | translate }}</h6>
            </div>
            <p>{{ 'You can update the info at anytime, after the setup.' | translate }}</p>
          </div>
        </mat-expansion-panel>
        <div class="button-holder">
          <app-rounded-button
            (click)="completeSetup()"
            color="primary"
            button_text="{{ 'Proceed to the App' | translate }}"
            [icon_category]="'arrows'"
            [icon_name]="'arrow-right-long'"
            [icon_right]="true"
          >
          </app-rounded-button>
        </div>
      </div>
    </div>
  </mat-accordion>
</div>
