import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProjectsListComponent } from './projects-list/projects-list.component';
import { ProjectsDashboardRoutingModule } from './projects-dashboard-routing.module';
import { ProjectsFacade } from '@app/core/facade/projects.facade';
import { SharedModule } from '@app/shared/shared.module';
import { ProjectSubprojectsComponent } from './project-subprojects/project-subprojects.component';
import { ProjectProductsTracedComponent } from './project-products-traced/project-products-traced.component';
import { ProjectOverviewComponent } from './project-overview/project-overview.component';
import { ProjectDetailsComponent } from './project-details/project-details.component';

@NgModule({
  declarations: [
    ProjectsListComponent,
    ProjectSubprojectsComponent,
    ProjectProductsTracedComponent,
    ProjectOverviewComponent,
    ProjectDetailsComponent,
  ],
  imports: [CommonModule, ProjectsDashboardRoutingModule, SharedModule],
  providers: [ProjectsFacade],
})
export class ProjectsDashboardModule {}
