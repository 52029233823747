<div class="translation-wrapper row">
  <h6 class="head col-12">
    {{ 'Translations Pages' | translate }}
  </h6>
  <hr class="big-hr" />
  <form class="col-12 p-3 row" [formGroup]="translateForm">
    <div class="col-6">
      <div class="transl d-flex flex-column">
        <mat-form-field
          appearance="outline"
          class="col-12 input-primary col mat-form-field-10-px-brd-rd"
          *ngIf="(pageTranslationList | async)?.results as pages"
        >
          <mat-label>{{ 'Select Page' | translate }}</mat-label>
          <mat-select (selectionChange)="selectionChangePage($event)" formControlName="select_page">
            <mat-option *ngFor="let page of pages" [value]="page">
              {{ page.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="transl d-flex flex-column">
        <mat-form-field
          appearance="outline"
          class="col-12 input-primary col mat-form-field-10-px-brd-rd"
          *ngIf="(languageList$ | async)?.results as languages"
        >
          <mat-label>{{ 'Select Language' | translate }}</mat-label>
          <mat-select (selectionChange)="selectionChangeLanguage($event)" formControlName="select_language">
            <mat-option *ngFor="let language of languages" [value]="language.name">
              {{ language.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="col-6">
      <div class="row desc">
        <div class="col-1">
          <img class="logo" src="../../../assets/logos/app-symbol-white.svg" alt="" />
        </div>
        <div class="col-11">
          {{ 'Please choose the page from the dropdown menu, that you want to manage translations for.' | translate }}
        </div>
      </div>
      <div class="row desc">
        <div class="col-1">
          <img class="logo" src="../../../assets/logos/app-symbol-white.svg" alt="" />
        </div>
        <div class="col-11">
          {{
            'Please note that English is the default language, and you can choose other languages if available.'
              | translate
          }}
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-between col-12">
      <div></div>
    </div>
    <div class="translations-wrp row">
      <div class="col-6">
        <h6 class="def-lb">
          {{ 'Default labels' | translate }}
        </h6>
        <div class="txt-lb">
          {{
            'In this section will appear the default label names of the page you will choose in the menu above.'
              | translate
          }}
        </div>
        <hr class="small-hr" />
      </div>
      <div class="col-6">
        <h6 class="renamed-lb">
          {{ 'Renamed labels' | translate }}
        </h6>
        <div class="txt-lb">
          {{
            'In this section you can rename the default labels to something that will be more appropriate to you.'
              | translate
          }}
        </div>
        <hr class="small-hr" />
      </div>
      <form [formGroup]="form" *ngIf="form" class="col-12">
        <div *ngFor="let key of objectKeys(form?.controls)" class="row">
          <div class="col-6">
            <label>{{ key }}</label>
          </div>
          <div class="col-6">
            <div class="row">
              <div class="col-6">
                <mat-form-field class="input-primary" appearance="outline">
                  <input matInput [formControlName]="key" [placeholder]="key" />
                </mat-form-field>
              </div>
              <div class="col-2">
                <app-rounded-button
                  color="tetriary-link"
                  class="btn"
                  [size]="'xsm'"
                  [button_text]="'Cancel'"
                  (click)="form.controls[key].dirty && refreshControl(key)"
                  [disabled]="form.controls[key].pristine"
                >
                </app-rounded-button>
              </div>
              <div class="col-2 offset-1">
                <app-rounded-button
                  color="primary"
                  [size]="'xsm'"
                  class="btn"
                  [button_text]="'Apply'"
                  (click)="form.controls[key].dirty && form.controls[key].valid && edit(key, form.controls[key].value)"
                  [disabled]="form.controls[key].pristine || form.controls[key].invalid"
                >
                </app-rounded-button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </form>
</div>
