import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UsersAccessRoutingModule } from './users-access-routing.module';
import { UsersAccessComponent } from './users-access.component';
import { SharedModule } from '@app/shared/shared.module';
import { AngularMaterialModule } from '@app/material.module';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [UsersAccessComponent],
  imports: [CommonModule, UsersAccessRoutingModule, AngularMaterialModule, SharedModule, ReactiveFormsModule],
})
export class UsersAccessModule {}
