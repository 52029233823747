import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { INameID, IProject, ISubproject } from '@app/core/interface/projects.interface';
import { IProduct, IProductDetails } from '@app/core/interface/products.interface';

import { FormGroup } from '@angular/forms';
import { GetDirtyValues } from '@app/core/utils/form-dirty-values';
import { MatDialog } from '@angular/material/dialog';
import { ProductDashboardGroup } from '../product-dashboard.group';
import { ProductsFacade } from '@app/core/facade/products.facade';
import { ProjectComponent } from '@app/module/projects/project/project.component';

@Component({
  selector: 'app-product-project',
  templateUrl: './product-project.component.html',
  styleUrls: ['./product-project.component.scss'],
})
export class ProductProjectComponent {
  @Input() product!: IProduct;
  @Input() projectsList!: Array<IProject>;
  @Input() projectsListOrg!: Array<IProject>;
  @Input() activeProjectsList!: Array<IProject>;
  @Input() activeProjectsListOrg!: Array<IProject>;
  @Input() subprojectsList!: Array<INameID>;
  @Input() subprojectsListOrg!: Array<INameID>;
  @Input() has_subproject!: boolean;
  @Input() has_project!: boolean;
  @Output() onUpdateSuccess = new EventEmitter<void>();
  @Output() onUpdateError = new EventEmitter<void>();
  need_help = false;
  viewCreateSubProject!: boolean;
  is_project: boolean | undefined;
  form!: FormGroup;
  @ViewChild('inputFilter', { static: false }) inputFilter!: ElementRef;

  constructor(
    private _productDashboardGroup: ProductDashboardGroup,
    private _productsFacade: ProductsFacade,
    private _dialog: MatDialog
  ) {
    this.form = this._productDashboardGroup.productDetails;
  }

  createProjectDialog(uuid: string, is_editing: boolean) {
    const dialogRef = this._dialog.open(ProjectComponent, {
      width: '1160px',
      height: '768px',
      panelClass: 'top-padding-0',
      data: {
        is_editing: is_editing,
        projectId: uuid,
        is_project: true,
      },
    });

    dialogRef.afterClosed().subscribe((result: IProject | ISubproject) => {
      if (result) {
        if (is_editing) {
          this.onUpdateSuccess.emit();
        }
        if (!is_editing) {
          this.addProductToProject(result.uuid, true, false);
        }
      }
    });
  }

  addProductToProject(uuid: string, is_project: boolean, is_switching: boolean): void {
    const changedFormValues: Partial<IProductDetails> = GetDirtyValues(this.form);
    if (this.is_project || is_project) {
      changedFormValues['project'] = uuid;
      if (is_switching) {
        changedFormValues['subproject'] = '';
        this.has_subproject = false;
        this.viewCreateSubProject = false;
      }

      if (uuid == '') {
        this.has_project = false;
        this.has_subproject = false;
      }
    } else {
      changedFormValues['project'] = this.product.project?.uuid;
      changedFormValues['subproject'] = uuid;
      this.has_subproject = false;
    }

    this._productsFacade.updateProduct$({ ...changedFormValues }, this.product.uuid).subscribe({
      next: this._successUpdateProduct.bind(this),
      error: this._error.bind(this),
    });
  }

  private _successUpdateProduct(): void {
    this.onUpdateSuccess.emit();
  }

  updateProduct(): void {
    this.onUpdateSuccess.emit();
  }

  error(): void {
    this.onUpdateError.emit();
  }

  private _error(): void {
    this.onUpdateError.emit();
  }

  createSubProjectDialog(uuid: string, is_editing: boolean) {
    const dialogRef = this._dialog.open(ProjectComponent, {
      width: '1160px',
      height: '768px',
      panelClass: 'top-padding-0',
      data: {
        projectId: this.product.project?.uuid,
        subprojectId: uuid,
        is_editing: is_editing,
        is_project: false,
      },
    });

    dialogRef.afterClosed().subscribe((result: ISubproject | IProject) => {
      if (result) {
        this.is_project = result.is_project;
        if (result.uuid) {
          this.addProductToProject(result.uuid, false, false);
        }
      }
    });
  }

  /**
   * Focus on inputFilter in dropdown menu while typing
   */
  onBlur() {
    this.inputFilter.nativeElement.focus();
  }

  /**
   * Filtering existing projects
   */
  filterProjects(event: Event | KeyboardEvent) {
    const value = (event.target as HTMLInputElement).value;
    if (!value) {
      this.activeProjectsList = this.activeProjectsListOrg;
    } else {
      this.activeProjectsList = this.activeProjectsListOrg.filter(el =>
        el.name.toLowerCase().includes(value.toLowerCase())
      );
    }
  }

  /**
   * Clearing filter/ Clearing input value
   */
  clearFilter(): void {
    this.activeProjectsList = this.activeProjectsListOrg;
    this.inputFilter.nativeElement.value = '';
  }

  /**
   * Clearing filter/ Clearing input value
   */
  clearSubprojectFilter(): void {
    this.subprojectsList = this.subprojectsListOrg;
    this.inputFilter.nativeElement.value = '';
  }

  /**
   * Filtering existing subprojects
   */
  filtersubprojects(event: Event | KeyboardEvent) {
    const value = (event.target as HTMLInputElement).value;
    if (!value) {
      this.subprojectsList = this.subprojectsListOrg;
    } else {
      this.subprojectsList = this.subprojectsListOrg.filter(el => el.name.toLowerCase().includes(value.toLowerCase()));
    }
  }
}
