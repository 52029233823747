import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import {
  ISupplier,
  ISupplierIngredient,
  ISupplierResponse,
  ISuppliersErrorResponse,
} from '@app/core/interface/suppliers.interface';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatTabGroup } from '@angular/material/tabs';
import { SupplierDashboardGroup } from './../supplier-dashboard.group';
import { SuppliersFacade } from '@app/core/facade/suppliers.facade';
import { InfoDialogComponent } from '@app/shared/components/info-dialog/info-dialog.component';
import { UntypedFormGroup } from '@angular/forms';
import { SupplierDetailsComponent } from './supplier-details/supplier-details.component';
import { SuppliersService } from '@app/core/service/suppliers.service';
import { ManufactoriesService } from '@app/core/service/manufactories.service';
import { SupplierSitesComponent } from './supplier-sites/supplier-sites.component';
import { SupplierComponentsComponent } from './supplier-components/supplier-components.component';
import { IngredientService } from '@app/core/service/ingredient.service';
import { IManufactory } from '@app/core/interface/manufactories.interface';
import { infoDialogHeight, infoDialogWidth, NotificationType } from '@app/core/constants';
import { SnackbarService } from '@app/core/service/snackbar.service';

@Component({
  selector: 'app-create-supplier',
  templateUrl: './create-supplier.component.html',
  styleUrls: ['./create-supplier.component.scss'],
  providers: [SupplierDashboardGroup, SuppliersFacade],
})
export class CreateSupplierComponent {
  @ViewChild('tabs') tabGroup!: MatTabGroup;
  @ViewChild('supplierDetails') supplierDetails!: SupplierDetailsComponent;
  @ViewChild('supplieSites') supplierSites!: SupplierSitesComponent;
  @ViewChild('supplierComponents') supplierComponents!: SupplierComponentsComponent;
  @ViewChild('dialogContent', { static: false }) dialogContent!: ElementRef;
  supplier!: ISupplierResponse;
  supplierUuid!: string;
  supplierName!: string;
  productId!: string;
  infoSiteTooltip!: boolean;
  infoComponentTooltip!: boolean;
  product_chain_uuid!: string | null;
  isShowNote = true;
  action!: string;
  form!: UntypedFormGroup;
  selectedSupplier?: ISupplier;
  selectedIndex = 0;
  isCreateDetails = true;
  detailsFormValidation = false;
  isCreateSite = false;
  sitesFormValidation = false;
  show_buttons!: boolean;
  component_btn!: boolean;
  hideSupplierComponent = false;
  addComponent = false;
  hideSupplierSIte = false;
  componentCreated = false;
  site!: IManufactory;
  componentView!: boolean;
  createdSupplier = false;
  createdSIte!: boolean;
  siteForm!: boolean;
  ingredients!: ISupplierIngredient[];
  isUpdateComponent = false;
  isUpdateSite = false;
  componentDeleted = false;
  constructor(
    public dialogRef: MatDialogRef<CreateSupplierComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { productId: string; product_chain_uuid: string; show_buttons: boolean; component_btn: boolean },
    public dialog: MatDialog,
    private _group: SupplierDashboardGroup,
    private supplierService: SuppliersService,
    private manufactoriesService: ManufactoriesService,
    private ingredientService: IngredientService,
    private _snackbarService: SnackbarService
  ) {
    this.productId = data?.productId;
    this.product_chain_uuid = data?.product_chain_uuid;
    this.form = this._group.newSupplierForm;
    dialogRef.disableClose = true;
    if (data) {
      this.show_buttons = this.data.show_buttons;
      this.component_btn = this.data.component_btn;
    }
  }

  supplierCreated(data: ISupplier): void {
    this.action = data.action;
    this.supplierUuid = data.uuid;
    this.supplierName = data.name;
    this.createdSupplier = true;
  }

  getSupplier(): void {
    this.supplierService.getSupplier$(this.supplierUuid).subscribe({
      next: this._setSupplierResponse.bind(this),
      error: this._error.bind(this),
    });
  }

  private _setSupplierResponse(response: ISupplierResponse) {
    this.supplier = response;
    this.supplierUuid = response.uuid;
  }

  private _error(error: IManufactory | ISuppliersErrorResponse): void {
    Object.values(error).map(err => this._snackbarService.openTypeSnackbar(err[0], NotificationType.error));
  }

  scrollToTop() {
    if (this.dialogContent && this.dialogContent.nativeElement) {
      this.dialogContent.nativeElement.scrollTop = 0;
    }
  }

  supplierView(): void {
    this.scrollToTop();
    this.hideSupplierComponent =
      this.hideSupplierSIte =
      this.componentView =
      this.addComponent =
      this.isUpdateSite =
      this.siteForm =
      this.componentDeleted =
        false;
  }

  siteFormView(data: boolean): void {
    this.siteForm = this.hideSupplierComponent = data;
    this.isShowNote = false;
  }

  siteCreated(data: boolean): void {
    this.createdSIte = data;
    this.hideSupplierComponent = this.siteForm = !data;
  }
  componentCretaed(data: boolean): void {
    this.hideSupplierComponent = this.hideSupplierSIte = this.addComponent = !data;
    this.componentCreated = this.isShowNote = data;
  }

  closeComponent(data: boolean): void {
    this.addComponent = this.hideSupplierSIte = this.isShowNote = this.hideSupplierComponent = !data;
  }

  createComponent(data: boolean): void {
    this.addComponent = this.hideSupplierComponent = this.hideSupplierSIte = data;
    this.isShowNote = false;
  }

  getSite(data: IManufactory): void {
    this.site = data;
  }

  creationFinished(): void {
    this.dialogRef.close(true);
  }

  unsavedChangesDialog(): void {
    const dialogRef = this.dialog.open(InfoDialogComponent, {
      width: infoDialogWidth,
      height: infoDialogHeight,
      data: {
        title: 'Supplier creation',
        infoText: 'Please note that you have unsaved changes, if you continue now all the changes will be lost!',
        confirmationText: 'Do you wish to continue?',
        btnText: 'Yes, continue',
        type: 'warning',
        text: 'warning-text',
        product_chain_uuid: this.product_chain_uuid,
      },
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.dialogRef.close(this.supplierUuid);
      }
    });
  }
  /// Details
  isDetailsFormValid(event: boolean) {
    this.detailsFormValidation = event;
  }
  DetailsAction(action: string) {
    this.supplierService.supplierDetailsActions.next(action);
  }

  isDetailsCreated(event: boolean) {
    this.isCreateDetails = event;
  }

  isSitesFormValid(event: boolean) {
    this.sitesFormValidation = event;
  }

  sitesAction(action: string) {
    this.manufactoriesService.supplierSiteActions.next(action);
  }

  viewComponent(data: boolean): void {
    this.scrollToTop();
    this.addComponent = false;
    this.componentView = this.hideSupplierComponent = this.hideSupplierSIte = data;
  }

  isSitesUpdate(event: boolean) {
    this.scrollToTop();
    this.isUpdateSite = event;
  }

  close() {
    this.dialogRef.close(this.supplierUuid);
  }

  ////// components
  getComponents(data: ISupplierIngredient[]): void {
    this.ingredients = data;
  }

  updateComponent(data: boolean): void {
    this.isUpdateComponent = data;
  }

  deletedComponent(data: boolean): void {
    this.componentDeleted = data;
  }
}
