import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AddressManagementComponent } from './address-management.component';
import { AddressManagementRoutingModule } from './address-management-routing.module';
import { AngularMaterialModule } from '@app/material.module';
import { CommonModule } from '@angular/common';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { SharedModule } from '@app/shared/shared.module';
import { CreateAddressComponent } from './create-address/create-address.component';
import { TranslateModule } from '@ngx-translate/core';
import { AddressViewDialogComponent } from './address-view-dialog/address-view-dialog.component';

@NgModule({
  declarations: [AddressManagementComponent, CreateAddressComponent, AddressViewDialogComponent],
  imports: [
    CommonModule,
    AddressManagementRoutingModule,
    AngularMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    TranslateModule.forChild({}),
  ],
  providers: [provideHttpClient(withInterceptorsFromDi())],
})
export class AddressManagementModule {}
