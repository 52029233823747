import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Certification } from '@app/core/interface/preview_publicaton.interface';
import { SharedModule } from '@app/shared/shared.module';

@Component({
  standalone: true,
  imports: [SharedModule, CommonModule],
  selector: 'app-certificate-info-dialog',
  templateUrl: './certificate-info-dialog.component.html',
  styleUrls: ['./certificate-info-dialog.component.scss'],
})
export class CertificateInfoDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: Certification) {}
}
