import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { IErrorResponse, IManufactory } from '@app/core/interface/manufactories.interface';
import { infoDialogHeight, infoDialogWidth } from '@app/core/constants';

import { AddFactoryComponent } from './../add-factory/add-factory.component';
import { InfoDialogComponent } from '@app/shared/components/info-dialog/info-dialog.component';
import { ManufactoriesFacade } from '@app/core/facade/manufactories.facade';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-supplier-sites',
  templateUrl: './supplier-sites.component.html',
  styleUrls: ['./supplier-sites.component.scss'],
  providers: [ManufactoriesFacade],
})
export class SupplierSitesComponent implements OnInit {
  @Input() supplier_uuid?: string;
  @Input() supplier_sites!: Partial<IManufactory>[];
  @Output() fetchSupplierSites = new EventEmitter<boolean>();
  @ViewChild(MatSort, { static: false }) sort!: MatSort;
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  dataSource: MatTableDataSource<Partial<IManufactory>> = new MatTableDataSource<Partial<IManufactory>>();
  displayedColumns: string[] = ['name', 'address', 'city', 'country', 'region', 'is_main_location', 'actions'];

  constructor(
    private _dialog: MatDialog,
    private _facade: ManufactoriesFacade,
    private _changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['supplier_sites']) this.dataSource.data = this.supplier_sites;
  }

  ngOnInit(): void {
    this.dataSource.data = this.supplier_sites;
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.paginator.pageSize = 6;
    this._changeDetectorRef.detectChanges();
  }

  editSupplierSite(site: IManufactory): void {
    const dialogRef = this._dialog.open(AddFactoryComponent, {
      data: { ...site, supplier: this.supplier_uuid },
      width: '1160px',
      height: '768px',
      panelClass: 'top-padding-0',
    });
    dialogRef.afterClosed().subscribe(data => {
      if (data) {
        this.fetchSupplierSites.emit(true);
      }
    });
  }

  deleteSupplierSite(site: IManufactory): void {
    const dialogRef = this._dialog.open(InfoDialogComponent, {
      width: infoDialogWidth,
      height: infoDialogHeight,
      data: {
        infoText:
          'You are about to delete this site from the Supplier. Please note that if you proceed with deleting this supplier site, the changes cannot be undone.',
        confirmationText: 'Do you wish to continue?',
        btnText: 'Yes, Delete',
        type: 'warning',
        text: 'warning-text',
      },
    });
    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result && this.supplier_uuid && site.uuid) {
        this._facade.deleteSupplierSite$(site.uuid).subscribe({
          next: this._deleteSupplierSiteSuccess.bind(this),
          error: this._error.bind(this),
        });
      }
    });
  }

  private _deleteSupplierSiteSuccess(): void {
    this.fetchSupplierSites.emit(true);
  }

  private _error(error: IErrorResponse): void {
    this._dialog.open(InfoDialogComponent, {
      width: infoDialogWidth,
      height: infoDialogHeight,
      data: {
        infoText: error.detail,
        btnText: 'Ok, Got It',
        type: 'forbidden',
        text: 'forbidden-text',
      },
    });
  }
}
