<div class="dialog-wrapper d-flex flex-column">
  <h2 mat-dialog-title>{{ 'supply-chain-step.Update step alert' | translate }}</h2>
  <mat-dialog-content class="d-flex flex-column flex-grow-1 m-0">
    <h5>
      <strong>{{ 'supply-chain-step.You don’t have any direct suppliers entered' | translate }}.</strong>
    </h5>

    <mat-radio-group class="d-flex justify-content-between" aria-label="Select an option" [formControl]="radioControl">
      <mat-radio-button [value]="true">
        {{ 'supply-chain-step.Go back and specify your direct suppliers' | translate }}
      </mat-radio-button>
      <mat-radio-button [value]="false">
        {{ 'supply-chain-step.Proceed without direct suppliers' | translate }}
      </mat-radio-button>
    </mat-radio-group>

    <div *ngIf="isSpecifyReason" class="d-flex flex-column specify-reason">
      <div class="label">{{ 'supply-chain-step.Specify the reason' | translate }}</div>
      <mat-form-field class="input-primary full-width" appearance="outline">
        <mat-label>{{ 'supply-chain-step.Enter reason' | translate }}</mat-label>
        <input matInput [formControl]="reasonInput" />
      </mat-form-field>
      <p class="p2 m-0">
        {{ 'supply-chain-step.Examples: I am the last supplier in the chain, Some other reason' | translate }}...
      </p>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions [align]="'end'">
    <app-rounded-button
      size="md"
      color="primary"
      button_text="{{ 'supply-chain-step.Proceed' | translate }}"
      [disabled]="reasonInput.invalid"
      (click)="onProceed()"
    >
    </app-rounded-button>
  </mat-dialog-actions>
</div>
