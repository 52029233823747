<div class="container-fluid">
  <div class="logo-holder" [ngClass]="header_variable ? 'header_change' : 'header_change_off'">
    <!-- <h5 class="text-center logo">respect-code.<span>org</span></h5> -->
    <div class="logo"></div>
  </div>

  <div class="holder">
    <div class="row">
      <div class="col-12 title-holder">
        <h3 class="plan_heading text-center">
          {{ 'Get the best plan for your' | translate }} <br class="title-brake" />
          {{ 'bussines model' | translate }}
        </h3>
        <h6 class="text-center">{{ 'You can cancel your subscription anytime' | translate }}</h6>
      </div>

      <div class="col-12 card-holder">
        <div class="row">
          <div class="col-12 col-md-4 my-2 card h-fc">
            <app-purchase-card
              color="soft-orange"
              [icon_category_list]="'essential'"
              [icon_name_list]="'tick-circle'"
              [icon_category]="'location'"
              [icon_name]="'global'"
              [card_title]="'Essential'"
              [price]="'25'"
              [card_list]="essential_list"
              [data_plan]="'essential-licenses-monthly-chf'"
            ></app-purchase-card>
          </div>
          <div class="col-12 col-md-4 my-2 card h-fc">
            <app-purchase-card
              color="dark_blue"
              [icon_category]="'learning'"
              [icon_name]="'brifecase-tick'"
              [card_title]="'Business'"
              [price]="'75'"
              [card_list]="business_list"
              [data_plan]="'business-licenses-monthly-chf'"
            ></app-purchase-card>
          </div>
          <div class="col-12 col-md-4 my-2 card h-fc">
            <app-purchase-card
              color="purple"
              [icon_category]="'support'"
              [icon_name]="'medal-star'"
              [card_title]="'Premium'"
              [price]="'150'"
              [card_list]="premium_list"
              [data_plan]="'premium-licences-monthly-chf'"
            ></app-purchase-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
