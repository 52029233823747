import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { BillingFacade } from '@app/core/facade/billing.facade';
import { IBillingCardDetails, IBillingUpdateCardDetails } from '@app/core/interface/billing.interface';
import { GetDirtyValues } from '@app/core/utils/form-dirty-values';
import { BillingManagementGroup } from '../../billing-management.group';
import { NotificationType } from '@app/core/constants';
import { SnackbarService } from '@app/core/service/snackbar.service';

@Component({
  selector: 'app-manage-credit-card',
  templateUrl: './manage-credit-card.component.html',
  styleUrls: ['./manage-credit-card.component.scss'],
  providers: [BillingManagementGroup, BillingFacade],
})
export class ManageCreditCardComponent implements OnInit {
  creditCardDetailsForm: UntypedFormGroup;
  @Input() creditCardDetails!: Array<IBillingCardDetails>;
  @Output() closeDialog: EventEmitter<boolean> = new EventEmitter<boolean>();
  minExpYear: string = new Date().getFullYear().toString();

  constructor(
    private _group: BillingManagementGroup,
    private _billingFacade: BillingFacade,
    private _snackbarService: SnackbarService
  ) {
    this.creditCardDetailsForm = this._group.creditCardForm;
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    this._mapCreditCardData();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Function to map credit card data
   */
  private _mapCreditCardData(): void {
    this.creditCardDetailsForm.patchValue({
      id: this.creditCardDetails[0].payment_source.id,
      first_name: this.creditCardDetails[0].payment_source.card.credit_card_first_name,
      last_name: this.creditCardDetails[0].payment_source.card.credit_card_last_name,
      expiry_month: this.creditCardDetails[0].payment_source.card.expiry_month,
      expiry_year: this.creditCardDetails[0].payment_source.card.expiry_year,
    });
    this._markInputsAsDirty();
  }

  /**
   * Function to mark inputs as dirty by default
   */
  private _markInputsAsDirty() {
    this.creditCardDetailsForm.get('id')?.markAsDirty();
    this.creditCardDetailsForm.get('first_name')?.markAsDirty();
    this.creditCardDetailsForm.get('last_name')?.markAsDirty();
    this.creditCardDetailsForm.get('expiry_month')?.markAsDirty();
    this.creditCardDetailsForm.get('expiry_year')?.markAsDirty();
  }

  private _success(): void {
    this._snackbarService.openTypeSnackbar('Account details updated successfully', NotificationType.success);
    this.closeDialog.emit(true);
  }

  private _error(error: Record<string, string[]>): void {
    Object.values(error).map(err => this._snackbarService.openTypeSnackbar(err[0], NotificationType.error));
    this.closeDialog.emit(true);
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Function to update credit card details
   */
  updateCreditCardDetails(): void {
    const changedFormValues: Partial<IBillingUpdateCardDetails> = GetDirtyValues(this.creditCardDetailsForm);
    if (this.creditCardDetailsForm.valid) {
      this._billingFacade.updateCreditCardDetails$({ ...changedFormValues }).subscribe({
        next: this._success.bind(this),
        error: this._error.bind(this),
      });
    }
  }
}
