<div class="tmp-wrap">
  <div class="head-wrp">
    <mat-label class="mat-dialog-title">{{ 'Modify template' | translate }}</mat-label>
    <app-rounded-button
      color="primary"
      mat-button
      mat-raised-button
      size="md"
      [button_text]="'Preview template' | translate"
      [icon_category]="'security'"
      [icon_name]="'eye'"
      [icon_right]="true"
      (click)="previewTemplate()"
    >
    </app-rounded-button>
    <div>
      <app-rounded-button
        size="md"
        [routerLink]="'../../'"
        color="tetriary-link"
        class="link"
        button_text="{{ 'Cancel' | translate }}"
      >
      </app-rounded-button>

      <app-rounded-button
        color="turquoise"
        mat-button
        mat-raised-button
        size="md"
        [button_text]="'Update Template' | translate"
        [disabled]="disabled"
        (click)="!disabled && modifyTemplate()"
        [icon_category]="'messages'"
        [icon_name]="'sms-tracking'"
        [icon_right]="true"
      >
      </app-rounded-button>
    </div>
  </div>
  <hr />
  <div class="cont-wrap">
    <app-create-email-content
      #content
      id="content"
      class="content invitation-text"
      [lang]="data.email_template_type.language"
      (resetForm)="setForm()"
    >
    </app-create-email-content>
  </div>
</div>
