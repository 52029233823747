<div *ngIf="!supplierSites.length" class="no-sites">
  <h4>{{ "You don't have any sites added yet." | translate }}</h4>
  <h6>{{ 'Please add one or more sites for this supplier.' | translate }}</h6>
</div>
<div *ngIf="supplierSites.length" class="sites-list d-flex flex-wrap justify-content-between">
  <div
    class="site-card d-flex"
    [ngClass]="{ 'is-main-site': site.is_main_location }"
    *ngFor="let site of supplierSites"
  >
    <div class="actions">
      <app-custom-icon [icon_category]="'files'" [icon_name]="'edit-2'"></app-custom-icon>
      <app-custom-icon [icon_category]="'essential'" [icon_name]="'trash'"></app-custom-icon>
    </div>
    <div class="circle-icon d-flex justify-content-center align-items-center">
      <app-custom-icon [icon_category]="'buildings'" [icon_name]="'factory'"></app-custom-icon>
    </div>
    <div class="site-info">
      <p class="m-0 p1-strong">{{ site.name }}</p>
      <p class="m-0 p2">
        {{ site.address ? site.address + ',' : '' }} {{ site.city ? site.city + ',' : '' }} {{ site.country }}
      </p>
      <p *ngIf="site.is_main_location" class="main-location m-0">{{ 'main supplier site' | translate }}</p>
    </div>
  </div>
</div>
<div class="button-holder">
  <app-rounded-button
    color="secondary"
    [size]="'md'"
    [icon_right]="true"
    icon_category="buildings"
    icon_name="factory"
    button_text="{{ 'Add Supplier Site' | translate }}"
    (click)="onAddSupplierSite()"
  >
  </app-rounded-button>
</div>
