import { Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

@Injectable()
export class FlagComponentGroup {
  flagAsChemicalForm: UntypedFormGroup;

  constructor(private _fb: UntypedFormBuilder) {
    this.flagAsChemicalForm = this._fb.group({
      ec_list: [null, Validators.required],
      cas_no: [null, Validators.required],
    });
  }
}
