<div class="table-wrapper">
  <table class="custom-table" mat-table matSort [dataSource]="dataSource">
    <ng-container matColumnDef="username">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Username' | translate }}</th>
      <td mat-cell *matCellDef="let element">
        {{ element.first_name || element.last_name ? element.first_name + ' ' + element.last_name : '' }}
      </td>
    </ng-container>

    <ng-container matColumnDef="user_email">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'User Email' | translate }}</th>
      <td mat-cell *matCellDef="let element">{{ element.email }}</td>
    </ng-container>

    <ng-container matColumnDef="role">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Role' | translate }}</th>
      <td mat-cell *matCellDef="let element">{{ element.role }}</td>
    </ng-container>

    <ng-container matColumnDef="admin">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Admin' | translate }}</th>
      <td mat-cell *matCellDef="let element" [ngStyle]="{ color: element.is_brand_admin ? '#0DB496' : '#FFCA10' }">
        {{ element.is_brand_admin ? 'Yes' : ('No' | translate) }}
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef class="actions-header text-center">{{ 'Actions' | translate }}</th>
      <td mat-cell *matCellDef="let element" class="actions-body text-center">
        <div class="wrapper-icons">
          <app-custom-icon
            matTooltip="{{ 'Remove Brand User' | translate }}"
            matTooltipClass="custom-tooltip-end mb-16"
            [matTooltipPosition]="'above'"
            class="remove-user-icon cursor-pointer"
            icon_category="user"
            icon_name="profile-remove"
            (click)="deleteBrandUser(element.uuid, element.email)"
          ></app-custom-icon>
          <app-custom-icon
            matTooltip="{{ 'Resend Invitation' | translate }}"
            matTooltipClass="custom-tooltip-end mb-16"
            [matTooltipPosition]="'above'"
            class="profile-reinvite cursor-pointer"
            icon_category="user"
            icon_name="profile-reinvite"
            (click)="resendInvite(element.email)"
          ></app-custom-icon>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>
