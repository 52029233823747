import { AngularMaterialModule } from '@app/material.module';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '@app/shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { TranslationComponent } from './translation.component';
import { TranslationRoutingModule } from './translation-routing.module';

@NgModule({
  declarations: [TranslationComponent],
  imports: [
    CommonModule,
    TranslationRoutingModule,
    AngularMaterialModule,
    ReactiveFormsModule,
    TranslateModule.forChild(),
    SharedModule,
  ],
})
export class TranslationtModule {}
