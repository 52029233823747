<div class="wrp">
  <h3 mat-dialog-title class="add-p">
    {{ (data.pac?.uuid ? 'Edit packaging' : 'Packaging creation') | translate }}
    <span class="ic"> </span>
  </h3>

  <mat-dialog-content>
    <app-set-packaging-info
      [packaging]="pac"
      [product]="product"
      (isInvalid)="isInvalid($event)"
    ></app-set-packaging-info>
  </mat-dialog-content>
  <div #infoWrap class="btn-tooltip d-flex align-items-center" [style.visibility]="showTooltip ? 'visible' : 'hidden'">
    <div class="info" #info>
      <p class="p1_strong">
        {{ 'Please note that in order to procced you must fill all of the required fields.' | translate }}
      </p>
    </div>
  </div>
  <mat-dialog-actions [align]="'end'">
    <app-rounded-button
      [color]="'tetriary-link'"
      [size]="'sm'"
      [button_text]="'supply-chain-step.Cancel' | translate"
      class="cancel"
      (click)="dialogRef.close()"
    >
    </app-rounded-button>
    <div class="add-supplier" *ngIf="!data.pac?.uuid" (mouseover)="onHover(!!btn1.disabled)" (mouseout)="onHoverOut()">
      <app-rounded-button
        #btn1
        class="add-supplier"
        size="md"
        color="primary"
        button_text="Create Packaging"
        [disabled]="!!invalid || setPackagingInfoComponent.form.invalid"
        (click)="!btn1.disabled && createPackaging()"
        [icon_category]="'essential'"
        [icon_name]="'3dcube'"
        [icon_right]="true"
      >
      </app-rounded-button>
    </div>
    <div class="add-supplier" *ngIf="data.pac?.uuid" (mouseover)="onHover(!!btn2.disabled)" (mouseout)="onHoverOut()">
      <app-rounded-button
        #btn2
        class="add-supplier"
        size="md"
        color="turquoise"
        button_text="Update Packaging"
        [disabled]="!!invalid || setPackagingInfoComponent.form.invalid"
        (click)="!btn2.disabled && createPackaging()"
        [icon_category]="'essential'"
        [icon_name]="'3dcube'"
        [icon_right]="true"
      >
      </app-rounded-button>
    </div>
  </mat-dialog-actions>
</div>
