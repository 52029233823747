<div style="text-align: center; padding-top: 15px">
  <p class="bar-text">{{ submittedQuestions }} / {{ questionNumber }} {{ 'submitted' | translate }}</p>
  <div class="progress-container">
    <ng-container *ngFor="let i of numbers">
      <div class="progress-segment {{ shouldBeColored(i) ? 'filled-segment' : '' }}"></div>
      <div class="{{ shouldBeColored(i) ? 'progress-divider-white' : 'progress-divider' }}"></div>
    </ng-container>
    <div class="progress-segment {{ shouldBeColored(questionNumber - 1) ? 'filled-segment' : '' }}"></div>
  </div>
</div>
