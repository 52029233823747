<div class="wrapper">
  <h2 *ngIf="!isChainComplete" mat-dialog-title>
    {{ 'Edit supply chain info' | translate }}
  </h2>
  <div class="view-header" *ngIf="isChainComplete">
    <h2>
      {{ 'Supply chain info' | translate }}
    </h2>
    <app-custom-icon
      class="close-btn cursor-pointer"
      [icon_category]="'essential'"
      [icon_name]="'close-circle'"
      mat-dialog-close
    ></app-custom-icon>
  </div>

  <mat-dialog-content class="mat-typography">
    <div class="col-12 create-supplier-wraper">
      <div class="row">
        <div class="supply-chain-details">
          <h6>Supply Chain Details</h6>
          <div class="product-project">
            <p>Product:</p>
            <div class="info">
              <img
                class="product-image"
                src="{{
                  productChain?.product?.image
                    ? productChain?.product?.image
                    : '../../../../../../assets/images/upload-placeholder-blue.svg'
                }}"
              />
              <p>{{ productChain.product.name }}</p>
            </div>
          </div>
          <div *ngIf="show_project" class="product-project">
            <p>Project:</p>
            <div class="info">
              <img
                *ngIf="productChain?.product?.project?.logo"
                class="product-image"
                src="{{ productChain.product.project.logo }}"
              />
              <img
                *ngIf="!productChain?.product?.project?.logo && productChain?.product?.project"
                class="product-image"
                src="../../../../../../assets/images/upload-placeholder-blue.svg"
              />
              <p *ngIf="productChain?.product?.project?.name; else notEntered">
                {{ productChain.product.project.name }}
              </p>
              <ng-template #notEntered>
                <p>Not entered</p>
              </ng-template>
            </div>
          </div>
        </div>
      </div>
      <form [formGroup]="form" *ngIf="!isChainComplete; else viewChainInfo">
        <div class="row">
          <div class="col-6">
            <h6 class="field-title w-40">{{ 'Chain Name' | translate }}</h6>
            <mat-form-field class="w-100 input-primary" appearance="outline">
              <mat-label>{{ 'Supply chain name' | translate }}</mat-label>
              <input matInput formControlName="name" />
            </mat-form-field>
          </div>

          <div class="col-6">
            <h6 class="field-title w-40">{{ 'Tags' | translate }}</h6>
            <mat-form-field class="w-100 input-primary" appearance="outline">
              <mat-label>{{ 'Enter Tags' | translate }}</mat-label>
              <input matInput formControlName="tags" />
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <h6 class="field-title w-40">{{ 'Reference Number' | translate }}</h6>
            <mat-form-field class="w-100 input-primary" appearance="outline">
              <mat-label>{{ 'Enter reference Number' | translate }}</mat-label>
              <input matInput formControlName="external_reference" />
            </mat-form-field>
          </div>
        </div>
      </form>

      <ng-template #viewChainInfo>
        <div class="row">
          <div class="supply-chain-details supply-chain-readonly">
            <div class="info-section">
              <p>{{ 'Supply chain name:' | translate }}</p>
              <p>{{ productChain.name }}</p>
            </div>
            <div class="info-section">
              <p>{{ 'Tags:' | translate }}</p>
              <p>{{ productChain.tags }}</p>
            </div>
            <div class="info-section">
              <p>{{ 'Reference number:' | translate }}</p>
              <p>{{ productChain.external_reference }}</p>
            </div>
          </div>
        </div>
      </ng-template>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions align="end" *ngIf="!isChainComplete">
    <app-rounded-button
      color="tetriary-link"
      class="me-2"
      [button_text]="'Cancel' | translate"
      [size]="'md'"
      [icon_right]="false"
      mat-dialog-close
    >
    </app-rounded-button>
    <app-rounded-button
      *ngIf="!isChainComplete"
      color="success"
      icon_category="essential"
      icon_name="info-circle"
      [disabled]="form && !form.valid && form.pristine"
      [button_text]="'Update info' | translate"
      [size]="'md'"
      [icon_right]="true"
      (click)="updateData()"
    >
    </app-rounded-button>
  </mat-dialog-actions>
</div>
