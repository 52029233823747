import { CommonModule, DatePipe } from '@angular/common';

import { CertificatesDashboardComponent } from './certificates-dashboard.component';
import { CertificatesDashboardRoutingModule } from './certificates-dashboard-routing.module';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '@app/shared/shared.module';
import { CreateCertificateDialogComponent } from './create-certificate-dialog/create-certificate-dialog.component';
import { BrandProductFormComponent } from './create-certificate-dialog/brand-product-form/brand-product-form.component';
import { CertificationProductsListComponent } from './create-certificate-dialog/certification-products-list/certification-products-list.component';
import { CertificationBrandsListComponent } from './create-certificate-dialog/certification-brands-list/certification-brands-list.component';
import { CertificationSuppliersListComponent } from './create-certificate-dialog/certification-suppliers-list/certification-suppliers-list.component';
import { FilesMeadiasLinksFacade } from '@app/core/facade/files-medias-links.facade';

@NgModule({
  declarations: [
    CertificatesDashboardComponent,
    CreateCertificateDialogComponent,
    BrandProductFormComponent,
    CertificationProductsListComponent,
    CertificationBrandsListComponent,
    CertificationSuppliersListComponent,
  ],
  imports: [CommonModule, CertificatesDashboardRoutingModule, SharedModule, ReactiveFormsModule],
  providers: [DatePipe, FilesMeadiasLinksFacade, { provide: MAT_DATE_LOCALE, useValue: 'en-GB' }],
})
export class CertificatesDashboardModule {}
