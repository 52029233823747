<div [ngClass]="is_project ? 'bg-dark' : 'bg-light'" class="project-info d-flex flex-column">
  <div class="top d-flex">
    <div class="name">
      <p class="m-0 label">{{ 'Name' | translate }}</p>
      <p class="m-0 project-name">{{ project?.name }}</p>
    </div>
    <div class="status">
      <p class="m-0 label">{{ 'status' | translate }}</p>
      <p class="m-0 project-status">{{ project?.status?.name }}</p>
    </div>
    <div
      mat-button
      [matMenuTriggerFor]="actionMenu"
      [ngClass]="is_project ? 'action-dark' : 'action-light'"
      class="action d-flex justify-content-center align-items-center"
      matTooltip="{{ 'View All Actions' | translate }}"
      matTooltipClass="custom-tooltip-center"
      matTooltipPosition="above"
    >
      <app-custom-icon [icon_category]="'settings'" [icon_name]="'more'"> </app-custom-icon>
    </div>
    <mat-menu class="mat-menu-primary project-action" #actionMenu="matMenu" xPosition="before" (closed)="clearFilter()">
      <button class="custom-item" mat-menu-item (click)="viewProject(project)">
        <p class="name m-0">{{ view }}</p>
      </button>
      <button (click)="createProjectDialog(project?.uuid, true)" class="custom-item" mat-menu-item>
        <p class="name m-0">
          {{ edit }}
        </p>
      </button>
      <button
        [disabled]="activeProjectsListOrg && activeProjectsListOrg.length <= 1"
        class="custom-item"
        mat-menu-item
        [matMenuTriggerFor]="switchProjects"
      >
        <p class="name m-0">
          {{ switch }}
        </p>
      </button>

      <button class="custom-item" mat-menu-item>
        <p class="name m-0" (click)="unassignProject(is_project)">
          {{ unassign }}
        </p>
      </button>
    </mat-menu>
    <mat-menu class="mat-menu-primary switch-menu existing-project-menu" #switchProjects="matMenu" xPosition="after">
      <div class="menu-wrapper">
        <div id="menu-text">
          <p class="add-text">{{ 'Switch to another ' + name | translate }}</p>
        </div>
        <div class="filterR" (click)="$event.stopPropagation()">
          <mat-form-field class="input-primary mat-form-field-10-px-brd-rd" appearance="outline">
            <mat-label class="label-text">{{ 'Search for project' | translate }}</mat-label>

            <input (blur)="onBlur()" (input)="filterProjects($event)" matInput placeholder="" #inputFilter />

            <app-custom-icon
              class="search-filter"
              [icon_category]="'search'"
              [icon_name]="'search-normal-2'"
            ></app-custom-icon>
          </mat-form-field>
        </div>
        <div class="item-holder">
          <div *ngFor="let project_subproject of activeProjectsList">
            <button
              *ngIf="project_subproject.uuid !== project?.uuid"
              lass="custom-item"
              mat-menu-item
              (click)="addProductToProject(project_subproject.uuid, is_project, true)"
            >
              <div class="menu-items">
                <div class="supp-name">
                  <div class="name-wrapper">
                    <p class="name">{{ project_subproject.name }}</p>
                  </div>
                </div>
              </div>
            </button>
          </div>
        </div>
        <div class="bottom-action">
          <p class="info-text">{{ btnLabel }}</p>
          <app-rounded-button
            (click)="createProjectDialog('', false)"
            color="primary"
            [button_text]="btnText"
            size="md"
          >
          </app-rounded-button>
        </div>
      </div>
    </mat-menu>
  </div>
  <div class="bottom d-flex">
    <div class="start-date">
      <p class="m-0 label">{{ 'start date' | translate }}</p>
      <p class="m-0 project-start">{{ project?.estimated_start_date | date: 'dd.MM.yyyy' }}</p>
    </div>
    <div class="end-date">
      <p class="m-0 label">{{ 'end date' | translate }}</p>
      <p class="m-0 project-end">{{ project?.estimated_end_date | date: 'dd.MM.yyyy' }}</p>
    </div>

    <div class="location-holder">
      <p class="m-0 label">{{ 'location' | translate }}</p>
      <p class="m-0 project-location">
        {{
          project?.address || project?.statezipcode || project?.city || project?.country?.name
            ? ((project?.address && !project?.statezipcode && project?.city && project?.country?.name) ||
              (project?.address && !project?.statezipcode && !project?.city && project?.country?.name) ||
              (project?.address && !project?.statezipcode && project?.city && !project?.country?.name)
                ? project?.address + ', '
                : project.address
                  ? project.address
                  : '') +
              (project?.statezipcode && !project?.city && !project?.country?.name && !project?.address
                ? project?.statezipcode
                : (project?.statezipcode && project?.address && project?.city && project?.country?.name) ||
                    (project?.statezipcode && project?.address && !project?.city && project?.country?.name) ||
                    (project?.statezipcode && project?.address && project?.city && !project?.country?.name)
                  ? ', ' + project?.statezipcode + ', '
                  : project?.statezipcode && project?.address && !project?.city && !project?.country?.name
                    ? ', ' + project?.statezipcode
                    : (project?.statezipcode && !project?.address && project?.city && !project?.country?.name) ||
                        (project?.statezipcode && !project?.address && project?.city && project?.country?.name) ||
                        (project?.statezipcode && !project?.address && !project?.city && project?.country?.name) ||
                        (project?.statezipcode && !project?.address && project?.city && !project?.country?.name)
                      ? project?.statezipcode + ', '
                      : '') +
              (project?.city && !project?.country?.name
                ? project?.city
                : project?.city && project?.country?.name
                  ? project?.city + ', '
                  : '') +
              (project?.country?.name ? project?.country?.name : '')
            : ('not applied' | translate)
        }}
      </p>
    </div>
  </div>
</div>
