import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

import { Injectable } from '@angular/core';

@Injectable()
export class ProductPreviewGroup {
  productLearnMoreForm: UntypedFormGroup;

  constructor(private _fb: UntypedFormBuilder) {
    this.productLearnMoreForm = this._fb.group({
      description: [''],
      reference: [''],
      composition: [''],
      uuid: [''],
    });
  }
}
