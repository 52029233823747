<div class="holder d-flex flex-column">
  <div *ngIf="show_info" class="info-wrapper d-flex justify-content-between align-items-center">
    <div class="text-holder d-flex align-items-center">
      <div class="logo"></div>
      <p class="m-0">
        {{ 'Here you can archive your deactivated products, or restore your archived ones.' | translate }}
      </p>
    </div>
    <app-custom-icon
      (click)="show_info = !show_info"
      id="close-icon"
      [icon_category]="'essential'"
      [icon_name]="'close-circle'"
    ></app-custom-icon>
  </div>

  <div class="table-wraper">
    <table class="custom-table" mat-table matSort [dataSource]="tableDataSource">
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Product Name' | translate }}</th>
        <td [ngClass]="is_archived ? 'lightgray' : 'darkgray'" mat-cell *matCellDef="let element">
          {{ element.name }}
        </td>
      </ng-container>

      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Product description' | translate }}</th>
        <td [ngClass]="is_archived ? 'lightgray' : 'darkgray'" mat-cell *matCellDef="let element">
          <!-- {{ element.description | slice : 0 : 30 }} -->
          <app-view-rich-text [content]="element.description"></app-view-rich-text>
        </td>
      </ng-container>

      <ng-container matColumnDef="chains">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Chains created' | translate }}</th>
        <td mat-cell *matCellDef="let element">
          <div [ngClass]="is_archived ? 'product-chains-lightgray' : 'product-chains-darkgray'">
            <span class="dot-green">
              <div class="div-dot"></div>
            </span>
            <span class="number">{{ element.product_chains.length }}</span>
            <span class="text">chains created</span>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef class="actions-header text-center">
          <div class="action-header d-flex justify-content-end">
            {{ 'product.Actions' | translate }}
          </div>
        </th>
        <td mat-cell *matCellDef="let element" class="actions-body text-center">
          <div class="icon-holder d-flex justify-content-end">
            <app-custom-icon
              (click)="archiveProduct()"
              *ngIf="!is_archived"
              id="archive"
              [icon_category]="'essential'"
              [icon_name]="'archive'"
              matTooltip="{{ 'Archive Product' | translate }}"
              matTooltipClass="custom-tooltip-center"
              matTooltipPosition="above"
            >
            </app-custom-icon>
            <app-custom-icon
              (click)="restoreProduct()"
              *ngIf="is_archived"
              id="refresh"
              [icon_category]="'arrows'"
              [icon_name]="'refresh-left-square'"
              matTooltip="{{ 'Restore Archived Product' | translate }}"
              matTooltipClass="custom-tooltip-center"
              matTooltipPosition="above"
            >
            </app-custom-icon>
            <app-custom-icon
              (click)="deleteProduct()"
              *ngIf="is_archived"
              id="trash"
              [icon_category]="'essential'"
              [icon_name]="'trash'"
              matTooltip="{{ 'Delete Product' | translate }}"
              matTooltipClass="custom-tooltip-end"
              matTooltipPosition="above"
            >
            </app-custom-icon>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </div>

  <div class="card-footer">
    <mat-paginator
      class="custom-paginator"
      [pageSizeOptions]="[6, 12, 18]"
      showFirstLastButtons
      aria-label="Select page of products"
    >
    </mat-paginator>
  </div>
</div>
