import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { IProduct } from '@app/core/interface/products.interface';

@Component({
  selector: 'app-product-details-dialog',
  templateUrl: './product-details-dialog.component.html',
  styleUrls: ['./product-details-dialog.component.scss'],
})
export class ProductDetailsDialogComponent {
  product: IProduct = {
    certification_product: [],
    composition: '',
    co2: null,
    description: '',
    external_url: '',
    h2o: null,
    image: null,
    is_clone: false,
    gtin: '',
    product_group: null,
    medias: [],
    name: '',
    product_model: null,
    product_chains: [],
    reference: '',
    summary: '',
    tags: '',
    uuid: '',
    visible_end: null,
    visible_start: null,
    youtube_url: '',
    suppliers: [],
    batch_chains: [],
    custom_fields: [],
  };
  chains_number: number | undefined;
  selectedIndex = 0;
  showRedCircle = true;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IProduct,
    public dialogRef: MatDialogRef<ProductDetailsDialogComponent>
  ) {
    this.product = this.data;
    // this.product.custom_fields = [];
    this.chains_number = this.product.product_chains.length;
  }

  tabChanged(event: MatTabChangeEvent) {
    this.selectedIndex = event.index;
    // Hide red-circle when custom fields tab is selected
    if (this.selectedIndex === 1) {
      this.showRedCircle = false;
    }
  }
}
