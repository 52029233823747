import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NotificationType } from '@app/core/constants';
import { ProductsFacade } from '@app/core/facade/products.facade';
import { ProjectsFacade } from '@app/core/facade/projects.facade';
import { IProduct, IProductsListResponse } from '@app/core/interface/products.interface';
import { IProjectResponse, ISubproject } from '@app/core/interface/projects.interface';
import { BreadcrumbHeaderService } from '@app/core/service/breadcrumb-header.service';
import { SnackbarService } from '@app/core/service/snackbar.service';
import { take } from 'rxjs';

@Component({
  selector: 'app-project-overview',
  templateUrl: './project-overview.component.html',
  styleUrls: ['./project-overview.component.scss'],
  providers: [ProductsFacade],
})
export class ProjectOverviewComponent implements OnInit {
  projectName = '';
  project!: IProjectResponse;
  selectedIndex = 0;
  subprojects!: ISubproject[];
  allProducts!: IProduct[];

  constructor(
    private _route: ActivatedRoute,
    private _breadcrumbHeaderService: BreadcrumbHeaderService,
    private _facade: ProjectsFacade,
    private _snackbarService: SnackbarService,
    private _productsFacade: ProductsFacade
  ) {}

  ngOnInit(): void {
    this.subsrcibeToParam();
    this.getProducts();
  }

  subsrcibeToParam(): void {
    this._route.params.pipe(take(1)).subscribe(params => {
      if (params['id']) {
        this._facade.getProject$(params['id']).subscribe({
          next: this._success.bind(this),
          error: this._error.bind(this),
        });
      }
    });
  }

  private _success(response: IProjectResponse): void {
    this.projectName = response.name;
    this.project = response;
    this.subprojects = response.subprojects;
    this._breadcrumbHeaderService.setBreadCrumbs(true, this.projectName);
  }

  private _error(error: Record<string, string[]>): void {
    Object.values(error).map(err => this._snackbarService.openTypeSnackbar(err[0], NotificationType.error));
  }

  updateProject() {
    this.subsrcibeToParam();
  }

  getProducts(): void {
    this._productsFacade.getProducts$().subscribe({
      next: this._getProductsSuccess.bind(this),
      error: this._error.bind(this),
    });
  }

  private _getProductsSuccess(data: IProductsListResponse): void {
    if (data && data.results.length) {
      this.allProducts = data.results.filter(product => product.is_active && product.project == null);
    }
  }
}
