<div class="holder d-flex flex-column">
  <div class="info-holder d-flex">
    <div class="logo"></div>
    <p class="text m-0">{{ is_project ? project_text : (subproject_text | translate) }}</p>
  </div>
  <div class="confirmation text-center">
    <h4>{{ 'Do you wish to continue?' | translate }}</h4>
  </div>

  <div class="button-holder d-flex align-items-center justify-content-center">
    <app-rounded-button (click)="close()" color="tetriary-link md" [button_text]="'Cancel'"> </app-rounded-button>
    <app-rounded-button color="primary md" (click)="unassign()" [button_text]="'Yes, Unassign'"> </app-rounded-button>
  </div>
</div>
