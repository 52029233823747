<div class="dialog-header">
  <h2>{{ title }}</h2>
  <app-custom-icon mat-dialog-close [icon_category]="'essential'" [icon_name]="'close-circle'" class="cursor-pointer">
  </app-custom-icon>
</div>

<mat-dialog-content>
  <div *ngIf="!project.custom_fields?.length; else tabs" [ngTemplateOutlet]="standardFields"></div>

  <!------------------------------ Tabs ------------------------------>
  <ng-template #tabs>
    <mat-tab-group (selectedTabChange)="tabChanged($event)">
      <!-- Details tab -->
      <mat-tab [label]="'Details' | translate">
        <ng-template matTabContent>
          <ng-container [ngTemplateOutlet]="standardFields"></ng-container>
        </ng-template>
      </mat-tab>
      <!-- Custom Fields tab -->
      <mat-tab>
        <ng-template mat-tab-label>
          <div class="custom-fields-label" *ngIf="is_project">
            <p class="m-0">Custom Fields</p>
            <div [ngClass]="showRedCircle ? 'red-circle' : 'd-none'"></div>
          </div>
        </ng-template>
        <ng-template matTabContent>
          <div class="holder d-flex flex-column">
            <ng-template [ngTemplateOutlet]="projectName"></ng-template>
            <div class="project-custom-fields-container">
              <app-view-custom-fields-details [customFields]="project.custom_fields"></app-view-custom-fields-details>
            </div>
          </div>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </ng-template>

  <!-- Reusable html block for project name (because it is used inside the two tabs) -->
  <ng-template #projectName>
    <div class="name-holder d-flex justify-content-between align-items-center">
      <h6>{{ project?.name }}</h6>
      <div
        class="status d-flex align-items-center"
        [ngClass]="{
          'in-progress': project?.status?.name === 'In progress',
          'not-started': project?.status?.name === 'Not started',
          completed: project?.status?.name === 'Completed',
        }"
      >
        <div
          class="dot"
          [ngClass]="{
            'dot-in-progress': project?.status?.name === 'In progress',
            'dot-not-started': project?.status?.name === 'Not started',
            'dot-completed': project?.status?.name === 'Completed',
          }"
        ></div>
        <p>{{ project?.status?.name }}</p>
      </div>
    </div>
  </ng-template>

  <!-- Reusable html block for standard fields (because it is used inside the two tabs) -->
  <ng-template #standardFields>
    <div *ngIf="project" class="holder d-flex flex-column">
      <ng-template [ngTemplateOutlet]="projectName"></ng-template>
      <div class="divider"></div>
      <div class="project-details-container d-flex flex-column">
        <div class="photo-holder d-flex flex-column">
          <h6 class="m-0">{{ photo | translate }}</h6>
          <div class="project-photo">
            <img src="{{ project.logo ? project.logo : '../../../../assets/images/brand-avatar.png' }}" alt="" />
          </div>
        </div>
        <div class="description-holder d-flex flex-column">
          <h6 class="m-0">{{ description }}</h6>
          <p class="m-0" [ngClass]="!project.description ? 'no-description' : ''">
            {{ project.description ? project.description : 'no description added yet' }}
          </p>
        </div>
        <div class="location-holder d-flex flex-column">
          <h6 class="m-0">{{ location }}</h6>
          <p class="m-0">
            {{ project.address ? project.address + ',' : '' }}
            {{ project.statezipcode ? project.statezipcode + ',' : '' }} {{ project.city ? project.city + ',' : '' }}
            {{ project?.country?.name }}
          </p>
          <p
            class="m-0 no-location"
            *ngIf="!project.address && !project.statezipcode && !project.city && !project.country"
          >
            {{ location_not_set }}
          </p>
        </div>
        <div class="duration d-flex flex-column">
          <h6 class="m-0">{{ duration }}</h6>
          <div class="date-holder d-flex">
            <p class="m-0">
              {{ 'Start Date' | translate }}
              <strong>{{ project.estimated_start_date | date: 'dd.MM.yyyy' }}</strong>
            </p>
            <p class="m-0">
              {{ 'End Date' | translate }} <strong>{{ project.estimated_end_date | date: 'dd.MM.yyyy' }}</strong>
            </p>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</mat-dialog-content>
