import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ImageEditPreviewComponent } from '@app/shared/components/image-edit-preview/image-edit-preview.component';
import { UserSettingsGroup } from '../user-settings.group';
import { RegisterFacade } from '@app/core/facade/register.facade';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { IBrandUserResult, IBrandUserResultErrorResponse } from '@app/core/interface/brands.interface';
import { GetDirtyValues } from '@app/core/utils/form-dirty-values';
import { BrandsFacade } from '@app/core/facade/brands.facade';
import { NotificationType } from '@app/core/constants';
import { SnackbarService } from '@app/core/service/snackbar.service';

@Component({
  selector: 'app-manage-user-dialog',
  templateUrl: './manage-user-dialog.component.html',
  styleUrls: ['./manage-user-dialog.component.scss'],
  providers: [UserSettingsGroup, RegisterFacade],
})
export class ManageUserDialogComponent implements OnInit {
  form!: UntypedFormGroup;
  brandUser!: IBrandUserResult;
  user: IBrandUserResult = {
    uuid: '',
    address: '',
    avatar: '',
    email: '',
    entreprise: '',
    first_name: '',
    function: '',
    is_brand_admin: false,
    last_name: '',
    phone: '',
    phone_mobile: '',
    role: '',
  };
  is_brand_admin = false;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IBrandUserResult,
    public dialogRef: MatDialogRef<ManageUserDialogComponent>,
    private _dialog: MatDialog,
    private _userSettingsGroup: UserSettingsGroup,
    private _snackbarService: SnackbarService,
    private _brandsFacade: BrandsFacade
  ) {}

  ngOnInit(): void {
    this.form = this._userSettingsGroup.userSettingsForm;
    this.user = this.data;
    if (this.user) {
      this._setFormValues();
    }
  }

  saveImageData(file: File): void {
    this.form.get('avatar')?.setValue(file);
    this.form.get('avatar')?.markAsDirty();
  }

  updateBrandUser(): void {
    const changedFormValues: Partial<IBrandUserResult> = GetDirtyValues(this.form);
    if (this.form.valid) {
      this._brandsFacade.updateBrandUser$({ ...changedFormValues }, this.user.uuid).subscribe({
        next: this._success.bind(this),
        error: this._error.bind(this),
      });
    }
  }

  private _setFormValues(): void {
    this.form.patchValue({
      email: this.user.email,
      first_name: this.user.first_name,
      last_name: this.user.last_name,
      role: this.user.role !== 'null' ? this.user.role : '',
      function: this.user.function,
      entreprise: this.user.entreprise,
      address: this.user.address,
      phone: this.user.phone !== 'null' ? this.user.phone : '',
      phone_mobile: this.user.phone_mobile,
      avatar: this.user.avatar,
      is_brand_admin: this.user.is_brand_admin,
    });
  }

  private _success(): void {
    this._snackbarService.openTypeSnackbar(`Your data has been securely submitted`, NotificationType.success);
    this.dialogRef.close();
  }

  /**
   *
   * @param error hadles HTTP error desplaying message from backend
   */
  private _error(error: IBrandUserResultErrorResponse): void {
    Object.values(error).map(err => this._snackbarService.openTypeSnackbar(err[0], NotificationType.error));
  }

  uploadImage() {
    this._dialog.open(ImageEditPreviewComponent, {
      autoFocus: false,
      width: '1160px',
      height: '768px',
      panelClass: 'position-relative',
    });
  }
}
