<div class="holder d-flex flex-column">
  <div class="components-header">
    <div class="d-flex justify-content-between align-items-center">
      <h6 class="m-0"><strong>Product Components</strong></h6>
      <div class="filter d-flex">
        <mat-form-field class="input-primary mat-form-field-10-px-brd-rd" appearance="outline">
          <mat-label class="label-text">{{ 'Quick search...' | translate }}</mat-label>
          <input matInput (keyup)="applyFilter($event)" placeholder="Quick search..." #input />
          <app-custom-icon
            class="search-filter"
            [icon_category]="'search'"
            [icon_name]="'search-normal-2'"
            matSuffix
          ></app-custom-icon>
        </mat-form-field>
        <app-custom-icon
          class="info-icon icon-align-middle"
          icon_category="essential"
          icon_name="info-circle"
          [matTooltip]="'Please search by\n component name,\n reference ID or\n supply chain ID' | translate"
          matTooltipPosition="above"
          matTooltipClass="custom-tooltip-center"
        ></app-custom-icon>
      </div>
      <app-rounded-button
        color="primary"
        button_text="Add Components"
        icon_category="essential"
        icon_name="box-2"
        [icon_right]="true"
        [size]="'md'"
        (click)="onAddComponent()"
        id="box-2"
      >
      </app-rounded-button>
    </div>
    <div class="checkbox-holder d-flex justify-content-end">
      <mat-checkbox
        (change)="onShowInactiveComponents($event)"
        [checked]="showInactiveComponents"
        class="checkbox-primary"
      >
        {{ 'show deactivated components' | translate }}
      </mat-checkbox>
    </div>
  </div>
  <div class="wrapper">
    <div class="row table-wrap">
      <table mat-table matSort [dataSource]="dataSource" class="mat-table product-ingredient-table custom-table">
        <ng-container matColumnDef="name">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>
            {{ 'Component name' | translate }}
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.name }}
          </td>
        </ng-container>
        <ng-container matColumnDef="reference">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>
            {{ 'Component reference ID' | translate }}
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.reference }}
          </td>
        </ng-container>
        <ng-container matColumnDef="supplier">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>
            {{ 'Supplier' | translate }}
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.supplier.name }}
          </td>
        </ng-container>
        <ng-container matColumnDef="supplier_site_country">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>
            {{ 'Site location' | translate }}
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.manufactory?.country?.name }}
          </td>
        </ng-container>
        <ng-container matColumnDef="supply_chain_id">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>
            {{ 'Supply Chain ID' | translate }}
          </th>
          <td mat-cell *matCellDef="let element">
            <span *ngIf="element.product_chains.length === 1">{{ element.product_chains[0].reference }}</span>
            <span
              *ngIf="element.product_chains.length > 1"
              #menuTrigger="matMenuTrigger"
              class="cursor-pointer"
              [matMenuTriggerFor]="beforeMenu"
            >
              <div class="d-flex align-items-center">
                <p class="p2-strong m-0">{{ element.product_chains.length + (' IDs' | translate) }}</p>
                <app-custom-icon
                  class="chains-arrow-icon"
                  icon_category="arrows"
                  icon_name="{{ menuTrigger.menuOpen ? 'arrow-up-2' : 'arrow-down-2' }}"
                ></app-custom-icon>
              </div>
              <mat-menu #beforeMenu="matMenu" class="product-chains-list-menu" yPosition="above">
                <p class="p2 chain-item m-0" *ngFor="let chain of element.product_chains">{{ chain.reference }}</p>
              </mat-menu>
            </span>
          </td>
        </ng-container>
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>
            {{ 'Actions' | translate }}
          </th>
          <td mat-cell *matCellDef="let element">
            <div class="icon-holder">
              <app-custom-icon
                *ngIf="element.is_active"
                [matTooltip]="'Deactivate Component' | translate"
                matTooltipPosition="above"
                matTooltipClass="custom-tooltip-center"
                class="cursor-pointer"
                [icon_category]="'essential'"
                [icon_name]="'deactivate-red'"
                (click)="deactivateComponent(element, true)"
              >
              </app-custom-icon>
              <app-custom-icon
                *ngIf="!element.is_active"
                [matTooltip]="'Reactivate Component' | translate"
                matTooltipPosition="above"
                matTooltipClass="custom-tooltip-center"
                class="cursor-pointer"
                [icon_category]="'essential'"
                [icon_name]="'activate-green'"
                (click)="reactivateComponent(element, false)"
              >
              </app-custom-icon>

              <app-custom-icon
                [matTooltip]="
                  element.cas_no && element.ec_list
                    ? 'Flagged as chemical! \n Update status'
                    : ('Flag component \n as chemical' | translate)
                "
                matTooltipPosition="above"
                matTooltipClass="custom-tooltip-end"
                class="flag-icon cursor-pointer"
                [ngClass]="{ 'is-flagged': element.cas_no && element.ec_list }"
                icon_category="essential"
                icon_name="flag"
                (click)="onFlagAsChemical(element)"
              >
              </app-custom-icon>
            </div>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr
          [ngClass]="{ 'inactive-component': !row.is_active }"
          mat-row
          *matRowDef="let row; columns: displayedColumns"
        ></tr>
      </table>
    </div>
  </div>
  <div class="card-footer">
    <mat-paginator showFirstLastButtons aria-label="Select page of users"> </mat-paginator>
  </div>
</div>
