<div class="content">
  <div class="content-small-devices">
    <app-custom-icon [icon_category]="'arrows'" [icon_name]="'chevron-up-solid'"></app-custom-icon>
  </div>
  <div class="row">
    <div class="col-6">
      <div class="d-flex flex-column justify-content-start inner mt-auto">
        <img
          (click)="onSingleImgDialog(this.product?.image ?? undefined)"
          *ngIf="!carousels"
          src="{{
            this.product?.image ? this.product?.image : '../../../../../../assets/images/image-placeholder.jpg'
          }}"
          alt="Product"
          class="product-img cursor-pointer"
        />
        <!-- <carousel [isAnimated]="true" [interval]="5000" [showIndicators]="false" *ngIf="carousels">
          <slide *ngFor="let carousel of carousels" class="carousel-slide mt-auto">
            <img
              (click)="onImgDialog(carousels, carousel ?? carousel.file)"
              src="{{ carousel.file ? carousel.file : carousel }}"
              alt="first slide"
              style="display: block"
              class="product-img cursor-pointer"
            />
          </slide>
        </carousel> -->
        <h6 class="carbon__title d-inline-block w-100">Respect Code</h6>
        <div class="d-flex align-items-start justify-content-start">
          <img src="./../../../../../assets/logos/symbol-dark-circle.png" class="site-logo" />
          <span class="ref-num">{{ reference }}</span>
        </div>
      </div>
    </div>
    <div class="col-6">
      <div class="d-flex inner flex-column align-items-start justify-content-start">
        <div class="d-flex align-items-center pb-2">
          <img *ngIf="brand?.logo" src="{{ brand?.logo }}" class="brand-logo pe-2" />
          <h3 class="">{{ brand?.name }}</h3>
        </div>

        <h1>{{ product?.name }}</h1>

        <!-- <label class="carbon__title d-inline-block w-100"
            >Crafted in Italy <br />
            Produce since 2020</label
          > -->
        <div class="mt-auto d-flex align-items-between btn_brand">
          <button
            *ngIf="batches !== null && batches?.length !== 0"
            class="btn btn_white"
            mat-button
            (click)="onBatchInfoDialog()"
          >
            {{ 'Batch Info' | translate }}
          </button>
          <button class="btn btn_blue" mat-button (click)="openDialog()">
            <!-- // Add to the button up -->
            <!-- [ngClass]="{ 'self-margin': batches?.length == 0 }" -->
            {{ 'Product Info' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
