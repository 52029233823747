import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { ObservableInput } from 'rxjs/internal/types';
import { catchError } from 'rxjs/internal/operators/catchError';
import { take } from 'rxjs/internal/operators/take';
import { throwError } from 'rxjs/internal/observable/throwError';
import { ProjectsService } from '../service/projects.service';
import {
  IActivateDeactivateProjectResponse,
  IDeleteProjectResponse,
  IProject,
  IProjectLookUpResponse,
  IProjectResponse,
  IProjectsListResponse,
  ISubproject,
  ISubprojectResponse,
} from '../interface/projects.interface';
import { generateFormData } from '../utils/generate-formData';
import { ProductsService } from '../service/products.service';

@Injectable()
export class ProjectsFacade {
  constructor(private _projectsService: ProjectsService, private _productsService: ProductsService) {}

  getProjects$(ordering?: string, limit?: number): Observable<IProjectsListResponse> {
    return this._projectsService.getProjects$(ordering, limit).pipe(
      take(1),
      catchError(({ error }: IProjectsListResponse): ObservableInput<IProjectsListResponse> => throwError(() => error))
    );
  }

  getProject$(uuid: string): Observable<IProjectResponse> {
    return this._projectsService.getProject$(uuid).pipe(
      take(1),
      catchError(({ error }: IProjectResponse): ObservableInput<IProjectResponse> => throwError(() => error))
    );
  }
  getSubproject$(uuid: string): Observable<ISubprojectResponse> {
    return this._projectsService.getSubproject$(uuid).pipe(
      take(1),
      catchError(({ error }: ISubprojectResponse): ObservableInput<ISubprojectResponse> => throwError(() => error))
    );
  }
  createProject$(data: Partial<IProject>): Observable<IProjectResponse> {
    return this._projectsService.createProject$(data).pipe(
      take(1),
      catchError(({ error }: IProjectResponse): ObservableInput<IProjectResponse> => throwError(() => error))
    );
  }

  updateProject$(data: Partial<IProject>, uuid: string): Observable<IProjectResponse> {
    return this._projectsService.updateProject$(data, uuid).pipe(
      take(1),
      catchError(({ error }: IProjectResponse): ObservableInput<IProjectResponse> => throwError(() => error))
    );
  }
  updateSubproject$(data: Partial<ISubproject>, uuid: string): Observable<ISubprojectResponse> {
    const formData = generateFormData(data);
    return this._projectsService.updateSubproject$(formData, uuid).pipe(
      take(1),
      catchError(({ error }: ISubprojectResponse): ObservableInput<ISubprojectResponse> => throwError(() => error))
    );
  }
  createSubproject$(data: Partial<ISubproject>): Observable<ISubprojectResponse> {
    const formData = generateFormData(data);
    return this._projectsService.createSubproject$(formData).pipe(
      take(1),
      catchError(({ error }: ISubprojectResponse): ObservableInput<ISubprojectResponse> => throwError(() => error))
    );
  }

  deleteProject$(uuid: string): Observable<IDeleteProjectResponse> {
    return this._projectsService.deleteProject$(uuid).pipe(
      take(1),
      catchError(
        ({ error }: IDeleteProjectResponse): ObservableInput<IDeleteProjectResponse> => throwError(() => error)
      )
    );
  }
  deleteSubProject$(uuid: string): Observable<IDeleteProjectResponse> {
    return this._projectsService.deleteSubProject$(uuid).pipe(
      take(1),
      catchError(
        ({ error }: IDeleteProjectResponse): ObservableInput<IDeleteProjectResponse> => throwError(() => error)
      )
    );
  }

  getProjectsStatus$(): Observable<IProjectLookUpResponse> {
    return this._projectsService.getProjectStatus$().pipe(
      take(1),
      catchError(
        ({ error }: IProjectLookUpResponse): ObservableInput<IProjectLookUpResponse> => throwError(() => error)
      )
    );
  }

  activateDeactivateProject$(
    uuid: string,
    data: Partial<IActivateDeactivateProjectResponse>
  ): Observable<IActivateDeactivateProjectResponse> {
    return this._projectsService.activateDeactivatePproject$(uuid, data).pipe(
      take(1),
      catchError(
        ({ error }: IActivateDeactivateProjectResponse): ObservableInput<IActivateDeactivateProjectResponse> =>
          throwError(() => error)
      )
    );
  }

  activateDeactivateSubproject$(
    uuid: string,
    data: Partial<IActivateDeactivateProjectResponse>
  ): Observable<IActivateDeactivateProjectResponse> {
    const formData = generateFormData(data);
    return this._projectsService.activateDeactivateSubproject$(uuid, formData).pipe(
      take(1),
      catchError(
        ({ error }: IActivateDeactivateProjectResponse): ObservableInput<IActivateDeactivateProjectResponse> =>
          throwError(() => error)
      )
    );
  }
}
