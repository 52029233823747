<ng-container>
  <canvas
    baseChart
    [data]="doughnutChartData"
    (chartClick)="chartClicked($event)"
    [type]="doughnutChartType"
    [options]="options"
    #chart
  >
  </canvas>
</ng-container>
