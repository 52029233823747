import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QuestionnairesManagementComponent } from '@module/questionnaires-management/questionnaires-management.component';
import { QuestionnairesManagementRoutingModule } from '@module/questionnaires-management/questionnaires-management-routing.module';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '@shared/shared.module';
import { CreateQuestionnaireComponent } from './create-questionnaire/create-questionnaire.component';
import { SectionQuestionComponent } from './section-question/section-question.component';
import { QuestionnairesManagementGroup } from '@module/questionnaires-management/questionnaires-management-group';
import { SectionProgressBarComponent } from './section-progress-bar/section-progress-bar.component';
import { QuestionnaireFacade } from '@facade/questionnaire.facade';
import { QuestionnaireDetailsComponent } from './questionnaire-details/questionnaire-details.component';
import { QuestionnaireNameEditModalComponent } from './questionnaire-name-edit-modal/questionnaire-name-edit-modal.component';
import { QuestionnaireQuestionComponent } from './questionnaire-question/questionnaire-question.component';
import { FollowUpQuestionComponent } from './follow-up-question/follow-up-question.component';
import { FormsModule } from '@angular/forms';
import { QuestionnaireStatusToResPipe } from '@app/core/pipes/questionnaire-status-to-res.pipe/questionnaire-status-to-res.pipe';

@NgModule({
  declarations: [
    QuestionnairesManagementComponent,
    CreateQuestionnaireComponent,
    SectionQuestionComponent,
    SectionProgressBarComponent,
    QuestionnaireDetailsComponent,
    QuestionnaireNameEditModalComponent,
    QuestionnaireQuestionComponent,
    FollowUpQuestionComponent,
    QuestionnaireStatusToResPipe,
  ],
  imports: [CommonModule, SharedModule, QuestionnairesManagementRoutingModule, TranslateModule, FormsModule],
  providers: [QuestionnairesManagementGroup, QuestionnaireFacade],
})
export class QuestionnairesManagementModule {}
