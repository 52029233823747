import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { Injectable } from '@angular/core';

@Injectable()
export class LoginGroup {
  loginForm: UntypedFormGroup;

  constructor(private _fb: UntypedFormBuilder) {
    this.loginForm = this._fb.group({
      username: [null, [Validators.required]],
      password: [null, [Validators.required]],
      rememberMe: [true],
    });
  }
}
