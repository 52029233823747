<div class="dialog-wrapper">
  <div class="holder">
    <div class="breadcrump">
      <app-custom-icon
        mat-dialog-close
        [icon_category]="'arrows'"
        [icon_name]="'arrow-circle-left-short'"
      ></app-custom-icon>
      <h4 mat-dialog-close>{{ 'Users Management' | translate }}</h4>
      <app-custom-icon [icon_category]="'arrows'" [icon_name]="'arrow-right-2'"></app-custom-icon>
      <h3>{{ 'Manage User' | translate }}</h3>
    </div>

    <div class="h-line">
      <div class="horizontal-line mb-2"></div>
    </div>

    <div class="header d-flex">
      <h4>{{ 'User Details' | translate }}</h4>
      <app-personal-data-tooltip></app-personal-data-tooltip>
    </div>

    <form [formGroup]="form">
      <div class="form-header">
        <div class="avatar-wrapper">
          <div class="avatar-name-holder">
            <div class="avatar-holder">
              <app-file-upload
                *ngIf="user"
                [placeholder]="user.avatar ? user.avatar : 'Upload product image'"
                [iconType]="'add_photo_alternate'"
                [imageUrl]="user.avatar"
                (fileData)="saveImageData($event)"
                [status]="form.controls['avatar'].status"
              >
              </app-file-upload>
              <app-file-upload
                *ngIf="!user"
                [placeholder]="'Upload product image'"
                [iconType]="'add_photo_alternate'"
                (fileData)="saveImageData($event)"
                [status]="form.controls['avatar'].status"
              >
              </app-file-upload>
            </div>

            <div class="name-holder">
              <mat-form-field class="input-primary" appearance="outline">
                <mat-label>{{ 'First Name' | translate }}</mat-label>
                <input matInput formControlName="first_name" placeholder="First Name" />
              </mat-form-field>

              <mat-form-field class="input-primary" appearance="outline">
                <mat-label>{{ 'Last Name' | translate }}</mat-label>
                <input matInput formControlName="last_name" placeholder="Last Name" />
              </mat-form-field>
            </div>
          </div>
        </div>

        <div class="email-holder">
          <mat-form-field class="input-primary" appearance="outline">
            <mat-label>{{ 'Email' | translate }}</mat-label>
            <input matInput formControlName="email" placeholder="Email" />
          </mat-form-field>
          <app-custom-icon
            class="indicator"
            [icon_category]="'essential'"
            [icon_name]="'info-circle'"
            [matTooltip]="
              'account-setup.We collect your email address to send you important updates and notifications related to your account. Your email will only be used for these purposes and will not be shared with third parties.'
                | translate
            "
            matTooltipClass="custom-tooltip-center"
          ></app-custom-icon>
        </div>
      </div>
      <div class="h-line">
        <div class="horizontal-line mt-3"></div>
      </div>

      <div class="header">
        <h4>{{ 'User additional info' | translate }}</h4>
      </div>
      <div class="input-holder-info">
        <div class="input-holder">
          <mat-form-field class="input-primary" appearance="outline">
            <mat-label>{{ 'Role' | translate }}</mat-label>
            <input matInput formControlName="role" placeholder="Some Role" />
          </mat-form-field>

          <mat-form-field class="input-primary" appearance="outline">
            <mat-label>{{ 'Function' | translate }}</mat-label>
            <input matInput formControlName="function" placeholder="Some Function" />
          </mat-form-field>
        </div>

        <div class="input-holder">
          <div class="input-wraper">
            <mat-form-field class="input-primary" appearance="outline">
              <mat-label>{{ 'Phone' | translate }}</mat-label>
              <input matInput formControlName="phone" placeholder="+49 211 1234567" />
            </mat-form-field>
            <app-custom-icon
              class="indicator"
              [icon_category]="'essential'"
              [icon_name]="'info-circle'"
              [matTooltip]="
                'Your phone number is collected to provide customer support and verify your identity. We may contact you regarding any issues or updates related to your account.'
                  | translate
              "
              matTooltipClass="custom-tooltip-center"
            ></app-custom-icon>
          </div>

          <div class="input-wraper">
            <mat-form-field class="input-primary" appearance="outline">
              <mat-label>{{ 'Mobile Phone' | translate }}</mat-label>
              <input matInput formControlName="phone_mobile" placeholder="+49 30 901820" />
            </mat-form-field>
            <app-custom-icon
              class="indicator"
              [icon_category]="'essential'"
              [icon_name]="'info-circle'"
              [matTooltip]="
                'Your phone number is collected to provide customer support and verify your identity. We may contact you regarding any issues or updates related to your account.'
                  | translate
              "
              matTooltipClass="custom-tooltip-center"
            ></app-custom-icon>
          </div>
        </div>

        <div class="input-holder">
          <mat-form-field class="input-primary" appearance="outline">
            <mat-label>{{ 'Company' | translate }}</mat-label>
            <input matInput formControlName="entreprise" placeholder="Some organization" />
          </mat-form-field>

          <div class="input-wraper">
            <mat-form-field class="input-primary" appearance="outline">
              <mat-label>{{ 'Address' | translate }}</mat-label>
              <input matInput formControlName="address" placeholder="Mozartstr. 9 67677 Enkenbach" />
            </mat-form-field>
            <app-custom-icon
              class="indicator"
              [icon_category]="'essential'"
              [icon_name]="'info-circle'"
              [matTooltip]="
                'We ask for your address to process orders and shipments. Your address will be used solely for delivery purposes and will be kept secure.'
                  | translate
              "
              matTooltipClass="custom-tooltip-center"
            ></app-custom-icon>
          </div>
        </div>
      </div>
      <mat-checkbox class="checkbox-primary" formControlName="is_brand_admin" checked="{{ user.is_brand_admin }}">{{
        'Is this User brand admin?' | translate
      }}</mat-checkbox>
    </form>
  </div>

  <mat-dialog-actions align="end" class="d-flex justify-content-between">
    <app-data-secured-badge></app-data-secured-badge>
    <div>
      <app-rounded-button size="md" mat-dialog-close color="tetriary-link" button_text="{{ 'Cancel' | translate }}">
      </app-rounded-button>

      <app-rounded-button
        cdkFocusInitial
        color="turquoise"
        mat-button
        mat-raised-button
        size="md"
        [button_text]="'Update User' | translate"
        [disabled]="form && !form.valid"
        (click)="updateBrandUser()"
      >
      </app-rounded-button>
    </div>
  </mat-dialog-actions>
</div>
