<mat-card>
  <mat-card-title>
    <h1>Languages</h1>
  </mat-card-title>
  <mat-card-content>
    <section class="row">
      <mat-icon class="col-4">info</mat-icon>
      <h3 class="offset-3 col-6">
        {{ "Manage your brand's languages by saving the selection:" | translate }}
      </h3>
    </section>
    <div class="row">
      <div class="col-6">
        <h2>{{ 'Selected Languages' }}</h2>
        <div class="row">
          <div class="col-12" *ngFor="let language of languagesControl.value">
            <mat-label>
              {{ language.short_name }}
              <mat-icon (click)="removeLang(language)" [matTooltip]="'Remove language' | translate"> close </mat-icon>
            </mat-label>
          </div>
        </div>
      </div>
      <div class="col-6">
        <h2>{{ 'Select Languages' }}</h2>
        <mat-form-field appearance="fill">
          <mat-label>{{ 'Language' | translate }}</mat-label>
          <!-- <mat-select formControlName="languages" multiple> -->
          <mat-select [formControl]="languagesControl" multiple [compareWith]="compareCategoryObjects">
            <mat-option *ngFor="let language of languages" [value]="language" placeholder="Language">
              {{ language.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </mat-card-content>
  <div class="btn-wrap">
    <div class="row">
      <div class="col-8"></div>
      <!-- <button mat-button classs="col-1">{{'Cancel' | translate}}</button> -->
      <button
        mat-button
        mat-raised-button
        color="primary"
        [disabled]="languagesControl.pristine || languagesControl.invalid"
        (click)="save()"
        class="col-1"
      >
        {{ 'Save' | translate }}
      </button>
    </div>
  </div>
</mat-card>
