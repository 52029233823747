import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';

import { DataAuthService } from '../service/data-auth.service';
import { DataStorageService } from '../service/data-localstorage.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { Router } from '@angular/router';
import { catchError } from 'rxjs/internal/operators/catchError';
import { throwError } from 'rxjs/internal/observable/throwError';
import { UrlStorageService } from '../service/url-storage.service';
import { REDIRECT, Storage } from '../constants';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private _dataStorageService: DataStorageService,
    private _dataAuthService: DataAuthService,
    private _urlStorageService: UrlStorageService
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError(err => {
        const error = err.error.message || err || err.statusText;

        if (err.status === 401) {
          // auto logout if 401 response returned from api
          this._dataStorageService.clearStorage();
          this._dataAuthService.user = null;
          this.router.navigate(['/login']);
          const lastUrl = this._urlStorageService.getPreviousUrl();
          if (
            lastUrl &&
            lastUrl !== '/login' &&
            !lastUrl.includes('switch-brand') &&
            !lastUrl.includes('select-brand')
          ) {
            this._dataStorageService.set(REDIRECT, lastUrl, Storage.local);
          }
          error.error = {
            detail: 'Your access token is invalid or expired',
          };
        }
        return throwError(() => error);
      })
    );
  }
}
