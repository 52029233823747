<div class="wrapper">
  <h2 mat-dialog-title>{{ data.step ? 'Editing Step' : ('Creating Step' | translate) }}</h2>

  <div class="content">
    <div class="header">
      <div class="toggle-buttons">
        <div class="toggle-group">
          <mat-button-toggle-group #group="matButtonToggleGroup">
            <div class="toggle">
              <mat-button-toggle value="step" aria-label="Step Details" [checked]="true">
                <span>{{ 'Step Details' | translate }}</span>
              </mat-button-toggle>
            </div>
            <div class="toggle">
              <mat-button-toggle value="supplier" aria-label="Supplier Details">
                <span>{{ 'Supplier Details' | translate }}</span>
              </mat-button-toggle>
            </div>
          </mat-button-toggle-group>
        </div>
        <div class="note" *ngIf="data.step">
          <app-custom-icon [icon_category]="'essential'" [icon_name]="'info-circle'"></app-custom-icon>
          <span>Switch through the step and supplier details to modify </span>
        </div>
      </div>
    </div>

    <div class="group-content">
      <app-step-details
        *ngIf="group.value === 'step'"
        [form]="createStepForm"
        [step]="data.step"
        (refreshStep)="refreshStep()"
      ></app-step-details>
      <app-supplier-details
        *ngIf="group.value === 'supplier'"
        [supplierForm]="createSupplierForm"
        [manufactoryForm]="createManufactoryForm"
        [step]="data.step"
        [supplier]="supplier"
        (refreshSupplier)="getSupplierDetails(data.step.supplier.uuid)"
      ></app-supplier-details>
    </div>
  </div>
</div>
<mat-dialog-actions align="end">
  <app-rounded-button color="tetriary-link" [button_text]="'Cancel'" [size]="'md'" mat-dialog-close>
  </app-rounded-button>
  <app-rounded-button
    color="primary"
    [button_text]="data.step ? 'Done Editing' : 'Create Step'"
    [size]="'md'"
    [icon_category]="'grid'"
    [icon_name]="'3square'"
    [icon_right]="true"
    [disabled]="createStepForm && !createStepForm.valid"
    (click)="data.step ? checkBeforeUpdate() : checkBeforeCreate()"
  >
  </app-rounded-button>
</mat-dialog-actions>
