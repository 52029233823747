import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NotificationType } from '@app/core/constants';
import { RegisterFacade } from '@app/core/facade/register.facade';
import { IUser } from '@app/core/interface/login.interface';
import { ICompleteProfileErrorResponse, ICompleteProfileForm } from '@app/core/interface/register.interface';
import { SnackbarService } from '@app/core/service/snackbar.service';
import { GetDirtyValues } from '@app/core/utils/form-dirty-values';
import { RegisterGroup } from '@app/module/register/register.group';

@Component({
  selector: 'app-manage-profile-dialog',
  templateUrl: './manage-profile-dialog.component.html',
  styleUrls: ['./manage-profile-dialog.component.scss'],
  providers: [RegisterGroup, RegisterFacade],
})
export class ManageProfileDialogComponent implements OnInit {
  user!: IUser | undefined;
  form: UntypedFormGroup;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { user?: IUser },
    public dialogRef: MatDialogRef<ManageProfileDialogComponent>,
    private _regGrup: RegisterGroup,
    private _registerFacade: RegisterFacade,
    private _snackbarService: SnackbarService
  ) {
    this.form = this._regGrup.manageProfileForm;
    if (this.data) {
      this.user = this.data.user;
    }
  }

  ngOnInit(): void {
    this.patchData();
  }

  patchData(): void {
    this.form.patchValue({
      username: this.user?.username,
      first_name: this.user?.first_name,
      last_name: this.user?.last_name,
      avatar: this.user?.avatar,
    });
  }

  saveImageData(file: File): void {
    this.form.get('avatar')?.setValue(file);
    this.form.get('avatar')?.markAsDirty();
  }

  get imageUrl(): string {
    return this.form.controls['avatar'].value;
  }

  completeProfile(): void {
    const changedFormValues: Partial<ICompleteProfileForm> = GetDirtyValues(this.form);
    if (this.form.valid) {
      this._registerFacade.manageProfile$({ ...changedFormValues }).subscribe({
        next: this._success.bind(this),
        error: this._error.bind(this),
      });
    }
  }

  private _success(response: IUser) {
    this._snackbarService.openTypeSnackbar(`Your data has been securely submitted`, NotificationType.success);
    this.dialogRef.close(response);
  }

  private _error(error: ICompleteProfileErrorResponse): void {
    Object.values(error).map(err => this._snackbarService.openTypeSnackbar(err[0], NotificationType.error));
    if (Object.values(error)[0] == 'A user with that username already exists.') {
      this.form.controls['username'].setErrors({ valieExist: true });
    }
    if (
      Object.values(error)[0] ==
      'Enter a valid username. This value may contain only letters, numbers, and @/./+/-/_ characters.'
    ) {
      this.form.controls['username'].setErrors({ invalidUserName: true });
    }
  }
}
