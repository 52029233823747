import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { Injectable } from '@angular/core';

@Injectable()
export class TasksGroup {
  createTaskForm: FormGroup;

  constructor(private _fb: FormBuilder) {
    this.createTaskForm = this._fb.group({
      name: [null, [Validators.required]],
      description: [null, [Validators.required, Validators.maxLength(300)]],
      assigned_to: [null, [Validators.required]],
      status: [null, [Validators.required]],
      end_date: [null],
      start_at: [null, [Validators.required]],
      relatedObj: [null, [Validators.required]],
    });
  }
}
