import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { IRelObj, ITask, ITasksResponse } from '@app/core/interface/tasks.interface';
import { NotificationType, infoDialogHeight, infoDialogWidth } from '@app/core/constants';

import { CreateTaskDialogComponent } from '../create-task-dialog/create-task-dialog.component';
import { IErrorResponse } from '@app/core/interface/register.interface';
import { InfoDialogComponent } from '@app/shared/components/info-dialog/info-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Observable } from 'rxjs';
import { SnackbarService } from '@app/core/service/snackbar.service';
import { TaskDetailsDialogComponent } from '../task-details-dialog/task-details-dialog.component';
import { TasksFacade } from '@app/core/facade/tasks.facade';

@Component({
  selector: 'app-tasks-list',
  templateUrl: './tasks-list.component.html',
  styleUrls: ['./tasks-list.component.scss'],
})
export class TasksListComponent implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort!: MatSort;
  displayedColumns: string[] = ['name', 'created_by', 'assign_to', 'related_obj', 'status', 'end_date', 'actions'];
  cardDataSource = new MatTableDataSource<ITask>();
  tableDataSource = new MatTableDataSource<ITask>();
  relatedObj!: IRelObj;
  list: Array<ITask> = [];
  listObservable$!: Observable<ITask[]>;
  constructor(
    private _dialog: MatDialog,
    private _facade: TasksFacade,
    private _snackbarService: SnackbarService
  ) {}

  ngAfterViewInit() {
    Promise.resolve().then(() => {
      this.cardDataSource.paginator = this.paginator;
      this.tableDataSource.paginator = this.paginator;
      this.tableDataSource.sort = this.sort;
    });
  }
  ngOnInit(): void {
    this.getTasks();
  }
  getTasks(): void {
    this._facade.getTasks$().subscribe({
      next: this._getTasksSuccess.bind(this),
      error: this._error.bind(this),
    });
  }
  openTaskDetails(task: ITask) {
    this._dialog.open(TaskDetailsDialogComponent, {
      data: { ...task, related_obj: this.relatedObj },
      panelClass: 'top-padding-0',
      width: '1160px',
      height: '768px',
    });
  }
  applyFilter() {
    // logic for filter is not present in the new design
    return;
  }
  viewChange(value: string): void {
    if (value === 'tableView') {
      // this._changeDetectorRef.detectChanges();
      this.tableDataSource.paginator = this.paginator;
      this.tableDataSource.sort = this.sort;
    } else {
      this.cardDataSource.paginator = this.paginator;
    }
  }

  createTaskDialog() {
    const dialogRef = this._dialog.open(CreateTaskDialogComponent, {
      panelClass: 'top-padding-0',
      width: '1160px',
      height: '768px',
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.getTasks();
      }
    });
  }
  deleteTask(task: ITask) {
    const dialogRef = this._dialog.open(InfoDialogComponent, {
      width: infoDialogWidth,
      height: infoDialogHeight,
      data: {
        infoText:
          'You are about to delete this task. Please note that if you delete the task, the changes cannot be undone.',
        confirmationText: 'Please Confirm',
        btnText: 'Yes, Delete',
        type: 'warning',
        text: 'warning-text',
      },
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this._facade.deleteTask$(task.uuid).subscribe({
          next: this._deleteSuccess.bind(this),
          error: this._error.bind(this),
        });
      }
    });
  }
  private _getTasksSuccess(res: ITasksResponse) {
    if (res && res.results.length) {
      this.list = res.results.reverse();
      this.cardDataSource.data = this.list;
      this.tableDataSource.data = this.list;
      this.listObservable$ = this.cardDataSource.connect();
    }
  }
  private _deleteSuccess() {
    this._snackbarService.openTypeSnackbar(`Task is successfully deleted`, NotificationType.success);
    this.getTasks();
  }
  private _error(error: IErrorResponse): void {
    Object.values(error).map(err => this._snackbarService.openTypeSnackbar(err[0], NotificationType.error));
  }
}
