import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { QuestionnairesManagementComponent } from '@module/questionnaires-management/questionnaires-management.component';
import { CreateQuestionnaireComponent } from '@module/questionnaires-management/create-questionnaire/create-questionnaire.component';
import { QuestionnaireDetailsComponent } from '@module/questionnaires-management/questionnaire-details/questionnaire-details.component';

const routes: Routes = [
  {
    path: '',
    component: QuestionnairesManagementComponent,
  },
  {
    path: 'create',
    component: CreateQuestionnaireComponent,
  },
  {
    path: 'create/:id',
    component: CreateQuestionnaireComponent,
  },
  {
    path: 'questionnaire-details/:id',
    component: QuestionnaireDetailsComponent,
  },
];

@NgModule({
  declarations: [],
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class QuestionnairesManagementRoutingModule {}
