import { RouterModule, Routes } from '@angular/router';

import { BrandManagementComponent } from './brand-management.component';
import { CreateBrandNewComponent } from '../register/complete-register-steps/create-brand-new/create-brand-new.component';
import { NgModule } from '@angular/core';
import { SwitchBrandComponent } from '../../shared/components/switch-brand/switch-brand.component';

const routes: Routes = [
  {
    path: '',
    component: BrandManagementComponent,
  },
  {
    path: 'create-brand',
    component: CreateBrandNewComponent,
  },
  {
    path: 'switch-brand',
    component: SwitchBrandComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class BrandManagmentRoutingModule {}
