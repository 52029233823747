<div class="preview-publication">
  <header>
    <app-rounded-button
      class="button-back"
      color="secondary"
      [icon_left]="true"
      [icon_category]="'arrows'"
      [icon_name]="'arrow-left'"
      [button_text]="'Go back to publication'"
      [size]="'sm'"
      (click)="goBack()"
    ></app-rounded-button>
  </header>
  <div class="main-map-container">
    <div class="map-holder">
      <div class="map-panel">
        <div class="map" id="map"></div>
        <div class="map-panel-inner">
          @if (previewPublication !== null) {
            <app-product-info
              [product]="previewPublication?.product"
              [reference]="previewPublication?.reference"
              [brand]="previewPublication?.brand"
              [batches]="previewPublication?.batches"
            ></app-product-info>
            <app-brand-info [productChain]="previewPublication"></app-brand-info>
          }
        </div>
      </div>
    </div>
  </div>
</div>
