<div class="centered-content">
  <div>
    <!--No AGEC case-->
    <div
      class="row"
      *ngIf="
        product && product.regulations && product.regulations[0] && !product.regulations[0].datas[0]?.steps_completed
      "
    >
      <div class="offset-2 col-7">
        <div class="row">
          <div class="set-ic-bg col-1">
            <app-custom-icon class="custom-icon img-ic" [icon_category]="'files'" [icon_name]="'edit-content'">
            </app-custom-icon>
          </div>
          <div class="col-10">
            <div class="col-12">
              <h5>
                {{ 'Here you can create a Digital Product Sheet for the AGEC Regulation' | translate }}
              </h5>
              <!-- <p class="p1-normal">
                {{
                  'Here you can create a digital product sheet in which you can add the product
                  specifications required by the AGEC law.' | translate
                }}
              </p> -->
            </div>
            <div class="col-10">
              <div class="centered-content">
                <app-rounded-button
                  color="primary"
                  [button_text]="'Create Digital Product Sheet'"
                  [size]="'lg'"
                  (click)="createDialogProductSheet('agec')"
                >
                </app-rounded-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--Not completed AGEC case-->
    <div
      class="row"
      *ngIf="
        product &&
        product.regulations &&
        product.regulations[0] &&
        (product.regulations[0].datas[0]?.steps_completed ?? -1) > 0 &&
        (product.regulations[0].datas[0]?.steps_completed ?? -1) < 5
      "
    >
      <div class="col-10 offset-1 p-0">
        <h5 class="header">
          {{ 'All of the steps are not completed yet' | translate }}
        </h5>
        <div class="stepper-container">
          <div *ngFor="let step of steps; let i = index" class="step" [class.completed]="step.completed">
            <div class="step-icon">
              <span *ngIf="!step.completed">{{ i + 1 }}</span>
              <span *ngIf="step.completed">&#10003;</span>
            </div>
            <div *ngIf="i < steps.length - 1" class="line" [class.filled]="step.completed"></div>
          </div>
        </div>
      </div>
      <div class="complete-wrap col-10 offset-1">
        <div class="pr-wrp row d-flex justify-content-between">
          <div class="col-6 p-0">
            <div class="tit-d">
              {{ 'digital product sheet' | translate }}
            </div>
            <div class="pr-name">
              {{ product.name }}
            </div>
          </div>
          <div class="col-6 row p-0 d-flex justify-content-end p-0">
            <!--Complete steps-->
            <app-rounded-button
              class="complete-step-btn w-auto p-0"
              color="secondary"
              [button_text]="'Complete the steps'"
              [size]="'xd'"
              (click)="createDialogProductSheet('agec')"
            >
            </app-rounded-button>
          </div>
        </div>
      </div>
    </div>
    <!--Completed 5 steps AGEC case-->
    <div
      class="row"
      *ngIf="
        product &&
        product.regulations &&
        product.regulations[0] &&
        product.regulations[0].datas[0]?.steps_completed === 5
      "
    >
      <div class="col-6 offset-3">
        <h5 class="header">
          {{ 'Your digital product sheet was completed' | translate }}
        </h5>
        <p class="p1-normal">
          {{
            'All of the steps of creating a digital product sheet have been completed, the next step would be
          to
          publish it live on this url: ' | translate
          }}
        </p>
      </div>
      <div class="complete-wrap col-6 offset-3">
        <div class="pr-wrp row">
          <div class="col-6">
            <div class="tit-d">
              {{ 'digital product sheet' | translate }}
            </div>
            <div class="pr-name">
              {{ product.name }}
            </div>
          </div>
          <div class="col-6 row">
            <!--Review Digital Product Sheet AGEC-->
            <div class="col-6">
              <div class="centered-content">
                <app-rounded-button
                  color="transparent"
                  [button_text]="'Review'"
                  [size]="'sm'"
                  (click)="reviewDialogProductSheet()"
                  [icon_category]="'security'"
                  [icon_name]="'eye'"
                  [icon_right]="true"
                >
                </app-rounded-button>
              </div>
            </div>
            <div class="col-6">
              <div class="centered-content btns" (click)="createDialogProductSheet('agec')">
                {{ 'Edit' | translate }}
                <app-custom-icon
                  [icon_category]="'files'"
                  [icon_name]="'edit'"
                  class="cursor-pointer edit-ic"
                ></app-custom-icon>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--Publish Digital Product Sheet AGEC-->
      <div class="offset-3">
        <app-rounded-button
          color="primary"
          [button_text]="'Publish Digital Product Sheet'"
          [size]="'lg'"
          (click)="publishDialogProductSheet()"
        >
        </app-rounded-button>
      </div>
    </div>
  </div>
</div>
