import { Pipe, PipeTransform } from '@angular/core';
import { IQuestionnaireStatus } from '@interface/questionnaire.interface';

@Pipe({
  name: 'questionnaireStatusToRes',
})
export class QuestionnaireStatusToResPipe implements PipeTransform {
  transform(value: unknown): string {
    switch (value) {
      case IQuestionnaireStatus.NotStarted:
        return 'Not Started';
      case IQuestionnaireStatus.Completed:
        return 'Completed';
      case IQuestionnaireStatus.InProgress:
      default:
        return 'In Progress';
    }
  }
}
