import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ProjectsListComponent } from './projects-list/projects-list.component';
import { ProjectOverviewComponent } from './project-overview/project-overview.component';

const routes: Routes = [
  {
    path: '',
    component: ProjectsListComponent,
  },
  {
    path: ':id',
    component: ProjectOverviewComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ProjectsDashboardRoutingModule {}
