import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  IQuestionnaire,
  IQuestionnaireDetailsCreateUpdate,
  IQuestionnaireResponse,
} from '@interface/questionnaire.interface';

@Injectable({
  providedIn: 'root',
})
export class QuestionnaireService {
  private apiUrl = environment.api;

  constructor(private _http: HttpClient) {}

  createQuestionnaire$(formData: FormData): Observable<IQuestionnaireDetailsCreateUpdate> {
    return this._http.post<IQuestionnaireDetailsCreateUpdate>(`${this.apiUrl}/questionnaires/`, formData);
  }

  createQuestionnaireSection$(formData: FormData): Observable<IQuestionnaireDetailsCreateUpdate> {
    return this._http.post<IQuestionnaireDetailsCreateUpdate>(`${this.apiUrl}/questionnaires/sections/`, formData);
  }

  createQuestionnaireSectionQuestion$(formData: FormData): Observable<IQuestionnaireDetailsCreateUpdate> {
    return this._http.post<IQuestionnaireDetailsCreateUpdate>(
      `${this.apiUrl}/questionnaires/sections/questions/`,
      formData
    );
  }

  getQuestionnaires$(): Observable<IQuestionnaireResponse> {
    return this._http.get<IQuestionnaireResponse>(`${this.apiUrl}/questionnaires`);
  }

  getQuestionnaireDetails$(uuid: string): Observable<IQuestionnaire> {
    return this._http.get<IQuestionnaire>(`${this.apiUrl}/questionnaires/${uuid}`);
  }

  updateQuestionnaireDetails$(
    questionnaireUuid: string,
    formData: FormData
  ): Observable<IQuestionnaireDetailsCreateUpdate> {
    return this._http.put<IQuestionnaireDetailsCreateUpdate>(
      `${this.apiUrl}/questionnaires/${questionnaireUuid}`,
      formData
    );
  }

  updateQuestionnaireSectionQuestionDetails$(
    questionUuid: string,
    formData: FormData
  ): Observable<IQuestionnaireDetailsCreateUpdate> {
    return this._http.put<IQuestionnaireDetailsCreateUpdate>(
      `${this.apiUrl}/questionnaires/sections/questions/${questionUuid}`,
      formData
    );
  }

  deleteQuestionnaireSectionQuestion$(questionUuid: string): Observable<IQuestionnaireDetailsCreateUpdate> {
    return this._http.delete<IQuestionnaireDetailsCreateUpdate>(
      `${this.apiUrl}/questionnaires/sections/questions/${questionUuid}`
    );
  }

  updateQuestionnaireSectionDetails$(
    sectionUuid: string,
    formData: FormData
  ): Observable<IQuestionnaireDetailsCreateUpdate> {
    return this._http.put<IQuestionnaireDetailsCreateUpdate>(
      `${this.apiUrl}/questionnaires/sections/${sectionUuid}`,
      formData
    );
  }
}
