import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { IProduct } from '@interface/products.interface';
import { environment } from '@env/environment';
import { IProductChainResult } from '@app/core/interface/productchain.interface';

@Component({
  selector: 'app-amway-finished-publication',
  templateUrl: './amway-finished-publication.component.html',
  styleUrl: './amway-finished-publication.component.scss',
})
export class AmwayFinishedPublicationComponent {
  product!: IProduct;
  isAmwayPublishRoute: boolean = false;
  url!: string;
  respectCodeUrl!: string;
  published_refernce!: string;
  response!: IProductChainResult;

  constructor(private _router: Router) {
    this.isAmwayPublishRoute = this._router.url.includes('amway/finished');
    const navigation = this._router.getCurrentNavigation();
    if (navigation?.extras.state) {
      this.product = navigation.extras.state['product'];
      this.published_refernce = navigation.extras.state['reference'];
    }
    this.respectCodeUrl = environment.urlRespectCodehttps;
  }

  ngOnInit(): void {
    this.url = `${this.respectCodeUrl + '/product/' + this.published_refernce}`;
  }
  goToProduct(): void {
    this._router.navigate(['/dashboard/product', this.product.uuid]).then(() => window.location.reload());
  }

  goToExternalLink(): void {
    this.isAmwayPublishRoute
      ? window.open(
          `${environment.amwayUIUrl}${this.product.product_group?.name}/${this.product.name.replaceAll(' ', '-')}-${this.product.external_reference_id}`,
          '_blank'
        )
      : window.open(this.url, '_blank');
  }
}
