<div class="dialog-header">
  <h3>{{ 'Step Details' | translate }}</h3>
  <app-custom-icon mat-dialog-close [icon_category]="'essential'" [icon_name]="'close-circle'" class="cursor-pointer">
  </app-custom-icon>
</div>
<mat-dialog-content>
  <div class="sel-info" #selInfo>
    <div class="detail-txt d-flex align-items-center">
      <img src="../../../../assets/images/symbol-blue.svg" alt="Symbol" height="45" width="45" class="col-1" />
      <span class="more-details align-self-center">
        <p class="p1-strong mb-0">
          {{
            'This step was created automatically by the system, based on the data delivered by the previous supplier.'
              | translate
          }}
        </p>
      </span>
    </div>
    <app-custom-icon
      class="close-icon cursor-pointer cls-more-details"
      icon_category="essential"
      icon_name="close-circle"
      (click)="selInfo.style.display = 'none'"
    ></app-custom-icon>
  </div>

  <mat-card class="holder">
    <div class="d-flex justify-content-between">
      <div class="d-flex flex-column wrapper-row">
        <p class="p1-strong">
          {{ 'Current step data' | translate }}
        </p>
        <app-input-info [label]="'Step name' | translate" [value]="step.name"></app-input-info>
        <app-input-info [label]="'Supplier name' | translate" [value]="step.supplier?.name"></app-input-info>
      </div>
      <div class="d-flex flex-column wrapper-row">
        <p class="p1-strong">
          {{ 'Step data as delivered by previous supplier' | translate }}
        </p>
        <app-input-info [label]="'Step name' | translate" [value]="step.step_supplier?.role"></app-input-info>
        <app-input-info [label]="'Supplier name' | translate" [value]="step.step_supplier?.name"></app-input-info>
        <app-input-info
          [label]="'Supplier contact email' | translate"
          [value]="step.step_supplier?.email"
        ></app-input-info>
        <app-input-info
          [label]="'Contact email language' | translate"
          [value]="step.step_supplier?.language_prefered?.name"
        ></app-input-info>
      </div>
    </div>
  </mat-card>
</mat-dialog-content>

<ng-template> </ng-template>
