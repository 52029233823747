import { Component, ViewChild } from '@angular/core';
import { IProduct, IProductsListResponse } from '@app/core/interface/products.interface';

import { IProductChainResult } from '@app/core/interface/productchain.interface';
import { Location } from '@angular/common';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { NotificationType } from '@app/core/constants';
import { Observable } from 'rxjs';
import { ProductChainFacade } from '@app/core/facade/productchain.facade';
import { ProductChainGroup } from '../product-chain/product-chain.group';
import { ProductsFacade } from '@app/core/facade/products.facade';
import { Router } from '@angular/router';
import { SnackbarService } from '@app/core/service/snackbar.service';
import { UntypedFormGroup } from '@angular/forms';
import { UrlStorageService } from '@app/core/service/url-storage.service';

@Component({
  selector: 'app-chain-setup',
  templateUrl: './chain-setup.component.html',
  styleUrls: ['./chain-setup.component.scss'],
  providers: [ProductsFacade, ProductChainFacade, ProductChainGroup],
})
export class ChainSetupComponent {
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  iconList = ['right', 'left', 'up', 'down'];
  selectedDirection = 'right';
  assetsPath = '../../../assets/';
  chainPlaceholderImage = '../../../assets/images/chain-right.png';
  productsObservable$!: Observable<IProduct[]>;
  cardDataSource = new MatTableDataSource<IProduct>();
  selectedProductUuid = '';
  selectedProductChainUuid = '';
  form: UntypedFormGroup;
  needHelpTooltip = false;
  timeoutId?: NodeJS.Timeout;
  constructor(
    private _productsFacade: ProductsFacade,
    private _productChainfacade: ProductChainFacade,
    private _router: Router,
    private _snackbarService: SnackbarService,
    private _group: ProductChainGroup,
    private _urlStorage: UrlStorageService,
    private location: Location,
    private router: Router
  ) {
    this.form = this._group.addChain;
    this._router.events.subscribe(() => {
      const product = this._router.getCurrentNavigation()?.extras?.state?.['product'];
      product && this.selectProduct(product);

      const productChain = this._router.getCurrentNavigation()?.extras?.state?.['productChain'];
      productChain && this.duplicatedProductchain(productChain);
    });
  }

  ngOnInit(): void {
    const redirect = this._urlStorage.getPreviousUrl().includes('chain-setup/chain-management');
    if (redirect) {
      this.location.back();
      this.timeoutId && clearTimeout(this.timeoutId);
      this.timeoutId = setTimeout(() => {
        if (
          !this.location.path().includes('dashboard/product') &&
          !this.location.path().includes('supplier-management')
        ) {
          this.router.navigate(['/dashboard/product-chains']);
        }
      }, 100);
    } else {
      this.form.get('direction')?.setValue('leftRight');
      this.getProducts();
    }
  }

  ngAfterViewInit() {
    this.cardDataSource.paginator = this.paginator;
  }

  ngOnDestroy() {
    if (this.cardDataSource) {
      this.cardDataSource.disconnect();
    }

    this.timeoutId && clearTimeout(this.timeoutId);
  }

  getProducts(): void {
    this._productsFacade.getActiveProducts$().subscribe({
      next: this._getProductsSuccess.bind(this),
      error: this._error.bind(this),
    });
  }

  selectChainDirection(direction: string): void {
    this.form.get('direction')?.setValue(this.formatChainDirection(direction));
    this.selectedDirection = direction;
    this.chainPlaceholderImage = `${this.assetsPath}images/chain-${direction}.png`;
  }

  selectProduct(product: IProduct): void {
    this.form.get('product')?.setValue(product.uuid);
    this.selectedProductUuid = product.uuid;
  }

  duplicatedProductchain(productChain: IProductChainResult) {
    this.form.get('name')?.setValue(`${productChain.name} - Copy`);
    this.form.get('product')?.setValue(productChain.product.uuid);
    this.selectedProductUuid = productChain.product.uuid;
    this.selectedProductChainUuid = productChain.uuid;
  }

  createProductChain(close?: boolean): void {
    if (this.form.valid) {
      this._productChainfacade.createProductChain$(this.form.value).subscribe((data: IProductChainResult) => {
        if (!close) {
          this._router.navigate([`/chain-setup/chain-management/${data.uuid}`]).then(() => window.location.reload());

          this._snackbarService.openTypeSnackbar(`Address ${data.uuid} successfully`, NotificationType.success);
        } else {
          this._router.navigate(['..']);
        }
      });
    }
  }

  duplicateProductChain(close?: boolean): void {
    if (this.form.valid) {
      this._productChainfacade
        .duplicateProductChain$(this.selectedProductChainUuid, this.form.value)
        .subscribe((data: IProductChainResult) => {
          if (!close) {
            this._router.navigate([`/chain-setup/chain-management/${data.uuid}`]).then(() => window.location.reload());
          } else {
            this._router.navigate(['..']);
          }
        });
    }
  }

  formatChainDirection(direction: string): string {
    switch (direction) {
      case 'right':
        return 'leftRight';
      case 'left':
        return 'rightLeft';
      case 'up':
        return 'bottomTop';
      case 'down':
        return 'topBottom';
      default:
        return 'leftRight';
    }
  }

  private _getProductsSuccess(data: IProductsListResponse): void {
    if (data && data.results.length) {
      this.cardDataSource.data = this.sortSelectedProduct(data);
      this.productsObservable$ = this.cardDataSource.connect();
    }
  }

  private _error(error: Record<string, string>): void {
    Object.values(error).map(err => {
      this._snackbarService.openTypeSnackbar(err, NotificationType.error);
    });
  }

  private sortSelectedProduct(data: IProductsListResponse) {
    // If Supply chain is created directly from the homepage. Do nothing.
    if (!this.selectedProductUuid) {
      return data.results;
    } // If Supply chain is created from "Duplicate chain". The Product should be first and selected.
    else if (this.selectedProductChainUuid) {
      data.results.sort(selected => {
        if (selected.uuid == this.selectedProductUuid) return -1;
        return 1;
      });

      return data.results;
    }

    // If Supply chain is created from a product page. The only one product should be shown.
    const selectedItem: IProduct[] = [];
    data.results?.map(selected => {
      if (selected.uuid == this.selectedProductUuid) selectedItem.push(selected);
    });
    return selectedItem;
  }
}
