<div class="carousels">
  <!-- TODO IMPLEMENT CAROUSELS -->
  <!-- <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig"> -->
  <!-- <div ngxSlickItem *ngFor="let slide of images" class="slide"> -->
  <div class="slide">
    <img src="{{ images[0]?.file ?? images[0] }}" alt="" class="media-swiper__item__img cursor-pointer" />
  </div>
  <!-- </ngx-slick-carousel> -->
  <app-custom-icon
    mat-dialog-close
    class="close-circle"
    [icon_category]="'essential'"
    [icon_name]="'close-circle'"
  ></app-custom-icon>
</div>
