import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Injectable } from '@angular/core';

@Injectable()
export class UnitManagementGroup {
  unitForm: UntypedFormGroup;

  constructor(private _fb: UntypedFormBuilder) {
    this.unitForm = this._fb.group({
      symbol: [null, [Validators.required]],
      name: [null],
      is_assign_to_batch: [null],
      is_assign_to_ingredient_category: [null],
      is_assign_to_step: [null],
      is_active: [true],
    });
  }
}
