import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IFactory } from '@app/core/interface/suppliers.interface';

@Component({
  selector: 'app-sites-list',
  templateUrl: './sites-list.component.html',
  styleUrls: ['./sites-list.component.scss'],
})
export class SitesListComponent {
  @Input() supplierSites!: IFactory[];
  @Output() addSupplierSite = new EventEmitter<void>();

  onAddSupplierSite() {
    this.addSupplierSite.emit();
  }
}
