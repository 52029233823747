<div class="d-flex flex-column create-add-comp">
  <h6 class="col-12">
    <strong>{{ 'Component general info' | translate }}</strong>
  </h6>
  <ng-container *ngIf="supplier && supplierSite">
    <div class="supplier-site-holder d-flex align-items-center">
      <p class="note">
        {{ 'The component you are creating will be linked to this Supplier and Supplier Site.' | translate }}
      </p>
      <div class="supplier-site d-flex justify-content-between">
        <div class="supplier d-flex align-items-center">
          <div class="circle d-flex align-items-center justify-content-center">
            <app-custom-icon icon_category="product" icon_name="truck"></app-custom-icon>
          </div>
          <div class="text flex-grow-1">
            <p class="p2">{{ 'Supplier' | translate }}</p>
            <p class="p1-strong">{{ supplier.name }}</p>
          </div>
        </div>
        <div class="site d-flex align-items-center">
          <div class="circle d-flex align-items-center justify-content-center">
            <app-custom-icon icon_category="buildings" icon_name="factory"></app-custom-icon>
          </div>
          <div class="text flex-grow-1">
            <p class="p2">{{ supplierSite.city }} {{ supplierSite.country }}</p>
            <p class="p1-strong">{{ supplierSite.name }}</p>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <form [formGroup]="form" class="d-flex flex-wrap col-12 flex-grow-1">
    <div class="col d-flex flex-column row-gap-10px">
      <mat-form-field class="input-primary full-width" appearance="outline">
        <mat-label>{{ 'Component Name' | translate }}</mat-label>
        <input formControlName="name" matInput placeholder="{{ 'Enter component name' | translate }}" />
      </mat-form-field>
      <mat-form-field class="input-primary full-width" appearance="outline">
        <mat-label>{{ 'Component Reference ID' | translate }}</mat-label>
        <input formControlName="reference" matInput placeholder="{{ 'Enter component reference ID' | translate }}" />
      </mat-form-field>
    </div>
    <mat-form-field class="input-primary textarea col description-field" id="text-area" appearance="outline">
      <mat-label>{{ 'Component Description' | translate }}</mat-label>
      <textarea
        matInput
        formControlName="description"
        placeholder="{{ 'Enter component description' | translate }}"
        rows="5"
        appTextareaAutoResize
      ></textarea>
    </mat-form-field>
  </form>
  <div class="buttons col-12 d-flex justify-content-end">
    <app-rounded-button
      class="btn-width-180px"
      color="tetriary-link underlined"
      button_text="Cancel"
      size="md"
      mat-dialog-close
    >
    </app-rounded-button>
    <app-rounded-button
      class="btn-width-180px"
      color="primary"
      button_text="{{ 'Create & Add Component' | translate }}"
      size="md"
      [disabled]="form.invalid"
      (click)="onCreateComponent()"
    >
    </app-rounded-button>
  </div>
</div>
