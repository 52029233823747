import { Component, Inject } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IPublicationType } from '@interface/publication.interface';
import { ChainOverviewGroup } from '@module/publication/chain-overview/chain-overview.group';
import { SnackbarService } from '@service/snackbar.service';
import { GetDirtyValues } from '@app/core/utils/form-dirty-values';
import { NotificationType } from '@app/core/constants';
import { CustomPublicationsFacade } from '@module/publication/custom-publications/custom-publications.facade';
import { ICustomPublicationType } from '@module/publication/custom-publications/custom-publications.models';

@Component({
  selector: 'app-amway-edit-classification',
  templateUrl: './amway-edit-classification.component.html',
  styleUrl: './amway-edit-classification.component.scss',
})
export class AmwayEditClassificationComponent {
  form: UntypedFormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { classification: IPublicationType; product_chain: string },
    public dialogRef: MatDialogRef<AmwayEditClassificationComponent>,
    private _group: ChainOverviewGroup,
    private _customPublicationFacade: CustomPublicationsFacade,
    private _snackbarService: SnackbarService
  ) {
    this.form = this._group.editClassificationForm;
    this.form.patchValue({
      name: this.data.classification?.name,
      color: this.data.classification?.color ?? '#000000',
      product_chain: this.data.product_chain,
    });

    this.form.get('product_chain')?.markAsDirty();
  }

  updateClassification(): void {
    const changedFormValues: Partial<IPublicationType> = GetDirtyValues(this.form);
    this._customPublicationFacade
      .updateClassification({ ...changedFormValues }, this.data.product_chain, this.data.classification.uuid)
      .subscribe(
        () => {
          this.dialogRef.close({ changedFormValues });
        },
        error => this._error(error)
      );
  }

  saveChanges(): void {
    const changedFormValues: Partial<IPublicationType> = GetDirtyValues(this.form);
    this._customPublicationFacade.createClassification({ ...changedFormValues }, this.data.product_chain).subscribe({
      next: this.createClassificationSuccess.bind(this),
      error: this._error.bind(this),
    });
  }

  createClassificationSuccess(data: ICustomPublicationType) {
    this.dialogRef.close(data);
  }

  updateClassificationSuccess(data: Partial<IPublicationType>) {
    this.dialogRef.close({ data });
  }

  colorPickerChange(color: string): void {
    this.form.get('color')?.setValue(color);
    this.form.get('color')?.markAsDirty();
  }

  private _error(error: Record<string, string[]>): void {
    Object.values(error).map(err => this._snackbarService.openTypeSnackbar(err[0], NotificationType.error));
  }
}
