import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { HazardousSubstanceComponent } from './hazardous-substance/hazardous-substance.component';
import { AgecPackagingType, IAgecPackaging, PackageRecyclabitity } from '@app/core/interface/regulations.interface';
import { RecyclabilityComponent } from './recyclability/recyclability.component';
import { RecycledMaterialsInfoComponent } from './recycled-materials-info/recycled-materials-info.component';
import { DigitalSheetGroup } from '../../../create-digital-product-sheet-dialog.group';
import { IProduct } from '@app/core/interface/products.interface';

@Component({
  selector: 'app-set-packaging-info',
  templateUrl: './set-packaging-info.component.html',
  styleUrls: ['./set-packaging-info.component.scss'],
  providers: [DigitalSheetGroup],
})
export class SetPackagingInfoComponent implements OnInit, OnChanges, AfterViewInit {
  @ViewChild(RecyclabilityComponent) recyclabilityComponent!: RecyclabilityComponent;
  @ViewChild(RecycledMaterialsInfoComponent) recycledMaterialsInfoComponent!: RecycledMaterialsInfoComponent;
  @ViewChildren(HazardousSubstanceComponent) hazardousSubstanceComponent!: QueryList<HazardousSubstanceComponent>;
  @Output() isInvalid = new EventEmitter<boolean>(true);
  @Input() packaging!: Partial<IAgecPackaging>;
  @Input() product!: IProduct;
  form!: UntypedFormGroup;
  types = [{ name: 'E-commerce' }, { name: 'Store' }, { name: 'E-commerce & Store' }];
  indicatorImg = '../../../../../../../../assets/images/indicator-progress-bar.svg';
  // editable = false;
  selectedItems = 0;
  formValues: Array<Partial<IAgecPackaging>> = [];
  constructor(private _group: DigitalSheetGroup) {
    this.form = _group.packagingForm;
  }
  ngOnInit(): void {
    this.isInvalid.emit(true);
  }
  ngAfterViewInit(): void {
    this.checkValidity();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['packaging']?.currentValue) {
      this.form.patchValue({
        name: changes['packaging'].currentValue.name,
        type: AgecPackagingType[changes['packaging'].currentValue.package_type],
        recyclability: PackageRecyclabitity[changes['packaging'].currentValue.package_recyclability],
        recycled_materials: changes['packaging'].currentValue.is_recycled_materials,
        hazardous_substances: changes['packaging'].currentValue.hazardous_substances,
      });
      if (changes['packaging']?.currentValue.uuid) this.selectedItems = 3;
    }
  }

  addName(name: string): void {
    this.form.controls['name'].setValue(name);
  }
  onSelection(): void {
    if (this.selectedItems < 3) this.selectedItems = this.selectedItems + 1;
    this.checkValidity();
  }
  checkValidity(): void | null {
    let isInvalid = false;
    this.formValues = [];
    if (this.hazardousSubstanceComponent)
      this.hazardousSubstanceComponent.forEach(component => {
        if (component.form.invalid) {
          isInvalid = true;
        }
        this.formValues.push({ ...component.form.value });
      });
    this.formValues.push({
      is_recyclable_type: this.recyclabilityComponent.selectedValue,
      is_recycled_materials: this.recycledMaterialsInfoComponent.selectedValue,
      is_recycled_materials_display: this.recycledMaterialsInfoComponent.visibility,
      is_recyclable_display: this.recyclabilityComponent.visibility,
    });
    if (
      this.recyclabilityComponent.selectedValue === '' ||
      this.recyclabilityComponent.selectedValue === undefined ||
      this.recycledMaterialsInfoComponent.selectedValue === undefined
    )
      isInvalid = true;
    this.isInvalid.emit(isInvalid);
  }
}
