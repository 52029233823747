<div class="holder d-flex flex-column">
  <div class="table-holder">
    <!-- Table -->
    <table mat-table matSort [dataSource]="dataSource" class="mat-table certificates-product-table custom-table">
      <ng-container matColumnDef="name">
        <th mat-header-cell mat-sort-header *matHeaderCellDef class="first-header-cell">
          {{ 'Certificate type' | translate }}
        </th>
        <td mat-cell *matCellDef="let element" class="alerts-name">
          <div class="content-width cert-name">{{ element.certificate.name }}</div>
        </td>
      </ng-container>

      <ng-container matColumnDef="area">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>
          {{ 'Area' | translate }}
        </th>
        <td mat-cell *matCellDef="let element" class="alerts-type">
          <div class="content-width">{{ element.certificate.category.name }}</div>
        </td>
      </ng-container>

      <ng-container matColumnDef="start-date" class="product-suppliers-action">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="product-suppliers-action">
          {{ 'Start Date' | translate }}
        </th>
        <td mat-cell *matCellDef="let element" class="product-suppliers-action">
          {{ element.validity_start ? (element.validity_start | date: 'dd.MM.YY') : ('Not set' | translate) }}
        </td>
      </ng-container>
      <ng-container matColumnDef="end-date" class="product-suppliers-action">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="product-suppliers-action">
          {{ 'End Date' | translate }}
        </th>
        <td mat-cell *matCellDef="let element" class="product-suppliers-action">
          {{ element.validity_end ? (element.validity_end | date: 'dd.MM.YY') : ('Not set' | translate) }}
        </td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>
          {{ 'Status' | translate }}
        </th>
        <td mat-cell *matCellDef="let element" class="">
          <div class="status" *ngIf="!element.is_expired">
            <app-custom-icon
              id="tick-circle"
              [icon_category]="'essential'"
              [icon_name]="'tick-circle'"
              [matTooltip]="'Up to date' | translate"
              matTooltipClass="custom-tooltip-center"
              [matTooltipPosition]="'above'"
            >
              >
            </app-custom-icon>
            <p>{{ 'Up to date' | translate }}</p>
          </div>
          <div class="status-expired" *ngIf="element.is_expired">
            <app-custom-icon
              [icon_category]="'essential'"
              [icon_name]="'danger'"
              [matTooltip]="'Expired Date' | translate"
              matTooltipClass="custom-tooltip-center"
              [matTooltipPosition]="'above'"
            >
              >
            </app-custom-icon>
            <p>{{ 'Expired' | translate }}</p>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="actions" class="product-certificates-action">
        <th mat-header-cell *matHeaderCellDef class="product-certificates-action">
          {{ 'Actions' | translate }}
        </th>
        <td mat-cell *matCellDef="let element" class="product-certificates-action">
          <div class="actions-holder">
            <app-custom-icon
              matTooltip="{{ 'View Certificate' | translate }}"
              matTooltipClass="custom-tooltip-center centering"
              matTooltipPosition="above"
              [icon_category]="'security'"
              [icon_name]="'eye'"
              (click)="openViewFile(element, element.document, element?.document?.split('/').at(-1))"
            ></app-custom-icon>
            <app-custom-icon
              matTooltip="{{ 'Edit Certificate' | translate }}"
              matTooltipClass="custom-tooltip-center centering"
              matTooltipPosition="above"
              [icon_category]="'files'"
              [icon_name]="'edit'"
              (click)="editCertification(element)"
            ></app-custom-icon>
            <app-custom-icon
              id="trash"
              [icon_category]="'essential'"
              [icon_name]="'trash'"
              class="cursor-pointer"
              matTooltip="{{ 'Delete Certificate' | translate }}"
              matTooltipClass="custom-tooltip-end"
              matTooltipPosition="above"
              (click)="deleteCertificate(element.uuid, element.certificate.category.name)"
            >
            </app-custom-icon>
          </div>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </div>
  <div class="card-footer">
    <mat-paginator #paginator showFirstLastButtons aria-label="Select page of components"> </mat-paginator>
  </div>
</div>
