import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { INameID, IProject, ISubproject } from '@app/core/interface/projects.interface';
import { IProduct, IProductDetails } from '@app/core/interface/products.interface';

import { FormGroup } from '@angular/forms';
import { GetDirtyValues } from '@app/core/utils/form-dirty-values';
import { MatDialog } from '@angular/material/dialog';
import { ProductDashboardGroup } from '../product-dashboard.group';
import { ProductsFacade } from '@app/core/facade/products.facade';
import { ProjectComponent } from '@app/module/projects/project/project.component';
import { ProjectDetailsDialogComponent } from '@app/module/projects/project-details-dialog/project-details-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { UnassingProjectDialogComponent } from '@app/module/projects/unassing-project-dialog/unassing-project-dialog.component';

@Component({
  selector: 'app-project-overview',
  templateUrl: './project-overview.component.html',
  styleUrls: ['./project-overview.component.scss'],
})
export class ProjectOverviewComponent implements AfterViewInit {
  @Input() product!: IProduct;
  @Input() project!: Partial<IProject> | Partial<ISubproject>;
  @Input() has_subproject!: boolean;
  @Input() has_project!: boolean;
  @Input() is_project!: boolean;
  @Input() name!: string;
  @Input() projectsList!: Array<IProject> | Array<INameID>;
  @Input() projectsListOrg!: Array<IProject> | Array<INameID>;
  @Input() activeProjectsList!: Array<IProject> | Array<INameID>;
  @Input() activeProjectsListOrg!: Array<IProject> | Array<INameID>;
  @Output() onUpdateSuccess = new EventEmitter<void>();
  @Output() onUpdateError = new EventEmitter<void>();
  form!: FormGroup;
  viewCreateSubProject!: boolean;
  view!: string;
  edit!: string;
  switch!: string;
  unassign!: string;
  btnText!: string;
  btnLabel!: string;
  @ViewChild('inputFilter', { static: false }) inputFilter!: ElementRef;

  constructor(
    private _dialog: MatDialog,
    private _productsFacade: ProductsFacade,
    private _productDashboardGroup: ProductDashboardGroup,
    private translate: TranslateService,
    private _changeDetectorRef: ChangeDetectorRef
  ) {
    this.form = this._productDashboardGroup.productDetails;
  }

  ngAfterViewInit() {
    this.setTranslation();
  }

  setTranslation(): void {
    this.view = this.is_project ? this.translate.instant('View project') : this.translate.instant('View sub-project');
    this.edit = this.is_project ? this.translate.instant('Edit project') : this.translate.instant('Edit sub-project');
    this.switch = this.is_project
      ? this.translate.instant('Switch project')
      : this.translate.instant('Switch sub-project');
    this.unassign = this.translate.instant('Unassign product');
    this.btnText = this.is_project
      ? this.translate.instant('Create Project')
      : this.translate.instant('Create Sub-project');
    this.btnLabel = this.is_project
      ? this.translate.instant('Can’t find the project you are looking for?')
      : this.translate.instant('Can’t find the sub-project you are looking for?');
    this._changeDetectorRef.detectChanges();
  }

  viewProject(project: Partial<IProject> | ISubproject | null | undefined) {
    this._dialog.open(ProjectDetailsDialogComponent, {
      width: '1160px',
      height: '768px',
      panelClass: 'padding-0',
      data: {
        project: project,
        is_project: this.is_project,
      },
    });
  }

  createProjectDialog(uuid: string | undefined, is_editing: boolean) {
    const dialogRef = this._dialog.open(ProjectComponent, {
      width: '1160px',
      minHeight: '768px',
      panelClass: 'top-padding-0',
      data: {
        is_editing: is_editing,
        projectId: this.is_project ? uuid : this.product?.project?.uuid,
        subprojectId: uuid,
        is_project: this.is_project,
      },
    });

    dialogRef.afterClosed().subscribe((result: IProject | ISubproject) => {
      if (result) {
        if (is_editing) {
          this.onUpdateSuccess.emit();
        }
        if (!is_editing) {
          this.addProductToProject(result.uuid, true, false);
        }
      }
    });
  }

  addProductToProject(uuid: string, is_project: boolean, is_switching: boolean): void {
    const changedFormValues: Partial<IProductDetails> = GetDirtyValues(this.form);
    if (this.is_project || is_project) {
      changedFormValues['project'] = uuid;
      if (is_switching) {
        changedFormValues['subproject'] = '';
        this.has_subproject = false;
        this.viewCreateSubProject = false;
      }

      if (uuid == '') {
        this.has_project = false;
        this.has_subproject = false;
      }
    } else {
      changedFormValues['project'] = this.product.project?.uuid;
      changedFormValues['subproject'] = uuid;
      this.has_subproject = false;
    }

    this._productsFacade.updateProduct$({ ...changedFormValues }, this.product.uuid).subscribe({
      next: this._successUpdateProduct.bind(this),
      error: this._error.bind(this),
    });
  }

  private _successUpdateProduct(): void {
    this.onUpdateSuccess.emit();
  }

  private _error(): void {
    this.onUpdateError.emit();
  }

  unassignProject(is_project: boolean) {
    if (is_project) {
      this.has_project = false;
    }

    if (!is_project) {
      this.has_subproject = false;
    }
    const dialogRef = this._dialog.open(UnassingProjectDialogComponent, {
      width: '580px',
      data: {
        is_project: is_project,
      },
    });
    dialogRef.afterClosed().subscribe((result: { uuid?: ''; close?: true }) => {
      if (result.close && is_project) {
        this.has_project = true;
      }
      if (result.close && !is_project) {
        this.has_subproject = true;
      }
      if (result.uuid == '') {
        this.addProductToProject(result.uuid, is_project, true);
        this.viewCreateSubProject = false;
      }
    });
  }

  /**
   * Focus on inputFilter in dropdown menu while typing
   */
  onBlur() {
    this.inputFilter.nativeElement.focus();
  }

  /**
   * Filtering existing projects
   */
  filterProjects(event: Event | KeyboardEvent) {
    const value = (event.target as HTMLInputElement).value;
    if (!value) {
      this.activeProjectsList = this.activeProjectsListOrg;
    } else {
      this.activeProjectsList = this.activeProjectsListOrg.filter(el =>
        el.name.toLowerCase().includes(value.toLowerCase())
      );
    }
  }

  /**
   * Clearing filter/ Clearing input value
   */
  clearFilter(): void {
    this.activeProjectsList = this.activeProjectsListOrg;
    this.inputFilter.nativeElement.value = '';
  }
}
