<div class="background">
  <div class="certificates-view">
    <mat-card class="certificates-wrapper">
      <div class="header-wrapper">
        <mat-card class="options-header">
          <div class="certificate-header">
            <div class="certificate-heading">
              <p>
                {{ 'Certificates Type List' | translate }}
              </p>
            </div>

            <div class="filter-view-wrapper">
              <!-- Input filter -->
              <div class="filter">
                <mat-form-field
                  class="input-primary mat-form-field-10-px-brd-rd custom-filter-header"
                  appearance="outline"
                >
                  <mat-label class="label-text">{{ 'Quick search...' | translate }}</mat-label>
                  <input matInput (keyup)="onQuickFilterChanged($event)" placeholder="Ex. Certificate" #input />
                  <app-custom-icon
                    class="search-filter"
                    [icon_category]="'search'"
                    [icon_name]="'search-normal-2'"
                  ></app-custom-icon>
                </mat-form-field>
              </div>
              <div class="create-certificate-btn">
                <app-rounded-button
                  color="primary"
                  [button_text]="'Create Certificate Type'"
                  [size]="'md'"
                  (click)="createEditCertificateType(null)"
                >
                </app-rounded-button>
              </div>
            </div>
          </div>

          <div class="filter-category" [ngClass]="!selectedCategory ? 'flex-end' : 'space-between'">
            <div class="filter-wrapper dropdown-primary">
              <div class="checkbox">
                <mat-checkbox
                  class="checkbox-primary"
                  (change)="showInactiveCertificates($event)"
                  [checked]="show_inactive"
                  >{{ 'show deactivated certificate types' | translate }}</mat-checkbox
                >
              </div>
            </div>
          </div>
        </mat-card>
      </div>
      <div class="wrapper">
        <div class="main-holder">
          <div class="table-wraper">
            <!-- Table View -->
            <table class="custom-table" mat-table matSort [dataSource]="dataSource">
              <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Certificate type name' | translate }}</th>
                <td mat-cell *matCellDef="let element" [ngClass]="{ inactive: !element.is_active }">
                  {{ element.name }}
                </td>
              </ng-container>

              <ng-container matColumnDef="category">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Category' | translate }}</th>
                <td mat-cell *matCellDef="let element">{{ element?.category?.name | slice: 0 : 30 }}</td>
              </ng-container>

              <ng-container matColumnDef="summary">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Summary' | translate }}</th>
                <!-- <td mat-cell *matCellDef="let element">{{ element?.summary | slice : 0 : 30 }}</td> -->
                <td mat-cell *matCellDef="let element">
                  <div class="sum-ser">
                    <app-view-rich-text [content]="element.summary" [inheritStyles]="true"></app-view-rich-text>
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef class="actions-header text-center">
                  {{ 'Actions' | translate }}
                </th>
                <td mat-cell *matCellDef="let element" class="actions-body text-center">
                  <div class="actions-holder">
                    <app-custom-icon
                      *ngIf="element.is_active"
                      id="edit"
                      [icon_category]="'files'"
                      [icon_name]="'edit'"
                      matTooltip="{{ 'Edit Certificate' | translate }}"
                      matTooltipClass="custom-tooltip-center"
                      matTooltipPosition="above"
                      (click)="createEditCertificateType(element.uuid)"
                    >
                    </app-custom-icon>
                    <app-custom-icon
                      matTooltip="{{ 'Deactivate Certificate' | translate }}"
                      matTooltipClass="custom-tooltip-end"
                      matTooltipPosition="above"
                      id="deactivate-red"
                      [icon_category]="'essential'"
                      [icon_name]="'deactivate-red'"
                      *ngIf="element.is_active"
                      (click)="removeCertificateType(true, element)"
                    >
                    </app-custom-icon>
                  </div>
                  <app-custom-icon
                    matTooltip="{{ 'Activate Certificate' | translate }}"
                    matTooltipClass="custom-tooltip-end"
                    matTooltipPosition="above"
                    id="activate"
                    [icon_category]="'essential'"
                    [icon_name]="'activate'"
                    class="activate"
                    *ngIf="!element.is_active"
                    (click)="restoreCertificateType(false, element)"
                  ></app-custom-icon>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>
          </div>
        </div>
      </div>

      <div class="card-footer">
        <mat-paginator class="custom-paginator" showFirstLastButtons aria-label="Select page of certificates">
        </mat-paginator>
      </div>
    </mat-card>
  </div>
</div>
