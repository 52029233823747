<div class="product-project d-flex flex-column">
  <div
    *ngIf="product && !product.project"
    [ngClass]="need_help ? 'assign-holder-long' : 'assign-holder-short'"
    class="assign-holder d-flex justify-content-around"
  >
    <div [matMenuTriggerFor]="beforeMenu" class="assign-action d-flex align-items-center justify-content-center">
      <p class="m-0">{{ 'Assign to Project' | translate }}</p>
      <app-custom-icon [icon_category]="'essential'" [icon_name]="'add-circle'"> </app-custom-icon>
    </div>
    <!-- Mat-menu for add existing project START -->
    <mat-menu
      class="mat-menu-primary existing-project-menu add-new-project-menu"
      #beforeMenu="matMenu"
      xPosition="after"
      (closed)="clearFilter()"
    >
      <div class="menu-wrapper" (click)="$event.stopPropagation()">
        <div id="menu-text">
          <p class="add-text">{{ 'Add this product to an existing project' | translate }}</p>
        </div>
        <div class="filterR">
          <mat-form-field class="input-primary modal-filter mat-form-field-10-px-brd-rd" appearance="outline">
            <!-- temporary comment to fix position MDC after modal migration -->
            <mat-label class="label-text">{{ 'Search for project' | translate }}</mat-label>
            <input (blur)="onBlur()" (input)="filterProjects($event)" matInput placeholder="" #inputFilter />

            <app-custom-icon
              class="search-filter"
              [icon_category]="'search'"
              [icon_name]="'search-normal-2'"
            ></app-custom-icon>
          </mat-form-field>
        </div>
        <div class="item-holder">
          <div *ngIf="activeProjectsList.length > 0">
            <button
              class="custom-item"
              mat-menu-item
              *ngFor="let project of activeProjectsList"
              (click)="addProductToProject(project.uuid, true, false)"
            >
              <div class="menu-items">
                <div class="supp-name">
                  <div class="name-wrapper">
                    <p class="name">{{ project.name }}</p>
                  </div>
                </div>
              </div>
            </button>
          </div>

          <button *ngIf="projectsList.length <= 0" class="custom-empty-item" mat-menu-item>
            <div class="empty-holder">
              <div class="icon-circle">
                <app-custom-icon [icon_category]="'essential'" [icon_name]="'danger'"></app-custom-icon>
              </div>
              <p class="d-flex align-items-center">{{ 'There are no projects yet!' }}</p>
            </div>
          </button>
        </div>
        <div class="bottom-action">
          <p class="info-text">{{ 'Can’t find the project you are looking for?' | translate }}</p>
          <app-rounded-button
            (click)="createProjectDialog('', false)"
            color="primary"
            button_text="Create Project"
            size="md"
          >
          </app-rounded-button>
        </div>
      </div>
    </mat-menu>
    <!-- Mat-menu for add existing project END -->
    <div class="divider"></div>
    <div class="need-help d-flex align-items-center justify-content-center">
      <app-custom-icon [icon_category]="'essential'" [icon_name]="'info-circle'"> </app-custom-icon>
      <p (click)="need_help = !need_help" *ngIf="!need_help" class="m-0 need-help-text">{{ 'need help?' }}</p>
      <p *ngIf="need_help" class="m-0 description">
        {{
          'Traced products can be grouped into projects and traceability data can be then aggregated to be available at project level.'
            | translate
        }}
        <span class="see-less" (click)="need_help = !need_help">{{ ' See less' | translate }}</span>
      </p>
    </div>
  </div>
  <div class="d-flex projects-holder">
    <div *ngIf="product && product.project" class="left-section d-flex flex-column">
      <p class="m-0 project-title">{{ 'Product project' | translate }}</p>
      <app-project-overview
        *ngIf="product && product.project"
        [product]="product"
        [project]="product.project"
        [projectsList]="projectsList"
        [projectsListOrg]="projectsListOrg"
        [has_subproject]="has_subproject"
        [has_project]="has_project"
        [activeProjectsList]="activeProjectsList"
        [activeProjectsListOrg]="activeProjectsListOrg"
        [is_project]="true"
        (onUpdateSuccess)="updateProduct()"
        (onUpdateError)="error()"
        [name]="'project'"
      ></app-project-overview>
    </div>
    <div *ngIf="product && product.project" class="right-section d-flex flex-column justify-content-between">
      <div *ngIf="product && !product.subproject" class="assign-subproject-holder">
        <div
          [matMenuTriggerFor]="subprojectMenu"
          class="assign-button d-flex justify-content-center align-items-center"
        >
          <p class="m-0">{{ 'Assign to Sub-Project' | translate }}</p>
          <app-custom-icon [icon_category]="'essential'" [icon_name]="'add-circle'"> </app-custom-icon>
        </div>
        <!-- Add existing subproject Mat-menu START -->
        <mat-menu
          class="mat-menu-primary existing-project-menu add-new-project-menu"
          #subprojectMenu="matMenu"
          xPosition="after"
          (closed)="clearSubprojectFilter()"
        >
          <div class="menu-wrapper" (click)="$event.stopPropagation()">
            <div id="menu-text">
              <p class="add-text">{{ 'Add product to an existing sub-project' | translate }}</p>
            </div>
            <div class="filterR">
              <mat-form-field class="input-primary modal-filter mat-form-field-10-px-brd-rd" appearance="outline">
                <!-- temporary comment to fix position MDC after modal migration -->
                <mat-label class="label-text">{{ 'Search for sub-project' | translate }}</mat-label>

                <input (blur)="onBlur()" (input)="filtersubprojects($event)" matInput placeholder="" #inputFilter />

                <app-custom-icon
                  class="search-filter"
                  [icon_category]="'search'"
                  [icon_name]="'search-normal-2'"
                ></app-custom-icon>
              </mat-form-field>
            </div>
            <div class="item-holder">
              <div *ngIf="subprojectsList.length > 0">
                <button
                  class="custom-item"
                  mat-menu-item
                  *ngFor="let subproject of subprojectsList"
                  (click)="addProductToProject(subproject.uuid, false, false)"
                >
                  <div class="menu-items">
                    <div class="supp-name">
                      <div class="name-wrapper">
                        <p class="name">{{ subproject.name }}</p>
                      </div>
                    </div>
                  </div>
                </button>
              </div>
              <button *ngIf="subprojectsList.length <= 0" class="custom-empty-item" mat-menu-item>
                <div class="empty-holder">
                  <div class="icon-circle">
                    <app-custom-icon [icon_category]="'essential'" [icon_name]="'danger'"></app-custom-icon>
                  </div>
                  <p class="m-0 no-subproject-text">{{ 'There are no sub-projects created yet!' | translate }}</p>
                </div>
              </button>
            </div>
            <div class="bottom-action">
              <p class="info-text">{{ 'Can’t find the sub-project you are looking for?' | translate }}</p>
              <app-rounded-button
                (click)="createSubProjectDialog('', false)"
                color="primary"
                button_text="Create sub-project"
                size="md"
              >
              </app-rounded-button>
            </div>
          </div>
        </mat-menu>
        <!-- Add existing subproject Mat-menu END -->
      </div>

      <div class="d-flex flex-column subproject-holder" *ngIf="viewCreateSubProject || (product && product.subproject)">
        <p class="m-0 project-title">{{ 'Sub-project' | translate }}</p>
        <!-- Subproject details START -->
        <app-project-overview
          *ngIf="product && product.subproject"
          [product]="product"
          [project]="product.subproject"
          [has_subproject]="has_subproject"
          [has_project]="has_project"
          (onUpdateSuccess)="updateProduct()"
          (onUpdateError)="error()"
          [projectsList]="subprojectsList"
          [projectsListOrg]="subprojectsListOrg"
          [activeProjectsList]="subprojectsList"
          [activeProjectsListOrg]="subprojectsListOrg"
          [is_project]="false"
          [name]="'sub-project'"
        ></app-project-overview>
        <!-- Subproject section END  -->
      </div>
    </div>
  </div>
</div>
