<div class="wrapper">
  <div class="header">
    <h6 class="strong">{{ 'supplier-dashboard.Steps overview' | translate }}</h6>

    <div class="dropdown" *ngIf="!hideMenu">
      <div class="d-flex justify-content-end">
        <p class="gray">{{ 'supplier-dashboard.select step type' | translate }}</p>
      </div>
      <div class="dropdown-btn" mat-button [matMenuTriggerFor]="actionMenu">
        <!-- Display the selected step type -->
        <p class="text">{{ selectedStepType | translate }}</p>
        <app-custom-icon
          class="cursor-pointer"
          matSuffix
          [icon_category]="'arrows'"
          [icon_name]="'arrow-down-2'"
        ></app-custom-icon>
      </div>

      <mat-menu class="mat-menu-primary steps-overview" #actionMenu="matMenu" xPosition="before">
        <ng-container *ngFor="let stepType of stepTypes">
          <button class="custom-item" mat-menu-item (click)="updateStepType(stepType.value)">
            <div class="icon-item">
              <div class="icon">
                <app-custom-icon
                  *ngIf="selectedStepType === stepType.value"
                  [icon_category]="'essential'"
                  [icon_name]="'tick-circle'"
                ></app-custom-icon>
              </div>

              <div class="selected-name">
                <p [ngClass]="selectedStepType === stepType.value ? 'name-strong m-0' : 'name m-0'">
                  {{ stepType.value | translate }}
                </p>
              </div>
            </div>
          </button>
        </ng-container>
      </mat-menu>
    </div>
  </div>

  <mat-divider></mat-divider>

  <div class="overview-content">
    <div class="holder">
      <p *ngIf="hideMenu">{{ chartTitle }}</p>
      <div class="doughnut-container">
        <app-doughnut-step-overview
          [allToUpdateChainStepsPercentage]="toUpdateChainStepsPercentage"
          [allUpdatedChainStepsPercentage]="updatedChainStepsPercentage"
          [updateSteps$]="updateSteps$"
        ></app-doughnut-step-overview>
      </div>

      <p class="gray-normal">{{ 'supplier-dashboard.Overview in numbers' | translate }}</p>
      <div class="steps">
        <div class="number-display">
          <div class="circle gray">
            <h5>{{ allSelectedChainSteps }}</h5>
          </div>
          <p class="number-text">
            {{ 'supplier-dashboard.Total number' | translate }}<br /><span class="center"
              >{{ 'supplier-dashboard.of steps' | translate }}
            </span>
          </p>
        </div>

        <div class="number-display">
          <div class="circle red">
            <h5>{{ allSelectedToUpdateChainSteps }}</h5>
          </div>
          <p class="number-text">{{ 'supplier-dashboard.Steps to update' | translate }}</p>
        </div>
        <div class="number-display">
          <div class="circle blue">
            <h5>{{ allSelectedUpdatedChainSteps }}</h5>
          </div>
          <p class="number-text">{{ 'supplier-dashboard.Updated steps' | translate }}</p>
        </div>
      </div>
    </div>
    <div class="action">
      <app-rounded-button
        color="secondary"
        [button_text]="'supplier-dashboard.View Steps' | translate"
        [size]="'sm'"
        icon_category="arrows"
        icon_name="arrow-right"
        [icon_right]="true"
        [routerLink]="'/chain-steps-info'"
      >
      </app-rounded-button>
    </div>
  </div>
</div>
