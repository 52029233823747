import { Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

@Injectable()
export class CreateComponentGroup {
  createComponentForm: UntypedFormGroup;

  constructor(private _fb: UntypedFormBuilder) {
    this.createComponentForm = this._fb.group({
      name: [null, [Validators.required]],
      reference: [null],
      description: [null],
      category: [null],
      quantity: [null],
      sourcing_location: [null],
    });
  }
}
