import { REGISTER_STEP, Storage } from '@app/core/constants';

import { Component } from '@angular/core';
import { DataStorageService } from '@app/core/service/data-localstorage.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-account-created',
  templateUrl: './account-created.component.html',
  styleUrls: ['./account-created.component.scss'],
})
export class AccountCreatedComponent {
  constructor(private _dataStorageService: DataStorageService, private _router: Router) {}

  completeRegisterSteps() {
    this._dataStorageService.set(REGISTER_STEP, '1', Storage.session);

    this._router.navigate(['/register/complete-register']);
  }
}
