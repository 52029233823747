import {
  IDeletePublicationGroup,
  IMapBoxFullAddress,
  IProductPublication,
  IPublicationStep,
} from '../interface/publication.interface';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';
import { IPreviewPublication } from '../interface/preview_publicaton.interface';
import { ICustomPublicationStep } from '@app/module/publication/custom-publications/custom-publications.models';

@Injectable({
  providedIn: 'root',
})
export class PublicationService {
  private apiUrl = environment.api;
  private assetPath = environment.assetPath;

  constructor(private _http: HttpClient) {}

  getPublicationProductChains$(uuid: string): Observable<IProductPublication[]> {
    return this._http.get<IProductPublication[]>(`${this.apiUrl}/products/${uuid}/productchains/`);
  }

  updatePublicationChainStep$(data: FormData, uuid: string): Observable<ICustomPublicationStep> {
    return this._http.patch<ICustomPublicationStep>(`${this.apiUrl}/steps/${uuid}/`, data);
  }

  deletePublicationStepCustomFields$(data: FormData, groupUuid: string): Observable<IPublicationStep> {
    return this._http.delete<IPublicationStep>(`${this.apiUrl}/groups/${groupUuid}/customfields/`, { body: data });
  }

  updateChainClassificationsPosition$(data: string[]): Observable<string[]> {
    return this._http.post<string[]>(`${this.apiUrl}/type-step-position/`, {
      type_steps: data,
    });
  }

  updateStepsPosition$(data: FormData): Observable<string[]> {
    return this._http.patch<string[]>(`${this.apiUrl}/steps/publication-position/`, data);
  }

  getFullAddress$(searchInput: string): Observable<IMapBoxFullAddress> {
    return this._http.get<IMapBoxFullAddress>(
      `https://api.mapbox.com/geocoding/v5/mapbox.places/${searchInput}.json?access_token=${environment.mapBoxAccessToken}`
    );
  }

  deletePublicationGroup$(uuid: string): Observable<IDeletePublicationGroup> {
    return this._http.delete<IDeletePublicationGroup>(`${this.apiUrl}/groups/${uuid}/`);
  }

  getPreviewData$(uuid: string): Observable<IPreviewPublication> {
    return this._http.get<IPreviewPublication>(`${this.apiUrl}/productchain/${uuid}/preview/`);
  }
}
