<div class="holder">
  <div class="header">
    <div class="logo"></div>
    <app-custom-icon
      mat-dialog-close
      class="cursor-pointer"
      [icon_category]="'essential'"
      [icon_name]="'close-circle'"
    ></app-custom-icon>
  </div>
  <div class="description">
    <h5>{{ description }}</h5>
  </div>

  <app-rounded-button
    mat-dialog-close
    *ngIf="description_type === 'first product'"
    (click)="manageSupplierDialog()"
    color="secondary"
    [button_text]="'Ok, Next'"
    [icon_category]="'arrows'"
    [icon_name]="'arrow-right-long'"
    [icon_right]="true"
    [size]="'md'"
  >
  </app-rounded-button>
  <app-rounded-button
    mat-dialog-close
    *ngIf="description_type === 'manage suppliers'"
    (click)="crateChainDialog()"
    color="secondary"
    [button_text]="'Ok, Next'"
    [icon_category]="'arrows'"
    [icon_name]="'arrow-right-long'"
    [icon_right]="true"
    [size]="'md'"
  >
  </app-rounded-button>
</div>
