<mat-card class="holder">
  <div class="header">
    <h6 class="m-0">{{ 'Project details' | translate }}</h6>
  </div>
  <div class="project d-flex flex-column">
    <div class="project-details d-flex">
      <!-- <div class="photo-name-holder d-flex"> -->
      <div class="project-photo">
        <img src="{{ project?.logo ? project?.logo : '../../../../../../../assets/images/brand-avatar.png' }}" alt="" />
      </div>
      <div class="info-holder d-flex flex-column">
        <div class="top d-flex justify-content-between">
          <div class="name-holder d-flex justify-content-between">
            <h5 class="m-0">{{ project?.name }}</h5>
            <div class="action-holder d-flex">
              <app-custom-icon
                id="eye"
                [icon_category]="'security'"
                [icon_name]="'eye'"
                matTooltip="{{ 'View Project' | translate }}"
                matTooltipClass="custom-tooltip-center"
                matTooltipPosition="above"
                (click)="openViewDialog(project)"
              >
              </app-custom-icon>
              <app-custom-icon
                id="edit"
                [icon_category]="'files'"
                [icon_name]="'edit'"
                matTooltip="{{ 'Edit Project' | translate }}"
                matTooltipClass="custom-tooltip-center"
                matTooltipPosition="above"
                (click)="openCreateDialog(project.uuid, true)"
              >
              </app-custom-icon>
              <app-custom-icon
                (click)="deactivateProject(project)"
                id="deactivate"
                [icon_category]="'essential'"
                [icon_name]="'deactivate-red'"
                matTooltip="{{ 'Deactivate Project' | translate }}"
                matTooltipClass="custom-tooltip-center"
                matTooltipPosition="above"
              >
              </app-custom-icon>
            </div>
          </div>

          <div
            class="status d-flex align-items-center"
            [ngClass]="{
              'in-progress': project?.status?.name === 'In progress',
              'not-started': project?.status?.name === 'Not started',
              completed: project?.status?.name === 'Completed'
            }"
          >
            <div
              class="dot"
              [ngClass]="{
                'dot-in-progress': project?.status?.name === 'In progress',
                'dot-not-started': project?.status?.name === 'Not started',
                'dot-completed': project?.status?.name === 'Completed'
              }"
            ></div>
            <p>{{ project?.status?.name }}</p>
          </div>
        </div>
        <div class="description m-0">
          <p>{{ project?.description ? project?.description : 'no description added yet' }}</p>
        </div>
      </div>

      <!-- </div> -->
    </div>
    <div class="divider"></div>
    <div class="project-location d-flex">
      <div *ngIf="project?.is_location_specific" class="divider-left"></div>
      <div
        *ngIf="project?.is_location_specific"
        class="location d-flex align-items-center"
        [ngClass]="{
          'location-not-set': !project?.country?.name && !project?.city && !project?.address && !project.statezipcode
        }"
      >
        <app-custom-icon
          id="location"
          [icon_category]="'location'"
          [icon_name]="
            project?.country || project?.city || project.address || project.statezipcode ? 'location' : 'location-cross'
          "
        >
        </app-custom-icon>
        <p class="m-0">
          {{
            project?.address || project?.statezipcode || project?.city || project?.country?.name
              ? ((project.address && !project.statezipcode && project?.city && project?.country?.name) ||
                (project.address && !project.statezipcode && !project?.city && project?.country?.name) ||
                (project.address && !project.statezipcode && project?.city && !project?.country?.name)
                  ? project.address + ', '
                  : project.address
                  ? project.address
                  : '') +
                (project.statezipcode && !project?.city && !project?.country?.name && !project?.address
                  ? project?.statezipcode
                  : (project.statezipcode && project.address && project?.city && project?.country?.name) ||
                    (project.statezipcode && project.address && !project?.city && project?.country?.name) ||
                    (project.statezipcode && project.address && project?.city && !project?.country?.name)
                  ? ', ' + project?.statezipcode + ', '
                  : project.statezipcode && project?.address && !project?.city && !project?.country?.name
                  ? ', ' + project?.statezipcode
                  : (project.statezipcode && !project?.address && project?.city && !project?.country?.name) ||
                    (project.statezipcode && !project?.address && project?.city && project?.country?.name) ||
                    (project.statezipcode && !project?.address && !project?.city && project?.country?.name) ||
                    (project.statezipcode && !project?.address && project?.city && !project?.country?.name)
                  ? project.statezipcode + ', '
                  : '') +
                (project.city && !project?.country?.name
                  ? project.city
                  : project.city && project?.country?.name
                  ? project.city + ', '
                  : '') +
                (project?.country?.name ? project?.country?.name : '')
              : ('project location not set yet' | translate)
          }}
        </p>
      </div>
      <div class="divider-left"></div>
      <div
        class="date-holder d-flex align-items-center"
        [ngClass]="{
          'date-set': project?.estimated_end_date && project?.estimated_start_date,
          'date-not-set': project?.estimated_end_date === null && project?.estimated_start_date === null,
          width70: project?.is_location_specific,
          width100: !project?.is_location_specific,
          completed: project?.status?.name === 'Completed'
        }"
      >
        <div class="start-date d-flex align-items-center">
          <app-custom-icon id="calendar-start" [icon_category]="'time'" [icon_name]="'calendar-circle'">
          </app-custom-icon>
          <p class="m-0">
            {{
              'Start date ' +
                (project?.estimated_start_date ? (project?.estimated_start_date | date : 'dd.MM.yyyy') : 'not set yet')
                | translate
            }}
          </p>
        </div>
        <div class="line"></div>
        <div class="end-date d-flex align-items-center">
          <app-custom-icon id="calendar-end" [icon_category]="'time'" [icon_name]="'calendar-circle'">
          </app-custom-icon>
          <p class="m-0">
            {{
              'End date ' +
                (project?.estimated_end_date ? (project?.estimated_end_date | date : 'dd.MM.yyyy') : 'not set yet')
                | translate
            }}
          </p>
        </div>
      </div>
      <div class="divider-left"></div>
    </div>
  </div>
</mat-card>
