export const columnsToDisplay = [
  { label: 'Step name', property: 'name', translation_key: 'supply-chain-step.Step name' },
  { label: 'Supply Chain Name', property: 'chain', translation_key: 'supply-chain-step.Supply Chain Name' },
  { label: 'Product name', property: 'product', translation_key: 'supply-chain-step.Product name' },
  { label: 'Project name', property: 'project', translation_key: 'supply-chain-step.Project name' },
  { label: 'Batches', property: 'batches', translation_key: 'supply-chain-step.Batches' },
  { label: 'Status', property: 'status', translation_key: 'supply-chain-step.Status' },
  { label: 'Actions', property: 'actions', translation_key: 'supply-chain-step.Actions' },
];

export const innerDisplayedColumns = [
  { label: 'Batch Step Name', property: 'step_name', translation_key: 'supply-chain-step.Batch Step Name' },
  { label: 'Batch Name', property: 'name', translation_key: 'supply-chain-step.Batch Name' },
  { label: 'Status', property: 'status', translation_key: 'supply-chain-step.Status' },
  { label: 'Actions', property: 'actions', translation_key: 'supply-chain-step.Actions' },
];
