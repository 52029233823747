<div class="holder d-flex flex-column">
  <div class="table-holder">
    <table mat-table matSort [dataSource]="dataSource" class="custom-table">
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Name' | translate }}</th>
        <td mat-cell *matCellDef="let element">{{ element.name }}</td>
      </ng-container>

      <ng-container matColumnDef="address">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Address' | translate }}</th>
        <td mat-cell *matCellDef="let element">{{ element.address }}</td>
      </ng-container>

      <ng-container matColumnDef="city">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'City' | translate }}</th>
        <td mat-cell *matCellDef="let element">{{ element.city }}</td>
      </ng-container>

      <ng-container matColumnDef="country">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Country' | translate }}</th>
        <td mat-cell *matCellDef="let element">{{ element.country }}</td>
      </ng-container>

      <ng-container matColumnDef="region">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Region' | translate }}</th>
        <td mat-cell *matCellDef="let element">{{ element.region }}</td>
      </ng-container>

      <ng-container matColumnDef="is_main_location">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Main Site' | translate }}</th>
        <td [ngClass]="element.is_main_location ? 'color-green' : 'color-yellow'" mat-cell *matCellDef="let element">
          {{ element.is_main_location ? 'Yes' : ('No' | translate) }}
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef class="actions-header text-center">
          <div class="actions">{{ 'Actions' | translate }}</div>
        </th>
        <td mat-cell *matCellDef="let element" class="actions-body text-center">
          <div class="icon-holder">
            <app-custom-icon
              matTooltip="{{ 'Edit Site' | translate }}"
              matTooltipClass="custom-tooltip-center"
              matTooltipPosition="above"
              [icon_category]="'files'"
              [icon_name]="'edit'"
              (click)="editSupplierSite(element)"
            ></app-custom-icon>
            <app-custom-icon
              id="trash"
              matTooltip="{{ 'Delete Site' | translate }}"
              matTooltipClass="custom-tooltip-end"
              matTooltipPosition="above"
              [icon_category]="'essential'"
              [icon_name]="'trash'"
              (click)="deleteSupplierSite(element)"
            ></app-custom-icon>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </div>

  <div class="card-footer">
    <mat-paginator showFirstLastButtons aria-label="Select page of contacts"> </mat-paginator>
  </div>
</div>
