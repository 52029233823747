import { Component, OnInit } from '@angular/core';
import { IBrandRegularionsResponse, IRegulationObject } from '@app/core/interface/regulations.interface';
import { NotificationType, infoDialogHeight, infoDialogWidth } from '@app/core/constants';

import { InfoDialogComponent } from '@app/shared/components/info-dialog/info-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { RegulationsFacade } from '@app/core/facade/regulations.facade';
import { SnackbarService } from '@app/core/service/snackbar.service';

@Component({
  selector: 'app-regulations',
  templateUrl: './regulations.component.html',
  styleUrls: ['./regulations.component.scss'],
  providers: [RegulationsFacade],
})
export class RegulationsComponent implements OnInit {
  general_regulations = false;
  regulationsDisplay = [
    {
      name: 'Agec',
      displayName: 'AGEC law regulation',
      info: 'This is where you can create a digital product sheet for your product.',
      is_enabled: false,
    },
  ] as IRegulationObject[];
  regulations: Array<IRegulationObject> = [];

  constructor(
    private _dialog: MatDialog,
    private _snackbarService: SnackbarService,
    private _regulationsFacade: RegulationsFacade
  ) {}
  ngOnInit(): void {
    this.getBrandRegulations();
  }
  getBrandRegulations() {
    this._regulationsFacade.getBrandRegulations$().subscribe({
      next: this._brandRegulationSuccess.bind(this),
      error: this._error.bind(this),
    });
  }
  /**
   *
   * @param reg reg or regulation can be boolean ( if it's general regulations) and objech if is separate regulation
   * @param text in ngFor loop we are passing Please note that you will disable  as text if it's separate regulation
   * for general we don't send the text we are using the text from dialog data
   */
  warningDialog(reg: IRegulationObject | boolean, text?: string): void {
    const dialogRef = this._dialog.open(InfoDialogComponent, {
      width: infoDialogWidth,
      height: infoDialogHeight,
      data: {
        infoText:
          text && typeof reg !== 'boolean'
            ? text + reg.name + '.'
            : `Note that once you disable this feature, everything related to regulations will be disabled.`,
        confirmationText: 'Are you sure you want to disable this feature?',
        btnText: 'Disable Feature',
        type: 'warning',
        text: 'warning-text',
      },
    });
    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        if (typeof reg !== 'boolean') {
          this.enableDisableRegulation(reg);
        } else {
          this.enableDisableGeneralRegulation(false);
          // need API
        }
      } else {
        this.general_regulations = true;
      }
    });
  }
  /**
   * Checks if every regulation is switched off turnns off the Regularion ( as general)
   */
  checkGeneral(): void {
    const isAnyChecked = this.regulations.find(reg => reg.is_enabled);
    this.general_regulations = !!isAnyChecked;
  }

  /**
   * Checks if every regulation is switched off turnns off the Regularion ( as general)
   */
  toggle(event: MouseEvent, reg: IRegulationObject): void {
    event.preventDefault();
    if (reg.is_enabled) {
      this.warningDialog(reg, 'Please note that you will disable ');
    } else {
      // enable agec
      this.enableDisableRegulation(reg);
    }
  }

  enableDisableRegulation(reg: IRegulationObject) {
    this._regulationsFacade.updateBrandRegulationStatus$(!reg.is_enabled, reg.uuid).subscribe({
      next: this.getBrandRegulations.bind(this),
      error: this._error.bind(this),
    });
  }
  enableDisableGeneralRegulation(enabled: boolean) {
    this._regulationsFacade.updateGeneralBrandRegulationStatus$(enabled).subscribe({
      next: this.getBrandRegulations.bind(this),
      error: this._error.bind(this),
    });
  }

  /**
   * checks all regulation like general regulation
   */
  handleGeneralRegulations(): void {
    this.regulations.forEach(reg => {
      this.enableDisableRegulation(reg); //TODO switch with endpoint for all regulations
    });
  }
  private _brandRegulationSuccess(response: IBrandRegularionsResponse) {
    const regulationDetailsMap = new Map(
      this.regulationsDisplay.map(obj => [
        obj.name,
        {
          displayName: obj.displayName,
          info: obj.info,
        },
      ])
    );

    // Merge display names and info into the regulations array
    this.regulations = response.results.map(reg => ({
      ...reg,
      displayName: regulationDetailsMap.get(reg.name)?.displayName || 'Default Display Name', // Fallback to a default if no displayName is found
      info: regulationDetailsMap.get(reg.name)?.info || 'No additional information available', // Fallback for info
    }));
    this.checkGeneral();
  }

  /**
   *
   * @param error Hadles HTTP error desplaying message from backend
   */
  private _error(error: Record<string, string[]>): void {
    Object.values(error).map(err => this._snackbarService.openTypeSnackbar(err[0], NotificationType.error));
  }
}
