import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TasksDashboardComponent } from './tasks-dashboard.component';
import { TasksDashboardRoutingModule } from './tasks-dashboard-routing.module';
import { TasksListComponent } from './tasks-list/tasks-list.component';
import { RecentTasksComponent } from './recent-tasks/recent-tasks.component';
import { TasksOverviewComponent } from './tasks-overview/tasks-overview.component';
import { SharedModule } from '@app/shared/shared.module';
import { TaskCardComponent } from './task-card/task-card.component';
import { TasksFacade } from '@app/core/facade/tasks.facade';
import { CreateTaskDialogComponent } from './create-task-dialog/create-task-dialog.component';
import { TaskDetailsDialogComponent } from './task-details-dialog/task-details-dialog.component';

@NgModule({
  declarations: [
    TasksDashboardComponent,
    TasksListComponent,
    RecentTasksComponent,
    TasksOverviewComponent,
    TaskCardComponent,
    CreateTaskDialogComponent,
    TaskDetailsDialogComponent,
  ],
  imports: [CommonModule, TasksDashboardRoutingModule, SharedModule],
  providers: [TasksFacade],
})
export class TasksDashboardModule {}
