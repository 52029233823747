import { Component, OnDestroy, OnInit } from '@angular/core';
import { PurchaseFacade } from '@app/core/facade/purchase.facade';
import { MainScrollEventService } from '@app/core/service/main-scroll-event.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-purchase',
  templateUrl: './purchase.component.html',
  styleUrls: ['./purchase.component.scss'],
  providers: [PurchaseFacade],
})
export class PurchaseComponent implements OnInit, OnDestroy {
  header_variable = false;
  essential_list: Array<string> = ['Acess to supply chain builder', 'Include basic support'];
  business_list: Array<string> = [
    'Everything in essential',
    'Invite Supplier',
    'Buy licence for the Supplier',
    'Publication with Respect Code',
    'Basic company profile',
    'Integration with Shopify',
  ];
  premium_list: Array<string> = [
    'Everything in business',
    'Premium company profile',
    'API to exhange with external partners',
    'Support xx hours/month',
  ];

  // Unsubscribe cleaner
  private _unsubscribe$: Subject<void> = new Subject();

  constructor(private _purchaseFacade: PurchaseFacade, private _mainScrollEventService: MainScrollEventService) {}

  ngOnInit(): void {
    this.subscribeToScrollEvent();
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }

  /**
   * Function to subscribe to scroll event
   */

  subscribeToScrollEvent() {
    this._mainScrollEventService.mainScrolledActive.pipe(takeUntil(this._unsubscribe$)).subscribe(data => {
      this.header_variable = data > 200;
    });
  }
}
