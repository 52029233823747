<mat-tab-group #tabs>
  <mat-tab>
    <ng-template mat-tab-label>
      {{ 'Supply Chains & Batches' | translate }}
    </ng-template>
    <div class="content-section">
      <app-chains-batches> [pageSize]="12" </app-chains-batches>
    </div>
  </mat-tab>
  <mat-tab [disabled]="true" *ngIf="!environment.qa && !environment.production">
    <ng-template mat-tab-label>
      {{ 'Phase Management' | translate }}
    </ng-template>
    <div class="content-section"></div>
  </mat-tab>
</mat-tab-group>
