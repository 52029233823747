import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@env/environment';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss'],
})
export class LandingComponent {
  environment = environment;
  constructor(private _router: Router) {}

  goToPurchase(): void {
    this._router.navigate([`/purchase`]).then(() => window.location.reload());
  }
}
