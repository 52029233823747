<div class="mat-card-main">
  <div class="vertical-line-preview"></div>
  <mat-card>
    <mat-card-content>
      <mat-tab-group (selectedTabChange)="tabChanged($event)" mat-stretch-tabs="false" mat-align-tabs="start">
        <mat-tab label="Step info">
          <app-step-info [data]="data"></app-step-info>
        </mat-tab>
        <mat-tab label="Supplier Info" bodyClass="custom-mat-tab">
          <div class="container map-panel-brand">
            <div class="row">
              <div class="col">
                <div class="brand-description"><app-supplier-info [data]="SupplierData"></app-supplier-info></div>
              </div>
            </div>
            <div class="row">
              <div class="text-center mt-4 col"></div>
            </div>
          </div>
        </mat-tab>
      </mat-tab-group>
    </mat-card-content>
  </mat-card>
  <app-custom-icon
    mat-dialog-close
    class="close-circle"
    [icon_category]="'essential'"
    [icon_name]="selectedTabIndex === 0 ? 'close-circle' : 'close-circle'"
  ></app-custom-icon>
</div>
