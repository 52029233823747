<div class="d-flex justify-content-center align-items-center">
  <div class="content-holder">
    <div class="logo-holder"></div>

    <div class="text-holder">
      <h3>
        <strong>{{ 'Create your Brand' | translate }}</strong>
      </h3>
      <h6>{{ 'Add information about your brand' | translate }}</h6>
    </div>

    <div class="divider-holder">
      <mat-divider></mat-divider>
    </div>

    <form class="row" [formGroup]="form">
      <div class="col-12 col-md-4">
        <app-file-upload
          [placeholder]="'Logo' | translate"
          [iconType]="'add_photo_alternate'"
          (fileData)="saveImageData($event)"
          [uploadImageText]="'Upload your logo'"
        >
        </app-file-upload>
      </div>
      <div class="col-12 col-md-8 row p-0">
        <mat-form-field class="col-12 input-primary" appearance="outline">
          <mat-label>{{ 'Brand Name' | translate }}</mat-label>
          <input matInput formControlName="name" placeholder="{{ 'Brand Name' | translate }}" />
        </mat-form-field>
        <div class="col-12 input-primary" appearance="outline">
          <mat-label>{{ 'Traceability Commitment' | translate }}</mat-label>
          <!-- <input matInput formControlName="description" placeholder="{{ 'Description' | translate }}" /> -->
          <div class="wrp">
            <app-rs-rte
              formControlName="traceability_commitment"
              [c]="$any(form.controls['traceability_commitment'])"
              [placeholder]="'Traceability Commitment' | translate"
            ></app-rs-rte>
          </div>
        </div>
        <div class="col-12 input-primary" appearance="outline">
          <mat-label>{{ 'Description' | translate }}</mat-label>
          <!-- <input matInput formControlName="summary" placeholder="{{ 'Summary' | translate }}" /> -->
          <div class="wrp">
            <app-rs-rte
              formControlName="description"
              [c]="$any(form.controls['description'])"
              [placeholder]="'Description' | translate"
            ></app-rs-rte>
          </div>
        </div>
        <!-- <mat-form-field class="col-6 dropdown-primary" appearance="outline">
          <mat-select
            #languagesSelect
            formControlName="languages"
            multiple
            panelClass="dropdown-above-left"
            placeholder="{{ 'Language' | translate }}"
            (selectionChange)="languagesSelect.close()"
          >
            <mat-option *ngFor="let language of languages" [value]="language.uuid" placeholder="Language">
              {{ language.name }}
            </mat-option>
          </mat-select>
          <app-custom-icon matSuffix [icon_category]="'arrows'" [icon_name]="'arrow-down-2'"></app-custom-icon>
        </mat-form-field> -->
        <mat-form-field class="col-12 dropdown-primary mat-form-field-custom-spacing" appearance="outline">
          <mat-select
            formControlName="domain_activity"
            panelClass="dropdown-above-right"
            placeholder="{{ 'Industry' | translate }}"
          >
            <mat-option *ngFor="let domain of domains" [value]="domain.uuid" placeholder="Industry">
              {{ domain.name }}
            </mat-option>
          </mat-select>
          <app-custom-icon matSuffix [icon_category]="'arrows'" [icon_name]="'arrow-down-2'"></app-custom-icon>
        </mat-form-field>
      </div>

      <div class="buttons-row d-flex">
        <app-rounded-button
          color="primary"
          button_text="{{ 'Create brand' | translate }}"
          [icon_category]="'arrows'"
          [icon_name]="'arrow-right'"
          [icon_right]="true"
          [disabled]="form.invalid || form.pristine"
          (click)="createBrand()"
        >
        </app-rounded-button>
      </div>
    </form>
  </div>
</div>
