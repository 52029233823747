import { IAddressListResponse, ICreateAddressResponse, IDeleteAddress } from '@app/core/interface/address.interface';

import { AddressService } from '@service/address.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { ObservableInput } from 'rxjs/internal/types';
import { catchError } from 'rxjs/internal/operators/catchError';
import { generateFormData } from '../utils/generate-formData';
import { take } from 'rxjs/internal/operators/take';
import { throwError } from 'rxjs/internal/observable/throwError';

@Injectable()
export class AddressFacade {
  constructor(private _addressService: AddressService) {}

  getAddresses$(): Observable<IAddressListResponse> {
    return this._addressService.getAddresses$().pipe(
      take(1),
      catchError(({ error }: IAddressListResponse): ObservableInput<IAddressListResponse> => throwError(() => error))
    );
  }

  getAddress$(uuid: string): Observable<ICreateAddressResponse> {
    return this._addressService.getAddress$(uuid).pipe(
      take(1),
      catchError(
        ({ error }: ICreateAddressResponse): ObservableInput<ICreateAddressResponse> => throwError(() => error)
      )
    );
  }

  createAddress$(data: Partial<ICreateAddressResponse>): Observable<ICreateAddressResponse> {
    const formData = generateFormData(data);

    return this._addressService.createAddress$(formData).pipe(
      take(1),
      catchError(
        ({ error }: ICreateAddressResponse): ObservableInput<ICreateAddressResponse> => throwError(() => error)
      )
    );
  }

  updateAddress$(data: Partial<ICreateAddressResponse>, uuid: string): Observable<ICreateAddressResponse> {
    const formData = generateFormData(data);

    return this._addressService.updateAddress$(formData, uuid).pipe(
      take(1),
      catchError(
        ({ error }: ICreateAddressResponse): ObservableInput<ICreateAddressResponse> => throwError(() => error)
      )
    );
  }

  deleteAddress$(uuid: string): Observable<IDeleteAddress> {
    return this._addressService.deleteAddress$(uuid).pipe(
      take(1),
      catchError(({ error }: IDeleteAddress): ObservableInput<IDeleteAddress> => throwError(() => error))
    );
  }
}
