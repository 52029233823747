import { Injectable } from '@angular/core';
import { Observable, take, catchError, ObservableInput, throwError } from 'rxjs';
import { generateFormData } from '../utils/generate-formData';
import { RegulationsService } from '../service/regulations.service';
import {
  AgecPackagingType,
  IAgecPackaging,
  IAgecRegulation,
  IAgecRegulationResponse,
  IAgecTracebility,
  IBrandRegularionsResponse,
  PackageRecyclabitity,
  SheetCategory,
  TracebilityStepOne,
  TracebilityStepThree,
  TracebilityStepTwo,
} from '../interface/regulations.interface';

@Injectable()
export class RegulationsFacade {
  constructor(private _regulationsService: RegulationsService) {}
  recycleTypeMsg: { [key: string]: string } = {
    'Mostly recyclable': 'Mostly recyclable',
    'Fully recyclable': 'Fully recyclable',
    'Same nature': 'Packaging recyclable into packaging of the same nature',
    'Not recyclable': 'Not recyclable',
  };
  getBrandRegulations$(): Observable<IBrandRegularionsResponse> {
    return this._regulationsService.getBrandRegulations$().pipe(
      take(1),
      catchError((err): ObservableInput<IBrandRegularionsResponse> => throwError(() => err.error))
    );
  }

  updateBrandRegulationStatus$(is_active: boolean, uuid: string): Observable<IBrandRegularionsResponse> {
    return this._regulationsService.updateBrandRegulationStatus$(uuid, is_active).pipe(
      take(1),
      catchError(
        ({ error }: IBrandRegularionsResponse): ObservableInput<IBrandRegularionsResponse> => throwError(() => error)
      )
    );
  }
  updateGeneralBrandRegulationStatus$(enabled: boolean): Observable<IBrandRegularionsResponse> {
    const formData = generateFormData({ are_regulations_enabled: enabled });
    return this._regulationsService.updateGeneralBrandRegulationStatus$(formData).pipe(
      take(1),
      catchError(
        ({ error }: IBrandRegularionsResponse): ObservableInput<IBrandRegularionsResponse> => throwError(() => error)
      )
    );
  }

  getDigitalSheets$(regulation: string): Observable<IAgecRegulationResponse> {
    return this._regulationsService.getDigitalSheets$(regulation).pipe(
      take(1),
      catchError((err): ObservableInput<IAgecRegulationResponse> => throwError(() => err.error))
    );
  }

  getDigitalSheet$(regulation: string, uuid: string): Observable<IAgecRegulation> {
    return this._regulationsService.getDigitalSheet$(regulation, uuid).pipe(
      take(1),
      catchError((err): ObservableInput<IAgecRegulation> => throwError(() => err.error))
    );
  }

  createDigitalSheet$(data: Partial<IAgecRegulation>, regulation: string): Observable<IAgecRegulation> {
    const formData = generateFormData(data);
    return this._regulationsService.createDigitalSheet$(formData, regulation).pipe(
      take(1),
      catchError(({ error }: IAgecRegulation): ObservableInput<IAgecRegulation> => throwError(() => error))
    );
  }

  updateDigitalSheet$(
    data: Partial<IAgecRegulation>,
    uuid: string,
    regulation: string
  ): Observable<IBrandRegularionsResponse> {
    const formData = generateFormData(data);
    return this._regulationsService.updateDigitalSheet$(formData, uuid, regulation).pipe(
      take(1),
      catchError(
        ({ error }: IBrandRegularionsResponse): ObservableInput<IBrandRegularionsResponse> => throwError(() => error)
      )
    );
  }
  createTracebilityStep$(data: Partial<IAgecTracebility>, regulation: string): Observable<IAgecTracebility> {
    const formData = generateFormData(data);
    return this._regulationsService.createTracebilityStep$(formData, regulation).pipe(
      take(1),
      catchError(({ error }: IAgecTracebility): ObservableInput<IAgecTracebility> => throwError(() => error))
    );
  }
  updateTracebilityStep$(
    data: Partial<IAgecTracebility>,
    uuid: string,
    regulation: string
  ): Observable<IAgecTracebility> {
    const formData = generateFormData(data);
    return this._regulationsService.updateTracebilityStep$(formData, uuid, regulation).pipe(
      take(1),
      catchError(({ error }: IAgecTracebility): ObservableInput<IAgecTracebility> => throwError(() => error))
    );
  }
  createPackaging$(data: Partial<IAgecPackaging>, regulation: string): Observable<IAgecPackaging> {
    const formData = generateFormData(data);
    return this._regulationsService.createPackaging$(formData, regulation).pipe(
      take(1),
      catchError(({ error }: IAgecPackaging): ObservableInput<IAgecPackaging> => throwError(() => error))
    );
  }
  updatePackaging$(data: Partial<IAgecPackaging>, uuid: string, regulation: string): Observable<IAgecPackaging> {
    const formData = generateFormData(data);
    return this._regulationsService.updatePackaging$(formData, uuid, regulation).pipe(
      take(1),
      catchError(({ error }: IAgecPackaging): ObservableInput<IAgecPackaging> => throwError(() => error))
    );
  }
  deletePackaging$(uuid: string, regulation: string): Observable<IAgecPackaging> {
    return this._regulationsService.deletePackaging$(uuid, regulation).pipe(
      take(1),
      catchError(({ error }: IAgecPackaging): ObservableInput<IAgecPackaging> => throwError(() => error))
    );
  }
  isKeyOfSheetCategory(key: string): key is keyof typeof SheetCategory {
    return key in SheetCategory;
  }
  isKeyOfFirstStep(key: string): key is keyof typeof TracebilityStepOne {
    return key in TracebilityStepOne;
  }
  isKeyOfSecondStep(key: string): key is keyof typeof TracebilityStepTwo {
    return key in TracebilityStepTwo;
  }
  isKeyOfThirdStep(key: string): key is keyof typeof TracebilityStepThree {
    return key in TracebilityStepThree;
  }
  isKeyOfPackageRecyclabitity(key: string): key is keyof typeof PackageRecyclabitity {
    return key in PackageRecyclabitity;
  }
  isKeyOfAgecPackagingType(key: string): key is keyof typeof AgecPackagingType {
    return key in AgecPackagingType;
  }
}
