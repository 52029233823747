import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LandingComponent } from './landing.component';
import { LandingRoutingModule } from './landing-routing.module';
import { AngularMaterialModule } from '@app/material.module';
import { SharedModule } from '@app/shared/shared.module';

@NgModule({
  declarations: [LandingComponent],
  imports: [CommonModule, LandingRoutingModule, AngularMaterialModule, SharedModule],
})
export class LandingModule {}
