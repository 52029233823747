<div class="header">
  <div class="header-actions" [ngClass]="{ 'chain-complete-header': isChainCompleted }">
    <app-rounded-button
      color="secondary-outline"
      [button_text]="isChainCompleted ? 'Close Step' : 'Update Step'"
      [size]="'sm'"
      [disabled]="form && !form.valid"
      (click)="isChainCompleted ? closeDialog.emit(true) : updateStep()"
    >
    </app-rounded-button>

    <app-rounded-button
      *ngIf="!isChainCompleted"
      class="cancel-btn"
      color="tetriary-link"
      [button_text]="'Cancel'"
      [size]="'sm'"
      (click)="closeDialog.emit(true)"
    >
    </app-rounded-button>
  </div>
  <div class="header-container">
    <h2 *ngIf="!isChainCompleted">{{ 'Manage Step:' | translate }}</h2>
    <p *ngIf="!isChainCompleted">{{ stepData?.name }}</p>
  </div>
</div>
<div class="custom-container" *ngIf="stepData">
  <div class="sidenav-content">
    <div class="col-12 update-supplier-wraper">
      <form [formGroup]="form">
        <mat-tab-group>
          <!-- MAIN INFO TAB -->
          <mat-tab>
            <ng-template mat-tab-label>
              <app-custom-icon [icon_category]="'essential'" [icon_name]="'info-circle'"></app-custom-icon>
              {{ 'Main info' | translate }}
            </ng-template>
            <div class="pt-3 ps-0" *ngIf="!isChainCompleted">
              <div class="d-flex align-items-center gap-2 supplier-status">
                <p class="p2-strong mb-0">{{ 'Status at supplier' | translate }}</p>
                <div [ngSwitch]="stepData?.supplier_status">
                  <app-custom-icon
                    *ngSwitchCase="'Updated'"
                    [icon_category]="'essential'"
                    [icon_name]="'tick-circle-green'"
                  ></app-custom-icon>
                  <app-custom-icon
                    *ngSwitchCase="'To update'"
                    [icon_category]="'essential'"
                    [icon_name]="'more-circle-red'"
                  ></app-custom-icon>
                  <app-custom-icon
                    *ngSwitchCase="'Not applicable'"
                    [icon_category]="'essential'"
                    [icon_name]="'circle-slash'"
                  ></app-custom-icon>
                  <div *ngSwitchDefault></div>
                </div>

                <p class="p2-normal mb-0">{{ stepData?.supplier_status }}</p>
              </div>
            </div>

            <div class="horizontal-line" *ngIf="!isChainCompleted"></div>
            <!-- General info  -->
            <div class="label {{ isChainCompleted ? 'pt-3' : '' }}">{{ 'General info' | translate }}</div>
            <p class="p1-normal mb-0">{{ 'Step Name:' | translate }}</p>
            <p class="p1-strong">{{ stepData?.name }}</p>
            <!-- Step Descrition  -->
            <div class="col-12">
              <div class="input-primary" appearance="outline" *ngIf="!isChainCompleted; else readOnlyDescription">
                <mat-label>{{ 'Step Description' | translate }}</mat-label>
                <!-- <input matInput formControlName="description" placeholder="Step Description" /> -->
                <div class="wrp">
                  <app-rs-rte
                    formControlName="description"
                    [c]="$any(form.controls['description'])"
                    [placeholder]="'Description' | translate"
                  ></app-rs-rte>
                </div>
              </div>
              <ng-template #readOnlyDescription>
                <p class="p1-normal mb-0" *ngIf="stepData.description">{{ 'Step Description:' | translate }}</p>
                <p class="p1-strong">
                  <app-view-rich-text
                    *ngIf="stepData.description"
                    [content]="stepData?.description || ''"
                  ></app-view-rich-text>
                </p>
              </ng-template>
            </div>

            <div class="horizontal-line"></div>
            <!-- Supplier info  -->
            <div class="label">{{ 'Supplier info' | translate }}</div>

            <div class="row" *ngIf="!isChainCompleted">
              <div class="col-12 invite-supplier mb-2">
                <p>{{ 'Need help with this step?' | translate }}</p>
                <app-rounded-button
                  color="secondary"
                  [button_text]="'Invite Supplier'"
                  [icon_category]="'product'"
                  [icon_name]="'truck'"
                  [size]="'sm'"
                  [icon_right]="true"
                  (click)="openEmailDialog()"
                >
                </app-rounded-button>
              </div>
            </div>
            <div *ngIf="!isChainCompleted; else supplierInfo">
              <div class="row">
                <div class="label">{{ 'Supplier' | translate }}</div>
                <div class="col-12">
                  <mat-form-field appearance="outline" class="input-primary">
                    <mat-label>{{ 'Supplier' | translate }}</mat-label>
                    <mat-select [value]="'STEP_SUPPLIER'" readonly>
                      <mat-option [value]="'STEP_SUPPLIER'">{{ stepData.supplier?.name }} </mat-option>
                    </mat-select>
                    <!-- TEMPORALLY REMOVED AUTOCOMPLETE FIELD -->
                    <!-- <input
                      type="text"
                      matInput
                      formControlName="supplier"
                      [matAutocomplete]="auto"
                      [matAutocompletePosition]="'above'"
                      readonly
                    />
                    <mat-autocomplete
                      #auto="matAutocomplete"
                      [displayWith]="displayFn"
                      (optionSelected)="onSupplierChange($event.option.value.uuid)"
                    >
                      <mat-option *ngFor="let supplier of filteredSuppliers | async" [value]="supplier"
                        >{{ supplier.name }}
                      </mat-option>
                    </mat-autocomplete> -->
                  </mat-form-field>
                </div>
              </div>

              <div class="horizontal-line m-16"></div>

              <!-- Supplier site -->
              <div class="row">
                <div class="label">{{ 'Supplier Site' | translate }}</div>
                <div class="col-12">
                  <mat-form-field appearance="outline" class="input-primary">
                    <mat-label>{{ 'Supplier Site' | translate }}</mat-label>
                    <mat-select formControlName="manufactories" (selectionChange)="onSupplierSiteChange()">
                      <mat-option
                        *ngFor="let factory of manufactories"
                        [value]="factory?.uuid"
                        placeholder="Supplier Site"
                      >
                        {{ factory?.name }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
            </div>
            <ng-template #supplierInfo>
              <div class="row">
                <div class="col-12">
                  <p class="p1-normal mb-0">{{ 'Supplier Name:' | translate }}</p>
                  <p class="p1-strong">{{ stepData?.supplier?.name }}</p>
                  <p class="p1-normal mb-0">{{ 'Supplier Site:' | translate }}</p>
                  <p class="p1-strong">{{ stepData?.manufactories?.name }}</p>
                </div>
              </div>
            </ng-template>
          </mat-tab>
          <!-- Quantity and Proofs TAB-->
          <mat-tab>
            <ng-template mat-tab-label>
              <app-custom-icon [icon_category]="'product'" [icon_name]="'box-tick'"></app-custom-icon>
              {{ 'Quantity and Proofs' | translate }}
            </ng-template>
            <div class="pt-3 ps-0" *ngIf="!isChainCompleted">
              <div class="d-flex align-items-center gap-2 supplier-status">
                <p class="p2-strong mb-0">{{ 'Status at supplier' | translate }}</p>
                <div [ngSwitch]="stepData.supplier_status">
                  <app-custom-icon
                    *ngSwitchCase="'Updated'"
                    [icon_category]="'essential'"
                    [icon_name]="'tick-circle-green'"
                  ></app-custom-icon>
                  <app-custom-icon
                    *ngSwitchCase="'To update'"
                    [icon_category]="'essential'"
                    [icon_name]="'more-circle-red'"
                  ></app-custom-icon>
                  <app-custom-icon
                    *ngSwitchCase="'Not applicable'"
                    [icon_category]="'essential'"
                    [icon_name]="'circle-slash'"
                  ></app-custom-icon>
                  <div *ngSwitchDefault></div>
                </div>
                <p class="p2-normal mb-0">{{ stepData.supplier_status }}</p>
              </div>
            </div>
            <div class="horizontal-line" *ngIf="!isChainCompleted"></div>
            <div *ngIf="stepData?.supplier_disapproval_reason" class="col-12 reason-msg font-14 mt-2 mb-2">
              <div class="label-2 mb-2 d-flex">
                <app-custom-icon [icon_category]="'essential'" [icon_name]="'danger-yellow'" class="cursor-pointer">
                </app-custom-icon>
                <p class="font-14 neutral mb-0 me-2 ms-2 align-self-center">
                  {{ "This step's supplier has disapproved the delivered quantity & proofs information" | translate }}
                </p>
              </div>
              <div class="text-container">
                <div class="short-text" *ngIf="!showReadMore">
                  <p class="p2-strong mb-0">{{ 'Reason of supplier disapproval:' }}</p>
                  {{ stepData.supplier_disapproval_reason | slice: 0 : 30
                  }}{{ stepData.supplier_disapproval_reason!.length > 30 ? '...' : '' }}
                </div>
                <div class="long-text" *ngIf="showReadMore">
                  <p class="p2-strong mb-0">{{ 'Reason of supplier disapproval:' }}</p>
                  {{ stepData.supplier_disapproval_reason }}
                </div>
                <a class="align-self-center" (click)="toggleText()"
                  >{{ showReadMore ? 'Show less' : 'Read more' }}
                  <div class="mat-select-arrow-wrapper" style="height: 12px">
                    <div class="mat-select-arrow"></div>
                  </div>
                </a>
              </div>
            </div>
            <!-- Delivered Quantity and Proofs  -->
            <div class="label" [ngClass]="{ 'pt-3': isChainCompleted }">
              {{ 'Delivered Quantity and Proofs' | translate }}
            </div>

            <div *ngIf="!isChainCompleted; else readonlyQuantityProofs">
              <!-- Quantity  -->
              <div class="d-flex">
                <p class="p2-normal mb-0 pe-2">{{ 'Quantity' | translate }}</p>
                <app-custom-icon
                  matTooltip="{{
                    'In this section, please specify the total quantity delivered by this step\'s supplier for this batch'
                      | translate
                  }}"
                  matTooltipClass="custom-tooltip-end"
                  matTooltipPosition="above"
                  class="cursor-pointer"
                  [icon_category]="'essential'"
                  [icon_name]="'info-circle'"
                ></app-custom-icon>
              </div>
              <div class="col-12">
                <mat-form-field class="input-primary" appearance="outline">
                  <mat-label>{{ 'Please enter quantity*' | translate }}</mat-label>
                  <input matInput formControlName="quantity" placeholder="Please enter quantity" />
                </mat-form-field>
              </div>
              <!-- Unit -->
              <p class="p2-normal mb-0">{{ 'Unit' | translate }}</p>
              <div class="col-12">
                <mat-form-field class="input-primary" appearance="outline">
                  <mat-label>{{ 'Choose unit*' | translate }}</mat-label>
                  <mat-select formControlName="quantity_unit">
                    <mat-option *ngFor="let unit of batchQuantityUnits" [value]="unit.uuid" placeholder="Choose unit*">
                      {{ unit.symbol }}
                      ({{ unit.name }})
                    </mat-option>
                    <p class="p1-normal ps-3" *ngIf="batchQuantityUnits.length === 0">
                      {{ 'There are no active units at the moment' | translate }}
                    </p>
                    <p class="note-text p1-strong">
                      {{ 'Please note that you can manage the units in the settings in units management.' | translate }}
                    </p>
                  </mat-select>
                </mat-form-field>
              </div>

              <div class="horizontal-line"></div>

              <!-- References  -->
              <div class="d-flex">
                <p class="p2-normal mb-0 pe-2">{{ 'References' | translate }}</p>
                <app-custom-icon
                  matTooltip="{{
                    'Please specify here all the numbers or references that this step\'s supplier has used as key to communicate with his client about the total quantity he has delivered to him for this batch. These references can be a delivery note number, a lot number or an order number.'
                      | translate
                  }}"
                  matTooltipClass="custom-tooltip-end"
                  matTooltipPosition="above"
                  class="cursor-pointer"
                  [icon_category]="'essential'"
                  [icon_name]="'info-circle'"
                ></app-custom-icon>
              </div>

              <div class="col-12">
                <mat-form-field class="input-primary" appearance="outline">
                  <mat-label>{{ 'Please enter references*' | translate }}</mat-label>
                  <input matInput formControlName="reference" placeholder="Please enter references*" />
                </mat-form-field>
              </div>
            </div>
            <ng-template #readonlyQuantityProofs>
              <div class="col-12">
                <p class="p1-normal mb-0">{{ 'Quantity:' | translate }}</p>
                <p class="p1-strong" *ngIf="stepData?.quantity; else notEntered">{{ stepData?.quantity }}</p>
              </div>
              <div class="col-12">
                <p class="p1-normal mb-0">{{ 'Unit:' | translate }}</p>
                <p class="p1-strong" *ngIf="stepData?.quantity_unit?.symbol; else notEntered">
                  {{ stepData?.quantity_unit?.symbol + ' (' + stepData?.quantity_unit?.name + ')' }}
                </p>
              </div>
              <div class="horizontal-line"></div>
              <div class="col-12">
                <p class="p1-normal mb-0">{{ 'References:' | translate }}</p>
                <p class="p1-strong" *ngIf="stepData?.reference; else notEntered">
                  {{ stepData?.reference }}
                </p>
              </div>
              <ng-template #notEntered>
                <p class="p-light">{{ 'not entered yet' | translate }}</p>
              </ng-template>
            </ng-template>
            <div class="horizontal-line"></div>
            <div class="d-flex justify-content-between">
              <div class="d-flex">
                <p class="p2-normal mb-0 pe-2">{{ 'Proofs' | translate }} {{ isChainCompleted ? ':' : '*' }}</p>
                <app-custom-icon
                  *ngIf="!isChainCompleted"
                  matTooltip="{{
                    'Please upload here all the documents that prove the total quantity delivered by this step\'s supplier to his client for this batch. These documents can be delivery notes, order numbers...'
                      | translate
                  }}"
                  matTooltipClass="custom-tooltip-end"
                  matTooltipPosition="above"
                  class="cursor-pointer"
                  [icon_category]="'essential'"
                  [icon_name]="'info-circle'"
                ></app-custom-icon>
              </div>
              <div *ngIf="!isChainCompleted">
                <input type="file" class="file-upload d-none" #fileUpload (change)="onFileSelected($event)" />
                <div class="d-flex" #fileUpload (change)="onFileSelected($event)" (click)="fileUpload.click()">
                  <div class="label-2 pe-2 cursor-pointer">{{ 'upload document' | translate }}</div>
                  <app-custom-icon
                    class="cursor-pointer"
                    [icon_category]="'essential'"
                    [icon_name]="'upload'"
                  ></app-custom-icon>
                </div>
              </div>
            </div>
            <div *ngIf="this.proofs?.length === 0" class="no-documents-uploaded d-flex align-items-center">
              <app-custom-icon [icon_category]="'essential'" [icon_name]="'danger'"></app-custom-icon>
              <p class="p2-normal mb-0">{{ 'There are no proof documents uploaded yet' }}</p>
            </div>
            <!--  Proofs container  -->

            <div
              *ngIf="this.proofs !== null && this.proofs?.length !== 0"
              class="proofs-container row align-items-center"
            >
              <div
                *ngFor="let proof of this.proofs | slice: 0 : 2"
                class="d-flex"
                [ngClass]="this.proofs?.length === 1 ? 'col-12' : this.proofs?.length! >= 3 ? 'col-5' : 'col-6'"
              >
                <p class="proof-name mb-0 me-1">{{ proof.name }}</p>
                <div class="d-flex" *ngIf="!isChainCompleted; else viewProof">
                  <app-custom-icon
                    [icon_category]="'settings'"
                    [icon_name]="'more'"
                    [matMenuTriggerFor]="menu"
                    matTooltip="View All Actions"
                    matTooltipClass="custom-tooltip-center"
                    [matTooltipPosition]="'above'"
                    class="pt-1"
                  ></app-custom-icon>
                  <mat-menu #menu="matMenu" class="actions-holder">
                    <button mat-menu-item (click)="openDocument(proof)">
                      <app-custom-icon [icon_category]="'security'" [icon_name]="'eye'"> </app-custom-icon>
                      <span class="p1-normal">{{ 'Open document' | translate }}</span>
                    </button>
                    <button mat-menu-item (click)="editDocument(proof)">
                      <app-custom-icon [icon_category]="'files'" [icon_name]="'edit'"> </app-custom-icon>
                      <span class="p1-normal">{{ 'Edit' | translate }}</span>
                    </button>
                    <button mat-menu-item (click)="removeProofDialog(proof)">
                      <app-custom-icon
                        id="trash"
                        [icon_category]="'essential'"
                        [icon_name]="'trash-red'"
                        class="trash-red"
                      >
                      </app-custom-icon>
                      <span class="p1-normal" class="color-red">{{ 'Remove' | translate }}</span>
                    </button>
                  </mat-menu>
                </div>
                <ng-template #viewProof>
                  <app-custom-icon
                    class="cursor-pointer"
                    [icon_category]="'security'"
                    [icon_name]="'eye'"
                    (click)="openDocument(proof)"
                  ></app-custom-icon>
                </ng-template>
              </div>

              <div *ngIf="this.proofs !== null && this.proofs?.length! >= 3" class="col-2">
                <app-custom-icon
                  [icon_category]="'settings'"
                  [icon_name]="'more-2'"
                  [matMenuTriggerFor]="allDocuments"
                  matTooltip="More"
                  matTooltipClass="custom-tooltip-center"
                  [matTooltipPosition]="'above'"
                ></app-custom-icon>
                <mat-menu #allDocuments="matMenu">
                  <div *ngIf="this.proofs !== null && this.proofs!.length >= 3">
                    <div
                      *ngFor="let proof of this.proofs | slice: 2"
                      class="d-flex proofs-container row"
                      (click)="$event.stopPropagation()"
                    >
                      <p class="proof-name mb-0 me-1 ms-2 col-8">{{ proof.name }}</p>
                      <app-custom-icon
                        [icon_category]="'settings'"
                        [icon_name]="'more'"
                        [matMenuTriggerFor]="menu2"
                        matTooltip="View All Actions"
                        matTooltipClass="custom-tooltip-center"
                        [matTooltipPosition]="'above'"
                        class="col-2"
                      ></app-custom-icon>
                      <mat-menu #menu2="matMenu">
                        <button mat-menu-item (click)="openDocument(proof)">
                          <app-custom-icon [icon_category]="'security'" [icon_name]="'eye'"> </app-custom-icon>
                          <span class="p1-normal">{{ 'Open document' | translate }}</span>
                        </button>
                        <button mat-menu-item (click)="editDocument(proof)">
                          <app-custom-icon [icon_category]="'files'" [icon_name]="'edit'"> </app-custom-icon>
                          <span class="p1-normal">{{ 'Edit' | translate }}</span>
                        </button>
                        <button mat-menu-item (click)="removeProofDialog(proof)">
                          <app-custom-icon [icon_category]="'essential'" [icon_name]="'trash-red'"> </app-custom-icon>
                          <span class="p1-normal" style="color: #e20613">{{ 'Remove' | translate }}</span>
                        </button>
                      </mat-menu>
                    </div>
                  </div>
                </mat-menu>
              </div>
            </div>
          </mat-tab>
          <!-- STEP DOCUMENTS TAB -->
          <mat-tab>
            <ng-template mat-tab-label>
              <app-custom-icon [icon_category]="'finance'" [icon_name]="'document'"></app-custom-icon>
              {{ 'Step Documents' | translate }}
            </ng-template>
            <div class="pt-3 ps-0" *ngIf="!isChainCompleted">
              <div class="d-flex align-items-center gap-2 supplier-status">
                <p class="p2-strong mb-0">{{ 'Status at supplier' | translate }}</p>
                <div [ngSwitch]="stepData.supplier_status">
                  <app-custom-icon
                    *ngSwitchCase="'Updated'"
                    [icon_category]="'essential'"
                    [icon_name]="'tick-circle-green'"
                  ></app-custom-icon>
                  <app-custom-icon
                    *ngSwitchCase="'To update'"
                    [icon_category]="'essential'"
                    [icon_name]="'more-circle-red'"
                  ></app-custom-icon>
                  <app-custom-icon
                    *ngSwitchCase="'Not applicable'"
                    [icon_category]="'essential'"
                    [icon_name]="'circle-slash'"
                  ></app-custom-icon>
                  <div *ngSwitchDefault></div>
                </div>
                <p class="p2-normal mb-0">{{ stepData.supplier_status }}</p>
              </div>
            </div>

            <div class="horizontal-line" *ngIf="!isChainCompleted"></div>
            <div class="label mb-0">{{ 'Steps documents' | translate }}</div>

            <div class="col-12">
              <app-step-media-accordion
                *ngIf="stepData"
                [canUpload]="!isChainCompleted"
                [photos]="photos"
                [videos]="videos"
                [documents]="stepData.documents"
                [type]="'batchsteps'"
                [typeUuid]="stepData?.uuid"
                [canDelete]="!isChainCompleted"
                (refreshStep)="getStep(stepData.uuid)"
              ></app-step-media-accordion>
            </div>

            <div class="row">
              <div class="col-12">
                <app-ingredient-details-form
                  #ingredientDetailsForm
                  *ngIf="form.get('ingredient')?.value"
                  [componentUuid]="form.get('ingredient')?.value"
                ></app-ingredient-details-form>
              </div>
            </div>
          </mat-tab>
          <mat-tab [labelClass]="'data-secured-tab'">
            <ng-template mat-tab-label>
              <app-data-secured-badge
                [routerLink]="'/terms-and-conditions'"
                [isColumnDirection]="true"
              ></app-data-secured-badge>
            </ng-template>
          </mat-tab>
        </mat-tab-group>
      </form>
    </div>
  </div>
</div>
