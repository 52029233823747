<div class="product-regul-wrap col-12">
  <h6 class="headd col-12">
    {{ 'All regulations' | translate }}
  </h6>
  <hr class="col-12" />
  <div class="cont">
    <!-- <mat-tab-group (selectedIndexChange)="onTabChange()" [(selectedIndex)]="selectedIndex" class="minHeightTabb"> -->
    <mat-tab-group [(selectedIndex)]="selectedIndex" class="minHeightTabb">
      <!-- Regulation tabs -->
      <mat-tab
        [label]="'AGEC Law regulation' | translate"
        *ngIf="product && product.regulations && product.regulations[0]?.are_regulation_enabled"
      >
        <ng-template matTabContent>
          <app-product-agec-regulation
            [product]="product"
            [brand_regulation]="brand_regulation"
            (refresh)="$event && refresh.emit($event)"
          ></app-product-agec-regulation>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
