<div class="wrapper d-flex flex-column">
  <h3 mat-dialog-title>{{ 'complete-direct-supplier.Complete Direct Supplier Information' | translate }}</h3>

  <div class="content">
    <form [formGroup]="form">
      <div class="direc-supp-info d-flex">
        <div class="name-wrapper d-flex flex-column">
          <p class="m-0 light">{{ 'complete-direct-supplier.Direct supplier name:' | translate }}</p>
          <p class="m-0 bold">{{ directSupplier.supplier }}</p>
        </div>
        <div class="name-wrapper d-flex flex-column">
          <p class="m-0 light">{{ 'complete-direct-supplier.Direct supplier role in the chain:' | translate }}</p>
          <p class="m-0 bold">{{ directSupplier.name }}</p>
        </div>
      </div>
      <div class="info-wrapp d-flex align-items-center">
        <img class="logo" src="assets/logos/symbol-logo-dark.svg" />
        <p class="m-0">
          {{
            "complete-direct-supplier.Please specify here the total quantity you've received from this direct supplier that made you complete your step for this batch. Please note again that this batch represents for you all the deliveries that you made to your client as mentioned in the section 'Data that concerns all the deliveries you've made to your client for this batch'."
              | translate
          }}
        </p>
      </div>
      <div class="input-wrapp d-flex">
        <div class="field-wrapper p-right">
          <p>{{ 'complete-direct-supplier.ReceivedQuantity' | translate }}*</p>
          <mat-form-field class="input-primary" appearance="outline">
            <mat-label>{{ 'complete-direct-supplier.Enter quantity here' | translate }}</mat-label>
            <input matInput formControlName="quantity" />
          </mat-form-field>
        </div>
        <div class="field-wrapper p-left">
          <p>{{ 'complete-direct-supplier.unit' | translate }}</p>
          <mat-form-field class="input-primary" appearance="outline">
            <mat-select
              formControlName="quantity_unit"
              panelClass="dropdown-primary"
              [placeholder]="'complete-direct-supplier.Choose unit' | translate"
            >
              <mat-option *ngFor="let unit of unitList" [value]="unit.uuid">
                {{ unit.symbol }} {{ unit.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="field-wrapper p-right d-flex flex-column">
          <div class="field-title d-flex align-items-center">
            <span>{{ 'complete-direct-supplier.References' | translate }}</span>
            <app-custom-icon
              icon_category="essential"
              icon_name="info-circle"
              [matTooltip]="
                'supply-chain-step.Please specify here all the numbers or references that you use as key to communicate with this supplier about these deliveries. These references can be a delivery note number, a lot number or an order number.'
                  | translate
              "
              matTooltipPosition="above"
              matTooltipClass="custom-tooltip-center"
              class="cursor-pointer"
            >
            </app-custom-icon>
          </div>
          <mat-form-field class="input-primary" appearance="outline">
            <mat-label>{{ 'complete-direct-supplier.Enter references here' | translate }}</mat-label>
            <input matInput formControlName="reference" />
          </mat-form-field>
        </div>
        <div class="field-wrapper p-left d-flex flex-column">
          <div class="field-title d-flex justify-content-between d-flex align-items-center">
            <div class="icon-proofs d-flex align-items-center">
              <span>{{ 'complete-direct-supplier.proofs' | translate }}*</span>
              <app-custom-icon
                icon_category="essential"
                icon_name="info-circle"
                [matTooltip]="
                  'complete-direct-supplier.Please upload here all the documents that prove the received quantity from this direct supplier for this batch.'
                    | translate
                "
                matTooltipPosition="above"
                matTooltipClass="custom-tooltip-center"
                class="cursor-pointer"
              >
              </app-custom-icon>
            </div>
            <input
              type="file"
              class="file-upload"
              #fileUpload
              accept="pdf.doc, image/jpeg, image/jpg, image/gif, image/tiff, image/tif, image/jfif, image/png, video/mp4, video/webm, application/pdf, .docx, .doc, .csv, .xlsx, .xls, .xml, .ppt, .pptx, .webm, .tif, .tiff, .webp"
              (change)="onFileSelected($event)"
            />
            <div class="upload-wrapp d-flex align-items-center cursor-pointer" (click)="fileUpload.click()">
              <p class="m-0">{{ 'complete-direct-supplier.upload document' | translate }}</p>
              <app-custom-icon icon_category="essential" icon_name="upload"></app-custom-icon>
            </div>
          </div>
          <div class="doc-holder d-flex">
            <div *ngIf="proofs.length" class="doc-view d-flex">
              <div class="doc d-flex align-items-center" *ngFor="let document of proofs | slice: 0 : 2; let i = index">
                <p class="m-0">{{ document.name }}</p>
                <app-custom-icon
                  icon_category="settings"
                  icon_name="more"
                  [matMenuTriggerFor]="actionMenu"
                  (click)="toggleViewMore($event, i)"
                  mat-button
                  [ngClass]="{ 'bg-icon': isViewMore && activeIndexdocList === i }"
                ></app-custom-icon>
                <mat-menu
                  class="mat-menu-primary project-action view-more-doc-menu"
                  #actionMenu="matMenu"
                  xPosition="before"
                  yPosition="above"
                  (closed)="onViewMoreClosed()"
                >
                  <button class="custom-item d-flex align-items-center" mat-menu-item (click)="openDocument(document)">
                    <app-custom-icon [icon_category]="'security'" [icon_name]="'eye'"></app-custom-icon>
                    <p class="name m-0">{{ 'complete-direct-supplier.Open document' | translate }}</p>
                  </button>
                  <button
                    class="custom-item d-flex align-items-center"
                    mat-menu-item
                    *ngIf="proofs.length > 1"
                    (click)="removeProofDialog(document.uuid)"
                  >
                    <app-custom-icon
                      [icon_category]="'essential'"
                      [icon_name]="'trash'"
                      id="trash-icon"
                    ></app-custom-icon>
                    <p class="name m-0 red-text">{{ 'complete-direct-supplier.Remove' | translate }}</p>
                  </button>
                </mat-menu>
              </div>
              <app-custom-icon
                class="cursor-pointer"
                mat-button
                [matMenuTriggerFor]="viewMoreMenu"
                [ngClass]="{ 'bg-icon': isViewMoreMenuOpen }"
                *ngIf="proofs.length >= 3"
                [icon_category]="'settings'"
                [icon_name]="'more-2'"
                (click)="toggleViewMoreMenu($event)"
              ></app-custom-icon>
              <mat-menu
                class="mat-menu-primary project-action doc-menu doc-list-menu"
                #viewMoreMenu="matMenu"
                xPosition="before"
                yPosition="above"
                #actionViewMenus[i]="matMenu"
                (closed)="onViewMoreMenuClosed()"
              >
                <button
                  class="custom-item d-flex align-items-center justify-content-between"
                  mat-menu-item
                  *ngFor="let document of proofs | slice: 2; let i = index"
                >
                  <p class="name m-0">{{ document.name }}</p>
                  <app-custom-icon
                    mat-button
                    [matMenuTriggerFor]="actionViewMenu"
                    [ngClass]="{ 'bg-icon': isActionViewMenuOpen && activeIndex === i }"
                    [icon_category]="'settings'"
                    [icon_name]="'more'"
                    (click)="toggleActionViewMenu($event, i)"
                  ></app-custom-icon>
                  <mat-menu
                    class="mat-menu-primary project-action view-more-doc-menu"
                    #actionViewMenu="matMenu"
                    xPosition="before"
                    yPosition="above"
                    [class.menu-open]="isActionViewMenuOpen"
                    (closed)="onActionViewMenuClosed()"
                  >
                    <button
                      class="custom-item d-flex align-items-center"
                      mat-menu-item
                      (click)="openDocument(document)"
                    >
                      <app-custom-icon [icon_category]="'security'" [icon_name]="'eye'"></app-custom-icon>
                      <p class="name m-0">{{ 'complete-direct-supplier.Open document' | translate }}</p>
                    </button>
                    <button
                      class="custom-item d-flex align-items-center"
                      mat-menu-item
                      (click)="removeProofDialog(document.uuid)"
                      *ngIf="proofs.length > 1"
                    >
                      <app-custom-icon
                        [icon_category]="'essential'"
                        [icon_name]="'trash'"
                        id="trash-icon"
                      ></app-custom-icon>
                      <p class="name m-0 red-text">{{ 'complete-direct-supplier.Remove' | translate }}</p>
                    </button>
                  </mat-menu>
                </button>
              </mat-menu>
            </div>
            <div *ngIf="!proofs.length" class="empty-list d-flex align-items-center">
              <app-custom-icon [icon_category]="'essential'" [icon_name]="'danger'"></app-custom-icon>
              <p class="m-0">
                {{ 'complete-direct-supplier.There are no documents of proof uploaded yet.' | translate }}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="info-wrapp d-flex align-items-center">
        <img class="logo" src="assets/logos/symbol-logo-dark.svg" />
        <p class="m-0">
          {{
            'complete-direct-supplier.Please review the contact information of this direct supplier with whom you have worked during this batch and update if necessary.'
              | translate
          }}
        </p>
      </div>
      <div class="input-wrapp d-flex">
        <div class="field-wrapper p-right d-flex flex-column">
          <div class="field-title d-flex align-items-center">
            <span>{{ 'complete-direct-supplier.Contact e-mail' | translate }}*</span>
            <app-custom-icon
              icon_category="essential"
              icon_name="info-circle"
              [matTooltip]="
                'complete-direct-supplier.Please enter the email address of your contact person at the supplier. Please make sure that you got his consent before entering it.'
                  | translate
              "
              matTooltipPosition="above"
              matTooltipClass="custom-tooltip-center"
              class="cursor-pointer"
            >
            </app-custom-icon>
          </div>
          <mat-form-field class="input-primary" appearance="outline">
            <mat-label>{{ 'complete-direct-supplier.Enter email here' | translate }}</mat-label>
            <input matInput formControlName="email" />
            @if (form.controls['email'].errors && form.controls['email'].errors['email']) {
              <mat-error>{{ 'Invalid email' | translate }}</mat-error>
            }
            @if (form.controls['email'].errors && form.controls['email'].errors['required']) {
              <mat-error>{{ 'Email is required' | translate }}</mat-error>
            }
          </mat-form-field>
        </div>
        <div class="field-wrapper d-flex p-left flex-column">
          <div class="field-title d-flex align-items-center">
            <span>{{ 'complete-direct-supplier.Contact Language' | translate }}*</span>
            <app-custom-icon
              icon_category="essential"
              icon_name="info-circle"
              [matTooltip]="
                'complete-direct-supplier.Please choose the default language of the selected contact in which the tool can communicate with him in order to continue build the product’s supply chain.'
                  | translate
              "
              matTooltipPosition="above"
              matTooltipClass="custom-tooltip-center"
              class="cursor-pointer"
            >
            </app-custom-icon>
          </div>
          <mat-form-field class="input-primary" appearance="outline">
            <mat-select
              formControlName="language_prefered"
              panelClass="dropdown-primary"
              [placeholder]="'supply-chain-step.Choose supplier contact language' | translate"
            >
              <mat-option *ngFor="let lang of languages" [value]="lang.uuid">
                {{ 'supply-chain-step.' + lang.name | translate }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </form>
  </div>
  <mat-dialog-actions class="d-flex justify-content-end">
    <app-rounded-button
      [color]="'tetriary-link'"
      [size]="'sm'"
      [button_text]="'supply-chain-step.Cancel' | translate"
      class="cancel"
      (click)="onCancel()"
    >
    </app-rounded-button>
    <app-rounded-button
      class="add-supplier"
      size="md"
      color="turquoise"
      button_text="complete-direct-supplier.Update Direct Supplier"
      (click)="updateDirectSupplier()"
      [disabled]="form.invalid"
    >
    </app-rounded-button>
  </mat-dialog-actions>
</div>
