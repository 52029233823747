import { ActivatedRoute, Router } from '@angular/router';
import { Component, Inject, OnInit } from '@angular/core';

import { AddressFacade } from '@app/core/facade/address.facade';
import { AddressManagementGroup } from './../address-management.group';
import { UntypedFormGroup } from '@angular/forms';
import { GetDirtyValues } from '@app/core/utils/form-dirty-values';
import { ICreateAddressResponse } from '@app/core/interface/address.interface';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CountryList } from '@app/core/utils/country-list';
import { SetCountry } from '@app/core/utils/country';
import { Observable, map, startWith } from 'rxjs';
import { ICountry } from '@app/core/interface/suppliers.interface';
import { NotificationType } from '@app/core/constants';
import { SnackbarService } from '@app/core/service/snackbar.service';

@Component({
  selector: 'app-create-address',
  templateUrl: './create-address.component.html',
  styleUrls: ['./create-address.component.scss'],
  providers: [AddressManagementGroup, AddressFacade],
})
export class CreateAddressComponent implements OnInit {
  form: UntypedFormGroup;
  addressUuid: string | null;
  countryList = CountryList.isoCountries;
  filteredCountries!: Observable<ICountry[]>;

  constructor(
    private _group: AddressManagementGroup,
    private _router: Router,
    private _addressFacade: AddressFacade,
    private _route: ActivatedRoute,
    private _snackbarService: SnackbarService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<CreateAddressComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ICreateAddressResponse
  ) {
    this.form = this._group.createAddressForm;
    this.addressUuid = '';
    this.form.patchValue({
      ...this.data,
      country: SetCountry(this.countryList, this.data?.country),
    });

    this.filteredCountries = this.form.controls['country'].valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value || ''))
    );
  }

  ngOnInit() {
    this.addressUuid = this._route.snapshot.paramMap.get('id');
    if (this.addressUuid) {
      this._addressFacade.getAddress$(this.addressUuid).subscribe({
        next: this._mapAddressData.bind(this),
        error: this._error.bind(this),
      });
    }
    this.form.controls['country'].valueChanges.subscribe(change => {
      this.filteredCountries.subscribe(countries => {
        const item = countries.find(country => country.name === change);
        if (item) {
          this.form.patchValue({
            country: item,
          });
        }
      });
    });
  }

  createAddress(): void {
    const changedFormValues: Partial<ICreateAddressResponse> = GetDirtyValues(this.form);
    if (changedFormValues['country']) {
      changedFormValues['country'] = this.form.controls['country'].value.code;
    }
    if (this.form.valid) {
      this._addressFacade.createAddress$({ ...changedFormValues }).subscribe({
        next: this._success.bind(this),
        error: this._error.bind(this),
      });
    }
  }

  updateAddress(): void {
    const changedFormValues: Partial<ICreateAddressResponse> = GetDirtyValues(this.form);
    if (changedFormValues['country']) {
      changedFormValues['country'] = this.form.controls['country'].value.code;
    }
    if (this.form.valid && this.data.uuid) {
      this._addressFacade.updateAddress$({ ...changedFormValues }, this.data.uuid).subscribe({
        next: this._success.bind(this),
        error: this._error.bind(this),
      });
    }
  }

  private _success(): void {
    this._snackbarService.openTypeSnackbar(
      `Address ${this.data?.uuid ? 'updated' : 'created'} successfully`,
      NotificationType.success
    );
    this.dialogRef.close(true);
    //this._router.navigate(['/address-management']);
  }

  private _mapAddressData(data: ICreateAddressResponse): void {
    this.form.patchValue({
      city: data.city,
      name: data.name,
      postcode: data.postcode,
      street_line_1: data.street_line_1,
      street_line_2: data.street_line_2,
      street_number: data.street_number,
      state: data.state,
    });
  }

  private _error(error: Record<string, string[]>): void {
    Object.values(error).map(err => this._snackbarService.openTypeSnackbar(err[0], NotificationType.error));
  }

  /**
   * Shows only name as display value and keeps hole obj as actual value (code is used for requiest and name for display)
   * @param subject ICountry from country dropdown
   * @returns country name for display
   */
  displayFn(subject: ICountry | null): string {
    return subject?.name ?? '';
  }
  private _filter(value: string): ICountry[] {
    const filterValue = value.toString().toLowerCase();

    return this.countryList.filter(country => country.name.toLowerCase().includes(filterValue));
  }
}
