import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { Injectable } from '@angular/core';

@Injectable()
export class ProductDashboardGroup {
  newSupplierForm: UntypedFormGroup;
  productDetails: UntypedFormGroup;
  editProductChain: UntypedFormGroup;
  filesForm: UntypedFormGroup;

  constructor(private _fb: UntypedFormBuilder) {
    this.newSupplierForm = this._fb.group({
      name: [null, [Validators.required]],
      description: [null, [Validators.maxLength(255)]],
      logo: [null],
      address: [null],
      country: [null, [Validators.required]],
      city: [null],
    });

    this.productDetails = this._fb.group({
      reference: [null],
      description: [null, [Validators.required, Validators.maxLength(255)]],
      composition: [null, [Validators.maxLength(255)]],
      summary: [null, [Validators.required, Validators.maxLength(255)]],
      co2: [null],
      h2o: [null],
      project: [null],
      subproject: [null],
      isProjectAssigne: [false],
      isSubProjectAssigne: [false],
    });
    this.editProductChain = this._fb.group({
      name: [null, Validators.required],
      tags: [[]],
      status: [null, Validators.required],
      external_reference: [[]],
    });

    this.filesForm = this._fb.group({
      name: [null, [Validators.required]],
      url: [null, [Validators.required, Validators.maxLength(255)]],
      // legend: [null, [Validators.maxLength(255)]],
      thumbnail: [null],
      file: [null, [Validators.required]],
    });
  }
}
