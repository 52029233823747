import { AngularMaterialModule } from './../../material.module';
import { CommonModule } from '@angular/common';
import { ForgotPasswordComponent } from './forgot-password.component';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { LinkSentComponent } from './link-sent/link-sent.component';
import { SharedModule } from '@app/shared/shared.module';
import { ForgotPasswordRoutingModule } from './forgot-password-routing.module';
import { NewPasswordComponent } from './new-password/new-password.component';
import { PasswordResetComponent } from './password-reset/password-reset.component';

@NgModule({
  declarations: [ForgotPasswordComponent, LinkSentComponent, NewPasswordComponent, PasswordResetComponent],
  imports: [
    ForgotPasswordRoutingModule,
    CommonModule,
    AngularMaterialModule,
    ReactiveFormsModule,
    TranslateModule.forChild(),
    SharedModule,
    FormsModule,
  ],
  exports: [ForgotPasswordComponent],
})
export class ForgotPasswordModule {}
