<div class="logo"><img class="logo" src="../../../../assets/images/respect-saas-logo.svg" /></div>
<mat-nav-list>
  <mat-accordion>
    <mat-expansion-panel expanded="true" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <mat-label>{{ batchSetup ? 'Batch info' : ('Supply chain info' | translate) }}</mat-label>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="chain-info-wraper">
        <div class="edit-batch" *ngIf="batchSetup" (click)="openBatchModal()">
          <div>
            <app-custom-icon
              [icon_category]="!isChainComplete ? 'files' : 'security'"
              [icon_name]="!isChainComplete ? 'edit' : 'eye'"
            ></app-custom-icon>
          </div>
          <h6>{{ isChainComplete ? 'View batch info' : ('Edit batch info' | translate) }}</h6>
        </div>
        <div class="edit-batch" *ngIf="!batchSetup" (click)="editSupplyChain()">
          <div>
            <app-custom-icon
              [icon_category]="!isChainComplete ? 'files' : 'security'"
              [icon_name]="!isChainComplete ? 'edit' : 'eye'"
            ></app-custom-icon>
          </div>
          <h6>{{ isChainComplete ? 'View supply chain info' : ('Edit supply chain info' | translate) }}</h6>
        </div>

        <div class="chain-info">
          <img
            class="product-image"
            src="{{
              chainData?.product?.image
                ? chainData?.product?.image
                : '../../../../../../assets/images/upload-placeholder-blue.svg'
            }}"
          />
          <div class="chain-name">
            <h6>{{ chainData?.product?.name }}</h6>
            <p>{{ 'Created on ' | translate }}{{ chainData?.product?.created_at | date: 'dd/MM/yyyy' }}</p>
          </div>
        </div>
        <div class="chain-info" *ngIf="batchSetup">
          <img src="../../../../assets/images/chain-name.svg" />
          <div class="chain-name">
            <h6>{{ chainData?.name }}</h6>
            <p>{{ 'Created on ' | translate }}{{ chainData?.created_at | date: 'dd/MM/yyyy' }}</p>
          </div>
        </div>
        <div class="chain-info">
          <img
            [src]="
              !batchSetup ? './../../../assets/images/chain-name.svg' : './../../../assets/images/batch-creation.svg'
            "
          />
          <div>
            <div class="chain-name">
              <h6>
                {{ chainData?.batch?.length ? chainData?.batch?.[0]?.name : chainData?.name }}
              </h6>
              <p class="mb-0">{{ 'Created on ' | translate }}{{ chainData?.created_at | date: 'dd/MM/yyyy' }}</p>
            </div>
            <div class="chain-name" *ngIf="!batchSetup">
              <h6>{{ 'Supply chain ID' | translate }}</h6>
              <p>{{ chainData?.reference }}</p>
            </div>
            <div class="chain-name" *ngIf="batchSetup">
              <h6>{{ 'Batch ID' | translate }}</h6>
              <p>{{ chainData?.batch?.[0]?.reference }}</p>
            </div>
          </div>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
  <div class="horizontal-line"></div>

  <mat-accordion>
    <mat-expansion-panel expanded="true" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <mat-label>{{ batchSetup ? 'Batch phases' : ('Supply chain phases' | translate) }}</mat-label>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="phases-info-wraper">
        <div class="doughnut-container">
          <app-doughnut-chart [doughnutChartData]="phases?.[0]" [selectedPhase]="selectedPhase"></app-doughnut-chart>
        </div>
        <p *ngIf="!showViewAllPhasesBtn">{{ 'Click on a phase to isolate it' | translate }}</p>
        <p *ngIf="showViewAllPhasesBtn" class="view-all-phases" (click)="viewAllPhases()">
          {{ 'View all' | translate }}
        </p>
      </div>
      <app-rounded-button
        color="success"
        [button_text]="'Add steps to phase'"
        [icon_category]="'essential'"
        [icon_name]="'add'"
        [size]="'md'"
        [icon_right]="true"
        *ngIf="!chainData?.batch?.length && !isChainComplete"
        (click)="addStepsToPhaseDialog()"
      >
      </app-rounded-button>
    </mat-expansion-panel>
  </mat-accordion>
  <div *ngIf="!batchSetup || (batchSetup && isChainComplete)" class="horizontal-line"></div>
  <app-rounded-button
    color="primary"
    [button_text]="'Mark Batch As Completed'"
    [size]="'md'"
    (click)="this.onUpdateBatchStatus.emit()"
    *ngIf="batchSetup && !isChainComplete"
  >
  </app-rounded-button>

  <div *ngIf="!batchSetup || (batchSetup && isChainComplete)" class="status d-flex align-items-center">
    <p>{{ batchSetup ? 'Batch Status:' : ('Chain Status:' | translate) }}</p>
    <div
      class="status"
      [ngClass]="{
        'dot-in-progress': chainData?.status === 'IN PROGRESS',
        'dot-completed': chainData?.status === 'COMPLETE',
      }"
    ></div>

    {{ chainData?.status === 'IN PROGRESS' ? 'In Progress' : ('Completed' | titlecase) }}

    <div
      *ngIf="!batchSetup"
      mat-button
      #menuTrigger="matMenuTrigger"
      [matMenuTriggerFor]="beforeMenu"
      class="action d-flex justify-content-center align-items-center"
    >
      <app-custom-icon
        [icon_category]="'arrows'"
        icon_name="{{ menuTrigger.menuOpen ? 'arrow-square-up' : 'arrow-square-down' }}"
      >
      </app-custom-icon>
    </div>

    <mat-menu class="mat-menu-primary status-menu" #beforeMenu="matMenu" yPosition="below">
      <button
        class="custom-item"
        mat-menu-item
        (click)="setStatus('IN PROGRESS')"
        [disabled]="chainData?.status === 'IN PROGRESS'"
      >
        <div class="wrapper">
          <p class="name m-0" [ngClass]="chainData?.status === 'IN PROGRESS' ? 'strong' : 'neutral'">
            {{ 'In Progress' | translate }}
          </p>
          <app-custom-icon
            [ngClass]="chainData?.status === 'IN PROGRESS' ? 'd-block' : 'd-none'"
            icon_category="essential"
            icon_name="tick-circle"
          >
          </app-custom-icon>
        </div>
      </button>

      <div class="horizontal-line"></div>

      <button
        class="custom-item"
        mat-menu-item
        (click)="setStatus('COMPLETE')"
        [disabled]="chainData?.status === 'COMPLETE'"
      >
        <div class="wrapper">
          <p class="name m-0" [ngClass]="chainData?.status === 'COMPLETE' ? 'strong' : 'neutral'">
            {{ 'Completed' | translate }}
          </p>
          <app-custom-icon
            [ngClass]="chainData?.status === 'COMPLETE' ? 'd-block' : 'd-none'"
            icon_category="essential"
            icon_name="tick-circle"
          >
          </app-custom-icon>
        </div>
      </button>
    </mat-menu>
  </div>

  <mat-accordion *ngIf="!batchSetup && !isChainComplete">
    <mat-expansion-panel expanded="true" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <mat-label>{{ 'Suppliers' | translate }}</mat-label>
        </mat-panel-title>
      </mat-expansion-panel-header>

      <app-rounded-button
        color="primary"
        [button_text]="'Create Supplier'"
        [icon_category]="'product'"
        [icon_name]="'truck'"
        [size]="'md'"
        [icon_right]="true"
        (click)="createSupplierDialog()"
      >
      </app-rounded-button>
      <p class="manage-text">{{ 'Manage suppliers & components' | translate }}</p>
      <app-rounded-button
        color="secondary"
        [button_text]="'Manage'"
        [icon_category]="'files'"
        [icon_name]="'edit'"
        [size]="'md'"
        [icon_right]="true"
        (click)="manageSupplierDialog()"
      >
      </app-rounded-button>

      <div class="suppliers-info-wraper">
        <!-- <div class="suppliers-list" *ngIf="supplierList?.length">
          <mat-accordion *ngFor="let supplier of supplierList; let i = index">
            <mat-expansion-panel class="suppliers" [expanded]="step === i" (opened)="setStep(i)">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <mat-label>{{ supplier.name }}</mat-label>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="supplier-locations-wraper">
                <div *ngFor="let factory of supplier.manufactories">
                  <p
                    [ngClass]="{ 'main-location': factory.is_main_location }"
                    matTooltip="{{ factory.is_main_location ? 'main site' : '' }}"
                    matTooltipClass="custom-tooltip-center"
                    [matTooltipPosition]="'above'"
                  >
                    <span>{{ factory.name }}</span> {{ factory.address ? factory.address + ',' : '' }}
                    {{ factory.city ? factory.city + ',' : '' }} {{ factory.country }}
                  </p>
                </div>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </div> -->
        <p class="no-suppliers" *ngIf="!supplierList?.length">{{ 'You have no suppliers created yet.' | translate }}</p>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</mat-nav-list>
