<div class="holder d-flex flex-column">
  <div class="table-holder">
    <table mat-table matSort [dataSource]="dataSource" class="custom-table mat-table product-supplier-table">
      <ng-container matColumnDef="name">
        <th mat-header-cell mat-sort-header class="supplier-name-header" *matHeaderCellDef>
          {{ 'Supplier name' | translate }}
        </th>
        <td mat-cell *matCellDef="let element" class="supp-name">
          {{ element.name }}
        </td>
      </ng-container>
      <ng-container matColumnDef="description">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>
          {{ 'Supplier description' | translate }}
        </th>
        <td mat-cell *matCellDef="let element" class="supplier_description">
          <div class="sup-description">
            <app-view-rich-text [content]="element.description" [inheritStyles]="true"></app-view-rich-text>
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="actions" class="product-suppliers-action">
        <th mat-header-cell *matHeaderCellDef class="product-suppliers-action">
          <div class="action">{{ 'Actions' | translate }}</div>
        </th>
        <td mat-cell *matCellDef="let element" class="product-suppliers-action">
          <div class="icon-holder">
            <div
              class="view-icon"
              matTooltipClass="custom-tooltip-center"
              [matTooltipPosition]="'above'"
              [matTooltip]="('View ' | translate) + element.name"
            >
              <a [routerLink]="['/supplier-management/', element.uuid]" href="" target="_blank">
                <app-custom-icon
                  [icon_category]="'security'"
                  [icon_name]="'eye'"
                  class="cursor-pointer"
                ></app-custom-icon>
              </a>
            </div>

            <div
              class="delete-icon"
              matTooltipClass="custom-tooltip-end"
              [matTooltipPosition]="'above'"
              [matTooltip]="'Remove ' + element.name"
            >
              <app-custom-icon
                [icon_category]="'user'"
                [icon_name]="'profile-remove'"
                (click)="removeProductSupplier(element.uuid, element.name)"
                class="cursor-pointer"
              >
              </app-custom-icon>
            </div>
          </div>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
    <div class="no-suppliers d-flex align-items-center" *ngIf="dataSource.data.length <= 0">
      <div class="icon-circle">
        <app-custom-icon id="truck" [icon_category]="'product'" [icon_name]="'truck'"></app-custom-icon>
      </div>
      <p class="d-flex align-items-center justify-content-center">
        {{ 'There are no suppliers added to this product yet!' }}
      </p>
    </div>
  </div>
  <div class="card-footer">
    <mat-paginator #paginator showFirstLastButtons aria-label="Select page of components"> </mat-paginator>
  </div>
</div>

<div *ngIf="isAddSupplier" class="col-12 btn-wrapper">
  <mat-form-field class="col-4 offset-1">
    <mat-label>{{ 'Supplier' | translate }}</mat-label>
    <mat-select [(ngModel)]="selectedSupplier">
      <mat-option *ngFor="let supplier of supplierList" [value]="supplier.uuid">{{ supplier.name }}</mat-option>
    </mat-select>
  </mat-form-field>
  <span class="col-4 offset-1">
    <button
      mat-icon-button
      aria-label="add-supplier"
      [matTooltip]="'Create Supplier' | translate"
      (click)="createSupplierDialog()"
    >
      <mat-icon>add</mat-icon>
    </button>
  </span>

  <div class="col-3 offset-8">
    <div class="row">
      <button mat-button (click)="cancel()" class="col-6 cancel-btn">{{ 'Cancel' | translate }}</button>
      <button
        mat-button
        mat-raised-button
        color="primary"
        [disabled]="!selectedSupplier"
        (click)="save()"
        class="col-6 save-btn"
      >
        {{ 'Add' | translate }}
      </button>
    </div>
  </div>
</div>
