<mat-card class="card-wrp" [formGroup]="form">
  <div class="flex">
    <div class="stepNum">{{ stepNum }}</div>
    <p class="head-step">{{ step.header }}</p>
  </div>
  <p class="tit">{{ step.title }}</p>

  <mat-radio-group formControlName="choice" class="row btn-wrp" *ngIf="step.options && step.options.length > 1">
    <mat-radio-button
      *ngFor="let option of step.options; let i = index"
      [value]="option"
      class="col-6 btn"
      [ngClass]="{ 'w-100': option === 'Dyeing & Printing' }"
    >
      <p class="lab-con">
        {{ option }}
      </p>
    </mat-radio-button>
  </mat-radio-group>
  <p class="inf" *ngIf="step.options && step.options.length === 2">
    {{ 'If both steps exist, please select "Weaving"' | translate }}
  </p>
  <div *ngIf="step.options && step.options.length === 1" class="flex v-middle">
    <div class="cyrcle"></div>
    <div class="lab-con">
      {{ step.options[0] }}
    </div>
  </div>

  <mat-form-field class="input-primary col-12 country-field" appearance="outline">
    <mat-label>{{ 'Country' | translate }}</mat-label>
    <input type="text" matInput formControlName="country" [matAutocomplete]="auto" required />
    <mat-autocomplete class="autocomplete-primary" #auto="matAutocomplete" [displayWith]="displayFn">
      <mat-option *ngFor="let country of filteredCountries | async" [value]="country">
        {{ country.name }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</mat-card>
