import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TasksGroup } from '../tasks.group';
import { IErrorResponse } from '@app/core/interface/products.interface';
import { TasksFacade } from '@app/core/facade/tasks.facade';
import {
  IRelObj,
  ITask,
  ITaskTypes,
  ITaskUser,
  ITasksObj,
  ITasksObjectResponse,
  ITasksTypesResponse,
  ITasksUsersResponse,
} from '@app/core/interface/tasks.interface';
import { take, takeWhile } from 'rxjs';
import { MatDialogRef } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { NotificationType } from '@app/core/constants';
import { SnackbarService } from '@app/core/service/snackbar.service';

@Component({
  selector: 'app-create-task-dialog',
  templateUrl: './create-task-dialog.component.html',
  styleUrls: ['./create-task-dialog.component.scss'],
  providers: [TasksGroup, DatePipe],
})
export class CreateTaskDialogComponent implements OnInit {
  form!: FormGroup;
  statuses = ['TO DO'];
  types: ITaskTypes[] = [];
  users: ITaskUser[] = [];
  objects: ITasksObj[] = [];
  filteredUsers: Array<ITaskUser> = [];
  filteredObjects: Array<ITasksObj> = [];
  filteredObjectsByType: Array<ITasksObj> = [];
  selectedType: ITaskTypes | undefined;
  selectedObject: IRelObj | undefined;
  assign_to: ITaskUser | undefined;
  minEnd!: Date;
  minStart!: Date;
  spinner = false;
  alive = true;
  constructor(
    public dialogRef: MatDialogRef<CreateTaskDialogComponent>,
    private _group: TasksGroup,
    private _facade: TasksFacade,
    private _snackbarService: SnackbarService,
    public datepipe: DatePipe
  ) {
    this.form = this._group.createTaskForm;
  }

  ngOnInit(): void {
    this.mergeTasksData();
    this.handleDates();
    this.handleRelatedObject();
    this.handleAssigedTo();
  }
  handleDates(): void {
    this.minStart = new Date();
    this.form.controls['start_at'].valueChanges.pipe(takeWhile(() => this.alive)).subscribe(date => {
      this.minEnd = date;
      date ? this.form.controls['end_date'].enable() : this.form.controls['end_date'].disable();
      if (!date && this.form.controls['end_date'].value) {
        this.form.controls['end_date'].patchValue(null);
      }
    });
    this.form.controls['start_at'].value
      ? this.form.controls['end_date'].enable()
      : this.form.controls['end_date'].disable();
  }
  handleRelatedObject(): void {
    this.form.controls['relatedObj'].valueChanges.pipe(takeWhile(() => this.alive)).subscribe(val => {
      let obj = val;
      this.selectedObject = val;
      if (typeof val === 'string') obj = this.getRelObjByName(val);
      if (!obj?.object_type) return;
      const reltype = this.getObjectType(obj.object_type);
      if (reltype) this.selectedType = reltype;
      this.form.updateValueAndValidity();
    });
  }

  handleAssigedTo(): void {
    this.form.controls['assigned_to'].valueChanges.pipe(takeWhile(() => this.alive)).subscribe(val => {
      this.assign_to = val;
      if (typeof val === 'string') this.assign_to = this.getUserByName(val);
    });
  }
  createTask(): void {
    if (this.spinner || this.form.invalid) return;
    this.spinner = true;
    const payload = this.getPayload();
    if (payload)
      this._facade.createTask$(payload).subscribe({
        next: this._onSuccessCreate.bind(this),
        error: this._error.bind(this),
      });
  }

  getPayload(): Partial<ITask> | undefined {
    if (!this.selectedObject || !this.assign_to) {
      this.spinner = false;
      return;
    }
    this.form.controls['relatedObj'].setValue(this.selectedObject);
    this.form.controls['assigned_to'].setValue(this.assign_to);
    let payload = {
      ...this.form.value,
    };
    if (this.form.value.assigned_to?.uuid) {
      payload = {
        ...this.form.value,
        assigned_to: this.form.value.assigned_to.uuid,
      };
    } else {
      delete payload.assigned_to;
    }
    if (this.form.value.relatedObj.object_type === 'Product')
      payload = { ...payload, product: this.form.value.relatedObj.uuid };
    if (this.form.value.relatedObj.object_type === 'Supplier')
      payload = { ...payload, supplier: this.form.value.relatedObj.uuid };
    if (this.form.value.relatedObj.object_type === 'Step')
      payload = { ...payload, step: this.form.value.relatedObj.uuid };
    if (this.form.value.relatedObj.object_type === 'ProductChain')
      payload = { ...payload, product_chain: this.form.value.relatedObj.uuid };
    delete payload.relatedObj;

    if (payload['start_at']) {
      payload['start_at'] = this.datepipe.transform(new Date(payload['start_at']), 'yyyy-MM-ddThh:mm:ss.SSSZ') ?? '';
    } else {
      payload['start_at'] = '';
    }
    if (payload['end_date']) {
      payload['end_date'] = this.datepipe.transform(new Date(payload['end_date']), 'yyyy-MM-ddThh:mm:ss.SSSZ') ?? '';
    } else {
      payload['end_date'] = '';
    }
    return payload;
  }

  filterItem(event: Event): void {
    const value = (event.target as HTMLInputElement).value;
    if (!value) {
      this.filteredUsers = this.users;
      this.assign_to = undefined;
    } else {
      this.filteredUsers = this.users.filter(el => el.username.includes(value));
    }
  }
  filterByType(): void {
    if (!this.selectedType) {
      this.filteredObjectsByType = this.objects;
    } else {
      this.filteredObjectsByType = this.objects.filter(
        (el: ITasksObj) => el.object_type.toLocaleLowerCase() === this.selectedType?.name.toLocaleLowerCase()
      );
    }
    if (this.filteredObjectsByType.length < 1) {
      this.form.controls['relatedObj'].disable();
    } else {
      this.form.controls['relatedObj'].enable();
    }
    this.filteredObjects = this.filteredObjectsByType;
    this.form.controls['relatedObj'].setValue('');
  }

  filterObjects(event: Event): void {
    const value = (event.target as HTMLInputElement).value;
    if (this.filteredObjectsByType.length < 1) {
      this.filteredObjectsByType = this.objects;
    }
    if (!value) {
      this.filteredObjects = this.filteredObjectsByType;
    } else {
      this.filteredObjects = this.filteredObjectsByType.filter(el => el.name.includes(value));
    }
  }
  removeSelectedObjAndType(): void {
    this.selectedType = undefined;
    this.removeSelectedObj();
  }
  removeSelectedObj(): void {
    this.form.controls['relatedObj'].setValue('');
  }
  mergeTasksData(): void {
    this._facade
      .mergeTasksdata()
      .pipe(take(1))
      .subscribe({
        next: this._mergeTasksDataSuccess.bind(this),
        error: this._error.bind(this),
      });
  }
  displayFn(subject: ITaskUser | null): string {
    return subject ? subject.username : '';
  }
  displayObjFn(subject: ITasksObj | null): string {
    return subject ? subject.name : '';
  }
  getObjectType(val: string): ITaskTypes | undefined {
    return this.types.find(obj => obj.name === val);
  }

  getRelObjByName(val: string): IRelObj | undefined {
    this.selectedObject = this.objects.find(obj => obj.name === val);
    return this.selectedObject;
  }

  getUserByName(val: string): ITaskUser | undefined {
    this.assign_to = this.users.find(obj => obj.username === val);
    return this.assign_to;
  }
  removeAssigned(): void {
    this.form.controls['assigned_to'].setValue(undefined);
  }
  private _mergeTasksDataSuccess(
    responsesArray: [ITasksObjectResponse, ITasksUsersResponse, ITasksTypesResponse]
  ): void {
    this.objects = this.filteredObjects = this.filteredObjectsByType = responsesArray[0].results;
    this.users = this.filteredUsers = responsesArray[1].results;
    this.types = responsesArray[2].results;
    this.spinner = false;
  }
  _onSuccessCreate(): void {
    this.spinner = false;
    this.dialogRef.close(true);
  }
  private _error(error: IErrorResponse): void {
    Object.values(error).map(err => this._snackbarService.openTypeSnackbar(err[0], NotificationType.error));
    this.spinner = false;
  }
}
