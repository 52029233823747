<div class="d-flex flex-column full-height">
  <h2 *ngIf="!isCreateComponent && !isCreateSupplierAndSite; else breadcrumb" mat-dialog-title>
    {{ 'Add Component' | translate }}
  </h2>
  <ng-template #breadcrumb>
    <div class="dialog-title d-flex align-items-center">
      <div
        class="d-flex align-items-center cursor-pointer"
        (click)="isCreateComponent = false; isCreateSupplierAndSite = false"
      >
        <app-custom-icon
          class="arrow-circle-left-icon"
          icon_category="arrows"
          icon_name="arrow-circle-left"
        ></app-custom-icon>
        <p class="m-0">{{ 'Add Component' | translate }}</p>
      </div>
      <app-custom-icon class="arrow-right-icon" icon_category="arrows" icon_name="arrow-right-2"></app-custom-icon>
      <h5 class="m-0">
        <strong>{{
          (isCreateComponent
            ? 'Create Component'
            : suppliersDropdown.value
              ? 'Create Supplier Site'
              : 'Create Supplier'
          ) | translate
        }}</strong>
      </h5>
    </div>
  </ng-template>

  <mat-dialog-content class="flex-grow-1">
    <div *ngIf="!isCreateSupplierAndSite" class="toggle-group">
      <mat-button-toggle-group #group="matButtonToggleGroup" (change)="onButtonToggleChange($event)">
        <div class="toggle">
          <mat-button-toggle value="supplier" aria-label="{{ 'Choose Supplier' | translate }}" [checked]="true">
            <span>{{ 'Choose Supplier' | translate }}</span>
          </mat-button-toggle>
        </div>
        <div class="toggle">
          <mat-button-toggle value="no-supplier" aria-label="{{ 'No Supplier' | translate }}">
            <span>{{ 'No Supplier' | translate }}</span>
          </mat-button-toggle>
        </div>
      </mat-button-toggle-group>
    </div>
    <!------------------------------------------ Choose supplier mode --------------------------------------->
    <ng-container *ngIf="mode === 'supplier' && !isCreateComponent && !isCreateSupplierAndSite">
      <h6 class="choose-create-supplier-note">
        <strong>{{ 'Choose or Create Supplier first and then choose a Supplier Site' | translate }}</strong>
      </h6>
      <div class="choose-supplier-and-site d-flex">
        <div class="autocomplete-input-field">
          <!--------------- Suppliers dropdown ----------->
          <mat-form-field class="input-primary col mat-form-field-10-px-brd-rd-dd" appearance="outline">
            <input
              id="supplier"
              [formControl]="suppliersDropdown"
              placeholder="{{ 'Search for a supplier' | translate }}"
              panelClass="autocomplete-primary"
              matInput
              [matAutocomplete]="auto"
              (input)="filterItem($event)"
              #inputFilter
              #trigger="matAutocompleteTrigger"
              matAutocompletePosition="below"
            />
            <mat-autocomplete
              panelClass="autocomplete-primary"
              [displayWith]="displayFn"
              class="autocomplete-primary certificate-list-autocomplete"
              #auto="matAutocomplete"
              (closed)="opened = !opened"
              (opened)="opened = !opened"
              (optionSelected)="onSupplierSelected($event)"
            >
              <mat-option *ngFor="let supplier of suppliers" [value]="supplier" placeholder="certificate.name">
                {{ supplier.name }}
              </mat-option>
            </mat-autocomplete>

            <app-custom-icon
              class="cursor-pointer"
              (click)="openOrClosePanel($event, trigger)"
              matSuffix
              [icon_category]="'arrows'"
              [icon_name]="opened ? 'arrow-up-2' : 'arrow-down-2'"
            ></app-custom-icon>
          </mat-form-field>
        </div>
        <!-------------- Sites dropdown --------------->
        <div class="site d-flex flex-column">
          <mat-form-field appearance="outline" class="input-primary col mat-form-field-10-px-brd-rd-dd">
            <mat-select
              #sitesDropdownElement
              [formControl]="sitesDropdown"
              panelClass="dropdown-primary"
              placeholder="{{ 'Choose Supplier Site' | translate }}"
              (selectionChange)="onSupplierSiteSelected($event)"
            >
              <mat-option *ngFor="let site of sites" [value]="site">
                {{ site.name }} {{ site.address }}, {{ site.city }}, {{ site.country }}
              </mat-option>
              <mat-option *ngIf="!sites.length">No supplier site yet</mat-option>
            </mat-select>
          </mat-form-field>

          <div class="what-is-note d-flex align-items-center">
            <img src="assets/logos/symbol-logo-dark.svg" alt="" />
            <p class="p1-strong m-0">{{ 'What is a Supplier Site?' | translate }}</p>
          </div>
        </div>

        <app-rounded-button
          class="btn-width-180px"
          color="turquoise"
          button_text="{{ (suppliersDropdown.value ? 'Create Supplier Site' : 'Create Supplier') | translate }}"
          size="md"
          (click)="isCreateSupplierAndSite = true; isCreateSupplier = suppliersDropdown.value ? false : true"
        >
        </app-rounded-button>
      </div>
      <div class="choose-or-create-component d-flex flex-wrap justify-content-between" *ngIf="sitesDropdown.value">
        <h6 class="col-12">
          <strong>{{ 'Choose or Create the Component you like to add' | translate }}</strong>
        </h6>
        <div class="component d-flex flex-column">
          <mat-form-field appearance="outline" class="input-primary col mat-form-field-10-px-brd-rd-dd">
            <mat-select
              [formControl]="componentsDropdown"
              panelClass="dropdown-primary"
              placeholder="{{ 'Choose Component' | translate }}"
            >
              <mat-option *ngFor="let component of components" [value]="component.uuid">
                {{ component.name }}
              </mat-option>
              <mat-option *ngIf="!components.length">No component created yet</mat-option>
            </mat-select>
          </mat-form-field>

          <div class="what-is-note d-flex align-items-center">
            <img src="assets/logos/symbol-logo-dark.svg" alt="" />
            <p class="p1-strong m-0">{{ 'What is Component?' | translate }}</p>
          </div>
        </div>
        <app-rounded-button
          class="btn-width-180px"
          color="secondary"
          button_text="{{ 'Create Component' | translate }}"
          size="md"
          icon_category="essential"
          icon_name="box-2"
          [icon_right]="true"
          (click)="isCreateComponent = true"
        >
        </app-rounded-button>
      </div>
    </ng-container>
    <!--------------------------------- No supplier mode ------------------------------------>
    <ng-container *ngIf="mode === 'no-supplier' && !isCreateComponent && !isCreateSupplierAndSite">
      <div class="choose-or-create-component d-flex flex-wrap justify-content-between">
        <h6 class="col-12">
          <strong>{{ 'Choose or Create the Component you like to add' | translate }}</strong>
        </h6>
        <div class="component d-flex flex-column">
          <mat-form-field appearance="outline" class="input-primary col mat-form-field-10-px-brd-rd-dd">
            <mat-select
              [formControl]="noSupplierComponentsDropdown"
              panelClass="dropdown-primary"
              placeholder="{{ 'Choose Component' | translate }}"
            >
              <mat-option *ngFor="let component of noSupplierComponents" [value]="component.uuid">
                {{ component.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <div class="what-is-note d-flex align-items-center">
            <img src="assets/logos/symbol-logo-dark.svg" alt="" />
            <p class="p1-strong m-0">{{ 'What is Component?' | translate }}</p>
          </div>
        </div>
        <app-rounded-button
          class="btn-width-180px"
          color="secondary"
          button_text="{{ 'Create Component' | translate }}"
          size="md"
          icon_category="essential"
          icon_name="box-2"
          [icon_right]="true"
          (click)="isCreateComponent = true"
        >
        </app-rounded-button>
      </div>
    </ng-container>
    <!----------------------------- Create and add component --------------------------->
    <ng-container *ngIf="isCreateComponent">
      <app-create-add-component
        [supplier]="mode === 'supplier' && suppliersDropdown.value"
        [supplierSite]="mode === 'supplier' && sitesDropdown.value"
        [productUuid]="product.uuid"
        (closeDialog)="dialogRef.close(true)"
      >
      </app-create-add-component>
    </ng-container>
    <!-------------------------- Create supplier and supplier site --------------------->
    <ng-container *ngIf="isCreateSupplierAndSite">
      <app-create-supplier-and-site
        [productUuid]="product.uuid"
        [supplierUuid]="suppliersDropdown.value?.uuid"
        [isCreateSupplier]="isCreateSupplier"
        [supplierSites]="sites"
        (addedSupplier)="onAddedSupplier($event)"
        (createdSite)="onCreatedSite($event)"
      >
      </app-create-supplier-and-site>
    </ng-container>
  </mat-dialog-content>
  <mat-dialog-actions *ngIf="!isCreateComponent && !isCreateSupplierAndSite" align="end">
    <app-rounded-button
      class="btn-width-180px"
      color="tetriary-link underlined"
      button_text="{{ 'Cancel' | translate }}"
      size="md"
      mat-dialog-close
    >
    </app-rounded-button>
    <app-rounded-button
      class="btn-width-180px"
      color="primary"
      button_text="{{ 'Add Component' | translate }}"
      size="md"
      [disabled]="mode === 'supplier' ? componentsDropdown.invalid : noSupplierComponentsDropdown.invalid"
      (click)="onAddComponent()"
    >
    </app-rounded-button>
  </mat-dialog-actions>
</div>
