import { RouterModule, Routes } from '@angular/router';

import { AccountCreatedComponent } from './account-created/account-created.component';
import { CompleteRegisterStepsComponent } from './complete-register-steps/complete-register-steps.component';
import { CreateAccountNewComponent } from './create-account-new/create-account-new.component';
import { NgModule } from '@angular/core';
import { ProfileCompletedComponent } from './complete-register-steps/profile-completed/profile-completed.component';

const routes: Routes = [
  {
    path: '',
    component: CreateAccountNewComponent,
  },
  {
    path: 'account-created',
    component: AccountCreatedComponent,
  },
  {
    path: 'complete-register',
    component: CompleteRegisterStepsComponent,
  },
  {
    path: 'create-account',
    component: CreateAccountNewComponent,
  },
  {
    path: 'profile-completed',
    component: ProfileCompletedComponent,
  },
  {
    path: ':uid/:token',
    component: CreateAccountNewComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class RegisterRoutingModule {}
