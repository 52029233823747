import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AlertsTableComponent } from './alerts-table/alerts-table.component';
import { AngularMaterialModule } from '@app/material.module';
import { CommonModule } from '@angular/common';
import { CreateSupplierComponent } from './create-supplier/create-supplier.component';
import { ManageSupplierComponent } from './manage-supplier/manage-supplier.component';
import { NgModule } from '@angular/core';
import { RecentSuppliersList } from './recent-suppliers-list/recent-suppliers-list.component';
import { SharedModule } from '@app/shared/shared.module';
import { SupplierDashboardComponent } from './supplier-dashboard.component';
import { SupplierDashboardRoutingModule } from './supplier-dashboard-routing.module';
import { SupplierDetailsComponent } from './create-supplier/supplier-details/supplier-details.component';
import { SupplierListComponent } from './supplier-list/supplier-list.component';
import { SupplierSitesComponent } from './create-supplier/supplier-sites/supplier-sites.component';
import { SuppliersFacade } from '@app/core/facade/suppliers.facade';
import { ProductsFacade } from '@app/core/facade/products.facade';

@NgModule({
  imports: [
    CommonModule,
    AngularMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    SupplierDashboardRoutingModule,
  ],
  declarations: [
    SupplierDashboardComponent,
    CreateSupplierComponent,
    RecentSuppliersList,
    AlertsTableComponent,
    SupplierListComponent,
    SupplierDetailsComponent,
    SupplierSitesComponent,
    ManageSupplierComponent,
  ],
  providers: [SuppliersFacade, ProductsFacade],
})
export class SupplierDashboardModule {}
