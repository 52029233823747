<mat-card class="projects-list-wrapper">
  <div class="header-wrapp d-flex flex-column justify-content-between">
    <div class="title d-flex justify-content-between align-items-center">
      <h6 class="pr-title">
        {{ 'Sub-Projects' | translate }}
      </h6>

      <app-rounded-button
        color="primary"
        (click)="openCreateDialog(null, false)"
        [button_text]="'Create Sub-Project'"
        [icon_category]="'essential'"
        [icon_name]="'sub-project'"
        [icon_right]="true"
        [size]="'md'"
        class="crt-btn"
      >
      </app-rounded-button>
    </div>
    <div *ngIf="allSubprojects.length" class="checkbox-holder d-flex justify-content-end">
      <mat-checkbox (change)="showDeactivatedSubprojects($event)" [checked]="show_deactivated" class="checkbox-primary">
        {{ 'show deactivated sub-projects' | translate }}
      </mat-checkbox>
    </div>
  </div>
  <div class="table-wraper">
    <div class="row">
      <table *ngIf="dataSource.data.length" class="project-table" mat-table matSort [dataSource]="dataSource">
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Sub-Project name' | translate }}</th>
          <td mat-cell *matCellDef="let element" [ngClass]="{ inactive: !element.is_active }">
            {{ element.name }}
          </td>
        </ng-container>

        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Status' | translate }}</th>
          <td mat-cell *matCellDef="let element" [ngClass]="{ inactive: !element.is_active }">
            <div *ngIf="element.is_active" class="status-holder d-flex align-items-center">
              <div
                [ngClass]="{
                  to_do: element.status?.name === 'Not started',
                  in_progress: element.status?.name === 'In progress',
                  completed: element.status?.name === 'Completed',
                }"
              ></div>
              <p class="m-0">
                {{ element.status?.name }}
              </p>
            </div>
            <p *ngIf="!element.is_active" class="m-0">{{ 'not set' | translate }}</p>
          </td>
        </ng-container>

        <ng-container matColumnDef="product_traced_num">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Number of products traced' | translate }}</th>
          <td mat-cell *matCellDef="let element" [ngClass]="{ inactive: !element.is_active }">
            {{ element.subproject_products.length ?? 0 }}
          </td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef class="actions-header">
            <div class="d-flex justify-content-end">{{ 'Actions' | translate }}</div>
          </th>
          <td mat-cell *matCellDef="let element" class="actions-body">
            <div *ngIf="element.is_active">
              <div class="action d-flex justify-content-end align-items-center">
                <app-custom-icon
                  (click)="openViewDialog(element)"
                  id="eye"
                  matTooltip="{{ 'View Sub-project' | translate }}"
                  matTooltipClass="custom-tooltip-center"
                  matTooltipPosition="above"
                  [icon_category]="'security'"
                  [icon_name]="'eye'"
                >
                </app-custom-icon>
                <app-custom-icon
                  id="edit"
                  (click)="openCreateDialog(element, true)"
                  matTooltip="{{ 'Edit Sub-project' | translate }}"
                  matTooltipClass="custom-tooltip-center"
                  matTooltipPosition="above"
                  [icon_category]="'files'"
                  [icon_name]="'edit'"
                >
                </app-custom-icon>
                <app-custom-icon
                  id="deactivate"
                  (click)="deactivateSubproject(element)"
                  matTooltip="{{ 'Deactivate Sub-project' | translate }}"
                  matTooltipClass="custom-tooltip-end"
                  matTooltipPosition="above"
                  [icon_category]="'essential'"
                  [icon_name]="'deactivate-red'"
                >
                </app-custom-icon>
              </div>
            </div>
            <div *ngIf="!element.is_active" class="activate d-flex justify-content-end">
              <app-custom-icon
                id="activate"
                [icon_category]="'essential'"
                [icon_name]="'activate'"
                *ngIf="!element.is_active"
                (click)="reactivateSubproject(element)"
                matTooltip="{{ 'Reactivate Sub-project' | translate }}"
                matTooltipClass="custom-tooltip-end"
                [matTooltipPosition]="'above'"
              ></app-custom-icon>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
      <div *ngIf="!allSubprojects.length" class="empty-list d-flex align-items-center justify-content-center">
        <div class="icon-holder d-flex align-items-center justify-content-center">
          <app-custom-icon icon_category="business" icon_name="pie-chart"></app-custom-icon>
        </div>
        <h4 class="m-0">{{ 'There are no sub-projects created yet.' | translate }}</h4>
      </div>
      <div
        *ngIf="!dataSource.data.length && allSubprojects.length"
        class="empty-list d-flex align-items-center justify-content-center"
      >
        <div class="icon-holder d-flex align-items-center justify-content-center">
          <app-custom-icon icon_category="business" icon_name="pie-chart"></app-custom-icon>
        </div>
        <h4 class="m-0">{{ 'Аll sub-projects are deactivated' | translate }}</h4>
      </div>
    </div>
  </div>

  <div class="card-footer" *ngIf="dataSource.data.length">
    <mat-paginator showFirstLastButtons aria-label="Select page of sub-projects"> </mat-paginator>
  </div>
</mat-card>
